<header class="site_head" *ngIf="user && !isUploadFileRoute()">
    <nav class="navbar navbar-default">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" (click)="isCollapsed = !isCollapsed"
                    data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" routerLink=""><img src="/assets/img/logo.png" alt="Treat it" /></a>
            </div>

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1" [collapse]="isCollapsed">
                <ul class="nav navbar-nav">
                    <li *ngIf="user.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST'"><a routerLink="orders"
                            routerLinkActive="active"> Assessments </a></li>
                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE === 'GP'"><a routerLink="appointments"
                            routerLinkActive="active">Video Consultations</a></li>

                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'MDT'"><a routerLink="mdt-dashboard"
                            routerLinkActive="active">Dashboard</a></li>
                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'MDT'"><a routerLink="mdt-orders"
                            routerLinkActive="active">Pending Orders</a></li>
                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'MDT'"><a routerLink="past-sessions"
                            routerLinkActive="active">Past Sessions</a></li>

                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'SPECIALIST'"><a
                            routerLink="pending-orders" routerLinkActive="active">Pending Orders</a></li>
                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'SPECIALIST'"><a
                            routerLink="tracking-numbers" routerLinkActive="active">Tracking Numbers</a></li>
                    <li *ngIf="!user.restricted && user.settings.DOCTOR_TYPE == 'SPECIALIST'"><a
                            routerLink="past-orders" routerLinkActive="active">Past Orders</a></li>

                    <li><a routerLink="formulary" routerLinkActive="active">Formularies</a></li>
                    <li><a routerLink="patients" routerLinkActive="active">All Patients</a></li>
                    <li *ngIf="!user.restricted" (click)="recommendationToolModal.show()" [style.cursor]="'pointer'">
                        <a>Product Recommendation Tool</a>
                    </li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    <li class="btn-group" dropdown>
                        <a dropdownToggle>
                            Welcome {{user.displayName}} <span class="caret"></span>
                        </a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li><a routerLink="doctor-info" routerLinkActive="active">Doctor Info</a></li>
                            <li *ngIf="!isQuickSilvaDoctor()"><a routerLink='/change-password'>Change password</a></li>
                            <li><a routerLink='/change-pin'>Change pin</a></li>
                            <li><a routerLink='/two-factor-auth'>Two factor authentication</a></li>
                            <li><a (click)='logout()'>Logout</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- /.container-fluid -->
    </nav>
</header>


<div class="print_only container site-address">
    <address class="pull-right ">
        The Treat it (UK), <br />
        Coventry University Technology Park,<br />
        The TechnoCentre, <br />
        Puma Way, <br />
        CV1 2TT Coventry<br />
        Company Number: 09359853 <br />
        Tel: 024 7745 0733 <br />
        Email: Medical@theGPservice.co.uk
    </address>
    <img alt="Treat it" src="/assets/img/logo.png" class="pull-left site-logo">
</div>

<div bsModal #recommendationToolModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" id="recommendationClearModalDialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="handleCloseToolForm()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Product Recommendation Tool</h4>
            </div>
            <div class="modal-body">
                <form #recommendationToolForm="ngForm" class="questionnaire-container">
                    <div *ngFor="let item of recommendationToolQuestions">
                        <span>
                            {{item.question}}
                            <span *ngIf="item.description !== ''" (mouseenter)="hoveredId = item.id"
                                (mouseleave)="hoveredId = undefined">
                                <div class="description" [class]="item.id === hoveredId ? 'hovered-description' : ''">
                                    {{item.description}}</div>
                                <img src="/assets/vectors/help.svg" alt="">
                            </span>
                        </span>
                        <br>

                        <ng-container *ngIf="item.type === 'radio'">
                            <input type="radio" [value]="item.yesResponse"
                                [(ngModel)]="recommendationToolModel[item.id]"
                                (ngModelChange)="calculateRecommendationPoints(recommendationToolForm)" [name]="item.id"
                                [id]="item.id+'Yes'"> <label class="first-label" [for]="item.id+'Yes'">Yes</label>
                            <input type="radio" [value]="item.noResponse" [(ngModel)]="recommendationToolModel[item.id]"
                                (ngModelChange)="calculateRecommendationPoints(recommendationToolForm)" [name]="item.id"
                                [id]="item.id+'No'"> <label class="second-label" [for]="item.id+'No'">No</label>
                        </ng-container>

                        <ng-container *ngIf="item.type === 'text'">
                            <input type="text" [(ngModel)]="recommendationToolModel[item.id]" [name]="item.id"
                                class="form-control">
                        </ng-container>
                    </div>
                    <h4 class="brand-1">Cumulated points</h4>
                    <div class="table-container">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>High CBD</th>
                                    <th>CBD = THC</th>
                                    <th>High THC</th>
                                    <th>Flowers</th>
                                    <th>Vaporising</th>
                                    <th>Oils</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{recommendationPoints.cbd}}</td>
                                    <td>{{recommendationPoints.balanced}}</td>
                                    <td>{{recommendationPoints.thc}}</td>
                                    <td>{{recommendationPoints.flower}}</td>
                                    <td>{{recommendationPoints.vaporize}}</td>
                                    <td>{{recommendationPoints.oil}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div bsModal #recommendationClearModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="recommendationClearModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Do you want to clear the values?</h4>
            </div>
            <div class="modal-body">
                <div style="display: flex; justify-content: flex-end; column-gap: 1rem;">
                    <button type="button" class="btn btn-danger" (click)="recommendationClearModal.hide()">No</button>
                    <button type="button" class="btn btn-primary" (click)="handleClearRecommendationToolValue()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>


<router-outlet></router-outlet>