import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/account/user.service';
import { PrescriptionService } from 'src/app/prescription.service';
import { MEDICATION_TYPE } from 'src/assets/formulary';
import {SpecialistService} from '../../specialist-orders/specialist.service';

@Component({
  selector: 'app-prescription-builder',
  templateUrl: './prescription-builder.component.html',
  styleUrls: ['./prescription-builder.component.scss'],
})
export class PrescriptionBuilderComponent implements OnInit {
  @Input() gpRecommendation: any;
  @Input() mdtRecommendation: any;
  @Input() orderId: any;
  @Output() hide = new EventEmitter();
  @Output() sendPrescription = new EventEmitter();

  // Option values
  routes: any[] = [];
  manufacturers: any[] = [];
  brands: any[] = [];
  strains: any[] = [];
  availableMedication: any[] = [];

  //prescription model
  medicationType: string = 'Medical Cannabis';
  pharmacy: number;
  category: string;
  route: string;
  manufacturer: string;
  brand: string;
  strain: string;
  additionalNotes: string;
  selectedMedication: any[] = [];

  constructor(
    protected prescriptionService: PrescriptionService,
    protected userService: UserService,
    private toastr: ToastrService,
    private specialistService: SpecialistService
  ) {}

  handleFormSubmit() {
    if (this.userService.user.settings.DOCTOR_TYPE === 'SPECIALIST') {
      this.emitPrescription();
    } else {
      this.emitRecommendation();
    }
  }

  emitPrescription() {
    if (!this.selectedMedication.length) {
      this.toastr.warning('', 'Please select atleast one medication!');
      return;
    }

    const hasUnitsZero = this.selectedMedication.some(item => item.units === 0);

    if (hasUnitsZero) {
      this.toastr.error('Set the quantity for prescribed medication. (currently set to zero)');
      return;
    }

    const changedAttrs: { [key: string]: any } = {};
    const formularyStructure = [];

    for (const item of this.selectedMedication) {
      const formulary = {
        id: item.id,
      };
      if (item.brandName !== this.mdtRecommendation?.brand) {
        changedAttrs.brand = (changedAttrs.brand || []).concat(item.brandName);
      }
      if (item.manufacture !== this.mdtRecommendation?.manufacturer) {
        changedAttrs.manufacturer = (changedAttrs.manufacturer || []).concat(
          item.manufacture
        );
      }
      if (item.strain !== this.mdtRecommendation?.strain) {
        changedAttrs.strain = (changedAttrs.strain || []).concat(item.strain);
      }
      formularyStructure.push({
        formulary,
        dosage: 'Inst',
        quantity: item.units,
        unit: 'ml',
        unlicensedUse: false,
      });
    }

    for (const key in changedAttrs) {
      changedAttrs[key] = changedAttrs[key].join();
    }

    let prescriptionChanged = false;
    let parsedAttributes = '';
    if (Object.keys(changedAttrs).length > 0) {
      prescriptionChanged = true;
      parsedAttributes = JSON.stringify(changedAttrs);
    }

    const prescription = {
      medication: formularyStructure,
      pharmacy: {
        id: this.pharmacy,
      },
      changed: prescriptionChanged,
      changes: parsedAttributes,
      additionalPatientNotes: this.additionalNotes,
    };
    this.sendPrescription.emit(prescription);
  }

  emitRecommendation() {
    if (!this.availableMedication.length) {
      this.toastr.warning(
        '',
        'Cannot add recommendation if there are no medications available!'
      );
      return;
    }
    const recommendation = {
      brand: this.brand,
      category: this.category,
      comments: this.additionalNotes,
      manufacturer: this.manufacturer,
      medicationType: this.medicationType,
      route: this.route,
      strain: this.strain,
      pharmacy: this.pharmacy,
    };

    this.sendPrescription.emit(recommendation);
    this.hide.emit();
  }

  ngOnInit(): void {
    let selectedRecommendation: any
    if (this.orderId !== null) {
      this.specialistService.getPrescription(this.orderId).subscribe((prescription) => {
        if (prescription.medication.length > 0) {
          prescription.medication.forEach((item: any): void => {
            const medicine = {
              active: item.formulary.active,
              administrationRoute: item.formulary.administrationRoute,
              availQuantity: item.formulary.availQuantity,
              benifits: item.formulary.benifits,
              brandName: item.formulary.brandName,
              buyPrice: item.formulary.buyPrice,
              category: item.formulary.category,
              id: item.formulary.id,
              manufacture: item.formulary.manufacture,
              medicineProfile: item.formulary.medicineProfile,
              originCountry: item.formulary.originCountry,
              originalStrain: item.formulary.originalStrain,
              pharmacyId: item.formulary.pharmacyId,
              productName: item.formulary.productName,
              productionNote: item.formulary.productionNote,
              quantity: item.formulary.quantity,
              salePrice: item.formulary.salePrice,
              stockStatus: item.formulary.stockStatus,
              strain: item.formulary.strain,
              stringId: item.formulary.stringId,
              unitPrice: item.formulary.unitPrice,
              units: item.quantity
            };
            this.selectedMedication.push(medicine);
          });
        }
        this.pharmacy = prescription.pharmacy.id;
        this.additionalNotes = prescription.additionalPatientNotes;
      });
    } else if (this.userService.user.settings.DOCTOR_TYPE === 'SPECIALIST') {
      selectedRecommendation = this.mdtRecommendation;
    } else {
      selectedRecommendation = this.gpRecommendation;
    }

    if (selectedRecommendation) {
      this.pharmacy = selectedRecommendation?.pharmacy;
      this.category = selectedRecommendation?.category;
      this.route = selectedRecommendation?.route;
      this.manufacturer = selectedRecommendation?.manufacturer;
      this.brand = selectedRecommendation?.brand;
      this.strain = selectedRecommendation?.strain;
      this.additionalNotes = selectedRecommendation?.comments;
      this.handlePrescriptionValueChange();
    }
  }

  onMedicationQTYChange($event, index, item) {
    if (!$event.target.value || $event.target.value < 1) {
      item.units = 0;
      const cachedMedication = this.selectedMedication;
      cachedMedication[index] = item;
      this.selectedMedication = [];
      setTimeout(() => {
        this.selectedMedication = cachedMedication;
      }, 10);

      return;
    }

    item.units = $event.target.value;
    this.selectedMedication[index] = item;
  }

  handleAddToSelectedMedications(index: number, item: any) {
    item.units = 0;
    this.selectedMedication.push(item);

    this.availableMedication[index] = {...item, selected: true};
  }

  handleRemoveSelectedMedication(index: number, item: any) {
    item.selected = false;
    item.units = 0;
    this.selectedMedication.splice(index, 1);
    for (let i = 0; i < this.availableMedication.length; i++) {
      const medication = this.availableMedication[i];
      if (medication.id === item.id) {
        this.availableMedication[i] = item;
        break;
      }
    }
  }

  handlePharmacyValueChange() {
    this.selectedMedication = [];
    this.availableMedication =
      this.prescriptionService.getAvailableMedications({
        administrationRoute: this.route,
        brandName: this.brand,
        category: this.category,
        manufacture: this.manufacturer,
        pharmacyId: this.pharmacy,
        strain: this.strain,
      });
  }

  handlePrescriptionValueChange() {
    if (
      this.userService.user.settings.DOCTOR_TYPE === 'SPECIALIST' &&
      !this.pharmacy
    ) {
      return;
    }
    this.availableMedication =
      this.prescriptionService.getAvailableMedications({
        administrationRoute: this.route,
        brandName: this.brand,
        category: this.category,
        manufacture: this.manufacturer,
        pharmacyId: this.pharmacy,
        strain: this.strain,
      });
  }

  getMedicationType() {
    return MEDICATION_TYPE;
  }

  // disable scroll issue of medicine QTY Filed
  // Ticket: CR-213
  // Author: Sarmilan
  disableQTYScroll(event: WheelEvent): void {
    event.preventDefault();
  }

  // disable scroll with keyboard issue of medicine QTY Filed
  // Ticket: CR-213
  // Author: Sarmilan
  disableUpDownArrows(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  }
}
