<div bsModal #pinkPrescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="pinkPrescriptionModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Pink prescription</h4>
            </div>
            <div class="modal-body">
                <div *ngIf="!!prescriptions.documents.length" class="documents-container">
                    <a *ngFor="let item of prescriptions.documents" class="file-item" [href]="item.url" target="_blank"
                        rel="noopener noreferrer">
                        <img src="/assets/vectors/pdf-icon.svg" alt="">
                        <span>Prescription Document</span>
                    </a>
                </div>
                <ng-container *ngIf="!!prescriptions.images.length">
                    <div *ngFor="let item of prescriptions.images">
                        <div class="document-name-container">
                            <h4>{{item.originalFileName}}</h4>
                            <!-- <span *ngIf="!!item.reference"><b>(Ref No: {{item.reference}})</b></span> -->
                        </div>
                        <div class="file-container">
                            <img [src]="item.url" alt="">
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>