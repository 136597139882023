import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-admin-key-alert-modal',
  templateUrl: './admin-key-alert-modal.component.html',
  styleUrls: ['./admin-key-alert-modal.component.scss']
})
export class AdminKeyAlertModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() patient: any;
  @ViewChild('keyAlertModal') modal: ModalDirective;

  private modalEventSubscription: Subscription;

  protected editNote = false;
  protected alertText = '';
  protected isLoading = false;

  constructor(private modalService: ModalService, private dataService: DataService) {}

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === 'keyAlertModal') {
        this.modal.show();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (!!this.patient.settings?.ADMIN_ALERTS) {
        this.alertText = this.patient.settings.ADMIN_ALERTS
      }
    }
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }

  private convertKeysToStrings(obj) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[String(`${key}`)] = String(value);
    }
    return newObj;
  }

  async handlePatientAlerts(state: boolean){
    if (!state && !!this.alertText) {
      this.isLoading = true;
      const patientPayload = {...this.patient}
      const preferencesPayload = this.convertKeysToStrings(this.patient.preferences);
      const settingsPayload = this.convertKeysToStrings(this.patient.settings);
      patientPayload.preferences = preferencesPayload;
      patientPayload.settings = settingsPayload;
      patientPayload.settings['ADMIN_ALERTS'] = this.alertText;
      const result = await lastValueFrom(this.dataService.updatePatientSettings(patientPayload));
      this.isLoading = false;
    } 

    this.editNote = state;
  }

}
