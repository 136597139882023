<div bsModal #consultationDocuments="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="consultationDocuments.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Consultation Documents</h4>
        </div>
        <div class="modal-body patient-files-container">
          <ng-container *ngIf="consultationFiles.images || consultationFiles.documents">
            <ng-container *ngIf="consultationFiles.documents">
              <div class="documents-container">
                <button *ngFor="let item of consultationFiles.documents" class="btn btn-default file-item"
                  (click)="handleOpenPDF(item)">
                  <img src="/assets/vectors/pdf-icon.svg" alt="">
                  <span>Document</span>
                </button>
              </div>
            </ng-container>
  
            <ng-container *ngIf="consultationFiles.images">
              <div *ngFor="let item of consultationFiles.images">
                <div class="file-container">
                  <img [src]="item" alt="" width="100%">
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>