
<div class="container" *ngIf="referralLetter && doctorNote && order && patient">

	<div class="consultation_note">
		<div class="row">
			<div class="col-md-6">
				<h1 class="brand-1 u-pull-left">Referral Letter</h1>
			</div>
			<div class="col-md-6">
                <button (click)="print()" class="pull-right btn btn-default btn-print">Print</button>
			</div>
		</div>

		<h3>Appointment date: {{doctorNote.appointment.datetime | date : 'dd/MM/yyyy'}} </h3>
		<div class="widgets widgets--2">
			<div class="widget widget--grey  widget--grey_border">
				<div class="inner">
					<h3>Order number</h3>
					<p>{{order.id}}</p>

					<h3>Referring doctor details</h3>
					<p>Name: {{order.doctor.displayName}} <br /> GMC No: {{order.doctor.gmc}}</p>

				</div>
			</div>

			<div class="widget widget--grey_border widget--white">
				<div class="inner">
					<h3>Patient details</h3>
					{{patient.displayName}} | {{patient.dateOfBirth | date : 'dd/MM/yyyy'}} | {{patient.email}} <br />
					<p>
						{{patient.address.line1}}<br *ngIf="patient.address.line1" /> {{patient.address.line2}}
						<br *ngIf="patient.address.line2" /> {{patient.address.city}}
						<br *ngIf="patient.address.city" /> {{patient.address.county}}
						<br *ngIf="patient.address.county" /> {{patient.address.postcode}}
					</p>
				</div>
			</div>
		</div>

		<div class="widget widget--grey_border widget--white" *ngIf="doctorNote">
			<h3>Doctor notes</h3>
			<h4>Relevant past medical history:</h4>
			<p>{{doctorNote.details.relevantPastMedicalHistory}}</p>

			<h4>Relevant Past Family History:</h4>
			<p>{{doctorNote.details.relevantPastFamilyHistory}}</p>

			<h4>Medication History (Past and Present):</h4>
			<p>{{doctorNote.details.medicationHistory}}</p>

			<h4>Allergy Information/Previous adverse reactions to medicine:</h4>
			<p>{{doctorNote.details.allergyInformation}}</p>

			<h4>NHS GP Details: </h4>
			<pre>{{doctorNote.details.nhsGPDetails}}</pre>

			<h4>Presenting Complaint</h4>
			<p>{{doctorNote.details.presentingComplaint}}</p>

			<h4>Differential diagnosis</h4>
			<p>{{doctorNote.details.differentialDiagnosis}}</p>

			<h4>Recommended Actions:</h4>
			<p>{{doctorNote.details.recommendedActions}}</p>

			<h4>Medicines Prescribed:</h4>
			<table *ngIf="doctorNote.medicinesPrescribedParsed" class="table">
				<thead>
					<tr>
						<th>Medication</th>
						<th>Formulation</th>
						<th>Strength</th>
						<th>Dosage</th>
						<th>Quantity</th>
						<th>Unit</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let medicine of doctorNote.medicinesPrescribedParsed">
						<td>{{medicine.formulary.name}}</td>
						<td>{{medicine.formulary.formulation}}</td>
						<td>{{medicine.formulary.strength}}</td>
						<td>{{medicine.dosage}}</td>
						<td>{{medicine.quantity}}</td>
						<td>{{medicine.unit ? medicine.unit : "N/A"}}</td>
					</tr>
				</tbody>
			</table>
			<p *ngIf="!doctorNote.medicinesPrescribedParsed">None</p>

			<h4>Reason for Referral:</h4>
			<p>{{referralLetter.data.reason}}</p>

			<h4>Referral Specialty:</h4>
			<p>{{referralLetter.data.speciality}}</p>

			<h4 *ngIf="doctorNote.additionalNotes">Notes for patient record:</h4>
			<p>{{doctorNote.details.additionalNotes}}</p>

		</div>

		<div class="widget widget--grey_border widget--white">
			<div class="col col-md-6">
				   <div class="form-group">
                    <label>Signature:</label>
                    <textarea readonly class="form-control" rows="3"></textarea>
                </div>
			</div>
			<div class="col col-md-6">

				<p><strong>Doctor name:</strong></p>
				<p>
					{{order.doctor.displayName}}</p>
				<p>GMC Number: {{order.doctor.gmc}}</p>

			</div>
		</div>

	</div>
</div>
