import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConditionsService {

    private data = [
        'Abdominal epilepsy',
        'Abdominal migraines',
        'Abdominal pain',
        'Abnormal heart rhythms and ICD',
        'Abnormal vaginal bleeding',
        'Abscess',
        'Abscessed tooth',
        'Absence seizures',
        'Achilles tendon injury',
        'Acid reflux',
        'ACL tear / labral tear',
        'Acne',
        'Acrocyanosis',
        'Actinic keratosis (solar keratosis)',
        'Acute lymphoblastic leukaemia',
        'Acute myeloid leukaemia',
        'Acute sinusitis',
        'ADD/ADHD',
        'Addison\'s disease',
        'Age-related macular degeneration (AMD)',
        'AIDS/HIV',
        'Alcohol addiction',
        'Alcohol poisoning',
        'Alcoholic liver disease (ALD)',
        'Allergic asthma',
        'Allergic rhinitis',
        'Allergies',
        'Allergy symptoms',
        'Alzheimer\'s disease',
        'Amblyopia',
        'Amenorrhoea',
        'Amoebic dysentery',
        'Amyotrophic lateral sclerosis (ALS)',
        'Anaemia, iron deficiency',
        'Anaemia, pernicious',
        'Anal abscess',
        'Anal cancer',
        'Anal fistula',
        'Anal tears',
        'Aneurysm',
        'Angina',
        'Angina, unstable',
        'Ankles, swollen',
        'Ankylosing spondylitis',
        'Anorectal abscess',
        'Anorexia nervosa',
        'Anxiety / panic',
        'Anxiety symptoms',
        'Aortic aneurysm',
        'Aphasia',
        'Appendicitis',
        'Apraxia',
        'Arachnoiditis',
        'Arrhythmia',
        'Arthritis',
        'Asperger syndrome',
        'Asthma',
        'Asthma Attack',
        'Astigmatism',
        'Ataxia',
        'Atherosclerosis',
        'Atherosclerosis and stroke',
        'Athlete\'s foot',
        'Atopic eczema',
        'Atrial flutter',
        'Attention deficit hyperactivity disorder (ADHD)',
        'Atypical depression',
        'Autism',
        'Back pain',
        'Back pain symptoms',
        'Bacterial vaginosis',
        'Bad breath',
        'Baker’s cyst',
        'Basal cell carcinoma',
        'Behçet’s disease',
        'Bell\'s palsy',
        'Bipolar disorder',
        'Bipolar disorder and sleep problems',
        'Bipolar disorder symptoms',
        'Bipolar disorder treatments',
        'Bipolar disorder, mixed',
        'Bird flu',
        'Bladder cancer',
        'Bladder infection',
        'Bladder pain',
        'Bladder prolapse',
        'Bladder spasms',
        'Bladder stones',
        'Bleeding in the digestive tract',
        'Bleeding varices',
        'Blepharitis',
        'Blighted ovum',
        'Blisters',
        'Bloating',
        'Blood clot in leg',
        'Blood in semen',
        'Blood in stool',
        'Blood in urine',
        'Blurred vision',
        'Body dysmorphic disorder',
        'Bone fractures',
        'Bowel cancer',
        'Bowel obstruction',
        'Bradycardia',
        'Breathing problems',
        'Broken finger',
        'Broken foot',
        'Broken nose',
        'Broken toe',
        'Broken tooth',
        'Broken wrist',
        'Bronchiolitis',
        'Bronchitis',
        'Bronchitis symptoms',
        'Bruises',
        'Bulimia nervosa',
        'Burns and scalds',
        'Candidiasis (yeast infection)',
        'Cardiac arrest',
        'Cardiomyopathy',
        'Cardiovascular diseases',
        'Carotid artery disease',
        'Carpal tunnel syndrome',
        'Cat allergies',
        'Cataracts',
        'Cellulite',
        'Cellulitis',
        'Central pain syndrome',
        'Cerebral palsy',
        'Chest Pain',
        'Chickenpox',
        'Chilblains',
        'Chlamydia',
        'Chronic bronchitis',
        'Chronic fatigue syndrome symptoms',
        'Chronic obstructive pulmonary disease',
        'Chronic pain and depression',
        'Chronic pelvic pain',
        'Churg Strauss vasculitis',
        'Cirrhosis of the liver',
        'Cleft lip and cleft palate',
        'Cluster headaches',
        'Coeliac disease',
        'Cold and cough',
        'Cold and flu',
        'Cold sores',
        'Colic',
        'Colitis symptoms',
        'Congestive heart failure',
        'Conjunctivitis',
        'Constipation',
        'COPD',
        'Cough',
        'Cramps, menstrual',
        'Crohn\'s disease',
        'Croup',
        'Cushing\'s syndrome',
        'Cyclothymia',
        'Cyclothymia (cyclothymic disorder)',
        'Cystic fibrosis',
        'Cystitis',
        'Cysts',
        'Cytomegalovirus',
        'Dandruff',
        'Deep vein thrombosis',
        'Delusional disorder',
        'Dementia',
        'Dengue fever',
        'Dental abscess',
        'Dental pain',
        'Depression',
        'Depression and chronic pain',
        'Dermatitis',
        'Dermatitis, contact',
        'Dermatitis, perioral',
        'Dermatitis, seborrhoeic',
        'Dermoid cyst',
        'Diabetes',
        'Diabetes and high blood pressure',
        'Diabetes diet, type 1',
        'Diabetes diet, type 2',
        'Diabetes mellitus',
        'Diabetes, Type 1',
        'Diabetes, Type 2',
        'Diabetic retinopathy',
        'Diarrhoea',
        'Dissociative fugue (amnesia)',
        'Dissociative identity disorder (multiple personality disorder)',
        'Dizziness',
        'Double vision (diplopia)',
        'Dry skin',
        'Dust allergy',
        'DVT',
        'Dystonia',
        'Dysuria (painful urination)',
        'Ear infection',
        'Earache',
        'Earwax',
        'Eclampsia',
        'Eczema',
        'Elbow pain',
        'Emphysema',
        'Encephalitis',
        'Endometriosis',
        'Enlarged prostate',
        'Enlarged spleen',
        'Enuresis',
        'Epilepsy',
        'Erectile dysfunction',
        'Erectile dysfunction (ED) and diabetes',
        'Erectile dysfunction (ED) physical causes',
        'Erectile dysfunction, psychological causes',
        'Essential tremor',
        'Excessive sweating',
        'Eye allergies',
        'Eye floaters',
        'Eye pain',
        'Eye problems',
        'Eye strain',
        'Eye, foreign body',
        'Faecal incontinence',
        'Fainting',
        'Febrile convulsions',
        'Feet, swollen',
        'Feet, tingling',
        'Female incontinence',
        'Fever',
        'Fibrocystic breast disease',
        'Fibroids',
        'Fibromyalgia',
        'Fissured tongue',
        'Flu',
        'Food poisoning',
        'Foot pain',
        'Frostbite',
        'Fungal nail infection',
        'Fungal skin infections',
        'Glaucoma',
        'Glaucoma symptoms',
        'Glaucoma, acute primary angle-closure',
        'Glaucoma, normal-tension',
        'Glaucoma, secondary and angle recession',
        'Glucosamine',
        'Glucose tolerance test',
        'Glue ear',
        'Goals, how to reach',
        'Goji berries',
        'Goldenseal',
        'Golfer’s elbow',
        'Gonorrhoea',
        'GORD',
        'GORD symptoms',
        'GORD treatments',
        'Gout',
        'Grandparent tips',
        'Grandparents, long-distance',
        'Granuloma annulare',
        'Grape seed extract',
        'Grapefruit Diet',
        'Graves\' disease',
        'Green coffee beans',
        'Grief',
        'Groin strain',
        'Grommets',
        'Group therapy',
        'Growing pains',
        'Guarana',
        'Gum problems',
        'Gum tissue grafts',
        'H.pylori infection',
        'Haematospermia',
        'Haematuria',
        'Haemophilia',
        'Haemorrhoids',
        'Haemorrhoids, surgery',
        'Hair dye',
        'Hair loss',
        'Hair loss in children',
        'Hair loss prevention',
        'Hair loss symptoms',
        'Hair loss treatments',
        'Hair removal, laser',
        'Hair removal, women',
        'Hair transplants',
        'Hair, picture of',
        'Hair, washing',
        'Hammer toes',
        'Hamstring strain',
        'Hand osteoarthritis',
        'Hand, foot and mouth disease',
        'Handball',
        'Handcycling',
        'Hands, tingling',
        'Harlequin ichthyosis',
        'Hashimoto\'s thyroiditis',
        'Hayfever',
        'HbA1c blood test',
        'hCG weight loss',
        'HDL cholesterol',
        'Head lice',
        'Headache in children',
        'Headache types',
        'Headache, chronic tension',
        'Headaches',
        'Headaches and hormones',
        'Headaches, treatment',
        'Health insurance, dental',
        'Health tourism',
        'Healthy ageing',
        'Healthy beauty',
        'Healthy eating',
        'Healthy weight gain',
        'Hearing aids',
        'Hearing loss',
        'Hearing loss, finding help',
        'Hearing tests',
        'Hearing-impaired children',
        'Heart anatomy',
        'Heart attack',
        'Heart attack symptoms',
        'Heart attack symptoms for women',
        'Heart disease',
        'Heart disease and ED',
        'Heart disease drugs',
        'Heart disease medication',
        'Heart disease symptoms',
        'Heart disease tests',
        'Heart disease, women',
        'Heart facts and fiction',
        'Heart failure',
        'Heart failure symptoms',
        'Heart failure treatment',
        'Heart failure, diet',
        'Heart failure, living with',
        'Heart healthy foods',
        'Heart murmurs',
        'Heart surgery recovery',
        'Heart transplant',
        'Heart valve disease',
        'Heart-healthy diet results',
        'Heartburn at night, relief',
        'Heartburn at night, risks',
        'Heartburn or GORD?',
        'Heartburn organisations',
        'Heartburn over-the-counter treatments',
        'Heartburn surgery',
        'Heartburn triggers',
        'Heartburn, foods to avoid',
        'Heartburn, home remedies',
        'Heartburn, lifestyle changes',
        'Heartburn, uncontrolled',
        'Heartburn/GORD',
        'Heat exhaustion',
        'Heat rash',
        'Heatstroke prevention',
        'Heatstroke symptoms',
        'Heavy periods',
        'Heel spurs',
        'Helen Scott',
        'Henoch-Schonlein purpura (HSP)',
        'Hepatitis',
        'Hepatitis A',
        'Hepatitis A and B vaccines',
        'Hepatitis B',
        'Hepatitis C',
        'Hepatitis C treatment',
        'Hepatitis vaccines',
        'Herbal remedies',
        'Herbal remedies for enlarged prostate/BPH',
        'Hereditary spastic paraplegia',
        'Hernia',
        'Herpes simplex eye infection',
        'Herpes, cold sores',
        'Herpes, genital',
        'HGH for weight loss',
        'Hiatus hernia',
        'Hiatus hernia diet tips',
        'Hibiscus',
        'Hidradenitis suppurativa (HSP)',
        'High blood pressure',
        'High blood pressure and ED',
        'High blood pressure and kidney disease',
        'High blood pressure in children',
        'High blood pressure prevention',
        'High blood pressure symptoms',
        'High blood pressure tests',
        'High blood pressure tips',
        'High blood pressure treatments',
        'High blood sugar',
        'High cholesterol',
        'High potassium in blood',
        'High-tech diet',
        'Hip fracture',
        'Hip pain',
        'Hip replacement',
        'Hip-waist ratio',
        'Hirsutism',
        'Histrionic personality disorder',
        'HIV positive, what now?',
        'HIV risk',
        'HIV vaccines',
        'HIV/AIDS',
        'Hives',
        'Hodgkin\'s disease',
        'Horny goat weed',
        'Hot flushes',
        'HPV/Genital warts',
        'Huntington\'s disease',
        'Hyperemesis gravidarum',
        'Hyperglycaemia',
        'Hyperhidrosis (excessive sweating)',
        'Hyperkalaemia',
        'Hyperpigmentation and hypopigmentation',
        'Hypertension',
        'Hyperthyroidism',
        'Hypochondria',
        'Hypoglycaemia',
        'Hypokalaemia',
        'Hyponatraemia',
        'Hypothermia',
        'Hypothyroidism',
        'IBS',
        'Impetigo',
        'Impingement syndrome',
        'Incontinence',
        'Indigestion',
        'Inflammation of the testicle (orchitis)',
        'Inflammatory bowel disease',
        'Influenza',
        'Ingrown hair',
        'Ingrown toenail',
        'Inguinal hernia',
        'Insect bites',
        'Insomnia',
        'Interstitial cystitis',
        'Iron deficiency anaemia',
        'Irregular heartbeat',
        'Irritable bowel syndrome (IBS)',
        'Ischaemia',
        'Jaundice',
        'Jaw pain',
        'Jetlag',
        'Joint pain',
        'Ketoacidosis',
        'Kidney disease',
        'Kidney infection',
        'Kidney infection symptoms',
        'Kidney stones',
        'Knee ligament injuries',
        'Knee osteoarthritis',
        'Knee osteoarthritis, non-surgical treatment',
        'Knee pain',
        'Leg ulcers',
        'Legionnaires\' disease',
        'Leprosy',
        'Liver biopsy',
        'Loss of appetite',
        'Lyme disease',
        'Malaria',
        'Measles',
        'Memory loss',
        'Ménière\'s disease',
        'Meningitis symptoms',
        'Meniscus tear',
        'Migraine headaches',
        'Molluscum contagiosum',
        'Morning sickness',
        'Motion sickness',
        'Mouth ulcers',
        'MS',
        'Multiple sclerosis',
        'Mumps',
        'Munchausen\'s syndrome',
        'Muscle building foods for men',
        'Muscle spasms',
        'Muscle spasms and cramps',
        'Muscle strain',
        'Muscular dystrophy',
        'Myasthenia gravis',
        'Myofascial pain',
        'Myofascial pain syndrome',
        'Myopia',
        'Nail infection',
        'Nail infection, fungal',
        'Narcolepsy',
        'Nausea & headaches',
        'Neuralgia, postherpetic',
        'Neuroblastoma',
        'Neurofibromatosis',
        'Night sweats',
        'Non-Hodgkin\'s lymphoma',
        'Nosebleeds',
        'Obesity',
        'Obsessive compulsive disorder (OCD)',
        'Obstructive sleep apnoea syndrome',
        'Oesophagitis',
        'Oral herpes',
        'Oral thrush',
        'Osteoarthritis symptoms',
        'Osteoarthritis, spinal',
        'Osteopenia',
        'Osteoporosis pain',
        'Otitis media',
        'Ovarian cysts',
        'Overactive bladder',
        'Overactive thyroid (hyperthyroid)',
        'Palpitations',
        'Pancreatitis, acute',
        'Pancreatitis, chronic',
        'Panic attacks',
        'Panic disorder',
        'Panic/Anxiety disorders',
        'Paralysis',
        'Parkinson\'s disease',
        'Paronychia (nail infection)',
        'Pelvic pain',
        'Peripheral neuropathy',
        'Peripheral vascular disease',
        'Peritonitis',
        'Pertussis (whooping cough)',
        'Peyronie’s disease',
        'PGP (pelvic girdle pain)',
        'Phlebitis',
        'Plantar warts',
        'PMS (pre menstrual syndrome)',
        'Pneumonia',
        'Poor circulation',
        'Portal hypertension',
        'Post-traumatic stress disorder',
        'Premenstrual syndrome (PMS)',
        'Pressure sores',
        'Prostate, enlarged',
        'Psoriasis',
        'Psoriatic arthritis',
        'PTSD',
        'Pubic lice',
        'Puffy eyes',
        'Rash',
        'Receding gums',
        'Rectal bleeding',
        'Rectal pain',
        'Restless legs syndrome',
        'Retinal detachment',
        'Retinoblastoma',
        'Rheumatic fever',
        'Rheumatoid arthritis',
        'Rheumatoid arthritis, hand and finger',
        'Rheumatoid arthritis, hip',
        'Rheumatoid arthritis, knee',
        'Rhinoplasty',
        'Ridges in fingernails',
        'Ringing in the ears',
        'Ringworm',
        'Rosacea',
        'Rubella',
        'Salivary gland problems',
        'Sarcoidosis',
        'Scabies',
        'Scarlet fever',
        'Schizophrenia',
        'Sciatica symptoms',
        'Scleritis',
        'Sclerotherapy',
        'Seasonal affective disorder (SAD)',
        'Seasonal allergies',
        'Shin splints',
        'Shingles',
        'Shoulder pain',
        'Sickle cell disease',
        'Sinusitis and asthma',
        'Skin cysts',
        'Skin rash',
        'Slapped cheek syndrome',
        'Sleep apnoea',
        'Sleep disorders',
        'Sleep problems',
        'Sleepwalking',
        'Smallpox',
        'Smell, loss of (anosmia)',
        'Snoring',
        'Social anxiety disorder',
        'Sore throat',
        'Spinal disc problems',
        'Spinal fracture',
        'Spinal headaches',
        'Sprained ankle',
        'Squint (strabismus)',
        'St John\'s wort',
        'Staph infection',
        'Stomach flu',
        'Stomach ulcer symptoms',
        'Stomach ulcers',
        'Stool colour changes',
        'Strained muscles',
        'Stress fractures',
        'Stress headache',
        'Stress incontinence',
        'Stress symptoms',
        'Stroke',
        'Stroke symptoms',
        'Stye',
        'Sun damaged skin',
        'Sunburn',
        'Swallowing problems',
        'Swine flu',
        'Swollen ankles and feet',
        'Swollen eyes',
        'Swollen feet and ankles, pregnancy',
        'Swollen glands',
        'Swollen lips',
        'Swollen testicles',
        'Syphilis',
        'Tapeworms',
        'Taste, loss of (ageusia)',
        'Temporal arteritis',
        'Tendonitis',
        'Tennis elbow',
        'Tension type headache',
        'Testicular disorder',
        'Thalassaemia',
        'Threadworms',
        'Thrombophlebitis',
        'Thrush, mouth',
        'Thrush, vagina',
        'Ticks',
        'Tingling, hands and feet',
        'Tinnitus',
        'Tonsillitis',
        'Tuberculosis',
        'Type 1 diabetes',
        'Type 2 diabetes',
        'Typhoid fever',
        'Ulcerative colitis',
        'Ulcers, leg',
        'Ulcers, stomach',
        'Underactive thyroid',
        'Urinary tract infection',
        'Urination, painful',
        'Vaginal discharge',
        'Vaginal dryness',
        'Vaginitis',
        'Varicose veins',
        'Vasculitis',
        'Verrucas (plantar warts)',
        'Vertigo',
        'Vertigo migraine',
        'Viral hepatitis',
        'Viral meningitis',
        'Vision loss',
        'Warts',
        'Wasp stings',
        'Weakness',
        'Wheezing',
        'Whiplash',
        'Whooping cough (pertussis)',
        'Wrist sprain',
        'Yeast infection',
        'Yellow fever',
        'Zollinger-Ellison syndrome',
        'Zoster (herpes) virus',
        'Other'
    ];

    getConditions() {
        return of(this.data);
    }
}
