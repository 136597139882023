
<div class="container" *ngIf="fitNote && doctorNote && order && patient">

    <div class="consultation_note">
        <div class="row">
            <div class="col-md-12">
                <h1 class="brand-1 pull-left">Statement of fitness for work</h1>
         <button (click)="print()" class="pull-right btn btn-default btn-print">Print</button>
            </div>
        </div>
        <div class="widgets widgets--2">
            <div class="widget widget--grey  widget--grey_border">
                <div class="inner">
                    <h3>Order number</h3>
                    <p>{{order.id}}</p>

                    <h3>Referring doctor details</h3>
                    <p>Name: {{order.doctor.displayName}} <br /> GMC No: {{order.doctor.gmc}}</p>

                </div>
            </div>

            <div class="widget widget--grey_border">
                <div class="inner">
                    <h3>Patient details</h3>
                    {{patient.displayName}}  <br *ngIf="patient.dateOfBirth" />{{patient.dateOfBirth | date : 'dd/MM/yyyy'}}  <br *ngIf="patient.email" /> {{patient.email}} <br />
                    <p>
                        {{patient.address.line1}}<br *ngIf="patient.address.line1" /> {{patient.address.line2}}
                        <br *ngIf="patient.address.line2" /> {{patient.address.city}}
                        <br *ngIf="patient.address.city" /> {{patient.address.county}}
                        <br *ngIf="patient.address.county" /> {{patient.address.postcode}}
                    </p>
                </div>
            </div>
        </div>

        <div class="widget widget--grey_border well--white">
            <h3>Doctor notes</h3>
            <p>I assessed your case on <strong>{{fitNote.creationDate | date : 'dd/MM/yyyy'}}</strong> and, because of the following
                condition(s): <strong>{{fitNote.differentialDiagnosis}}</strong>.

				<span *ngIf="doctorNote.details.shareDetailsWithGp">
					<h4>NHS GP Details: </h4>
					<pre>{{doctorNote.details?.nhsGPDetails}}</pre>
				</span>

                <div class="form_group">
                    <h4>I advise you that</h4>
                    <div class="form_control" *ngIf="!fitNote.fit">
                        <input type="checkbox" disabled checked/><label>You are not fit for work</label>
                    </div>
                    <div class="form_control" *ngIf="fitNote.fit">
                        <input type="checkbox" disabled checked /><label>You maybe fit for work taking account of the following advice:</label>
                    </div>
                </div>

                <div class="form_group">
                    <h4>If available, and with your employer's agreement, you may benefit from:</h4>
                    <div class="row">
                        <div class="col col-md-12">
                            <div class="form_control form_control--inline">
                                <input type="checkbox" [checked]="fitNote.phasedReturn" disabled /><label>A phased return to work</label>
                            </div>
                            <div class="form_control form_control--inline">
                                <input type="checkbox" disabled [checked]="fitNote.amendedDuties" /><label>Amended duties</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-md-12">
                            <div class="form_control form_control--inline">
                                <input type="checkbox" disabled [checked]="fitNote.alteredHours" /><label>Altered hours</label>
                            </div>
                            <div class="form_control form_control--inline">
                                <input type="checkbox" disabled [checked]="fitNote.workplaceAdapt" /><label>workplace adaptions</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="fitNote.expirationDate">
                  <p>This will be the case for <strong>{{daysValid}} days</strong> from <strong>{{fitNote.fromDate}}</strong> to <strong>{{fitNote.expirationDate}}</strong>.</p>
                </div>

                <p *ngIf="!fitNote.reassessmentNeeded">I will not need to assess your fitness for work again at the end of this period.</p>

                <div class="form_group">
					<label> Comments, including functional effects of your condition(s):</label>
					<p>{{fitNote?.conditionNotes}}</p>
				</div>
        </div>

        <div class="widget widget--grey_border well--white">
            <div class="col col-md-6">
                <div class="form-group">
                    <label>Signature:</label>
                    <textarea readonly class="form-control" rows="3"></textarea>
                </div>
            </div>
            <div class="col col-md-6">
               	<p><strong>Doctor name:</strong></p>
				<p>{{order.doctor.displayName}}</p>
				<p>{{order.doctor.settings.QUALIFICATIONS}}</p>
	            <p>GMC Number: {{order.doctor.gmc}}</p>
            </div>

        </div>

    </div>
</div>
