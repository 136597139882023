import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { UserService } from '../../account/user.service';
import { PharmacyService } from '../pharmacy.service';
import { AddPrescriptionComponent } from 'src/app/appointments/add-prescription.component';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { filter, take } from 'rxjs/operators';

interface Address {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postcode: string;
}
interface Patient {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  address: Address;
}

interface Pet {
  id?: number;
  name: string;
  species: string;
  owner: { id: number };
  address: Address;
}

interface Pharmacy {
  id: number;
  displayName: string;
  address: Address;
}

enum DoctorType {
  REGULAR_DOCTOR,
  COMPANY_DOCTOR,
  COMPANY_VET,
  COMPANY_PRESCRIBING_PHARMACIST,
  PRESCRIBING_DOCTOR,
  SURGERY_ADMIN
}

@Component({
  selector: 'app-create-prescription',
  templateUrl: './create-prescription.component.html',
  styleUrls: ['./create-prescription.component.scss']
})
export class CreatePrescriptionComponent implements OnInit {

  @Input() public forPet: boolean = false;
  @Output() public action = new EventEmitter<any>();

  public existingPatients: Patient[];
  public existingPets: Pet[];
  public selectedPatientIndex: number;
  public patient: Patient;
  public pet: Pet;
  public pharmacy: Pharmacy;
  public submitting: boolean;
  public submittingMessage: string = 'Please wait';

  public isExistingPatient: boolean;
  public isExistingPet: boolean;
  public step: number;
  public emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  public dobRegex = /^\d{1,2}-\d{1,2}-\d{4}$/i;
  public isSearchingForPatients: boolean = false;
  public isSearchingForPets: boolean = false;
  public doctorType: DoctorType;
  public DoctorType = DoctorType;

  public modalOptions: ModalOptions = {
    ignoreBackdropClick: true,
    keyboard: false,
    backdrop: false
  }

  private PLACEHOLDER_ADDRESS: Address = { line1: '', line2: '', city: '', county: '', postcode: '' }
  private PLACEHOLDER_PATIENT: Patient = { id: 1, firstName: '', lastName: '', dateOfBirth: '', email: '', phone: '', address: { ...this.PLACEHOLDER_ADDRESS } }
  private PLACEHOLDER_PET: Pet = { id: 1, name: '', species: '', owner: { id: null }, address: { ...this.PLACEHOLDER_ADDRESS } }

  @ViewChild('addPrescriptionComponent') public addPrescriptionComponent: AddPrescriptionComponent;
  @ViewChild('confirmUserModal') public confirmUserModal: ModalDirective;

  constructor(
    private pharmacyService: PharmacyService,
    private userService: UserService
  ) { }

  initState() {
    this.step = 1;
    if (this.isQuickSilvaDoctor() && sessionStorage.getItem('patient-subject')) {
      this.patient = JSON.parse(sessionStorage.getItem('patient-subject'));
      this.isExistingPatient = true;
      this.selectedPatientIndex = null;
      // this.patient.dateOfBirth = moment(this.patient.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY');
    } else {
      this.patient = { ...this.PLACEHOLDER_PATIENT }
      this.isExistingPatient = false;
    }

    this.pet = { ...this.PLACEHOLDER_PET }
    this.pharmacy = null;
    this.existingPatients = [];
    this.existingPets = [];
    this.selectedPatientIndex = null;
    this.selectedPatientIndex = null;
    
    this.isExistingPet = false;
    this.submitting = false;
    if (this.addPrescriptionComponent) {
      this.addPrescriptionComponent.initState();
    }
  }

  ngOnInit(): void {
    this.userService.user$.pipe(filter(x => x != null), take(1)).subscribe(user => {
      this.doctorType = (user?.prescriptionCompany?.id == null) ? DoctorType.REGULAR_DOCTOR
          : user?.settings?.DOCTOR_TYPE == 'VET' ? DoctorType.COMPANY_VET
          : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST' ? DoctorType.COMPANY_PRESCRIBING_PHARMACIST
          : user?.settings?.DOCTOR_TYPE == 'SURGERY_ADMIN' ? DoctorType.SURGERY_ADMIN
          : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_DOCTOR' ? DoctorType.PRESCRIBING_DOCTOR
          : DoctorType.COMPANY_DOCTOR;
  })

    this.initState();
  }

  goBack() {
    this.step -= 1;
  }

  gotoNext() {
    this.step += 1;
  }

  searchPatient() {
    this.isSearchingForPatients = true;
    this.pharmacyService.searchPatient(
      this.patient.firstName,
      this.patient.lastName,
      moment(this.patient.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD')
    ).subscribe({
      next: res => {
        const patients = res.content as Patient[];
        this.existingPatients = patients.map(patient => ({ ...patient, dateOfBirth: moment(patient.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY') }));
        this.selectedPatientIndex = this.existingPatients.length > 0 ? 0 : null;
      },
      error: error => {
        console.error(error);
        this.existingPatients = [];
        this.selectedPatientIndex = null;
      }
    }).add(() => {
      this.isSearchingForPatients = false;
      this.confirmUserModal.show();
    })
  }

  getPets(patientId: number) {
    this.isSearchingForPets = true;
    this.pharmacyService.getPets(patientId).subscribe({
      next: pets => {
        this.existingPets = pets.content;
        console.log('Found existing pets for patient', this.existingPets);
      },
      error: error => {
        this.existingPets = [];
        console.log('Did not find existing pets for patient', error);
      }
    }).add(() => {
      this.isSearchingForPets = false;
    });
  }

  selectExistingPatient() {
    this.isExistingPatient = true;
    this.patient = this.existingPatients[this.selectedPatientIndex];
    this.selectedPatientIndex = null;
    this.confirmUserModal.hide();
  }

  ignoreExistingPatient() {
    this.isExistingPatient = false;
    this.selectedPatientIndex = null;
    this.confirmUserModal.hide();
  }

  selectExistingPet(pet: Pet) {
    console.log('selected pet', pet);
    this.isExistingPet = true;
    this.pet = { ...pet };
  }

  addressEqual(a: Address, b: Address) {
    return (a.line1 === b.line1) && (a.line2 === b.line2) && (a.city === b.city) && (a.county === b.county) && (a.postcode === b.postcode);
  }

  updatePetAddress(event: any) {
    const checked = Boolean(event.target.checked);
    if (checked) {
      this.pet.address = { ...this.patient.address };
    } else {
      this.pet.address = { ...this.PLACEHOLDER_ADDRESS };
    }
  }

  initPetStage() {
    this.isExistingPet = false;
    this.pet = { ...this.PLACEHOLDER_PET };
  }

  createPrescription(data: any) {
    console.log(data);
    this.submitting = true;
    of<any>(null).pipe(
      // patient
      switchMap(() => {
        if (this.isExistingPatient) {
          console.log('returning selected patient', this.patient);
          return of(this.patient);
        } else {
          const patient = this.patient;
          console.log('creating new patient', patient);
          this.submittingMessage = 'Adding Patient Record...';
          return this.pharmacyService.createCompanyPatient(patient, data.pin);
        }
      }),
      // pet
      switchMap(patient => {
        if (!this.forPet) {
          return of<[any, any]>([patient, null]);
        } else if (this.isExistingPet) {
          console.log('returning selected pet', this.pet);
          return of<[any, any]>([patient, this.pet]);
        } else {
          const pet = { ...this.pet, owner: { id: patient.id } };
          console.log('creating new pet', pet);
          this.submittingMessage = 'Adding Pet Record...';
          return this.pharmacyService.createPet(pet, data.pin).pipe(map(returnedPet => [patient, returnedPet]));
        }
      }),
      // order
      switchMap(([patient, pet]) => {
        const doctor = this.userService.user;
        const order = {
          deliveryType: 'PHARMACY',
          paymentType: 'OFFLINE',
          name: `Prescription - ${this.userService.user.prescriptionCompany?.name || this.userService.user.prescriptionCompany?.id}`,
          patient: { id: patient.id },
          doctor: { id: doctor.id },
          pharmacy: { id: this.pharmacy.id },
          company: { id: doctor.prescriptionCompany?.id }
        };
        console.log('creating new order', order);
        this.submittingMessage = 'Creating Order...';
        return this.pharmacyService.createCompanyOrder(order, data.pin).pipe(map(order => [order, pet]));
      }),
      // prescription
      switchMap(([order, pet]) => {
        console.log('order created', order);
        const prescription: any = {
          orderItem: { id: order.id },
          medication: data.prescriptions,
          additionalPatientNotes: data.additionalPatientNotes,
          refNumber: data.refNumber,
          pin: data.pin
        };
        if (pet?.id) {
          prescription.pet = { id: pet.id }
        }
        console.log('creating new prescription', prescription);
        this.submittingMessage = 'Adding Prescription to Order...';
        return this.pharmacyService.createCompanyOrderPrescription(prescription)
      })
    ).subscribe({
      next: prescription => {
        console.log('prescription created', prescription);
        this.ngOnInit();
        this.action.emit({ success: true, result: prescription, error: null });
      },
      error: error => {
        this.action.emit({ success: false, result: null, error: error });
      }
    }).add(() => {
      this.submitting = false;
    })
    localStorage.setItem('patient-suject', null)
  }

  setPatientDob(dob: string) {
    const date = moment(dob, 'DD-MM-YYYY');
    if (date.isValid()) {
      this.patient.dateOfBirth = date.format('DD-MM-YYYY');
    }
  }

  isQuickSilvaDoctor() {
    return [DoctorType.PRESCRIBING_DOCTOR, DoctorType.SURGERY_ADMIN].includes(this.doctorType);
  }

}
