<div class="container">
	<div class="central_panel">
		<h2>Change Password</h2>

		<form [formGroup]="passwordForm"
          autocomplete="off"
          novalidate
          [ngClass]="{ submitted: isProcessing }"
          (ngSubmit)="submit()">
			<div class="form-group">
				<label for="oldPassword">Current Password</label>
				<input formControlName="currentPassword" id="oldPassword" type="password" class="form-control" required />
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="passwordForm.controls?.currentPassword?.touched
                      && passwordForm.controls?.currentPassword?.errors?.required">
          Current password is required.
        </div>
			</div>
			<div class="form-group">
				<label for="newPassword">New Password</label>
				<input formControlName="newPassword" id="newPassword" type="password" class="form-control"  required />
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="passwordForm.controls?.newPassword?.touched
                      && passwordForm.controls?.newPassword?.errors?.fieldError">
          {{passwordForm.controls?.newPassword?.errors?.fieldError}}
        </div>
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="passwordForm.controls?.newPassword?.touched
                      && passwordForm.controls?.newPassword?.value?.length > 0
                      && passwordForm.errors?.samePasswordError">
          {{passwordForm.errors?.samePasswordError}}
        </div>
			</div>
			<div class="form-group">
				<label for="passwordConfirmation">Confirm New Password</label>
				<input formControlName="passwordConfirmation" id="passwordConfirmation" type="password" class="form-control" />
        <div style="margin-top: 5px; color: red; font-size: 12px;"
             *ngIf="passwordForm.controls?.passwordConfirmation?.touched && passwordForm.errors?.error">
          {{passwordForm.errors?.error}}
        </div>
			</div>

      <div class="alert alert-info" style="margin-top: 15px;">
        <strong>Your password should meet the following rules:</strong>
        <ul>
          <li>It should contain at least 1 upper-case letter.</li>
          <li>It should contain at least 1 lower-case letter.</li>
          <li>It should contain at least 1 number.</li>
          <li>It should contain at least 1 special character.</li>
          <li>It should be at least 8 characters length.</li>
        </ul>
      </div>

      <button type="button" class="btn btn-default" routerLink="/">Back</button>
      <button class="btn btn-primary" type="submit" style="margin-left: 15px;"
              [disabled]="!passwordForm.valid || isProcessing"
              [style.opacity]="passwordForm.valid && !isProcessing ? 1 : 0.5">Change Password</button>

		</form>
	</div>
</div>
