import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GAD_FORM } from 'src/assets/patientQuestionnaires';

@Component({
  selector: 'app-gad-note-modal',
  templateUrl: './gad-note-modal.component.html',
  styleUrls: ['./gad-note-modal.component.scss']
})
export class GadNoteModalComponent implements OnInit {
  @Input() gadNote: any;
  @Output() hideModal = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  get gadForm(){
    return GAD_FORM;
  }

}
