import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-label',
  templateUrl: './comment-label.component.html',
  styleUrls: ['./comment-label.component.scss']
})
export class CommentLabelComponent implements OnInit {
  @Input() response: any;

  constructor() { }

  ngOnInit(): void {
  }

}
