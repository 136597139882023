<div *ngIf="!!gpNote" class="version-control-container">
    <form #selectForm="ngForm">
        <select *ngIf="!editNote" class="form-control" [ngModel]="noteversion"
            (ngModelChange)="handleVersionCHange($event)" name="versionControl">
            <option *ngFor="let item of gpNote; let i = index" [ngValue]="i">Version {{i + 1}}</option>
        </select>
    </form>
    <p *ngIf="editNote" class="brand-1"><b>*You are editing the latest version</b></p>
    <button
        *ngIf="!editNote && orderstatus === 'AWAITING_MDT_SESSION_INITIATION' && !!doctorId && doctorId === gpNote[0].doctor.id"
        class="btn btn-danger" (click)="handleEditBtn(true)" [disabled]="isLoading">
        Edit
    </button>
    <button
        *ngIf="editNote && orderstatus === 'AWAITING_MDT_SESSION_INITIATION' && !!doctorId && doctorId === gpNote[0].doctor.id"
        class="btn btn-primary" (click)="handleEditBtn(false)" [disabled]="isLoading">
        View
    </button>
    <div *ngIf="isLoading" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>
</div>

<app-consultation-notes-form *ngIf="editNote" [orderId]="orderId"
    [gpNote]="gpNote[gpNote.length - 1]"></app-consultation-notes-form>

<div *ngIf="!editNote" class="order-notes">
    <ng-container
        *ngIf="!!parsedNote && !!parsedNote?.doctorComments; else generalFormat">
        {{parsedNote?.doctorComments}}
    </ng-container>

    <ng-template #generalFormat>
        <ng-container *ngIf="isExistsAnswers; else noContent">
            <div class="notes-header">
                <h5 class="note-question">Question</h5>
                <h5 class="answer">Answer</h5>
            </div>

            <div *ngFor="let questionSet of filteredQuestionnaire">
                <h3>{{questionSet.title}}</h3>
                <div class="title-underline"></div>
                <ng-container *ngIf="parsedNote !== undefined">
                    <div *ngFor="let question of questionSet.questions" class="notes-body">
                        <ng-container *ngIf="question.questionId !== ''">
                            <ng-container *ngIf="question.question !== ''; else noQuestion">
                                <span class="note-question">{{question.question}}</span>

                                <p class="answer">
                                    <ng-container *ngIf="!!parsedNote[question.questionId].choices">
                                        <ng-container *ngFor="let choice of parsedNote[question.questionId].choices">
                                            <ng-container *ngIf="choice.checked">
                                                <span><b>{{choice.label}}</b></span>
                                                <span *ngIf="!!choice.text"> {{choice.text}}</span> <br>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="isChoiceQuestion(parsedNote[question.questionId])">
                                        <ng-container [ngSwitch]="parsedNote[question.questionId].choice">
                                            <span *ngSwitchCase="true" class="label label-primary">Yes</span>
                                            <span *ngSwitchCase="false" class="label label-warning">No</span>
                                            <span *ngSwitchDefault>{{parsedNote[question.questionId].choice}}</span>
                                        </ng-container>
                                    </ng-container>

                                    <span class="text-break"
                                        title={{parsedNote[question.questionId].text}}>{{parsedNote[question.questionId].text}}</span>
                                </p>
                            </ng-container>

                            <ng-template #noQuestion>
                                <span class="note-question">{{parsedNote[question.questionId].choices[0]?.label}}</span>
                                <p class="answer">
                                    <span *ngIf="parsedNote[question.questionId].choices[0]?.checked"
                                        class="label label-primary">Yes</span>
                                    <span *ngIf="!parsedNote[question.questionId].choices[0]?.checked"
                                        class="label label-warning">No</span>
                                    <span *ngIf="!!parsedNote[question.questionId].choices[0]?.text">
                                        {{parsedNote[question.questionId].choices[0].text}}</span>
                                </p>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #noContent>
            No content available
        </ng-template>
    </ng-template>
</div>