import { Injectable, Inject } from '@angular/core';
import { AccountService } from './account.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { GlobalOptions } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class KeepAliveService {
    jwtHelper: JwtHelperService = new JwtHelperService();
    private refreshTimeout: any;

    constructor(
        private accountService: AccountService,
        private authHttp: HttpClient,
        private idle: Idle,
        @Inject('global_options') private options: GlobalOptions) {
    }

    init() {
        this.accountService.authorised$.subscribe(authorised => {
            if (authorised) {
                this.extend(localStorage.getItem(`doctor.id_token`));
            } else {
                if (this.refreshTimeout) {
                    clearTimeout(this.refreshTimeout);
                }
            }
        });

        this.initIdleLogout();
    }

    private initIdleLogout() {

        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onTimeout.subscribe(() => {
            this.accountService.logout();
        });

        this.accountService.authorised$.subscribe(authorised => {
            if (authorised) {
                let token = localStorage.getItem(`doctor.id_token`);
                let duration = this.getTokenDuration(token);
                this.idle.setIdle(duration);
                this.idle.setTimeout(0);
                this.idle.watch();
            } else {
                //this.idle.stop();
            }
        });
    }

    refreshToken() {
        let url = `${this.options.apiEndPoint}api/doctor/refresh`;
        return this.authHttp.get<any>(url).pipe(map(response => response.value));
    }

    private extend(token) {
        let diff = this.getTokenDuration(token);
        diff *= 0.9;

        this.refreshTimeout = setTimeout(() => {
            this.refreshToken().subscribe(newToken => {
                localStorage.setItem(`doctor.id_token`, newToken);
                this.extend(newToken);
            });
        }, diff);
    }

    private getTokenDuration(token) {
        let now = new Date();
        let expirationDate = this.jwtHelper.getTokenExpirationDate(token);
        return expirationDate.getTime() - now.getTime();
    }
}
