<div *ngIf="selectedOrder?.patient?.alerts" class="alert alert-dark-red" (click)="showAlerts = !showAlerts">
  <strong>Key message alerts: </strong>
  <span *ngIf="!showAlerts">{{selectedOrder?.patient?.alerts | slice:0:25}}</span><span
    *ngIf="selectedOrder?.patient?.alerts.length > 25 && !showAlerts">...</span>
  <span *ngIf="selectedOrder?.patient?.alerts.length > 25">
    <span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
    <span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
  </span>
  <div *ngIf="showAlerts"> {{selectedOrder?.patient?.alerts}}</div>
</div>

<div *ngFor="let order of orders" [ngClass]="selectedOrder?.id == order.id ? 'is_active' : ''" class="accordian">

  <!-- Add appropriate titles -->
  <div *ngIf="order.status !== 'CANCELED_BY_DOCTOR' && order.status !== 'CANCELED_BY_PATIENT'">
    <div class="history-header" (click)="selectOrderId(order.id)">
      <div *ngIf="order.type == 'BOOKING'" class="title">{{order.appointment?.datetime | date : 'dd/MM/yyyy HH:mm'}}
        <span class="label label-primary">Consultation</span>
      </div>
      <div *ngIf="order.type == 'FORM'" class="title">{{order.appointment?.datetime | date : 'dd/MM/yyyy HH:mm'}} <span
          class="label label-default">{{order.name}}</span></div>
      <div *ngIf="order.type == 'PRESCRIPTION_ONLY'" class="title">{{order.appointment?.datetime | date : 'dd/MM/yyyy HH:mm'}}
        <span class="label label-default">{{order.name}}</span>
      </div>
      <div *ngIf="order.type == 'TRAVEL'" class="title">{{order.appointment?.datetime | date : 'dd/MM/yyyy HH:mm'}}
        <span class="label label-default">{{order.name}}</span></div>
    </div>

    <div *ngIf="selectedOrder?.id == order.id" class="content">
      <div class="patient-history-content">

        <div class="steps-container">

          <app-gp-decisions [orderNotes]="selectedOrder.orderNotes"></app-gp-decisions>

          <div class="patient-step">
            <div class="decision">
              <img
                [src]="gpApprovalStatus.includes(selectedOrder.status) ? '/assets/vectors/success.svg': '/assets/vectors/close.svg'" />
              <span>{{gpApprovalStatus.includes(selectedOrder.status) ? 'GP Approved' : 'GP Rejected'}}</span>
            </div>

            <div class="step-content">
              <button class="btn btn-default" (click)="viewPatientNotes.show()">Patient Notes</button>
              <button class="btn btn-default" (click)="modalService.openModal('SCRModal')">Patient Uploaded documents</button>
              <button class="btn btn-default" (click)="modalService.openModal('gpNotes')">GP Notes</button>
              <button *ngIf="selectedGPRecommendation !== undefined" class="btn btn-default"
                (click)="viewGPRecommendations.show()">Recommendation</button>
            </div>
          </div>

          <div *ngIf="gpApprovalStatus.includes(selectedOrder.status)" class="patient-step">
            <ng-container *ngIf="mdtPendingStatus.includes(selectedOrder.status); else MDTDecided">
              <div class="decision">
                <img src="/assets/vectors/pending.svg" />
                <span>MDT Pending</span>
              </div>
            </ng-container>

            <ng-template #MDTDecided>
              <div class="decision">
                <img
                  [src]="mdtApprovedStatus.includes(selectedOrder.status) ? '/assets/vectors/success.svg': '/assets/vectors/close.svg'" />
                <span>{{mdtApprovedStatus.includes(selectedOrder.status) ? 'MDT Approved' : 'MDT Rejected'}}</span>
              </div>
            </ng-template>

            <div class="step-content">
              <button *ngIf="selectedMDTRecommendation !== undefined" class="btn btn-default"
                (click)="viewMDTRecommendations.show()">Recommendation</button>
              <button *ngIf="selectedMDTRecommendation !== undefined" class="btn btn-default"
                (click)="viewMDTProforma.show()">Proforma</button>
            </div>
          </div>

          <div *ngIf="mdtApprovedStatus.includes(selectedOrder.status)" class="patient-step">
            <ng-container *ngIf="specialistPendingStatus.includes(selectedOrder.status); else SpecialistDecided">
              <div class="decision">
                <img src="/assets/vectors/pending.svg" />
                <span>Specialist Pending</span>
              </div>
            </ng-container>

            <ng-template #SpecialistDecided>
              <div class="decision">
                <img
                  [src]="specialistApprovedStatus.includes(selectedOrder.status) ? '/assets/vectors/success.svg': '/assets/vectors/close.svg'" />
                <span>{{specialistApprovedStatus.includes(selectedOrder.status) ? 'Specialist Approved' : 'Specialist Rejected'}}</span>
              </div>
            </ng-template>

            <!-- <div *ngIf="selectedPrescription !== undefined" class="step-content">
              <button class="btn btn-default" (click)="viewPrescription.show()">Prescription</button>
            </div> -->
          </div>

        </div>

        <div class="comments-container">
          <app-comment-section [standard]="true" [orderId]="order.id"></app-comment-section>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="order.status == 'CANCELED_BY_DOCTOR' || order.status == 'CANCELED_BY_PATIENT'">
    <div *ngIf="order.type == 'BOOKING'" class="title">{{order.appointmentDate | date : 'dd/MM/yyyy HH:mm'}} <span
        class="label label-danger">Consultation / Cancelled</span></div>
    <div *ngIf="order.type == 'FORM'" class="title">{{order.date | date : 'dd/MM/yyyy HH:mm'}} <span
        class="label label-danger">{{order.name}} / Cancelled</span></div>
  </div>
</div>

<div *ngIf="ordersFromCoop?.length > 0">
  <h4 class="text-danger question"><i class="fa fa-flag" aria-hidden="true"></i>&nbsp;&nbsp;This patient has previous
    orders from coop pharmacy</h4>
  <div class="question" *ngFor="let orderItem of ordersFromCoop">
    <strong>{{orderItem.date}} (Coop Pharmacy)</strong>
    <ul>
      <li *ngFor="let item of orderItem.items">
        {{item.name}}
        <br>
        <small>Qty - {{item.quantity}}</small>
      </li>
    </ul>
  </div>
</div>

<app-gp-notes-modal *ngIf="!!selectedOrder" [orderstatus]="selectedOrder.status" [orderId]="selectedOrder.id"></app-gp-notes-modal>

<app-patient-scr-documents-modal *ngIf="selectedOrder" [order]="selectedOrder"></app-patient-scr-documents-modal>

<div bsModal #viewPatientNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedOrderNotes !== undefined">
    <app-patient-notes-modal [orderNotes]="selectedOrderNotes" (hideModal)="viewPatientNotes.hide()">
    </app-patient-notes-modal>
  </ng-container>
</div>

<div bsModal #viewGPRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedGPRecommendation !== undefined">
    <app-recommendation-modal [title]="'GP'" [recommendation]="selectedGPRecommendation"
      (hideModal)="viewGPRecommendations.hide()">
    </app-recommendation-modal>
  </ng-container>
</div>

<div bsModal #viewMDTRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedMDTRecommendation !== undefined">
    <app-recommendation-modal [title]="'MDT'" [recommendation]="selectedMDTRecommendation"
      (hideModal)="viewMDTRecommendations.hide()">
    </app-recommendation-modal>
  </ng-container>
</div>

<div bsModal #viewMDTProforma="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="proformaNote !== undefined">
    <app-mdt-proforma-modal [proformaNotes]="proformaNote" (hideModal)="viewMDTProforma.hide()">
    </app-mdt-proforma-modal>
  </ng-container>
</div>

<!-- <div bsModal #viewPrescription="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedPrescription !== undefined">
    <app-prescription-modal [prescription]="selectedPrescription" [additionalNotes]="selectedAdditionalNote"
      (hideModal)="viewPrescription.hide()">
    </app-prescription-modal>
  </ng-container>
</div> -->