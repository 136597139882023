<div class="modal-dialog patient-modal">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
                AUDIT Note
            </h4>
        </div>
        <div class="modal-body">
            <section class="patient-content">
                <div class="note-score">Score: {{auditNote.note.score}}</div>
                <div *ngFor="let item of auditForm | keyvalue" class="patient-response">
                    <h4>{{item.value.label}}</h4>
                    <span>{{item.value.values[auditNote.note[item.key]]}}</span>
                </div>
            </section>
        </div>
    </div>
</div>