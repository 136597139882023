export const ALL_STATUS = [
  'BOOKED',
  'AWAITING_MDT_SESSION_INITIATION',
  'IN_MDT_SESSION',
  'AWAITING_MDT_SESSION_COMPLETION',
  'AWAITING_SPECIALIST_CALL_PATIENT',
  'REJECTED_BY_MDT',
  'AWAITING_SPECIALIST_PRESCRIPTION_CREATION',
  'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY',
  'REJECTED_BY_SPECIALIST',
  'REJECTED_BY_PATIENT',
  'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL',
  'WAITING_FOR_DELIVERY_NOTE',
  'WAITING_FOR_TRACKING_CODE',
  'WAITING_FOR_PHARMACY_APPROVAL',
  'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
  'REJECTED_BY_PHARMACY',
  'REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION',
  'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
  'AWAITING_PHARMACY_DISPENSE',
  'AWAITING_PHARMACY_DISPATCH',
  'AWAITING_PHARMACY_DELIVER',
  'DELIVERED',
];

export const SPECIALIST_PAST_ORDERS = [
  'WAITING_FOR_PHARMACY_APPROVAL',
  'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
  'REJECTED_BY_PHARMACY',
  'AWAITING_PHARMACY_DELIVER',
  'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
  'AWAITING_PHARMACY_DISPENSE',
  'AWAITING_PHARMACY_DISPATCH',
  'REJECTED_BY_SPECIALIST',
  'DELIVERED',
]

export const GP_REJECTED = ['GP_REJECTED'];
export const MDT_REJECTED = ['REJECTED_BY_MDT'];
export const SPECIALIST_REJECTED = ['REJECTED_BY_SPECIALIST'];
export const REJECTION_LIST = [
  'GP_REJECTED',
  'REJECTED_BY_MDT',
  'REJECTED_BY_SPECIALIST',
  'REJECTED_BY_PHARMACY',
  'REJECTED_BY_PATIENT',
  'REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION'
]

export const GP_APPROVED_LIST = [
  'AWAITING_MDT_SESSION_INITIATION',
  'IN_MDT_SESSION',
  'AWAITING_MDT_SESSION_COMPLETION',
  'AWAITING_SPECIALIST_CALL_PATIENT',
  'REJECTED_BY_MDT',
  'AWAITING_SPECIALIST_PRESCRIPTION_CREATION',
  'REJECTED_BY_SPECIALIST',
  'REJECTED_BY_PATIENT',
  'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY',
  'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL',
  'WAITING_FOR_DELIVERY_NOTE',
  'WAITING_FOR_TRACKING_CODE',
  'WAITING_FOR_PHARMACY_APPROVAL',
  'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
  'REJECTED_BY_PHARMACY',
  'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
  'AWAITING_PHARMACY_DISPENSE',
  'AWAITING_PHARMACY_DELIVER',
  'AWAITING_PHARMACY_DISPATCH',
  'AWAITING_PHARMACY_DELIVER',
  'DELIVERED',
];

export const MDT_PENDING_LIST = [
  'AWAITING_MDT_SESSION_INITIATION',
  'IN_MDT_SESSION',
  'AWAITING_MDT_SESSION_COMPLETION',
];

export const MDT_APPROVED_LIST = [
  'AWAITING_SPECIALIST_CALL_PATIENT',
  'AWAITING_SPECIALIST_PRESCRIPTION_CREATION',
  'REJECTED_BY_SPECIALIST',
  'REJECTED_BY_PATIENT',
  'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL',
  'WAITING_FOR_DELIVERY_NOTE',
  'WAITING_FOR_TRACKING_CODE',
  'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY',
  'WAITING_FOR_PHARMACY_APPROVAL',
  'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
  'REJECTED_BY_PHARMACY',
  'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
  'AWAITING_PHARMACY_DISPENSE',
  'AWAITING_PHARMACY_DELIVER',
  'AWAITING_PHARMACY_DISPATCH',
  'DELIVERED',
];

export const SPECIALIST_PENDING_LIST = [
  'AWAITING_SPECIALIST_CALL_PATIENT',
  'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY',
  'AWAITING_SPECIALIST_PRESCRIPTION_CREATION',
];

export const SPECIALIST_APPROVED_LIST = [
  'REJECTED_BY_PATIENT',
  'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES',
  'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL',
  'WAITING_FOR_DELIVERY_NOTE',
  'WAITING_FOR_TRACKING_CODE',
  'WAITING_FOR_PHARMACY_APPROVAL',
  'AWAITING_PATIENT_PHARMACY_PRESCRIPTION_CONFIRMATION',
  'REJECTED_BY_PHARMACY',
  'AWAITING_PATIENT_PRESCRIPTION_PAYMENT',
  'AWAITING_PHARMACY_DISPENSE',
  'AWAITING_PHARMACY_DELIVER',
  'AWAITING_PHARMACY_DISPATCH',
  'DELIVERED',
];
