<div class="container">
    <div class="mdt-session-orders">
        <ng-container *ngIf="!initLoading; else dataFetchInProgress">
            <div *ngIf="sessionStat === false; else sessionPresent" class="start-session-btn-container">
                <button [disabled]="allPendingOrders.length === 0" class="btn btn-primary"
                    (click)="startSession()">Start session</button>
            </div>

          <div class="row" style="margin-bottom: 10px">
            <div class="col-lg-6 col-sm-12">
              <div  class="orders-search-container">
                <select [(ngModel)]="searchType" class="form-control" style="width: 47%">
                  <option value="name">Patient Name</option>
                  <option value="id">ID</option>
                </select>

                <input name="treatItSearchInput" type="text" [(ngModel)]="searchValue" class="form-control input-search-orders"
                       placeholder="Search by patient name or order ID" />
                <button class="btn btn-primary search-button" (click)="onSearchInput(searchType, searchValue)">Search</button>
                <button class="btn btn-danger reset-button" (click)="onResetSearch()">Reset</button>
              </div>
            </div>
          </div>

          <ng-template #sessionPresent>
                <div class="session-form" [class]="expanded? 'expanded': ''">
                    <div class="form-header" (click)="expanded = !expanded">
                        <span><b>Expand to view/ edit overall session details</b></span>
                        <img src="/assets/vectors/expand.svg" alt="">
                    </div>
                    <form #sessionForm="ngForm" autocomplete="off" (ngSubmit)="submitSessionDetails(sessionForm)"
                        novalidate class="form-content">
                        <div>
                            <label for="date">Started Date</label>
                            <p id="date">{{sessionStat.startDate | date: 'dd-MMM-YYYY'}}</p>
                        </div>
                        <div>
                            <label for="chair">
                                {{sessionStat.chair === null ? 'Select session chair' : 'Session chair'}}
                            </label>
                            <select *ngIf="sessionStat.chair === null; else chairPresent" class="form-control"
                                name="chair" [(ngModel)]="chair" id="chair" required>
                                <ng-container *ngIf="allMDTs !== undefined">
                                    <option *ngFor="let mdt of allMDTs" [ngValue]="mdt">{{mdt.displayName}}</option>
                                </ng-container>
                            </select>
                            <ng-template #chairPresent>
                                <h5><b>{{sessionStat.chair.displayName}}</b></h5>
                            </ng-template>
                        </div>
                        <div>
                            <label for="location">Location</label>
                            <input *ngIf="sessionStat.location === null; else locationPresent" id="location" type="text"
                                name="location" required [(ngModel)]="location" placeholder="Type location"
                                class="form-control" />
                            <ng-template #locationPresent>
                                <h5>{{sessionStat.location}}</h5>
                            </ng-template>
                        </div>

                        <h5><b>Select participants</b></h5>
                        <div class="question-layout participants">
                            <ng-container *ngIf="allMDTs !== undefined">
                                <div *ngFor="let mdt of allMDTs">
                                    <input type="checkbox" (click)="addParticipants($event, mdt)"
                                        [disabled]="mdt.id === sessionStat.reporter.id || mdt.id == chair?.id || mdt.id === sessionStat.chair?.id || user.id !== sessionStat.reporter.id"
                                        [checked]="mdt.id === sessionStat.reporter.id || mdt.id == chair?.id || mdt.id === sessionStat.chair?.id || isParticipantPresent(mdt.id)" />
                                    <span> {{mdt.displayName}} </span>
                                </div>
                            </ng-container>
                        </div>

                        <div *ngIf="user.id === sessionStat.reporter.id" class="submit-btn-container">
                            <button *ngIf="sessionStat.chair === null" class="btn btn-danger" type="button"
                                (click)="cancelSessionModal.show()">Cancel</button>
                            <button class="btn btn-primary" type="submit">Save</button>
                        </div>
                    </form>
                </div>
                <h5 class="warning-text" *ngIf="sessionStat.chair === null">
                    <em>*Fill the form above to start reviewing orders.</em>
                </h5>

                <div *ngIf="sessionStat.chair !== null && user.id === sessionStat.reporter.id"
                    class="finalize-btn-container">
                    <button [disabled]="hasCompletedOrders"
                        [title]="hasCompletedOrders ? 'Cannot cancel the session becuase there are completed orders.' : ''"
                        class="btn btn-danger" type="button" (click)="cancelSessionModal.show()">Cancel</button>
                    <button class="btn btn-primary" (click)="finalizeSessionModal.show()">Finalize Session</button>
                </div>
            </ng-template>

            <div class="pagination-container">
                <span>Page {{page + 1}} of {{totalPageCount}}</span>
                <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
                    <img class="previous" src="/assets/vectors/expand.svg" alt="">
                </button>
                <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
                    <img class="next" src="/assets/vectors/expand.svg" alt="">
                </button>
            </div>
            <br>
            <div class="table-container">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Order Type</th>
                            <th>Patient</th>
                            <th>Initial Consultation</th>
                            <th>Care Specialist</th>
                            <th>Consulted Pharmacy</th>
                            <th>Pharmacist Name</th>
                            <th style="width: 18rem;">Pharmacy Email</th>
                            <!-- <th>Pharmacy Contact Number</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allPendingOrders.length > 0">
                        <tr *ngFor="let order of allPendingOrders">
                            <td>{{order.id}}</td>
                            <td>{{order.creationDate | date : 'dd-MMM-yyyy HH:mm' }}</td>
                            <td>{{order.parentOrderItemId ? 'Repeat' : 'Consultation'}}</td>
                            <td>{{order.patient.displayName}}</td>
                            <td>{{!!order.doctor ? order.doctor.displayName : '-'}}</td>
                            <td>{{!!order?.patient?.careSpecialist ? order.patient.careSpecialist : '-'}}</td>
                            <td>{{!!order.prescribingPharmacist ? order.prescribingPharmacist.displayName : '-'}}</td>
                            <td>{{!!order.prescribingPharmacist ? order.workflow.PRESCRIBING_PHARMACIST_NAME : '-'}}</td>
                            <td>{{!!order.prescribingPharmacist ? getPharmacistEmail(order.prescribingPharmacist.email)[0]+"\n@"+getPharmacistEmail(order.prescribingPharmacist.email)[1] : '-'}}</td>
                            <!-- <td>{{!!order.prescribingPharmacist ? order.prescribingPharmacist.phone : '-'}}</td> -->
                            <td>
                                <button class="btn" [disabled]="sessionStat?.chair === null"
                                    (click)="routeToReview(order.id)" [class]="getButtonStatus(order.status)"
                                    [style.width]="'10rem'">
                                    {{order.status === "AWAITING_MDT_SESSION_INITIATION" ? "Pending" : ""}}
                                    {{order.status === "IN_MDT_SESSION" ? "In Progress" : ""}}
                                    {{order.status === "AWAITING_MDT_SESSION_COMPLETION" ? "Completed" : ""}}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="allPendingOrders.length === 0">
                      <tr>
                        <td colspan="10" style="text-align: center;font-weight: bold; margin-top: 15px">
                          <div>No records found</div>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <ng-template #dataFetchInProgress>
            <div class="loading-container">
                <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
            </div>
        </ng-template>
    </div>
</div>

<div bsModal #cancelSessionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-body">
				<h4>Do you really want to cancel the session?</h4>
                <div class="finalize-btn-container">
                    <button class="btn btn-danger" (click)="cancelSessionModal.hide()">No</button>
                    <button class="btn btn-primary" (click)="handleCancelSession()">Yes</button>
                </div>
			</div>
		</div>
	</div>
</div>

<div bsModal #finalizeSessionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-body">
				<h4>Do you really want to finalize the session?</h4>
                <div class="finalize-btn-container">
                    <button class="btn btn-danger" (click)="finalizeSessionModal.hide()">No</button>
                    <button class="btn btn-primary" (click)="handleSessionFinalization()">Yes, finalize.</button>
                </div>
			</div>
		</div>
	</div>
</div>
