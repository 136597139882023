import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockStatus'
})
export class StockStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'OUT_OF_STOCK':
        return 'Out of stock'
      case 'LOW_STOCK':
        return 'Low stock'
      case 'IN_STOCK':
        return 'In stock'
      case null:
        return 'Not available'
      default:
        return value;
    }
  }

}
