import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';
import { TreatmentService } from './treatment.service';
import { GlobalOptions } from '../app.module';
import { AppService } from '../app.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})

@Injectable()
export class OrderComponent implements OnInit {
  public orderItem: any;
  public patient: any;
  private itemId: number;
  public treatments: any[];
  public treatment: string;
  public formulary;
  public pin: string;
  public additionalNotes: string;
  public alerts: string;
  public dosageInstructions: string;
  public rejectReason: string;
  public chosenTreatment: string;
  public patientConsideredForRepeatPrescriptions: boolean = false;
  public doctorApprovedRepeatPrescriptions: boolean;
  public frequency: number;
  public orderFrequency;
  public noOfRepeatPrescriptions: number;
  public repeatPrescriptionOptions: any[];
  public patientQuestion: string = "";
  public patientAnswer: string = "";
  public docQuestioned: string = "";
  public enablePatientQuestion: boolean = true;

  public showAlerts: boolean;
  public showSummary: boolean;

  sendToGp = false;
  public orders: any[];

  malariaTreatment: any = {
    id: 0,
    name: ''
  };
  malariaTreatments = [
    {
      id: 1,
      name: 'Mefloquine (Lariam)'
    },
    {
      id: 2,
      name: 'Doxycycline'
    },
    {
      id: 3,
      name: 'Atovaquone / Proguanil (Malarone)'
    },
    {
      id: 4,
      name: 'Atovaquone / Proguanil (Generic)'
    },
    {
      id: 5,
      name: 'Chloroquine (Avloclor)'
    },
    {
      id: 6,
      name: 'Proguanil & Chloroquine (Paludrine & Avloclor)'
    }
  ];
  malariaTreatmentName = '';
  malariaTreatmentPrevious = {
    id: '',
    dosageStartDate: '',
    dosageEndDate: '',
    tablets: 0
  };
  noVaccines = false;
  malariaTreatmentChanged = false;
  malariaTreatmentLength = 1;
  proposedImmunisation;
  medications = [];
  chosenFormulary;
  frequencies = [];
  chosenFrequency;

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private treatmentService: TreatmentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    @Inject('global_options') private options: GlobalOptions
  ) {
    activatedRoute.params.subscribe(params => {
      this.itemId = params['orderItemId'];
    });
  }

  checkVaccines() {
    let vaccineLength = 0;
    this.orderItem.requestedVaccinations.forEach((item) => {
      if (!item.isRejected) {
        vaccineLength++;
      }
    });
    if (vaccineLength == 0) {
      this.noVaccines = true;
    }
  }

  getMalariaName(id) {
    let malariaName;
    this.malariaTreatments.forEach((item) => {
      if (item.id == id) {
        malariaName = item.name;
      }
    });
    if (!malariaName) {
      malariaName = 'None';
    }
    return malariaName;
  }

  approveItem() {
    if (this.orderItem.type === 'TRAVEL') {
      let malariaTreatment;
      if (this.orderItem.requestedAntimalarial && this.malariaTreatment != 'none') {
        malariaTreatment = {
          id: this.malariaTreatment,
          tablets: this.malariaTreatmentLength,
          startDate: this.orderItem.requestedAntimalarial.dosageStartDate,
          endDate: this.orderItem.requestedAntimalarial.dosageEndDate
        };
      }

      if (this.malariaTreatment == 'none') {
        this.malariaTreatmentName = null;
        this.malariaTreatmentLength = null;
        this.orderItem.requestedAntimalarial.dosageStartDate = null;
        this.orderItem.requestedAntimalarial.dosageEndDate = null;
      }

      let vaccines = [];
      if (this.orderItem.requestedVaccinations.length > 0) {
        this.orderItem.requestedVaccinations.forEach((item) => {
          if (!item.isRejected) {
            vaccines.push({
              id: item.id,
              startDate: item.startDate
            });
          }
        });
      }

      if ((this.malariaTreatment == 'none' || this.malariaTreatment.id == 0) && this.noVaccines) {
        this.dataService.rejectOrderItem(this.itemId, this.additionalNotes, this.alerts, this.pin).subscribe(
          (result) => {
            this.router.navigate(['orders']);
            this.toastr.success('Order rejected successfully', 'Order Rejected');
          },
          (error) => {
            this.toastr.error(this.getErrorMessage(error), 'Could Not Reject Order');
          });
      } else {
        this.dataService.approveTravelOrderItem(this.itemId, malariaTreatment, vaccines, this.additionalNotes, this.alerts, this.pin).subscribe(
          (result) => {
            this.pin = '';
            this.toastr.success('Order approved successfully', 'Order Approved');
            this.refresh();
          },
          (error) => {
            this.pin = '';
            this.toastr.error(this.getErrorMessage(error), 'Could Not Approve Order');
          });
      }

    } else if (this.doctorApprovedRepeatPrescriptions) {

      if (this.orderItem.formType){
        this.dataService.approveOrderItemWithRepeatPrescriptionsWithFormulary(this.itemId, this.formulary, this.additionalNotes, this.alerts, this.dosageInstructions, this.pin, this.frequency, this.noOfRepeatPrescriptions).subscribe(
          (result) => {
            this.pin = '';
            this.toastr.success('Order approved successfully', 'Order Approved');
            this.refresh();
          },
          (error) => {
            this.pin = '';
            this.toastr.error(this.getErrorMessage(error), 'Could Not Approve Order');
          });
  
      }else{
        this.dataService.approveOrderItemWithRepeatPrescriptions(this.itemId, this.treatment, this.additionalNotes, this.alerts, this.dosageInstructions, this.pin, this.frequency, this.noOfRepeatPrescriptions).subscribe(
          (result) => {
            this.pin = '';
            this.toastr.success('Order approved successfully', 'Order Approved');
            this.refresh();
          },
          (error) => {
            this.pin = '';
            this.toastr.error(this.getErrorMessage(error), 'Could Not Approve Order');
          });
      }

      
    } else {

      if (this.orderItem.formType){
        this.dataService.approveOrderItemWithFormulary(this.itemId, this.formulary, this.orderFrequency, this.additionalNotes, this.alerts, this.dosageInstructions, this.pin).subscribe(
          (result) => {
            this.pin = '';
            this.toastr.success('Order approved successfully', 'Order Approved');
            this.refresh();
          },
          (error) => {
            this.pin = '';
            this.toastr.error(this.getErrorMessage(error), 'Could Not Approve Order');
          });
      }else{
        this.dataService.approveOrderItem(this.itemId, this.treatment, this.additionalNotes, this.alerts, this.dosageInstructions, this.pin).subscribe(
          (result) => {
            this.pin = '';
            this.toastr.success('Order approved successfully', 'Order Approved');
            this.refresh();
          },
          (error) => {
            this.pin = '';
            this.toastr.error(this.getErrorMessage(error), 'Could Not Approve Order');
          });
      }
      
    }
  }

  rejectItem() {
    this.dataService.rejectOrderItem(this.itemId, this.rejectReason, this.alerts, this.pin).subscribe(
      (result) => {
        this.router.navigate(['orders']);
        this.toastr.success('Order rejected successfully', 'Order Rejected');
      },
      (error) => {
        this.toastr.error(this.getErrorMessage(error), 'Could Not Reject Order');
      });
  }

  removeVaccine(vaccine) {
    vaccine.isRejected = true;
  }

  cancelRejectVaccine(vaccine) {
    vaccine.isRejected = false;
  }

  changeMalariaTreatment(e) {
    if (this.malariaTreatmentPrevious.id == e) {
      this.orderItem.requestedAntimalarial.dosageStartDate = this.malariaTreatmentPrevious.dosageStartDate;
      this.orderItem.requestedAntimalarial.dosageEndDate = this.malariaTreatmentPrevious.dosageEndDate;
      this.malariaTreatmentLength = this.malariaTreatmentPrevious.tablets;
      this.malariaTreatmentChanged = false;
      return false;
    }
    this.malariaTreatmentChanged = true;
    this.malariaTreatmentLength = null;
    this.orderItem.requestedAntimalarial.dosageStartDate = null;
    this.orderItem.requestedAntimalarial.dosageEndDate = null;
  }

  complete() {
    this.dataService.completeOrderItem(this.itemId).subscribe(
      (result) => {
        this.router.navigate(['orders']);
        this.toastr.success('Order completed successfully', 'Order Completed');
      },
      (error) => {
        this.toastr.error(this.getErrorMessage(error), 'Could Not Complete Order');
      });

  }
  downloadItem() {
    this.dataService.downloadPrescription(this.itemId).subscribe(
      data => {
        let url = window.URL.createObjectURL(data);
        let anchor = document.createElement('a');
        anchor.setAttribute('href', url);
        anchor.setAttribute('download', 'prescription.pdf');
        anchor.click();
        window.URL.revokeObjectURL(url);
        this.toastr.success('Please check your downloaded items', 'Prescription Downloaded')
      },
      error => {
        this.toastr.error(this.getErrorMessage(error), 'Could Not Download Prescription')
      }
    )
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  private refresh() {
    this.dataService.getOrderItem(this.itemId).subscribe(item => {
      this.dataService.getCompleteOrders(item.patient.id).subscribe(orders => {
        this.dataService.getPatient(item.patient.id).subscribe(patient => {
          this.alerts = patient.alerts;
          this.treatmentService.getTreatments().subscribe(treatments => {
            this.patient = patient;
            this.orders = orders.content;
            this.orderItem = item;
            this.sendToGp = this.orderItem.form['disclaimer.shareDetailsWithGp'];
            this.treatments = [];

            let formTreatments = treatments.filter(x => x.formId == item.workflow.FORM_ID);

            if (this.orderItem.type == 'TRAVEL') {
              if (this.orderItem.requestedAntimalarial) {
                this.malariaTreatment = parseInt(this.orderItem.requestedAntimalarial.id);
                this.malariaTreatmentLength = parseInt(this.orderItem.requestedAntimalarial.tablets);

                this.malariaTreatmentPrevious = {
                  id: this.orderItem.requestedAntimalarial.id,
                  dosageStartDate: this.orderItem.requestedAntimalarial.dosageStartDate,
                  dosageEndDate: this.orderItem.requestedAntimalarial.dosageEndDate,
                  tablets: this.orderItem.requestedAntimalarial.tablets
                };
              }

              if (this.orderItem.requestedVaccinations) {
                this.orderItem.requestedVaccinations.forEach((item) => {
                  if (item.id == 4 || item.id == 2302) {
                    this.proposedImmunisation = item.id;
                  }
                });
              }

              
            }

            if (this.orderItem.formType){

              if (this.orderItem.formQuestionAnswer) {
                this.patientQuestion = this.orderItem.formQuestionAnswer.question ? this.orderItem.formQuestionAnswer.question : "";
                this.enablePatientQuestion = this.patientQuestion ? false : true;
                this.patientAnswer = this.orderItem.formQuestionAnswer.answer ? this.orderItem.formQuestionAnswer.answer : 'Awaiting patient response';
                this.docQuestioned =  this.orderItem.formQuestionAnswer.doctor ? this.orderItem.formQuestionAnswer.doctor : "";
              }
             

              this.dataService.getMedications(this.orderItem.formType.type).subscribe(medication => {
                  medication.forEach(m => {
                    m.formFormularies.forEach(f => {
                      if (f.id == this.orderItem.workflow.REQUESTED_FORMULARY_ID || ''){
                        this.chosenFormulary = f;
                        this.formulary = this.chosenFormulary?.id;
                      }
                      this.medications.push(f)
                    });

                  });
                  if (!this.chosenFormulary){
                    this.formulary = '';
                  }
                  }            
                );


                if (this.orderItem.formType.availableFrequencies){
                      console.log(this.orderItem.formType.availableFrequencies)
                      this.frequencies = (this.orderItem.formType.availableFrequencies).split(" ").map(Number);
                      console.log(this.frequencies)
                      if (this.orderItem.repeatFormOrderSubscription){
                        this.chosenFrequency = this.orderItem.repeatFormOrderSubscription.frequency
                        console.log(this.chosenFrequency)
                        this.orderFrequency = this.chosenFrequency
                      }else{
                        this.orderFrequency = '';
                      }

                }
            }

            

            if (formTreatments.length) {
              this.treatments = formTreatments[0].treatments;

              this.chosenTreatment = this.orderItem.workflow.REQUESTED_TREATMENT_NAME || '';
              this.treatment = this.chosenTreatment;

              if (this.treatments.length === 1) {
                this.treatment = this.treatments[0] || '';
              } else if (this.treatments.indexOf(this.treatment) === -1) {
                this.treatment = '';
              }
            }

          });
        });
      });

      Object.keys(item.form).map(function (element: string, index) {
        let isRepeat = element.indexOf('disclaimer.consideredRepeatPrescriptions');
        if (isRepeat !== -1 && item.form[element] == 'Yes') {
          this.patientConsideredForRepeatPrescriptions = true;
        }
      }, this);
    });
  }

  ngOnInit() {
    this.refresh();
  }

  updateNoRepeatsAllowed(repeatsField) {
    let numberOfOptions;
    if (this.frequency != 0 && this.frequency != null) {
      this.appService.getAppInfo().subscribe(appInfo => {
        numberOfOptions = Math.round(appInfo.repeatPrescriptionsExpiryDays / this.frequency);
        this.repeatPrescriptionOptions = [];
        for (var i = 1; i < numberOfOptions; i++) {
          this.repeatPrescriptionOptions.push(i);
        }
      });
    }

    // if there are no options or the selected option doesn't exist after changing frequency reset repeatsField
    if (!numberOfOptions || repeatsField.selectedIndex > this.repeatPrescriptionOptions.length) {
      this.noOfRepeatPrescriptions = null;
      repeatsField.selectedIndex = -1;
    }
  }

  preventDecimalAndPower(event) {
    event = event || window.event;
    var key = event.keyCode || event.which;
    key = String.fromCharCode(key);
    if (key === '.' || key === 'e') {
      event.preventDefault();
    }
  }

  getErrorMessage(error: any) {
    return error.error?.message || error.message || "An unexpected error occurred";
  }

  submitQuestion() {
    this.dataService.submitQuestion(this.patientQuestion, this.orderItem.id).subscribe(
      (result) => {
        this.toastr.success('Question submitted', 'Success');
        this.enablePatientQuestion = false;
        this.patientAnswer = "Awaiting patient response";
        this.refresh();
      },(error) => {
        this.toastr.error(this.getErrorMessage(error), 'Error submitting question');
      }
    );
  }
}
