<div class="modal-dialog prescription-modal">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hide.emit()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">
                Add {{userService.user.settings.DOCTOR_TYPE === 'SPECIALIST' ? 'Prescription' : 'Recommendation'}}
            </h4>
        </div>
        <div class="modal-body prescription-form">
            <div *ngIf="gpRecommendation || mdtRecommendation" class="recommendation-details">
                <div *ngIf="mdtRecommendation" class="recommendation-slot">
                    <h4><b>MDT Recommendation</b></h4>
                    <p>
                        <b>Category:</b> {{mdtRecommendation.category}} <br>
                        <b>Route of admin:</b> {{mdtRecommendation.route}}
                    </p>
                    <h5><b>Patient preferences</b></h5>
                    <p>
                        <b>Brand:</b> {{mdtRecommendation.brand}} <br>
                        <b>Manufacturer:</b> {{mdtRecommendation.manufacturer}} <br>
                        <b>Strain:</b> {{mdtRecommendation.strain}}
                    </p>
                    <h5><b>Additional notes</b></h5>
                    <p>{{mdtRecommendation.comments}}</p>
                </div>
                <div *ngIf="gpRecommendation" class="recommendation-slot">
                    <h4><b>GP Recommendation</b></h4>
                    <p>
                        <b>Category:</b> {{gpRecommendation.category}} <br>
                        <b>Route of admin:</b> {{gpRecommendation.route}}
                    </p>
                    <h5><b>Patient preferences</b></h5>
                    <p>
                        <b>Brand:</b> {{gpRecommendation.brand}} <br>
                        <b>Manufacturer:</b> {{gpRecommendation.manufacturer}} <br>
                        <b>Strain:</b> {{gpRecommendation.strain}}
                    </p>
                    <h5><b>Additional notes</b></h5>
                    <p>{{gpRecommendation.comments}}</p>
                </div>
            </div>
            <div *ngIf="gpRecommendation || mdtRecommendation" class="divider"></div>
            <div class="form-container">
                <form #prescriptionForm="ngForm">

                    <div class="question-section">
                        <div class="question">
                            <label for="medicationType">Medication type</label>
                            <select class="form-control" name="medicationType" [(ngModel)]="medicationType"
                                id="medicationType" required>
                                <option *ngFor="let type of getMedicationType()" [value]="type">{{type}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="question-section">
                        <div class="question">
                            <label for="pharmacy">Pharmacy</label>
                            <span *ngIf="pharmacy" (click)="pharmacy = undefined; handlePharmacyValueChange()">&#215;</span>
                            <select class="form-control" name="pharmacy" [(ngModel)]="pharmacy"
                                (ngModelChange)="handlePharmacyValueChange()" required id="pharmacy">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let pharmacy of prescriptionService.pharmacyList" [value]="pharmacy.id">
                                    {{pharmacy.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="filter-fields">
                        <div class="question">
                            <label for="category">THC %</label>
                            <span *ngIf="category" (click)="category = undefined; handlePrescriptionValueChange()">&#215;</span>
                            <select class="form-control" name="category" [(ngModel)]="category" required id="category"
                                (ngModelChange)="handlePrescriptionValueChange()">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let percentage of prescriptionService.thcList" [value]="percentage">
                                    {{percentage}}
                                </option>
                            </select>
                        </div>

                        <div class="question">
                            <label for="route">Route of admin</label>
                            <span *ngIf="route" (click)="route = undefined; handlePrescriptionValueChange()">&#215;</span>
                            <select class="form-control" name="route" [(ngModel)]="route" id="route" required
                                (ngModelChange)="handlePrescriptionValueChange()">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let route of prescriptionService.routesList" [value]="route">
                                    {{route}}</option>
                            </select>
                        </div>

                        <div class="question">
                            <label for="manufacturer">Manufacturer</label>
                            <span *ngIf="manufacturer" (click)="manufacturer = undefined; handlePrescriptionValueChange()">&#215;</span>
                            <select class="form-control" name="manufacturer" [(ngModel)]="manufacturer" required
                                id="manufacturer" (ngModelChange)="handlePrescriptionValueChange()">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let item of prescriptionService.manufacturerList" [value]="item">
                                    {{item}}</option>
                            </select>
                        </div>

                        <div class="question">
                            <label for="brand">Brand</label>
                            <span *ngIf="brand" (click)="brand = undefined; handlePrescriptionValueChange()">&#215;</span>
                            <select class="form-control" name="brand" [(ngModel)]="brand" id="brand" required
                                (ngModelChange)="handlePrescriptionValueChange()">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let item of prescriptionService.brandList" [value]="item">{{item}}
                                </option>
                            </select>
                        </div>

                        <div class="question">
                            <label for="strain">Strain</label>
                            <span *ngIf="strain" (click)="strain = undefined; handlePrescriptionValueChange()">&#215;</span>
                            <select class="form-control" name="strain" [(ngModel)]="strain" id="strain" required
                                (ngModelChange)="handlePrescriptionValueChange()">
                                <option [value]="undefined" hidden disabled>Not selected</option>
                                <option *ngFor="let item of prescriptionService.strainList" [value]="item">{{item}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="medication-table-container">
                        <h4><b>Available medications for the selected properties</b></h4>
                        <div class="table-container">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Quantity</th>
                                        <th>Stock status</th>
                                        <th>Strain</th>
                                        <th>Sale price</th>
                                        <th>Unit qty</th>
                                        <th *ngIf="userService.user.settings.DOCTOR_TYPE === 'SPECIALIST'"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="availableMedication.length; else noContent">
                                        <tr *ngFor="let item of availableMedication; index as i">
                                            <td>{{item.id}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.availQuantity ? item.availQuantity : 0}}</td>
                                            <td>{{item.stockStatus | stockStatus}}</td>
                                            <td>{{item.strain}}</td>
                                            <td>{{item.salePrice}}</td>
                                            <td>{{item.quantity}}</td>
                                            <td *ngIf="userService.user.settings.DOCTOR_TYPE === 'SPECIALIST'" width="10%">
                                                <button class="btn btn-primary" [disabled]="item.selected"
                                                    (click)="handleAddToSelectedMedications(i, item)">
                                                    {{item.selected ? 'Added' : 'Add'}}
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noContent>
                                        <tr>
                                            <td colspan="7">
                                                <span>No medications available matching to attributes you
                                                    selected.</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div *ngIf="category || route || manufacturer || brand || strain" class="recommendation-brief">
                        <h4><b>You are recommending the following properties:</b></h4>
                        <div>
                            <span *ngIf="category">THC percentage: <b>{{category}}</b></span>
                            <span *ngIf="route">Administration route: <b>{{route}}</b></span>
                            <span *ngIf="manufacturer">Manufacturer: <b>{{manufacturer}}</b></span>
                            <span *ngIf="brand">Brand: <b>{{brand}}</b></span>
                            <span *ngIf="strain">Strain: <b>{{strain}}</b></span>
                        </div>
                    </div>

                    <div *ngIf="userService.user.settings.DOCTOR_TYPE === 'SPECIALIST'" class="medication-table-container">
                        <h4><b>Prescription</b></h4>
                        <div class="table-container">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>THC %</th>
                                        <th>Route of admin</th>
                                        <th>Manufacturer</th>
                                        <th>Brand</th>
                                        <th>Strain</th>
                                        <th>Quantity</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="selectedMedication.length; else noSelectedMedication">
                                        <tr *ngFor="let item of selectedMedication; index as i">
                                            <td>{{item.id}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.category}}</td>
                                            <td>{{item.administrationRoute}}</td>
                                            <td>{{item.manufacture}}</td>
                                            <td>{{item.brandName}}</td>
                                            <td>{{item.strain}}</td>
                                            <td>
                                                <input class="form-control" [value]="item.units" type="number" min="0"
                                                    (blur)="onMedicationQTYChange($event, i, item)" 
                                                    (wheel)="disableQTYScroll($event)"
                                                    (keydown)="disableUpDownArrows($event)"/>
                                            </td>
                                            <td width="10%">
                                                <button class="btn btn-danger"
                                                    (click)="handleRemoveSelectedMedication(i, item)">
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noSelectedMedication>
                                        <tr>
                                            <td colspan="9">
                                                <span>Select a medication from the available medications table.</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="question">
                        <label for="additionalComments">Additional Comments</label>
                        <textarea class="form-control" [(ngModel)]="additionalNotes" name="additionalNotes"
                            id="additionalNotes" rows="2"></textarea>
                    </div>
                </form>

                <button class="btn btn-primary" (click)="handleFormSubmit()">
                    Submit {{userService.user.settings.DOCTOR_TYPE === 'SPECIALIST' ? 'Prescription' : 'Recommendation'}}
                </button>
            </div>
        </div>
    </div>
</div>