<div bsModal #viewGPSNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog gp-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="viewGPSNotes.hide()" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">
                    GP Notes
                </h4>
            </div>
            <div class="modal-body">
                <app-order-notes [orderstatus]="orderstatus" [orderId]="orderId"></app-order-notes>
            </div>
        </div>
    </div>
</div>