<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
                Prescription
            </h4>
        </div>
        <div class="modal-body">
            <ng-container *ngIf="!!prescription">
                <div *ngFor="let item of prescription" class="recommendations">
                    <table class="table">
                        <tbody>
                            <tr>
                              <th>
                                <p>Date</p>
                              </th>
                              <th>{{ creationDate ? (creationDate | date) : 'N/A' }}</th>
                            </tr>
                            <tr>
                                <th>
                                    <p>Type</p>
                                </th>
                                <th>Cannabis</th>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Basic details</p>
                                </th>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>{{item.formulary.category}}</td>
                            </tr>
                            <tr>
                                <td>Route of admin</td>
                                <td>{{item.formulary.administrationRoute}}</td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Patient preferences</p>
                                </th>
                            </tr>
                            <tr>
                                <td>Brand</td>
                                <td>{{item.formulary.brandName}}</td>
                            </tr>
                            <tr>
                                <td>Strain</td>
                                <td>{{item.formulary.strain}}</td>
                            </tr>
                            <tr>
                                <td>Manufacturer</td>
                                <td>{{item.formulary.manufacture}}</td>
                            </tr>
                            <tr>
                                <th>
                                    <p>Medication</p>
                                </th>
                                <th>{{item.formulary.productName}}</th>
                            </tr>
                            <tr>
                              <th>
                                <p>Quantity</p>
                              </th>
                              <th>{{item.quantity}}</th>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Additional comments</p>
                                </th>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <p>{{additionalNotes}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="pinkPrescriptionURL" class="pink-prescription">
                    <p>Pink Prescription</p>
                    <img src="{{pinkPrescriptionURL}}" alt="Pink Prescription">
                </div>
            </ng-container>
        </div>
    </div>
</div>
