import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SpecialistService } from './specialist.service';
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators, } from '@angular/forms';
import {
  AUDIT_FORM,
  GAD_FORM,
  PEG_FORM, SIDE_EFFECT_FORM,
} from 'src/assets/patientQuestionnaires';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, lastValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { UserService } from '../account/user.service';
import { SuccessfulReviewToastComponent } from './successful-review-toast.component';
import { OrderNotesService } from '../order-notes.service';
import { repeatQuestionnaire } from 'src/assets/repeatOrderQuestionnaire';
import { MEDICATION_TYPE } from 'src/assets/formulary';
import { ModalService } from '../components/modals/modal.service';

@Component({
  selector: 'app-mdt-oder-review',
  templateUrl: './specialist-order-review.component.html',
  styleUrls: ['./specialist-order-review.component.scss'],
})
export class SpecialistOrderReviewComponent implements OnInit {
  @ViewChild('specialistPrescriptionModal')
  specialistPrescriptionModal: ModalDirective;
  @ViewChild('prescriptionPinModal')
  prescriptionPinModal: ModalDirective;
  @ViewChild('pinkPrescriptionModal')
  pinkPrescriptionModal: ModalDirective;
  @ViewChild('gadModal') gadModal: ModalDirective;
  @ViewChild('pegModal') pegModal: ModalDirective;
  @ViewChild('auditModal') auditModal: ModalDirective;
  @ViewChild('previewImgModal') previewImgModal: ModalDirective;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('messageContainer') private messageContainer: ElementRef;

  public orderItem: any;
  public prevOrderItem: any;
  public patient: any;
  public orderId: number;
  public isRepeatOrder: boolean;
  public readRecommendationsAndNotes: boolean = false;
  public contactedPatient: boolean = false;
  public callProforma = '';
  public approved: boolean;
  public rejectReason: string;
  public rejectReasonDescription = '';
  public prescriptionEnabled: boolean = false;
  public prescriptionAdded: boolean = false;
  public prescriptionPin: string;
  public prescriptionGroup: FormGroup;

  // public prescriptionForm: any;
  public initLoading: boolean;
  public pinkPrescriptions: any;
  public pinkPlaceholder: string;
  public prescriptionThumbnails: any[] = [];
  public qrUrl: string;
  public selectedImage: string;
  public specialistMessages: any;
  public detailsExpanded: boolean;

  //order notes
  public mdtRecommendation: any;
  public gpRecommendation: any;
  public orderNotes: any;
  public gpNotes: any;
  public gadFormValues: any;
  public pegFormValues: any;
  public repeatOrderPegFormValues: any;
  public repeatOrderSideEffectFormValues: any;
  public repeatOrderGadFormValues: any;
  public auditFormValues: any;
  public proformaNote: any;
  public changePrescriptionReason: string;
  public repeatOrderQuestionnaire: any;
  public pharmacyRejectionDetails: any;
  public previousPrescription: any;
  public patientComplaint: any;
  public careSpecialist: string = "";

  private fileSize = 0;
  private finalizedPrescription: any;
  private filesUploadedafterPharmacyRejection: any;
  protected teleConvoRecorded = false;
  public isPinkPrescriptionUploaded: boolean = false;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private specialistService: SpecialistService,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private userService: UserService,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {
    activatedRoute.params.subscribe((params) => {
      this.orderId = params['orderId'];
    });
  }

  handleImagePreview(imgSrc: string) {
    this.selectedImage = imgSrc;
    this.previewImgModal.show();
  }

  async sendMessage(messageForm: NgForm) {
    if (!this.teleConvoRecorded && !!this.callProforma) {
      await lastValueFrom(this.specialistService.createSpecialistCalledPatient(this.orderId, this.callProforma));
    }
    await lastValueFrom(this.specialistService.createSpecialistMessagePatient(this.orderId, {question: messageForm.value.message,}));

    this.refresh();
    messageForm.resetForm();
  }

  handleSavePrescription($prescription) {
    this.finalizedPrescription = $prescription;
    this.specialistPrescriptionModal.hide();
    this.prescriptionPinModal.show();
  }

  handleSubmitPrescription(form: NgForm): void {
    this.checkPinkStatus();
    if (
      this.orderItem.status === 'AWAITING_SPECIALIST_PRESCRIPTION_CREATION' ||
      this.orderItem.status === 'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY' ||
      this.orderItem.status === 'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      this.orderItem.status === 'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL'
    ) {
      this.submitPrescription();
    } else {
      this.submitPatientCall();
    }

    this.prescriptionPinModal.hide();
  }

  openFileUploader() {
    this.fileInput.nativeElement.click();
  }

  updatePinkPrescriptions($event) {
    this.pinkPrescriptions = undefined;
    this.pinkPrescriptions = $event.currentTarget.files;
    this.setupPinkPlaceholder(this.pinkPrescriptions.length);
    this.prescriptionThumbnails = [];
    this.fileSize = 0;

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const referenceControls = {}
    for (let index = 0; index < $event.currentTarget.files.length; index++) {
      const file = $event.currentTarget.files[index];
      this.fileSize += file.size / 1024 / 1024;
      referenceControls[index] = new FormControl("", Validators.required);
      toBase64(file).then((value) => {
        this.prescriptionThumbnails.push(value);
      });
    }

    this.isPinkPrescriptionUploaded = true;
    this.prescriptionGroup = new FormGroup(referenceControls);
  }

  loadPatientComplaint(result) {
    this.patientComplaint = result.orderNotes.find((note) => note.type === 'PRESENTING_COMPLAINT')?.description
  }

  removeUploadedImage(index: number) {
    this.fileSize = 0;
    const dt = new DataTransfer();
    this.prescriptionThumbnails.splice(index, 1);
    for (let i = 0; i < this.pinkPrescriptions.length; i++) {
      if (i !== index) {
        this.fileSize += this.pinkPrescriptions[i].size / 1024 / 1024;
        dt.items.add(this.pinkPrescriptions[i]);
      }
    }

    this.pinkPrescriptions = dt.files;
    this.setupPinkPlaceholder(this.pinkPrescriptions.length);
  }

  setupPinkPlaceholder(count: number) {
    if (count === 0) {
      this.pinkPlaceholder = 'No files selected';
    }
    if (count === 1) {
      this.pinkPlaceholder = this.pinkPrescriptions[0].name;
    }
    if (count > 1) {
      this.pinkPlaceholder = `${count} files selected`;
    }
  }

  handleSubmitPinkPrescription() {
    this.pinkPrescriptionModal.hide();
  }

  handleSubmitReview(e) {
    e.preventDefault();
    if (!this.approved) {
      this.submitRejection();
    } else if (this.orderItem.status === 'AWAITING_SPECIALIST_CALL_PATIENT') {
      this.submitPatientCall();
    } else if (this.orderItem.status === 'WAITING_FOR_TRACKING_CODE') {
      this.router.navigate(['']);
    } else {
      this.uploadPrescriptions();
    }
  }

  private submitPatientCall() {
    this.specialistService
      .createSpecialistCalledPatient(this.orderId, this.callProforma)
      .subscribe({
        next: () => {
          if (this.approved) {
            this.submitPrescription();
          } else {
            this.submitRejection();
          }
        },
        error: (err) => {
          this.toastr.error(
            'Failed to update patient call',
            'Patient call update'
          );
        },
      });
  }

  private submitPrescription() {
    this.finalizedPrescription = {
      ...this.finalizedPrescription,
      orderItem: {
        id: this.orderId,
      },
      pin: this.prescriptionPin,
    };

    if (this.prescriptionAdded) {
      this.specialistService
        .updatePrescription(this.finalizedPrescription, this.orderId)
        .subscribe({
          next: () => {
            this.prescriptionAdded = true;
            this.refresh();
          },
          error: (err) => {
            let messageHeader = "Prescription"
            let messageBody = 'Failed to create prescription'

            if (err.status === 402) {
              messageHeader = `${err.error.error}!`;
              messageBody = "Payment capture from the patient failed. Preauth expired."
            }
            this.toastr.error(messageBody, messageHeader);
            this.refresh();
          },
        });
    } else {
      this.specialistService
        .createPrescription(this.finalizedPrescription)
        .subscribe({
          next: () => {
            this.prescriptionAdded = true;
            this.refresh();
          },
          error: (err) => {
            let messageHeader = "Prescription"
            let messageBody = 'Failed to create prescription'

            if (err.status === 402) {
              messageHeader = `${err.error.error}!`;
              messageBody = "Payment capture from the patient failed. Preauth expired."
            }
            this.toastr.error(messageBody, messageHeader);
            this.refresh();
          },
        });
    }
  }

  private uploadPrescriptions() {
    if (this.fileSize === 0) {
      this.toastr.warning('', 'You have to select atleast one image');
      return;
    }
    if (this.fileSize >= 25) {
      this.toastr.warning('', 'Image(s) total should be less than 25mb');
      return;
    }

    const prescriptionReferences = this.prescriptionGroup.value;

    const emptyValue = Object.keys(prescriptionReferences).find(key => !prescriptionReferences[key]);
    if (!!emptyValue) {
      this.toastr.warning('', 'Please add reference number to the selected image');
      return;
    }

    const requestList = [];
    for (let index = 0; index < this.pinkPrescriptions.length; index++) {
      const formData = new FormData();

      formData.append('files', this.pinkPrescriptions[index]);
      formData.append('reference', prescriptionReferences[index]);
      formData.append('action', 'PINK_PRESCRIPTION_UPLOAD');

      requestList.push(this.specialistService.uploadPinkPrescription(this.orderId, formData));
    }

    if (requestList.length > 0) {
      forkJoin([...requestList]).subscribe({
        complete: () => {
          this.toastr.success(
            'The order has been moved to add tracking numbers.',
            'Successfully reviewed the order!',
            { toastComponent: SuccessfulReviewToastComponent, timeOut: 4000 }
          );
          this.router.navigate(['']);
        },
        error: (err) => {
          this.toastr.error('', 'Could not upload prescription image');
          this.refresh();
        },
      })
    }
  }

  private submitRejection() {
    const passigData = {
      type: 'SPECIALIST_DECISION',
      approved: this.approved,
      description:
        this.rejectReason === 'MEDICATION_OUT_OF_STOCK'
          ? 'Medication is out of stock'
          : this.rejectReasonDescription,
      descriptionKey: this.rejectReason,
    };

    this.specialistService.createDecision(this.orderId, passigData).subscribe({
      next: () => {
        this.router.navigate(['']);
      },
      error: (err) => {
        this.toastr.error('', 'Could not update the order');
        this.refresh();
      },
    });
  }

  fetchParentOrder() {
    if (this.detailsExpanded) {
      return;
    }

    const requestList = [
      this.dataService.getOrderItem(this.orderItem.parentOrderItemId),
      this.specialistService.getPrescription(this.orderItem.parentOrderItemId),
    ];

    forkJoin(requestList).subscribe({
      next: (result) => {
        this.prevOrderItem = result[0];
        this.setupOrderNotes(result[0]);
        this.previousPrescription = result[1].medication[0].formulary;
        this.detailsExpanded = true;
      },
    });
  }

  routeToOrderPreviewPage() {
    const angularRoute = this.location.path();
    const url = window.location.href;
    const appDomain = url.replace(angularRoute, '');
    const routeUrl = `${appDomain}/order-preview/${this.orderItem.parentOrderItemId}`;
    window.open(routeUrl, '_blank');
  }

  ngOnInit() {
    this.pinkPlaceholder = 'No files selected';
    this.initLoading = true;

    this.refresh();
  }

  routeToPatientHistory() {
    const routeUrl = `${location.origin}/patient/${this.patient.id}`;
    window.open(routeUrl);
  }

  private refresh(): void {
    this.dataService.getOrderItem(this.orderId).subscribe({
      next: (result) => {
        this.dataService.getPatient(result.patient.id).subscribe((patient) => {
          this.patient = patient;
        });
        this.initValues(result);
        this.setupQR(result);
        this.pharmacyRejectionDetails =
          this.orderNotesService.getPharmacyRejectReason(result.orderNotes);
        if (
          this.filesUploadedafterPharmacyRejection === undefined &&
          this.pharmacyRejectionDetails
        ) {
          this.filesUploadedafterPharmacyRejection = false;
        } else {
          this.filesUploadedafterPharmacyRejection = true;
        }
        this.preFillForm(result);
        this.initLoading = false;
      },
    });
  }

  private initValues(result: any) {
    this.orderItem = result;

    this.careSpecialist = result?.patient?.careSpecialist ? result.patient.careSpecialist : "";
    this.specialistMessages = this.orderNotesService.getSpecialistMessages(result.orderNotes);
    this.loadPatientComplaint(result);

    const teleConvoNote = this.orderNotesService.getSpecialistCallPatient(result.orderNotes)
    if (!!teleConvoNote) {
      this.teleConvoRecorded = true;
      this.callProforma = teleConvoNote.questionAnswer;
    }

    if (result.parentOrderItemId) {
      const pegNote = this.orderNotesService.getPEGNote(result.orderNotes);
      const gadNote = this.orderNotesService.getGADNote(result.orderNotes);
      const sideEffectNote: any = this.orderNotesService.getSideEffectNote(result.orderNotes);

      this.repeatOrderPegFormValues = pegNote ? pegNote.note : undefined;
      this.repeatOrderGadFormValues = gadNote ? gadNote.note : undefined;
      this.repeatOrderSideEffectFormValues = sideEffectNote ? sideEffectNote.note : undefined;

      this.repeatOrderQuestionnaire = this.orderNotesService.getRepeatOrderQuestionnaire(result.orderNotes);

      const reasonNote = this.orderNotesService.getRepeatOrderChangeReason(result.orderNotes);
      if (reasonNote) {
        this.changePrescriptionReason = `${reasonNote?.descriptionKey} - ${reasonNote.description}`;
      }
    }

    if (result.parentOrderItemId && result.isRepeatOrder) {
      this.isRepeatOrder = true;
      this.detailsExpanded = false;
      const repeatRecommendation =
        this.orderNotesService.getRepeatOrderRecommendation(result.orderNotes);

      if (!!repeatRecommendation) {
        this.mdtRecommendation = repeatRecommendation;
      }
    } else {
      this.setupOrderNotes(result);
    }
  }

  private setupOrderNotes(result: any) {
    this.orderNotes = result.orderNotes;
    const gadNote = this.orderNotesService.getGADNote(result.orderNotes);
    this.gadFormValues = gadNote ? gadNote.note : undefined;
    this.gpNotes = this.orderNotesService.getGPConsultationNote(result.orderNotes);
    const auditNote = this.orderNotesService.getAUDITNote(result.orderNotes);
    this.auditFormValues = auditNote ? auditNote.note : undefined;
    this.proformaNote = this.orderNotesService.getMDTProforma(result.orderNotes);
    const pegNote = this.orderNotesService.getPEGNote(result.orderNotes);
    this.pegFormValues = pegNote ? pegNote.note : undefined;
    this.mdtRecommendation = this.orderNotesService.getMDTRecommendation(result.orderNotes);
    this.gpRecommendation = this.orderNotesService.getGPRecommendation(result.orderNotes);
  }

  private preFillForm(result: any) {
    if (
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      result.status === 'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY' ||
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL' ||
      result.status === 'AWAITING_SPECIALIST_PRESCRIPTION_CREATION' ||
      result.status ===
        'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION' ||
      result.status === 'WAITING_FOR_TRACKING_CODE'
    ) {
      this.approved = true;
      this.readRecommendationsAndNotes = true;
      this.contactedPatient = true;
    }

    if (
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL' ||
      result.status === 'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION'
    ) {
      this.checkPinkStatus();
    }

    if (
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      result.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL' ||
      result.status ===
        'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION' ||
      result.status === 'WAITING_FOR_TRACKING_CODE'
    ) {
      this.prescriptionAdded = true;
    }
  }

  private setupQR(result) {
    if (this.filesUploadedafterPharmacyRejection) {
      this.prescriptionThumbnails = result.files.map((file) => file.url);
    }
    const angularRoute = this.location.path();
    const url = window.location.href;
    const appDomain = url.replace(angularRoute, '');
    this.qrUrl = `${appDomain}/upload-files?orderId=${this.orderId}&userId=${this.userService.user.id}&uploadToken=${result.fileUploadToken}`;
  }

  private async checkPinkStatus() {
    const order$ = this.dataService.getOrderItem(this.orderId);
    const order = await lastValueFrom(order$);
    if (
      order.files.length > 0 &&
      order.status === 'WAITING_FOR_TRACKING_CODE'
    ) {
      this.filesUploadedafterPharmacyRejection = true;
      this.orderItem = order;
      this.prescriptionThumbnails = [];
      order.files.forEach((file) => {
        this.prescriptionThumbnails.push(file.url);
      });
      this.toastr.success('', 'Pink prescription uploaded successfully!');
      this.pinkPrescriptionModal.hide();
    } else {
      setTimeout(() => {
        this.checkPinkStatus();
      }, 5000);
    }
  }

  getErrorMessage(error: any) {
    return (
      error.error?.message || error.message || 'An unexpected error occurred'
    );
  }

  getMessage(value: string) {
    return JSON.parse(value);
  }

  getRepeatQuestionLabel(id: string) {
    return repeatQuestionnaire.find((item) => item.name === id).text;
  }

  getMedicationType() {
    return MEDICATION_TYPE;
  }

  getPEGAverage(average: string | number) {
    if (typeof average === 'string') {
      average = Math.round(parseFloat(average));
      return average;
    }

    return Math.round(average);
  }

  get prescriptionType() {
    if (this.isRepeatOrder) {
      return 'REPEAT_PRESCRIPTION';
    }
    return 'SPECIALIST_PRESCRIPTION';
  }

  get auditForm() {
    return AUDIT_FORM;
  }

  get pegForm() {
    return PEG_FORM;
  }

  get gadForm() {
    return GAD_FORM;
  }

  get sideEffectForm(): any {
    return SIDE_EFFECT_FORM;
  }

  get prescriptionBtnstatus() {
    return [
      'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL',
      'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES',
    ];
  }

  get initCheckboxstatus() {
    return (
      this.orderItem.status === 'AWAITING_SPECIALIST_PRESCRIPTION_CREATION' ||
      this.orderItem.status ===
        'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION' ||
      this.orderItem.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      this.orderItem.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL' ||
      this.orderItem.status === 'WAITING_FOR_TRACKING_CODE' ||
      this.prescriptionThumbnails.length
    );
  }

  get initDecisiontatus() {
    return (
      // this.orderItem.status === 'AWAITING_PATIENT_SPECIALIST_ALERT_REPLY' ||
      this.orderItem.status ===
        'AWAITING_PATIENT_SPECIALIST_PRESCRIPTION_CONFIRMATION' ||
      this.orderItem.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES' ||
      this.orderItem.status ===
        'WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL' ||
      this.orderItem.status === 'WAITING_FOR_TRACKING_CODE' ||
      this.prescriptionThumbnails.length
    );
  }

  get orderDetailsClassname() {
    if (!this.isRepeatOrder) {
      return '';
    }

    if (this.detailsExpanded) {
      return 'order-details-container expanded';
    }
    return 'order-details-container';
  }
}
