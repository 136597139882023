<div>
    <div class="step-wrapper" *ngIf="!parsedNote?.doctorComments">
        <div class="step-container">
            <h5>{{filteredQuestionnaire[stepPosition -1].title}}</h5>

            <div class="question-progress-container">
                <div class="progress-background">
                    <div class="progress" [style.width]="stepPercentage+'%'"></div>
                </div>
                <span>{{stepPercentage}}%</span>
            </div>
            <div class="nav-buttons">
                <button (click)="handleStepPosition(false)" type="button" class="btn btn-default"
                    [disabled]="stepPosition === 1">
                    Previous
                </button>
                <button (click)="handleStepPosition(true)" type="button" class="btn btn-primary"
                    [disabled]="stepPosition === filteredQuestionnaire.length">
                    Next
                </button>
            </div>
        </div>
    </div>
    <form [formGroup]="notesGroup" autocomplete="off" *ngIf="!!notesGroup" novalidate>
        <section *ngIf="!!parsedNote.doctorComments; else initialForm" class="questions-container">
            <div class="question">
                <label>Doctor's notes</label>
                <textarea class="form-control" name="doctorComment" id="doctorComment" rows="2"
                    formControlName="doctorComments" (blur)="handleOnGPNotesBlur()"></textarea>
            </div>
        </section>

        <ng-template #initialForm>
            <section class="questions-container">
                <div *ngFor="let questionsList of filteredQuestionnaire">
                    <ng-container *ngIf="questionsList.step === stepPosition">
                        <div *ngFor="let question of questionsList.questions">
                            <ng-container *ngIf="getIsQuestionRendered(question)">
                                <div *ngIf="question.type !== getQuestionStructures().signal" class="question"
                                    [formGroupName]="question.questionId">

                                    <label
                                        *ngIf="question.question !== '' && question.type !== getQuestionStructures().signal"
                                        [for]="'question.questionId'">{{question.question}}</label>

                                    <div *ngIf="question.type === getQuestionStructures().checkbox || question.type === getQuestionStructures().checkboxAndText"
                                        formArrayName="choices">
                                        <ng-container *ngFor="let choice of question.choices; index as i">
                                            <div [formGroupName]="i"
                                                [style.margin-top]="choice.id === 'none' ? '1rem': ''">
                                                <label>
                                                    <input [id]="choice.id" type="checkbox" [name]="choice.id"
                                                        [equal]="true" formControlName="checked"
                                                        (blur)="handleOnGPNotesBlur()" />
                                                    <span> {{choice.label}} </span>
                                                </label>
                                                <ng-container
                                                    *ngIf="textareaVisibility(question, i) && !!question.tbTitle">
                                                    <br>
                                                    <label>
                                                        {{question.tbTitle}}
                                                    </label>
                                                </ng-container>
                                                <textarea class="checkbox-text form-control"
                                                    *ngIf="textareaVisibility(question, i)" [name]="choice.id+'Desc'"
                                                    formControlName="text" [id]="choice.id+'Desc'" rows="2"
                                                    (blur)="handleOnGPNotesBlur()"></textarea>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div *ngIf="question.type === getQuestionStructures().choiceAndTextOnCondition || question.type === getQuestionStructures().choiceAndText || question.type === getQuestionStructures().choice"
                                        class="radio_control">
                                        <div *ngIf="question.choices === undefined; else hasChoices"
                                            class="radio_control">
                                            <input type="radio" [value]="true" formControlName="choice" [name]="choice"
                                                [id]="[question.questionId]+'Yes'" (blur)="handleOnGPNotesBlur()" />
                                            <label [for]="[question.questionId]+'Yes'">Yes</label>
                                            <input type="radio" [value]="false" formControlName="choice" [name]="choice"
                                                [id]="[question.questionId]+'No'" (blur)="handleOnGPNotesBlur()" />
                                            <label [for]="[question.questionId]+'No'">No</label>
                                        </div>

                                        <ng-template #hasChoices>
                                            <div *ngFor="let choice of question.choices" class="radio_control">
                                                <input type="radio" [value]="choice.label" formControlName="choice"
                                                    [id]="[question.questionId]+'_'+choice.id"
                                                    (blur)="handleOnGPNotesBlur()" />
                                                <label
                                                    [for]="[question.questionId]+'_'+choice.id">{{choice.label}}</label>
                                            </div>
                                        </ng-template>
                                    </div>

                                    <ng-container *ngIf="textareaVisibility(question) && !!question.tbTitle">
                                        <br>
                                        <label>
                                            {{question.tbTitle}}
                                        </label>
                                    </ng-container>
                                    <textarea *ngIf="textareaVisibility(question)" class="form-control"
                                        [name]="textareaId(question)" formControlName="text" [id]="textareaId(question)"
                                        [rows]="question.rows" [placeholder]="getPlaceholder(question)"
                                        (blur)="handleOnGPNotesBlur()"></textarea>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </section>
        </ng-template>
    </form>
</div>