<div>
	<form #prescriptionForm="ngForm" (ngSubmit)="updateRecommendation()" novalidate autocomplete="off">

		<div *ngIf="type === 'SPECIALIST_PRESCRIPTION' || type === 'REPEAT_PRESCRIPTION' ">
			<input type="checkbox" name="enableEdit" id="enableEdit" [(ngModel)]="prescriptionEnabled" />
			<label class="radio-label" for="enableEdit">
				Change recommendation added by the
				{{type ==='SPECIALIST_PRESCRIPTION' ? 'MDT' : 'previous prescription'}}
			</label>
		</div>

		<div class="prescription-form">

			<div class="question-section">
				<div class="question">
					<label for="medicationType">Medication type</label>
					<select class="form-control" name="medicationType" [(ngModel)]="medicationType" id="medicationType"
						required [disabled]="!prescriptionEnabled">
						<option *ngFor="let type of getMedicationType()" [value]="type">{{type}}</option>
					</select>
				</div>
			</div>

			<div class="question-section">
				<h5><b>Doctor Recommendations</b></h5>

				<div class="question">
					<label for="category">THC %</label>
					<select class="form-control" name="category" [ngModel]="category" required
						(ngModelChange)="thcPercentageChange($event)" id="category" [disabled]="!prescriptionEnabled">
						<option *ngFor="let percentage of thcList" [value]="percentage">{{percentage}}
						</option>
					</select>
				</div>

				<div class="question">
					<label for="route">Route of admin</label>
					<select class="form-control" [class]="getFieldAvailability('route') ? 'error-field': ''"
						name="route" [ngModel]="route" (ngModelChange)="routeChange($event)" id="route"
						[disabled]="!prescriptionEnabled" required>
						<option [value]="-1" hidden></option>
						<option *ngFor="let route of routes" [value]="route">{{route}}</option>
					</select>
					<span *ngIf="getFieldAvailability('route')" class="error-text">{{noRecord.value}}</span>
				</div>
			</div>

			<div class="question-section">
				<h5><b>Patient preference</b></h5>

				<div class="question">
					<label for="manufacturer">Manufacturer</label>
					<select class="form-control" [class]="getFieldAvailability('manufacturer') ? 'error-field': ''"
						name="manufacturer" [ngModel]="manufacturer" required
						(ngModelChange)="manufactureChange($event)" id="manufacturer" [disabled]="!prescriptionEnabled">
						<option [value]="-1" hidden></option>
						<option *ngFor="let item of manufacturers" [value]="item">{{item}}</option>
					</select>
					<span *ngIf="getFieldAvailability('manufacturer')" class="error-text">{{noRecord.value}}</span>
				</div>

				<div class="question">
					<label for="brand">Brand</label>
					<select class="form-control" [class]="getFieldAvailability('brand') ? 'error-field': ''"
						name="brand" [ngModel]="brand" (ngModelChange)="brandChange($event)" id="brand"
						[disabled]="!prescriptionEnabled" required>
						<option [value]="-1" hidden></option>
						<option *ngFor="let item of brands" [value]="item">{{item}}</option>
					</select>
					<span *ngIf="getFieldAvailability('brand')" class="error-text">{{noRecord.value}}</span>
				</div>

				<div class="question">
					<label for="strain">Strain</label>
					<select class="form-control" [class]="getFieldAvailability('strain') ? 'error-field': ''"
						name="strain" [ngModel]="strain" (ngModelChange)="strainChange($event)" id="strain"
						[disabled]="!prescriptionEnabled" required>
						<option [value]="-1" hidden></option>
						<option *ngFor="let item of strains" [value]="item">{{item}}</option>
					</select>
					<span *ngIf="getFieldAvailability('strain')" class="error-text">{{noRecord.value}}</span>
				</div>
			</div>

			<div class="question-section">
				<div class="question">
					<label for="comments">
						Additional comments {{type === 'REPEAT_PRESCRIPTION' ? 'from the previous order' : ''}}
					</label>
					<textarea class="form-control" name="comments" required [(ngModel)]="comments" id="" rows="3"
						[disabled]="!prescriptionEnabled"></textarea>
				</div>
			</div>

			<ng-container *ngIf="medicationList.length">
				<h5><b>Available medicines</b></h5>
				<div class="table-container">
					<table class="table table-striped table-hover">
						<thead>
							<tr>
								<th>ID</th>
								<th>Name</th>
								<th>Quanity</th>
								<th>Original strain</th>
								<th>Benefits</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of medicationList">
								<td>{{item.id}}</td>
								<td>{{item.productName}}</td>
								<td>{{item.availQuantity ? item.availQuantity : 0}}</td>
								<td>{{item.originalStrain}}</td>
								<td>{{item.benifits}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</ng-container>

			<div *ngIf="type === 'SPECIALIST_PRESCRIPTION' || type === 'REPEAT_PRESCRIPTION'"
				class="question-section last">
				<div class="question">
					<label for="manufacturer">Medications</label>
					<select class="form-control" name="medications" [(ngModel)]="medicationSelect"
						(ngModelChange)="handleAddMedication($event)" id="medications">
						<option [value]="-1" hidden></option>
						<option *ngFor="let med of medicationList" [value]="med | json"
							[disabled]="selectedPharmacy !== undefined && selectedPharmacy !== med.pharmacyId">
							{{med.productName}}
						</option>
					</select>

					<div class="medications-list">
						<ng-container *ngFor="let med of medications">
							<div class="medication-item">
								<div class="remove-btn" (click)="handleRemoveMedication(med)">&#10006;</div>
								<div class="item-details" [class]="med === selectedMedication ? 'expanded' : ''"
									(click)="handleExpandMedication(med)">
									<div class="header">
										<h5><b>{{med.productName}}</b></h5>
										<img src="/assets/vectors/expand.svg" alt="">
									</div>

									<div class="content">
										<div>
											<span class="attribute-key">Medicine profile</span>
											<span>: {{med.medicineProfile}}</span>
										</div>

										<div>
											<span class="attribute-key">Original strain</span>
											<span>: {{med.originalStrain}}</span>
										</div>

										<div>
											<span class="attribute-key">Original country</span>
											<span>: {{med.originCountry}}</span>
										</div>

										<div>
											<span class="attribute-key">Benefits</span>
											<span>: {{med.benifits}}</span>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>

			<button type="submit" class="btn btn-primary submit-btn">
				{{getSubmitButtonText()}}
			</button>
		</div>
	</form>
</div>