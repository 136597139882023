import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalOptions } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class FormularyService {

  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions) {
  }

  public async initFormulary(companyId: number, forceReload = false) {
    const timestamp = Date.now()
    if (forceReload || !this.cachedFormulary || this.cachedFormulary.timestamp < timestamp - 1000 * 60 * 60 * 24 || this.cachedFormulary.companyId !== companyId) {
      const data = await this.fetchFormulary('', '', 'all', companyId).toPromise();
      this.cachedFormulary = { data, timestamp, companyId };
    }
    return true;
  }

  public updateFormulary(medication: any, action: 'CREATE' | 'UPDATE' | 'DELETE') {
    const body = { medications: [medication] }
    let url = `${this.options.apiEndPoint}api/doctor/company-formulary`;
    switch (action) {
      case 'CREATE':
        return this.http.post<any>(url, body);
      case 'UPDATE':
        return this.http.put<any>(url, body);
      case 'DELETE':
        return this.http.request<any>('DELETE', url, { body });
    }
  }

  public searchFormulary(query: string, page: number, size = 10) {
    let data = this.cachedFormulary.data;
    if (query) {
      let searchResults = data.filter((item: { name: string, formulation: string, strength: string, id:number }) => {
        if ((item.name.toLowerCase().includes(query.toLowerCase())) || (item.formulation.toLowerCase().includes(query.toLowerCase())) || (item.strength == query) || (item.id.toString() == query)  ){
          return true;
        }
      });
      return this.paginateAndFormatContent(searchResults, page, size);
    } else {
      // get from cache without filtering, but with offset according to pagination
      return this.paginateAndFormatContent(data, page, size);
    }
  }

  private fetchFormulary(query: string, page: any, size: any, companyId: number = null) {
    let formulary = companyId ? 'company-formulary' : 'formulary';
    let url = `${this.options.apiEndPoint}api/doctor/${formulary}`;

    if (typeof size === 'string' && (size as string).toLowerCase() === 'all') {
      url = `${url}/all`
    } else {
      url = `${url}?`
      if (query) {
        url += `&q=${query}`;
      }
      if (typeof page === 'number') {
        url += `&page=${page}`;
      }
      if (typeof size === 'number' && size > 0) {
        url += `&size=${size}`;
      } else {
        url += `&size=10`;
      }
    }
    return this.http.get<any>(url);
  }

  public set cachedFormulary(value: CachedFormulary) {
    if (value == null) {
      localStorage.removeItem('cachedFormulary');
    } else {
      value.timestamp = Date.now();
      localStorage.setItem('cachedFormulary', JSON.stringify(value));
    }
  }

  public get cachedFormulary(): CachedFormulary {
    const cachedFormularyString = localStorage.getItem('cachedFormulary');
    return cachedFormularyString ? JSON.parse(cachedFormularyString) : null;
  }

  private paginateAndFormatContent(content: any[], page: number, size: number) {
    const paginatedContent = content.slice(page * size, (page + 1) * size);
    return {
      content: paginatedContent,
      number: page,
      totalPages: (content.length % size === 0 ? (content.length / size) : (Math.floor(content.length / size) + 1))
    };
  }

}

interface CachedFormulary {
  data: any[];
  timestamp: number;
  companyId: number;
}
