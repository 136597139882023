import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../account/user.service';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-mdt-session',
  templateUrl: './mdt-session.component.html',
  styleUrls: ['./mdt-session.component.scss'],
})
export class MdtSessionComponent implements OnInit {
  @ViewChild('ordersModal') ordersModal: ModalDirective;
  @ViewChild('sessionApprovalModal') sessionApprovalModal: ModalDirective;

  sessionOwner = false;
  orders: any[];
  activeSession: any;
  canFinalize = false;

  //Models for committee approval form
  memberApproved: boolean;
  memberDescription: string;

  constructor(
    private mdtService: MdtService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchSessionOrders();
  }

  private fetchSessionOrders() {
    this.mdtService.getActiveSession().subscribe({
      next: (value) => {
        this.activeSession = value;
        if (value.doctor.id === this.userService.user.id) {
          this.sessionOwner = true;
        }
        this.orders = value.orders;

        let finalize = false;
        for (const order of value.orders) {
          if (order.status === 'SELECTED_FOR_MDT_SESSION') {
            finalize = false;
            break;
          }
          finalize = true;
        }
        this.canFinalize = finalize;
      },
      error: (error) => {
        if (error.error.text === "No active MDT sessions found") {
          this.router.navigate(['/mdt-orders']);
        }
      },
    });
  }

  removeFromSession($event) {
    if (this.orders.length === 1) {
      this.toastr.info("A session must have atleast one order!", "Can not remove order")
      return
    }
    const removingItem = {
      orderItemId: $event,
      inSession: false,
    };

    this.mdtService
      .editSessionOrders(this.activeSession.id, [removingItem])
      .subscribe({
        next: (value) => {
          this.fetchSessionOrders();
          this.toastr.info('', 'Order removed from session successfully!');
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onSessionUpdated() {
    this.fetchSessionOrders();
  }

  onFinalizeSession() {
    this.mdtService.finalizeMDTSession(this.activeSession.id).subscribe({
      next: (value) => {
        this.fetchSessionOrders()
        console.log(value);
        this.toastr.success('', 'Session finalised successfully!');
      },
    });
  }

  onSessionApproval(form: NgForm) {
    if (form.invalid) {
      this.toastr.warning('', 'Please fill the fields!');
      return;
    }

    const data = {
      sessionId: this.activeSession.id,
      approved: this.memberApproved,
      description: this.memberDescription,
    };

    this.mdtService.createMDTSessionApproval(data).subscribe({
      next: (value) => {
        this.fetchSessionOrders()
        console.log(value)
        this.toastr.success('', "Your response has been saved!")
        form.resetForm()
        this.sessionApprovalModal.hide()
      }, 
      error: (err) => {
        console.log(err)
      }
    })
  }

  getApprovalVisibility(){
    let approvalItem = {
      voted: false,
      approved: undefined
    }
    for (const note of this.activeSession.sessionNotes) {
      if (note.doctor.id === this.userService.user.id) {
        approvalItem = {
          voted: true,
          approved: note.approved
        }
        break;
      }
    }

    return approvalItem
  }
}
