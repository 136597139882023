<div class="container">

    <div *ngIf="!formularies" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>

    <ng-container *ngIf="formularies">
        <h1 class="brand-1">Formularies</h1>

        <div class="formulary-menu">
            <span>Sort by</span>
            <select class="form-control" (change)="handleFilterChange($event)">
                <option value="-1" disabled selected hidden>Select option to sort</option>
                <option value="pharmacy">Pharmacy</option>
                <option value="route">Administration route</option>
                <option value="category">Category</option>
            </select>
        </div>

        <div class="table-container">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Pharmacy</th>
                        <th>Brand</th>
                        <th>Product</th>
                        <th>Category</th>
                        <th>Route</th>
                        <th>Profile</th>
                        <th>Manufacturer</th>
                        <th>Strain</th>
                        <th>Org. Strain</th>
                        <th>Org. Country</th>
                        <th>Benefits</th>
                        <th>Stock Status</th>
                        <th>Unit Qty</th>
                        <th>Sale Price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of formularies">
                        <td>{{item.stringId}}</td>
                        <td>{{getPharmacyName(item.pharmacyId)}}</td>
                        <td>{{item.brandName}}</td>
                        <td>{{item.productName}}</td>
                        <td>{{item.category}}</td>
                        <td>{{item.administrationRoute}}</td>
                        <td>{{item.medicineProfile}}</td>
                        <td>{{item.manufacture}}</td>
                        <td>{{item.strain}}</td>
                        <td>{{item.originalStrain}}</td>
                        <td>{{item.originCountry}}</td>
                        <td>{{item.benifits}}</td>
                        <td>{{item.stockStatus | stockStatus}}</td>
                        <td>{{item.quantity}}</td>
                        <td>{{item.unitPrice}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>