import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GlobalOptions } from '../app.module';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private jwtHelperService = new JwtHelperService();
  private _authorised$: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private parentRouter: Router,
    @Inject('global_options') private options: GlobalOptions
  ) {
    this._authorised$ = new BehaviorSubject(this.loggedIn());
  }

  get authorised$() {
    return this._authorised$.asObservable();
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.options.apiEndPoint + 'login', {
      username: username,
      password: password,
      type: 'doctor',
    });
  }

  loggedIn() {
    const token = localStorage.getItem(`doctor.id_token`);
    return this.jwtHelperService.isTokenExpired(token) == false;
  }

  logout() {
    localStorage.removeItem(`doctor.id_token`);
    this._authorised$.next(false);
    this.parentRouter.navigate(['/login']);
  }

  blacklistToken() {
    sessionStorage.clear();
    const url = `${this.options.apiEndPoint}logout`;
    return this.http.post<any>(url, {});
  }

  authenticate(token: string) {
    localStorage.setItem(`doctor.id_token`, token);
    this._authorised$.next(true);
  }

  registerForTwoFactorAuth(email: string, password: string) {
    return this.http.post<any>(
      `${this.options.apiEndPoint}2fa?action=register`,
      {
        username: email,
        password: password,
        type: 'doctor',
      }
    );
  }

  enableTwoFactorAuth(
    email: string,
    password: string,
    key: string,
    topt: number
  ) {
    return this.http.post<any>(`${this.options.apiEndPoint}2fa?action=enable`, {
      username: email,
      password: password,
      type: 'doctor',
      key: key,
      topt: topt,
    });
  }

  disableTwoFactorAuth(email: string, password: string) {
    return this.http.post<any>(
      `${this.options.apiEndPoint}2fa?action=disable`,
      {
        username: email,
        password: password,
        type: 'doctor',
      }
    );
  }

  verifyOTP(topt: number, email: string, password: string, key: string) {
    return this.http.post<any>(`${this.options.apiEndPoint}verify`, {
      password: password,
      username: email,
      type: 'doctor',
      topt: topt,
      key: key,
    });
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${this.options.apiEndPoint}forget-password`, {
      username: email,
      type: 'doctor',
    });
  }

  resetPin(email: string, token: string, pin: string) {
    return this.resetPassword(email, token, pin, 'PIN');
  }

  resetPassword(
    email: string,
    token: string,
    password: string,
    type = 'PASSWORD'
  ) {
    return this.http.post<any>(
      `${this.options.apiEndPoint}reset-password?auth-type=${type}`,
      {
        username: email,
        token: token,
        newPassword: password,
        confirmNewPassword: password,
        type: 'doctor',
      }
    );
  }

  changePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ) {
    return this.http.post<any>(`${this.options.apiEndPoint}change-password`, {
      username: username,
      oldPassword: currentPassword,
      newPassword: newPassword,
      userType: 'doctor',
    });
  }

  setSecurityDetails(doctor: any) {
    return this.http.post<any>(
      `${this.options.apiEndPoint}set-doctor-security-details`,
      {
        username: doctor.username,
        password: doctor.password,
        pin: doctor.pin,
        token: doctor.token,
      }
    );
  }

    authenticateQuickSilvaDoctor(user: string, subject: string) {
        return this.http.post<any>(`${this.options.apiEndPoint}auth/quicksilva`, {
            user: user,
            subject: subject
        });
    }
}
