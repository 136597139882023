import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalOptions } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class VideoTokenService {

    constructor(private http: HttpClient,
        @Inject('global_options') private options: GlobalOptions) { }

    getToken(appointmentId: string) {
        let url = `${this.options.apiEndPoint}api/doctor/appointment/${appointmentId}?action=token`;
        return this.http.get<any>(url);
    }
}
