import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-info',
  templateUrl: './doctor-info.component.html'
})
export class DoctorInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
