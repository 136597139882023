import { Component, OnInit, ViewChild } from '@angular/core';

import { Observable ,  Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-pin-check',
    templateUrl: './pin-check.component.html'
})
export class PinCheckComponent implements OnInit {
    @ViewChild('modal', { static: true }) modal: ModalDirective;
    pin: string;

    private subject = new Subject<string>();

    constructor() { }

    ngOnInit() { }

    open(): Observable<string> {
        this.modal.show();
        return this.subject.asObservable();
    }

    onCancel() {
        this.modal.hide();
        this.subject.next(null);
    }

    onSubmit() {
        this.modal.hide();
        this.subject.next(this.pin);
        this.pin = null;
    }
}