import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SIDE_EFFECT_FORM} from '../../../../assets/patientQuestionnaires';

@Component({
  selector: 'app-side-effect-note-modal',
  templateUrl: './side-effect-note-modal.component.html',
  styleUrls: ['./side-effect-note-modal.component.scss']
})
export class SideEffectNoteModalComponent implements OnInit {
  @Input() sideEffectNote: any;
  @Output() hideModal = new EventEmitter();


  constructor() {
    console.log('sideEffectNote', this.sideEffectNote);
  }

  ngOnInit(): void {
  }

  get sideEffectForm(): any {
    return SIDE_EFFECT_FORM;
  }
}
