import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { Location } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { DataService } from '../data.service';
import { DomSanitizer } from '@angular/platform-browser';
import {
  questionnaire,
  questionStructure,
  questionType,
  signalFormTypes,
} from 'src/assets/questionnaire';
import { ToastrService } from 'ngx-toastr';
import {
  AUDIT_FORM,
  CONSENT_FORM,
  GAD_FORM,
  PEG_FORM,
} from 'src/assets/patientQuestionnaires';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { lastValueFrom, debounceTime } from 'rxjs';
import { Router } from '@angular/router';
import { OrderNotesService } from '../order-notes.service';
import { ModalService } from '../components/modals/modal.service';
import { debounce } from 'lodash-es';

export interface INoteSheet {
  [key: string]: {
    choices?: { checked: boolean | null }[];
  };
}

@Component({
  selector: 'app-edit-patient-notes',
  templateUrl: './edit-patient-notes.component.html',
  styleUrls: ['edit-patient-notes.component.scss'],
})
export class EditPatientNotesComponent implements OnInit {
  @Input() videoCall: any;
  @Input() order: any;
  @Input() patient: any;
  @Input() orders: any[];
  @Input() patientIdentified: boolean;
  @Input() submitted: boolean;
  @Input() ordersInTheLast28DaysWithPrescription: any = [];
  @Output() displayPreviousMedications = new EventEmitter();
  @Output() signalForForms = new EventEmitter();
  @Output() updateCompletion = new EventEmitter();
  @Output() fetchPatient = new EventEmitter();
  @ViewChild('auditModal') auditModal: ModalDirective;
  @ViewChild('pegModal') pegModal: ModalDirective;
  @ViewChild('gadModal') gadModal: ModalDirective;
  @ViewChild('consentModal') consentModal: ModalDirective;
  @ViewChild('auditFormModal') auditFormModal: ModalDirective;
  @ViewChild('gadFormModal') gadFormModal: ModalDirective;
  @ViewChild('pegFormModal') pegFormModal: ModalDirective;
  @ViewChild('consentFormModal') consentFormModal: ModalDirective;
  @ViewChild('pendingModal') pendingModal: ModalDirective;
  @ViewChild('prescriptionModal') prescriptionModal: ModalDirective;
  @ViewChild('verifyNHSModal') verifyNHSModal: ModalDirective;
  @ViewChild('formContainer') private formContainer: ElementRef;
  public registeredLocation = true;
  public followUpConsultation = false;
  public showAlerts = false;
  public enterprise = '';
  public stepPosition = 1;
  // Whether asked
  public alertsAsked: boolean;
  public relevantPastMedicalHistoryAsked: boolean;
  public relevantPastFamilyHistoryAsked: boolean;
  public medicationHistoryAsked: boolean;
  public allergyInformationAsked: boolean;
  public examinationFindingsAsked: boolean;
  public patientLocationAsked: boolean;
  public followUpAsked: boolean;
  public shareDetailsWithGpAsked: boolean;
  public selectedTab: string;
  public summaryCareRecordsData: any;
  public withConsent: boolean;
  //Form groups
  notesGroup: FormGroup;
  conditionalRenderingQList: questionType[] = [];

  isSubmitting = false;
  recommendation: any;
  repeatOrderQuestionnaire: any;
  addRecommendation = false;
  gpNotes: any;
  gpNoteId: any;
  patientComplaint:any;

  patientNotes = {};
  containsDocuments = false;
  stepPercentage = 0;

  gadFormFilled: boolean;
  nhsNumber: number;

  pegData: any;
  consentData: any;

  @ViewChild('form', { static: true }) form: NgForm;
  signalFormStatus = {};
  debouncedInputChange = debounce(this.handleTextareaChange, 800);

  constructor(
    protected dataService: DataService,
    public _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private location: Location,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {}

  async initializeGPNotes() {
    const orderNote = this.orderNotesService.getGPConsultationNote(
      this.order.orderNotes
    );
    if (!!orderNote) {
      this.gpNotes = JSON.parse(orderNote[0].questionAnswer);
      this.gpNoteId = orderNote[0].id;
    }
    if (!this.gpNotes) {
      const consultationNotes = {
        type: 'GP_CONSULTATION_NOTES',
        questionAnswer: JSON.stringify({}),
      };
      await lastValueFrom(
        this.dataService.createPatientNote(this.order.id, consultationNotes)
      );
      const consultationNote = await lastValueFrom(
        this.dataService.getConsultationNote(this.order.id)
      );
      this.gpNotes = {};
      this.gpNoteId = consultationNote[0].id;
    }

    this.createNotesForm();
  }

  async saveNote(){
    let noteSheet = this.notesGroup.value;

    if (!!this.conditionalRenderingQList.length) {
      this.conditionalRenderingQList.forEach((question) => {
        const { parentId, selectionKey, decisionValue } =
          question.renderCondition;
        if (noteSheet[parentId][selectionKey] !== decisionValue) {
          delete noteSheet[question.questionId];
        }
      });
    }

    for (const [key, { choices }] of Object.entries(noteSheet as INoteSheet)) {
      if (!!choices) {
        const filteredChoices = choices.filter(
          (choice: any) => choice.checked !== null
        );
        noteSheet[key].choices = filteredChoices;
      }
    }

    const consultationNotes = {
      id: this.gpNoteId,
      type: 'GP_CONSULTATION_NOTES',
      questionAnswer: JSON.stringify(noteSheet),
    };

    await lastValueFrom(
        this.dataService.updatePatientNote(this.order.id, consultationNotes).pipe(debounceTime(500))
      )
  }

  async handleOnGPNotesBlur() {
    this.saveNote();
  }

  handleCheckboxChange(value: boolean, key: string, index: number) {
    const choicesList = this.notesGroup.value[key].choices;

    if (!value) {
      const choicePayload = {
        ...this.notesGroup.value[key].choices[index],
        text: ""
      }
      choicesList[index] = choicePayload;
      this.notesGroup.patchValue({
        [key]: {
          ...this.notesGroup.value,
          choices: choicesList
        }
      })
    }
    this.saveNote();
  }

  handleRadioButtonChange(question: questionType){
    const { type, condition, questionId } = question;
    const noteValue = this.notesGroup.value[question.questionId]
    if (type === questionStructure.choiceAndTextOnCondition) {
      if (typeof condition === 'boolean' && noteValue.choice !== condition) {
        this.notesGroup.patchValue({
          [questionId]: {
            ...noteValue,
            text: "",
          }
        })
      }

      if (typeof condition === 'object' && !condition[noteValue.choice]) {
        this.notesGroup.patchValue({
          [questionId]: {
            ...noteValue,
            text: "",
          }
        })
      }
    }

    this.saveNote();
  }

  handleTextareaChange(){
    this.saveNote();
  }

  loadPatientComplaint() {
    this.patientComplaint = this.order.orderNotes.find((note) => note.type === 'PRESENTING_COMPLAINT')?.description
  }

  ngOnInit() {
    this.addRecommendation = Boolean(localStorage.getItem(`${this.order.id}addRecommendation`)) || false;
    
    this.initializeGPNotes();
    this.repeatOrderQuestionnaire =
      this.orderNotesService.getRepeatOrderQuestionnaire(this.order.orderNotes);

    if (this.order) {
      this.fetchPatientNotes();
      this.loadPatientComplaint();
    }
  }

  private createNotesForm() {
    const noteControls = {};
    const consultationNotes = this.gpNotes;

    const isChecked = (questionStructChoice: {label: string, id: string}, noteChoices: any[] = []) => {
      const selectedChoice = noteChoices.find(item => item.id === questionStructChoice.id)
      if (!!selectedChoice) {
        return {
          checked: new FormControl(selectedChoice.checked),
          text: new FormControl(selectedChoice.text),
        }
      }
      return {
        checked: new FormControl(undefined),
        text: new FormControl(undefined),
      }
  
    }

    if (!!this.order.parentOrderItemId) {
      noteControls['doctorComments'] = new FormControl(
        consultationNotes?.['doctorComments'] || undefined,
        Validators.required
      );
    } else {
      questionnaire.forEach((questionSet) => {
        for (let i = 0; i < questionSet.questions.length; i++) {
          const questionStruct = questionSet.questions[i];

          if (
            !questionStruct.active ||
            questionStruct.type === questionStructure.signal
          ) {
            continue;
          }

          if (questionStruct.type === questionStructure.text) {
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              text: new FormControl(
                consultationNotes?.[questionStruct.questionId]?.text ||
                  undefined,
                Validators.required
              ),
            });
            continue;
          }

          if (
            questionStruct.type === questionStructure.choice ||
            questionStruct.type === questionStructure.choiceAndText ||
            questionStruct.type === questionStructure.choiceAndTextOnCondition
          ) {
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              text: new FormControl(
                consultationNotes?.[questionStruct.questionId]?.text || ''
              ),
              choice: new FormControl(
                consultationNotes
                  ? consultationNotes[questionStruct.questionId]?.choice
                  : undefined,
                Validators.required
              ),
            });
            continue;
          }

          if (
            questionStruct.type === questionStructure.checkbox ||
            questionStruct.type === questionStructure.checkboxAndText
          ) {
            const checkboxArray = questionStruct.choices.map(
              (choice, index) =>
                new FormGroup({
                  label: new FormControl(choice.label),
                  ...isChecked(choice, consultationNotes[questionStruct.questionId]?.choices),
                  id: new FormControl(choice.id),
                 })
            );
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              choices: new FormArray(checkboxArray),
            });
            continue;
          }
        }
      });
    }

    this.notesGroup = new FormGroup(noteControls);
  }

  private fetchPatientNotes() {
    this.dataService.getOrderNotes(this.order.id).subscribe({
      next: (value) => {
        if (value.orderNotes && value.orderNotes.length > 0) {
          this.structurePatientNotes(value.orderNotes);
        }

        this.pegData = this.orderNotesService.getPEGNote(value.orderNotes)
        this.consentData = this.orderNotesService.getConsentNote(value.orderNotes)
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private structurePatientNotes(notes: any[]) {
    const noteStructure = {};
    notes.forEach((note) => {
      switch (note.type) {
        case 'GAD_FORM':
          this.gadFormFilled = true;
          break;
        case 'PEG_FORM':
          this.signalFormStatus[signalFormTypes.PEG_FORM] = 'filled';
          break;
        case 'PATIENT_DETAIL_CONFIRMATION':
          this.signalFormStatus[signalFormTypes.PATIENT_DETAIL_CONFIRMATION] =
            'filled';
          break;

        default:
          break;
      }
      noteStructure[note.type] = note;
    });

    this.patientNotes = noteStructure;
  }

  handleOpenPDF(item: string) {
    let pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${encodeURI(item)}'></iframe>`
    );
  }

  handleSignal(formType: string, doctorFills: boolean) {
    if (
      this.checksignalStatus(formType, 'filled') ||
      this.checksignalStatus(formType, 'pending') ||
      this.patientNotes[formType] !== undefined
    ) {
      switch (formType) {
        case signalFormTypes.AUDIT_FORM:
          this.auditFormModal.show();
          break;

        case signalFormTypes.GAD_FORM:
          this.gadFormModal.show();
          break;

        case signalFormTypes.PATIENT_DETAIL_CONFIRMATION:
          this.consentFormModal.show();
          break;

        case signalFormTypes.PEG_FORM:
          this.pegFormModal.show();
          break;

        default:
          break;
      }
      return;
    }

    if (doctorFills) {
      switch (formType) {
        case signalFormTypes.AUDIT_FORM:
          this.auditFormModal.show();
          break;

        case signalFormTypes.GAD_FORM:
          this.gadFormModal.show();
          break;

        default:
          break;
      }
      return;
    }

    if (this.videoCall.hasError) {
      switch (formType) {
        case signalFormTypes.PEG_FORM:
          this.pegFormModal.show();
          break;

        case signalFormTypes.PATIENT_DETAIL_CONFIRMATION:
          this.consentFormModal.show();
          break;

        default:
          break;
      }
      return;
    }

    this.signalForForms.emit(formType);
  }

  handleSignalStatus(value) {
    if (value.status === 'filled') {
      this.fetchPatientNotes();
    }
    this.signalFormStatus[value.key] = value.status;
  }

  handleAddRecommendationDecision($event) {
    localStorage.setItem(`${this.order.id}addRecommendation`, $event);
    this.addRecommendation = $event;
  }

  checksignalStatus(key: string, type: 'pending' | 'filled') {
    if (this.videoCall.hasError) {
      return false;
    }

    if (
      this.signalFormStatus[key] !== undefined &&
      this.signalFormStatus[key] === type
    ) {
      return true;
    }

    return false;
  }

  updateRecommendation($event) {
    this.recommendation = $event;
  }

  submitDecision(decisions: FormGroup) {
    this.stepPercentage = 100;
    this.saveRecommendation();
    this.saveDecision(decisions.value);
  }

  saveRecommendation() {
    if (
      this.recommendation === undefined ||
      Object.keys(this.recommendation).length === 0
    ) {
      return;
    }

    const passingData = {
      type: 'GP_RECOMMENDATION',
      formulary: JSON.stringify(this.recommendation),
    };

    this.dataService
      .createRecommendation(this.order.id, passingData)
      .subscribe({
        next: (value) => {
          this.toastr.success('', 'Recommendation submitted successfully!');
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  routeToPatientHistory() {
    const routeUrl = `${location.origin}/patient/${this.patient.id}`;
    window.open(routeUrl);
  }

  saveDecision(decisionValues: any) {
    let description = decisionValues.description;
    if (!decisionValues.approved && decisionValues.reason !== '') {
      description = `${decisionValues.reason} - ${description}`;
    }
    const passingData = {
      type: 'GP_APPROVAL_DECISION',
      approved: decisionValues.approved,
      description,
    };

    this.dataService.createPatientNote(this.order.id, passingData).subscribe({
      next: (value) => {
        this.toastr.success('', 'Feedback added successfully!');
        this.completeAppointment(passingData);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  completeAppointment(approvalData) {
    this.dataService.completeAppointment(this.order.appointment.id).subscribe({
      next: (completeResponse) => {
        this.toastr.success('', 'Consultation Completed successfully');
        this.isSubmitting = false;
        this.pendingModal.hide();
        this.updateCompletion.emit(approvalData);
        localStorage.removeItem(`${this.order.id}addRecommendation`)
        localStorage.removeItem(`${this.order.id}decision`)
        setTimeout(() => {
          this.router.navigate(['/appointments']);
        }, 400);
      },
      error: (error) => {
        this.isSubmitting = false;
        this.toastr.error(
          this.getErrorMessage(error),
          'Could Not Complete Consultation'
        );
      },
    });
  }

  verifyPatientSCR() {
    const payload = {
      id: this.patient.id,
      settings: {
        ...this.patient.settings,
        SCR_VALIDITY_CONFIRMATION: true,
        SCR_CONFIRMED_BY: `Verified by doctor - ${this.order.doctor.displayName}`,
        NHS_NUMBER: this.nhsNumber,
      },
    };

    this.verifyNHSModal.hide();

    this.dataService.updatePatientSettings(payload).subscribe({
      next: () => {
        this.toastr.success('', 'Patient SCR verified successfully!');
        this.fetchPatient.emit();
      },
    });
  }

  getErrorMessage(error: any) {
    return (
      error.error?.message || error.message || 'An unexpected error occurred'
    );
  }

  handleStepPosition(state: boolean) {
    if (state) {
      const { questions } = questionnaire[this.stepPosition - 1];
      const controls = this.notesGroup.controls;
      const noteSheet = this.notesGroup.value;
      const errorFields = questions.filter((question) => {
        if (!question.active) {
          return false;
        }

        if (!!question.renderCondition) {
          const { decisionValue, parentId, selectionKey } =
            question.renderCondition;
          if (noteSheet[parentId][selectionKey] !== decisionValue) {
            return false;
          }
        }

        if (
          question.type === questionStructure.checkbox ||
          question.type === questionStructure.checkboxAndText
        ) {
          const valueChecked = controls[
            question.questionId
          ]?.value?.choices.some((item) => item.checked);
          return !valueChecked;
        }

        return (
          question.type !== questionStructure.signal &&
          controls[question.questionId].invalid
        );
      });

      if (errorFields.length > 0) {
        errorFields.reverse().forEach((field) => {
          this.toastr.warning(field.question, 'Please fill the empty field');
        });
        return;
      }
      this.stepPosition++;
    }

    if (!state && this.stepPosition > 1) {
      this.stepPosition--;
    }
    this.setProgressWidth();
    this.scrollToTop();
  }

  getSIgnalBtnText(formType: string, doctorFills: boolean) {
    switch (formType) {
      case signalFormTypes.PEG_FORM:
        if (this.pegData) {
          return 'Fill the';
        }
        break;
      case signalFormTypes.PATIENT_DETAIL_CONFIRMATION:
        if (this.consentData) {
          return 'Fill the';
        }
        break;

      default:
        break;
    }

    if (!doctorFills && !this.signalFormStatus[formType]) {
      return 'Request patient to fill';
    }

    return 'Fill the';
  }

  scrollToTop(): void {
    try {
      this.formContainer.nativeElement.scrollTop = 0;
    } catch (err) {}
  }

  getQuestionnaires() {
    return questionnaire;
  }

  getQuestionStructures() {
    return questionStructure;
  }

  textareaVisibility(question: questionType, index = -1) {
    if (
      question.type === questionStructure.text ||
      question.type === questionStructure.choiceAndText
    ) {
      return true;
    }

    const noteValue = this.notesGroup.controls[question.questionId].value;

    if (question.type === questionStructure.choiceAndTextOnCondition) {
      if (typeof question.condition === 'boolean') {
        return noteValue.choice === question.condition;
      }

      if (typeof question.condition === 'object') {
        return !!question.condition[noteValue.choice];
      }
    }

    if (
      question.type === questionStructure.checkbox &&
      !!question.condition &&
      noteValue.choices[index] !== undefined
    ) {
      return noteValue.choices[index].checked === true && !!question.condition[noteValue.choices[index].label];
    }

    if (
      question.type === questionStructure.checkboxAndText &&
      noteValue.choices[index] !== undefined
    ) {
      return noteValue.choices[index].checked === true;
    }
  }

  textareaId(question: questionType) {
    if (question.type === questionStructure.text) {
      return question.questionId;
    }

    if (
      question.type === questionStructure.choiceAndTextOnCondition ||
      question.type === questionStructure.choiceAndText
    ) {
      return `${question.questionId}Desc`;
    }
  }

  setProgressWidth() {
    const accuratePercentage = 100 * (this.stepPosition / questionnaire.length);
    const firstStepPercentage = 100 * (1 / questionnaire.length);
    this.stepPercentage = accuratePercentage - firstStepPercentage;
  }

  routeToOrderPreviewPage() {
    const angularRoute = this.location.path();
    const url = window.location.href;
    const appDomain = url.replace(angularRoute, '');
    const routeUrl = `${appDomain}/order-preview/${this.order.parentOrderItemId}`;
    window.open(routeUrl, '_blank');
  }

  getNoteFormPosition() {
    let position = 20;
    if (this.order.parentOrderItemId) {
      position += 12;
    }

    if (!this.patient.settings.NHS_NUMBER) {
      position += 4.5;
    }

    if (this.order && this.order.pictures) {
      position += 4.5;
    } else {
      position += 2;
    }

    return `${position}rem`;
  }

  getReOrderReason() {
    if (this.order.parentOrderItemId) {
      const reasonNote = this.order.orderNotes.find(
        (item) =>
          item.type === 'REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON'
      );

      if (!!reasonNote) {
        let reason = reasonNote.descriptionKey;
        if (reasonNote.description) {
          reason = `${reasonNote.descriptionKey} - ${reasonNote.description}`;
        }
        return reason;
      }

    }

    return '';
  }

  getIsQuestionRendered(question: questionType) {
    if (!question.renderCondition) {
      return true;
    }

    if (!!question.renderCondition) {
      this.conditionalRenderingQList.push(question);
      const { parentId, selectionKey, decisionValue } =
        question.renderCondition;
      const noteSheet = this.notesGroup.value;
      return noteSheet[parentId][selectionKey] === decisionValue;
    }

    return false;
  }

  getPlaceholder(question: questionType) {
    if (!question.placeholder) {
      return '';
    }

    if (
      question.type === questionStructure.choiceAndTextOnCondition ||
      question.type === questionStructure.choiceAndText
    ) {
      const note = this.notesGroup.value[question.questionId];
      if (!!question.choices) {
        return '';
      }

      if (note.choice === true) {
        return question.placeholder.boolTrue;
      }

      if (note.choice === false) {
        return question.placeholder.boolFalse;
      }

      return question.placeholder.default;
    }
  }

  get recommendationBtnText() {
    if (!this.patientIdentified) {
      return 'Verify patient to add a recommendation';
    }

    if (this.recommendation !== undefined) {
      return 'Edit Recommendation';
    }

    return 'Add Recommendation';
  }
  
  get GPAddress(){
    if (this.patient) {
      return this.patient.preferences?.gpAddress;
    }
    return undefined;
  }

  get auditForm() {
    return AUDIT_FORM;
  }

  get pegForm() {
    return PEG_FORM;
  }

  get gadForm() {
    return GAD_FORM;
  }

  get consentForm() {
    return CONSENT_FORM;
  }
}
