import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { signalFormTypes } from 'src/assets/questionnaire';
import {
  GP_APPROVED_LIST,
  MDT_APPROVED_LIST,
  MDT_PENDING_LIST,
  SPECIALIST_APPROVED_LIST,
  SPECIALIST_PENDING_LIST,
} from 'src/assets/statusMappings';
import { ModalService } from '../components/modals/modal.service';
import { OrderNotesService } from '../order-notes.service';
import { SpecialistService } from '../specialist-orders/specialist.service';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss'],
})
export class PatientHistoryComponent implements OnInit {
  @Input() orders: any[];
  @Input() ordersFromCoop: any[];
  @Input() orderId: number;
  @ViewChild('viewGPSNotes', { static: true }) viewGPSNotes: any;
  @ViewChild('viewPatientNotes', { static: true }) viewPatientNotes: any;
  @ViewChild('viewGPRecommendations', { static: true })
  viewGPRecommendations: any;
  @ViewChild('viewMDTRecommendations', { static: true })
  viewMDTRecommendations: any;

  selectedOrder: any;
  selectedOrderNotes: any[];
  selectedGpNote: any;
  selectedGPRecommendation: any[];
  selectedMDTRecommendation: any[];
  selectedPrescription: any;
  selectedAdditionalNote: string;
  public proformaNote: any;

  activeTab = signalFormTypes.AUDIT_FORM;
  patientTabs = [
    {
      label: 'AUDIT Form',
      type: signalFormTypes.AUDIT_FORM,
    },
    {
      label: 'PEG Form',
      type: signalFormTypes.PEG_FORM,
    },
    {
      label: 'GAD Form',
      type: signalFormTypes.GAD_FORM,
    },
    {
      label: 'Consent Form',
      type: signalFormTypes.PATIENT_DETAIL_CONFIRMATION,
    },
  ];

  constructor(
    private specialistService: SpecialistService,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.orderId) {
      this.selectOrderId(this.orderId);
    }
  }

  selectOrderId(id: number) {
    if (this.selectedOrder && this.selectedOrder.id === id) {
      this.selectedOrder = undefined;
      return;
    }

    this.specialistService.getPrescription(id).subscribe({
      next: (value) => {
        this.selectedPrescription = value.medication[0].formulary;
        this.selectedAdditionalNote = value.additionalPatientNotes;
      },
    });

    this.selectedGPRecommendation = undefined;
    this.selectedMDTRecommendation = undefined;

    for (const order of this.orders) {
      if (order.id === id) {
        this.selectedOrder = order;
        this.selectedOrderNotes = order.orderNotes;
        break;
      }
    }

    this.selectedGpNote = this.orderNotesService.getGPConsultationNote(this.selectedOrderNotes);

    this.selectedOrderNotes.forEach((note) => {
      switch (note.type) {
        case 'GP_RECOMMENDATION':
          this.selectedGPRecommendation = JSON.parse(note.formulary);
          break;

        case 'MDT_RECOMMENDATION':
          this.selectedMDTRecommendation = JSON.parse(note.formulary);
          break;

        case 'MDT_PROFORMA':
          this.proformaNote = JSON.parse(note.questionAnswer);
          break;

        default:
          break;
      }
    });
  }

  get gpApprovalStatus() {
    return GP_APPROVED_LIST;
  }
  get mdtPendingStatus() {
    return MDT_PENDING_LIST;
  }
  get mdtApprovedStatus() {
    return MDT_APPROVED_LIST;
  }
  get specialistPendingStatus() {
    return SPECIALIST_PENDING_LIST;
  }
  get specialistApprovedStatus() {
    return SPECIALIST_APPROVED_LIST;
  }
}
