import { Component, Input, OnInit } from '@angular/core';
import { OrderNotesService } from 'src/app/order-notes.service';

@Component({
  selector: 'app-gp-decisions',
  templateUrl: './gp-decisions.component.html',
  styleUrls: ['./gp-decisions.component.scss'],
})
export class GpDecisionsComponent implements OnInit {
  @Input() orderNotes: any;
  public gpDecision: any;
  public latestConsultationNote: any;

  constructor(private orderNotesService: OrderNotesService) {}

  ngOnInit(): void {
    this.gpDecision = this.orderNotesService.getGPDecision(this.orderNotes);
    const notes = this.orderNotesService.getGPConsultationNote(this.orderNotes);
    
    if (notes.length) {
      const consultationNote = JSON.parse(notes[notes.length - 1].questionAnswer)
      if (!('doctorComments' in consultationNote)) {
        
        this.latestConsultationNote = consultationNote;
      }
    }
  }
}
