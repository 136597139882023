import { Component, OnInit } from '@angular/core';
import { SpecialistService } from './specialist.service';

@Component({
  selector: 'app-track-orders',
  templateUrl: './track-orders.component.html',
  styleUrls: ['./track-orders.component.scss']
})
export class TrackOrdersComponent implements OnInit {
  pharmacyOrders: any = [];
  public isLoading: boolean = false;
  constructor(private specialistService: SpecialistService) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.isLoading = true;
    this.specialistService.getPharmacyWiseOrders().subscribe({
      next: (value): void => {
        value.pharmacyOrders.forEach((pharmacyOrder: any): void => {
          pharmacyOrder.orderItems.forEach((order: any): void => {
            this.specialistService.getPrescription(order.id).subscribe({
              next: (result): void => {
                order.medication = result.medication;
                order.additionalNote = result.additionalPatientNotes;
              },
            });
          });
        });
        this.pharmacyOrders = value.pharmacyOrders;
      }
    });
    this.isLoading = false;
  }

}
