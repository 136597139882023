<div class="container">
	 <div *ngIf="patient?.alerts" class="alert alert-dark-red" (click)="showAlerts = !showAlerts">
		<strong>Key message alerts: </strong> 
		<span *ngIf="!showAlerts">{{patient?.alerts | slice:0:30}}</span><span *ngIf="patient?.alerts.length > 30 && !showAlerts">...</span>
		<span *ngIf="patient?.alerts.length > 30">
			<span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
			<span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
		</span>
		<div *ngIf="showAlerts"> {{patient?.alerts}}</div>
	</div>
	<p>
		<a routerLink="/history" class="btn btn-default"><i class="fa fa-arrow-left"></i> Back to history </a>
	</p>
	<div *ngIf="patient" class="well well--white">


		<h1 class="brand-1">{{patient.displayName}}</h1>
		<p>
			{{patient.phone}}<br /> DOB: {{patient.dateOfBirth | date: 'dd-MMM-yyyy' }} <br/>
			{{patient.email}}
		</p>
	</div>
	<div class="well well--white">

		<form #formConsultationNotes="ngForm" autocomplete="off" novalidate *ngIf="order && patient && doctorNote && order.type === 'BOOKING'"
			[ngClass]="{submitted: formConsultationNotes.submitted}" (ngSubmit)="formConsultationNotes.valid && updateDoctorNote()">

			<h2 class="h1 brand-1">{{order.id}} - {{order.name}}</h2>

			<fieldset>

				<div class="form-group">
					<label for="alerts">Key message alerts</label>
					<textarea class="form-control" id="alerts" name="alerts" [(ngModel)]="doctorNote.alerts">
					</textarea>
				</div>

				<legend>Medical History</legend>
				<div class="form-group">
					<label for="relevantPastMedicalHistory">Relevant past medical history</label>
					<textarea class="form-control" id="relevantPastMedicalHistory" name="relevantPastMedicalHistory" [(ngModel)]="doctorNote.details.relevantPastMedicalHistory">
					</textarea>
				</div>

				<div class="form-group">
					<label for="relevantPastFamilyHistory">Relevant past family history</label>
					<textarea class="form-control" id="relevantPastFamilyHistory" name="relevantPastFamilyHistory" [(ngModel)]="doctorNote.details.relevantPastFamilyHistory">
					</textarea>
				</div>

				<div class="form-group">
					<label for="medicationHistory">Drug history (past and present)</label>
					<textarea class="form-control" id="medicationHistory" name="medicationHistory" [(ngModel)]="doctorNote.details.medicationHistory">
					</textarea>
				</div>

				<div class="form-group">
					<label for="allergyInformation">Allergy information</label>
					<textarea class="form-control" id="allergyInformation" name="allergyInformation" [(ngModel)]="doctorNote.details.allergyInformation">
					</textarea>
				</div>

				<div class="form-group">
					<label for="examinationFindings">Examination Findings</label>
					<textarea class="form-control" id="examinationFindings" name="examinationFindings" [(ngModel)]="doctorNote.details.examinationFindings">
					</textarea>
				</div>

				<div class="form-group">
					<label>Is the Patient at this Address?</label>
					<div class="form-group">
						<label class="radio-inline">
							<input type="radio" [value]="'true'" name="registeredLocation" [(ngModel)]="doctorNote.details.patientAtRegLocation"/>Yes
						</label>
						<label class="radio-inline">
							<input type="radio" [value]="'false'" name="registeredLocation" [(ngModel)]="doctorNote.details.patientAtRegLocation"/>No
						</label>
					</div>
					<p *ngIf="doctorNote.details.patientAtRegLocation == 'true'" class="indented-address">
						<span *ngIf="patient.address.line1">{{ patient.address.line1 }}<br /></span>
						<span *ngIf="patient.address.line2">{{ patient.address.line2 }}<br /></span>
						<span *ngIf="patient.address.county">{{ patient.address.county }}<br /></span>
						<span *ngIf="patient.address.postcode">{{ patient.address.postcode }}<br /></span>
						<span *ngIf="patient.address.city">{{ patient.address.city }}</span>
					</p>
					<div *ngIf="doctorNote.details.patientAtRegLocation == 'false'" class="form-group">
						<label for="newPatientLocation">Enter the new patient's location</label>
						<textarea class="form-control" required name="patientAltLocation" [(ngModel)]="doctorNote.details.location" rows="4" ></textarea>
					</div>
				</div>

				<div class="form-group">
					<label>Does the patient require a follow up consultation?</label>
					<div>
						<label class="radio-inline">
							<input type="radio" [value]="'true'" name="followUpConsultation" [(ngModel)]="doctorNote.details.followUpConsultation"/>Yes
						</label>

						<label class="radio-inline">
							<input type="radio" [value]="'false'" name="followUpConsultation" [(ngModel)]="doctorNote.details.followUpConsultation"/>No
						</label>
					</div>
				</div>
				<div *ngIf="doctorNote.details.followUpConsultation == 'true'">
					<div class="form-group" *ngIf="followUpConsultation">
						<label for="followUpReason">Enter the reason</label>
						<textarea required class="form-control" name="followUpReason" [(ngModel)]="doctorNote.details.followUpReason" rows="4" ></textarea>
					</div>
					<div class="form-group" *ngIf="followUpConsultation">
						<label for="followUpDays">No of days until next consultation</label>
						<input required class="form-control" name="followUpDays" #followupdays="ngModel" [(ngModel)]="doctorNote.details.followUpDays" type="text"/>
					</div>
				</div>
				<div class="form-group">
					<label>Share summary with GP?</label>
					<div>
						<label class="radio-inline">
            				<input required type="radio" name="shareDetailsWithGp" id="shareDetailsWithGPYes" [value]="'true'" [(ngModel)]="doctorNote.details.shareDetailsWithGp"/> Yes
            			</label>

						<label class="radio-inline">
            				<input required type="radio" name="shareDetailsWithGp" id="shareDetailsWithGPNo" [value]="'false'" [(ngModel)]="doctorNote.details.shareDetailsWithGp"/> No
            			</label>
					</div>
				</div>

				<div class="form-group" *ngIf="doctorNote.details.shareDetailsWithGp == 'true'">
					<label for="nhsGPDetails">Enter the patients local GP details</label>
					<textarea rows="4" class="form-control" id="nhsGPDetails" name="nhsGPDetails" [(ngModel)]="doctorNote.details.nhsGPDetails" required></textarea>
				</div>
			</fieldset>

			<fieldset>
				<legend>Patient Notes</legend>
				<div class="form-group">
					<label for="patientInformation">Patient provided information</label>
					<textarea rows="4" class="form-control" id="patientInformation" name="patientInformation" [(ngModel)]="order.workflow.PRESENTING_COMPLAINT"
						disabled></textarea>
				</div>
				<div class="form-group">
					<label for="presentingComplaint">Presenting complaint</label>
					<textarea rows="4" class="form-control" id="presentingComplaint" name="presentingComplaint" [(ngModel)]="doctorNote.details.presentingComplaint" required></textarea>
				</div>

				<div class="form-group">
					<label>Is the Patient feeling any pain?</label>
					<div>
						<label class="radio-inline" for="painYes">
							<input type="radio" [value]="'true'" name="pain" [(ngModel)]="doctorNote.details.pain" required/> Yes 
						</label>
						<label class="radio-inline" for="painNo">
							<input type="radio" [value]="'false'" name="pain" [(ngModel)]="doctorNote.details.pain" required/> No 
						</label>
					</div>
					<div *ngIf="doctorNote.details && doctorNote.details.pain === 'true'">
						<label for="painLevel">How would the Patient describe the pain?</label>
						<select class="form-control" name="painLevel" [(ngModel)]="doctorNote.details.painLevel" required>
							<option value="" selected disabled hidden>Choose here</option>
							<option value="slight">Slight</option>
							<option value="mild">Mild</option>
							<option value="moderate">Moderate</option>
							<option value="severe">Severe</option>
						</select>
					</div>
				</div>

				<div class="form-group">
					<label for="differentialDiagnosis">Differential diagnosis</label>
					<input id="differentialDiagnosis" name="differentialDiagnosis" [(ngModel)]="doctorNote.details.differentialDiagnosis" [typeahead]="conditions" typeaheadEditable="false" class="form-control" required />
				</div>

				<div class="form-group">
					<label for="differentialDiagnosisNotes">Differential diagnosis notes</label>
					<textarea rows="4" class="form-control" id="differentialDiagnosisNotes" name="differentialDiagnosisNotes" [(ngModel)]="doctorNote.details.differentialDiagnosisNotes"></textarea>
				</div>

				<div class="form-group">
					<label for="recommendedActions">Recommended actions</label>
					<textarea rows="4" class="form-control" id="recommendedActions" name="recommendedActions" [(ngModel)]="doctorNote.details.recommendedActions" required></textarea>
				</div>

				<div class="form-group">
					<label for="additionalNotes">Notes for patient record</label>
					<textarea rows="4" class="form-control" id="additionalNotes" name="additionalNotes" [(ngModel)]="doctorNote.details.additionalNotes"></textarea>
				</div>

				<div class="form-group">
					<label for="additionalNotes">Referral details</label>
					<textarea rows="4" class="form-control" id="patientReferredDetails" name="patientReferredDetails" [(ngModel)]="doctorNote.details.patientReferredDetails"></textarea>
				</div>
			</fieldset>
			<app-order-downloads order [orderItem]="order" [fitNote]="doctorNote.sicknoteId" [referralLetter]="doctorNote.referralLetterId" [prescription]="doctorNote.prescriptionId"></app-order-downloads>
			<button class="btn btn-primary" type="submit">Update</button>
		</form>

		<form #formAssessmentNotes="ngForm" autocomplete="off" novalidate *ngIf="order && patient && order.type === 'FORM'" [ngClass]="{submitted: formAssessmentNotes.submitted}"
			(ngSubmit)="formAssessmentNotes.valid && updateAssessmentNotes()">

			<h2>{{order.id}} - {{order.name}}</h2>

			<div class="alert alert-info break-word" *ngIf="order.repeatPrescription">
				A repeat prescription was generated for this order which is due every <strong>{{ order.repeatPrescription.frequency }} days</strong> valid for <strong>{{ order.repeatPrescription.noOfRepeatsPrescribed }} repeats</strong>. This prescription have been pre-approved by {{ order.doctor.displayName }} and goes directly to the pharmacy the patient picks. This will be <strong>valid until {{ order.repeatPrescription.expiryDate | date }}</strong>, if the patient uses all prescribed repeats or if the patient allows 28 days to pass after a prescription's due date.
			</div>

			<div class="alert alert-info break-word" *ngIf="!order.repeatPrescription && order.form['disclaimer.consideredRepeatPrescriptions'] && order.form['disclaimer.consideredRepeatPrescriptions'] == 'Yes'">
				This patient requested a repeat-prescription but it was rejected by {{ order.doctor.displayName }}.
			</div>

			<app-assessment-form [orderItem]="order"></app-assessment-form>
			<div class="form-group">
				<label for="doctorApproveOrRejectNote">Notes</label>
				<textarea rows="10" class="form-control" id="doctorApproveOrRejectNote" name="doctorApproveOrRejectNote" [(ngModel)]="order.workflow.DOCTOR_APPROVE_NOTES"></textarea>
				<label for="dosageInstructions">Dosage Instructions</label>
				<textarea class="form-control" [(ngModel)]="prescription.dosageInstructions" id="dosageInstructions" name="dosageInstructions" readonly></textarea>
			</div>

			<button class="btn btn-primary" type="submit">Update</button>
		</form>
	
		<form #formAssessmentNotes="ngForm" autocomplete="off" novalidate *ngIf="order && patient && order?.type === 'TRAVEL'" [ngClass]="{submitted: formAssessmentNotes.submitted}" (ngSubmit)="formAssessmentNotes.valid && updateAssessmentNotes()">
			<h2>{{order.id}} - {{order.name}} </h2>
			<app-assessment-form [orderItem]="order"></app-assessment-form>
			<div class="form-group">
				<label for="doctorApproveOrRejectNote">Notes</label>
				<textarea rows="10" class="form-control" id="doctorApproveOrRejectNote" name="doctorApproveOrRejectNote" [(ngModel)]="order.workflow.DOCTOR_APPROVE_NOTES"> </textarea>   
			</div>

			<app-order-downloads order [orderItem]="order" [prescription]="true"></app-order-downloads>
			<button class="btn btn-primary" type="submit">Update</button>
		</form>
	</div>
</div>

<app-pin-check #pinCheck></app-pin-check>
