<div class="container">
    <h1 class="brand-1">Two Factor Authentication</h1>

    <div class="well well--white">
        <h4>Two factor authentication enabled</h4>
        <ul *ngIf="is2faEnabled !== undefined" class="pagination">
            <li><a (click)="onauthToggle(true)" class="pagination-background" [class.active]="is2faEnabled">Yes</a></li>
            <li><a (click)="onauthToggle(false)" class="pagination-background" [class.active]="!is2faEnabled">No</a>
            </li>
        </ul>
    </div>
</div>

<div bsModal #authModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="onCloseAuthModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{is2faEnabled ? "Disable" : "Enable"}} two factor authentication</h4>
            </div>

            <form #authForm="ngForm" (ngSubmit)="onSwitchForAuth()">
                <div class="modal-body">
                    <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>

                    <div class="text-center">
                        Please enter password to confirm
                    </div>

                    <div class="form-group">
                        <label for="password">Password</label>
                        <input id="password" name="password" type="password" autocomplete="password" required
                            [(ngModel)]="formModel.password" class="form-control" />
                    </div>
                </div>
                <div class="modal-footer">
                    <p>
                        <button class="btn btn-primary" type="submit" [disabled]="!authForm.form.valid">
                            {{is2faEnabled ? "Disable" : "Enable"}}
                        </button>
                    </p>
                </div>
            </form>
        </div>
    </div>
</div>

<div bsModal #otpModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="onCloseOTPModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Scan QR code</h4>
            </div>

            <form #authForm="ngForm" (ngSubmit)="onEnableTwoFactorAuth()">
                <div class="modal-body">
                    <div class="alert alert--error" *ngIf="errorMessage !== ''">{{errorMessage}}</div>
                    <div *ngIf="registerDetails !== undefined" class="qr-container">
                        <img [src]="registerDetails.qrUrl" alt="">

                        <h5>{{registerDetails.secretCode}}</h5>

                        <div class="form-group">
                            <input id="topt" name="topt" type="number" [(ngModel)]="otpModel.topt"
                                placeholder="OTP number" class="form-control" />
                        </div>
                    </div>

                    <p>
                        Scan QR code or enter the code with the authenticator app to retrieve the OTP number. Save this
                        code to use later if you lose your account on authenticator app to re-generate the OTP number.
                    </p>

                </div>

                <div class="modal-footer">
                    <p class="modal-submit-button">
                        <button class="btn btn-primary" type="submit" [disabled]="!authForm.form.valid">Submit</button>
                    </p>
                </div>
            </form>
        </div>
    </div>
</div>