import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/account/user.service';
import { DataService } from 'src/app/data.service';
import { questionnaire } from 'src/assets/questionnaire';

@Component({
  selector: 'app-order-notes',
  templateUrl: './order-notes.component.html',
  styleUrls: ['./order-notes.component.scss'],
})
export class GpOrderNotesComponent implements OnChanges {
  @Input() orderId: any;
  @Input() orderstatus: string;

  doctorId: number;
  gpNote: any;
  filteredQuestionnaire: any[];
  noteversion = 0;
  editNote = false;
  parsedNote = undefined;
  isLoading = false;
  isExistsAnswers = false;

  constructor(private dataService: DataService, private userService: UserService) {}

  private setupNotes() {
    if (!this.gpNote.length) {
      return;
    }
    this.doctorId = this.userService.user.id
    this.parsedNote = JSON.parse(this.gpNote[this.noteversion].questionAnswer);
    this.filteredQuestionnaire = questionnaire.map((questionItem) => {
      const filteredQuestions = questionItem.questions.filter(
        (question) => question.questionId in this.parsedNote
      );
      this.isExistsAnswers = filteredQuestions.length ? true : this.isExistsAnswers;
      return {
        title: questionItem.title,
        questions: filteredQuestions,
        step: questionItem.step,
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderId) {
      this.dataService.getConsultationNote(this.orderId).subscribe({
        next: (result) => {
          this.gpNote = result;
          this.noteversion = this.gpNote.length - 1;
          this.setupNotes();
        },
      });
    }
  }

  async handleEditBtn(state: boolean){
    if (state) {
      const latestNote = this.gpNote[this.gpNote.length - 1].questionAnswer
      const consultationNotes = {
        type: 'GP_CONSULTATION_NOTES',
        questionAnswer: latestNote,
      };

      this.isLoading = true;
      await lastValueFrom(this.dataService.createPatientNote(this.orderId, consultationNotes));
      this.gpNote = await lastValueFrom(this.dataService.getConsultationNote(this.orderId));
      
      this.isLoading = false;
      this.editNote = state;
    } else {
      this.isLoading = true;
      this.gpNote = await lastValueFrom(this.dataService.getConsultationNote(this.orderId));
      this.noteversion = this.gpNote.length - 1;
      this.isLoading = false;
      this.setupNotes();
      this.editNote = state;
    }
  }

  handleVersionCHange(event) {
    this.noteversion = event;
    this.setupNotes();
  }

  isChoiceQuestion(answer: any) {
    return 'choice' in answer;
  }
}
