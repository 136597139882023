import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ConditionsService } from '../appointments/conditions.service';
import { PinCheckComponent } from '../account/pin-check.component';

@Component({
  selector: 'app-edit-patient-note',
  templateUrl: './edit-patient-note.component.html'
})
export class EditPatientNoteComponent implements OnInit {

  model: any = {};
  doctorNote: any;
  patient: any;
  order: any;
  prescription: any;
  conditions: string[];
  @ViewChild('pinCheck', { static: true }) pinCheck: PinCheckComponent;
  public registeredLocation: boolean = true;
  public followUpConsultation: boolean = false;

  public showAlerts: boolean;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private conditionsService: ConditionsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.conditionsService.getConditions().subscribe(
      conditions => {
        this.conditions = conditions;
      });

    this.activatedRoute.params.subscribe(params => {
      let orderItemId = +params['orderItemId'];

      this.dataService.getOrderItem(orderItemId).subscribe(order => {
        this.order = order;

        if (this.order.status == 'REJECTED_BY_DOCTOR') {
          this.order.doctorApproveOrRejectNote = this.order.workflow.DOCTOR_REJECT_NOTES;
        }
        this.dataService.getPatient(this.order.patient.id).subscribe(p => {
          this.patient = p;
          if (order.type !== 'BOOKING' || this.order.appointment.id === 0) {
            return;
          }

          this.dataService.getDoctorNote(this.order.appointment.id).subscribe(d => {
            this.doctorNote = d;

            this.doctorNote.alerts = this.patient.alerts;
            this.doctorNote.details.location ? this.registeredLocation = false : this.registeredLocation = true;
            this.doctorNote.details.followUpReason ? this.followUpConsultation = true : this.followUpConsultation = false;
            this.doctorNote.details.shareDetailsWithGp = this.doctorNote.details.shareDetailsWithGp;

          });
        });
      });
      this.dataService.getPrescription(orderItemId).subscribe(prescription => {
        this.prescription = prescription;
      });

    });
  }

  updateDoctorNote() {
    this.dataService.updateDoctorNote(this.doctorNote).subscribe(
      (response) => {
        this.toastr.success('Doctor Note updated successfully', 'Doctor Note Updated');
        this.router.navigate(['/history']);
      },
      (error) => {
        this.toastr.error(this.getErrorMessage(error), 'Could Not Update Doctor Note');
      });
  }

  updateAssessmentNotes() {
    this.pinCheck.open().subscribe(pin => {
      if (pin) {
        this.dataService.updateOrderItemNotes(this.order.id, this.order.workflow.DOCTOR_APPROVE_NOTES, pin).subscribe(
          (response) => {
            this.toastr.success('Assessment Note updated successfully', 'Assessment Note Updated');
            this.router.navigate(['/history']);
          },
          (error) => {
            this.toastr.error(this.getErrorMessage(error), 'Could Not Update Assessment Note');
          }
        );
      }
    });
  }

  getErrorMessage(error: any) {
    return error.error?.message || error.message || "An unexpected error occurred";
  }
}
