import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { AccountService } from './account.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  response: any;
  errorMessage: any;
  requestStatus = 'DEFAULT'; // DEFAULT | PROCESSING

  constructor(private accountService: AccountService) { }

  ngOnInit(): void { }

  submit(): void {
    if (this.requestStatus === 'PROCESSING') {
      return; // wait for current request to finish.
    }
    this.requestStatus = 'PROCESSING';
    this.accountService.forgotPassword(this.email)
      .pipe(finalize(() => {
        this.requestStatus = 'DEFAULT';
      }))
      .subscribe(
        (response) => {
          this.response = 'DONE';
          this.errorMessage = false;
        },
        (error) => {
          if (error.status === 401) {
            this.errorMessage = 'Unable to process';
          } else {
            this.errorMessage = 'Something went wrong. Please check your internet connection and try again.' +
              ' If the issue persists, please contact us.';
          }
        });
  }
}
