<div>
    <span *ngFor="let levelVal of buttons">
      <button
        class="round-button"
        [ngClass]="getNumberButtonClass(levelVal)"
        [ngStyle]="{ 'background-color': getButtonBackgroundColor(levelVal) }"
        (click)="numberButtonClicked(levelVal)"
      >
        <span class="number-text">
          {{ numbers[levelVal] }}
        </span>
      </button>
    </span>
</div>
