import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { QRCodeModule } from 'angularx-qrcode';

import { JwtModule } from '@auth0/angular-jwt';
import { NgIdleModule } from '@ng-idle/core';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ToastrModule } from 'ngx-toastr';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { AppRoutingModule } from './app-routing.module';

import { TimeComponent } from './common/time.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login.component';
import { AuthQuicksilva } from './account/auth-quicksilva.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentComponent } from './appointments/appointment.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order.component';
import { VideoCallComponent } from './appointments/video-call.component';
import { AddFitNoteComponent } from './appointments/add-fit-note.component';
import { AddPrescriptionComponent } from './appointments/add-prescription.component';
import { AddReferralLetterComponent } from './appointments/add-referral-letter.component';
import { EditPatientNotesComponent } from './appointments/edit-patient-notes.component';
import { AppointmentQuestionComponent } from './appointments/appointment-question.component';
import { TextChatComponent } from './appointments/text-chat.component';
import { AppointmentActionsComponent } from './appointments/appointment-actions.component';
import { PinCheckComponent } from './account/pin-check.component';
import { HistoryComponent } from './orders/history.component';
import { PatientComponent } from './patient/patient.component';
import { AssessmentFormComponent } from './orders/assessment-form.component';
import { PatientHistoryComponent } from './patient/patient-history.component';
import { EditPatientNoteComponent } from './patient/edit-patient-note.component';
import { ReferralLetterComponent } from './documents/referral-letter.component';
import { FitNoteComponent } from './documents/fit-note.component';
import { PrescriptionComponent } from './documents/prescription.component';
import { ListPrescriptionsComponent } from './prescriptions/list-prescriptions/list-prescriptions.component';
import { OrderSummaryComponent } from './documents/order-summary.component';
import { OrderDownloadComponent } from './patient/order-downloads.component';
import { AddressComponent } from './address.component';
import { MyAccountComponent } from './account/my-account.component';
import { ChangePasswordComponent } from './account/change-password.component';
import { ChangePinComponent } from './account/change-pin.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { ResetPinComponent } from './account/reset-pin.component';
import { DoctorInfoComponent } from './doctor-info/doctor-info.component';
import { SummaryCareRecordsComponent } from './summary-care-records/summary-care-records.component';
import { SecurityDetailsComponent } from './account/security-details.component';
import { FormularyComponent } from './formulary/formulary.component';

import { OrderByPipe } from './pipes/orderby.pipe';
import { OrderStatePipe } from './pipes/order-state.pipe';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { OrderFilterPipe } from './pipes/order-filter.pipe';
import { AppointmentStatusPipe } from './pipes/appointment-status.pipe';
import { FilterByAppointmentStatusPipe } from './pipes/filter-by-appointment-status.pipe';
import { AppointmentDateRangePipe } from './pipes/appointment-date-range.pipe';
import { booleanPipe } from './pipes/boolean.pipe';

import { environment } from '../environments/environment';
import { EqualValidatorDirective } from './common/equal-validator.directive';
import { ConsentComponent } from './account/consent/consent.component';
import { CreatePrescriptionComponent } from './prescriptions/create-prescription/create-prescription.component';
import { PharmacyPickerComponent } from './prescriptions/pharmacy-picker/pharmacy-picker.component';
import { MaskDirective } from './common/mask.directive';
import { TwoFactorAuthComponent } from './account/two-factor-auth.component';
import { MdtOrdersComponent } from './mdt-orders/mdt-orders.component';
import { MdtSessionComponent } from './mdt-orders/mdt-session.component';
import { MdtSessionItemComponent } from './mdt-orders/mdt-session-item.component';
import { TabNavComponent } from './common/tab-nav/tab-nav.component';
import { CommentSectionComponent } from './common/comment-section/comment-section.component';
import { DecisionFormComponent } from './common/forms/decision-form.component';
import { PatientNotesModalComponent } from './common/modals/patient-notes-modal.component';
import { RecommendationModalComponent } from './common/modals/recommendation-modal.component';
import { OrdersPageComponent } from './specialist-orders/orders-page.component';
import { PastOrderItemComponent } from './specialist-orders/past-order-item.component';
import { TrackOrdersComponent } from './specialist-orders/track-orders.component';
import { MdtOrderReviewComponent } from './mdt-orders/mdt-order-review.component';
import { MdtDashboardComponent } from './mdt-orders/mdt-dashboard.component';
import { GpOrderNotesComponent } from './common/forms/order-notes.component';
import { SpecialistOrderReviewComponent } from './specialist-orders/specialist-order-review.component';
import { ChatWidgetComponent } from './common/chat-widget/chat-widget.component';
import { TrackOrdersItemComponent } from './specialist-orders/track-orders-item.component';
import { PastSessionsComponent } from './mdt-orders/past-sessions.component';
import { PastOrdersComponent } from './specialist-orders/past-orders.component';
import { MdtProformaModalComponent } from './common/modals/mdt-proforma-modal.component';
import { FileUploaderComponent } from './specialist-orders/file-uploader.component';
import { PastSessionItemComponent } from './mdt-orders/past-session-item.component';
import { FormularyViewComponent } from './formulary-view/formulary-view.component';
import { PrescriptionModalComponent } from './common/modals/prescription-modal.component';
import { SuccessfulReviewToastComponent } from './specialist-orders/successful-review-toast.component';
import { StockStatusPipe } from './pipes/stock-status.pipe';
import { RepeatOrderNoteComponent } from './common/repeat-order-note/repeat-order-note.component';
import { PrescriptionBuilderComponent } from './common/modals/prescription-builder.component';
import { GpDecisionsComponent } from './common/gp-decisions/gp-decisions.component';
import { PatientListComponent } from './patient/patient-list.component';
import { PegNoteModalComponent } from './common/modals/peg-note-modal.component';
import { AuditNoteModalComponent } from './common/modals/audit-note-modal.component';
import { GadNoteModalComponent } from './common/modals/gad-note-modal.component';
import { ConsentNoteModalComponent } from './common/modals/consent-note-modal.component';
import { GenderPipe } from './pipes/gender.pipe';
import { PegFormComponent } from './appointments/peg-form.component';
import { GadFormComponent } from './appointments/gad-form.component';
import { AuditFormComponent } from './appointments/audit-form.component';
import { ConsentFormComponent } from './appointments/consent-form.component';
import { PatientScrDocumentsModalComponent } from './components/modals/patient-scr-documents-modal/patient-scr-documents-modal.component';
import { PinkPrescriptionModalComponent } from './components/modals/pink-prescription-modal/pink-prescription-modal.component';
import { GpNotesModalComponent } from './components/modals/gp-notes-modal/gp-notes-modal.component';
import { ConsultationNotesFormComponent } from './components/forms/consultation-notes-form/consultation-notes-form.component';
import { ConsultationDocumentsModalComponent } from './components/modals/consultation-documents-modal/consultation-documents-modal.component';
import { AdminKeyAlertModalComponent } from './components/modals/admin-key-alert-modal/admin-key-alert-modal.component';
import { ConsultationNotesModalComponent } from './components/modals/consultation-notes-modal/consultation-notes-modal.component';
import { PharmacyConultationNoteComponent } from './components/notes/pharmacy-conultation-note/pharmacy-conultation-note.component';
import { CommentLabelComponent } from './components/notes/pharmacy-conultation-note/comment-label.component';
import { PharmacyConsultationNoteModalComponent } from './components/modals/pharmacy-consultation-note-modal/pharmacy-consultation-note-modal.component';
import { PainScaleComponent } from './common/modals/pain-scale/pain-scale.component';
import {SideEffectNoteModalComponent} from './common/modals/side-effect-note-modal/side-effect-note-modal.component';
import { PatientDocumentModalComponent } from './components/modals/patient-document-modal/patient-document-modal.component';

export interface GlobalOptions {
  apiEndPoint: string;
  openTokApiKey: string;
}

const globalOptions: GlobalOptions = {
  apiEndPoint: environment.apiEndPoint,
  openTokApiKey: environment.openTokApiKey,
};

export function tokenGetter() {
  return localStorage.getItem(`doctor.id_token`);
}

@NgModule({
    imports: [
        QRCodeModule,
        BrowserModule,
        FormsModule,
        NgxSliderModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        AccordionModule.forRoot(),
        AppRoutingModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        NgIdleModule.forRoot(),
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: [
                    'localhost:8080',
                    'api.alt.thegpservice.com',
                    'api.demo.thegpservice.com',
                    'api.training.thegpservice.co.uk',
                    'api.thegpservice.co.uk',
                    'api.dev.treatit.thegpservice.com',
                    'api.treat-it.clinic'
                ],
                disallowedRoutes: ['/api/public']
            }
        })
    ],
    declarations: [
        AppComponent,
        OrdersComponent,
        AppointmentsComponent,
        AppointmentComponent,
        PrescriptionComponent,
        ListPrescriptionsComponent,
        LoginComponent,
        AuthQuicksilva,
        OrderComponent,
        TimeComponent,
        OrderByPipe,
        VideoCallComponent,
        AppointmentQuestionComponent,
        AddFitNoteComponent,
        AddPrescriptionComponent,
        AddReferralLetterComponent,
        EditPatientNotesComponent,
        TextChatComponent,
        AppointmentActionsComponent,
        PinCheckComponent,
        HistoryComponent,
        PatientComponent,
        AssessmentFormComponent,
        PatientHistoryComponent,
        EditPatientNoteComponent,
        DoctorInfoComponent,
        SummaryCareRecordsComponent,
        OrderStatePipe,
        OrderStatusPipe,
        OrderFilterPipe,
        booleanPipe,
        ReferralLetterComponent,
        FitNoteComponent,
        OrderDownloadComponent,
        AppointmentStatusPipe,
        FilterByAppointmentStatusPipe,
        AppointmentDateRangePipe,
        PrescriptionComponent,
        OrderSummaryComponent,
        AddressComponent,
        MyAccountComponent,
        ChangePasswordComponent,
        ChangePinComponent,
        TwoFactorAuthComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ResetPinComponent,
        SecurityDetailsComponent,
        FormularyComponent,
        EqualValidatorDirective,
        ConsentComponent,
        CreatePrescriptionComponent,
        PharmacyPickerComponent,
        MaskDirective,
        MdtOrdersComponent,
        MdtOrderReviewComponent,
        MdtSessionComponent,
        MdtSessionItemComponent,
        TabNavComponent,
        CommentSectionComponent,
        DecisionFormComponent,
        PatientNotesModalComponent,
        GpNotesModalComponent,
        GpOrderNotesComponent,
        RecommendationModalComponent,
        OrdersPageComponent,
        PastOrderItemComponent,
        TrackOrdersComponent,
        MdtDashboardComponent,
        SpecialistOrderReviewComponent,
        ChatWidgetComponent,
        TrackOrdersItemComponent,
        PastSessionsComponent,
        PastOrdersComponent,
        MdtProformaModalComponent,
        FileUploaderComponent,
        PastSessionItemComponent,
        FormularyViewComponent,
        PrescriptionModalComponent,
        SuccessfulReviewToastComponent,
        StockStatusPipe,
        RepeatOrderNoteComponent,
        PrescriptionBuilderComponent,
        GpDecisionsComponent,
        PatientListComponent,
        PegNoteModalComponent,
        AuditNoteModalComponent,
        GadNoteModalComponent,
        ConsentNoteModalComponent,
        PinkPrescriptionModalComponent,
        GenderPipe,
        PegFormComponent,
        GadFormComponent,
        AuditFormComponent,
        ConsentFormComponent,
        PatientScrDocumentsModalComponent,
        ConsultationNotesFormComponent,
        ConsultationDocumentsModalComponent,
        ConsultationNotesModalComponent,
        AdminKeyAlertModalComponent,
        PharmacyConultationNoteComponent,
        CommentLabelComponent,
        PharmacyConsultationNoteModalComponent,
        PainScaleComponent,
        SideEffectNoteModalComponent,
        PatientDocumentModalComponent
    ],
    providers: [
        { provide: 'global_options', useValue: globalOptions }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
