import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TreatmentService {

    private _treatments$ = new ReplaySubject<any[]>(1);

    constructor(private http: HttpClient) { }

    public getTreatments() {
        if (!this._treatments$.observers.length) {
            this.http.get<any>('assets/treatments.json').subscribe(
                data => this._treatments$.next(data),
                error => this._treatments$.error(error)
            );
        }

        return this._treatments$;
    }

}
