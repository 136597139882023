import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { VideoCallComponent } from './video-call.component';
import { EditPatientNotesComponent } from './edit-patient-notes.component';
import { AppointmentActionsComponent } from './appointment-actions.component';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GlobalOptions } from '../app.module';
import { AppService } from '../app.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {
  @ViewChild('videoCall') videoCall: VideoCallComponent;
  @ViewChild('actions') actions: AppointmentActionsComponent;
  @ViewChild('editNotes') editNotes: EditPatientNotesComponent;
  @ViewChild('completeModal') completeModal: ModalDirective;
  @ViewChild('pendingModal') pendingModal: ModalDirective;
  @ViewChild('medicationLast28days', { static: true })
  medicationLast28days: ModalDirective;

  showAlerts: boolean = false;
  order: any;
  patient: any;
  doctorNote: any = {};
  submitted = false;
  errors = [];
  orders = [];
  coopOrder: boolean = false;
  pharmacy: any;
  isLoading: boolean = false;
  cancelCharge: boolean;
  cancelReason: string;
  cancelReasonOption: any;
  ordersInTheLast28DaysWithPrescription = [];
  isSubmitting = false;
  patientIdentified: boolean

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      let orderItemId = +params['orderItemId'];

      this.dataService.getOrderItem(orderItemId).subscribe((order) => {
        this.order = order;
        this.isLoading = true;

        this.fetchPatient();

        this.dataService
          .getCompleteOrders(order.patient.id)
          .subscribe((orders) => {
            this.orders = orders.content;
          });
      });
    });
  }

  fetchPatient(){
    this.dataService.getPatient(this.order.patient.id).subscribe((patient) => {
      this.patient = patient;
    });
  }

  cancel() {
    let cancelOptionAndReason =
      this.cancelReasonOption + ' - ' + this.cancelReason;
    this.dataService
      .updateDoctorNote(this.doctorNote)
      .subscribe((doctorNoteResponse) => {
        this.dataService
          .cancelAppointment(
            this.order.appointment.id,
            cancelOptionAndReason,
            this.cancelCharge
          )
          .subscribe(
            (completeResponse) => {
              this.toastr.success(
                'Consultation cancelled successfully',
                'Consultation Cancelled'
              );
              this.router.navigate(['']);
            },
            (error) => {
              this.toastr.error(
                this.getErrorMessage(error),
                'Could Not Cancel Consultation'
              );
            }
          );
      });
  }

  updateDoctorNote(note) {
    this.doctorNote.id = note.id;
    this.doctorNote = note;
    this.doctorNote.alerts = note.alerts;
    this.doctorNote.details = {
      confirmedPatientId: note.details.confirmedPatientId,
      relevantPastMedicalHistory: note.details.relevantPastMedicalHistory,
      relevantPastFamilyHistory: note.details.relevantPastFamilyHistory,
      medicationHistory: note.details.medicationHistory,
      allergyInformation: note.details.allergyInformation,
      examinationFindings: note.details.examinationFindings,
      patientAtRegLocation: note.details.patientAtRegLocation
        ? note.details.patientAtRegLocation
        : 'true',
      location: note.details.location,
      followUpConsultation: note.details.followUpConsultation
        ? note.details.followUpConsultation
        : 'false',
      followUpReason:
        note.details.followUpConsultation === 'true'
          ? note.details.followUpReason
          : null,
      followUpDays:
        note.details.followUpConsultation === 'true'
          ? note.details.followUpDays
          : null,
      shareDetailsWithGp: note.details.shareDetailsWithGp,
      nhsGPDetails: note.details.nhsGPDetails,
      presentingComplaint: note.details.presentingComplaint,
      differentialDiagnosis: note.details.differentialDiagnosis,
      differentialDiagnosisNotes: note.details.differentialDiagnosisNotes,
      recommendedActions: note.details.recommendedActions,
      additionalNotes: note.details.additionalNotes,
      patientReferredDetails: note.details.patientReferredDetails,
    };
    this.doctorNote.appointment['id'] = this.order.appointment.id;
  }

  updatePatientVerification($event){
    this.patientIdentified = $event
  }

  patientNotesUpdated(details) {
    this.doctorNote.details = details;
  }

  displayPreviousMedications() {
    if (this.ordersInTheLast28DaysWithPrescription.length) {
      this.medicationLast28days.show();
    }
  }

  getErrorMessage(error: any) {
    return (
      error.error?.message || error.message || 'An unexpected error occurred'
    );
  }

  /**
   * If alerts from both admin and doctor exist, show as such.
   * Else, show first 50 chars of available alert.
   */
  getAlertsTitle(): string | void {
    const { doctor, admin } = this.patient?.allAlerts || {};
    let alertsTitle;
    if (doctor && admin) {
      alertsTitle = 'From Doctor, Admin';
    } else if (doctor) {
      alertsTitle = doctor.slice(0, 50);
    } else if (admin) {
      alertsTitle = admin.slice(0, 50);
    }
    return alertsTitle;
  }

  /**
   * Show 3 dots to signify expansion, if alert msg is more than 50 chars
   * but not if both admin and doctor alerts are present.
   */
  showAlertsDots(): boolean {
    const { doctor, admin } = this.patient?.allAlerts || {};
    let showDots = false;
    if (!doctor || !admin) {
      if (doctor && doctor.length > 50) {
        showDots = true;
      } else if (admin && admin.length > 50) {
        showDots = true;
      }
    }
    return showDots;
  }

  /**
   * Show expand alert btn if alert dots are shown, or if both admin and doctor alerts are present.
   */
  showExpandAlertsBtn(): boolean {
    const { doctor, admin } = this.patient?.allAlerts || {};
    return this.showAlertsDots() || (doctor && admin);
  }
}
