import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, lastValueFrom } from 'rxjs';
import { AUDIT_FORM } from 'src/assets/patientQuestionnaires';
import { DataService } from '../data.service';
import { OrderNotesService } from '../order-notes.service';

@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.scss']
})
export class AuditFormComponent implements OnInit {
  @Input() order: any;
  @Output() hideModal = new EventEmitter();

  auditGroup: FormGroup;
  auditData: any;
  auditId: any;

  constructor(
    private dataService: DataService,
    private orderNotesService: OrderNotesService,
  ) { }

  private async initializeAuditNotes(){
    const auditNote = this.orderNotesService.getAUDITNote(this.order.orderNotes);
    if (!!auditNote) {
      this.auditData = auditNote.note;
      this.auditId = auditNote.id;
    }
  }

  private createAuditNotesForm() {
    const noteControls = {};
    const auditNotes = this.auditData;

    Object.keys(this.auditForm).forEach((key: string) => {
      noteControls[key] = new FormControl(
        auditNotes?.[key] || undefined,
        Validators.required
      );
    });

    this.auditGroup = new FormGroup(noteControls);
    this.onAuditNotesChange();
  }

  private onAuditNotesChange() {
    this.auditGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      if (!this.auditId) {
        const auditData = this.getAuditData();
        await lastValueFrom(this.dataService.createPatientNote(this.order.id, auditData));
        const auditNote = await lastValueFrom(this.dataService.getAuditNote(this.order.id));
        this.auditId = auditNote[0].id;
      } else {
        const auditData = this.getAuditData();
        auditData['id'] = this.auditId;
        await lastValueFrom(this.dataService.updatePatientNote(this.order.id, auditData));
      }
    });
  }

  ngOnInit(): void {
    this.initializeAuditNotes();
    this.createAuditNotesForm();
  }

  getAuditData() {
    let formValues = this.auditGroup.value;
    let totalScore = 0;
    Object.keys(formValues).forEach((key: string) => {
      if (!!formValues[key]) {
        totalScore += parseInt(formValues[key]);
      }
    });

    formValues = {
      ...formValues,
      score: totalScore,
    };

    return {
      type: 'AUDIT_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  get auditForm() {
    return AUDIT_FORM;
  }

}
