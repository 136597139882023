<div class="container" *ngIf="ready && prescription">

	<div class="row mb-2">
		<div class="col col-md-12">
			<div style="display: flex; justify-content: space-between; align-items: flex-end;">
				<div>
					<h1 class="brand-1">Private prescription {{isPetOrder ? '(For Pet)' : ''}}</h1>
					<h4 class="brand-1" *ngIf="prescription.id">
						<strong>ID:&nbsp;{{prescription.id}}</strong>
					</h4>
					<h5 class="h5" *ngIf="prescription.refNumber">
						<strong>Reference No:&nbsp;</strong>{{ prescription.refNumber }}
					</h5>
				</div>
				<button (click)="print()" class="btn btn-default btn-print">Print</button>
			</div>
		</div>
	</div>

	<div class="widgets widgets--2">
		<div class="widget widget--white widget--grey_border" *ngIf="prescription.orderItem.doctor.displayName">
			<div class="inner">
				<h4 class="brand-1 mb-2">Prescriber details</h4>
				<p class="brand-1 name-tag"><strong>{{prescription.orderItem.doctor.displayName}}</strong></p>
				<span *ngIf="prescription.orderItem.doctor.settings.QUALIFICATIONS">{{prescription.orderItem.doctor.settings.QUALIFICATIONS}}<br/></span>
				<span *ngIf="prescription.orderItem.doctor.gmc"><strong>{{getIdentifier()}} number:</strong> {{prescription.orderItem.doctor.gmc}}<br/></span>
			</div>
		</div>
		<div class="widget widget--white widget--grey_border" *ngIf="isPetOrder">
			<div class="inner">
				<h4 class="brand-1 mb-2">Pet Details</h4>
				<p class="brand-1 name-tag"><strong>{{prescription.pet.name}}</strong></p>
				<span><strong>Species:</strong> {{prescription.pet.species}}<br/></span>
				<span *ngIf="prescription.pet.address"><strong>Address:</strong> <br/></span>
				<span *ngIf="addressLine(prescription.pet.address, 1)">{{addressLine(prescription.pet.address, 1)}}<br/></span>
				<span *ngIf="addressLine(prescription.pet.address, 2)">{{addressLine(prescription.pet.address, 2)}}<br/></span>
				<span *ngIf="addressLine(prescription.pet.address, 3)">{{addressLine(prescription.pet.address, 3)}}<br/></span>
			</div>
		</div>
		<div class="widget widget--white widget--grey_border">
			<div class="inner">
				<h4 class="brand-1 mb-2">{{isPetOrder ? 'Owner' : 'Patient'}} Details</h4>
				<p class="brand-1 name-tag"><strong>{{prescription.orderItem.patient.displayName}}</strong></p>
				<span><strong>Date of birth:</strong> {{prescription.orderItem.patient.dateOfBirth | date : 'dd/MM/yyyy'}}<br/></span>
				<span *ngIf="prescription.orderItem.patient.address"><strong>Address:</strong> <br/></span>
				<span *ngIf="addressLine(prescription.orderItem.patient.address, 1)">{{addressLine(prescription.orderItem.patient.address, 1)}}<br/></span>
				<span *ngIf="addressLine(prescription.orderItem.patient.address, 2)">{{addressLine(prescription.orderItem.patient.address, 2)}}<br/></span>
				<span *ngIf="addressLine(prescription.orderItem.patient.address, 3)">{{addressLine(prescription.orderItem.patient.address, 3)}}<br/></span>
			</div>
		</div>
	</div>

	<div class="well well--white">
		<h4 class="brand-1 mb-2">Prescribed medication</h4>

		<div *ngIf="prescription.medication && prescription.medication.length > 0">
			<table class="table">
				<thead>
					<th>Medication</th>
					<th>Formulation</th>
					<th>Strength</th>
					<th>Dosage</th>
					<th>Quantity</th>
					<th>Unit</th>
					<th>Unlicensed Use</th>
				</thead>
				<tbody>
					<tr *ngFor="let treatment of prescription.medication">
						<td>{{treatment.formulary.name}}</td>
						<td>{{treatment.formulary.formulation}}</td>
						<td>{{treatment.formulary.strength}}</td>
						<td>{{treatment.dosage}}</td>
						<td>{{treatment.quantity}}</td>
						<td>{{treatment.unit ? treatment.unit : "N/A"}}</td>
						<td>{{treatment.unlicensedUse ? 'Yes' : 'No'}}</td>
					</tr>
				</tbody>
			</table>
			<div class="alert alert-warning" *ngIf="medicationsContainUnlicensedUse">
				<p>
					<strong>Please confirm the patient has been advised and is happy with the following:</strong>
				</p>
				<ul>
					<li>
						One of this medications is termed 'off-label' or 'off-licensed' for the use prescribed by the doctor. This means, although many people find it helpful for this condition, the effectiveness and possible side effects for this purpose have not been specifically studied by the manufacturer. This treatment does not always work for this condition.
					</li>
					<li>
						Please be aware that you are using this medicine for an 'off-licensed' indication, the manufacturer will not be held liable for any unwanted effects that you may experience.
					</li>
				</ul>
			</div>
			<div *ngIf="!prescription.medication">
				<span *ngFor="let treatment of prescription.treatment">{{treatment}}<br/></span> <br/>
			</div>
		</div>
		<div class="row">
			<div class="col col-md-12" *ngIf="prescription.additionalPatientNotes">
				<label>Additional Patient Notes:</label>
				<span>&nbsp; {{ prescription.additionalPatientNotes }}</span>
			</div>
			<div class="col col-md-12" *ngIf="prescription.pet?.id">
				<p style="color: red;">
					<span>For Animal Treatment Only</span><br/>
					<span><strong>KEEP OUT OF REACH OF CHILDREN</strong></span><br/>
					<span>Medication supplied according to cascade</span><br>
					<span>The prescription is issued in respect of an animal under my cascade</span><br>
				</p>
			</div>
		</div>
	</div>

	<div class="well well--white">
		<div class="row">
			<div class="col col-md-6">
				<div class="form-group">
					<label>Signature:</label><br/>
          <textarea readonly class="form-control" rows="3"></textarea>
				</div>
			</div>
			<div class="col col-md-6">
				<div class="form-group">
					<label>Date:</label>
					<textarea readonly class="form-control" rows="3">{{prescription.creationDate | date: 'dd/MM/yyyy' }} {{prescription.creationDate| date: 'shortTime' }}</textarea>
				</div>
			</div>
		</div>
	</div>

	<p class="print_only">To verify this prescription you can call us on 024 7745 0733 or go to <a href="https://app.thegpservice.co.uk/prescription-checker">Our prescription checker</a></p>

</div>

