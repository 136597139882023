import { Options } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PEG_FORM } from 'src/assets/patientQuestionnaires';
import { DataService } from '../data.service';
import { OrderNotesService } from '../order-notes.service';

@Component({
  selector: 'app-peg-form',
  templateUrl: './peg-form.component.html',
  styleUrls: ['./peg-form.component.scss'],
})
export class PegFormComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() pegNote:any;
  @Input() orderId:any;
  @Output() hideModal = new EventEmitter();

  pegGroup: FormGroup;
  notes: any;
  id: any;
  average: string;

  options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    showTicks: true,
    tickStep: 1,
    getLegend: (value: number): string => {
      if (value === 0 || value === 10) {
        return `${value}`;
      }
      return '';
    },
  };

  public buttonsArray: number[] = [];
  public numbersArray: number[] = [];

  constructor(
    private dataService: DataService,
    private orderNotesService: OrderNotesService,
  ) {}

  private async initializePegNotes(){
    if (!!this.pegNote) {
      this.notes = this.pegNote.note;
      this.id = this.pegNote.id;
    }
  }

  private createPegNotesForm(): void {
    const noteControls = {};
    const pegData = this.notes;

    if (!!pegData && !!pegData.average && typeof pegData.average === 'number') {
      // Get the average as a whole number
      this.average = Math.round(pegData.average).toString();
    } else {
      this.average = pegData?.average || 0;
    }

    Object.keys(PEG_FORM).forEach((key: string) => {
      noteControls[key] = new FormControl(pegData?.[key] || 0, [
        Validators.required,
        Validators.max(10),
        Validators.min(0),
      ]);
    });

    this.pegGroup = new FormGroup(noteControls);
  }

  private onPegNotesChange(): void {
    this.pegGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      val = {
        painInPastWeek: Math.round(val.painInPastWeek),
        painInterferenceWithEnjoyment: Math.round(val.painInterferenceWithEnjoyment),
        painInterferenceWithGeneralActivity: Math.round(val.painInterferenceWithGeneralActivity)
      };
      const pegData = this.getPegData(val);
      this.average = this.getPegAverage(val).toString();
      if (!this.id) {
        await lastValueFrom(this.dataService.createPatientNote(this.orderId, pegData));
        const pegNote = await lastValueFrom(this.dataService.getPegNote(this.orderId));
        this.id = pegNote[0].id;
      } else {
        pegData['id'] = this.id;
        await lastValueFrom(this.dataService.updatePatientNote(this.orderId, pegData));
      }
    });
  }

  private updateConsentNotesForm(): void {
    if (!!this.pegGroup) {
      this.pegGroup.patchValue(this.notes);
    }
  }

  ngOnInit(): void {
    for (let i = 0; i <= 10; i++) {
      this.buttonsArray.push(i);
      this.numbersArray.push(i);
    }
    this.initializePegNotes();
    this.createPegNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pegNote) {
      this.initializePegNotes();
      this.updateConsentNotesForm();
    }
  }

  ngAfterViewInit(): void {
    this.onPegNotesChange();
  }

  private getPegData(pegValue: any) {
    const average = this.getPegAverage(pegValue);

    const formValues = {
      ...pegValue,
      average,
    };

    return {
      type: 'PEG_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  private getPegAverage(pegValue: any): number {
    let average = 0;
    Object.keys(pegValue).forEach((key: string) => {
      if (!!pegValue[key]) {
        average += parseFloat(pegValue[key]);
      }
    });

    return Math.round(average / 3);
  }

  get pegForm() {
    return PEG_FORM;
  }
  public handleButtonClicked(event: any): void {
    this.pegGroup.get(event.key).setValue(event.value);
  }
}
