import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { DataService } from '../data.service';
import { AddPrescriptionComponent } from './add-prescription.component';
import { AppService } from '../app.service';
import { GlobalOptions } from '../app.module';

export enum DocumentAction {
  Add,
  Update,
  Cancel,
}

export enum Document {
  Prescription,
  FitNote,
  ReferralLetter,
  AppointmentExtension,
}

export enum DocumentState {
  None,
  Proposed,
  Accepted,
  Updated,
  Completed,
}

@Component({
  selector: 'app-appointment-actions',
  templateUrl: './appointment-actions.component.html',
})
export class AppointmentActionsComponent implements OnInit {
  @Input() orderId: number;
  @Input() appointmentId: number;
  @Input() pharmacy: {};
  @Input() enabled: boolean;
  @Input() doctorNote: any;
  @Input() submitted: boolean;
  @Input() patient: {};
  @Input() patientIdentified: boolean;
  @Output() sendMessage = new EventEmitter<any>();
  @Output() updateIdentity = new EventEmitter();
  @Output() updatePatientVerification = new EventEmitter();
  @Output() displayPreviousMedications = new EventEmitter();
  @ViewChild('prescriptionModal') prescriptionModal: any;
  @ViewChild(AddPrescriptionComponent)
  addPrescriptionComponent: AddPrescriptionComponent;
  @ViewChild('confirmPatientIdentityModal', { static: true })
  confirmPatientIdentityModal: any;

  Document = Document;
  DocumentState = DocumentState;
  invalidDocuments: string[] = [];
  isLoading = true;

  offline = false;
  patientExternallyIdentified: boolean = false;
  discountPercentage: number;
  prescriptionPrice: number = 0;
  referralLetterPrice: number = 0;
  fitNotePrice: number = 0;
  appointmentExtensionPrice: number = 0;
  doctorConfirmingPatientId: boolean;
  doctorAskedPatientId: boolean = false;
  mentalHealthType = null;

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private toastr: ToastrService,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  ngOnInit() {
    this.dataService.getOrderItem(this.orderId).subscribe(
      (order) => {
        this.patientExternallyIdentified = order.patient.externallyIdentified;
        this.appService
          .getBillingInfo(order.platform || 'GPS')
          .subscribe((billingInfo) => {
            // load billing price for order platform
            this.fitNotePrice =
              billingInfo.referralLetterIssuedDuringAppointmentPrice;
            this.appointmentExtensionPrice =
              billingInfo.appointmentExtensionPrice;
            if (order.workflow.COUPON) {
              this.dataService
                .getCoupon(order.workflow.COUPON)
                .subscribe((coupon) => {
                  // show billing prices after applying discount
                  this.discountPercentage = coupon.percentage;
                  this.prescriptionPrice =
                    this.prescriptionPrice * (1 - this.discountPercentage);
                  this.referralLetterPrice =
                    this.referralLetterPrice * (1 - this.discountPercentage);
                  this.fitNotePrice =
                    this.fitNotePrice * (1 - this.discountPercentage);
                  // update loading status
                  this.isLoading = false;
                });
            } else {
              // update loading status
              this.isLoading = false;
            }
          });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  notify(type: string, action: DocumentAction, success: boolean) {
    if (success) {
      this.toastr.success('', `${type} ${this.actionPastTense(action)}`);
    } else {
      this.toastr.error('', `Could Not ${action} ${type}`);
    }
  }

  actionPastTense(action: DocumentAction) {
    switch (action) {
      case DocumentAction.Add:
        return 'Added';
      case DocumentAction.Cancel:
        return 'Canceled';
      case DocumentAction.Update:
        return 'Updated';
    }
  }

  notifyPatientCancelation(type: string) {
    if (!this.offline) {
      this.sendMessage.emit({
        text: `${type} has been cancelled`,
        response: true,
        accept: false,
      });
    }
  }

  notifyPatient(message: string) {
    if (!this.offline) {
      this.sendMessage.emit({ text: message });
    }
  }

  openConfirmPatientIdentityModal(value) {
    this.doctorConfirmingPatientId = value;
    this.confirmPatientIdentityModal.show();
  }

  confirmPatientId(value) {
    this.doctorAskedPatientId = true;
    this.updatePatientVerification.emit(value);
    this.confirmPatientIdentityModal.hide();
    this.updateIdentity.emit(value);
    this.displayPreviousMedications.emit();
  }

  resetPatientIdentification() {
    delete this.doctorNote.details.confirmPatientId;
    this.doctorAskedPatientId = false;
  }

  getErrorMessage(error: any) {
    console.error(error);
    return (
      error.error?.message || error.message || 'An unexpected error occurred'
    );
  }
}
