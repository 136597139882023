<div bsModal #consultationNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="consultationNotes.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Patient Complaint</h4>
      </div>
      <div class="modal-body patient-files-container">
        <ng-container *ngIf="notes">
          <div class="documents-container">
            {{notes}}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>