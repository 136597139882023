import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { PharmacyService } from '../pharmacy.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { interval } from 'rxjs';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';


interface Address {
  line1: string;
  line2: string;
  city: string;
  county: string;
  postcode: string;
}
interface Patient {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  address: Address;
}

@Component({
  selector: 'app-pharmacy-picker',
  templateUrl: './pharmacy-picker.component.html',
  styleUrls: ['./pharmacy-picker.component.scss']
})
export class PharmacyPickerComponent implements OnInit, OnChanges {

  public postcode: string;
  public pharmacies = [];

  public pristine = true;
  public searching = false;

  public patient: Patient;

  public mapOptions: google.maps.MapOptions = {
    restriction: null,
    clickableIcons: false,
    zoom: 5
  }

  public markerOptions: google.maps.MarkerOptions = {
    icon: {
      url: '/assets/img/pharmacy_icon.png',
      scaledSize: new google.maps.Size(25, 25, "px", "px")
    }
  }

  @Input() pharmacy: any;
  @Output() pharmacyChange = new EventEmitter<any>();

  @Input() requireVaccines = false;
  @Input() deliveryOnly = false;
  @Input() memberOnly = false;

  @ViewChild('googleMap') googleMap: GoogleMap;

  isPostcodeValid: boolean;
  vaccinationAbility = false;

  @Input() orderType = '';

  constructor(
    private pharmacyService: PharmacyService) {
  }

  trySelectPharmacy(p: any) {
    this.pharmacy = p;
    this.pharmacyChange.emit(p);
    this.googleMap.panTo({ lat: p.latitude, lng: p.longitude });
  }

  ngOnInit() {
    if (sessionStorage.getItem('patient-subject')) {
      this.patient = JSON.parse(sessionStorage.getItem('patient-subject'));
      this.postcode = this.patient.address.postcode.replace(" ", "");
    } 
    if (this.postcode) {
      // run when google.maps is loaded
      interval(1000).pipe(
        filter(() => typeof google === 'object' && typeof google.maps === 'object'),
        take(1)
      ).subscribe(() => {
        this.findPharmacies();
      });
    }
  }

  ngOnChanges() {
    // wait until the google.maps is loaded
    if (typeof google === 'object' && typeof google.maps === 'object') {
      if (this.postcode) {
        this.findPharmacies();
      }
    }
  }

  updateInfoWindowState(bound: number, expected: number, infoWindow: MapInfoWindow, marker: MapMarker) {
    if (bound == expected) {
      infoWindow.open(marker);
    } else {
      infoWindow.close();
    }
  }

  findPharmacies() {
    this.pristine = false;
    this.searching = true;
    this.pharmacyService.getLocationFromPostcode(this.postcode).pipe(
      switchMap(([lat, lng]) => {
        this.isPostcodeValid = true;
        this.setBounds(lat, lat, lng, lng);
        return this.pharmacyService.getNearestPharmacies(lat, lng, 5, this.deliveryOnly, this.memberOnly)
      })
    ).subscribe({
      next: (pharmacies) => {
        this.pharmacies = pharmacies;
        const lats = this.pharmacies.map(p => p.latitude)
        const lngs = this.pharmacies.map(p => p.longitude)
        if (this.pharmacies.length > 0) {
          this.setBounds(Math.min(...lats), Math.max(...lats), Math.min(...lngs), Math.max(...lngs))
        } else {
          this.removeBounds();
        }
        this.searching = false;
      },
      error: () => {
        this.isPostcodeValid = false;
        this.pharmacies = [];
        this.searching = false;
      }
    });
  }

  private setBounds(minLat: number, maxLat: number, minLng: number, maxLng: number) {
    const r = 0.1
    this.mapOptions.restriction = {
      latLngBounds: { north: maxLat + r, east: maxLng + r, south: minLat - r, west: minLng - r }
    }
  }

  private removeBounds() {
    this.mapOptions.restriction = null;
  }
}
