import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AUDIT_FORM,
  CONSENT_FORM,
  GAD_FORM,
  PEG_FORM, SIDE_EFFECT_FORM,
} from 'src/assets/patientQuestionnaires';
import { signalFormTypes } from 'src/assets/questionnaire';

@Component({
  selector: 'app-patient-notes-modal',
  templateUrl: './patient-notes-modal.component.html',
  styleUrls: ['./patient-notes-modal.component.scss'],
})
export class PatientNotesModalComponent implements OnInit {
  @Input() orderNotes: any[];
  @Output() hideModal = new EventEmitter();

  activeTab = signalFormTypes.PEG_FORM;
  patientTabs = [];

  selectedPatientNotes = {
    [signalFormTypes.AUDIT_FORM]: {
      form: AUDIT_FORM,
      answers: {},
    },
    [signalFormTypes.GAD_FORM]: {
      form: GAD_FORM,
      answers: {},
    },
    [signalFormTypes.PEG_FORM]: {
      form: PEG_FORM,
      answers: {},
    },
    [signalFormTypes.PATIENT_DETAIL_CONFIRMATION]: {
      form: CONSENT_FORM,
      answers: {},
    },
    [signalFormTypes.SIDE_EFFECT_FORM]: {
      form: SIDE_EFFECT_FORM,
      answers: {},
    },
  };

  constructor() {}

  ngOnInit(): void {
    this.setupPatientNotes();
  }

  public setupPatientNotes(): void {
    this.patientTabs = [];
    this.orderNotes.forEach((note) => {
      switch (note.type) {
        case signalFormTypes.AUDIT_FORM:
          this.patientTabs.push({
            label: 'AUDIT Form',
            type: signalFormTypes.AUDIT_FORM,
          });
          this.activeTab = signalFormTypes.AUDIT_FORM;
          this.selectedPatientNotes[signalFormTypes.AUDIT_FORM] = {
            form: AUDIT_FORM,
            answers: JSON.parse(note.questionAnswer),
          };
          break;
        case signalFormTypes.GAD_FORM:
          this.patientTabs.push({
            label: 'GAD Form',
            type: signalFormTypes.GAD_FORM,
          });
          this.selectedPatientNotes[signalFormTypes.GAD_FORM] = {
            form: GAD_FORM,
            answers: JSON.parse(note.questionAnswer),
          };
          break;
        case signalFormTypes.PEG_FORM:
          this.patientTabs.push({
            label: 'PEG Form',
            type: signalFormTypes.PEG_FORM,
          });
          this.selectedPatientNotes[signalFormTypes.PEG_FORM] = {
            form: PEG_FORM,
            answers: JSON.parse(note.questionAnswer),
          };
          break;
        case signalFormTypes.PATIENT_DETAIL_CONFIRMATION:
          this.patientTabs.push({
            label: 'Consent Form',
            type: signalFormTypes.PATIENT_DETAIL_CONFIRMATION,
          });
          this.selectedPatientNotes[
            signalFormTypes.PATIENT_DETAIL_CONFIRMATION
          ] = {
            form: CONSENT_FORM,
            answers: JSON.parse(note.questionAnswer),
          };
          break;
        case signalFormTypes.SIDE_EFFECT_FORM:
          this.patientTabs.push({
            label: 'Side Effect Form',
            type: signalFormTypes.SIDE_EFFECT_FORM,
          });
        // tslint:disable-next-line:no-switch-case-fall-through
        default:
          break;
      }
    });
  }

  setTab($event) {
    this.activeTab = $event;
  }

  getPEGAverage(average: string | number) {
    if (typeof average === 'string') {
      return average;
    }

    return average.toFixed(2);
  }

  get tab() {
    return signalFormTypes;
  }
}
