<div bsModal #keyAlertModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="keyAlertModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Admin key alerts</h4>
            </div>
            <div class="modal-body">
                <form #alertForm="ngForm">
                    <div class="action-container">
                        <button *ngIf="editNote" class="btn btn-primary" (click)="handlePatientAlerts(false)" [disabled]="isLoading">Save Key Alerts</button>
                        <button *ngIf="!editNote" class="btn btn-danger" (click)="handlePatientAlerts(true)">Edit Key Alerts</button>
                        <div *ngIf="isLoading" class="loading-container">
                            <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
                        </div>
                    </div>
                    <textarea class="form-control" name="alertText" [(ngModel)]="alertText" required rows="10" [disabled]="!editNote"></textarea>
                </form>
            </div>
        </div>
    </div>
</div>