import { Component, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'app-address',
    template: `<address style="margin-bottom: 0.5em;" [innerHTML]="content"></address>`
})
export class AddressComponent implements OnChanges {

    @Input() public address: any;
    public content = '';

    constructor() { }

    ngOnChanges() {
        const line1 = this.address[`line1`]
        const line2 = this.address[`line2`]
        const line3 = [this.address['city'], this.address['county'], this.address['postcode']].filter(x => x ? true : false).join(', ')
        const lines = [line1, line2, line3].filter(x => x ? true : false);
        this.content = lines.join('<br/>');
    }
}
