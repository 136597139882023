import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time.component.html',
    providers: []
})
export class TimeComponent implements OnInit {

    public hours: Array<number> = new Array<number>();
    public mins: Array<number> = new Array<number>();

    @Input() startHour: number = 0;
    @Input() endHour: number = 24;
    @Input() startMinute: number = 0;
    @Input() intervalMinutes: number = 10;
    @Input() endMinute: number = 5;
    @Input() selectedHour: number = 9;
    @Output() selectedHourChange: EventEmitter<number> = new EventEmitter<number>();
    @Input() selectedMin: number = 0;
    @Output() selectedMinChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() { }

    minuteChange(val: number) {
        this.selectedMinChange.emit(val);
    }

    hourChange(val: number) {
        this.selectedHourChange.emit(val);
    }

    ngOnInit() {
        for (let hour = this.startHour; hour < this.endHour; hour++) {
            this.hours.push(hour);
        }
        let min = 0;
        while (min < 60) {
            this.mins.push(min);
            min += this.intervalMinutes;
        }
    }

}
