import { Component, OnInit } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { DataService } from '../data.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit {
  protected patientSearch = new Subject<string>();
  protected patientValue: any;

  protected patientList: any[];
  protected initLoading = true;
  protected totalPageCount: number;
  protected page: number;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.patientValue = '';
    this.page = 0;

    this.patientSearch
      .pipe(debounceTime(500))
      .subscribe((patientName: string) => {
        this.initLoading = true;
        this.dataService.getAllPatients(patientName, this.page).subscribe({
          next: (result) => {
            this.patientList = result.content;
            this.totalPageCount = result.totalPages;
            this.initLoading = false;
          },
        });
      });

    this.patientSearch.next(this.patientValue);
  }

  onPatientInput(patientValue: string) {
    this.page = 0;
    this.patientSearch.next(patientValue);
  }

  handlePageCount(increase: boolean) {
    if (increase) {
      this.page += 1;
    } else {
      this.page -= 1;
    }

    this.patientSearch.next(this.patientValue);
  }
}
