<div class="container login">
	<div class="central_panel">
		<div class="well well--white">
      <img class="site_logo" src="/assets/img/logo.png" alt="Treat it" />

      <div class="alert alert-warning break-word" *ngIf="!model.token">
        To access the system you will need to change your password.
        This change needs to happen every <strong>three months</strong> or
        for those <strong>new</strong> to system use.
      </div>

      <h1 class="brand-1">Reset Password</h1>

      <div *ngIf="message"
           class="alert lower"
           [ngClass]="{ 'alert-success': success, 'alert--error': !success }"
           style="word-wrap: normal; word-break: normal;"
           [innerHTML]="message"></div>

      <form *ngIf="!success"
            [formGroup]="passwordForm"
            autocomplete="off"
            novalidate
            [ngClass]="{ submitted: isProcessing }"
            (ngSubmit)="resetPassword()">
        <div class="form-group" *ngIf="!model.token">
          <label for="currentPassword">Current Password</label>
          <input id="currentPassword" type="password" class="form-control" formControlName="currentPassword" required />
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.currentPassword?.touched
                        && passwordForm.controls?.currentPassword?.errors?.required">
            Current password is required.
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input id="newPassword" type="password" class="form-control" formControlName="password" required />
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.password?.touched
                        && passwordForm.controls?.password?.errors?.fieldError">
            {{passwordForm.controls?.password?.errors?.fieldError}}
          </div>
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.password?.touched
                       && passwordForm.controls?.password?.value?.length > 0
                       && passwordForm.errors?.samePasswordError">
            {{passwordForm.errors?.samePasswordError}}
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <input id="confirmPassword" type="password" class="form-control" formControlName="passwordConfirmation" required />
          <div style="margin-top: 5px; color: red; font-size: 12px;"
               *ngIf="passwordForm.controls?.passwordConfirmation?.touched && passwordForm.errors?.error">
            {{passwordForm.errors?.error}}
          </div>
        </div>
        <div class="alert alert-info">
          <strong>Your password should meet the following rules:</strong>
          <ul>
            <li>It should contain at least 1 upper-case letter.</li>
            <li>It should contain at least 1 lower-case letter.</li>
            <li>It should contain at least 1 number.</li>
            <li>It should contain at least 1 special character.</li>
            <li>It should be at least 8 characters length.</li>
          </ul>
        </div>
        <div style="margin-top: 15px; ">
          <button type="button" class="btn btn-default" routerLink="/">Back</button>
          <button class="btn btn-primary" type="submit" style="margin-left: 15px;"
                  [disabled]="!passwordForm.valid || isProcessing"
                  [style.opacity]="passwordForm.valid && !isProcessing ? 1 : 0.5">Submit</button>
        </div>
      </form>
		</div>
	</div>
</div>
