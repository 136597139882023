import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-consultation-documents-modal',
  templateUrl: './consultation-documents-modal.component.html',
  styleUrls: ['../shared.styles.scss'],
})
export class ConsultationDocumentsModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() documents: any[];
  @Input() modalId: string = 'consultationDocuments';
  @ViewChild('consultationDocuments') modal: ModalDirective;

  private modalEventSubscription: Subscription;
  protected consultationFiles = {
    images: undefined,
    documents: undefined,
  };

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === this.modalId) {
        this.modal.show();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents) {

      if (typeof this.documents[0] === 'string') {
        this.setupBase64Files();
      } else {
        this.setupDocuments();
      }
    }
  }

  setupDocuments(){
    let documents = [];
    let images = [];

    this.documents.forEach(item => {
      if (item.contentType.includes('image')) {
        images.push(item.url)
      } else {
        documents.push(item.url)
      }
    })

    if (documents.length) {
      this.consultationFiles.documents = documents;
    }

    if (images.length) {
      this.consultationFiles.images = images;
    }
  }

  setupBase64Files(){
    let documents = [];
      let images = [];

      for (let i = 0; i < this.documents.length; i++) {
        const fileString = this.documents[i] as string;
        if (fileString.includes('image')) {
          images.push(fileString);
        }

        if (fileString.includes('application')) {
          documents.push(fileString);
        }
      }

      if (documents.length) {
        this.consultationFiles.documents = documents;
      }

      if (images.length) {
        this.consultationFiles.images = images;
      }
  }

  handleOpenPDF(item: string) {
    let pdfWindow = window.open('');
    pdfWindow.document.write(
      `<iframe width='100%' height='100%' src='${encodeURI(item)}'></iframe>`
    );
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }
}
