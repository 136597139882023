<div class="table-container" style="border: 1px solid #e6e6e6; border-radius: 3px;">
    <table class="table table-striped table-hover">
        <thead>
            <tr>
                <th>Question</th>
                <th>Answer</th>
            </tr>
        </thead>
        <tbody *ngIf="note.version === 'old'; else newVersion">
            <tr *ngFor="let item of note.formValues | keyvalue">
                <td width="80%">{{getRepeatQuestionLabel(item.key)}}</td>
                <td>
                    <span *ngIf="item.value === true" class="label label-primary">Yes</span>
                    <span *ngIf="item.value === false" class="label label-warning">No</span>
                </td>
            </tr>
        </tbody>

        <ng-template #newVersion>
            <tbody>
                <tr *ngFor="let item of note.formValues | keyvalue">
                    <td>{{item.value.question}}</td>
                    <td>
                        <div style="display: flex; column-gap: 0.5rem;">
                            <span *ngIf="item.value.answer === true" class="label label-primary" style="height: fit-content; margin-top: 0.5rem;">Yes</span>
                            <span *ngIf="item.value.answer === false" class="label label-warning" style="height: fit-content; margin-top: 0.5rem;">No</span>
                            <span *ngIf="item.value.reason"> - {{item.value.reason}}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>