import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AppointmentQuestionComponent),
    multi: true
};


@Component({
    selector: 'app-appointment-question',
    template: `    
    <div class="question">
        <label [attr.for]="name">{{question}}</label>
        <a *ngIf="!edit" (click)="edit = true">Edit this</a>
        <a *ngIf="edit" (click)="edit = false">Close</a>
        <p *ngIf="!edit">{{value || 'No relevant information given'}}</p>
        <textarea [attr.id]="name" required *ngIf="edit" [(ngModel)]="value" (change)="onAsked()" (blur)="onBlur()">{{value}}</textarea>
        <div class="validation" [ngClass]="{'is_active': changed, 'ng-invalid': !changed }" (click)="onAsked()"></div>
    </div>
    `,
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AppointmentQuestionComponent implements ControlValueAccessor {

    @Input() question;
    @Input() name;
    @Output() asked = new EventEmitter();
    @Output() blur = new EventEmitter();

    private innerValue: any = '';
    public originalValue: any;
    public changed = false;
    public edit = false;

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    get value(): any {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    onAsked() {
        this.changed = true;
        this.asked.emit();
    }

    onBlur() {
        this.onTouchedCallback();
        this.blur.emit();
    }

    writeValue(value: any) {
        this.originalValue = value;
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
}