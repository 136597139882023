import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[dateMask]'
})
export class MaskDirective {

  @HostListener('input', ['$event.target'])
  onInput(element: HTMLInputElement) {
    if (!element.value.length) {
      return;
    }
    const newValue = element.value.replace(/[^0-9]/g, '').slice(0, 8);
    if (newValue.length <= 2) {
      element.value = newValue.slice(0, newValue.length).replace(/-$/g, '');
    }
    else if (newValue.length <= 4) {
      element.value = newValue.slice(0, 2) + '-' + newValue.slice(2, newValue.length).replace(/-$/, '');
    }
    else if (newValue.length <= 8) {
      element.value = newValue.slice(0, 2) + '-' + newValue.slice(2, 4) + '-' + newValue.slice(4, newValue.length).replace(/-$/, '');
    }
  }

}
