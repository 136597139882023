<div class="container login">
  <div class="central_panel">
    <div class="well well--white">
      <img class="site_logo" src="/assets/img/logo.png" alt="Treat it" />
      <h1 class="brand-1">Reset Pin</h1>
      <div class="alert alert-danger" *ngIf="!response?.success && response?.message">{{ response.message }}</div>
      <div *ngIf="response?.success">
        <div class="alert alert-success">Thank you, your pin has been reset</div>
        <a class="btn btn-default" routerLink="/"><i class="fa fa-arrow-left"></i> Go back</a>
      </div>

      <form
        #form="ngForm"
        autocomplete="off"
        novalidate
        [ngClass]="{ submitted: form.submitted }"
        (ngSubmit)="form.valid && submit()"
        *ngIf="!response?.success"
      >
        <div>
          <div class="form-group">
            <label for="newPassword">New Pin</label>
            <input
              #newPassword="ngModel"
              id="newPassword"
              [(ngModel)]="model.newPassword"
              name="newPassword"
              type="password"
              class="form-control"
              required
            />
          </div>
          <div class="form-group">
            <label for="cpassword">Confirm New Pin</label>
            <input
              #cpassword="ngModel"
              id="cpassword"
              ngModel
              name="cpassword"
              type="password"
              class="form-control form_control--text"
              required
              [equal]="newPassword"
            />
            <span class="help-block" *ngIf="form.submitted && cpassword.hasError('equal')"> Pin confirmation must match </span>
          </div>
        </div>
        <button class="btn btn-primary" type="submit">Submit</button>
      </form>
    </div>
  </div>
</div>
