<div *ngIf="!isLoading" class="buttons" [ngClass]="{ submitted : submitted }">
	<div class="question sign-off lowered" *ngIf="!doctorAskedPatientId">
		<p *ngIf="patientExternallyIdentified">
			This patient's account has been successfully verified against the details that were provided at
			registration. However, you should confirm that the person presenting at the consultation is the same
			individual for whom the consultation has been booked. Suggestions include: Confirming their name,address or
			DOB.
		</p>
		<p *ngIf="!patientExternallyIdentified">
			This patient's account has NOT been verified against the details that were provided at registration. You
			must ensure Photo ID is presented and confirmed from the patient to verify their Identity before the
			consultation starts. Please confirm this has been done.
		</p>
		<div class="toggle">
			<button class="btn btn-primary" (click)="openConfirmPatientIdentityModal(true)">Yes</button>
			<button class="btn btn-danger" (click)="openConfirmPatientIdentityModal(false)">No</button>
		</div>
	</div>

	<div *ngIf="doctorAskedPatientId">
		<div *ngIf="patientIdentified == 'false'" class="lowered">
			As you have not been able to confirm the Patients ID, please inform the Patient that you may only provide
			advice. <strong>No prescriptions, fit notes or referral letters</strong> can be prescribed.
		</div>
	</div>
</div>


<div bsModal #confirmPatientIdentityModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="confirmPatientIdentityModal.hide()"
					aria-label="Close"><span aria-hidden="true">&times;</span></button>
				<h4 class="modal-title">
					Confirm Patient Identity
				</h4>
			</div>
			<div class="modal-body">
				<div *ngIf="!doctorConfirmingPatientId">
					<label>
						Are you sure you want to confirm that the patient's identity could not be verified? Please note
						that by not confirming the patient's identity you may only provide advice.<strong> No
							prescriptions, fit notes or referral letters</strong> can be prescribed.
					</label>
					<div>
						<button class="btn btn-primary" (click)="confirmPatientId('false')">Yes</button>
						<button class="btn btn-danger" (click)="confirmPatientIdentityModal.hide()">No</button>
					</div>
				</div>
				<div *ngIf="doctorConfirmingPatientId">
					<label>
						Are you sure you want to confirm the patient's identity?
					</label>
					<div>
						<button class="btn btn-primary" (click)="confirmPatientId('true')">Yes</button>
						<button class="btn btn-danger" (click)="confirmPatientIdentityModal.hide()">No</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>