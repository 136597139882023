import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from './account.service';
import { UserService } from './user.service';

@Component({
  selector: 'two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwoFactorAuthComponent implements OnInit {
  @ViewChild('authModal', { static: true }) authModal: ModalDirective;
  @ViewChild('otpModal', { static: true }) otpModal: ModalDirective;
  is2faEnabled = undefined;
  registerDetails = undefined;
  email: string;
  errorMessage: string;
  formModel: any = {};
  otpModel: any = {};

  constructor(
    private userService: UserService,
    private accountService: AccountService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.userService.getUser().subscribe((u) => {
      this.is2faEnabled = u.is2faEnabled;
      this.email = u.email;
    });
  }

  onCloseAuthModal() {
    this.errorMessage = '';
    this.authModal.hide();
  }

  onCloseOTPModal() {
    this.errorMessage = '';
    this.otpModal.hide();
  }

  onauthToggle(value: boolean) {
    this.errorMessage = '';
    if (value === this.is2faEnabled) {
      return;
    }
    this.authModal.show();
  }

  onSwitchForAuth() {
    this.errorMessage = '';
    if (this.is2faEnabled) {
      this.accountService
        .disableTwoFactorAuth(this.email, this.formModel.password)
        .subscribe(
          (result) => {
            this.errorMessage = '';
            this.is2faEnabled = result.is2FAEnabled;
            this.authModal.hide();
            this.toastr.success(
              'Two factor authentication disabled successfully.',
              'Disabled'
            );
          },
          (error) => {
            this.errorMessage = error.error.message;
            document.body.scrollTop = 0;
          }
        );
    } else {
      this.accountService
        .registerForTwoFactorAuth(this.email, this.formModel.password)
        .subscribe(
          (result) => {
            this.errorMessage = '';
            this.registerDetails = result;
            this.authModal.hide();
            this.otpModal.show();
          },
          (error) => {
            this.errorMessage = error.error.message;
            document.body.scrollTop = 0;
          }
        );
    }
  }

  onEnableTwoFactorAuth() {
    this.errorMessage = '';
    if (this.otpModel.topt === undefined) {
      this.errorMessage = 'OTP number is required!';
      return;
    }

    this.accountService
      .enableTwoFactorAuth(
        this.email,
        this.formModel.password,
        this.registerDetails.key,
        this.otpModel.topt
      )
      .subscribe(
        (result) => {
          this.is2faEnabled = result.is2FAEnabled;
          this.formModel = {};
          this.otpModel = {};
          this.registerDetails = undefined;
          this.otpModal.hide();
          this.toastr.success(
            'Two factor authentication enabled successfully.',
            'Enabled'
          );
          this.logout();
        },
        (error) => {
          this.errorMessage = error.error.message;
        }
      );
  }

  logout() {
    this.accountService.blacklistToken().subscribe(
      () => {
        this.accountService.logout();
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
