<div bsModal #viewPharmacyConsultationNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog pharmacy-consultation-notes-modal">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="viewPharmacyConsultationNotes.hide()" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">
                    Pharmacy Consultation Notes
                </h4>
            </div>
            <div class="modal-body">
                <app-pharmacy-conultation-note [gpNote]="gpNote"></app-pharmacy-conultation-note>
            </div>
        </div>
    </div>
</div>