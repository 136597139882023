import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalOptions } from '../app.module';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions) {
  }

  public getNearestPharmacies(lat: number, lng: number, limit = 5, deliveryOnly = false, goldMembersOnly = false) {
    let url = `${this.options.apiEndPoint}api/public/nearest-pharmacies?latitude=${lat}&longitude=${lng}&limit=${limit * 10}`;
    if (goldMembersOnly) {
      url = `${url}&memberType=GOLD`;
    }
    return this.http.get<any[]>(url)
      .pipe(
        map(pharmacies => pharmacies.filter(p => p.active)),
        map(pharmacies => deliveryOnly ? pharmacies.filter(p => p.delivers) : pharmacies),
        map(pharmacies => goldMembersOnly ? pharmacies.filter(p => p.memberType === 'GOLD') : pharmacies),
        map(pharmacies => pharmacies.slice(0, limit))
      );
  }

  public getLocationFromPostcode(postcode: string) {
    const url = `https://api.postcodes.io/postcodes/${postcode}`
    return this.http.get<any>(url).pipe(
      map(res => {
        if (res.status === 200) {
          return [res.result.latitude, res.result.longitude]
        } else {
          throwError({ message: 'Invalid Postcode' })
        }
      })
    );
  }

  public createCompanyPatient(patient: any, pin: string) {
    const url = `${this.options.apiEndPoint}api/doctor/company-doctor/register/patient?pin=${pin}`;
    patient = { ...patient, password: '', preferences: {}, dateOfBirth: moment(patient.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD') }
    delete patient.id;
    return this.http.post<any>(url, patient);
  }

  public createPet(pet: any, pin: string) {
    const url = `${this.options.apiEndPoint}api/doctor/pet?pin=${pin}`;
    return this.http.post<any>(url, pet);
  }

  public getPets(patientId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/pet?id=${patientId}`;
    return this.http.get<any>(url);
  }

  public createCompanyOrder(order: any, pin: string) {
    const url = `${this.options.apiEndPoint}api/doctor/order?type=PRESCRIPTION_ORDER&pin=${pin}`;
    return this.http.post<any>(url, order);
  }

  public createCompanyOrderPrescription(prescription: any) {
    const url = `${this.options.apiEndPoint}api/doctor/company-doctor/create-prescription`;
    return this.http.post<any>(url, prescription);
  }

  public searchPatient(firstName: string, lastName: string, dateOfBirth: string) {
    const url = `${this.options.apiEndPoint}api/doctor/get-patient`;
    return this.http.get<any>(url, { params: { firstName, lastName, dateOfBirth } });
  }
}
