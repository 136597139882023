import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MEDICATION_TYPE } from 'src/assets/formulary';
import { ToastrService } from 'ngx-toastr';
import { PrescriptionService } from '../prescription.service';

type tNoRecord = {
  id: 'route' | 'manufacturer' | 'brand' | 'strain' | undefined;
  value: string;
  priority: -1 | 1 | 2 | 3 | 4;
};

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.scss'],
})
export class AddPrescriptionComponent implements OnInit {
  @Input() type: string;
  @Input() orderId: any;
  @Input() pharmacy: any;
  @Input() patient: any;
  @Input() pet?: any;
  @Input() prescriptionEnabled?: boolean = true;
  @Input() showRefNumberInput = false;
  @Input() prevRecommendation = undefined;
  @Output() back = new EventEmitter();
  @Output() draftRecommendation = new EventEmitter();
  @Output() hide = new EventEmitter();
  @ViewChild('prescriptionForm') prescriptionForm: NgForm;

  formChangesSubscription = null;
  prefix: string = null;
  selectedRow = undefined;
  filteredFormularies = [];

  protected selectedPharmacy: number;

  // Option values
  routes: any[] = [];
  manufacturers: any[] = [];
  brands: any[] = [];
  strains: any[] = [];
  noRecord: tNoRecord = {
    id: undefined,
    value: '',
    priority: -1,
  };

  //model
  medicationType = '';
  category = '';
  route = '';
  manufacturer = '';
  brand = '';
  strain = '';
  comments = '';
  medicationSelect = '';
  medications = [];
  selectedMedication: any;

  constructor(
    private toastr: ToastrService,
    private prescriptionService: PrescriptionService
  ) {}
  showFilter = false;

  initState() {}

  ngOnInit() {
    if (!this.prevRecommendation) {
      return;
    }

    this.routes = this.prescriptionService.filterRoutes({
      category: this.prevRecommendation.category,
    });

    if (this.routes.includes(this.prevRecommendation.route)) {
      this.route = this.prevRecommendation.route;
      this.manufacturers = this.prescriptionService.filterManufacturers({
        administrationRoute: this.prevRecommendation.route,
      });
    } else {
      this.noRecord = {
        id: 'route',
        value: `Administration route '${this.prevRecommendation.route}' is not available anymore`,
        priority: 4,
      };
    }

    if (this.manufacturers.includes(this.prevRecommendation.manufacturer)) {
      this.manufacturer = this.prevRecommendation.manufacturer;
      this.brands = this.prescriptionService.filterBrands({
        manufacture: this.prevRecommendation.manufacturer,
      });
    } else {
      if (this.noRecord.priority <= 3) {
        this.noRecord = {
          id: 'manufacturer',
          value: `Manufacturer '${this.prevRecommendation.manufacturer}' is not available anymore`,
          priority: 3,
        };
      }
    }

    if (this.brands.includes(this.prevRecommendation.brand)) {
      this.brand = this.prevRecommendation.brand;
      this.strains = this.prescriptionService.filterStrains({
        brandName: this.prevRecommendation.brand,
      });
    } else {
      if (this.noRecord.priority <= 2) {
        this.noRecord = {
          id: 'brand',
          value: `Brand '${this.prevRecommendation.brand}' is not available anymore`,
          priority: 2,
        };
      }
    }

    if (this.strains.includes(this.prevRecommendation.strain)) {
      this.strain = this.prevRecommendation.strain;
    } else {
      if (this.noRecord.priority <= 2) {
        this.noRecord = {
          id: 'strain',
          value: `Strain '${this.prevRecommendation.strain}' is not available anymore`,
          priority: 1,
        };
      }
    }

    this.medicationType = this.prevRecommendation.medicationType;
    this.category = this.prevRecommendation.category;
    this.comments = this.prevRecommendation.comments;
  }

  updateRecommendation() {
    if (this.prescriptionForm.invalid) {
      this.toastr.warning('', 'Fill all the empty fields');
      return;
    }

    if (
      this.type === 'SPECIALIST_PRESCRIPTION' &&
      this.selectedPharmacy === undefined
    ) {
      this.toastr.warning('', 'Please select atleast one medication');
      return;
    }

    let formValues = {};

    if (!this.prescriptionEnabled) {
      formValues = {
        medicationType: this.medicationType,
        brand: this.brand,
        category: this.category,
        route: this.route,
        strain: this.strain,
        manufacturer: this.manufacturer,
        comments: this.comments,
        ...this.prescriptionForm.value,
      };
    } else {
      formValues = { ...this.prescriptionForm.value };
    }

    if (this.medications !== undefined && this.medications.length > 0) {
      const medicationIDs = this.medications.map((med) => med.id);
      formValues = {
        ...formValues,
        medications: medicationIDs,
        pharmacyId: this.selectedPharmacy,
      };
    }
    this.draftRecommendation.emit(formValues);
    this.hide.emit();
  }

  handleAddMedication($event: any) {
    const selectedMedication = JSON.parse($event);
    if (this.medications.some((med) => med.id === selectedMedication.id)) {
      return;
    }
    this.medications.push(selectedMedication);
    this.selectedPharmacy = selectedMedication.pharmacyId;
  }

  handleRemoveMedication($event: any) {
    this.medications = this.medications.filter((med) => med != $event);
    this.medicationSelect = undefined;
    if (this.medications.length === 0) {
      this.selectedPharmacy = undefined;
    }
  }

  handleExpandMedication(med: any) {
    if (med === this.selectedMedication) {
      this.selectedMedication = undefined;
      return;
    }
    this.selectedMedication = med;
  }

  getMedicationLabel(med) {
    return this.medicationList.find(({ id }) => id == med).productName;
  }

  getMedicationType() {
    return MEDICATION_TYPE;
  }

  getSubmitButtonText() {
    if (this.type === 'SPECIALIST_PRESCRIPTION') {
      return 'Submit prescription';
    }

    if (this.prevRecommendation) {
      if (
        this.prevRecommendation.category !== this.category ||
        this.prevRecommendation.route !== this.route ||
        this.prevRecommendation.manufacturer !== this.manufacturer ||
        this.prevRecommendation.brand !== this.brand ||
        this.prevRecommendation.strain !== this.strain
      ) {
        return 'Submit prescription with changes';
      }
    }

    return 'Submit prescription';
  }

  //Model changes
  thcPercentageChange($event) {
    this.category = $event;
    this.route = undefined;
    this.manufacturer = undefined;
    this.brand = undefined;
    this.strain = undefined;
    this.medications = [];

    this.routes = this.prescriptionService.filterRoutes({
      category: this.category,
    });
    this.resetNoRecord();
  }

  routeChange($event) {
    this.route = $event;
    this.manufacturer = undefined;
    this.brand = undefined;
    this.strain = undefined;
    this.medications = [];

    this.manufacturers = this.prescriptionService.filterManufacturers({
      administrationRoute: this.route,
    });
    this.resetNoRecord();
  }

  manufactureChange($event) {
    this.manufacturer = $event;
    this.brand = undefined;
    this.strain = undefined;
    this.medications = [];

    this.brands = this.prescriptionService.filterBrands({
      manufacture: this.manufacturer,
    });
    this.resetNoRecord();
  }

  brandChange($event) {
    this.brand = $event;
    this.strain = undefined;
    this.medications = [];

    this.strains = this.prescriptionService.filterStrains({
      brandName: this.brand,
    });
    this.resetNoRecord();
  }

  strainChange($event){
    this.strain = $event
    this.medications = [];
  }

  getFieldAvailability(id: string) {
    return this.noRecord.id === id;
  }

  private resetNoRecord() {
    this.noRecord = {
      id: undefined,
      value: '',
      priority: -1,
    };
  }

  get thcList() {
    return this.prescriptionService.thcList;
  }

  get medicationList() {
    if (this.strain) {
      return this.prescriptionService.filteredMedications({
        strain: this.strain,
      });
    }

    return [];
  }
}
