import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONSENT_FORM } from 'src/assets/patientQuestionnaires';

@Component({
  selector: 'app-consent-note-modal',
  templateUrl: './consent-note-modal.component.html',
  styleUrls: ['./consent-note-modal.component.scss']
})
export class ConsentNoteModalComponent implements OnInit {
  @Input() consentNote: any;
  @Output() hideModal = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  get consentForm(){
    return CONSENT_FORM;
  }

}
