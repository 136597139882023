<div class="container">
    <h1 class="brand-1">Past Sessions</h1>

    <div *ngIf="allSessions === undefined; else sessionsFetched" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>

    <ng-template #sessionsFetched>
        <div class="pagination-container">
            <span>Page {{page + 1}} of {{totalPageCount}}</span>
            <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
                <img class="previous" src="/assets/vectors/expand.svg" alt="">
            </button>
            <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
                <img class="next" src="/assets/vectors/expand.svg" alt="">
            </button>
        </div>
        <div class="table-container">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Start date</th>
                        <th>Finished date</th>
                        <th>Chair</th>
                        <th>Reporter</th>
                        <th>Location</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let session of allSessions">
                        <tr *ngIf="session.status === 'COMPLETED'">
                            <td>{{session.id}}</td>
                            <td>{{session.startDate | date : 'dd-MMM-yyyy HH:mm' }}</td>
                            <td>{{session.updateDate | date : 'dd-MMM-yyyy HH:mm' }}</td>
                            <td>{{session.chair.displayName}}</td>
                            <td>{{session.reporter.displayName}}</td>
                            <td>{{session.location}}</td>
                            <td><button class="btn btn-info" (click)="handleSessionRoute(session.id)">View</button></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>
