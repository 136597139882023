<div class="container login">
  <div class="central_panel">
    <div class="well well--white">
      <img class="site_logo" src="/assets/img/logo.png" alt="Treat it"/>
      <h1 class="brand-1">Forgot Password</h1>
      <div class="alert alert-danger" *ngIf="errorMessage" style="word-break: initial;">
        {{errorMessage}}
      </div>

      <div *ngIf="response">
        <div class="alert alert-success" style="word-break: initial; margin-bottom: 1rem;">
          If this email address is registered with Treat it, you will receive an email with a password reset link
          shortly.
        </div>
        <a class="btn btn-default" routerLink="/"><i class="fa fa-arrow-left"></i> Go back</a>
      </div>

      <form #form="ngForm" autocomplete="off" [ngClass]="{submitted: form.submitted}" novalidate
            (ngSubmit)="form.valid && submit()"
            *ngIf="!response">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" name="email" type="email" class="form-control" [(ngModel)]="email" required email/>
        </div>
        <a routerLink="/" class="btn btn-default" style="margin-right: 10px;">Back</a>
        <button class="btn btn-primary"
                type="submit">{{ requestStatus === "DEFAULT" ? "Submit" : "Processing" }}</button>
      </form>
    </div>
  </div>
</div>
