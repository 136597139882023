export const MEDICATION_TYPE = ['Medical Cannabis'];

export const MEDICATIONS = [
  { id: 'Together Pharma Andromeda', label: 'Together Pharma Andromeda' },
  { id: 'KG', label: 'KG' },
  { id: 'Viagara', label: 'Viagara' },
];

export const THC_PERCENTAGE = ['High THC', 'Balanced', 'High CBD'];

export const ROUTES = {
  'High THC': ['Flos', 'Vape'],
  Balanced: ['Flos', 'Oil', 'Vape'],
  'High CBD': ['Oil', 'Flos'],
};

export const Formulary = {
  'Together Pharma Andromeda': {
    name: 'Together Pharma Andromeda',
    thc: 'High THC',
    route: 'Flos',
    medProfile: 'THC 19% - CBD<1%',
    unitQty: 10,
    manufacturer: 'Cantourage',
    salePrice: '£65',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '£ 6.50',
    originStrain: 'Kush Kush',
    originCountry: 'Uganda',
  },
  'Together Pharma Cassiopeia': {
    name: 'Together Pharma Cassiopeia',
    thc: 'High THC',
    route: 'Flos',
    medProfile: 'THC 20% - CBD<1%',
    unitQty: 10,
    manufacturer: 'Cantourage',
    salePrice: '£65',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '£ 6.50',
    originStrain: 'Ninja',
    originCountry: 'Uganda',
  },
  'Fotmer T3h': {
    name: 'Fotmer T3h',
    thc: 'High THC',
    route: 'Flos',
    medProfile: 'THC 21.5% - CBD <1%',
    unitQty: 10,
    manufacturer: 'Cantourage',
    salePrice: '£70',
    strain: 'Strain 3',
    brand: 'Brand 3',
    pricePerUnit: '£ 7.00',
    originStrain: 'Purple dog bud',
    originCountry: 'Uruguay',
  },
  'NOIDECS T5:C7': {
    name: 'NOIDECS T5:C7',
    thc: 'Balanced',
    route: 'Flos',
    medProfile: 'THC:5% CBD:7%',
    unitQty: 10,
    manufacturer: 'Dispensary Green',
    salePrice: '£80',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '£ 8.00',
    originStrain: '-',
    originCountry: 'Australia',
  },
  'Spectrum Blue': {
    name: 'Spectrum Blue',
    thc: 'Balanced',
    route: 'Flos',
    medProfile: 'T8%:C7%',
    unitQty: 5,
    manufacturer: 'Dispensary Green',
    salePrice: '£60',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '£ 6.00',
    originStrain: '-',
    originCountry: 'Canada',
  },
  'Adven EMC-1 T<1:C14%': {
    name: 'Adven EMC-1 T<1:C14%',
    thc: 'High CBD',
    route: 'Flos',
    medProfile: '1% THC: <14% CBD',
    unitQty: 10,
    manufacturer: 'Dispensary Green',
    salePrice: '£60',
    strain: 'Strain 3',
    brand: 'Brand 3',
    pricePerUnit: '£ 6.00',
    originStrain: '-',
    originCountry: 'Spain',
  },
  'Grow 18/1': {
    name: 'Grow 18/1',
    thc: 'High THC',
    route: 'Flos',
    medProfile: 'THC 18% - CBD <1%',
    unitQty: 10,
    manufacturer: 'Cantourage',
    salePrice: '£80',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '£ 8.00',
    originStrain: 'Herijuana',
    originCountry: 'North Macedonia',
  },
  'Althea CBD25 : THC2': {
    name: 'Althea CBD25 : THC2',
    thc: 'High CBD',
    route: 'Oil',
    medProfile: 'THC <2mg/ml- CBD25mg/ml',
    unitQty: '50ml',
    manufacturer: 'Cantourage',
    salePrice: '£199',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
  'Lumir Balanced 25:25': {
    name: 'Lumir Balanced 25:25',
    thc: 'Balanced',
    route: 'Oil',
    medProfile: 'THC 25mg/ml CBD 25 mg/ml',
    unitQty: '30ml',
    manufacturer: 'Target/FirsLine',
    salePrice: '£160',
    strain: 'Strain 3',
    brand: 'Brand 3',
    pricePerUnit: '-',
    originStrain: 'CBD High Yaron & Jamaica Fem',
    originCountry: '-',
  },
  'Lumir Balanced 12.5:12.5': {
    name: 'Lumir Balanced 12.5:12.5',
    thc: 'Balanced',
    route: 'Oil',
    medProfile: 'THC 12.5mg/ml CBD 12.5 mg/ml',
    unitQty: '30ml',
    manufacturer: 'Target/FirsLine',
    salePrice: '£110',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '-',
    originStrain: 'Wild Thailand & CBD High Yaron',
    originCountry: '-',
  },
  Kanabo: {
    name: 'Kanabo',
    thc: 'High THC',
    route: 'Flos',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Target/FirsLine',
    salePrice: '£75',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '-',
    originStrain: 'Creamy Keyes (Vanilla Cream)',
    originCountry: '-',
  },
  Noidecs: {
    name: 'Noidecs',
    thc: 'High THC',
    route: 'Vape',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Dispensary Green',
    salePrice: '£80',
    strain: 'Strain 3',
    brand: 'Brand 3',
    pricePerUnit: '-',
    originStrain: 'T-340',
    originCountry: '-',
  },
  'Noidecs (Terpean1)': {
    name: 'Noidecs (Terpean1)',
    thc: 'High THC',
    route: 'Vape',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Dispensary Green',
    salePrice: '-',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
  'Noidecs (Terpean2)': {
    name: 'Noidecs (Terpean2)',
    thc: 'High THC',
    route: 'Vape',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Kanabo (Cartridge)',
    salePrice: '-',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
  'Kanabo (Cartridge)': {
    name: 'Kanabo (Cartridge)',
    thc: 'Balanced',
    route: 'Vape',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Kanabo (Cartridge)',
    salePrice: '-',
    strain: 'Strain 3',
    brand: 'Brand 3',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
  'Wild Thailand': {
    name: 'Wild Thailand',
    thc: 'High THC',
    route: 'Flos',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Target/FirsLine',
    salePrice: '-',
    strain: 'Strain 1',
    brand: 'Brand 1',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
  'Legendary Larry': {
    name: 'Legendary Larry',
    thc: 'High THC',
    route: 'Flos',
    medProfile: '-',
    unitQty: '-',
    manufacturer: 'Target/FirsLine',
    salePrice: '-',
    strain: 'Strain 2',
    brand: 'Brand 2',
    pricePerUnit: '-',
    originStrain: '-',
    originCountry: '-',
  },
};
