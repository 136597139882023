import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AUDIT_FORM } from 'src/assets/patientQuestionnaires';

@Component({
  selector: 'app-audit-note-modal',
  templateUrl: './audit-note-modal.component.html',
  styleUrls: ['./audit-note-modal.component.scss']
})
export class AuditNoteModalComponent implements OnInit {
  @Input() auditNote: any;
  @Output() hideModal = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  get auditForm(){
    return AUDIT_FORM;
  }

}
