<div [ngSwitch]="referralLetterState">
	<form #form="ngForm" *ngSwitchCase="'initial'" autocomplete="off" novalidate >
		<div class="form-group">
			<label for="reason">Reason for referral</label>
			<textarea required rows="5" class="form-control" name="reason" id="reason" [(ngModel)]="model.reason"></textarea>
		</div>

		<div class="form-group">
			<label for="speciality">Referral speciality</label>
			<textarea required rows="5" class="form-control" name="speciality" id="speciality" [(ngModel)]="model.speciality"></textarea>
		</div>

		<button type="button" class="btn btn-danger" (click)="moveToCanceledState()">Delete</button>    
		<button type="button" class="btn btn-primary" (click)="referralLetterState = 'proposed'" [disabled]="!form.form.valid">Add</button>
	</form>

	<div *ngSwitchCase="'proposed'" class="summary">

		<p><strong>Reason for referal:</strong><br /> {{model.reason}}
		</p>
		<p><strong>Referral speciality:</strong><br /> {{model.speciality}}
		</p>

		<form #pinForm="ngForm" autocomplete="off" novalidate>
			<div class="form-group">
				<label for="pin">Enter your pin to continue</label>
				<input class="form-control pw" id="pin" name="pin" required type="text" [(ngModel)]="model.pin" autocomplete="off" />
			</div>
			<button type="button" class="btn btn-default" (click)="referralLetterState = 'initial'">Change</button>
			<button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="pinForm.form.invalid">Confirm</button>
		</form>
	</div>

	<div *ngSwitchCase="'canceled'" class="summary">
		<form #pinForm="ngForm" autocomplete="off" novalidate>
			<div class="form-group" *ngIf="referralLetterId">
				<label for="pin">Confirm referral letter deletion by entering your pin</label>
				<input class="form-control pw" id="pin" name="pin" required type="text" [(ngModel)]="model.pin" autocomplete="off" />
			</div>
			<label *ngIf="!referralLetterId">Confirm referral letter deletion</label>
		</form>
		<button type="button" class="btn btn-default" (click)="referralLetterState = 'initial'">Change</button>
		<button type="button" class="btn btn-primary" (click)="onDelete()" [disabled]="pinForm.form.invalid">Confirm</button>
	</div>
</div>