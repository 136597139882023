import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import * as moment from 'moment';

@Component({
    selector: 'app-fit-note',
    templateUrl: './fit-note.component.html'
})
export class FitNoteComponent implements OnInit {

    fitNote: any;
    doctorNote: any;
    order: any;
    patient: any;
    daysValid: number;

    constructor(
        private orderService: DataService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            let orderItemId = +params['orderItemId'];

            this.orderService.getOrderItem(orderItemId).subscribe(orderItem => {
                forkJoin([
                    this.orderService.getFitNote(orderItem.appointment.id),
                    this.orderService.getDoctorNote(orderItem.appointment.id),
                    this.orderService.getPatient(orderItem.patient.id)
                ]).subscribe(([fitNote, doctorNote, patient]) => {
                    this.order = orderItem;
                    this.fitNote = fitNote;
                    this.doctorNote = doctorNote;
                    this.patient = patient;
                    
                    let firstDate = moment(this.fitNote.fromDate);
                    let secondDate = moment(this.fitNote.expirationDate);

                    this.daysValid = Math.abs(firstDate.diff(secondDate, 'days')) + 1; // day i to day i+k (both inclusive) has k+1 days.
                });
            });
        });
    }

    print() {
        window.print();
    }
}
