<div class="notes-container">
    <div class="note-row header">
        <div class="question-col text-center"><b>Question</b></div>
        <div class="choice-col text-center"><b>Response</b></div>
        <div class="description-col"><b>Comments</b></div>
    </div>
    <section *ngFor="let step of questionnaire.urlParam" class="notes-section">
        <div class="header-container">
            <h3><b>{{questionnaire.data[step].title}}</b></h3>
        </div>
        <ng-container *ngFor="let key of questionnaire.data[step].keys">
            <div *ngIf="renderQuestion(step, key)" class="note-row">
                
                <!-- feedbackType.text -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.text">
                    <div class="question-col">{{getQuestion(step, key).question}}</div>
                    <div class="choice-col"></div>
                    <div class="description-col">
                        <app-comment-label [response]="getResponse(step, key)"></app-comment-label>
                    </div>
                </ng-container>

                <!-- feedbackType.choice || feedbackType.choiceAndTextOnCondition -->
                <ng-container *ngIf="[feedbackType.choice,feedbackType.choiceAndTextOnCondition].includes(getQuestion(step, key).responseType)">
                    <div class="question-col">{{getQuestion(step, key).question}}</div>
                    <div class="choice-col">
                        <ng-container [ngSwitch]="getResponse(step, key).choice">
                            <span *ngSwitchCase="true" class="label label-primary">Yes</span>
                            <span *ngSwitchCase="false" class="label label-warning">No</span>
                            <span *ngSwitchDefault>{{getResponse(step, key).choice}}</span>
                        </ng-container>
                    </div>
                    <div class="description-col">
                        <app-comment-label *ngIf="getQuestion(step, key).responseType === feedbackType.choiceAndTextOnCondition" [response]="getResponse(step, key)"></app-comment-label>
                    </div>
                </ng-container>

                <!-- feedbackType.checkbox -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkbox">
                    <div class="question-col">{{getQuestion(step, key).question}}</div>
                    <div class="choice-col">
                        <ng-container *ngFor="let choice of getResponse(step, key).choices">
                            <div *ngIf="choice.checked">{{choice.label}}</div>
                        </ng-container>
                    </div>
                    <div class="description-col"></div>
                </ng-container>

                <!-- feedbackType.checkboxQuestion -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkboxQuestion">
                    <ng-container *ngFor="let choice of getResponse(step, key).choices">
                        <ng-container *ngIf="choice.checked">
                            <div class="question-col">{{choice.label}}</div>
                            <div class="choice-col">&nbsp;</div>
                            <div class="description-col">Adviced</div></ng-container>
                    </ng-container>
                </ng-container>

                <!-- feedbackType.checkboxAndText -->
                <ng-container *ngIf="getQuestion(step, key).responseType === feedbackType.checkboxAndText">
                    <ng-container *ngFor="let choice of getCheckedChoices(step, key); index as i">
                        <ng-container *ngIf="choice.checked">
                            <div *ngIf="i === 0; else emptyQuestion" class="question-col">{{getQuestion(step, key).question}}</div>
                            <ng-template #emptyQuestion>
                                <div class="question-col"></div>
                            </ng-template>
                            <div class="choice-col">
                                <div>{{choice.label}}</div>
                            </div>
                            <div class="description-col">
                                <app-comment-label [response]="choice"></app-comment-label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </section>
</div>
