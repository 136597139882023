import { EventEmitter, Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalEvent: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  openModal(modalName: string, orderId?: string): void {
    const currentUrl: string = this.router.url;
    if (currentUrl.includes('past-orders')) {
      const data: any = {
        modalName,
        orderId
      };
      this.modalEvent.emit(data);
    } else {
      this.modalEvent.emit(modalName);
    }
  }
}
