<p>
    <ng-container *ngIf="!!response.patientText && !!response.pharmacyText">
        <b>Patient's Comment:</b><br/>
        <span>{{response.patientText}}</span>
        <br/>
        <br/>
        <b>Pharmacist's Comment:</b><br/>
        <span>{{response.pharmacyText}}</span>
    </ng-container>
    
    <ng-container *ngIf="!!response.patientText && !response.pharmacyText">
        <b>Patient's Comment:</b><br/>
        <span>{{response.patientText}}</span>
    </ng-container>

    <ng-container *ngIf="!response.patientText && !!response.pharmacyText">
        <b>Pharmacist's Comment:</b><br/>
        <span>{{response.pharmacyText}}</span>
    </ng-container>
</p>