import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public errorMessage: string;
  private returnUrl: string;
  otpDetails = undefined;

  model: any = {};
  otpModel: any = {};

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    activatedRoute.params.subscribe((queryParams) => {
      this.returnUrl = queryParams['returnUrl'];
    });
  }

  ngOnInit() {
    if (this.accountService.loggedIn()) {
      return this.router.navigate(['']);
    }
  }

  onLogin() {
    this.errorMessage = '';
    this.accountService
      .login(this.model.username, this.model.password)
      .subscribe({
        next: (result) => {
          if (result.value) {
            this.accountService.authenticate(result.value);
            this.router.navigateByUrl(this.returnUrl || '');
          } else {
            this.otpDetails = result;
          }
        },
        error: (error) => {
          if (error.status == 401) {
            this.errorMessage = 'Invalid Credentials';
          }
          if (error.status == 403) {
            this.errorMessage =
              "Please confirm your account by entering the 6 digit code you received on your mobile phone. If you're having troubles please contact the Treat it";
          }
          if (error.status == 423) {
            this.errorMessage =
              "You've reached the maximum number of login attempts allowed. You can reset your password following the link below: 'Forgot your Password'";
          }
          if (error.status == 412) {
            this.router.navigateByUrl(
              '/reset-password?username=' + this.model.username
            );
          }
          document.body.scrollTop = 0;
        },
      });
  }

  onOTPSubmit() {
    this.errorMessage = '';
    if (this.otpModel.topt === undefined) {
      this.errorMessage = 'OTP number is required!';
      return;
    }

    this.accountService
      .verifyOTP(
        this.otpModel.topt,
        this.model.username,
        this.model.password,
        this.otpDetails.key
      )
      .subscribe({
        next: (result) => {
          this.accountService.authenticate(result.value);
          this.router.navigateByUrl(this.returnUrl || '');
        },
        error: (error) => {
          this.errorMessage = 'Invalid OTP number';
        },
      });
  }
}
