<div class="container" *ngIf="treatments && orderItem">
	<div *ngIf="patient?.alerts" class="alert alert-dark-red" (click)="showAlerts = !showAlerts">
		<strong>Key message alerts: </strong>
		<span *ngIf="!showAlerts">{{patient.alerts | slice:0:50}}</span><span *ngIf="patient.alerts.length > 50 && !showAlerts">...</span>
		<span *ngIf="patient.alerts.length > 50">
			<span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
			<span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
		</span>
		<div *ngIf="showAlerts"> {{patient?.alerts}}</div>
	</div>
	<h1 class="brand-1">Review order {{orderItem.id}}</h1>
	<h4>Ordered on: {{orderItem.creationDate | date}}</h4>
	<div class="row">
		<div class="col col-md-4">
			<div class="well well--black">
				<h3>Patient Details</h3>
				<p><strong>Patient ID:</strong> {{orderItem.patient.id}}</p>
				<p><strong>Name:</strong> {{orderItem.patient.displayName}}</p>
				<p><strong>Phone:</strong> {{orderItem.patient.phone}}</p>
				<p><strong>Email:</strong> {{orderItem.patient.email}}</p>
				<p><strong>DOB:</strong> {{patient.dateOfBirth | date : "dd/MM/yyyy" }}</p>
				<p><strong>Gender:</strong> {{patient.gender}}</p>
				<p class="address">
					<strong>Address</strong>
          			<app-address [address]="patient.address"></app-address>
				</p>
				<p class="address">
					<strong>Nominated Pharmacy</strong><br />
          			<span *ngIf="orderItem.pharmacy.displayName">{{ orderItem.pharmacy.displayName }}</span>
					<app-address [address]="orderItem.pharmacy.address"></app-address>
				</p>
				<p class="address">
					<strong>GP Address</strong> <br />
					{{patient.preferences.gpName}}<br *ngIf="patient.preferences.gpName" />
					{{patient.preferences.gpPractice}}<br *ngIf="patient.preferences.gpPractice" />
					<app-address *ngIf="patient.preferences.gpAddress" [address]="patient.preferences.gpAddress"></app-address>
					<span *ngIf="!patient.preferences.gpAddress">Patient not provided</span>
				</p>
			</div>
			<div class="well well--white">
				<h3 class="brand-1">Order log history</h3>
				<p *ngIf="orderItem.updateDate">
					<strong>{{orderItem.updateDate | date : 'dd/MM/yyyy HH:mm'}}</strong><br />
				  	Order was updated by {{orderItem.doctor.displayName}}
				</p>
				<p><strong>{{orderItem.creationDate | date : 'dd/MM/yyyy HH:mm'}}</strong><br /> Order was generated</p>
			</div>
		</div>
		<div class="col col-md-8">
			<div class="well well--white">
				<h3 class="brand-1">Order actions</h3>
        <h4 *ngIf=" orderItem.workflow.COUPON">Voucher Code : {{ orderItem.workflow.COUPON }}</h4>
        <button *ngIf="orderItem.status === 'RESERVED_BY_DOCTOR' && orderItem.workflow.COUPON" class="btn btn-success" (click)="approveModal.show()">Accept</button>
        <button *ngIf="orderItem.status === 'RESERVED_BY_DOCTOR' && !orderItem.workflow.COUPON && !orderItem.formType" class="btn btn-success" (click)="approveModal.show()">Propose Treatment</button>
		<button *ngIf="orderItem.status === 'RESERVED_BY_DOCTOR' && !orderItem.workflow.COUPON && orderItem.formType" class="btn btn-success" (click)="approveModal.show()">Propose Formulary</button>
        <button *ngIf="orderItem.status === 'RESERVED_BY_DOCTOR'" class="btn btn-danger" (click)="rejectModal.show()">Reject</button>			</div>
			<div class="alert alert-{{orderItem | orderState}}"><strong>Order status:</strong> {{orderItem.status | orderStatus}}</div>
			<div class="well well--white">
				<h3 class="brand-1">Order Details </h3>
				<div *ngIf="orderItem.type != 'TRAVEL'">
					<h4>Condition: {{orderItem.name}}</h4>
				</div>
				<div *ngIf="orderItem.type == 'TRAVEL'">
					<h4>{{orderItem.name}}</h4>
					<p>Area = <strong>{{orderItem.trip.area}}</strong></p>
					<p>Trip Start = <strong>{{orderItem.trip.startDate  | date : 'dd/MM/yyyy'}}</strong> | Trip End = <strong>{{orderItem.trip.endDate  | date : 'dd/MM/yyyy'}}</strong></p>
				</div>
				<h4>Assessment form answers</h4>
				<app-assessment-form [orderItem]="orderItem"></app-assessment-form>
			</div>


			<div class="well well--white">
				<h3 class="brand-1">Ask Question</h3>
				<div *ngIf="enablePatientQuestion">
					<textarea [(ngModel)]="patientQuestion" rows="5" style="width:100%;" maxlength="300" placeholder="Enter question to be asked from the patient"></textarea>
					<div class="count-div">
						<div class="count-text" style="text-align: right;" >{{patientQuestion.length || 0}}/300</div>
					</div>
					<button [disabled]="patientQuestion.length == 0" class="btn btn-success" (click)="submitQuestion()" >Submit</button> 
				</div>
				<div *ngIf="!enablePatientQuestion">
					<h4>{{docQuestioned}}</h4>
					<label>{{patientQuestion}}</label>
					<p>{{patientAnswer}}</p>
				</div>
			</div>


			<div class="well well--white">
				<h3 class="brand-1">Patient history</h3>
				<app-patient-history [orders]="orders"></app-patient-history>
			</div>
		</div>
	</div>
	<div bsModal #approveModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" (click)="approveModal.hide()" aria-label="Close">
            			<span aria-hidden="true">&times;</span>
          			</button>
					<h4 class="modal-title" *ngIf="!orderItem.formType">
						Propose Treatment
					</h4>
					<h4 class="modal-title" *ngIf="orderItem.formType">
						Propose Formulary
					</h4>
				</div>
				<div class="modal-body">
					<form #approveForm="ngForm" autocomplete="off" novalidate *ngIf="!showSummary">
						<p *ngIf="chosenTreatment">
							The patient has selected <strong>{{chosenTreatment}}</strong> as their preferred treatment option
						</p>
						<p *ngIf="chosenFormulary">
							The patient has selected <strong>{{chosenFormulary.name}} - {{chosenFormulary.strength}} - {{chosenFormulary.tablets}} {{chosenFormulary.formulation}}</strong> as their preferred treatment option
							<span *ngIf="chosenFrequency">for a frequency of <strong>{{chosenFrequency}} days</strong></span>
						</p>

						<div class="form-group" *ngIf="treatments.length >= 1">
							<label for="treatment" *ngIf="chosenTreatment">Proposed treatment</label>
							<label for="treatment" *ngIf="!chosenTreatment">Please select a treatment</label>
							<select name="treatment" id="treatment" class="form-control" *ngIf="!orderItem.workflow.COUPON" required [(ngModel)]="treatment">
								<option disabled value=""> -- select an option -- </option>
								<option *ngFor="let t of treatments" [value]="t">{{t}}</option>
							</select>
						<select name="treatment" id="voucherTreatment" class="form-control" *ngIf="orderItem.workflow.COUPON" required [(ngModel)]="treatment" [disabled]="true">
							<option [value]="chosenTreatment">{{chosenTreatment}}</option>
						</select>
						</div>

						<div class="form-group" *ngIf="orderItem.formType">
							
							<label for="formulary" *ngIf="chosenFormulary">Proposed Formulary</label>
							<label for="formulary" *ngIf="!chosenFormulary">Please select a Formulary</label>
							<select name="formulary" id="formulary" class="form-control" *ngIf="!orderItem.workflow.COUPON" required [(ngModel)]="formulary">
								<option disabled value=""> -- select an option -- </option>
								<option *ngFor="let m of medications" [value]="m.id">{{m.name}} - {{m.strength}} - {{m.tablets}} {{m.formulation}}</option>
							</select>
							
							<!-- this checks whether it contains a set of available frequencies -->
							<span *ngIf="this.orderItem.formType.availableFrequencies  && orderItem.repeatFormOrderSubscription">
								<br>
								<label for="orderFrequency" *ngIf="chosenFrequency">Proposed Frequency</label>
								<label for="orderFrequency" *ngIf="!chosenFrequency">Please select a Frequency</label>
								<select name="orderFrequency" id="orderFrequency" class="form-control" *ngIf="(!orderItem.workflow.COUPON)" required [(ngModel)]="orderFrequency">
									<option disabled value=""> -- select an option -- </option>
									<option *ngFor="let f of frequencies" [value]="f">Every {{f}} Days for the Next Six Months</option>
								</select>
							</span>

							<select name="formulary" id="voucherTreatment" class="form-control" *ngIf="orderItem.workflow.COUPON" required [(ngModel)]="formulary" [disabled]="true">
								<option [value]="chosenFormulary">{{chosenFormulary.name}} - {{chosenFormulary.strength}} - {{chosenFormulary.tablets}} Tablets</option>
							</select>
						</div>


						<div *ngIf="orderItem.type == 'TRAVEL'">
							<h4>{{orderItem.name}}</h4>
							<p>Area = <strong>{{orderItem.trip.area}}</strong></p>
							<p>Trip Start = <strong>{{orderItem.trip.startDate | date : 'dd/MM/yyyy'}}</strong> | Trip End = <strong>{{orderItem.trip.endDate  | date : 'dd/MM/yyyy'}}</strong></p>
							<hr />
							<div *ngIf="orderItem.requestedAntimalarial">
								<div class="form-group">
								<label for="malariaTreatment">Malaria Treatment</label>
								<select id="malariaTreatment" name="malariaTreatment" class="form-control" required [(ngModel)]="malariaTreatment" (ngModelChange)="changeMalariaTreatment($event)">
									<option disabled value=""> -- select an option -- </option>
									<option value="none">None - Reject Treatment</option>
									<option *ngFor="let treatment of malariaTreatments" [ngValue]="treatment.id">{{treatment.name}}</option>
								</select>
								</div>
								<div *ngIf="malariaTreatment != 'none'">
								<div class="form-group">
									<label for="malariaStartDate">Start Date</label>
									<input class="form-control" type="date" placeholder="yyyy-mm-dd" id="malariaStartDate" name="malariaStartDate" required [value]="orderItem.requestedAntimalarial.dosageStartDate | date:'yyyy-MM-dd'" (input)="orderItem.requestedAntimalarial.dosageStartDate=parseDate($event.target.value)" [disabled]="!malariaTreatmentChanged">
								</div>
								<div class="form-group">
									<label for="malariaEndDate">End Date</label>
									<input class="form-control" type="date" placeholder="yyyy-mm-dd" id="malariaEndDate" name="malariaEndDate" required  [value]="orderItem.requestedAntimalarial.dosageEndDate | date:'yyyy-MM-dd'" (input)="orderItem.requestedAntimalarial.dosageEndDate=parseDate($event.target.value)" [disabled]="!malariaTreatmentChanged">
								</div>
								<div class="form-group">
									<label for="malariaTreatmentLength">Tablets needed</label>
									<input id="malariaTreatmentLength" name="malariaTreatmentLength" type="number" min="1" class="form-control" required [(ngModel)]="malariaTreatmentLength" [disabled]="!malariaTreatmentChanged" />
								</div>
								</div>
								<hr>
							</div>
							<div class="form-group" *ngIf="orderItem.requestedVaccinations.length > 0">
								<label>Vaccination(s)</label>
								<div *ngFor="let treatment of orderItem.requestedVaccinations; let i = index" [ngClass]="{'inactive': treatment.isRejected}">
								<div>
									<p><span>{{treatment.name}}</span> <button class="btn btn-primary pull-right" *ngIf="treatment.isRejected" (click)="cancelRejectVaccine(treatment)">Cancel</button> <button class="btn btn-danger pull-right" *ngIf="!treatment.isRejected" (click)="removeVaccine(treatment)">Reject</button></p>
								</div>
								<div class="form-group">
									<label for="vaccineStartDate{{i}}">Start Date</label>
									<input  class="form-control" type="date" placeholder="yyyy-mm-dd" id="vaccineStartDate{{i}}" name="malariaEndDate" required  [value]="treatment.startDate | date:'yyyy-MM-dd'" (input)="treatment.startDate=parseDate($event.target.value)" [disabled]="true">
								</div>
								<hr />
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="notes">Notes for patient record</label>
							<textarea rows="4" class="form-control" name="notes" id="notes" [(ngModel)]="additionalNotes"></textarea>
						</div>
						<div class="form-group">
							<label for="notes">Key message alerts</label>
							<textarea rows="4" class="form-control" name="alerts1" id="alerts1" [(ngModel)]="alerts"></textarea>
						</div>
						<div *ngIf="orderItem.type == 'TRAVEL'">
						<button type="button" class="btn btn-primary" [disabled]="!approveForm.form.valid" (click)="showSummary = true; malariaTreatmentName = getMalariaName(malariaTreatment); checkVaccines()">Finish</button>
						</div>
						<div *ngIf="orderItem.type != 'TRAVEL'">
							<label for="dosageInstructions">Dosage Instructions</label>
							<textarea rows="4" class="form-control" id="dosageInstructions" name="dosageInstructions" [(ngModel)]="dosageInstructions" required></textarea>
							<br/>

							<div *ngIf="patientConsideredForRepeatPrescriptions" class="form-group">
								<label for="repeatPrescriptions">This patient would like to be considered for repeat prescriptions:</label>

								<div class="radio_control radio-btn">
									<input required type="radio" [value]="true" [(ngModel)]="doctorApprovedRepeatPrescriptions" name="doctorApprovedRepeatPrescriptions" id="doctorApprovedRepeatPrescriptionsYes"/>
									<label class="radio_label" for="doctorApprovedRepeatPrescriptionsYes">Yes</label>
									<input required type="radio" [value]="false" [(ngModel)]="doctorApprovedRepeatPrescriptions" name="doctorApprovedRepeatPrescriptions" id="doctorApprovedRepeatPrescriptionsNo"/>
									<label class="radio_label" for="doctorApprovedRepeatPrescriptionsNo">No</label>
								</div>
								<div class="form-group" *ngIf="doctorApprovedRepeatPrescriptions">
									<div class="col-md-6 lower">
										<label for="frequency">Minimum period between release of repeat prescription(in days)</label>
										<input type="number" required class="form-control" name="frequency" id="frequency" [(ngModel)]="frequency" (keypress)="preventDecimalAndPower($event)" (ngModelChange)="updateNoRepeatsAllowed(refRepeatsField)"/>
									</div>
									<div class="col-md-6 lower bigger">
										<label for="repeats">Number of repeats(excluding first supply)</label>
										<select #refRepeatsField required class="form-control" name="repeats" id="repeats" [disabled]="!frequency || repeatPrescriptionOptions.length < 1" [(ngModel)]="noOfRepeatPrescriptions">
											<option *ngFor="let option of repeatPrescriptionOptions" [ngValue]="option">{{option}}</option>
										</select>
									</div>
								</div>
							</div>

							<button type="button" class="btn btn-primary" [disabled]="!approveForm.form.valid" (click)="showSummary = true">Finish</button>
						</div>

						</form>
						<form #pinForm="ngForm" autocomplete="off" novalidate *ngIf="showSummary">
							<div *ngIf="orderItem.type == 'TRAVEL'">
							<p *ngIf="orderItem.requestedAntimalarial">
								<strong>Malaria Treatment =</strong><br />
								<span>{{malariaTreatmentName}}</span>
								<span *ngIf="malariaTreatmentName != 'None'"> - {{malariaTreatmentLength}} Tablets</span>
								<span *ngIf="malariaTreatmentName != 'None'">Start Date = {{orderItem.requestedAntimalarial.dosageStartDate | date : 'dd/MM/yyyy'}}</span>
								<span *ngIf="malariaTreatmentName != 'None'">End Date = {{orderItem.requestedAntimalarial.dosageEndDate | date : 'dd/MM/yyyy'}}</span>
							</p>
							<p *ngIf="orderItem.requestedVaccinations.length > 0">
								<strong>Vaccinations =<br /></strong>
								<span *ngIf="noVaccines">None</span>
								<span *ngFor="let vaccine of orderItem.requestedVaccinations"><span *ngIf="!vaccine.isRejected">{{vaccine.name}}<br /></span></span>
							</p>
						</div>
						<div *ngIf="orderItem.type != 'TRAVEL'">
							<p *ngIf="chosenTreatment">
								The patient has selected <strong>{{chosenTreatment}}</strong> as their preferred treatment option
							</p>
							<p>You have selected <strong>{{treatment}}</strong>.</p>
						</div>
						<div *ngIf="additionalNotes">
							<p>Additional notes:</p>
							<pre>{{additionalNotes}}</pre>
						</div>
						<div *ngIf="alerts">
							<p>Key message alerts:</p>
							<pre>{{alerts}}</pre>
						</div>
						<div *ngIf="dosageInstructions">
							<p>Dosage Instructions:</p>
							<pre>{{dosageInstructions}}</pre>
						</div>
						<div *ngIf="doctorApprovedRepeatPrescriptions">
							<p><strong>This patient would like to be considered for repeat prescriptions:</strong></p>
							<!-- <strong>{{doctorApprovedRepeatPrescriptions == true ? 'Yes': 'No'}}</strong> -->
						</div>
						<div *ngIf="frequency">
							<p class="col-md-4">Minimum period between release of repeat prescription(in days)</p>
							<pre class="col-md-2">{{frequency}}</pre>
						</div>
						<div *ngIf="noOfRepeatPrescriptions">
							<p class="col-md-4">Number of repeats(excluding first supply)</p>
							<pre class="col-md-2">{{noOfRepeatPrescriptions}}</pre>
						</div>
						<div class="form-group">
							<label for="pin">Confirm your prescription by entering your pin</label>
							<input class="form-control pw" id="pin" name="pin" required type="text" [(ngModel)]="pin" autocomplete="off" />
						</div>
						<button type="button" class="btn btn-default" (click)="showSummary = false">Edit</button>
						<button type="button" class="btn btn-primary" (click)="approveModal.hide(); approveItem();" [disabled]="pinForm.form.invalid">Confirm</button>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div bsModal #rejectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" (click)="rejectModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
					<h4 class="modal-title">Reject order</h4>
				</div>
				<div class="modal-body">
					<form #rejectForm="ngForm" autocomplete="off" novalidate (ngSubmit)="rejectItem()">
						<div class="form-group">
							<label for="rejectReason">Reason for rejection</label>
							<textarea required rows="5" class="form-control" name="rejectReason" id="rejectReason" [(ngModel)]="rejectReason"></textarea>
						</div>
						<div class="form-group">
							<label for="notes">Key message alerts</label>
							<textarea rows="4" class="form-control" name="alerts" id="alerts" [(ngModel)]="alerts"></textarea>
						</div>
						<div class="form-group">
							<label for="pin">Pin</label>
							<input id="pin" class="form-control pw" type="text" name="pin" required [(ngModel)]="pin" autocomplete="off" />
						</div>
						<button type="submit" class="btn btn-primary" [disabled]="!rejectForm.form.valid">Submit</button>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div bsModal #completeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" (click)="completeModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
					<h4 class="modal-title">Complete order</h4>
				</div>
				<div class="modal-body">
					<form #completeForm="ngForm" autocomplete="off" novalidate (ngSubmit)="complete()">
						<div class="checkbox">
							<label>
							  <input id="sentPrescription" type="checkbox" name="sentPrescription" required [equal]="true" ngModel /> Please tick this box to confirm you are sending the <a style="text-decoration: underline" routerLink="/prescriptions/{{orderItem.id}}" target="_blank">Prescription</a>
							</label>
						</div>
						<button type="submit" class="btn btn-primary" [disabled]="!completeForm.form.valid">Submit</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
