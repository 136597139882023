import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GlobalOptions } from '../app.module';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MdtService {
  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  getAllMDTs(){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-committee`;
    return this.http.get<any>(url);
  }

  getAllSessions(page: number = 0){
    return this.http.get<any>(`${this.options.apiEndPoint}api/doctor/mdt-sessions?page=${page}&size=12`);
  }

  getActiveSession(){
    return this.http.get<any>(`${this.options.apiEndPoint}api/doctor/mdt-session`);
  }

  getCompletedSession(sessionId){
    return this.http.get<any>(`${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}`);
  }

  public getOrdersForReview(searchType: string, searchValue: string, page: number): Observable<any> {
    let url: any = `${this.options.apiEndPoint}api/doctor/order-items?page=${page}&size=10&sort=creationDate,asc&status=AWAITING_MDT_SESSION_INITIATION,IN_MDT_SESSION,AWAITING_MDT_SESSION_COMPLETION`;

    if (!!searchValue) {
      url = `${url}&searchValue=${searchValue}&searchType=${searchType}`;
    }

    return this.http.get<any>(url);
  }

  createSession(){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session`;
    return this.http.post<any>(url, {});
  }

  saveSessionParticipants(sessionId, data){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}/session-note`;
    return this.http.post<any>(url, data);
  }

  updateSession(data){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session`;
    return this.http.put<any>(url, data);
  }

  addOrderToSession(sessionId, data){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}/order-link`;
    return this.http.post<any>(url, data);
  }

  saveProforma(orderId, data){
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  saveMDTDecision(orderId, data){
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  saveMDTRecommendation(orderId, data){
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  finalizeSession(sessionId){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}/finalize`;
    return this.http.put<any>(url, {});
  }

  cancelSession(sessionId){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}`;
    return this.http.delete<any>(url);
  }

  // Deprecated ------------------------------------------------------------------------------------
  editSessionOrders(sessionId, data){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-sessions/${sessionId}/order-links`;
    return this.http.post<any>(url, data);
  }

  createMDTDecision(orderId: number, data){
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  finalizeMDTSession(sessionId){
    const url = `${this.options.apiEndPoint}api/doctor/mdt-session/${sessionId}/finalize`;
    return this.http.put<any>(url, {})
  }

  createMDTSessionApproval(data){
    const url = `${this.options.apiEndPoint}api/doctor/session-note`;
    return this.http.post<any>(url, data)
  }

  getSpecialists(){
    const url = `${this.options.apiEndPoint}api/doctor/specialists`;
    return this.http.get<any>(url);
  }

  updateSpecialist(id, specialist) {
    const url = `${this.options.apiEndPoint}api/doctor/specialist/patient?patientId=${id}&careSpecialist=${specialist}`;
    return this.http.put<any>(url, {});
  }

}
