<div class="modal-dialog patient-modal">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
                Patient Notes
            </h4>
        </div>
        <div class="modal-body">
            <app-tab-nav [activeTab]="activeTab" [tabs]="patientTabs" (changeTab)="setTab($event)" [small]="true">

                <section *ngIf="activeTab === tab.AUDIT_FORM" class="patient-content">
                    <div class="note-score">Score: {{selectedPatientNotes.AUDIT_FORM.answers.score}}</div>
                    <div *ngFor="let item of selectedPatientNotes.AUDIT_FORM.form | keyvalue" class="patient-response">
                        <h4>{{item.value.label}}</h4>
                        <span>{{item.value.values[selectedPatientNotes.AUDIT_FORM.answers[item.key]]}}</span>
                    </div>
                </section>

                <section *ngIf="activeTab === tab.PEG_FORM" class="patient-content">
                    <div class="note-score">
                        Average: {{!!selectedPatientNotes.PEG_FORM.answers.average 
                            ? getPEGAverage(selectedPatientNotes.PEG_FORM.answers.average) 
                            : ''}}
                    </div>

                    <div *ngFor="let item of selectedPatientNotes.PEG_FORM.form | keyvalue" class="patient-response">
                        <h4>{{item.value}}</h4>
                        <span>{{selectedPatientNotes.PEG_FORM.answers[item.key]}}</span>
                    </div>
                </section>

                <section *ngIf="activeTab === tab.GAD_FORM" class="patient-content">
                    <div class="note-score">Score: {{selectedPatientNotes.GAD_FORM.answers.score}}</div>
                    <div *ngFor="let item of selectedPatientNotes.GAD_FORM.form | keyvalue" class="patient-response">
                        <h4>{{item.value.label}}</h4>
                        <span>{{item.value.values[selectedPatientNotes.GAD_FORM.answers[item.key]]}}</span>
                    </div>
                </section>

                <section *ngIf="activeTab === tab.PATIENT_DETAIL_CONFIRMATION" class="patient-content">
                    <h3>The patient has confirmed that:</h3>
                    <div *ngFor="let item of selectedPatientNotes.PATIENT_DETAIL_CONFIRMATION.form | keyvalue"
                        class="patient-response">
                        <div *ngIf="selectedPatientNotes.PATIENT_DETAIL_CONFIRMATION.answers[item.key] === true"
                            class="patient-statement">
                            <img src="/assets/vectors/success.svg" alt="">
                            <h4>{{item.value}}</h4>
                        </div>
                    </div>
                </section>

            </app-tab-nav>
        </div>
    </div>
</div>