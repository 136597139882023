export const recommendationToolQuestionnaire = [
  {
    id: "q_1",
    question: 'Is the patient a naïve cannabis user?',
    description: 'Cannabis Naïve users should “start low and go slow” with consumption, in a comfortable place and a trusted confidante close by. More experienced users should aim to use the minimum effective dose.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: -3,
      vaporize: 1,
      oil: 0,
      cbd: 2,
      balanced: 1,
      thc: -5
    }
  },
  {
    id: "q_2",
    question: 'Does the patient typically smoke (cigarettes, e-cig)? Accustomed to smoking practices?',
    description: 'If a patient is a non- smoker. Alternatives to smoking are essential.',
    type: "radio",
    noResponse: {
      flower: -3,
      vaporize: 0,
      oil: 2,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: 2,
      vaporize: 2,
      oil: 1,
      cbd: 0,
      balanced: 0,
      thc: 0
    }
  },
  {
    id: "q_3",
    question: 'Did the patient suffers from anxiety or has he taken/is taking anti-anxiety medication?',
    description: 'High doses of cannabis THC can result in side effects of anxiety and paranoia. Recommended to avoid high THC products with patients that have an anxiety history',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: 2,
      vaporize: 2,
      oil: 1,
      cbd: 1,
      balanced: 0,
      thc: -3
    }
  },
  {
    id: "q_4",
    question: 'Does the patient/ condition requires fast and immediate onset?',
    description: 'Pulmunary ( smoking/ Vaporising) onset is immediate ( 10-15 minutes) in oppose to onset of 1 to 3 hours in oral administration.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 1,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: 1,
      vaporize: 1,
      oil: 0,
      cbd: 0,
      balanced: 1,
      thc: 1
    }
  },
  {
    id: "q_5",
    question: 'Contraindications - Does the patient suffer form any lung problems? (Asthma, pneumothorax, bronchial tubes, bronchitis, COPD, lung cancer, pneumonia)',
    description: 'Each medical case of course is needed to be examined by itself. Oral administration of cannabinoids  goes through 1st phase metabolism and may cause additional interfence.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: -5,
      vaporize: -2,
      oil: 1,
      cbd: 0,
      balanced: 0,
      thc: 0
    }
  },
  {
    id: "q_6",
    question: 'Contraidcitations - Does the patient suffer for liver problems or takes additional medicenes that might interfere with metabolism of Cannabinoids in the liver.',
    description: 'Each medical case of course is needed to be examined by itself. Oral administration of cannabinoids  goes through 1st phase metabolism and may cause additional interfence.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: 0,
      vaporize: 0,
      oil: -4,
      cbd: 0,
      balanced: 0,
      thc: 0
    }
  },
  {
    id: "q_7",
    question: 'Should the duration of drug action be limited (not more then 3-4 hours)',
    description: 'Oral delivery of Cannabis based medicene will have longer duration and effects of up to 8-9 hours . Pulmunary administration effects will usually take 3-4 to be eliminated.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 1,
      cbd: 0,
      balanced: 0,
      thc: 0
    },
    yesResponse: {
      flower: 1,
      vaporize: 1,
      oil: 0,
      cbd: 0,
      balanced: 0,
      thc: 0
    }
  },
  {
    id: "q_8",
    question: 'Indication - Is the pain described as a high acute pain (8+ on the pain scale)',
    description: 'Oral delivery of Cannabis based medicene will have longer duration and effects of up to 8-9 hours . Pulmunary administration effects will usually take 3-4 to be eliminated.',
    type: "radio",
    noResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 1,
      balanced: 1,
      thc: 0
    },
    yesResponse: {
      flower: 0,
      vaporize: 0,
      oil: 0,
      cbd: 0,
      balanced: 1,
      thc: 2
    }
  },
  {
    id: "q_9",
    question: 'Is the patient a Medical Cannabis patient',
    description: 'Ask about the current treatments',
    type: "radio",
    noResponse: false,
    yesResponse: true
  },
  {
    id: "q_10",
    question: 'What type of delivery method and dose of medicine',
    description: '',
    type: "text"
  },
];
