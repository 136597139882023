import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pain-scale',
  templateUrl: './pain-scale.component.html',
  styleUrls: ['./pain-scale.component.scss']
})
export class PainScaleComponent implements OnInit, OnChanges  {
  @Input() numbers: any[] = [];
  @Input() buttons: any[] = [];
  @Input() pegValue: any = null;
  @Input() key: string;
  @Input() painScaleControl: any;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

  public clickedNumberButton: any = null;
  private memoizedClassNames: Map<number, string> = new Map<number, string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    // Handle changes in input properties
    if ('pegValue' in changes) {
      this.clickedNumberButton = this.pegValue;
    }
  }

  public getNumberButtonClass(index: any): string {
    let className: string = 'level-' + index;
    if (index === this.clickedNumberButton) {
      className += ' selected';
    }
    this.memoizedClassNames.set(index, className);
    return className;
  }


  public getButtonBackgroundColor(index: any): string {
    const r: any = 255;
    const g: any = 223 - index * 10;
    const b: any = 223 - index * 10;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }

  public numberButtonClicked(index: any): void {
    this.clickedNumberButton = index;
    this.pegValue = index;
    this.buttonClicked.emit({ key: this.key, value: this.pegValue });
  }
}
