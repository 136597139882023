<div class="mdt-order-review container" *ngIf="!initLoading">
	<h1 class="brand-1">Review order {{orderItem.id}}</h1>
	<h4>Ordered on: {{orderItem.creationDate | date}}</h4>
	<div class="row">
		<div class="col col-md-4">
			<div class="well well--black" *ngIf="!!patient">
				<h3>Patient Details</h3>
				<p><strong>Patient ID:</strong> {{patient.id}}</p>
				<p><strong>Name:</strong> {{patient.displayName}}</p>
				<p><strong>Phone:</strong> {{patient.phone}}</p>
				<p><strong>Email:</strong> {{patient.email}}</p>
				<p><strong>DOB:</strong> {{patient.dateOfBirth | date : "dd/MM/yyyy" }}</p>
				<p><strong>Age:</strong> {{dataService.getPatientBirthday(patient.dateOfBirth) }}</p>
				<p><strong>Gender:</strong> {{patient.gender}}</p>
				<p><strong>NHS:</strong> {{patient.settings.NHS_NUMBER ? patient.settings.NHS_NUMBER : 'Not available'}}</p>
				<p class="address">
					<strong>Address</strong>
					<app-address [address]="orderItem.patient.address"></app-address>
				</p>
				<p class="address">
					<strong>GP Address</strong> <br />
					{{patient.preferences.gpName}}<br *ngIf="patient.preferences.gpName" />
					{{patient.preferences.gpPractice}}<br *ngIf="patient.preferences.gpPractice" />
					<app-address *ngIf="patient.preferences.gpAddress" [address]="patient.preferences.gpAddress">
					</app-address>
					<span *ngIf="!patient.preferences.gpAddress">Patient not provided</span>
				</p>
			</div>
		</div>
		<div class="col col-md-8">
			<div *ngIf="sessionExist" class="well well--white">
				<button *ngIf="!!patient" class="btn btn-default" (click)="modalService.openModal('keyAlertModal')">Admin Key Alerts</button>
				<h3 class="brand-1">Order actions</h3>
				<div>
					<input type="checkbox" [(ngModel)]="readRecommendationsAndNotes" id="readRecommendationsAndNotes"
						[disabled]="user.id !== session.reporter.id || orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'" />
					<label class="radio-label" for="readRecommendationsAndNotes">I have reviewed the GP Notes and
						Recommendations</label>
				</div>
				<div style="margin-top: 1rem;">
					<button class="btn btn-default"
						[disabled]="!readRecommendationsAndNotes || orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'"
						(click)=" proformaModal.show()">
						{{proformaCompleted ? 'Edit Proforma' : 'Fill Proforma'}}
						<span *ngIf="proformaCompleted || orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'">
							&nbsp;&nbsp;
							<img src="/assets/vectors/success.svg" alt="" width="20">
						</span>
					</button>
				</div>

				<div class="decision-container"
					*ngIf="(proformaCompleted && readRecommendationsAndNotes) || orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'">

					<h5 *ngIf="!orderItem.isRepeatOrder">Select Specialist</h5>
					<select *ngIf="!orderItem.isRepeatOrder" class="form-control specialist-dropdown" [ngModel]="SelectedSpecialist" (ngModelChange)="handleSpecialistChange($event)" name="versionControl">
						<option *ngFor="let item of specialist;" [ngValue]="item">{{ item }}</option>
					</select>

					<h5>Decision</h5>
					<input required type="radio" [(ngModel)]="approved" [value]="true" required name="decision"
						id="decision-approve" [disabled]="orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'" />
					<label class="radio-label" for="decision-approve">Approve</label>
					<input required type="radio" [(ngModel)]="approved" [value]="false" required name="decision"
						id="decision-reject" [disabled]="orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'" />
					<label class="radio-label" for="decision-reject">Reject</label>

					<div style="margin-top: 1rem;" *ngIf="approved === false">
						<label>Reason for rejection?</label>
						<textarea name="rejectReason" [(ngModel)]="rejectReason" class="form-control"
							[disabled]="orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'"></textarea>
					</div>
				</div>
				<div *ngIf="approved === true" style="margin-top: 1rem;">
					<button class="btn btn-default" (click)="openPrescriptionModal()"
						[disabled]="orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'">
						{{orderItem.status !== 'AWAITING_MDT_SESSION_COMPLETION' && recommendationAdded
						? 'Edit Recommendations' : 'Add Recommendations'}}
						<span *ngIf="recommendationAdded">
							&nbsp;&nbsp;
							<img src="/assets/vectors/success.svg" alt="" width="20">
						</span>
					</button>
				</div>

				<div *ngIf="recommendationAdded === true || approved === false"
					style="display: flex; justify-content: end; margin-top: 1rem;">
					<button class="btn btn-primary" (click)="handleSubmitReview()"
						[disabled]="orderItem.status === 'AWAITING_MDT_SESSION_COMPLETION'">
						Submit Review
					</button>
				</div>
			</div>

			<div *ngIf="orderItem.parentOrderItemId" class="repeat-reason">
				<p *ngIf="changePrescriptionReason" class="brand-1">
					The patient booked this consultation because they wanted a change to the prescription of a previous
					order. <br>
					Reason: <b>{{changePrescriptionReason}}</b>
				</p>
				<div style="display: flex; column-gap: 1rem;">
					<button *ngIf="repeatOrderQuestionnaire" class="btn btn-primary"
						(click)="repeatOrderNoteModal.show()">
						Repeat proforma
					</button>
					<!-- <button class="btn btn-primary" (click)="routeToOrderPreviewPage()">
						View linked order notes
					</button> -->
					<button class="btn btn-primary" (click)="routeToPatientHistory()">View Patient history</button>
				</div>
			</div>

			<div *ngIf="sessionExist" class="alert alert-{{orderItem | orderState}}"><strong>Order status:</strong>
				{{orderItem.status |
				orderStatus}}</div>
			<div class="well well--white">

				<div *ngIf="orderItem.consultationDuration === 'IN_PERSON_MINUTE_00'" class="prescriber-details-container">
					<h4><b>*This order was conducted by a pharmacist</b></h4>
					<h5><u>Pharmacy details</u></h5>
					<div class="prescriber-details">
						<p><b>Pharmacy:</b> {{orderItem.prescribingPharmacist.displayName}}</p>
						<p><b>Pharmacist:</b> {{orderItem.workflow.PRESCRIBING_PHARMACIST_NAME}}</p>
						<p><b>Pharmacy email:</b> {{orderItem.prescribingPharmacist.email}}</p>
						<p><b>Pharmacy Contact number:</b> {{orderItem.prescribingPharmacist.phone}}</p>
					</div>
				</div>

				<h3 *ngIf="orderItem.consultationDuration === 'IN_PERSON_MINUTE_00'; else regularConsultation" class="brand-1">Pharmacy Consultation Summary</h3>
				<ng-template #regularConsultation>
					<h3 class="brand-1">Triage Consultation Summary</h3>
				</ng-template>
				<br />

				<ng-container>
					<h4 class="brand-1">Patient's Consent</h4>
					<div class="patient-hsitory">
						<p>Patient has given consent to the following:</p>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They are over 21 or over and a UK resident.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have answered the online medical assessment truthfully with the knowledge that
								giving
								false information to obtain medicines could lead to a medicine being prescribed that
								is
								harmful to my health.</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>Any medicine prescribed is for their sole use.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have agreed to read the patient information leaflet that will accompany the
								medicine.
							</span>
						</div>
						<div style="display: flex; margin-bottom: 0.5rem;">
							<img src="/assets/vectors/success.svg" alt="" width="15" style="margin: 0 1rem auto 0;">
							<span>They have read and understood the terms and conditions.
							</span>
						</div>

						<br>
						<h4 class="brand-1">Forms Filled During the Consultation</h4>

						<button *ngIf="orderItem.patient.files && orderItem.patient.files.length"
							class="btn btn-default" (click)="modalService.openModal('SCRModal')" style="margin-right: 1rem;">
							Patient documents
						</button>

						<button *ngIf="!!orderItem && !!orderItem.pictures && !!orderItem.pictures.length"
							class="btn btn-default" (click)="modalService.openModal('consultationDocuments')" style="margin-right: 1rem;">
							Consultation documents
						</button>

						<button *ngIf="!!orderItem && !!patientComplaint"
							class="btn btn-default" (click)="modalService.openModal('consultationNotes')" style="margin-right: 1rem;">
							Consultation notes
						</button>

						<button *ngIf="auditFormValues" class="btn btn-default" (click)="auditModal.show()"
							style="margin-right: 1rem;">
							AUDIT form
						</button>

						<button *ngIf="gadFormValues" class="btn btn-default" (click)="gadModal.show()"
							style="margin-right: 1rem;">
							GAD form
						</button>

            <button *ngIf="sideEffectFormValues" class="btn btn-default" (click)="sideEffectModal.show()" style="margin-right: 1rem;">
              Side Effect form
            </button>

						<button *ngIf="pegFormValues" class="btn btn-default" (click)="pegModal.show()">
							PEG form
						</button>
					</div>
				</ng-container>
				<br>

				<app-gp-decisions [orderNotes]="orderItem.orderNotes"></app-gp-decisions>

				<ng-container *ngIf="gpRecommendation !== undefined">
					<h4 class="brand-1">GP Recommendations</h4>
					<div class="recommendations">
						<table class="table">
							<tbody>
								<tr>
									<th>
										<p>Type</p>
									</th>
									<th>{{gpRecommendation.medicationType}}</th>
								</tr>
								<tr>
									<th colspan="2">
										<p>GP recommendations</p>
									</th>
								</tr>
								<tr>
									<td>Category</td>
									<td>{{gpRecommendation.category}}</td>
								</tr>
								<tr>
									<td>Route of admin</td>
									<td>{{gpRecommendation.route}}</td>
								</tr>
								<tr>
									<th colspan="2">
										<p>Patient preferences</p>
									</th>
								</tr>
								<tr>
									<td>Brand</td>
									<td>{{gpRecommendation.brand}}</td>
								</tr>
								<tr>
									<td>Strain</td>
									<td>{{gpRecommendation.strain}}</td>
								</tr>
								<tr>
									<td>Manufacturer</td>
									<td>{{gpRecommendation.manufacturer}}</td>
								</tr>
								<tr>
									<th colspan="2">
										<p>Additional comments</p>
									</th>
								</tr>
								<tr>
									<td colspan="2">
										<p>{{gpRecommendation.comments}}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</ng-container>

				<h4 *ngIf="!!gpNote" class="brand-1">Consultation Notes</h4>
				<ng-container *ngIf="!!gpNote" [ngSwitch]="orderItem.consultationDuration">
					<app-order-notes *ngSwitchCase="'MINUTES_30'" [orderstatus]="orderItem.status" [orderId]="orderItem.id"></app-order-notes>
					<p *ngSwitchCase="'MINUTES_15'">{{gpNote?.doctorComments}}</p>
					<app-pharmacy-conultation-note *ngSwitchCase="'IN_PERSON_MINUTE_00'" [gpNote]="gpNote"></app-pharmacy-conultation-note>
				</ng-container>

				<br />
			</div>
		</div>
	</div>
	<app-chat-widget *ngIf="orderItem !== undefined" [orderId]="orderItem.id"></app-chat-widget>
</div>

<app-admin-key-alert-modal *ngIf="!!patient" [patient]="patient"></app-admin-key-alert-modal>

<div bsModal #prescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<app-prescription-builder *ngIf="!initLoading" [gpRecommendation]="gpRecommendation"
		(hide)="prescriptionModal.hide()" (sendPrescription)="handleUpdateRecommendation($event)">
	</app-prescription-builder>
</div>

<div bsModal #proformaModal="bs-modal" class="modal fade proforma-container" tabindex="-1" role="dialog"
	aria-hidden="true">
	<div class="modal-dialog modal-lg modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="proformaModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h3 class="modal-title">Proforma</h3>
			</div>
			<div class="modal-body">
				<form *ngIf="!initLoading" #proformaForm="ngForm" (ngSubmit)="handleSubmitProforma(proformaForm)"
					novalidate autocomplete="off">
					<div class="proforma-questions">
						<ng-container *ngIf="!!patient">
							<h4 class="brand-1">Patient details</h4>
							<div class="section">
								<h5 class="section-content">Patient name</h5>
								<p>{{patient.displayName}}</p>
							</div>
							<div class="section">
								<h5 class="section-content">Treat it patient ID number</h5>
								<p>{{patient.id}}</p>
							</div>
							<div class="section">
								<h5 class="section-content">NHS number</h5>
								<p>{{patient.nhsNumber}}</p>
							</div>
							<div class="section">
								<h5 class="section-content">Date of birth</h5>
								<p>{{patient.dateOfBirth}}</p>
							</div>
							<div class="section">
								<h5 class="section-content">Address</h5>
								<p>
									{{patient.address.line1}}
									<span *ngIf="patient.address.line1">,</span>
									{{patient.address.line2}}
									<span *ngIf="patient.address.line2">,</span>
									{{patient.address.city}}
									<span *ngIf="patient.address.city">,</span>
									{{patient.address.county}}
									<span *ngIf="patient.address.county">,</span>
									{{patient.address.postcode}}
								</p>
							</div>
						</ng-container>

						<h4 class="brand-1">Governance check</h4>
						<div class="section">
							<h5 class="section-content">Has the patient completed an eligibility questionnaire?</h5>
							<div class="section-content" [style.display]="'flex'" [style.margin]="'auto 0'">
								<div class="radio-container">
									<input required type="radio" [(ngModel)]="gc_q_1" [value]="true" [checked]="gc_q_1"
										name="gc_q_1" id="gc_q_1-true" />
									<label class="radio-label" for="gc_q_1-true">Yes</label>
								</div>
								<div class="radio-container left">
									<input required type="radio" [(ngModel)]="gc_q_1" [value]="false" [checked]="gc_q_1"
										name="gc_q_1" id="gc_q_1-false" />
									<label class="radio-label" for="gc_q_1-false">No</label>
								</div>
							</div>
						</div>
						<div class="section">
							<h5 class="section-content">Has the patient satisfied eligibility assessment?</h5>
							<div class="section-content" [style.display]="'flex'" [style.margin]="'auto 0'">
								<div class="radio-container">
									<input required type="radio" [(ngModel)]="gc_q_2" [value]="true" [checked]="gc_q_2"
										name="gc_q_2" id="gc_q_2-true" />
									<label class="radio-label" for="gc_q_2-true">Yes</label>
								</div>
								<div class="radio-container left">
									<input required type="radio" [(ngModel)]="gc_q_2" [value]="false" [checked]="gc_q_2"
										name="gc_q_2" id="gc_q_2-false" />
									<label class="radio-label" for="gc_q_2-false">No</label>
								</div>
							</div>
						</div>
						<div class="section">
							<h5 class="section-content">Has the patient had a clinical review?</h5>
							<div class="section-content" [style.display]="'flex'" [style.margin]="'auto 0'">
								<div class="radio-container">
									<input required type="radio" [(ngModel)]="gc_q_3" [value]="true" [checked]="gc_q_3"
										name="gc_q_3" id="gc_q_3-true" />
									<label class="radio-label" for="gc_q_3-true">Yes</label>
								</div>
								<div class="radio-container left">
									<input required type="radio" [(ngModel)]="gc_q_3" [value]="false" [checked]="gc_q_3"
										name="gc_q_3" id="gc_q_3-false" />
									<label class="radio-label" for="gc_q_3-false">No</label>
								</div>
							</div>
						</div>
						<div class="section">
							<h5 class="section-content">Has the patient been deemed appropriate for prescription of
								medicinal cannabis?</h5>
							<div class="section-content" [style.display]="'flex'" [style.margin]="'auto 0'">
								<div class="radio-container">
									<input required type="radio" [(ngModel)]="gc_q_4" [value]="true" [checked]="gc_q_4"
										name="gc_q_4" id="gc_q_4-true" />
									<label class="radio-label" for="gc_q_4-true">Yes</label>
								</div>
								<div class="radio-container left">
									<input required type="radio" [(ngModel)]="gc_q_4" [value]="false" [checked]="gc_q_4"
										name="gc_q_4" id="gc_q_4-false" />
									<label class="radio-label" for="gc_q_4-false">No</label>
								</div>
							</div>
						</div>

						<h4 class="brand-1">Clinical assessment of case</h4>
						<div class="section">
							<h5 class="section-content">What is the condition being treated *</h5>
							<input type="text" name="ca_q_1" [(ngModel)]="ca_q_1" class="form-control section-content">
						</div>
						<div class="section">
							<h5 class="section-content">Is medicinal cannabis an appropriate treatment for this
								condition *</h5>

							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_2" [value]="true"
											[checked]="ca_q_2" name="ca_q_2" id="ca_q_2-true" />
										<label class="radio-label" for="ca_q_2-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_2" [value]="false"
											[checked]="ca_q_2" name="ca_q_2" id="ca_q_2-false" />
										<label class="radio-label" for="ca_q_2-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_2 === false">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true" class="section">
							<h5 class="section-content">Brief summary of patient case *</h5>
							<textarea name="ca_q_3" [(ngModel)]="ca_q_3"
								class="form-control section-content"></textarea>
						</div>
						<div *ngIf="ca_q_2 === true" class="section">
							<h5 class="section-content">Are there any contra-indications (clinical or pharmacological)
								identified by the MDT
								from case note assessment for medicinal cannabis for this patient?</h5>
							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_4" [value]="true"
											[checked]="ca_q_4" name="ca_q_4" id="ca_q_4-true" />
										<label class="radio-label" for="ca_q_4-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_4" [value]="false"
											[checked]="ca_q_4" name="ca_q_4" id="ca_q_4-false" />
										<label class="radio-label" for="ca_q_4-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_4 === true">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true && ca_q_4 === false" class="section">
							<h5 class="section-content">Has a psychological assessment been performed as per protocol
								via the traffic light
								system?</h5>
							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_5" [value]="true"
											[checked]="ca_q_5" name="ca_q_5" id="ca_q_5-true" />
										<label class="radio-label" for="ca_q_5-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_5" [value]="false"
											[checked]="ca_q_5" name="ca_q_5" id="ca_q_5-false" />
										<label class="radio-label" for="ca_q_5-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_5 === false">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true && ca_q_4 === false && ca_q_5 === true" class="section">
							<h5 class="section-content">Does the patient meet the criteria as per protocol for unmet
								clinical need?</h5>
							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_6" [value]="true"
											[checked]="ca_q_6" name="ca_q_6" id="ca_q_6-true" />
										<label class="radio-label" for="ca_q_6-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_6" [value]="false"
											[checked]="ca_q_6" name="ca_q_6" id="ca_q_6-false" />
										<label class="radio-label" for="ca_q_6-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_6 === false">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true && ca_q_4 === false && ca_q_5 === true && ca_q_6 === true"
							class="section">
							<h5 class="section-content">Is the initial dose, configuration, and delivery appropriate for
								this patient?</h5>
							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_7" [value]="true"
											[checked]="ca_q_7" name="ca_q_7" id="ca_q_7-true" />
										<label class="radio-label" for="ca_q_7-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_7" [value]="false"
											[checked]="ca_q_7" name="ca_q_7" id="ca_q_7-false" />
										<label class="radio-label" for="ca_q_7-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_7 === false">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true && ca_q_4 === false && ca_q_5 === true && ca_q_6 === true && ca_q_7 === true"
							class="section">
							<h5 class="section-content">Is this patient already on medicinal cannabis?</h5>
							<div class="section-content" [style.display]="'flex'" [style.margin]="'auto 0'">
								<div class="radio-container">
									<input required type="radio" [(ngModel)]="ca_q_8" [value]="true" [checked]="ca_q_8"
										name="ca_q_8" id="ca_q_8-true" />
									<label class="radio-label" for="ca_q_8-true">Yes</label>
								</div>
								<div class="radio-container left">
									<input required type="radio" [(ngModel)]="ca_q_8" [value]="false" [checked]="ca_q_8"
										name="ca_q_8" id="ca_q_8-false" />
									<label class="radio-label" for="ca_q_8-false">No</label>
								</div>
							</div>
						</div>
						<div *ngIf="ca_q_2 === true && ca_q_4 === false && ca_q_5 === true && ca_q_6 === true && ca_q_7 === true && ca_q_8 === true"
							class="section">
							<h5 class="section-content">Is the MDT satisfied with the dose adjustment plan or plan to
								continue at same doses?
							</h5>
							<div class="section-content" [style.display]="'flex'" [style.flex-direction]="'column'">
								<div [style.display]="'flex'" [style.margin]="'auto 0'">
									<div class="radio-container">
										<input required type="radio" [(ngModel)]="ca_q_9" [value]="true"
											[checked]="ca_q_9" name="ca_q_9" id="ca_q_9-true" />
										<label class="radio-label" for="ca_q_9-true">Yes</label>
									</div>
									<div class="radio-container left">
										<input required type="radio" [(ngModel)]="ca_q_9" [value]="false"
											[checked]="ca_q_9" name="ca_q_9" id="ca_q_9-false" />
										<label class="radio-label" for="ca_q_9-false">No</label>
									</div>
								</div>
								<div *ngIf="ca_q_9 === false">
									<label>Reason for disapproval?</label>
									<textarea name="reason" [(ngModel)]="reason" class="form-control"></textarea>
								</div>
							</div>
						</div>
						<div *ngIf="(ca_q_2 === true && ca_q_4 === false && ca_q_5 === true && ca_q_6 === true && ca_q_7 === true && ca_q_8 === false) || (ca_q_2 === true && ca_q_4 === false && ca_q_5 === true && ca_q_6 === true && ca_q_7 === true && ca_q_8 === true && ca_q_9 === true)"
							class="section">
							<h5 class="section-content">MDT Discussion</h5>
							<textarea name="ca_q_10" [(ngModel)]="ca_q_10" class="form-control section-content">
								</textarea>
						</div>

						<div>
							<h4 class="brand-1">Finalize</h4>
							<div>
								<input type="checkbox" [(ngModel)]="signOff" id="signOff" name="signOff"
									[disabled]="!(reason != '' || ca_q_10 != '')" />
								<label class="radio-label" for="signOff">Sign off</label>
							</div>

							<div *ngIf="signOff">
								<img [src]="orderItem.doctor.settings.SIGNATURE_URL" width="150"
									style="border: 1px solid gray;">

								<br />
								<br />
							</div>

							<button type="submit" class="btn btn-primary">
								Submit
							</button>
						</div>
					</div>

				</form>
			</div>
		</div>
	</div>
</div>

<app-patient-scr-documents-modal *ngIf="orderItem" [order]="orderItem"></app-patient-scr-documents-modal>

<app-consultation-documents-modal *ngIf="!!orderItem && !!orderItem.pictures && !!orderItem.pictures.length" [documents]="orderItem.pictures"></app-consultation-documents-modal>
<app-consultation-notes-modal *ngIf="!!orderItem && !!patientComplaint" [notes]="patientComplaint"></app-consultation-notes-modal>

<div bsModal #repeatOrderNoteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="repeatOrderNoteModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">Repeat Order Note</h4>
			</div>
			<div class="modal-body">
				<app-repeat-order-note *ngIf="repeatOrderQuestionnaire" [note]="repeatOrderQuestionnaire">
				</app-repeat-order-note>
			</div>
		</div>
	</div>
</div>

<div bsModal #auditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="orderItem?.status !== 'AWAITING_MDT_SESSION_COMPLETION' && auditFormValues"
		class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="auditModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">AUDIT Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Score: {{auditFormValues.note.score}}</div>
				<div *ngFor="let item of auditForm | keyvalue" class="patient-response">
					<h4>{{item.value.label}}</h4>
					<span>{{item.value.values[auditFormValues.note[item.key]]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #pegModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="orderItem?.status !== 'AWAITING_MDT_SESSION_COMPLETION' && !!pegFormValues && !!pegFormValues.note" class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="pegModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">PEG Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Average: {{!!pegFormValues.note.average ? getPEGAverage(pegFormValues.note.average) : ''}}</div>
				<div *ngFor="let item of pegForm | keyvalue" class="patient-response">
					<h4>{{item.value}}</h4>
					<span>{{pegFormValues.note[item.key]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div bsModal #sideEffectModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div *ngIf="orderItem?.status !== 'AWAITING_MDT_SESSION_COMPLETION' && sideEffectFormValues && sideEffectFormValues.note" class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="sideEffectModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Side Effect Feedback</h4>
      </div>
      <div class="modal-body">
        <div class="note-score">Total: {{sideEffectFormValues.note.average}}</div>
        <div *ngFor="let item of sideEffectForm | keyvalue" class="patient-response">
          <h4>{{item.value}}</h4>
          <span>{{sideEffectFormValues.note[item.key]}}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #gadModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div *ngIf="orderItem?.status !== 'AWAITING_MDT_SESSION_COMPLETION' && gadFormValues" class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="gadModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">GAD Feedback</h4>
			</div>
			<div class="modal-body">
				<div class="note-score">Score: {{gadFormValues.note.score}}</div>
				<div *ngFor="let item of gadForm | keyvalue" class="patient-response">
					<h4>{{item.value.label}}</h4>
					<span>{{item.value.values[gadFormValues.note[item.key]]}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
