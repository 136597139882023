import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { UserService } from './user.service';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss'],
})
export class ChangePinComponent implements OnInit {
  success: boolean;
  pinForm: UntypedFormGroup;
  isProcessing = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.pinForm = this.fb.group({
      currentPin: ['', [Validators.required]],
      newPin: ['', [Validators.required, this.validatePin]],
      pinConfirmation: ['', [Validators.required]],
    }, { validators: [pinCrossValidator, currentAndNewPinValidator] });
  }

  validatePin(field: UntypedFormControl): ValidationErrors | null {
    if (field.value?.length !== 4) {
      return { fieldError: 'Password must be exactly 4 digits long.' };
    }
    if ((/\D/.test(field.value))) { // any non-digits
      return { fieldError: 'Password must contain only numbers.' };
    }
    return null;
  }

  submit(): void {
    const { currentPin, newPin } = this.pinForm.value;
    this.userService.changePin(currentPin, newPin)
      .pipe(finalize(() => {
        this.isProcessing = false;
      }))
      .subscribe(
        (response) => {
          this.success = true;
          this.toastr.success('Pin changed successfully', 'PIN Changed');
          this.router.navigateByUrl('/');
        },
        (error) => {
          this.toastr.error(this.getErrorMessage(error), 'Could Not Change PIN');
        });
  }

  getErrorMessage(error: any): string {
    return error.error?.message || error.message || 'An unexpected error occurred.';
  }
}

/** Ensure that new pin and confirm pin fields are equal */
const pinCrossValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const newPin = formGroup.get('newPin').value;
  const pinConfirmation = formGroup.get('pinConfirmation').value;
  return newPin === pinConfirmation ? null : { error: 'The pins do not match.' };
};

/** Ensure that old and new pins are different */
const currentAndNewPinValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const newPin = formGroup.get('newPin').value;
  const currentPin = formGroup.get('currentPin').value;
  return newPin === currentPin ? { samePinError: 'New pin can\'t be the same as the current pin.' } : null;
};
