import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  styleUrls: ['./prescription-modal.component.scss'],
})
export class PrescriptionModalComponent implements OnInit {
  @Input() prescription: any;
  @Input() additionalNotes: any;
  @Input() creationDate?: any;
  @Input() pinkPrescriptionURL: string;
  @Output() hideModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
