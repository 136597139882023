import { Component, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

@Component({
  selector: 'app-summary-care-records',
  templateUrl: './summary-care-records.component.html'
})
export class SummaryCareRecordsComponent {
  public patient = {
    firstName: '',
    lastName: '',
    gender: '',
    dateOfBirth: '',
    get displayName() {
      return this.firstName + ' ' + this.lastName;
    }
  }
  public summaryCareRecordsData: any;
  @ViewChild('summaryCareRecordsModal', { static: true }) summaryCareRecordsModal: ModalDirective;
  
  constructor(private dataService: DataService) {}

  requestSCRAccess() {
    this.summaryCareRecordsModal.config.ignoreBackdropClick = true;
    this.summaryCareRecordsModal.show();
    this.summaryCareRecordsData = { waiting: true }
    this.dataService.requestSCRAccessForThirdParty(this.patient).subscribe(response => {
        console.log(response)
        this.summaryCareRecordsData = response;
        this.summaryCareRecordsData.waiting = false;
    }, error => {
        console.log(error);
        this.summaryCareRecordsData.error = error;
        this.summaryCareRecordsData.waiting = false;
    })
  }

  copySCRDoB(dob) {
    var outputDate = moment(dob).format('DD-MMM-YYYY');
    var textField = document.createElement('textarea')
    textField.innerText = outputDate
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }
}
