import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { style, state, animate, transition, trigger } from '@angular/animations';


@Component({
    selector: 'app-security-details',
    templateUrl: './security-details.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [   // :enter is alias to 'void => *'
                style({ height: '0px', opacity: 0, overflow: 'hidden' }),
                animate(500, style({ height: '*', opacity: 1, overflow: 'hidden' })) 
            ]),
                transition(':leave', [   // :leave is alias to '* => void'
                animate(500, style({ height: '0px', opacity: 0, overflow: 'hidden' })) 
            ])
        ])
    ]
})
export class SecurityDetailsComponent implements OnInit {

    public errorMessage: string;
    doctor: any = {};

    constructor(private accountService: AccountService,
                private activatedRoute: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((param: any) => {
            this.doctor = {
                username: param['username'],
                password: '',
                pin: '',
                token: param['token'],
                passwordFocused: false,
                pinFocused: false
            };
        })
    }

    onSubmit(pin, password) {
        if (!pin.valid || !password.valid) {
            return;
        }
        this.errorMessage = '';
        this.accountService.setSecurityDetails(this.doctor).subscribe((result) => {
            this.accountService.authenticate(result.value);
            this.router.navigateByUrl('');
        }, (error) => {
            if (error.status == 404) {
                this.errorMessage = 'We could not find an account from this link, please contact a member of The Treat it Admin Team.';
            }
            if (error.status == 412) {
                this.errorMessage = 'This link is no longer valid. We have sent a new link to your email address. Please click on it to set your security details in order to login';
            }
            document.body.scrollTop = 0;
        });
    }

}
