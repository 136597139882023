import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'booleanAnswer'})
export class booleanPipe implements PipeTransform {
  transform(value: any): any {
    switch(value){
        case true: return 'Yes';
        case false: return 'No';
        case 'true': return 'Yes';
        case 'false': return 'No';
        default: return value;
    }
  }
}