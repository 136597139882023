import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'auth-quicksilva',
    templateUrl: './auth-quicksilva.component.html',
    styleUrls: ['./auth-quicksilva.component.scss']
})
export class AuthQuicksilva implements OnInit {

    private token: string;
    private user: string;
    private subject: string;
    private patient: string;
    public surgeryNotFound: boolean = false;

    constructor(private accountService: AccountService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
                this.user = params['user'];
                this.subject = params['subject'];
                if (this.user && this.subject) {
                    this.accountService.authenticateQuickSilvaDoctor(this.user, this.subject).subscribe((result) => {
                        this.token = result.token;
                        this.patient = result.patient;

                        this.accountService.authenticate(this.token);
                        
                        sessionStorage.setItem('patient-subject', JSON.stringify(this.patient));
                        sessionStorage.setItem("showPrescriptionModal", 'true');

                        this.router.navigateByUrl('/orders');
                    }, (error) => {
                        if (error.status == 404) {
                            this.surgeryNotFound = true
                        } else {
                            this.router.navigateByUrl('/login');
                        }
                    });
                } else {
                    this.router.navigateByUrl('/login');
                }
        })
       
    }
  
}