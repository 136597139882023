import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalOptions } from './app.module';
import { retry, shareReplay } from 'rxjs/operators';

export interface AppInfo {
  version: string;
  "authenticationTokenDuration(minutes)": number;
  videoTokenExpirationMinutes: number;
  minutesBeforeAppointmentUserAllowedToJoin: number;
  minutesAfterAppointmentUserAllowedToJoin: number;
  debug: boolean;
  sendEmails: boolean;
  repeatPrescriptionsExpiryDays: number;
  repeatPrescriptionsValidDays: number;
  defaultPharmacy: number;
  coopPharmacy: number;
  superdrugPharmacy: number;
  billing: BillingInfo;
}

export interface BillingInfo {
  /** stripe API key */
  publicKey: string;
  defaultFormPrice: number;
  appointmentPrice: number;
  appointmentPreAuthorization: number;
  appointmentExtensionPrice: number;
  sicknoteIssuedDuringAppointmentPrice: number;
  prescriptionIssuedDuringAppointmentPrice: number;
  maximumNumberOfTimesAnAppointmentCanBeExtended: number;
  referralLetterIssuedDuringAppointmentPrice: number;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions) { }

  public getBillingInfo(platform: string) {
    return this.http.get<BillingInfo>(`${this.options.apiEndPoint}api/public/billing`, { headers: { 'X-Origin-Platform': platform } });
  }

  public getAppInfo() {
    return this.http.get<AppInfo>(`${this.options.apiEndPoint}info`).pipe(
      retry(1),
      shareReplay(1)
    )
  }
}
