import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-patient-scr-documents-modal',
  templateUrl: './patient-scr-documents-modal.component.html',
  styleUrls: ['../shared.styles.scss'],
})
export class PatientScrDocumentsModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() order: any;
  @ViewChild('patientDocumentsModal') modal: ModalDirective;

  private modalEventSubscription: Subscription;
  protected patientFiles = {
    images: undefined,
    documents: undefined,
  };

  constructor(private modalService: ModalService, private router: Router) {}

  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((data) => {
      if (currentUrl.includes('past-orders')) {
        if (data.modalName === 'SCRModal' && data.orderId === this.order.id) {
          this.modal.show();
        }
      } else {
        if (data === 'SCRModal') {
          this.modal.show();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.order) {
      let documents = [];
      let images = [];
      for (const file of this.order.patient.files) {
        if (
          file.contentType === 'image/png' ||
          file.contentType === 'image/jpg' ||
          file.contentType === 'image/jpeg'
        ) {
          images.push(file);
          continue;
        }

        documents.push(file);
      }

      if (documents.length) {
        this.patientFiles.documents = documents;
      }

      if (images.length) {
        this.patientFiles.images = images;
      }
    }
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }
}
