<div class="login">
    <div class="central_panel">

        <div class="well well--white well-security">

            <img class="site_logo" src="/assets/img/logo.png" alt="Treat it" />

            <h1 class="brand-1">Doctor Portal</h1>
            <p>Welcome to the Treat it Doctor Portal. Please set up your security details below in order to login.</p>
            <form #securityForm="ngForm" (ngSubmit)="onSubmit(pin, password)">
                <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input id="email" name="email" type="text" required disabled [(ngModel)]="doctor.username" class="form-control" />
                </div>

                <div class="form-group">
                    <label for="password">Password</label>
                    <input #password="ngModel" id="password" name="password" type="password" required [(ngModel)]="doctor.password" class="form-control" pattern="(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z]).*$"(focus)="doctor.passwordFocused = true" (blur)="doctor.passwordFocused = false"/>
                </div>
                <div @fadeInOut *ngIf="securityForm.submitted && password.invalid && doctor.passwordFocused == false" class="alert alert-danger">
                    <strong>You password does not meet the rules</strong>
                </div>
                <div @fadeInOut *ngIf="doctor.passwordFocused" class="alert alert-warning">
                  <strong>Your password should meet the following rules:</strong>
                  <ul>
                    <li>It should contain at least 1 upper-case letter.</li>
                    <li>It should contain at least 1 number.</li>
                    <li>It should be at least 8 characters length.</li>
                  </ul>
                </div>
                
                <div class="form-group">
                    <label for="pin">Pin</label>
                    <input #pin="ngModel" id="pin" name="pin" type="password" required [(ngModel)]="doctor.pin" class="form-control" pattern="\d{4}" (focus)="doctor.pinFocused = true" (blur)="doctor.pinFocused = false"/>
                </div>
                <div @fadeInOut *ngIf="securityForm.submitted && pin.invalid && doctor.pinFocused == false" class="alert alert-danger">
                    <strong>You pin does not meet the rules</strong>
                </div>
                <div @fadeInOut *ngIf="doctor.pinFocused" class="alert alert-warning">
                  <strong>Your pin should meet the following rules:</strong>
                  <ul>
                    <li>It should only contain digits</li>
                    <li>It should be at least 4 digits long.</li>
                  </ul>
                </div>

                <p>
                    <button class="btn btn-primary" type="submit">Submit</button>
                </p>
            </form>
        </div>
    </div>
</div>