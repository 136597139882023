import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-doctor-appointments',
    templateUrl: './appointments.component.html'
})
export class AppointmentsComponent implements OnInit {

    public newAppointment: any = {
        day: moment().format('YYYY-MM-DD'),
        hour: 12,
        minute: 0
    };

    public status: string;
    public allAppointments: any[];
    public weeklyAppointments: any[];
    public startDate: Date;
    public endDate: Date;
    public displayWeek: string;
    public currentAppointment: any;
    public cancelCharge: boolean;
    public cancelReason: string;
    public cancelReasonOption: any;
    public isLoading: boolean;

    protected rootAppointmentIDs = []

    public appointmentsVisibility = {
        0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false
    };

    private datetimecomparator = (a: any, b: any) => moment(a.datetime).isBefore(moment(b.datetime)) ? -1 : moment(a.datetime).isAfter(moment(b.datetime)) ? 1 : 0;
    private distinct = (value: any, index: number, self: any[]) => self.indexOf(value) === index;

    constructor(
        private dataService: DataService,
        private toastr: ToastrService) { }

    previousWeek() {
        this.initialiseVisibilityOfDaysAppointments();
        this.setWeek(moment(this.startDate).subtract(1, 'week').toDate());
    }

    nextWeek() {
        this.initialiseVisibilityOfDaysAppointments();
        this.setWeek(moment(this.startDate).add(1, 'week').toDate());
    }

    async setWeek(start: Date) {
        const end = moment(start).add(1, 'week').toDate();
        this.startDate = start;
        this.endDate = end;
        this.displayWeek = `${moment(this.startDate).format('MMM Do')} - ${moment(this.endDate).format('MMM Do')}`;
        await this.refreshAppointments();
        // weekly appointments
        const weeklyAppointments = this.allAppointments.filter(a => {
            if (a.status === 'LINKED') {
                this.rootAppointmentIDs.push(a.rootAppointment);
            }
            return moment(a.datetime).startOf('day').isSameOrAfter(moment(start)) && moment(a.datetime).startOf('day').isBefore(moment(end)) && (a.status !== 'LINKED' || a.status !== 'LINKED')
        });
        const keys = weeklyAppointments.map(a => moment(a.datetime).startOf('day').toISOString()).filter(this.distinct);
        this.weeklyAppointments = keys.map(k => {
            return {
                date: moment(k).toDate(),
                displayDate: moment(k).format('YYYY-MM-DD'),
                appointments: weeklyAppointments.filter(a => moment(a.datetime).startOf('day').isSame(moment(k)))
            };
        });
    }

    addAppointment() {
        let date = this.getNewAppointmentDate();
        this.dataService.addAppointment(date.toDate()).subscribe(
            (result) => {
                //console.log(result);
                this.toastr.success(date.format('dddd, MMMM Do YYYY, h:mm:ss a'), 'Appointment Slot Added');
                this.refreshAppointments();
            },
            (error) => {
                //console.log(error);
                this.toastr.error(this.getErrorMessage(error), 'Could Not Add Appointment Slot');
            });
    }

    async refreshAppointments() {
        this.isLoading = true;
        this.allAppointments = []
        const appointments$ = this.dataService.getAppointments(this.startDate, moment(this.startDate).add(1, 'week').toDate());
        const appointments = await lastValueFrom(appointments$);
        this.allAppointments = appointments.sort(this.datetimecomparator);
        this.isLoading = false;
    }

    cancelAppointment(appointment) {
        if (this.currentAppointment.orderItemId) {
            let cancelOptionAndReason = this.cancelReasonOption + ' - ' + this.cancelReason;
            this.dataService.cancelAppointment(appointment.id, cancelOptionAndReason, this.cancelCharge).subscribe(
                (result) => {
                    this.toastr.success('Appointment cancelled successfully', 'Appointment Cancelled');
                    this.refreshAppointments();
                },
                (error) => {
                    this.toastr.error(this.getErrorMessage(error), 'Could Not Cancel Appointment');
                });
        } else {
            this.dataService.cancelAppointment(appointment.id).subscribe(
                (result) => {
                    this.toastr.success('Appointment cancelled successfully', 'Appointment Cancelled');
                    this.refreshAppointments();
                },
                (error) => {
                    this.toastr.error(this.getErrorMessage(error), 'Could Not Cancel Appointment');
                });
        }
        delete this.cancelReasonOption;
        delete this.cancelReason;
        delete this.cancelCharge;
    }

    ngOnInit() {
        this.setWeek(moment().startOf('week').toDate());
    }

    canAddAppointment() {
        let date = this.getNewAppointmentDate();
        if (date.isBefore(moment())) {
            return false;
        }
        if (!this.allAppointments) {
            return false;
        }
        return this.allAppointments.every(a => !date.isSame(moment(a.datetime)));
    }

    private getNewAppointmentDate() {
        return moment(this.newAppointment.day).hours(this.newAppointment.hour).minutes(this.newAppointment.minute);
    }

    initialiseVisibilityOfDaysAppointments() {
        this.appointmentsVisibility = {
            0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false
        };
    }

    toggleVisibilityOfDaysAppointments(dayIndex) {
        this.appointmentsVisibility[dayIndex] = !this.appointmentsVisibility[dayIndex];
    }

    getErrorMessage(error: any) {
        return error.error?.message || error.message || "An unexpected error occurred";
    }

}
