<div class="container">
	<h1 class="brand-1">Video Consultations</h1>

	<!--<p class="alert alert-warning">
        <i class="fa fa-warning"></i> <strong>Please note:</strong> You have reserved an assessment form. If you do not complete
        this form within 10 minutes this will be made available to other doctors
    </p>-->

	<div class="new-appointment">
		<div class="well well--white">
			<form #addAppointmentForm="ngForm" (ngSubmit)="addAppointment()" class="form-inline">
				<h4>Add an available time to your schedule for video consultations</h4>
				<div class="form-group">
					<input class="form-control" type="date" placeholder="yyyy-mm-dd" [(ngModel)]="newAppointment.day" required name="newAppointment.day">
				</div>
				<div class="form-group">
					<app-time-picker [(selectedHour)]="newAppointment.hour" [(selectedMin)]="newAppointment.minute" [intervalMinutes]="15"></app-time-picker>
				</div>
				<button class="btn btn-primary" type="submit" [disabled]="addAppointmentForm.invalid || !canAddAppointment()">Add Appointment Slot</button>
			</form>
		</div>
	</div>
	<div class="row">
		<div class="col col-md-8">
			<ul class="nav nav-tabs">
				<li [ngClass]="{active: !status}" (click)="status = ''" class="active"><a href="javascript:void(0)">All slots</a></li>
				<li [ngClass]="{active: status === 'BOOKED'}" (click)="status = 'BOOKED'"><a href="javascript:void(0)">Booked</a></li>
				<li [ngClass]="{active: status === 'SCHEDULED'}" (click)="status = 'SCHEDULED'"><a href="javascript:void(0)">Available</a></li>
				<li [ngClass]="{active: status === 'APPOINTMENT_COMPLETED'}" (click)="status = 'APPOINTMENT_COMPLETED'"><a href="javascript:void(0)">Complete</a></li>
				<li [ngClass]="{active: status === 'CANCELED_BY_DOCTOR'}" (click)="status = 'CANCELED_BY_DOCTOR'"><a href="javascript:void(0)">Cancelled</a></li>
			</ul>
		</div>
		<div class="col col-md-4">
			<div class="pull-right appointment_date_range"><span class="h4">{{displayWeek}}</span> &nbsp;
				<button class="btn btn-default" (click)="previousWeek()"><i class="fa fa-chevron-left"></i></button>
				<button class="btn btn-default" (click)="nextWeek()"><i class="fa fa-chevron-right"></i></button>
			</div>
		</div>
	</div>
	<div *ngIf="isLoading" class="text-center" style="padding: 2rem;">
		<div>Please wait...</div>
		<i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
	</div>
	<!--TO DO: Functionality for this -->
	<div *ngIf="!isLoading && weeklyAppointments">
		<div style="margin-top: 5px;" *ngFor="let day of weeklyAppointments; index as i;">
			<table class="table table-striped table-hover" width="100%" *ngIf="(day.appointments | filterByAppointmentStatus : status).length">
				<thead>
					<tr class="appointment_date_container_row" style="cursor: pointer;" (click)="toggleVisibilityOfDaysAppointments(i)">
						<th colspan="6" class="appointment_date" >
							<div style="width: 100%; display: flex; justify-content: space-between;">
								<h4>{{day.displayDate}}</h4>
								<div style="display: flex; justify-content: center; align-items: center;">
									<span>{{appointmentsVisibility[i] ? '&#9650;' : '&#9660;'}}</span>
								</div>
							</div>
						</th>
					</tr>
				</thead>
				<thead *ngIf="appointmentsVisibility[i]">
					<tr>
						<th><strong>Date</strong></th>
						<th style="text-align: center;"><strong>Status</strong></th>
						<th style="text-align: center;"><strong>App</strong></th>
						<th style="text-align: center;"><strong>Consultation Type</strong></th>
						<th><strong>Patient</strong></th>
						<th style="text-align: center; padding-right: 1em;"><strong>Action</strong></th>
					</tr>
				</thead>
				<tbody *ngIf="appointmentsVisibility[i]">
					<tr *ngFor="let item of (day.appointments | filterByAppointmentStatus : status)">
						<td width="15%">{{item.datetime | date: 'MMM d, y, h:mm a' }}</td>
						<td width="15%" [ngSwitch]="item.status">
							<span *ngSwitchCase="'BOOKED'" class="alert alert-info alert-small">Booked</span>
							<span *ngSwitchCase="'APPOINTMENT_COMPLETED'" class="alert alert-success alert-small">Completed</span>
							<span *ngSwitchCase="'CANCELED_BY_DOCTOR'" class="alert alert-danger alert-small">Cancelled</span>
							<span *ngSwitchCase="'PATIENT_ABSENT'" class="alert alert-danger alert-small">Cancelled</span>
							<span *ngSwitchCase="'CANCELED_BY_ADMIN'" class="alert alert-danger alert-small">Cancelled</span>
							<span *ngSwitchCase="'SCHEDULED'" class="alert alert-available alert-small">Free</span>
							<span *ngSwitchCase="'GPS_BOOKING'" class="alert alert-available alert-small">Booked</span>
							<span *ngSwitchDefault>{{item.status}}</span>
						</td>
						<td width="15%" [ngSwitch]="item.bookingPlatform">
							<span *ngSwitchCase="'GPS'" class="alert alert-info alert-small">GPS</span>
							<span *ngSwitchCase="null" class="alert alert-success alert-small">Treat it</span>
							<span *ngSwitchDefault class="alert alert-success alert-small">Treat it</span>
						</td>
						<td width="20%">
							<span *ngIf="item.status === 'SCHEDULED'; else hasInfo" class="alert alert-info alert-small">
								Regular
							</span>
							<ng-template #hasInfo>
								<span *ngIf="item.parentOrderItemId; else regularOrder" class="alert alert-info alert-small">Repeat - 15 minutes</span>
								<ng-template #regularOrder>
									<span class="alert alert-info alert-small">
										Initial <span *ngIf="rootAppointmentIDs.includes(item.id)">- 30 minutes</span>
									</span>
								</ng-template>
							</ng-template>
						</td>
						<td width="15%" style="vertical-align: middle;">
							<span *ngIf="item.patient">{{item.patient.displayName}}</span>
						</td>
						<td width="20%" align="right">
							<a *ngIf="item.status === 'APPOINTMENT_COMPLETED' || item.status === 'APPOINTMENT_CANCELLED'" class="btn btn-default" routerLink="/patient/{{item.patient.id}}">View Patient</a>
							<a *ngIf="item.status === 'BOOKED'" class="btn btn-primary" routerLink="/appointments/{{item.orderItemId}}">Join Consultation</a>
							<button *ngIf="item.status !== 'APPOINTMENT_COMPLETED' && item.status !== 'CANCELED_BY_DOCTOR' && item.status !== 'PATIENT_ABSENT'" class="btn btn-default" (click)="deleteModal.show(); currentAppointment=item;"><i class="fa fa-times"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="!(allAppointments | filterByAppointmentStatus: status).length" class="text-center" style="padding: 2rem;">
			<span>(No {{status | appointmentStatus}} Video Consultations)</span>
		</div>
	</div>
	<div bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" (click)="deleteModal.hide()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<h4 class="modal-title">Video Consultations</h4>
				</div>
				<div class="modal-body">
					<div *ngIf="currentAppointment && !currentAppointment.orderItemId">
						<p>Are you sure do you want to delete this appointment?</p>
						<button type="button" class="btn btn-default" (click)="deleteModal.hide()">Cancel</button>
						<button type="button" class="btn btn-primary" (click)="cancelAppointment(currentAppointment);deleteModal.hide();">Confirm</button>
					</div>
					<div *ngIf="currentAppointment && currentAppointment.orderItemId">
						<form #cancelForm="ngForm" autocomplete="off" novalidate>
							<div class="form-group">
								<label><strong>Please specify the reason why you would like to cancel this consultation:</strong></label>
								<div class="consultation-cancellation">
									<p>
										<input type="radio" value="Technical issues" id="techissues" name="cancelReasonOption" [(ngModel)]="cancelReasonOption" required>
										<label for="techissues">Technical issues experienced during the consultation</label>
									</p>
									<p>
										<input type="radio" value="Patient missed the consultation" id="nocustomer" name="cancelReasonOption" [(ngModel)]="cancelReasonOption" required>
										<label for="nocustomer">The patient missed the consultation</label>
									</p>
									<p>
										<input type="radio" value="Patient did not provide identification" id="nocustomerid" name="cancelReasonOption" [(ngModel)]="cancelReasonOption" required>
										<label for="nocustomerid">The patient did not provide Identification</label>
									</p>
								</div>
							</div>
							<div class="form-group">
								<label>Should the patient be charged for this consultation?</label>
								<div class="consultation-cancellation">
									<p>
										<input type="radio" [value]="true" id="cancelCharge" name="cancelCharge" [(ngModel)]="cancelCharge" required>
										<label for="cancelCharge">Yes</label>
									</p>
									<p>
										<input type="radio" [value]="false" id="cancelCharge" name="cancelCharge" [(ngModel)]="cancelCharge" required>
										<label for="cancelCharge">No</label>
									</p>
								</div>
							</div>
							<div class="form-group">
								<label for="cancelReason">Reason for cancellation</label>
								<textarea id="cancelReason" class="form-control" name="cancelReason" required [(ngModel)]="cancelReason"></textarea>
							</div>
							<button class="btn btn-primary" [disabled]="cancelForm.form.invalid" (click)="deleteModal.hide(); cancelAppointment(currentAppointment);">Ok</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
