import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PrescriptionService } from '../prescription.service';

@Component({
  selector: 'app-formulary-view',
  templateUrl: './formulary-view.component.html',
  styleUrls: ['./formulary-view.component.scss'],
})
export class FormularyViewComponent implements OnInit {
  protected formularies: any[];

  constructor(private prescriptionService: PrescriptionService) {}

  ngOnInit(): void {
    if (this.prescriptionService.prescriptions === undefined) {
      this.loadPrescriptions();
    } else {
      this.formularies = this.prescriptionService.prescriptions;
    }
  }

  async loadPrescriptions() {
    await this.prescriptionService.cachePrescriptions();
    this.formularies = this.prescriptionService.prescriptions;
  }

  handleFilterChange($event) {
    switch ($event.target.value) {
      case 'pharmacy':
        this.formularies.sort(
          (firstItem, secondItem) =>
            firstItem.pharmacyId - secondItem.pharmacyId
        );
        break;

      case 'route':
        this.formularies.sort((firstItem, secondItem) => {
          const nameA = firstItem.administrationRoute.toUpperCase();
          const nameB = secondItem.administrationRoute.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;

      case 'category':
        this.formularies.sort((firstItem, secondItem) => {
          const nameA = firstItem.category.toUpperCase();
          const nameB = secondItem.category.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;

      default:
        break;
    }
  }

  getPharmacyName(id: number) {
    const pharmacy = this.prescriptionService.pharmacies.find(
      (item) => item.id === id
    );
    if (pharmacy) {
      return pharmacy.displayName;
    }

    return '-';
  }
}
