import { Component, OnInit, Input } from '@angular/core';
import deepClone  from 'lodash/cloneDeep';
import { FormService } from './form.service';

@Component({
    selector: 'app-assessment-form',
    templateUrl: './assessment-form.component.html'
})
export class AssessmentFormComponent implements OnInit {
    @Input() orderItem: any;
    public form: [string, string, string][];
    blockerAnswers: any [];
    patientGaveConsentForSCR: boolean = false;
    formAnswers = []

    constructor(private formService: FormService) { }

    ngOnInit() {
        this.form = this.formService.parse(this.orderItem.form);
        this.blockerAnswers = this.formService.blockerAnswers.filter(x => x.formId == this.orderItem.workflow.FORM_ID);
        this.checkPatientSCRConsent();
        let formAnswers = deepClone(this.orderItem.formAnswers);
        formAnswers.sort((formAnswerOne, formAnswerTwo) => {
            return formAnswerOne.formQuestion.questionOrder - formAnswerTwo.formQuestion.questionOrder;
        });
        formAnswers = formAnswers.map((formAnswer) => {
            // Handle calculation questions
            if (formAnswer.formQuestion.optionType === "CALCULATION") {
                const { formQuestion } = formAnswer;
                let { formAnswersOptions } = formAnswer;
                formAnswersOptions = formAnswersOptions.map(answerOption => {
                    answerOption.formQuestionOption.description = JSON.parse(answerOption.formQuestionOption.description);
                    return answerOption;
                });
                formQuestion.additionalDetails = JSON.parse(formQuestion.additionalDetails);
                formAnswer.formQuestion = formQuestion;
                let formula: string = formQuestion.additionalDetails.formula;
                formAnswersOptions.forEach((formAnswerOption) => {
                    const regexp = new RegExp(formAnswerOption.formQuestionOption.description.key, 'g');
                    formula = formula.replace(regexp, formAnswerOption.value);
                });
                formAnswer.answer = this.calculate(formula).toFixed(2);
            } else if (formAnswer.formQuestion.optionType === "DATE_TIME") {
              let { formAnswersOptions } = formAnswer;
              formAnswersOptions = formAnswersOptions.map(answerOption => {
                answerOption.formQuestionOption.description = JSON.parse(answerOption.formQuestionOption.description);
                return answerOption;
              }).sort((answerOptionOne, answerOptionTwo) => {
                return answerOptionOne.formQuestionOption.optionOrder - answerOptionTwo.formQuestionOption.optionOrder;
              });
              formAnswer.formAnswersOptions = formAnswersOptions;
            }
            return formAnswer;
        });
        this.formAnswers = formAnswers;

    }

    originalAnswer(answer) {
       return answer[0].indexOf('Original') !== -1;
    }

    blockerAnswer(answer) {
        return this.blockerAnswers.filter(x => answer[2] === x.group + '.' + x.question).some(x => x.answer.includes(answer[1]));
    }

    checkPatientSCRConsent() {
        let scrAccessAnswer = this.form.filter(answer => answer[2] === 'disclaimer.scrAccess')[0];

        this.patientGaveConsentForSCR = false;
        if (this.orderItem.patient.preferences.accessNHSdetails){
         if (this.orderItem.patient.preferences.accessNHSdetails=="true"){
            this.patientGaveConsentForSCR = true;
         } 
        }

        // this.patientGaveConsentForSCR = this.orderItem.patient.nhsConsent;
    }

    calculate(jsString): number {
        return Function('"use strict";return (' + jsString + ')')();
    }

    getLabel(description){
        // var jsonDescription = JSON.parse(description)
        console.log(description.label)
        return "label"
    }
}
