<ng-container>
  <div bsModal #confirmUserModal="bs-modal" [config]="modalOptions" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" *ngIf="!(existingPatients?.length > 0)" class="close" (click)="confirmUserModal.hide();"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Search Patient</h4>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="existingPatients?.length > 0">
            <h5>
              We found the following patients with the given Name and DOB.
              If your patient is listed here, please select that patient and click on "Confirm".
              Else, click on "Enter Manually" to add a new patient record.
            </h5>
            <ul class="list-group">
              <li *ngFor="let patient of existingPatients; let i = index;" class="list-group-item" style="display: flex; font-size: 0.8em;" [ngClass]="{active: selectedPatientIndex == i}" (click)="selectedPatientIndex = i;">
                <div class="col col-xs-6">
                  <span><strong>Name:</strong> {{patient.firstName}} {{patient.lastName}}</span><br />
                  <span><strong>DOB:</strong> {{patient.dateOfBirth}}</span><br />
                  <span><strong>Email:</strong> {{patient.email}}</span><br />
                  <span><strong>Phone:</strong> {{patient.phone}}</span><br />
                </div>
                <div class="col col-xs-6">
                  <span>
                    <strong>Address:<br /></strong>
                    {{patient.address.line1}}<br />
                    <ng-container *ngIf="patient.address.line2">{{patient.address.line2}}<br /></ng-container>
                    {{patient.address.city}}<ng-container *ngIf="patient.address.county">, {{patient.address.county}}</ng-container>, {{patient.address.postcode}}
                  </span>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!(existingPatients?.length > 0)">
            <p>We do not have any patient record matching this information. Please enter the remaining patient information manually.</p>
          </ng-container>
        </div>
        <div class="modal-footer" *ngIf="existingPatients?.length > 0">
          <button type="button" class="btn btn-danger" (click)="ignoreExistingPatient()">Enter Manually</button>
          <button type="button" class="btn btn-success" [disabled]="selectedPatientIndex == null" (click)="selectExistingPatient();">Confirm</button>
        </div>
      </div>
    </div>
  </div>
  <accordion>
    <accordion-group #patientGroup [isOpen]="true" [isDisabled]="true">
      <div accordion-heading style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <h4 class="brand-1">1. Enter {{forPet ? 'Owner' : 'Patient'}} Information</h4>
        <span *ngIf="step > 1" class="brand-1" style="font-size: 1.5em"><i class="fa fa-check-circle"></i></span>
      </div>
      <div [hidden]="step !== 1">
        <form #patientForm="ngForm" novalidate>
          <div class="row">
            <div class="col col-md-6">
              <div class="row">
                <div class="form-group col-xs-6 pr-1" [ngClass]="{'has-error': firstNameInput.touched && firstNameInput.invalid}">
                  <label>First Name*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #firstNameInput="ngModel" name="firstName" type="text" required [(ngModel)]="patient.firstName" />
                  <small class="text-danger" *ngIf="firstNameInput.touched && firstNameInput.hasError('required')">First Name is required</small>
                </div>
                <div class="form-group col-xs-6 pl-1" [ngClass]="{'has-error': lastNameInput.touched && lastNameInput.invalid}">
                  <label>Last Name*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #lastNameInput="ngModel" name="lastName" type="text" required [(ngModel)]="patient.lastName" />
                  <small class="text-danger" *ngIf="lastNameInput.touched && lastNameInput.hasError('required')">Last Name is required</small>
                </div>
              </div>

              <div class="row">
                <div *ngIf="!isQuickSilvaDoctor()" class="form-group col-xs-4 pr-1" [ngClass]="{'has-error': dateOfBirthInput.touched && dateOfBirthInput.invalid}">
                  <label>Date of Birth*</label> 
                  
                  <input [disabled]="isExistingPatient || isSearchingForPatients" placeholder="dd-mm-yyyy" dateMask #dateOfBirthInput="ngModel" class="form-control" name="dateOfBirth" type="text" required
                    ngModel [value]="patient.dateOfBirth" (input)="!dateOfBirthInput.hasError('pattern') && setPatientDob($event.target.value)" [pattern]="dobRegex" />
                    
                  <small class="text-danger" *ngIf="dateOfBirthInput.touched && dateOfBirthInput.hasError('required')">Date of Birth is Required</small>
                  <small class="text-danger" *ngIf="dateOfBirthInput.touched && dateOfBirthInput.hasError('required') == false && (dateOfBirthInput.hasError('pattern') || !patient.dateOfBirth)">Please
                    enter a valid date of birth</small>
                </div>

                <div *ngIf="isQuickSilvaDoctor()" class="form-group col-xs-4 pr-1" [ngClass]="{'has-error': dateOfBirthInput.touched && dateOfBirthInput.invalid}">
                  <label>Date of Birth*</label>     
                  <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #dateOfBirthInput="ngModel" name="dateOfBirth" type="text" required [(ngModel)]="patient.dateOfBirth" />
                </div>

                <div class="form-group col-xs-2 pl-1 pr-1 search-btn">
                  <label>&nbsp;</label>
                  <button type="submit" class="btn btn-success" (click)="searchPatient()" [disabled]="isExistingPatient || !(patient.firstName && patient.lastName && patient.dateOfBirth) || isSearchingForPatients"><i *ngIf="isSearchingForPatients" class="fa fa-spinner fa-spin"></i><i *ngIf="!isSearchingForPatients" class="fa fa-search"></i></button>
                </div>
                
                <div class="form-group col-xs-6 pl-1" [ngClass]="{'has-error': phoneInput.touched && phoneInput.invalid}">
                  <label>Phone*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #phoneInput="ngModel" name="phone" type="tel" required [(ngModel)]="patient.phone" />
                  <small class="text-danger" *ngIf="phoneInput.touched && phoneInput.hasError('required')">Phone is Required</small>
                </div>
              </div>

              <div class="form-group" [ngClass]="{'has-error': emailInput.touched && emailInput.invalid}">
                <label>Email*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #emailInput="ngModel" name="email" type="email" required [pattern]="emailRegex" [(ngModel)]="patient.email" />
                <small class="text-danger" *ngIf="emailInput.touched && emailInput.hasError('required')">Email address is required</small>
                <small class="text-danger" *ngIf="emailInput.touched && emailInput.hasError('required') == false && emailInput.hasError('pattern')">Please enter a valid email address</small>
              </div>
            </div>

            <div class="col col-md-6">
              <div class="form-group" [ngClass]="{'has-error': addressLine1.touched && addressLine1.invalid}">
                <label>Line 1*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #addressLine1="ngModel" name="addressLine1" type="text" required [(ngModel)]="patient.address.line1" />
                <small class="text-danger" *ngIf="addressLine1.touched && addressLine1.hasError('required')">Address Line 1 is required</small>
              </div>
              <div class="form-group">
                <label>Line 2</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" name="addressLine2" type="text" [(ngModel)]="patient.address.line2" />
              </div>
              <div class="row">
                <div class="col-xs-4 pr-1">
                  <div class="form-group" [ngClass]="{'has-error': addressCity.touched && addressCity.invalid}">
                    <label>City*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #addressCity="ngModel" name="addressCity" type="text" required [(ngModel)]="patient.address.city" />
                    <small class="text-danger" *ngIf="addressCity.touched && addressCity.hasError('required')">Address City is required</small>
                  </div>
                </div>
                <div class="col-xs-4 pl-1 pr-1">
                  <div class="form-group">
                    <label>County</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" name="addressCounty" type="text" [(ngModel)]="patient.address.county" />
                  </div>
                </div>
                <div class="col-xs-4 pl-1">
                  <div class="form-group" [ngClass]="{'has-error': addressPostcode.touched && addressPostcode.invalid}">
                    <label>Postcode*</label> <input [disabled]="isExistingPatient || isSearchingForPatients" class="form-control" #addressPostcode="ngModel" name="addressPostcode" type="text" required
                      [(ngModel)]="patient.address.postcode" />
                    <small class="text-danger" *ngIf="addressPostcode.touched && addressPostcode.hasError('required')">Address Postcode is required</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-md-12" *ngIf="!isExistingPatient">
              <div class="form-group" [ngClass]="{'has-error': consent.touched && consent.invalid}">
                <input #consent="ngModel" ngModel name="consent" type="checkbox" required />&nbsp;
                <label class="text-danger"> Patient provides consent to share the above information with thegpservice.co.uk</label>
                <br>
                <small class="text-muted">This information will be accessible to thegpservice.co.uk and its partners for regulatory purposes.</small>
              </div>
            </div>
          </div>
        </form>
        <button class="btn btn-danger" (click)="initState(); patientForm.reset()" *ngIf="!isQuickSilvaDoctor()">Clear</button>&nbsp;
        <button class="btn btn-primary" [disabled]="!isExistingPatient && (!patient.dateOfBirth || !patientForm?.valid) || isSearchingForPatients" (click)="gotoNext(); forPet && isExistingPatient && getPets(patient.id);">Continue</button>
      </div>
      <div *ngIf="step > 1">
        <div class="row">
          <div class="col col-xs-6">
            <span><strong>Name:</strong> {{patient.firstName}} {{patient.lastName}}</span><br />
            <span><strong>DOB:</strong> {{patient.dateOfBirth}}</span><br />
            <span><strong>Email:</strong> {{patient.email}}</span><br />
            <span><strong>Phone:</strong> {{patient.phone}}</span><br />
          </div>
          <div class="col col-xs-6">
            <span>
              <strong>Address:<br /></strong>
              {{patient.address.line1}}<br />
              <ng-container *ngIf="patient.address.line2">{{patient.address.line2}}<br /></ng-container>
              {{patient.address.city}}<ng-container *ngIf="patient.address.county">, {{patient.address.county}}</ng-container>, {{patient.address.postcode}}
            </span>
          </div>
        </div>
      </div>
    </accordion-group>

    <accordion-group *ngIf="forPet" #petGroup [isOpen]="step > 1" [isDisabled]="true">
      <div accordion-heading style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <h4 class="brand-1">2. Enter Pet Information</h4>
        <span *ngIf="step > 2" class="brand-1" style="font-size: 1.5em"><i class="fa fa-check-circle"></i></span>
      </div>
      <div [hidden]="step !== 2">
        <div *ngIf="isSearchingForPets" class="text-center" style="padding: 2rem;">
          <div>Searching for available pets...</div>
          <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
        </div>
        <form [hidden]="isSearchingForPets" accordion-panel #petForm="ngForm" novalidate>
          <div class="row">
            <div class="col col-xs-12">
              <div class="form-group col-xs-6 pr-1" [ngClass]="{'has-error': petNameInput.touched && petNameInput.invalid}">
                <label>Pet Name*</label> <input [disabled]="isExistingPet" placeholder="Search by Name" class="form-control" #petNameInput="ngModel" name="name" type="search" autocomplete="off" required [(ngModel)]="pet.name" [typeahead]="existingPets" typeaheadOptionField="name" [typeaheadMinLength]="0" (typeaheadOnSelect)="selectExistingPet($event.item)" />
                <small class="text-danger" *ngIf="petNameInput.touched && petNameInput.hasError('required')">Pet Name is required</small>
              </div>
              <div class="form-group col-xs-6 pl-1" [ngClass]="{'has-error': petSpeciesInput.touched && petSpeciesInput.invalid}">
                <label>Pet Species*</label> <input [disabled]="isExistingPet" class="form-control" #petSpeciesInput="ngModel" name="species" type="text" required [(ngModel)]="pet.species" />
                <small class="text-danger" *ngIf="petSpeciesInput.touched && petSpeciesInput.hasError('required')">Pet Species is required</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-xs-12">
              <div class="form-group col-xs-12">
                <input #petAddressCheckBox="ngModel" [disabled]="isExistingPet" [ngModel]="addressEqual(pet.address, patient.address)" (change)="updatePetAddress($event)" type="checkbox" name="pet">&nbsp;<label>Same Address as the Owner</label>
                <br>
              </div>
            </div>
          </div>
          <div class="row" [hidden]="petAddressCheckBox.value">
            <div class="col col-xs-12">
              <div class="form-group col-xs-12" [ngClass]="{'has-error': addressLine1.touched && addressLine1.invalid}">
                <label>Line 1*</label> <input [disabled]="isExistingPet" class="form-control" #addressLine1="ngModel" name="addressLine1" type="text" required [(ngModel)]="pet.address.line1" />
                <small class="text-danger" *ngIf="addressLine1.touched && addressLine1.hasError('required')">Address Line 1 is required</small>
              </div>
              <div class="form-group col-xs-12">
                <label>Line 2</label> <input [disabled]="isExistingPet" class="form-control" name="addressLine2" type="text" [(ngModel)]="pet.address.line2" />
              </div>
              <div class="row">
                <div class="col-xs-4 pr-1">
                  <div class="form-group col-xs-12" [ngClass]="{'has-error': addressCity.touched && addressCity.invalid}">
                    <label>City*</label> <input [disabled]="isExistingPet" class="form-control" #addressCity="ngModel" name="addressCity" type="text" required [(ngModel)]="pet.address.city" />
                    <small class="text-danger" *ngIf="addressCity.touched && addressCity.hasError('required')">Address City is required</small>
                  </div>
                </div>
                <div class="col-xs-4 pl-1 pr-1">
                  <div class="form-group col-xs-12">
                    <label>County</label> <input [disabled]="isExistingPet" class="form-control" name="addressCounty" type="text" [(ngModel)]="pet.address.county" />
                  </div>
                </div>
                <div class="col-xs-4 pl-1">
                  <div class="form-group col-xs-12" [ngClass]="{'has-error': addressPostcode.touched && addressPostcode.invalid}">
                    <label>Postcode*</label> <input [disabled]="isExistingPet" class="form-control" #addressPostcode="ngModel" name="addressPostcode" type="text" required [(ngModel)]="pet.address.postcode" />
                    <small class="text-danger" *ngIf="addressPostcode.touched && addressPostcode.hasError('required')">Address Postcode is required</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <button class="btn btn-info" (click)="goBack()">Back</button>&nbsp;
        <button class="btn btn-danger" (click)="initPetStage(); petForm.reset()">Clear</button>&nbsp;
        <button class="btn btn-primary" [disabled]="!isExistingPet && !petForm?.valid" (click)="gotoNext()">Continue</button>
      </div>
      <div *ngIf="step > 2">
        <div class="row">
          <div class="col col-xs-6">
            <span><strong>Name:</strong> {{pet.name}}</span><br />
            <span><strong>Species:</strong> {{pet.species}}</span><br />
          </div>
          <div class="col col-xs-6">
            <span>
              <strong>Address:<br /></strong>
              {{pet.address.line1}}<br />
              <ng-container *ngIf="pet.address.line2">{{pet.address.line2}}<br /></ng-container>
              {{pet.address.city}}<ng-container *ngIf="pet.address.county">, {{pet.address.county}}</ng-container>, {{pet.address.postcode}}
            </span>
          </div>
        </div>
      </div>
    </accordion-group>

    <accordion-group #pharmacyGroup [isOpen]="step > (forPet ? 2: 1)" [isDisabled]="true">
      <div accordion-heading style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <h4 class="brand-1">{{forPet ? 3 : 2}}. Enter Pharmacy Information</h4>
        <span *ngIf="step > (forPet ? 3 : 2)" class="brand-1" style="font-size: 1.5em"><i class="fa fa-check-circle"></i></span>
      </div>
      <div [hidden]="step !== (forPet ? 3 : 2)">
        <app-pharmacy-picker accordion-panel (pharmacyChange)="pharmacy = $event"></app-pharmacy-picker>
        <button class="btn btn-info" (click)="goBack()">Back</button>&nbsp;
        <button class="btn btn-primary" [disabled]="!pharmacy" (click)="gotoNext()">Continue</button>
      </div>
      <div *ngIf="step > (forPet ? 3 : 2)">
        <strong>{{pharmacy.displayName}}</strong><br />
        {{pharmacy.address.line1}}<br />
        <ng-container *ngIf="pharmacy.address.line2">{{pharmacy.address.line2}}<br /></ng-container>
        {{pharmacy.address.city}}<ng-container *ngIf="pharmacy.address.county">, {{pharmacy.address.county}}</ng-container>, {{pharmacy.address.postcode}}
      </div>
    </accordion-group>

    <accordion-group #prescriptionGroup [isOpen]="step > (forPet ? 3 : 2)" [isDisabled]="true">
      <div accordion-heading style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
        <h4 class="brand-1">{{forPet ? 4 : 3}}. Select Medication</h4>
      </div>
      <div accordion-panel>
        <div [hidden]="submitting">
          <app-add-prescription #addPrescriptionComponent [showRefNumberInput]="true" [patient]="patient" [pet]="forPet ? pet : null" [pharmacy]="pharmacy" (submit)="createPrescription($event)" (back)="goBack()"></app-add-prescription>
        </div>
        <div *ngIf="submitting" class="text-center" style="padding: 2rem; width: 100%;">
          <h4 class="brand-1">{{submittingMessage}}</h4>
          <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
        </div>
      </div>
    </accordion-group>

  </accordion>
</ng-container>