<div class="container">
  <h1 class="brand-1">Prescriptions</h1>
  <p>Please find the prescriptions of consultations below.</p>
  <div class="table-container">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th colspan="8">
            <div style="display: flex; justify-content: flex-end;">

              <button class="btn btn-primary" *ngIf="((doctorType == DoctorType.COMPANY_DOCTOR) || (doctorType == DoctorType.COMPANY_PRESCRIBING_PHARMACIST)) && (!isPrescribingDoctor())" (click)="openCreatePrescriptionModal(false)">Create Prescription (Patient)</button>
              &nbsp;
              <button class="btn btn-primary" *ngIf="doctorType == DoctorType.COMPANY_VET" (click)="openCreatePrescriptionModal(true)">Create Prescription (Pet)</button>
              &nbsp;

              <div class="input-group">
                <input class="form-control" style="width: 250px;" placeholder="Search orders..." type="text" [(ngModel)]="searchTerm" />
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button">&nbsp;<i class="glyphicon glyphicon-search"></i></button>
                </span>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Id</th>
          <th>Date</th>
          <th>Patient ID</th>
          <th>Patient</th>
          <th>Name</th>
          <th>Type</th>
          <th colspan="2">
            Status
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of (orderItems | orderFilter: searchTerm)">
          <td>{{ item.id }}</td>
          <td>{{ item.creationDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ item.patient.id }}</td>
          <td>{{ item.patient.displayName }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td class="alert alert-{{item.status | orderState}}">{{ item.status | orderStatus }}</td>
          <td>
            <a class="btn btn-info" routerLink="/prescriptions/{{item.id}}/print" *ngIf="item.status == 'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION'"
              >Print</a
            >
            <a class="btn btn-info" routerLink="/prescriptions/{{item.id}}">View</a>
            <a class="btn btn-primary" (click)="openAppointmentActionModal(item.appointment.id)" *ngIf="item.status == 'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION'"
              >Complete</a
            >
          </td>
        </tr>
        <tr *ngIf="searching">
          <td colspan="8" style="padding: 1rem; background: white; text-align: center;">
            <div>Please Wait...</div>
            <i style="font-size: 2rem;" class="fa fa-spinner fa-spin"></i>
          </td>
        </tr>
        <tr *ngIf="!searching && orderItems && (orderItems | orderFilter: searchTerm).length == 0">
          <td colspan="8" style="padding: 1rem; background: white; text-align: center;">(No Items)</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div bsModal #completeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="completeModal.hide()" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Complete consultation</h4>
        </div>
        <div class="modal-body">
          <form #completeForm="ngForm" autocomplete="off" novalidate (ngSubmit)="completeAppointmentPrescription()">
            <label for="pin">Pin</label> <input id="pin" class="form-control pw" type="text" name="pin" required [(ngModel)]="pin" autocomplete="off" />
          </form>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" (click)="completeAppointmentPrescription()" [disabled]="!completeForm.form.valid">Submit</button>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #createPrescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeCreatePrescriptionModal()"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title">Create Prescription</h4>
        </div>
        <div class="modal-body">
          <app-create-prescription [forPet]="isPetOrder" #modalForm (action)="completeCreatePrescription($event, modalForm);"></app-create-prescription>
        </div>
      </div>
    </div>
  </div>
</div>
