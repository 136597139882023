<div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Verify that</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="consentGroup" autocomplete="off">
          <div *ngFor="let item of consentForm | keyvalue" class="modal-question" style="display: flex;">
            <input type="checkbox" [formControlName]="item.key" [id]="item.key">
            <label [for]="item.key" style="margin-left: 5px; margin-top: 10px"><b>{{item.value}}</b></label>
          </div>
          <!-- <button class="btn btn-primary" [style.width]="'100%'" type="submit"
            [disabled]="consentGroup.invalid">Submit</button> -->
        </form>
      </div>
    </div>
  </div>
