<div class="container" *ngIf="order && isLoading">
  <!-- Key Alerts -->
  <!-- <div *ngIf="getAlertsTitle()" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="alert" style="cursor: pointer;" (click)="showAlerts = !showAlerts">
      <div class="key-alert-title">
        <strong>Key message alerts: </strong>
        <span *ngIf="!showAlerts">{{ getAlertsTitle() }}</span>
        <span *ngIf="showAlertsDots()">...</span>
        <span *ngIf="showExpandAlertsBtn()">
          <span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
          <span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
        </span>
      </div>

      <div *ngIf="showAlerts" class="key-alerts-inner-container">
        <div *ngIf="patient?.allAlerts?.doctor?.length > 0">
          <strong>From Doctor:</strong>
          <p>{{ patient?.allAlerts?.doctor }}</p>
        </div>
        <div *ngIf="patient?.allAlerts?.admin?.length > 0">
          <strong>From Admin:</strong>
          <p>{{ patient?.allAlerts?.admin }}</p>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="consultation">
      <div class="video_chat">
        <app-video-call #videoCall
          (messageReceived)="textChat.onMessageReceived($event); actions.onMessageReceived($event)"
          (messageSent)="textChat.onMessageSent($event)" [order]="order"
          (updateSignalForm)="editNotes.handleSignalStatus($event)"></app-video-call>
        <div class="chat_wrap">
          <!-- <div style="margin-top: 1rem; display: flex; justify-content: center;">
            <button class="btn btn-danger">Finish Consultation</button>
          </div> -->
          <app-text-chat #textChat [patientName]="order.patient.displayName" [enabled]="videoCall.connections > 1"
            (sendMessage)="videoCall.sendMessage($event)"></app-text-chat>
          <!-- <div *ngIf="order && doctorNote && doctorNote.id"> -->
          <app-appointment-actions #actions [patientIdentified]="patientIdentified"
            (updatePatientVerification)="updatePatientVerification($event)"
            (updateIdentity)="videoCall.updateVerification($event)" (sendMessage)="videoCall.sendMessage($event)"
            class="chat_buttons" [submitted]="submitted" [doctorNote]="doctorNote"
            [appointmentId]="order.appointment.id" [orderId]="order.id" [pharmacy]="order.pharmacy"
            [enabled]="videoCall.connections > 1" (displayPreviousMedications)="displayPreviousMedications()"
            [patient]="order.patient">
          </app-appointment-actions>
        </div>
      </div>
      <div *ngIf="patient" class="consultation_details">
        <app-edit-patient-notes #editNotes [submitted]="submitted" [order]="order"
          [patientIdentified]="patientIdentified" [orders]="orders" [patient]="patient"
          (displayPreviousMedications)="displayPreviousMedications()"
          (signalForForms)="videoCall.signalForForms($event)" (updateCompletion)="videoCall.updateCompletion($event)"
          (fetchPatient)="fetchPatient()"
          [ordersInTheLast28DaysWithPrescription]="ordersInTheLast28DaysWithPrescription" [videoCall]="videoCall">
        </app-edit-patient-notes>
      </div>
    </div>
  </div>

</div>
<app-chat-widget *ngIf="order !== undefined" [orderId]="order.id"></app-chat-widget>

<div bsModal #pendingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="pending-container">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h4>Please wait until we finalize things!</h4>
      </div>
    </div>
  </div>
</div>