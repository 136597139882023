<div class="modal-dialog proforma-modal">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
          aria-hidden="true">&times;</span></button>
      <h4 class="modal-title">
        MDT Proforma
      </h4>
    </div>
    <div class="modal-body">
      <div class="notes-header">
        <h5 class="note-question">Question</h5>
        <h5 class="answer">Answer</h5>
      </div>
      <ng-container *ngFor="let item of questions | keyvalue : returnZero">
        <div *ngIf="isKeyExist(item.key) && item.key !== 'signOff'" class="question">
          <span class="note-question">{{item.value.title}}</span>
          <div *ngIf="isAnswerBoolean(item.key); else textAnswer" class="answer">
            <span *ngIf="proformaNotes[item.key].answer === true" class="label label-primary">Yes</span>
            <span *ngIf="proformaNotes[item.key].answer === false" class="label label-warning">No</span>
            <ng-container *ngIf="proformaNotes[item.key].answer === false">
              <span>{{proformaNotes.reason?.answer ? ' - '+proformaNotes.reason.answer : ''}}</span>
            </ng-container>
          </div>
          
          <ng-template #textAnswer>
            <span class="answer">{{proformaNotes[item.key].answer}}</span>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>