import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByAppointmentStatus'
})

export class FilterByAppointmentStatusPipe implements PipeTransform {
    transform(value: any, status: string): any {

        if (!status) {
            return value;
        }
        return value.filter(v => {
            // If Status CANCELED_BY_DOCTOR or PATIENT_ABSENT will return true if CANCELED_BY_DOCTOR section
            if (v.status === 'CANCELED_BY_DOCTOR' && status === 'CANCELED_BY_DOCTOR' || v.status === 'PATIENT_ABSENT' && status === 'CANCELED_BY_DOCTOR') {
              return true;
            }
            return v.status === status;
        });
    }
}
