import { Component, Input, OnChanges } from '@angular/core';
import { feedbackType, mockResponse, pharmacyQuestionnaire, pharmacyQuestionType } from 'src/assets/pharmacyQuestionnaire';

@Component({
  selector: 'app-pharmacy-conultation-note',
  templateUrl: './pharmacy-conultation-note.component.html',
  styleUrls: ['./pharmacy-conultation-note.component.scss']
})
export class PharmacyConultationNoteComponent implements OnChanges {
  @Input() gpNote: any;
  note: any;

  constructor() { }

  ngOnChanges(): void {
    this.note = JSON.parse(this.gpNote[0].questionAnswer || {});
  }

  renderQuestion(step: string, key: string){
    const question = this.getQuestion(step, key);
    return (
      !question.hasOwnProperty('header') &&
      question.responseType !== feedbackType.externalForm &&
      this.note.hasOwnProperty(`${step}|${key}`) &&
      this.validateRenderCondition(question)
    );
  }

  validateRenderCondition(question: pharmacyQuestionType) {
    if (!question.hasOwnProperty('renderCondition')) {
      return true;
    }
    const { stepId, questionId } = question.renderCondition;
    const masterQuestion = this.questionnaire.data[stepId].data[questionId];
    const masterAnswer = this.note[`${stepId}|${questionId}`];

    if (
      [feedbackType.choice, feedbackType.choiceAndTextOnCondition].includes(
        masterQuestion.responseType
      )
    ) {
      return masterAnswer.choice;
    }

    return true;
  }

  getQuestion(step: string, key: string){
    return pharmacyQuestionnaire.data[step].data[key]
  }

  getResponse(step: string, key: string){
    return this.note[`${step}|${key}`]
  }

  getCheckedChoices(step: string, key: string){
    return this.note[`${step}|${key}`].choices.filter(choice => choice.checked)
  }

  get questionnaire(){
    return pharmacyQuestionnaire;
  }

  get feedbackType(){
    return feedbackType;
  }

}
