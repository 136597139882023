
<div [ngSwitch]="fitNoteState">
    <div *ngSwitchCase="'initial'">
        <form #fitNoteForm="ngForm" *ngIf="doctorNote.details.differentialDiagnosis" autocomplete="off" novalidate>
            <div class="form-group">
                <label for="differentialDiagnosis">Differential diagnosis</label>
                <input id="differentialDiagnosis" disabled required [(ngModel)]="doctorNote.details.differentialDiagnosis" [typeahead]="conditions" typeaheadEditable="false"
                    class="form-control" name="differentialDiagnosis">
            </div>
            <div class="form-group">
                <label for="differentialDiagnosisNotes">Differential diagnosis notes</label>
                <textarea id="differentialDiagnosisNotes" disabled [(ngModel)]="doctorNote.details.differentialDiagnosisNotes" name="differentialDiagnosisNotes"
                    class="form-control"></textarea>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" required [(ngModel)]="model.fit" [value]="false" name="fit">
                    Not fit for work
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" required [(ngModel)]="model.fit" [value]="true" name="fit">
                    Maybe fit for work
                </label>
            </div>

            <div *ngIf="model.fit">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="model.phasedReturn" name="phasedReturn">
                        A phased return to work
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="model.alteredHours" name="alteredHours">
                        Altered hours
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="model.amendedDuties" name="amendedDuties">
                        Amended duties
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="model.workplaceAdapt" name="workplaceAdapt">
                        Work place adaption
                    </label>
                </div>
            </div>
          <div class="form-group">
            <label for="expirationDate">Valid from:</label>
            <input type="date" class="form-control"
                   [(ngModel)]="model.fromDate"
                   name="fromDate" id="fromDate"
                   [min]="minFromDate"
                   [max]="maxFromDate"
                   placeholder="yyyy-mm-dd"
                   (change)="onFitNoteDateChange($event, 'FROM')"
                   required>
            <p *ngIf="wrongFromDate" class="ng-invalid">This cannot be before the expiration date below.</p>
          </div>
            <div class="form-group">
                <label for="expirationDate">Valid until:</label>
                <input type="date" class="form-control"
                       [(ngModel)]="model.expirationDate"
                       name="expirationDate" id="expirationDate"
                       [min]="minFromDate"
                       placeholder="yyyy-mm-dd"
                       (change)="onFitNoteDateChange($event, 'TO')"
                       required>
                <p *ngIf="wrongExpirationDate" class="ng-invalid">Expiration date cannot be before the from date above.</p>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="model.reassessmentNeeded" name="reassessmentNeeded">
                    Reassessment needed
                </label>
            </div>
            <div class="form-group">
                <label for="conditionNotes">Additional Notes (functional effect of patient's condition)</label>
                <textarea #conditionNotes="ngModel" [(ngModel)]="model.conditionNotes" name="conditionNotes" class="form-control" maxlength="250"></textarea>
            </div>

            <button type="button" class="btn btn-danger" (click)="moveToCanceledState()" style="margin-right: 1rem;">Delete</button>
            <button type="button" class="btn btn-primary" (click)="onCreate()" [disabled]="!fitNoteForm.form.valid || wrongFromDate || wrongExpirationDate">Create</button>

        </form>
        <div *ngIf="!doctorNote.details.differentialDiagnosis">
            <p>Please complete the differential diagnosis before creating a fit note</p>
        </div>
    </div>

    <div *ngSwitchCase="'proposed'" class="summary">
        <p><strong>Differential diagnosis:</strong><br />
            <em> {{doctorNote.details.differentialDiagnosis}} </em>
        </p>
        <p><strong>Differential diagnosis notes:</strong><br />
            <em> {{doctorNote.details.differentialDiagnosisNotes}} </em>
        </p>

        <p><strong>Patient is fit for work:<br /></strong>
            <em *ngIf="model.fit">Yes<br /><br /></em>
            <em *ngIf="!model.fit">No<br /></em>
            <span *ngIf="model.fit">
                <em *ngIf="model.phasedReturn">Patient should have a phased return.<br /></em>
                <em *ngIf="model.alteredHours">Patient hours should be altered.<br /></em>
                <em *ngIf="model.amendedDuties">Patient should go back to work with amended duties.<br /></em>
                <em *ngIf="model.workplaceAdapt">The workplace should be adapted.<br /></em>
            </span>
        </p>

        <p>
          <strong>Fit note valid from: </strong>
          <br />
          <em> {{model.fromDate}} </em>
        </p>
      <p>
        <strong>Fit note valid until: </strong>
        <br />
        <em> {{model.expirationDate}} </em>
      </p>
        <p *ngIf="model.reassessmentNeeded">
            <em> A reassessment is needed. </em>
        </p>
        <p><strong>Additional Notes: </strong><br />
            <em> {{model.conditionNotes}} </em>
        </p>

        <form #pinForm="ngForm" autocomplete="off" novalidate>
            <div class="form-group">
                <label for="pin">Pin</label>
                <input class="form-control pw" id="pin" name="pin" required type="text" [(ngModel)]="model.pin" autocomplete="off"/>
            </div>
            <button type="button" class="btn btn-default" (click)="fitNoteState = 'initial'">Change</button>
            <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="pinForm.form.invalid">Confirm</button>
        </form>

    </div>
    <div  *ngSwitchCase="'canceled'">
         <form #pinForm="ngForm" autocomplete="off" novalidate>
            <div class="form-group" *ngIf="fitNoteId">
                <label for="pin">Confirm fit note deletion by entering your pin</label>
                <input class="form-control pw" id="pin" name="pin" required type="text" [(ngModel)]="model.pin" autocomplete="off" />
            </div>
			<label *ngIf="!fitNoteId">Confirm fit note deletion</label>
        </form>
        <button type="button" class="btn btn-default" (click)="fitNoteState = 'initial'">Change</button>
        <button type="button" class="btn btn-primary" (click)="onDelete()" [disabled]="pinForm.form.invalid">Confirm</button>
    </div>
</div>
