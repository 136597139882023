import { Component, OnInit, EventEmitter, Output, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-decision-form',
  templateUrl: './decision-form.component.html',
  styleUrls: ['./decision-form.component.scss'],
})
export class DecisionFormComponent implements OnInit, AfterViewInit {
  @Input() isSubmitting: boolean;
  @Input() orderId: boolean;
  @Output() addDescision = new EventEmitter();
  @ViewChild('finalizeModal') finalizeModal: ModalDirective;
  decisionGroup: FormGroup;
  constructor(private toastr: ToastrService) {}

  ngOnInit(): void {
    const decisionNote = JSON.parse(localStorage.getItem(`${this.orderId}decision`) || '{}')

    this.decisionGroup = new FormGroup({
      approved: new FormControl(decisionNote?.approved || '', Validators.required),
      reason: new FormControl(decisionNote?.reason || ''),
      description: new FormControl(decisionNote?.description || '', Validators.required),
    });
  }

  complete() {
    if (this.decisionGroup.invalid) {
      const invalidFields = [];
      const controls = this.decisionGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalidFields.push(controls[name].value.question);
        }
      }

      for (let i = 0; i < invalidFields.length; i++) {
        this.toastr.warning(invalidFields[i], 'Please fill the empty field');
      }
      return;
    }

    this.finalizeModal.show();
  }

  ngAfterViewInit(): void {
    this.decisionGroup.valueChanges.subscribe((value) => {
      localStorage.setItem(`${this.orderId}decision`, JSON.stringify(value));
    })
  }
}
