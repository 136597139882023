import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GlobalOptions } from './app.module';
import { ALL_STATUS } from 'src/assets/statusMappings';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  getPharmacy(id: number) {
    return this.http.get<any>(
      `${this.options.apiEndPoint}api/public/pharmacy/${id}`
    );
  }

  // 6 per hour, 144 per day, 1008 per 7 days
  getAppointments(
    _startDate: Date,
    _endDate: Date,
    pageNumber = 0,
    pageSize = 1008
  ) {
    let startDate = _startDate.toISOString();
    let endDate = _endDate.toISOString();
    let url = `${this.options.apiEndPoint}api/doctor/appointments?from=${startDate}&to=${endDate}&page=${pageNumber}&size=${pageSize}`;
    return this.http.get<any>(url).pipe(
      map((response) => {
        let listings = response.content;
        listings.forEach((d: any) => {
          d = this.parseGpAddress(d);
          d.datetime = new Date(d.datetime);
        });
        return listings;
      })
    );
  }

  public getPrescriptionsWithThisDrug(
    medication: string,
    patientId: number,
    petId?: number
  ) {
    let url =
      `${
        this.options.apiEndPoint
      }api/doctor/prescription/search-medication?patientId=${patientId}&medication=${encodeURIComponent(
        medication
      )}` + (petId ? `&petId=${petId}` : '');
    return this.http.get<any>(url);
  }

  addAppointment(dateTime: Date) {
    let url = `${this.options.apiEndPoint}api/doctor/appointment/`;
    return this.http.post<any>(url, { datetime: dateTime.toISOString() });
  }

  extendAppointment(appointmentId: number) {
    return this.updateAppointment(appointmentId, 'extend');
  }

  completeAppointment(appointmentId: number) {
    return this.updateAppointment(appointmentId, 'complete');
  }

  cancelAppointment(
    appointmentId: number,
    reason: string = '',
    cancelCharge: boolean = false
  ) {
    return this.updateAppointment(appointmentId, 'cancel', {
      additionalNotes: reason,
      charge: cancelCharge,
    });
  }

  cancelAppointmentHolder(
    appointmentId: number,
    reason: string = null,
    type: string
  ) {
    return this.updateAppointment(appointmentId, type, { reason: reason });
  }

  patientAbsentFromAppointment(appointmentId: number) {
    return this.updateAppointment(appointmentId, 'no-patient');
  }

  updateAppointment(appointmentId: number, action: string, model = null) {
    let url = `${this.options.apiEndPoint}api/doctor/appointment/${appointmentId}?action=${action}`;
    return this.http.put<any>(url, model);
  }

  addPrescription(
    orderItemId: number,
    treatments: any[],
    additionalPatientNotes: string,
    pin: string
  ) {
    let url = `${this.options.apiEndPoint}api/doctor/prescription/`;
    return this.http
      .post<any>(url, {
        orderItem: {
          id: orderItemId,
        },
        medication: treatments,
        additionalPatientNotes: additionalPatientNotes,
        pin: pin,
      })
      .pipe(map(this.parseGpAddress));
  }

  deletePrescription(orderItemId: number, pin: string) {
    let url = `${this.options.apiEndPoint}api/doctor/prescription/${orderItemId}`;
    return this.http.request<any>('DELETE', url, { body: { pin: pin } });
  }

  addReferralLetter(
    appointmentId: number,
    pin: string,
    reason: string,
    speciality: string
  ) {
    let url = `${this.options.apiEndPoint}api/doctor/referral-letter/`;
    return this.http.post<any>(url, {
      appointment: {
        id: appointmentId,
      },
      text: JSON.stringify({
        reason: reason,
        speciality: speciality,
      }),
      pin: pin,
    });
  }

  deleteReferralLetter(appointmentId: number, pin: string) {
    let url = `${this.options.apiEndPoint}api/doctor/referral-letter`;
    return this.http.request<any>('DELETE', url, {
      body: {
        appointment: {
          id: appointmentId,
        },
        pin: pin,
      },
    });
  }

  addSickNote(appointmentId: number, model: any) {
    model.appointment = {
      id: appointmentId,
    };
    let url = `${this.options.apiEndPoint}api/doctor/sicknote/`;
    return this.http.post<any>(url, model);
  }

  deleteFitNote(appointmentId: number, pin: string) {
    let url = `${this.options.apiEndPoint}api/doctor/sicknote/`;
    return this.http.request<any>('DELETE', url, {
      body: {
        appointment: {
          id: appointmentId,
        },
        pin: pin,
      },
    });
  }

  updateDoctorNote(doctorNote: any) {
    let url = `${this.options.apiEndPoint}api/doctor/doctor-note/${doctorNote.id}`;
    return this.http.put<any>(url, doctorNote);
  }

  getFitNote(appointmentId: number) {
    return this.getDocument(appointmentId, 'sick-note');
  }

  getReferralLetter(appointmentId: number) {
    return this.getDocument(appointmentId, 'referral-letter');
  }

  getPrescription(orderId: number) {
    return this.getDocument(orderId, 'prescription');
  }

  getDocument(appointmentId: number, type: string) {
    let url = `${this.options.apiEndPoint}api/doctor/document/${appointmentId}?type=${type}`;
    return this.http.get<any>(url).pipe(
      map(this.parseGpAddress),
      catchError((error) => {
        console.log(error);
        return of(null);
      })
    );
  }

  getDoctorNote(appointmentId: number) {
    if (appointmentId) {
      return this.getDocument(appointmentId, 'doctor-note').pipe(
        map(this.parseDoctorNote)
      );
    } else {
      return of(null);
    }
  }

  getOrderNotes(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order-item/${orderId}`;
    return this.http.get<any>(url);
  }

  private parseDoctorNote(data: any) {
    if (data.medicinesPrescribed) {
      data.medicinesPrescribedParsed = data.medicinesPrescribed;
    }
    return data;
  }

  // getDoctorNotesByPatient(patientId: number, pageNumber = 0, pageSize = 100) {
  //   let url = `${this.options.apiEndPoint}api/doctor/doctor-notes/${patientId}?page=${pageNumber}&size=${pageSize}&sort=creationDate,desc`;
  //   return this.http.get<any>(url).pipe(
  //     map(this.parseGpAddress),
  //     map(response => {
  //       let content: any[] = response.content;
  //       return content.map(this.parseDoctorNote);
  //     })
  //   );
  // }

  createPatientNote(orderId: number, doctorNote: any) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, doctorNote);
  }

  updatePatientNote(orderId: number, doctorNote: any) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.put<any>(url, doctorNote);
  }

  getConsultationNote(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes?type=GP_CONSULTATION_NOTES`;
    return this.http.get<any>(url);
  }

  getPegNote(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes?type=PEG_FORM`;
    return this.http.get<any>(url);
  }

  getGadNote(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes?type=GAD_FORM`;
    return this.http.get<any>(url);
  }

  getAuditNote(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes?type=AUDIT_FORM`;
    return this.http.get<any>(url);
  }

  getPatientConsent(orderId: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes?type=PATIENT_DETAIL_CONFIRMATION`;
    return this.http.get<any>(url);
  }

  createRecommendation(orderId: number, recommendation: any) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, recommendation);
  }

  createDecision(orderId: number, decision: any) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, decision);
  }

  getPatient(id: number) {
    let url = `${this.options.apiEndPoint}api/doctor/patient/${id}`;
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  getAllPatients(nameStr: string, page: number) {
    let url = `${this.options.apiEndPoint}api/doctor/patient?page=${page}&size=10`;
    if (!!nameStr) {
      url = `${url}&q=${nameStr}`;
    }

    return this.http.get<any>(url);
  }

  getPendingAssessments() {
    let pendingStates = [
      'WAITING_FOR_DOCTOR_APPROVAL',
      'RESERVED_BY_DOCTOR',
      // 'WAITING_DOCTOR_COMPLETION',
      'AWAITING_PATIENT_RESPONSE',
      'PROPOSED_BY_DOCTOR',
    ];
    return this.getOrderItems(0, 1000, pendingStates, ['FORM', 'TRAVEL']);
  }

  getPendingConsultations() {
    return this.getOrderItems(0, 1000, ['BOOKED'], ['BOOKING']);
  }

  getCompleteOrders(patientId: number = null, currentPage = 0, size = 10) {
    return this.getOrderItems(currentPage, size, ALL_STATUS, [], patientId);
  }

  getExtendedOrders(patientId: number = null, currentPage = 0, size = 10) {
    let url = `${this.options.apiEndPoint}api/doctor/extended-order-items?page=${currentPage}&size=${size}&sort=creationDate,desc&status=${ALL_STATUS.join()}`;

    if (patientId) {
      url += `&patient-id=${patientId}`;
    }
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  getAwaitingMDTOrders() {
    const url = `${
      this.options.apiEndPoint
    }api/doctor/order-items?page=${0}&size=${10}&sort=creationDate,asc&status=AWAITING_MDT_APPROVAL`;
    return this.http.get<any>(url);
  }

  getSessionMDTOrders() {
    const url = `${this.options.apiEndPoint}api/doctor/order-items?page=0&size=10&sort=creationDate,desc&status=SELECTED_FOR_MDT_SESSION`;
    return this.http.get<any>(url);
  }

  // status
  // ['WAITING_FOR_PHARMACY_APPROVAL',
  // 'REJECTED_BY_PHARMACY',
  // 'PAYMENT_FAILED',
  // 'APPROVED_BY_PHARMACY',
  // 'PROPOSED_BY_DOCTOR',
  // 'DELIVERED',
  // 'BOOKED',
  // 'WAITING_FOR_DOCTOR_APPROVAL',
  // 'CANCELED_BY_PATIENT',
  // 'RESERVED_BY_DOCTOR',
  // 'REJECTED_BY_DOCTOR',
  // 'REJECTED_BY_PATIENT',
  // 'ONGOING',
  // 'APPOINTMENT_COMPLETED',
  // 'CANCELED_BY_DOCTOR',
  // 'DELIVERED']

  // types ['Booking', 'Form', 'TestKit']
  getOrderItems(
    pageNumber = 0,
    pageSize = 100,
    status: string[] = [],
    type: string[] = [],
    patientId: number = null
  ) {
    let url = `${this.options.apiEndPoint}api/doctor/order-items?page=${pageNumber}&size=${pageSize}&sort=creationDate,desc`;
    if (status.length) {
      url += `&status=${status.join()}`;
    }
    if (type.length) {
      url += `&types=${type.join()}`;
    }
    if (patientId) {
      url += `&patient-id=${patientId}`;
    }
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  // ToDo: Need to create type for orders from coop and cast json
  getCompleteOrdersFromCoop(patientId: number = null): Observable<any> {
    const url = `${this.options.apiEndPoint}api/doctor/order-items/coop?patient-id=${patientId}`;
    return this.http.get<any>(url).pipe(map((response) => response.orders));
  }

  getAwaitingDoctorActionOrders(patientId: number = null) {
    let completedStates = [
      'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION',
      'WAITING_FOR_PATIENT_DELIVERY_DETAILS',
      'WAITING_FOR_ADMIN_DELIVERY_DETAILS',
    ];
    return this.getOrderItems(0, 1000, completedStates, [], patientId);
  }

  getCompanyOrders() {
    return this.getOrderItems(0, 1000, [], ['PRESCRIPTION_ONLY']);
  }

  getPrescribingPharmacistOrders() {
    return this.getOrderItems(0, 1000, [], ['PRESCRIPTION_ONLY', 'FORM']);
  }

  confirmAppointmentPrescription(appointmentId: number, pin: string) {
    let url = `${this.options.apiEndPoint}api/doctor/print-prescription/${appointmentId}`;
    return this.http.put<any>(url, { pin: pin });
  }

  getOrderItem(id: number) {
    let url = `${this.options.apiEndPoint}api/doctor/order-item/${id}`;
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  updateOrderItem(id: number, action: string, body: any = null) {
    let url = `${this.options.apiEndPoint}api/doctor/order-item/${id}?action=${action}`;
    return this.http.put<any>(url, body).pipe(map(this.parseGpAddress));
  }

  reserveOrderItem(id: number) {
    return this.updateOrderItem(id, 'reserve');
  }

  updateOrderItemNotes(id: number, additionalNotes: string, pin: string) {
    return this.updateOrderItem(id, 'update-notes', {
      additionalNotes: additionalNotes,
      pin: pin,
    });
  }

  approveOrderItem(
    id: number,
    doctorProposal: string,
    additionalNotes: string,
    alerts: string,
    dosageInstructions: string,
    pin: string
  ) {
    return this.updateOrderItem(id, 'approve', {
      value: doctorProposal,
      additionalNotes: additionalNotes,
      alerts: alerts,
      dosageInstructions: dosageInstructions,
      pin: pin,
    });
  }

  approveOrderItemWithFormulary(
    id: number,
    doctorProposalFormulary: number,
    orderFrequency: number,
    additionalNotes: string,
    alerts: string,
    dosageInstructions: string,
    pin: string
  ) {
    return this.updateOrderItem(id, 'approve', {
      formulary: {
        id: doctorProposalFormulary,
      },
      orderFrequency: orderFrequency,
      additionalNotes: additionalNotes,
      alerts: alerts,
      dosageInstructions: dosageInstructions,
      pin: pin,
    });
  }

  approveOrderItemWithRepeatPrescriptions(
    id: number,
    doctorProposal: string,
    additionalNotes: string,
    alerts: string,
    dosageInstructions: string,
    pin: string,
    frequency: number,
    noOfRepeatsPrescribed: number
  ) {
    return this.updateOrderItem(id, 'approve', {
      value: doctorProposal,
      additionalNotes: additionalNotes,
      alerts: alerts,
      dosageInstructions: dosageInstructions,
      frequency: frequency,
      noOfRepeatsPrescribed: noOfRepeatsPrescribed,
      pin: pin,
    });
  }

  approveOrderItemWithRepeatPrescriptionsWithFormulary(
    id: number,
    doctorProposalFormulary: number,
    additionalNotes: string,
    alerts: string,
    dosageInstructions: string,
    pin: string,
    frequency: number,
    noOfRepeatsPrescribed: number
  ) {
    return this.updateOrderItem(id, 'approve', {
      formulary: {
        id: doctorProposalFormulary,
      },
      additionalNotes: additionalNotes,
      alerts: alerts,
      dosageInstructions: dosageInstructions,
      frequency: frequency,
      noOfRepeatsPrescribed: noOfRepeatsPrescribed,
      pin: pin,
    });
  }

  approveTravelOrderItem(
    id: number,
    doctorProposalMalaria: any,
    doctorProposalVaccines: any,
    additionalNotes: string,
    alerts: string,
    pin: string
  ) {
    return this.updateOrderItem(id, 'approve', {
      antimalarial: doctorProposalMalaria,
      vaccinations: doctorProposalVaccines,
      additionalNotes: additionalNotes,
      alerts: alerts,
      pin: pin,
    });
  }

  completeOrderItem(id: number) {
    return this.updateOrderItem(id, 'complete', null);
  }

  rejectOrderItem(id: number, reason: string, alerts: string, pin: string) {
    return this.updateOrderItem(id, 'reject', {
      additionalNotes: reason,
      alerts: alerts,
      pin: pin,
    });
  }

  downloadDocument(id: number, type: string) {
    let url = `${this.options.apiEndPoint}api/doctor/dl-document/${id}?type=${type}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadPrescription(id: number) {
    return this.downloadDocument(id, 'prescription');
  }

  downloadSickNote(id: number) {
    return this.downloadDocument(id, 'sick-note');
  }

  downloadReferralLetter(id: number) {
    return this.downloadDocument(id, 'referral-letter');
  }

  downloadDocumentByOrderItem(id: number, type: string) {
    let url = `${this.options.apiEndPoint}api/doctor/document/${id}?type=${type}`;
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  downloadPrescriptionByOrderItem(id: number) {
    return this.downloadDocumentByOrderItem(id, 'prescription');
  }

  private parseGpAddress(body: any) {
    if (body && body.preferences && body.preferences.gpAddress) {
      body.preferences.gpAddress = JSON.parse(body.preferences.gpAddress);
    }
    if (
      body &&
      body.patient &&
      body.patient.preferences &&
      body.patient.preferences.gpAddress
    ) {
      body.patient.preferences.gpAddress = JSON.parse(
        body.patient.preferences.gpAddress
      );
    }
    return body;
  }

  getDrugPrice(id: number, quantity: number, pharmacyId: number) {
    let url = `${this.options.apiEndPoint}api/doctor/formulary/charge?id=${id}&qty=${quantity}&pharmacyId=${pharmacyId}`;
    return this.http.get<any>(url);
  }

  getCoupon(coupon: string) {
    let url = `${this.options.apiEndPoint}api/doctor/coupon/${coupon}`;
    return this.http.get<any>(url);
  }

  requestSCRAccess(orderItemId: string, withConsent: boolean) {
    let url = `${this.options.apiEndPoint}api/doctor/hscn/request-scr-access?order-item-id=${orderItemId}&consent=${withConsent}`;
    return this.http.get<any>(url);
  }

  requestSCRAccessForThirdParty(patient) {
    let url = `${this.options.apiEndPoint}api/doctor/hscn/create-legitimate-relationship-for-third-party`;
    let body = {
      dateOfBirth: patient.dateOfBirth,
      firstName: patient.firstName,
      lastName: patient.lastName,
      gender: patient.gender,
    };
    return this.http.put<any>(url, body);
  }

  checkIfPatientIsEnterpriseEmployee(orderItem: Number) {
    let url = `${this.options.apiEndPoint}api/doctor/enterprise-name?order-item-id=${orderItem}`;
    return this.http.get<any>(url).pipe(map(this.parseGpAddress));
  }

  updatePatientSettings(payload: any) {
    let url = `${this.options.apiEndPoint}api/doctor/patient`;
    return this.http.put<any>(url, payload);
  }

  getPatientBirthday(birthday: string) {
    const today = new Date();
    const birthDate = new Date(birthday);
    const monthDiff = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  // deprecated ---------------------------------------------------------------------
  getOrderFiles(orderId): Observable<any> {
    const url = `${this.options.apiEndPoint}api/doctor/images?order=${orderId}`;
    return this.http.get(url);
  }

  getFileBlob(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }
  // ---------------------------------------------------------------------------------

  getMedications(type: string) {
    const url = `${this.options.apiEndPoint}api/public/forms/${type}/medications`;
    return this.http.get<any>(url);
  }

  submitQuestion(question: string, orderItem: number) {
    const url = `${this.options.apiEndPoint}api/doctor/order-item/${orderItem}/ask-question`;
    let body = {
      question: question,
    };
    return this.http.post<any>(url, body);
  }

  addOrderComment(orderId: number, comment: string) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    const body = {
      type: 'DOCTOR_COMMENT',
      description: comment,
    };

    return this.http.post<any>(url, body);
  }

  public getPatientDocuments(patientId: string, action: string): Observable<any> {
    const url = `${this.options.apiEndPoint}api/doctor/patient-document/${patientId}?action=${action}&status=true`;
    return this.http.get<any>(url);
  }
}
