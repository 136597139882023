<div class="container">
	<h1 class="brand-1">Assessments</h1>
	<p>Please find the available assessments below <small class="date_updated" *ngIf="lastUpdated">Last updated = {{ lastUpdated | date: 'y MMM dd HH:mm' }}</small></p>
	<p *ngIf="orderItems?.length > 0">You have {{reservedOrders}} out of {{ordersAllowance}} orders "in Progress"</p>
	<div class="table-container">
		<table class="table table-striped table-hover">
			<thead>
				<tr>
					<th>Id</th>
					<th>Date</th>
					<th>Patient</th>
					<th>Name</th>
					<th>Type</th>
					<th>Status</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of orderItems">
					<td>{{item.id}}</td>
					<td>{{item.creationDate | date:'dd/MM/yyyy HH:mm'}}</td>
					<td>{{item.patient.displayName}}</td>
					<td>{{item.name}}</td>
					<td>{{item.type}}</td>
					<td class="alert alert-{{item.status | orderState}}">{{item.status | orderStatus}}</td>
					<td>
						<button *ngIf="item.status === 'WAITING_FOR_DOCTOR_APPROVAL' && !(reservedOrders>=ordersAllowance)" class="btn btn-primary" (click)="reserveItem(item)">Reserve</button>
						<a *ngIf="item.status === 'RESERVED_BY_DOCTOR' || item.status === 'AWAITING_PATIENT_RESPONSE'" class="btn btn-primary" routerLink="/orders/{{item.id}}">View</a>
					</td>
				</tr>
				<tr *ngIf="searching">
					<td colspan="8" style="padding: 1rem; background: white; text-align: center;">
						<div>Please Wait...</div>
						<i style="font-size: 2rem;" class="fa fa-spinner fa-spin"></i>
					</td>
				</tr>
				<tr *ngIf="!searching && orderItems.length == 0">
					<td colspan="8" style="padding: 1rem; background: white; text-align: center;">(No Items)</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
