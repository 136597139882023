import { HttpClient } from '@angular/common/http';
import { Attribute, Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GlobalOptions } from './app.module';

enum eFormAttributes {
  ROUTE,
  MANUFACTURER,
  BRAND,
  STRAIN,
}

type tFilters = {
  pharmacyId?: number;
  category?: string;
  administrationRoute?: string;
  manufacture?: string;
  brandName?: string;
  strain?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PrescriptionService {
  prescriptions: any[];
  pharmacies: any[];

  // Filtered items for GP and MDT
  filteredRoutes: any[];
  filteredManufacturers: any[];
  filteredBrands: any[];
  filteredStrains: any[];

  // initial list
  pharmacyList: any[] = [];
  thcList = new Set();
  routesList = new Set();
  manufacturerList = new Set();
  brandList = new Set();
  strainList = new Set();
  availableMedicationForSpecialist: any[] = [];
  indexedPrescriptions = {};

  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  // API --------------------------------------------------------------------------------------------
  getAllPrescriptions() {
    return this.http.get<any>(
      `${this.options.apiEndPoint}api/doctor/formularies`
    );
  }

  getAllPharmacies() {
    return this.http.get<any>(
      `${this.options.apiEndPoint}api/doctor/pharmacies`
    );
  }

  async cachePrescriptions() {
    this.prescriptions = [];

    const prescriptions$ = this.getAllPrescriptions();
    const prescriptionResult = await lastValueFrom(prescriptions$);
    const pharmacies$ = this.getAllPharmacies();
    const pharmacyResult = await lastValueFrom(pharmacies$);
    this.pharmacies = pharmacyResult.content;

    const pharmacyIDS: number[] = pharmacyResult.content.map((item) => {
      if (item.active) {
        return item.id;
      }
    });

    this.prescriptions = prescriptionResult.content.reduce((acc, item) => {
      if (item.active && pharmacyIDS.includes(item.pharmacyId)) {
        const pharmacy = this.getPharmacy(item.pharmacyId);
        this.pharmacyList.push({
          id: pharmacy.id,
          name: pharmacy.displayName,
        });
        this.thcList.add(item.category);
        this.routesList.add(item.administrationRoute);
        this.manufacturerList.add(item.manufacture);
        this.brandList.add(item.brandName);
        this.strainList.add(item.strain);

        acc.push(item);
      }
      return acc;
    }, []);

    this.pharmacyList = Object.values(
      this.pharmacyList.reduce((acc, cur) => {
        acc[cur.place + cur.name] = cur;
        return acc;
      }, {})
    );
  }

  // Utilities ---------------------------------------------------------------------------------------
  get formAttributes() {
    return eFormAttributes;
  }

  getPharmacy(id: number) {
    return this.pharmacies.find((item) => item.id === id);
  }

  getAvailableMedications(values: tFilters) {
    if (!values.administrationRoute && !values.brandName && !values.category && !values.manufacture && !values.pharmacyId && !values.strain ) {
      return []
    }
    let filteredPrescriptions = this.prescriptions;
    if (values.pharmacyId) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) =>
          item.pharmacyId == values.pharmacyId &&
          item.stockStatus !== 'OUT_OF_STOCK'
      );
    }
    if (values.administrationRoute) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) => item.administrationRoute === values.administrationRoute &&
        item.stockStatus !== 'OUT_OF_STOCK'
      );
    }
    if (values.brandName) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) => item.brandName === values.brandName &&
        item.stockStatus !== 'OUT_OF_STOCK'
      );
    }
    if (values.category) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) => item.category === values.category &&
        item.stockStatus !== 'OUT_OF_STOCK'
      );
    }
    if (values.manufacture) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) => item.manufacture === values.manufacture &&
        item.stockStatus !== 'OUT_OF_STOCK'
      );
    }
    if (values.strain) {
      filteredPrescriptions = filteredPrescriptions.filter(
        (item) => item.strain === values.strain &&
        item.stockStatus !== 'OUT_OF_STOCK'
      );
    }

    this.thcList.clear();
    this.routesList.clear();
    this.manufacturerList.clear();
    this.brandList.clear();
    this.strainList.clear();

    filteredPrescriptions.forEach((item) => {
      this.thcList.add(item.category);
        this.routesList.add(item.administrationRoute);
        this.manufacturerList.add(item.manufacture);
        this.brandList.add(item.brandName);
        this.strainList.add(item.strain);
    })

    return filteredPrescriptions;
  }

  //funneling process for GP and MDT
  filterRoutes(values: tFilters) {
    const filteredList = [];
    this.filteredRoutes = this.prescriptions.filter((item) => {
      if (item.category === values.category) {
        if (!filteredList.includes(item.administrationRoute)) {
          filteredList.push(item.administrationRoute);
        }
        return true;
      }
    });

    return filteredList;
  }

  filterManufacturers(values: tFilters) {
    const filteredList = [];
    this.filteredManufacturers = this.filteredRoutes.filter((item) => {
      if (item.administrationRoute === values.administrationRoute) {
        if (!filteredList.includes(item.manufacture)) {
          filteredList.push(item.manufacture);
        }
        return true;
      }
    });

    return filteredList;
  }

  filterBrands(values: tFilters) {
    const filteredList = [];
    this.filteredBrands = this.filteredManufacturers.filter((item) => {
      if (item.manufacture === values.manufacture) {
        if (!filteredList.includes(item.brandName)) {
          filteredList.push(item.brandName);
        }
        return true;
      }
    });

    return filteredList;
  }

  filterStrains(values: tFilters) {
    const filteredList = [];
    this.filteredStrains = this.filteredBrands.filter((item) => {
      if (item.brandName === values.brandName) {
        if (!filteredList.includes(item.strain)) {
          filteredList.push(item.strain);
        }
        return true;
      }
    });

    return filteredList;
  }

  filteredMedications(filters: tFilters) {
    return this.filteredStrains.filter((item) => {
      if (item.strain === filters.strain) {
        return true;
      }
    });
  }
}
