import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss'],
})
export class CommentSectionComponent implements OnInit, OnChanges {
  @Input() orderId: number;
  @Input() standard = false;
  @ViewChild('chatContainer') private chatContainer: ElementRef;

  // model for form
  comment = '';

  commentList: any[];

  constructor(
    private toastr: ToastrService,
    private dataService: DataService
  ) {}

  sortByDate = (firstComment, secondComment) => {
    const dateA = firstComment.creationDate;
    const dateB = secondComment.creationDate;
  
    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  };

  fetchComments() {
    this.dataService.getOrderNotes(this.orderId).subscribe({
      next: (value) => {
        const comments = value.orderNotes.filter(
          (note: any) => note.type === 'DOCTOR_COMMENT'
        );
        comments.sort(this.sortByDate);
        this.commentList = comments;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  addComment(commentForm: NgForm) {
    if (commentForm.invalid) {
      this.toastr.warning('', 'Write a comment first!');
      return;
    }

    this.dataService
      .addOrderComment(this.orderId, commentForm.value.comment)
      .subscribe({
        next: () => {
          this.fetchComments()
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          commentForm.resetForm();
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderId) {
      this.fetchComments();
    }
  }

  ngOnInit(): void {
  }
}
