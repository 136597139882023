<div class="session-item" [class]="expanded ? 'expanded' : ''">
    <div class="header">
        <div class="order-details">
            <p><span>#{{order.id}}: </span> <span> {{order.patient.displayName}}</span> consulted by
                <span>{{order.doctor.displayName}}</span> on
                <span>{{order.appointment.datetime| date : 'dd-MMM-yyyy'}}</span>
            </p>
        </div>
        <div class="action">
            <ng-container
                *ngIf="(order.status === 'APPROVED_BY_MDT_CHAIR' || order.status === 'REJECTED_BY_MDT_CHAIR' || order.status === 'AWAITING_MDT_SESSION_APPROVAL')">
                <span class="label label-primary" *ngIf="getOrderStatus(order.orderNotes)">Approved</span>
                <span class="label label-danger" *ngIf="!getOrderStatus(order.orderNotes)">Rejected</span>
            </ng-container>

            <ng-container *ngIf="order.status === 'SELECTED_FOR_MDT_SESSION' && sessionView">
                <span class="label label-warning">Yet to be reviewed</span>
                <button *ngIf="sessionOwner" class="btn btn-danger" (click)="removeFromSession(order.id)">Remove
                    from session</button>
            </ng-container>

            <button class="btn expand-btn" (click)="expanded = !expanded">
                <img src="/assets/vectors/expand.svg" alt="">
            </button>
        </div>
    </div>

    <div class="content">
        <section class="notes-section">
            <div>
                <div class="note-header">
                    <div class="step-point"></div>
                    <h4>{{(order.status === 'APPROVED_BY_MDT_CHAIR' || order.status === 'REJECTED_BY_MDT_CHAIR' ||
                        order.status === 'AWAITING_MDT_SESSION_APPROVAL') ? "Reviewed information" : "Information to be reviewed"}}
                    </h4>
                </div>
                <div class="order-content row-gap">
                    <button class="btn btn-default" (click)="viewPatientNotes.show()">View patient filled forms</button>
                    <button class="btn btn-default" (click)="modalService.openModal('gpNotes')">View GP Notes</button>
                    <button *ngIf="selectedGPRecommendation !== undefined" class="btn btn-default second-row"
                        (click)="viewGPRecommendations.show()">View GP Recommendations</button>
                    <span *ngIf="selectedGPDecision.description" class="full-row">Approved:
                        {{selectedGPDecision.description}}</span>
                </div>
            </div>
            <ng-container *ngIf="sessionView">
                <hr
                    *ngIf="(order.status === 'APPROVED_BY_MDT_CHAIR' || order.status === 'REJECTED_BY_MDT_CHAIR' || order.status === 'AWAITING_MDT_SESSION_APPROVAL') || sessionOwner">
                <div *ngIf="order.status !== 'SELECTED_FOR_MDT_SESSION'">
                    <div class="note-header">
                        <div class="step-point"></div>
                        <h4>MDT Remarks</h4>
                    </div>
                    <div class="order-content row-gap">
                        <button *ngIf="selectedMDTRecommendation !== undefined" class="btn btn-default"
                            (click)="viewMDTRecommendations.show()">View MDT Recommendations</button>
                        <span *ngIf="selectedMDTDecision.description" class="full-row">{{selectedMDTDecision.approved ?
                            "Approved" : "Rejected"}}: {{selectedMDTDecision.description}}</span>
                    </div>
                </div>
                <div *ngIf="(order.status === 'SELECTED_FOR_MDT_SESSION') && sessionOwner">
                    <div class="note-header">
                        <div class="step-point"></div>
                        <h4>Add Remarks</h4>
                    </div>
                    <div class="order-content row-gap">
                        <button class="btn btn-default" (click)="prescriptionModal.show()" type="button">
                            Add Recommendations
                        </button>
                        <div class="full-row second-row">
                            <app-decision-form [isSubmitting]="isSubmitting" [orderId]="order.id" (addDescision)="setupDescision($event)">
                            </app-decision-form>
                        </div>
                    </div>
                </div>
            </ng-container>
        </section>

        <section *ngIf="expanded" class="comment-section-container">
            <app-comment-section [orderId]="order.id"></app-comment-section>
        </section>
    </div>
</div>

<div bsModal #prescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="prescriptionModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Draft Recommendations</h4>
            </div>
            <div class="modal-body">
                <app-add-prescription (hide)="prescriptionModal.hide()">
                </app-add-prescription>
            </div>
        </div>
    </div>
</div>

<app-gp-notes-modal *ngIf="!!order" [orderstatus]="order.status" [orderId]="order.id"></app-gp-notes-modal>

<div bsModal #viewPatientNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <app-patient-notes-modal [orderNotes]="order.orderNotes" (hideModal)="viewPatientNotes.hide()">
    </app-patient-notes-modal>
</div>

<div bsModal #viewGPRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <ng-container *ngIf="selectedGPRecommendation !== undefined">
        <app-recommendation-modal [title]="'GP'" [recommendation]="selectedGPRecommendation"
            (hideModal)="viewGPRecommendations.hide()">
        </app-recommendation-modal>
    </ng-container>
</div>

<div bsModal #viewMDTRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <ng-container *ngIf="selectedMDTRecommendation !== undefined">
        <app-recommendation-modal [title]="'GP'" [recommendation]="selectedMDTRecommendation"
            (hideModal)="viewMDTRecommendations.hide()">
        </app-recommendation-modal>
    </ng-container>
</div>