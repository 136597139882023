import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, NgForm } from '@angular/forms';
import { proformaQuestions } from 'src/assets/proforma';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MdtService } from './mdt.service';
import { UserService } from '../account/user.service';
import {
  AUDIT_FORM,
  GAD_FORM,
  PEG_FORM, SIDE_EFFECT_FORM,
} from 'src/assets/patientQuestionnaires';
import { Location } from '@angular/common';
import { OrderNotesService } from '../order-notes.service';
import { ModalService } from '../components/modals/modal.service';

@Component({
  selector: 'app-mdt-oder-review',
  templateUrl: './mdt-order-review.component.html',
  styleUrls: ['./mdt-order-review.component.scss'],
})
export class MdtOrderReviewComponent implements OnInit {
  @ViewChild('proformaModal') proformaModal: ModalDirective;
  @ViewChild('prescriptionModal') prescriptionModal: ModalDirective;
  @ViewChild('gadModal') gadModal: ModalDirective;
  @ViewChild('pegModal') pegModal: ModalDirective;
  @ViewChild('auditModal') auditModal: ModalDirective;
  proformaQuestionGroup: FormGroup;

  public orderItem: any;
  public patient: any;
  private orderId: number;
  public gpRecommendations: any;
  public orderNotes: any;
  public gpNote: any;
  public readRecommendationsAndNotes: boolean = false;
  public gc_q_1: boolean;
  public gc_q_2: boolean;
  public gc_q_3: boolean;
  public gc_q_4: boolean;
  public ca_q_1: string;
  public ca_q_2: boolean;
  public ca_q_3: string;
  public ca_q_4: boolean;
  public ca_q_5: boolean;
  public ca_q_6: boolean;
  public ca_q_7: boolean;
  public ca_q_8: boolean;
  public ca_q_9: boolean;
  public ca_q_10: string = '';
  public signOff: boolean = false;
  public reason: string = '';
  public proformaCompleted: boolean = false;
  public approved: boolean;
  public rejectReason = '';
  public recommendationAdded: boolean = false;
  public initLoading: boolean;
  public gpRecommendation: any;
  public patientComplaint: any;

  public gadFormValues: any;
  public pegFormValues: any;
  public sideEffectFormValues: any;
  public auditFormValues: any;
  public changePrescriptionReason: any;

  private proformaValues: any;
  private recommendationValues: any;
  private session: any;
  sessionExist = false;
  repeatOrderQuestionnaire: any;
  SelectedSpecialist = '';
  specialist = [];

  constructor(
    protected dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private mdtService: MdtService,
    private router: Router,
    private userService: UserService,
    private location: Location,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {
    activatedRoute.params.subscribe((params) => {
      this.orderId = params['orderId'];
    });
  }

  handleSubmitProforma(form: NgForm) {
    if (!this.signOff) {
      this.toastr.warning('', 'Please fill all the empty fields');
      return;
    }

    let values = {};
    Object.keys(form.value).forEach((key: string) => {
      if (key) {
        values = {
          ...values,
          [key]: {
            question: proformaQuestions[key]?.title,
            answer: form.value[key],
          },
        };
      }
    });

    this.proformaValues = values;
    this.proformaCompleted = true;
    this.proformaModal.hide();
  }

  handleUpdateRecommendation(event) {
    this.recommendationValues = event;
    this.recommendationAdded = true;
  }

  handleSubmitReview() {
    const proformaData = {
      type: 'MDT_PROFORMA',
      questionAnswer: JSON.stringify(this.proformaValues),
    };

    const decisionData = {
      type: 'MDT_DECISION',
      approved: this.approved,
      description: this.rejectReason,
    };

    const forkContent = [
      this.mdtService.saveProforma(this.orderId, proformaData),
      this.mdtService.saveMDTDecision(this.orderId, decisionData),
    ];

    if (this.approved) {
      const recommendationData = {
        type: 'MDT_RECOMMENDATION',
        formulary: JSON.stringify(this.recommendationValues),
      };
      forkContent.push(
        this.mdtService.saveMDTRecommendation(this.orderId, recommendationData)
      );
    }

    forkJoin(forkContent).subscribe({
      next: (result) => {
        this.router.navigate(['mdt-orders']);
      },
    });
  }

  loadPatientComplaint(item) {
    this.patientComplaint = item.orderNotes.find((note) => note.type === 'PRESENTING_COMPLAINT')?.description
  }

  private refresh() {
    forkJoin([
      this.dataService.getOrderItem(this.orderId),
      this.mdtService.getActiveSession(),
    ]).subscribe({
      next: (result) => {
        this.dataService.getPatient(result[0].patient.id).subscribe((patient) => {
          this.patient = patient;
          this.SelectedSpecialist = patient?.careSpecialist ? patient?.careSpecialist : '';
        });
        const item = result[0];
        this.orderItem = item;
        this.orderNotes = item.orderNotes;
        this.gc_q_1 = item.patient.eligibilityQuestionnaire.length > 0;
        this.gc_q_2 = item.patient.eligibilityQuestionnaire.length > 0;
        this.gc_q_3 = item.patient.eligibilityQuestionnaire.length > 0;
        this.gc_q_4 = item.patient.eligibilityQuestionnaire.length > 0;

        this.gpRecommendation = this.orderNotesService.getGPRecommendation(item.orderNotes);
        this.gpNote = this.orderNotesService.getGPConsultationNote(item.orderNotes);
        const mdtDecision = this.orderNotesService.getMDTDecision(item.orderNotes);
        this.pegFormValues = this.orderNotesService.getPEGNote(item.orderNotes);
        this.gadFormValues = this.orderNotesService.getGADNote(item.orderNotes);
        this.sideEffectFormValues = this.orderNotesService.getSideEffectNote(item.orderNotes);
        this.auditFormValues = this.orderNotesService.getAUDITNote(item.orderNotes);
        this.repeatOrderQuestionnaire = this.orderNotesService.getRepeatOrderQuestionnaire(item.orderNotes);

        if (item.parentOrderItemId) {
          const reasonNote = this.orderNotesService.getRepeatOrderChangeReason(item.orderNotes);
          if (reasonNote) {
            this.changePrescriptionReason = reasonNote.descriptionKey;
            if (reasonNote.description) {
              this.changePrescriptionReason = `${reasonNote?.descriptionKey} - ${reasonNote.description}`;
            }
          }
        }

        this.loadPatientComplaint(item)

        this.rejectReason = mdtDecision?.description || null;


        this.readRecommendationsAndNotes =
          item.status === 'AWAITING_MDT_SESSION_COMPLETION';

        this.approved =
          item.status === 'AWAITING_MDT_SESSION_COMPLETION'
            ? mdtDecision?.approved
            : null;

        this.recommendationAdded =
          item.status === 'AWAITING_MDT_SESSION_COMPLETION';

        const activeSession = result[1];
        this.session = activeSession;
        if (
          'active' in activeSession ||
          this.userService.user.id !== activeSession.reporter.id
        ) {
          this.initLoading = false;
          return;
        }
        this.sessionExist = true;
        if (item.status === 'AWAITING_MDT_SESSION_INITIATION') {
          const passingData = {
            id: item.id,
          };
          this.mdtService
            .addOrderToSession(activeSession.id, passingData)
            .subscribe({
              next: (value) => {
                this.refresh();
              },
            });
        } else {
          this.initLoading = false;
        }
      },
    });
  }

  routeToOrderPreviewPage() {
    const angularRoute = this.location.path();
    const url = window.location.href;
    const appDomain = url.replace(angularRoute, '');
    const routeUrl = `${appDomain}/order-preview/${this.orderItem.parentOrderItemId}`;
    window.open(routeUrl, '_blank');
  }

  ngOnInit() {
    this.initLoading = true;
    this.refresh();
    this.getSpecialists();
  }

  getSpecialists() {
    this.mdtService.getSpecialists().subscribe(res => {
      if(res.length > 0){
        for(let i=0; i<res.length; i++){
          this.specialist.push(res[i].displayName);
        }
      }
    })
  }

  routeToPatientHistory(){
    const routeUrl = `${location.origin}/patient/${this.patient.id}`;
    window.open(routeUrl);
  }

  getErrorMessage(error: any) {
    return (
      error.error?.message || error.message || 'An unexpected error occurred'
    );
  }

  getPEGAverage(average: string | number){
    if (typeof average === 'string') {
      average = Math.round(parseFloat(average));
      return average;
    }

    return Math.round(average);
  }

  handleSpecialistChange(event: any) {
    this.mdtService.updateSpecialist(this.patient.id, event).subscribe(res => {
      this.SelectedSpecialist = event;
      if(res?.message){
        this.toastr.success(res.message);
      }
    })
  }

  openPrescriptionModal() {
    if(this.SelectedSpecialist == "" && !this.orderItem.isRepeatOrder){
      this.toastr.error("Please select a specialist to continue.");
    }
    else {
      this.prescriptionModal.show();
    }
  }

  get user() {
    return this.userService.user;
  }

  get auditForm() {
    return AUDIT_FORM;
  }

  get pegForm() {
    return PEG_FORM;
  }

  get gadForm() {
    return GAD_FORM;
  }

  get sideEffectForm(): any {
    return SIDE_EFFECT_FORM;
  }
}
