import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DocumentState } from './document';
import { DataService } from '../data.service';

@Component({
    selector: 'app-add-referral-letter',
    templateUrl: './add-referral-letter.component.html',
    styleUrls: ['./add-referral-letter.component.scss'],
})
export class AddReferralLetterComponent {

    @Input() doctorNote: any;
    @Input() referralLetterId: number;
    @Output() documentStateChanged = new EventEmitter();
    @Output() hide = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() delete = new EventEmitter();

    model: any = {};
    referralLetterState = 'initial';

    constructor(private dataService: DataService) { }

    ngOnInit() {
        if (this.doctorNote.referralLetterId) {
            this.dataService.getReferralLetter(this.doctorNote.appointment.id).subscribe(referralLetter => {
                var parsedReferralLetter = JSON.parse(referralLetter.text);
                this.model.reason = parsedReferralLetter.reason;
                this.model.speciality = parsedReferralLetter.speciality;
            })
        }
    }
    
    onSubmit() {
        this.save.emit(this.model);
        this.referralLetterState = 'initial';
        this.model.pin = '';
        this.documentStateChanged.emit(DocumentState.Completed);
    }
    
    onDelete() {
        this.delete.emit(this.model);
        this.referralLetterState = 'initial';
        this.model.pin = '';
    }
    
    resetReferralLetter() {
        this.model.speciality = '';
        this.model.reason = '';
    }

    moveToCanceledState () {
        this.dataService.getReferralLetter(this.doctorNote.appointment.id).subscribe(
        referralLetter => {
            this.referralLetterId = referralLetter.id;
        },
        error => {
            delete this.referralLetterId;
        });
        this.referralLetterState = 'canceled';
    }

    public isValid() {
        this.dataService.getReferralLetter(this.doctorNote.appointment.id).subscribe(referralLetter => {
            let serverVersionReferralLetter = JSON.parse(referralLetter.text);

            if (this.model.reason !== serverVersionReferralLetter.reason || this.model.speciality !== serverVersionReferralLetter.speciality) {
                this.documentStateChanged.emit(DocumentState.Updated);
                return;
            }

            this.documentStateChanged.emit(DocumentState.Completed);
        });
    }

}
