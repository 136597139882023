import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SPECIALIST_PAST_ORDERS } from 'src/assets/statusMappings';
import { GlobalOptions } from '../app.module';
import {Observable} from 'rxjs';

type tSpecialistMessage = {
  question: string;
  answer?: string;
};

@Injectable({
  providedIn: 'root',
})
export class SpecialistService {
  constructor(
    private http: HttpClient,
    @Inject('global_options') private options: GlobalOptions
  ) {}

  public getAllOrders(searchType: string, searchValue: string, page: number): Observable<any> {
    let url: any = `${this.options.apiEndPoint}api/doctor/order-items?page=${page}&size=10&sort=creationDate,desc&status=AWAITING_SPECIALIST_CALL_PATIENT,AWAITING_SPECIALIST_PRESCRIPTION_CREATION,WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_NO_CHANGES,WAITING_FOR_PINK_PRESCRIPTION_CONFIRMATION_WITH_PATIENT_APPROVAL,AWAITING_PATIENT_SPECIALIST_ALERT_REPLY,REJECTED_BY_PHARMACY_AWAITING_SPECIALIST_ALTERATION`;

    // Check the searchValue and change the url accordingly
    if (!!searchValue) {
      url = `${url}&searchValue=${searchValue}&searchType=${searchType}`;
    }

    return this.http.get<any>(url);
  }

  getPastOrders(page: number = 0) {
    return this.http.get<any>(
      `${
        this.options.apiEndPoint
      }api/doctor/order-items?page=${page}&size=10&sort=creationDate,desc&status=${SPECIALIST_PAST_ORDERS.join()}`
    );
  }

  public getPharmacyWiseOrders(): Observable<any> {
    return this.http.get<any>(
      `${this.options.apiEndPoint}api/doctor/orders/waiting-for-tracking-code`
    );
  }

  getPrescription(orderId: number | string) {
    return this.http.get<any>(
      `${this.options.apiEndPoint}api/doctor/document/${orderId}?type=prescription`
    );
  }

  createSpecialistCalledPatient(orderId: number | string, proforma: string) {
    const data = {
      type: 'SPECIALIST_CALL_PATIENT',
      approved: true,
      description: '',
      questionAnswer: proforma,
    };
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  createSpecialistMessagePatient(orderId: number | string, value: tSpecialistMessage) {
    const data = {
      type: 'SPECIALIST_PATIENT_MESSAGE',
      questionAnswer: JSON.stringify(value),
    };
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  createPrescription(data) {
    const url = `${this.options.apiEndPoint}api/doctor/prescription`;
    return this.http.post<any>(url, data);
  }

  updatePrescription(data, id: number): Observable<any> {
    const url = `${this.options.apiEndPoint}api/doctor/prescription/update?orderId=${id}`;
    return this.http.put<any>(url, data);
  }

  uploadPinkPrescription(orderId, data) {
    const url = `${this.options.apiEndPoint}api/doctor/file-upload?order=${orderId}`;
    return this.http.post<any>(url, data);
  }

  uploadMobilePinkPrescription(orderId, userId, data) {
    const url = `${this.options.apiEndPoint}api/public/file-upload?order=${orderId}&action=PINK_PRESCRIPTION_UPLOAD&userId=${userId}`;
    return this.http.post<any>(url, data);
  }

  createDecision(orderId, data) {
    const url = `${this.options.apiEndPoint}api/doctor/order/${orderId}/order-notes`;
    return this.http.post<any>(url, data);
  }

  addTrackingNumber(data) {
    const url = `${this.options.apiEndPoint}api/doctor/orders/tracking-code`;
    return this.http.post<any>(url, data);
  }
}
