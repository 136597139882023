import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';
import { SpecialistService } from './specialist.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  pinkPrescription: any;
  prescriptionPlaceholder: string;
  thumbnail: any;
  orderId: any;
  userId: any;
  uploadToken: any;
  btnDisabled = false;

  private fileSize = 0;

  constructor(
    private specialistService: SpecialistService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {
    this.orderId = activatedRoute.snapshot.queryParams['orderId'];
    this.userId = activatedRoute.snapshot.queryParams['userId'];
    this.uploadToken = activatedRoute.snapshot.queryParams['uploadToken'];
  }

  openFileUploader() {
    this.fileInput.nativeElement.click();
  }

  updatePinkPrescriptions($event) {
    this.pinkPrescription = undefined;
    this.thumbnail = undefined;
    this.pinkPrescription = $event.currentTarget.files;
    this.setupPinkPlaceholder(this.pinkPrescription.length);
    this.fileSize = 0;

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const file = $event.currentTarget.files[0];
    this.fileSize = (file.size / 1024) / 1024;
    toBase64(file).then((value) => {
      this.thumbnail = value;
    });
  }

  uploadImages() {
    const referenceInput:HTMLInputElement = document.getElementById("reference") as HTMLInputElement;
    if (this.fileSize >= 25) {
      this.toastr.warning('', 'Image(s) total should be less than 25mb');
      return;
    }

    if (!referenceInput.value) {
      this.toastr.warning('', 'Please add reference number to the selected image');
      return;
    }

    this.btnDisabled = true;
    const formData = new FormData();
    for (let index = 0; index < this.pinkPrescription.length; index++) {
      formData.append('files', this.pinkPrescription[index]);
    }
    formData.append('userType', 'DOCTOR');
    formData.append('token', this.uploadToken);
    formData.append('reference', referenceInput.value);

    this.specialistService
      .uploadMobilePinkPrescription(this.orderId, this.userId, formData)
      .subscribe({
        next: () => {
          this.toastr.success('', 'Uploaded image successfully!');
        },
        error: (err) => {
          this.toastr.error('', 'Could not upload prescription image');
        },
      });
  }

  ngOnInit(): void {
    this.prescriptionPlaceholder = 'No files selected';
  }

  setupPinkPlaceholder(count: number) {
    if (count === 0) {
      this.prescriptionPlaceholder = 'No files selected';
    }
    if (count === 1) {
      this.prescriptionPlaceholder = this.pinkPrescription[0].name;
    }
  }
}
