<div bsModal #patientDocumentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="patientDocumentModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Patient Documents</h4>
      </div>
      <div class="modal-body">
        <h5 style="text-align: center" *ngIf="documents.length === 0">No patient documents.</h5>
        <div *ngFor="let document of documents">
          <div *ngIf="document.status" style="padding: 5px; margin-bottom: 5px">
            <div class="document-name-container">
              <h4>{{document?.category !== null ? document.category : 'N/A'}}</h4>
            </div>
            <div *ngIf="document.contentType !== 'application/pdf'" class="img-preview">
              <img [src]="document.url" alt="">
            </div>
            <div *ngIf="document.contentType === 'application/pdf'" class="img-preview">
              <a [href]="document.url" target="_blank" rel="noopener noreferrer">
                Open PDF
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
