<div bsModal #keyAlertModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="keyAlertModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">key alerts</h4>
            </div>
            <div class="modal-body">
                <form #alertForm="ngForm">
                    <div class="action-container">
                        <button *ngIf="editNote" class="btn btn-primary" (click)="handlePatientAlerts(false)"   [disabled]="isLoading">Save Key Alerts</button>
                        <button *ngIf="!editNote" class="btn btn-danger" (click)="handlePatientAlerts(true)">Edit Key Alerts</button>
                        <div *ngIf="isLoading" class="loading-container">
                            <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
                        </div>
                    </div>
                  <label style="margin-top: 5px; margin-bottom: 5px">Admin Alerts</label>
                  <textarea class="form-control" name="adminAlertText" [(ngModel)]="adminAlertText" required rows="5" [disabled]="true"></textarea>
                  <label style="margin-top: 5px; margin-bottom: 5px">Doctor Alerts</label>
                  <textarea class="form-control" name="doctorAlertText" [(ngModel)]="doctorAlertText" required rows="5" [disabled]="!editNote"></textarea>
                  <div>
                    <p *ngIf="currentWordCount >= maxWordCount" style="color: red;">You have reached the maximum word count.</p>
                    <p><span>{{ currentWordCount }}</span> / {{ maxWordCount }}</p>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>
