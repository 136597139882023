import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-pharmacy-consultation-note-modal',
  templateUrl: './pharmacy-consultation-note-modal.component.html',
  styleUrls: ['./pharmacy-consultation-note-modal.component.scss']
})
export class PharmacyConsultationNoteModalComponent implements OnInit, OnDestroy {
  @Input() gpNote: any;
  @ViewChild('viewPharmacyConsultationNotes') modal: ModalDirective;
  
  private modalEventSubscription: Subscription;
  
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === 'pharmacyConsultationNotes') {
        this.modal.show();
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }

}
