import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-consultation-notes-modal',
  templateUrl: './consultation-notes-modal.component.html',
  styleUrls: ['../shared.styles.scss'],
})
export class ConsultationNotesModalComponent implements OnInit, OnDestroy {
  @Input() notes: any[];
  @Input() modalId: string = 'consultationNotes';
  @ViewChild('consultationNotes') modal: ModalDirective;

  private modalEventSubscription: Subscription;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === this.modalId) {
        this.modal.show();
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }
}
