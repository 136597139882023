export enum feedbackType {
    text,
    checkboxAndText,
    checkboxQuestion,
    checkbox,
    externalForm,
    choiceAndTextOnCondition,
    choice,
  }
  
  export enum modalType {
    PEG, GAD, AUDIT, PERSONAL
  }
  
  export type pharmacyQuestionType = {
    active: boolean;
    question?: string;
    questionId?: string;
    header?: string;
    headerId?: string;
    description?: string
    renderPharmacistComment?: boolean;
    renderPatientComment?: boolean
    responseType?: feedbackType;
    choices?: {
      label: string;
      id: string;
      supportDescription?: {
        description: string;
        points?: string[]
      }
    }[];
    supportDescription?: {
      description: string;
      points?: string[]
    };
    renderCondition?: {
      stepId: string;
      questionId: string;
      value: boolean | string
    };
    inputRenderCondition?: boolean | object;
    modalDetails?: {
      openModal: boolean;
      formType: modalType
    }
  };
  
  type Step = {
    title: string;
    description?: string;
    keys: string[];
    data: Record<string, pharmacyQuestionType>;
  };
  
  type questionnaireStructure = {
    urlParam: string[];
    data: Record<string, Step>;
  };
  
  export const pharmacyQuestionnaire: questionnaireStructure = {
    urlParam: ['step_1', 'step_2', 'step_3', 'step_4', 'step_5', 'step_6', 'step_7'],
    data: {
      'step_1': {
        title: 'Triage',
        keys: ['q_1', 'q_2', 'q_3', 'q_4', 'q_5', 'q_6'],
        data: {
          q_1: {
            question: 'What is your occupation?',
            questionId: 'q_1',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
          q_2: {
            question:
              'For what condition(s) and resultant symptoms are you hoping to be treated?',
            questionId: 'q_2',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
          q_3: {
            question:
              'Does your occupation involve the performance of skilled tasks, whether manual or cognitive?',
            questionId: 'q_3',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
          q_4: {
            question:
              'Are your symptoms stable, getting better, or getting worse?',
            questionId: 'q_4',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
          q_5: {
            question:
              'What treatments have you tried to date for managing your pain symptoms?',
            questionId: 'q_5',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
          q_6: {
            question: 'Please provide details of any investigations diagnostics',
            questionId: 'q_6',
            renderPharmacistComment: true,
            responseType: feedbackType.text,
            active: true,
          },
        },
      },
      'step_2': {
        title: 'PEG & Medical History',
        keys: ['h_1', 'q_7', 'h_2', 'q_8', 'q_9'],
        data: {
          h_1: {
            active: true,
            header: 'PEG',
            headerId: 'h_1',
          },
          q_7: {
            active: true,
            question: 'Fill PEG Form',
            questionId: 'q_7',
            responseType: feedbackType.externalForm,
            modalDetails: {
              openModal: true,
              formType: modalType.PEG
            }
          },
          h_2: {
            active: true,
            header: 'Medical History',
            headerId: 'h_2',
          },
          q_8: {
            active: true,
            question:
              'Have you ever had medical problems affecting the following (Select all that apply)',
            questionId: 'q_8',
            responseType: feedbackType.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
              { label: 'Heart', id: 'heart' },
              { label: 'Lungs', id: 'lungs' },
              { label: 'GIT', id: 'git' },
              { label: 'Eyes', id: 'eyes' },
              { label: 'Skin', id: 'skin' },
              { label: 'Brain', id: 'brain' },
              { label: 'Liver', id: 'liver' },
              { label: 'Kidney', id: 'kidney' },
              { label: 'Anything else', id: 'others' },
              { label: 'None of the above', id: 'none' },
            ],
          },
          q_9: {
            active: true,
            question: "Are there any other medical conditions that you have which I don’t know about?",
            questionId: "q_9",
            responseType: feedbackType.text,
            renderPharmacistComment: true,
          },
        },
      },
      'step_3': {
        title: "Medication History and Medical Cannabis Experience",
        description: "As we move on to the next section, we want to learn more about your medication history. We will also be discussing any previous experiences you may have had with medical cannabis this information will help us to optimise your treatment plan . Whether you are new to medical cannabis or have had previous experiences, your insights will be important in this process. Please provide responses to the best of your ability, keeping in mind that both experiences and no experiences with medical cannabis are valuable for us to know.",
        keys: ["h_3", "q_10", "q_11", "q_12", "q_13", "h_4", "q_14", "h_5", "q_15", "q_16", "h_6", "q_17", "h_7", "q_18", "h_8", "q_19", "h_9", "q_20", "q_21"],
        data: {
          h_3: {
            active: true,
            header: "1. Medication History",
            headerId: "h_3"
          }, 
          q_10: {
            active: true,
            question: "What current medication are you taking?",
            questionId: "q_10",
            responseType: feedbackType.text,
            renderPharmacistComment: true
          }, 
          q_11: {
            active: true,
            question: "Have you ever been prescribed any of the following medications?",
            questionId: "q_11",
            responseType: feedbackType.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
              { label: 'PreGabalin', id: 'preGabalin' },
              { label: 'Codeine', id: 'codeine' },
              { label: 'Morphine', id: 'morphine' },
              { label: 'Oxycodone', id: 'oxycodone' },
              { label: 'Oxycontin', id: 'oxycontin' },
              { label: 'Tapentadol', id: 'tepantadol' },
              { label: 'Fentanyl or Buprenoprphine Patch', id: 'fentanyl' },
              { label: 'Tramadol', id: 'tramadol' },
              { label: 'Co-Codamol', id: 'codamol' },
              { label: 'Gabapentin', id: 'gabapentin' },
              { label: 'Amitriptyline', id: 'amitriptyline' },
              { label: 'Duloxetine', id: 'duloxetine' },
              { label: 'Other', id: 'other' },
              { label: 'None of the above', id: 'none' },
            ]
          }, 
          q_12: {
            active: true,
            question: "Have you used any over-the-counter (OTC) medications, especially pain killers, regularly in the last year?",
            questionId: "q_12",
            responseType: feedbackType.choiceAndTextOnCondition,
            renderPharmacistComment: true
          }, 
          q_13: {
            active: true,
            question: "Are you taking any food supplements or herbal remedies?",
            questionId: "q_13",
            responseType: feedbackType.text,
            renderPharmacistComment: true
          }, 
          h_4: {
            active: true,
            header: "2. Medical Cannabis and Specialist Care",
            headerId: "h_4"
          }, 
          q_14: {
            active: true,
            question: "Are you currently under the care of a specialist for the condition you are requesting medical cannabis for?",
            questionId: "q_14",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "The Hospital they are being treated at",
                "The name of the specialist (sometimes the patient will not know this as specialist teams will often have trainees seeing the patient regularly.",
                "How long have they been under the care of the specialist",
                "When were they last seen (can be an estimate, not exact date)",
                "When is their next appointment (can be an estimate, not exact date)"
              ]
            }
          }, 
          h_5: {
            active: true,
            header: "3. Past and Current Prescriptions",
            headerId: "h_5"
          }, 
          q_15: {
            active: true,
            question: "Have you previously or are you currently prescribed medical cannabis?",
            questionId: "q_15",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "Which clinic are you being seen at?",
                "How long have you been with them?",
                "When was your last cannabis prescription issued?"
              ]
            }
          }, 
          q_16: {
            active: true,
            question: "Have you used cannabis within the last year?",
            questionId: "q_16",
            responseType: feedbackType.choice,
          }, 
          h_6: {
            active: true,
            header: "4. Cannabis Consumption Methods",
            headerId: "h_6",
            renderCondition: {
              stepId: "step_3",
              questionId: "q_16",
              value: true
            }
          }, 
          q_17: {
            active: true,
            question: "Can you please specify the method(s) you used to take cannabis? (Select all that apply)",
            questionId: "q_17",
            responseType: feedbackType.checkboxAndText,
            renderCondition: {
              stepId: "step_3",
              questionId: "q_16",
              value: true
            },
            choices: [
              { label: 'Oil', id: 'oil' },
              { label: 'Flower', id: 'flower' },
              { label: 'Smoke', id: 'smoke' },
              { label: 'Vape', id: 'vape' },
              { label: 'Oral', id: 'oral' },
              { label: 'Other', id: 'other' },
            ],
            supportDescription: {
              description: "Gather below information for each selected item",
              points: [
                "The strain name of the cannabis (the patient may not know this, but helpful if they do, and important to know)",
                "How much are they consuming every day?",
                "Do they need the cannabis daily or as required? How often?"
              ]
            }
          }, 
          h_7: {
            active: true,
            header: "5. Contraception Understanding",
            headerId: "h_7"
          }, 
          q_18: {
            active: true,
            question: "I understand that should I be prescribed medical cannabis I require an effective means of contraception?",
            questionId: "q_18",
            responseType: feedbackType.choiceAndTextOnCondition,
          }, 
          h_8: {
            active: true,
            header: "6. Healthcare Professional's Advice",
            headerId: "h_8",
          }, 
          q_19: {
            active: true,
            question: "Has a healthcare professional ever advised that you should not take cannabis?",
            questionId: "q_19",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "Which healthcare professional advised against it?",
                "Why did they advise against it?",
              ]
            }
          }, 
          h_9: {
            active: true,
            header: "7. Allergic Reactions",
            headerId: "h_9"
          }, 
          q_20: {
            active: true,
            question: "Have you ever had an allergic or hypersensitivity reaction to cannabis or cannabis-related products?",
            questionId: "q_20",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "When was the reaction?",
                "How did you react to it?",
              ]
            }
          },
          q_21: {
            active: true,
            question: "Are you allergic to any other medications or substances, including nuts, seeds, and oils?",
            questionId: "q_21",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "What do you react to?",
                "How do you react to it?",
                "Have you ever been hospitalized for an allergic reaction?",
                "Do you carry an Epi-pen?",
              ]
            }
          }
        }
      },
      'step_4': {
        title: "Mental Health History and Lifestyle Choices",
        description: "We are now moving to a section that inquires about your mental health history and your lifestyle choices, such as alcohol and tobacco usage. This information is vital to comprehensively evaluate your suitability for medical cannabis treatment. Please rest assured, all your information will remain confidential.",
        keys: ["h_10", "q_22", "h_11", "q_23", "h_12", "q_24", "q_25" ],
        data: {
          h_10: {
            active: true,
            header: "1. Mental Health Diagnoses",
            headerId: "h_10"
          },
          q_22: {
            active: true,
            question: "Have you ever been diagnosed with any of the following? (Please select all that apply)",
            questionId: "q_22",
            responseType: feedbackType.checkboxAndText,
            renderPharmacistComment: true,
            renderPatientComment: false,
            choices: [
              { label: 'Personality disorder', id: 'personality' },
              { label: 'Schizophrenia or Psychosis', id: 'schizophrenia' },
              { label: 'Depression', id: 'depression' },
              { label: 'PTSD', id: 'ptsd' },
              { label: 'Bipolar disorder', id: 'bipolar' },
              {
                label: 'History of Suicide attempts or Self harm',
                id: 'selfharm',
              },
              { label: 'History of addiction', id: 'addiction' },
              {
                label:
                  'A blood relative with a history of Schizophrenia or Psychosis',
                id: 'bloodRelative',
              },
              { label: 'Anything else', id: 'any' },
              { label: 'None of the above', id: 'none' },
            ],
            supportDescription: {
              description: `For any ticked disorders please ask dates, and details.
  For example ‘history of suicide attempts’ could be,
              
  Cigarette burn, self-harm during teenage years or,
  It could be a hanging attempt last year which resulted in ITU admission.
  
  These two obviously carry very different risks`
            }
          },
          h_11: {
            active: true,
            header: "2. Other Mental Health Disorders",
            headerId: "h_11",
          },
          q_23: {
            active: true,
            question: "Have you been diagnosed with any other mental health disorders not previously mentioned?",
            questionId: "q_23",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "What is the diagnosis?",
                "How are you being/were being treated for it?",
                "Who is treating you? (which hospital/specialist)"
              ]
            }
          },
          h_12: {
            active: true,
            header: "3. Recent Mental Health State",
            headerId: "h_12"
          },
          q_24: {
            active: true,
            question: "During the last month, have you often been bothered by feeling down, depressed, or hopeless?",
            questionId: "q_24",
            responseType: feedbackType.choiceAndTextOnCondition,
            renderPharmacistComment: true,
          },
          q_25: {
            active: true,
            question: "During the last month, have you often been bothered by having little interest or pleasure in doing things?",
            questionId: "q_25",
            responseType: feedbackType.choiceAndTextOnCondition,
            renderPharmacistComment: true
          }
        }
      },
      'step_5': {
        title: "GAD & AUDIT",
        keys: ["h_13", "q_26", "h_14", "q_27", "q_28", "q_29", "h_15", "q_30", "h_16", "q_31"],
        data: {
          h_13: {
            active: true,
            header: "GAD",
            headerId: "h_13"
          },
          q_26: {
            active: true,
            question: "Fill GAD Form",
            questionId: "q_26",
            responseType: feedbackType.externalForm,
            modalDetails: {
              openModal: true,
              formType: modalType.GAD
            }
          },
          h_14: {
            active: true,
            header: "2. MSE Mental state examination",
            headerId: "h_14"
          },
          q_27: {
            active: true,
            question: "Could you please share any general comments or observations you have regarding the patient’s mental state examination?",
            questionId: "q_27",
            responseType: feedbackType.text,
            supportDescription: {
              description: 
              "<ul>"+
  
              "<li>Appearence and Behaviour"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Do they look well-dressed/ clean? &ldquo;<u>patient appears well kempt</u>&rdquo;</li>"+
              "<li>Do they keep eye contact? &ldquo;<u>Good rapport with patient, normal eye contact</u>&rdquo;</li>"+
              "<li>Do they appear irritable?</li>"+
              "</ul>"+
              "</li>"+
  
              "<li>Mood"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Euthymic (this just means a normal mood), elated, depressed, labile, incongruous, blunted</li>"+
              "</ul>"+
              "</li>"+
  
              "<li>Speech"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Rate, rhythm, tone, volume</li>"+
              "<li>Also use speech to assess disorders of <b>thought or perception:</b> does their speech make sense? Are there any delusions or obsessions that you can pick up from your discussion with them? Do they describe any hallucinations (visual or verbal)?</li>"+
              "</ul>"+
              "</li>"+
  
              "<li>Cognitive assessment"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Do they seem orientated and do they have insight? &ldquo;<u>the patient is orientated to time, place and person, and has insight about their condition</u>&rdquo;</li>"+
              "</ul>"+
              "</li>"+
  
              "</ul>"
            }
          },
          q_28: {
            active: true,
            question: "Do you drink alcohol?",
            questionId: "q_28",
            responseType: feedbackType.choice
          },
          q_29: {
            active: true,
            question: "Fill AUIDT Form",
            questionId: "q_29",
            responseType: feedbackType.externalForm,
            modalDetails: {
              openModal: true,
              formType: modalType.AUDIT
            },
            renderCondition: {
              stepId: "step_5",
              questionId: "q_28",
              value: true
            }
          },
          h_15: {
            active: true,
            header: "4. Tobacco Usage",
            headerId: "h_15"
          },
          q_30: {
            active: true,
            question: "Do you use tobacco? (Please select the most appropriate option)",
            questionId: "q_30",
            responseType: feedbackType.choiceAndTextOnCondition,
            choices: [
              { label: 'Non-smoker', id: 'none' },
              { label: 'Ex-smoker', id: 'ex' },
              { label: 'Current-smoker', id: 'smoker' },
              { label: 'Vape', id: 'vape' },
            ],
            supportDescription: {
              description: `If an ex-smoker, please find out when they stopped.
  
  Some patients would have stopped 3 days ago and will still call themselves ex-smokers.
  
  A general rule of thumb in medicine is that if they have smoked more than 100 cigarettes in their whole life and stopped more than 28 days ago, they are an ex smoker.
  
  If they have smoked less than 100 cigarettes in their life, and are not currently smoking, they are a non-smoker`
            },
            inputRenderCondition: {
              'Ex-smoker': 'smoker',
              'Current-smoker': 'smoker',
              Vape: 'vape',
            }
          },
          h_16: {
            active: true,
            header: "5. Recreational Drug Usage",
            headerId: "h_16",
          },
          q_31: {
            active: true,
            question: "Do you currently use any recreational drugs? (Please select all that apply)",
            questionId: "q_31",
            responseType: feedbackType.checkboxAndText,
            choices: [
              { label: 'Cocaine', id: 'cocaine' },
              { label: 'Ecstasy', id: 'ecstasy' },
              { label: 'Heroin', id: 'heroin' },
              { label: 'Anything else', id: 'any' },
              { label: 'None of the above', id: 'none' },
            ],
          }
        }
      },
      'step_6': {
        title: "Safety Considerations and Treatment Preferences",
        description: "As we reach the last section of this consultation, we will now discuss some safety considerations with you and your preferences for medical cannabis formulations, should you be eligible for this treatment. We are also here to/provide you with further information regarding your medication including driving, travel, and understanding potential side effects. Please let us know your thoughts and concerns.",
        keys: ["h_17", "q_32", "h_18", "q_33", "h_19", "q_34", "h_20", "h_21", "q_35", "h_22", "q_36"],
        data: {
          h_17: {
            active: true,
            header: "1. Mobility and Fall Risk",
            headerId: "h_17"
          },
          q_32: {
            active: true,
            question: "Do you have any mobility issues or have you been advised that you are at risk of falls?",
            questionId: "q_32",
            responseType: feedbackType.choiceAndTextOnCondition,
            supportDescription: {
              description: "Gather below information if the patient response is Yes",
              points: [
                "How frequently they fall",
                "When was their last fall",
                "Are they under treatment for falls",
                "Have they ever needed hospital admission for a fall"
              ]
            }
          },
          h_18: {
            active: true,
            header: "2. Caregiver Status",
            headerId: "h_18"
          },
          q_33: {
            active: true,
            question: "Are you the main carer for anyone you live with?",
            questionId: "q_33",
            responseType: feedbackType.choiceAndTextOnCondition
          },
          h_19: {
            active: true,
            header: "3. Safety Advisories – Important information to share with the patients",
            headerId: "h_19",
          },
          q_34: {
            active: true,
            question: "I would like to provide you with advice on a few important topics.",
            questionId: "q_34",
            responseType: feedbackType.checkboxQuestion,
            choices: [
              {label: "Driving or operating skilled machinery", id: "driving", 
              supportDescription: {
                description: 
              "<ul>"+
              "<li>Advice regarding cannabis and driving can be found on Gov.uk website</li>"+
              "<li>Like many everyday medicines, your driving ability may be reduced (driving impairment) while you are taking your medical cannabis. Your medication may cause drowsiness and can initially reduce your reaction times. This can increase your risk of having an accident, putting yourself and others at risk. You must not drink ANY alcohol or medicines which can impair you whilst on medical cannabis</li>"+
              "<li>You only need to inform the DVLA of your medical condition, not your prescription. And only if your medical condition affects your driving. The only condition that medical cannabis is commonly prescribed which requires an individual to inform the DVLA is epilepsy. However, it's recommended that people prescribed medical cannabis inform the DVLA and their car insurance company of their prescription to avoid the risk of losing insurance coverage in case of a collision.</li>"+
              "<li>Avoid driving or tasks that require fine motor skills until you know how your medication affects you. Some of the times your driving risk is likely to increase are:"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Starting medical cannabis for the first time (give it at least 4-7 days)</li>"+
              "<li>After a change in dose, most likely after an increase in dose</li>"+
              "</ul>"+
              "</li>"+
              "<li>A statutory defence may apply if a car accident occurs where a specialist doctor has legally prescribed medical cannabis and driving performance was not impaired. It's highly likely that if you've been prescribed medical cannabis preparations containing THC, you will exceed the legal limit. THC can show up in your blood within minutes after taking your medication, and it can still be detectable in your blood stream well beyond 28 days.</li>"+
              "<li>No matter your medication, you should never drive whilst impaired. Your dose and prescription can affect you differently from someone else, so you should always speak to your specialist doctor about possible impairment while taking your medication. Cannabidiol (CBD) only products have been shown to have no potential effect on driving reaction speed and risk of collision.</li>"+
              "</ul>"
              }},
              {label: "Cannabis and travel", id: "travelling",
              supportDescription: {
                description: 
              "<ul>"+
              "<li>You should always contact the embassy of the country you're planning to visit. You'll be able to check the legal status of medical cannabis and what you might need to take for visiting your destination country.</li>"+
              "<li>While some countries have legalised medical cannabis, other countries don't recognise medical cannabis, with some countries giving out serious penalties if you're found with medical cannabis, even with a prescription.</li>"+
              "<li>Assuming you're planning on returning to the UK, you should also ensure that you read the requirements for bringing medicine containing a controlled drug into the UK here.</li>"+
              "</ul>"
              }},
              {label: "Understanding potential side effects of cannabis", id: "understanding",
              supportDescription: {
                description: 
                "Potential side-effects (this isn't an extensive list, it's just produced for the consultation you're doing, but the patient will have a written leaflet, and the FAQ section of the treat-it website to refer to)"+
              "<ul>"+
              "<li>The side effects of medical cannabis are more likely to occur when your treatment first starts</li>"+
              "<li>With most patients the side effects are mild and reduce/go within a few days. It's important to document these and share any concerns with your specialist.</li>"+
  
              "<li>Common (affecting more than 1 in 10 people)"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Feeling dizzy or tired.</li>"+
              "</ul>"+
              "</li>"+
  
              "<li>Less Common (affecting less than 1 in 10 people)"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Problems with your memory or having trouble concentrating.</li>"+
              "<li>Feeling sleepy or giddy</li>"+
              "<li>Blurred vision.</li>"+
              "<li>Eating more or less than usual.</li>"+
              "<li>Changed sense of taste or a dry mouth.</li>"+
              "<li>Constipation or diarrhoea.</li>"+
              "<li>Feeling or being sick.</li>"+
              "<li>Lack of energy or feeling weak or generally unwell.</li>"+
              "<li>Feeling abnormal or drunk.</li>"+
              "<li>Loss of balance or falling over.</li>"+
              "<li>Feeling depressed or confused.</li>"+
              "<li>Feeling over excited or losing touch with reality.</li>"+
              "</ul>"+
              "</li>"+
  
              "<li>Rare (affecting less than 1 in 100 people)"+
              "<ul style='font-weight: 400; font-size: 1.4rem;'>"+
              "<li>Seeing or hearing things that are not there (hallucinations). Believing ideas that are not true/paranoia</li>"+
              "<li>Thoughts of suicide.</li>"+
              "<li>Fainting.</li>"+
              "<li>Changes in pulse rate, heart rate or blood pressure.</li>"+
              "</ul>"+
              "</li>"+
  
              "</ul>"
              }},
            ]
          },
          h_20: {
            active: true,
            header: "4. Consent",
            headerId: "h_20",
            description: "Before we move forward, we would need your consent."
          },
          h_21: {
            active: true,
            header: "Patient Consent Form (To be verbally confirmed by the patient)",
            headerId: "h_21",
            description: "Dear Pharmacist,\nPlease read out each statement to the patient and obtain their verbal confirmation. Once they confirm, kindly tick the corresponding box in the system."
          },
          q_35: {
            active: true,
            question: "",
            questionId: "q_35",
            responseType: feedbackType.externalForm,
            modalDetails: {
              openModal: false,
              formType: modalType.PERSONAL
            }
          },
          h_22: {
            active: true,
            header: "5. Preference for Cannabis Formulation",
            headerId: "h_22"
          },
          q_36: {
            active: true,
            question: "If prescribed medical cannabis, do you have a preference for how you would like to take it? Here are the options you can choose from (select all that apply)",
            questionId: "q_36",
            responseType: feedbackType.checkbox,
            choices: [
              { label: 'Vape', id: 'vape' },
              { label: 'Oil', id: 'oil' },
              { label: 'Flower', id: 'flower' },
              { label: 'Oral', id: 'oral' },
              { label: 'Other', id: 'other' },
              { label: 'No preference', id: 'none' },
            ]
          }
        }
      },
      'step_7': {
        title: "Patient Questions to check understanding",
        description: "Purpose:\nTo ensure that the patient fully understands the process, the treatments they might receive, and the implications of their decisions, we have dedicated this section to address any questions or concerns they may have.",
        keys: ["q_37", "q_38", "q_39"],
        data: {
          q_37: {
            active: true,
            question: "Do you have any questions or concerns about the process, the treatment, or any other related matter?",
            questionId: "q_37",
            responseType: feedbackType.choiceAndTextOnCondition
          },
          q_38: {
            active: true,
            question: "Do you understand and are you comfortable with the process as explained to you?",
            questionId: "q_38",
            responseType: feedbackType.choiceAndTextOnCondition
          },
          q_39: {
            active: true,
            question: "Have all of your questions been answered to your satisfaction and do you feel informed to make decisions regarding your treatment if it is approved for you?",
            questionId: "q_39",
            responseType: feedbackType.choiceAndTextOnCondition
          },
        }
      },
    },
  };
  
  export const mockResponse = {
    "step_1|q_1": {"question":"What is your occupation?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_1|q_2":{"question":"For what condition(s) and resultant symptoms are you hoping to be treated?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_1|q_3":{"question":"Does your occupation involve the performance of skilled tasks, whether manual or cognitive?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_1|q_4":{"question":"Are your symptoms stable, getting better, or getting worse?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_1|q_5":{"question":"What treatments have you tried to date for managing your pain symptoms?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_1|q_6":{"question":"Please provide details of any investigations diagnostics","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_2|q_8":{"question":"Have you ever had medical problems affecting the following (Select all that apply)","choices":[{"label":"Heart","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"heart"},{"label":"Lungs","checked":null,"patientText":null,"pharmacyText":null,"id":"lungs"},{"label":"GIT","checked":null,"patientText":null,"pharmacyText":null,"id":"git"},{"label":"Eyes","checked":null,"patientText":null,"pharmacyText":null,"id":"eyes"},{"label":"Skin","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"skin"},{"label":"Brain","checked":null,"patientText":null,"pharmacyText":null,"id":"brain"},{"label":"Liver","checked":null,"patientText":null,"pharmacyText":null,"id":"liver"},{"label":"Kidney","checked":null,"patientText":null,"pharmacyText":null,"id":"kidney"},{"label":"Anything else","checked":null,"patientText":null,"pharmacyText":null,"id":"others"},{"label":"None of the above","checked":null,"patientText":null,"pharmacyText":null,"id":"none"}]},
    "step_2|q_9":{"question":"Are there any other medical conditions that you have which I don’t know about?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_3|q_10":{"question":"What current medication are you taking?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_3|q_11":{"question":"Have you ever been prescribed any of the following medications?","choices":[{"label":"PreGabalin","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"preGabalin"},{"label":"Codeine","checked":null,"patientText":null,"pharmacyText":null,"id":"codeine"},{"label":"Morphine","checked":null,"patientText":null,"pharmacyText":null,"id":"morphine"},{"label":"Oxycodone","checked":null,"patientText":null,"pharmacyText":null,"id":"oxycodone"},{"label":"Oxycontin","checked":null,"patientText":null,"pharmacyText":null,"id":"oxycontin"},{"label":"Tapentadol","checked":null,"patientText":null,"pharmacyText":null,"id":"tepantadol"},{"label":"Fentanyl or Buprenoprphine Patch","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"fentanyl"},{"label":"Tramadol","checked":null,"patientText":null,"pharmacyText":null,"id":"tramadol"},{"label":"Co-Codamol","checked":null,"patientText":null,"pharmacyText":null,"id":"codamol"},{"label":"Gabapentin","checked":null,"patientText":null,"pharmacyText":null,"id":"gabapentin"},{"label":"Amitriptyline","checked":null,"patientText":null,"pharmacyText":null,"id":"amitriptyline"},{"label":"Duloxetine","checked":null,"patientText":null,"pharmacyText":null,"id":"duloxetine"},{"label":"Other","checked":null,"patientText":null,"pharmacyText":null,"id":"other"},{"label":"None of the above","checked":null,"patientText":null,"pharmacyText":null,"id":"none"}]},
    "step_3|q_12":{"question":"Have you used any over-the-counter (OTC) medications, especially pain killers, regularly in the last year?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","choice":true},
    "step_3|q_13":{"question":"Are you taking any food supplements or herbal remedies?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid "},
    "step_3|q_14":{"question":"Are you currently under the care of a specialist for the condition you are requesting medical cannabis for?","patientText":null,"pharmacyText":null,"choice":false},
    "step_3|q_15":{"question":"Have you previously or are you currently prescribed medical cannabis?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_3|q_16":{"question":"Have you used cannabis within the last year?","patientText":null,"pharmacyText":null,"choice":false},
    "step_3|q_17":{"question":"Can you please specify the method(s) you used to take cannabis? (Select all that apply)","choices":[{"label":"Oil","checked":null,"patientText":null,"pharmacyText":null,"id":"oil"},{"label":"Flower","checked":null,"patientText":null,"pharmacyText":null,"id":"flower"},{"label":"Smoke","checked":null,"patientText":null,"pharmacyText":null,"id":"smoke"},{"label":"Vape","checked":null,"patientText":null,"pharmacyText":null,"id":"vape"},{"label":"Oral","checked":null,"patientText":null,"pharmacyText":null,"id":"oral"},{"label":"Other","checked":null,"patientText":null,"pharmacyText":null,"id":"other"}]},
    "step_3|q_18":{"question":"I understand that should I be prescribed medical cannabis I require an effective means of contraception?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_3|q_19":{"question":"Has a healthcare professional ever advised that you should not take cannabis?","patientText":null,"pharmacyText":null,"choice":false},
    "step_3|q_20":{"question":"Have you ever had an allergic or hypersensitivity reaction to cannabis or cannabis-related products?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_3|q_21":{"question":"Are you allergic to any other medications or substances, including nuts, seeds, and oils?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_4|q_22":{"question":"Have you ever been diagnosed with any of the following? (Please select all that apply)","choices":[{"label":"Personality disorder","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"personality"},{"label":"Schizophrenia or Psychosis","checked":null,"patientText":null,"pharmacyText":null,"id":"schizophrenia"},{"label":"Depression","checked":null,"patientText":null,"pharmacyText":null,"id":"depression"},{"label":"PTSD","checked":true,"patientText":null,"pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","id":"ptsd"},{"label":"Bipolar disorder","checked":null,"patientText":null,"pharmacyText":null,"id":"bipolar"},{"label":"History of Suicide attempts or Self harm","checked":null,"patientText":null,"pharmacyText":null,"id":"selfharm"},{"label":"History of addiction","checked":null,"patientText":null,"pharmacyText":null,"id":"addiction"},{"label":"A blood relative with a history of Schizophrenia or Psychosis","checked":null,"patientText":null,"pharmacyText":null,"id":"bloodRelative"},{"label":"Anything else","checked":null,"patientText":null,"pharmacyText":null,"id":"any"},{"label":"None of the above","checked":null,"patientText":null,"pharmacyText":null,"id":"none"}]},
    "step_4|q_23":{"question":"Have you been diagnosed with any other mental health disorders not previously mentioned?","patientText":null,"pharmacyText":null,"choice":false},
    "step_4|q_24":{"question":"During the last month, have you often been bothered by feeling down, depressed, or hopeless?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":"kjahsdl alsdl a shdla sdlasd aisduh askdj ilausdh lajsb khabs uy auisdy ashdb laid ilasd iauhs dilajsdianlsid ","choice":true},
    "step_4|q_25":{"question":"During the last month, have you often been bothered by having little interest or pleasure in doing things?","patientText":null,"pharmacyText":null,"choice":false},
    "step_5|q_27":{"question":"Could you please share any general comments or observations you have regarding the patient’s mental state examination?","patientText":"asdjha dihashasiduh siu sdoiuaisaisuaisud","pharmacyText":null},
    "step_5|q_28":{"question":"Do you drink alcohol?","patientText":null,"pharmacyText":null,"choice":false},
    "step_5|q_30":{"question":"Do you use tobacco? (Please select the most appropriate option)","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":"Ex-smoker"},
    "step_5|q_31":{"question":"Do you currently use any recreational drugs? (Please select all that apply)","choices":[{"label":"Cocaine","checked":true,"patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"id":"cocaine"},{"label":"Ecstasy","checked":null,"patientText":null,"pharmacyText":null,"id":"ecstasy"},{"label":"Heroin","checked":true,"patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"id":"heroin"},{"label":"Anything else","checked":null,"patientText":null,"pharmacyText":null,"id":"any"},{"label":"None of the above","checked":null,"patientText":null,"pharmacyText":null,"id":"none"}]},
    "step_6|q_32":{"question":"Do you have any mobility issues or have you been advised that you are at risk of falls?","patientText":null,"pharmacyText":null,"choice":false},
    "step_6|q_33":{"question":"Are you the main carer for anyone you live with?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_6|q_34":{"question":"I would like to provide you with advice on a few important topics.","choices":[{"label":"Driving or operating skilled machinery","checked":true,"patientText":null,"pharmacyText":null,"id":"driving"},{"label":"Cannabis and travel","checked":true,"patientText":null,"pharmacyText":null,"id":"travelling"},{"label":"Understanding potential side effects of cannabis","checked":true,"patientText":null,"pharmacyText":null,"id":"understanding"}]},
    "step_6|q_36":{"question":"If prescribed medical cannabis, do you have a preference for how you would like to take it? Here are the options you can choose from (select all that apply)","choices":[{"label":"Vape","checked":true,"patientText":null,"pharmacyText":null,"id":"vape"},{"label":"Oil","checked":true,"patientText":null,"pharmacyText":null,"id":"oil"},{"label":"Flower","checked":true,"patientText":null,"pharmacyText":null,"id":"flower"},{"label":"Oral","checked":null,"patientText":null,"pharmacyText":null,"id":"oral"},{"label":"Other","checked":null,"patientText":null,"pharmacyText":null,"id":"other"},{"label":"No preference","checked":null,"patientText":null,"pharmacyText":null,"id":"none"}]},
    "step_7|q_37":{"question":"Do you have any questions or concerns about the process, the treatment, or any other related matter?","patientText":null,"pharmacyText":null,"choice":false},
    "step_7|q_38":{"question":"Do you understand and are you comfortable with the process as explained to you?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true},
    "step_7|q_39":{"question":"Have all of your questions been answered to your satisfaction and do you feel informed to make decisions regarding your treatment if it is approved for you?","patientText":"hasdja sldakd kah sd lhalsdj sd ia usdiasd kjasd abisd ailsd ilasd la sdilua sdijadb aidbasibdila ube sfhba bfia","pharmacyText":null,"choice":true}
  }