<div class="modal-dialog recommendation-modal">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
                {{title}} Recommendations
            </h4>
        </div>
        <div class="modal-body recommendation-content">
            <div class="medication-info">

                <h5><b>Medication Type</b></h5>
                <div class="medication-label">{{recommendation.medicationType}}</div>
            </div>

            <div class="details">
                <span>THC Percentage</span>
                <span>: {{recommendation.category}}</span>

                <span>Route of admin</span>
                <span>: {{recommendation.route}}</span>

                <span>Manufacturer</span>
                <span>: {{recommendation.manufacturer}}</span>

                <span>Brand</span>
                <span>: {{recommendation.brand}}</span>

                <span>Strain</span>
                <span>: {{recommendation?.strain}}</span>
            </div>

            <h5 [style.margin-top]="'3rem'"><b>Comments</b></h5>
            <p>{{recommendation.comments}}</p>
        </div>
    </div>
</div>