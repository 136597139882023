import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../account/user.service';

@Component({
  selector: 'app-doctor-orders',
  templateUrl: './orders.component.html'
})
export class OrdersComponent implements OnInit, OnDestroy {

  public orderItems: Array<any> = new Array<any>();
  private hasReserved = false;
  public searching: boolean;
  reservedOrders = 0;
  ordersAllowance = 10;
  lastUpdated: {};
  updatedInterval: any;
  user: any;

  constructor(
    private userService: UserService,
    private dataService: DataService,
    private toastr: ToastrService,
    private router: Router
  ) {

  }

  getCountReservedOrders(orders: Array<any>): number {
    let count = 0;
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].status === 'RESERVED_BY_DOCTOR') {
        count++
      }
    }
    return count;
  }

  reserveItem(item: any) {
    if (this.reservedOrders <= this.ordersAllowance) {
      this.reservedOrders++;
      this.dataService.reserveOrderItem(item.id).subscribe(
        (result) => {
          this.refreshOrders();
          let url = '/orders/' + item.id;
          this.router.navigate([url]);
        },
        (error) => {
          this.toastr.error(this.getErrorMessage(error), 'Could Not Reserve Order Item')
        });
    } else {
      this.hasReserved = true;
    }
  }

  refreshOrders() {
    this.searching = true;
    // console.log(this.user.displayName)

    if (this.user.settings.DOCTOR_TYPE){
      if (this.user.settings.DOCTOR_TYPE=='PRESCRIBING_PHARMACIST'){
        this.dataService.getPrescribingPharmacistOrders()
        .subscribe((items) => {
          this.lastUpdated = new Date();
          this.orderItems = items.content;
          this.searching = false;

          this.reservedOrders = this.getCountReservedOrders(this.orderItems);
          if (this.reservedOrders >= this.ordersAllowance) {
            this.hasReserved = true;
          }
          //getting at least one record with status=='RESERVED_BY_DOCTOR' to set hasReserved as true
          //this.hasReserved = this.orderItems.some(o => { return o.status === 'RESERVED_BY_DOCTOR'; });
        });
      }
    } else {
      if (this.user){
        this.dataService.getPendingAssessments()
        .subscribe((items) => {
          this.lastUpdated = new Date();
          this.orderItems = items.content;
          this.searching = false;
  
          this.reservedOrders = this.getCountReservedOrders(this.orderItems);
          if (this.reservedOrders >= this.ordersAllowance) {
            this.hasReserved = true;
          }
          //getting at least one record with status=='RESERVED_BY_DOCTOR' to set hasReserved as true
          //this.hasReserved = this.orderItems.some(o => { return o.status === 'RESERVED_BY_DOCTOR'; });
        });
      }
    }
  }

  async ngOnInit() {
    this.user = await this.userService.getUser().toPromise()
    this.refreshOrders();
    this.updatedInterval = setInterval(() => {
      this.refreshOrders();
    }, 600000);
  }

  ngOnDestroy() {
    if (this.updatedInterval) {
      clearInterval(this.updatedInterval);
    }
  }

  getErrorMessage(error: any) {
    return error.error?.message || error.message || "An unexpected error occurred";
  }
}
