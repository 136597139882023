import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { UserService } from '../account/user.service';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-mdt-orders',
  templateUrl: './mdt-orders.component.html',
  styleUrls: ['./mdt-orders.component.scss'],
})
export class MdtOrdersComponent implements OnInit {
  @ViewChild('cancelSessionModal') cancelSessionModal: ModalDirective
  @ViewChild('finalizeSessionModal') finalizeSessionModal: ModalDirective
  page = 0;

  // Variables to grab fetch results
  allMDTs: any[];
  allPendingOrders: any[];
  hasCompletedOrders: boolean;
  sessionStat: any;
  initLoading: boolean;
  totalPageCount: number;

  // Model for session form
  location: string;
  chair: any;
  participants = [];

  expanded = false;
  public searchValue: any = null;
  public searchType: string = null;

  constructor(
    private mdtService: MdtService,
    private toastsr: ToastrService,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.searchValue = '';
    this.searchType = 'id';
    this.initLoading = true;
    this.fetchDetails(this.searchType, this.searchValue);
  }

  private fetchDetails(searchType?: string, searchValue?: string): void {
    forkJoin([
      this.mdtService.getAllMDTs(),
      this.mdtService.getOrdersForReview(searchType, searchValue, this.page),
      this.mdtService.getActiveSession(),
    ]).subscribe({
      next: (result) => {
        this.allMDTs = result[0];
        this.allPendingOrders = result[1].content;
        this.totalPageCount = result[1].totalPages;
        const activeSession = result[2];
        if ('active' in activeSession) {
          this.sessionStat = activeSession.active;
        } else {
          this.sessionStat = activeSession;
          if (activeSession.chair === null) {
            this.expanded = true;
          }

          this.hasCompletedOrders = activeSession.orders.some(item => item.status === 'AWAITING_MDT_SESSION_COMPLETION')

          if (activeSession.sessionNotes.length > 0) {
            for (const note of activeSession.sessionNotes) {
              if (note.type === 'MDT_SESSION_DOCTORS') {
                const doctorsList = JSON.parse(note.description);
                this.participants = doctorsList;
                break;
              }
            }
          }
        }
        this.initLoading = false;
      },
    });
  }

  handlePageCount(increase: boolean): void {
    if (increase) {
      this.page += 1;
    } else {
      this.page -= 1;
    }

    if (this.searchValue !== '') {
      this.fetchDetails(this.searchType, this.searchValue);
    } else {
      this.fetchDetails();
    }
  }

  startSession() {
    this.mdtService.createSession().subscribe({
      next: (value) => {
        this.fetchDetails();
      },
    });
  }

  handleCancelSession(){
    this.cancelSessionModal.hide();
    this.mdtService.cancelSession(this.sessionStat.id).subscribe({
      next: () => {
        this.fetchDetails();
      }
    })
  }

  addParticipants($event, user) {
    if ($event.target.checked) {
      this.participants.push(user);
      return;
    }

    const index = this.participants.indexOf(user);
    this.participants.splice(index, 1);
  }

  submitSessionDetails(sessionForm: NgForm) {
    if (this.sessionStat.chair === null) {
      if (sessionForm.invalid) {
        this.toastsr.warning('', 'Fill all blank fields!');
        return;
      }
      const passingData = {
        id: this.sessionStat.id,
        chair: this.chair,
        location: this.location,
      };
      if (!this.participants.includes(this.chair)) {
        this.participants.push(this.chair);
      }
      const addReporter = this.chair.id !== this.sessionStat.reporter.id;
      this.mdtService.updateSession(passingData).subscribe({
        next: () => {
          this.expanded = false;
          this.toastsr.success('', 'Session details saved successfully!');
          this.saveParticipants(addReporter);
        },
      });

      return;
    }
    this.saveParticipants(false);
  }

  private saveParticipants(addReporter: boolean) {
    if (addReporter && !this.participants.includes(this.sessionStat.reporter)) {
      this.participants.push(this.sessionStat.reporter);
    }

    const passingData = {
      type: 'MDT_SESSION_DOCTORS',
      description: JSON.stringify(this.participants),
    };

    this.mdtService
      .saveSessionParticipants(this.sessionStat.id, passingData)
      .subscribe({
        next: (value) => {
          this.toastsr.success('', 'Participants saved successfully!');
          this.fetchDetails();
        },
      });
  }

  handleSessionFinalization(){
    this.finalizeSessionModal.hide();
    if (!this.hasCompletedOrders) {
      this.toastsr.warning("", "You have to review at least one order to finalize the session!")
      return
    }

    this.mdtService.finalizeSession(this.sessionStat.id).subscribe({
      next: (value) =>{
        this.toastsr.success("", "Session finalised successfully!")
        this.fetchDetails()
      }
    })
  }

  routeToReview(orderId) {
    this.router.navigate([`mdt-orders/${orderId}/review`])
  }

  getPharmacistEmail(email:string){
    return email.split('@');
  }

  getButtonStatus(status: string) {
    if (status === 'AWAITING_MDT_SESSION_INITIATION') {
      return 'btn-info';
    }

    if (status === 'IN_MDT_SESSION') {
      return 'btn-warning';
    }

    if (status === 'AWAITING_MDT_SESSION_COMPLETION') {
      return 'btn-success';
    }
  }

  isParticipantPresent(id: number) {
    let isPresent = false;
    if (this.participants.length < 1) {
      return isPresent;
    }
    for (const doctor of this.participants) {
      if (doctor.id == id) {
        isPresent = true;
        break;
      }
    }

    return isPresent;
  }

  get user (){
    return this.userService.user
  }

  // Get the search value from the search box
  public onSearchInput(searchType: string, searchValue: string): void {
    this.page = 0;
    // Trim leading and trailing white spaces from searchValue
    searchValue = searchValue.trim();

    // Validate searchValue based on searchType
    if (searchType === 'id' && !(/^\d+$/.test(searchValue))) {
      // Display an error message for invalid input
      this.toastsr.error('', 'Please enter numeric values for ID.');
      return;
    }
    if (searchType === 'name' && (/^\d+$/.test(searchValue))) {
      // Display an error message for invalid input
      this.toastsr.error('', 'Please enter a Patient Name');
      return;
    }
    // Check if searchValue contains special characters
    if (/[^a-zA-Z0-9\s]/.test(searchValue)) {
      // Display an error message for invalid input
      this.toastsr.error('', 'Please enter a Patient Name without special characters');
      return;
    }
    this.initLoading = true;
    this.fetchDetails(searchType, searchValue);
  }

  // Reset the search value in the input
  public onResetSearch(): void {
    this.page = 0;
    this.searchValue = '';
    this.searchType = 'id';
    this.initLoading = true;
    this.fetchDetails();
  }
}
