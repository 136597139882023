<div class="container">
  <h1 class="brand-1">History</h1>
  <p>Please find your history of consultations and assessments below.</p> 
  <div  class="well well--white">
    <div class="row mr-0 ml-0 mb-minus-2em">
      <div class="col-md-7 col-xs-12">
        <ul class="pagination mt-0" style="margin: 0;">
          <li class="page-item"><a class="page-link light-green" [ngClass]="{ disabled: currentPage === 0 }" (click)="changePage(0)">First</a></li>
          <li class="page-item">
            <a class="page-link light-green" [ngClass]="{ disabled: currentPage - 1 < 0 }" (click)="changePage(currentPage - 1)">Previous</a>
          </li>
          <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link light-green disabled">...</a></li>
          <li *ngIf="currentPage !== 0" class="page-item hidden-xs">
            <a class="page-link light-green" (click)="changePage(currentPage - 1)">{{ currentPage }}</a>
          </li>
          <li class="page-item">
            <a class="page-link light-green pagination-active-background"><strong>{{ currentPage + 1 }}</strong></a>
          </li>
          <li *ngIf="currentPage < lastPage" class="page-item hidden-xs">
            <a class="page-link light-green" (click)="changePage(currentPage + 1)">{{ currentPage + 2 }}</a>
          </li>
          <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link light-green disabled">...</a></li>
          <li class="page-item">
            <a class="page-link light-green" [ngClass]="{ disabled: currentPage >= lastPage }" (click)="changePage(currentPage + 1)">Next</a>
          </li>
          <li class="page-item">
            <a class="page-link light-green" [ngClass]="{ disabled: currentPage === lastPage }" (click)="changePage(lastPage)">Last</a>
          </li>
        </ul>
      </div>

      <div class="col-md-5 col-xs-12">
        <div class="input-group" style="display: flex; flex-direction: row; justify-content: stretch;">
          <input type="text" class="form-control" placeholder="id, type, status, name" [(ngModel)]="searchQuery" (keypress)="isNumberOrLetter($event)" (keyup.enter)="search()">
          <span class="input-group-btn" style="width: auto;">
            <button type="button" [disabled]="searchQuery==''" class="btn btn-default" (click)="searchQuery=''; search()">
              <span class="glyphicon glyphicon-remove-circle"></span>
            </button>
            <button type="button" class="btn btn-default" (click)="search()">
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </span>
        </div>
      </div>
    </div>
      

    <div class="table-container">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th>Date</th>
            <th>Patient ID</th>
            <th>Patient</th>
            <th>Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="!searching">
          <tr *ngFor="let item of (orderItems | orderFilter: searchQuery)">
            <td>{{ item.id }}</td>
            <td>{{ item.creationDate | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>{{ item.patient.id }}</td>
            <td>{{ item.patient.displayName }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td class="alert alert-{{item.status | orderState}}">{{ item.status | orderStatus }}</td>
            <td>
              <a class="btn btn-primary" [routerLink]="['/patient/' + item.patient.id, { orderId: item.id }]">View Patient</a>
              <a class="btn btn-default" routerLink="/edit-patient-note/{{item.id}}">Edit Notes</a>
            </td>
          </tr>
          <tr *ngIf="orderItems && (orderItems | orderFilter: searchQuery).length == 0">
            <td colspan="8" style="padding: 1rem; background: white; text-align: center;">(No Items)</td>
          </tr>
        </tbody>
        <tbody *ngIf="searching">
          <tr>
            <td colspan="8" style="padding: 1rem; background: white; text-align: center;">
              <div>Please Wait...</div>
              <i style="font-size: 2rem;" class="fa fa-spinner fa-spin"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-center mr-0 ml-0 pl-15">
      <ul class="pagination mt-0">
        <li class="page-item"><a class="page-link light-green" [ngClass]="{ disabled: currentPage === 0 }" (click)="changePage(0)">First</a></li>
        <li class="page-item">
          <a class="page-link light-green" [ngClass]="{ disabled: currentPage - 1 < 0 }" (click)="changePage(currentPage - 1)">Previous</a>
        </li>
        <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link light-green disabled">...</a></li>
        <li *ngIf="currentPage !== 0" class="page-item hidden-xs">
          <a class="page-link light-green" (click)="changePage(currentPage - 1)">{{ currentPage }}</a>
        </li>
        <li class="page-item">
          <a class="page-link light-green pagination-active-background"><strong>{{ currentPage + 1 }}</strong></a>
        </li>
        <li *ngIf="currentPage < lastPage" class="page-item hidden-xs">
          <a class="page-link light-green" (click)="changePage(currentPage + 1)">{{ currentPage + 2 }}</a>
        </li>
        <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link light-green disabled">...</a></li>
        <li class="page-item">
          <a class="page-link light-green" [ngClass]="{ disabled: currentPage >= lastPage }" (click)="changePage(currentPage + 1)">Next</a>
        </li>
        <li class="page-item">
          <a class="page-link light-green" [ngClass]="{ disabled: currentPage === lastPage }" (click)="changePage(lastPage)">Last</a>
        </li>
      </ul>
    </div>
  </div>
</div>
