<section class="container">
  <h1 class="brand-1">Summary Care Records</h1>
  <div class="new-appointment">
		<div class="well well--white">
			<form #scrForm="ngForm" (ngSubmit)="requestSCRAccess()">
        <h4>Please enter the required fields in order to request access to a patient's NHS Summary Care Record</h4>
        <br/>
        <div class="scr-inputs">
          <div><label for="firstName">First Name</label></div>
          <div><label for="lastName">Last Name</label></div>
          <div class="grid-gender"><label for="gender">Gender</label></div>
          <div><label for="dateOfBirth">Date of Birth</label></div>
          <div></div>
          <div class="form-group">
            <input class="form-control" type="text" name="firstName" [(ngModel)]="patient.firstName" required>
          </div>
          <div class="form-group">
            <input class="form-control" type="text" name="lastName" [(ngModel)]="patient.lastName" required>
          </div>
          <div class="form-group grid-gender-male">
            <input type="radio" name="gender" [(ngModel)]="patient.gender" value="1" required> 
            <span class="radio-label">Male</span>
          </div>
          <div class="form-group grid-gender-female">
            <input class="radio_control" type="radio" name="gender" [(ngModel)]="patient.gender" value="2" required> 
            <span class="radio-label">Female</span>
          </div>
          <div class="form-group">
            <input class="form-control" type="date" placeholder="yyyy-mm-dd" name="dateOfBirth" [(ngModel)]="patient.dateOfBirth" required>
          </div>
          <div class="form-group">
            <button class="btn btn-primary" type="submit" [disabled]="!scrForm.form.valid">Request SCR access</button>
          </div>
      </div>
			</form>
		</div>
	</div>
  
  <div bsModal #summaryCareRecordsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="summaryCareRecordsModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">Summary Care Records</h4>
        </div>
        <div class="modal-body">
          <div *ngIf="summaryCareRecordsData">
            <div *ngIf="summaryCareRecordsData.waiting">
              Please wait while we try to request access for {{ patient.displayName }}'s Summary Care Records.
            </div>
  
            <div *ngIf="summaryCareRecordsData.dateOfBirth && summaryCareRecordsData.nhsNumber">
              <p>
                <strong>You can access {{ patient.displayName }}'s Summary Care Record with the following details:</strong>
              </p>
              <p>
                <label>Date of Birth: </label> {{ summaryCareRecordsData.dateOfBirth | date: 'dd-MMM-yyyy' }} <button class="btn btn-primary" type="button" (click)="copySCRDoB(summaryCareRecordsData.dateOfBirth)">Copy</button>
              </p>
              <p>
                <label>NHS Number: </label> {{ summaryCareRecordsData.nhsNumber }}
              </p>
            </div>
            <div *ngIf="summaryCareRecordsData.error" class="alert alert-danger">
              <p> Unable to get you access to {{patient.displayName}}'s Summary Care Record.</p>
              <strong>{{ summaryCareRecordsData.error }}</strong>
            </div>
            <div *ngIf="!summaryCareRecordsData.waiting" class="text-center">
              <button class="btn btn-primary" type="button" (click)="summaryCareRecordsModal.hide()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
