import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../components/modals/modal.service';
import { OrderNotesService } from '../order-notes.service';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-past-session-item',
  templateUrl: './past-session-item.component.html',
  styleUrls: ['./past-session-item.component.scss'],
})
export class PastSessionItemComponent implements OnInit {
  public sessionDetails: any;
  public doctorList: any;
  public selectedOrder: any;
  public mdtRecommendation: any
  private sessionId: string;
  public proformaNote: any;
  public selectedGpNote: any;

  constructor(
    private mdtService: MdtService,
    private activatedRoute: ActivatedRoute,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {
    activatedRoute.params.subscribe((param) => {
      this.sessionId = param.sessionId;
    });
  }

  handleOrderSelection(order:any){
    if (order === this.selectedOrder) {
      this.selectedOrder = undefined
      return
    }
    this.selectedGpNote = this.orderNotesService.getGPConsultationNote(order.orderNotes);
    this.mdtRecommendation = undefined;
    const recommendationItem = order.orderNotes.find(item => item.type === "MDT_RECOMMENDATION")
    if (recommendationItem) {
      this.mdtRecommendation = JSON.parse(recommendationItem.formulary)
    }
    this.proformaNote = JSON.parse(
      order.orderNotes.find((note) => note.type === 'MDT_PROFORMA')
        ?.questionAnswer || null
    );
    this.selectedOrder = order
  }

  ngOnInit(): void {
    this.mdtService.getCompletedSession(this.sessionId).subscribe({
      next: (result) => {
        this.sessionDetails = result;
        const doctorNote = result.sessionNotes.find(
          (item) => item.type === 'MDT_SESSION_DOCTORS'
        );
        this.doctorList = JSON.parse(doctorNote.description);
      },
    });
  }
}
