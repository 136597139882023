import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SpecialistService } from './specialist.service';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-track-orders-item',
  templateUrl: './track-orders-item.component.html',
  styleUrls: ['./track-orders-item.component.scss'],
})
export class TrackOrdersItemComponent implements OnInit {
  @Input() data: any;
  @Output() fetchData = new EventEmitter();
  @ViewChild('previewImgModal') previewImgModal: ModalDirective;
  @ViewChild('digitalPrescription') digitalPrescription: ModalDirective;

  expanded = false;
  allSelected = false;
  selectedOrders: any[];
  trackingNumber: string;
  public selectedImage: string;
  public selectedDigitalPrescription: any;

  constructor(
    private toastr: ToastrService,
    private specialistService: SpecialistService
  ) {}

  submitTrackingNumber(form: NgForm) {
    if (this.selectedOrders.length === 0) {
      this.toastr.warning('', 'Select orders to assign tracking numbers!');
      return;
    }

    const passingData = {
      trackingCode: this.trackingNumber,
      orderItems: this.selectedOrders
    }

    this.specialistService.addTrackingNumber(passingData).subscribe({
      next: () => {
        this.toastr.success("", "Succefully added the tracking number!")
        this.fetchData.emit();
      }
    })
  }

  ngOnInit(): void {
    this.selectedOrders = [];
  }

  public updateSelectedOrders($event: any, item: string): void {
    const element = $event.target as HTMLElement;

    if (element.tagName.toLowerCase() !== 'input' || element.getAttribute('type') !== 'checkbox') {
      return;
    }

    this.allSelected = false;
    if ($event.target.checked) {
      this.selectedOrders.push(item);
      return;
    }

    const index = this.selectedOrders.indexOf(item);
    if (index !== -1) {
      this.selectedOrders.splice(index, 1);
    }
  }

  setAllSelected($event) {
    this.selectedOrders = [];
    this.allSelected = $event.target.checked;
    if (!this.allSelected) {
      return;
    }

    this.selectedOrders = [...this.data.orderItems];
  }

  public handleImagePreview(event: any, imgSrc: string): void {
    // event.stopPropagation();
    this.selectedImage = imgSrc;
    this.previewImgModal.show();
  }

  public openDigitalPrescription(data: any): void {
    this.selectedDigitalPrescription = data;

    this.digitalPrescription.show();
  }
}
