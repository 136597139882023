import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-referral-letter',
    templateUrl: './referral-letter.component.html'
})
export class ReferralLetterComponent implements OnInit {

    referralLetter: any;
    doctorNote: any;
    order: any;
    patient: any;

    constructor(
        private orderService: DataService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            let orderItemId = +params['orderItemId'];
            this.orderService.getOrderItem(orderItemId).subscribe(orderItem => {
                forkJoin([
                    this.orderService.getReferralLetter(orderItem.appointment.id),
                    this.orderService.getDoctorNote(orderItem.appointment.id),
                    this.orderService.getPrescription(orderItemId),
                    this.orderService.getPatient(orderItem.patient.id)
                ]).subscribe(([referralLetter, doctorNote, prescription, patient]) => {
                    this.order = orderItem;
                    this.referralLetter = referralLetter;
                    this.referralLetter.data = JSON.parse(this.referralLetter.text);
                    this.doctorNote = doctorNote;
                    this.patient = patient;
                    if (this.order.type === 'BOOKING') {
                        this.doctorNote.medicinesPrescribedParsed = prescription?.medication;
                    }
                });
            });

        });
    }

    print() {
        window.print();
    }
}
