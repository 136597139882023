<div class="prescription-order-container" [class]="expanded ? 'expanded' : ''">
    <div class="header" (click)="handleExpand()">
        <p>
            <span>#{{order.id}}: </span>
            <ng-container *ngIf="!order.parentOrderItemId && !order.isRepeatOrder; else hasParent">
                <span>{{order.patient.displayName}}</span>
                consulted by
                <span *ngIf="order.consultationDuration === 'IN_PERSON_MINUTE_00'; else regularOrder">
                    {{order.prescribingPharmacist.displayName}}
                </span>
                <ng-template #regularOrder>
                    <span>
                        {{order.doctor.displayName}}
                    </span>
                </ng-template>
                on
                <span>{{order.appointment.datetime | date : 'dd-MMM-yyyy HH:mm'}}</span>
            </ng-container>
            <ng-template #hasParent>
                Repeat order by
                <span>{{order.patient.displayName}}</span>
                for consultation
                <span>#{{order.parentOrderItemId}} </span>
            </ng-template>
        </p>
        <img src="/assets/vectors/expand.svg" alt="">
    </div>

    <div class="order-content">
        <div class="notes-section">
            <app-gp-decisions *ngIf="order.consultationDuration !== 'IN_PERSON_MINUTE_00'" [orderNotes]="order.orderNotes">
            </app-gp-decisions>
            <div class="note-item">
                <h5><b>Notes</b></h5>
                <div class="note-actions">
                    <button *ngIf="order.isRepeatOrder === false || order.isRepeatOrder === null" class="btn btn-default" (click)="viewPatientNotes.show()">Patient Notes</button>
                    <button *ngIf="order.isRepeatOrder === false || order.isRepeatOrder === null" class="btn btn-default" (click)="modalService.openModal('gpNotes', order.id)">GP Notes</button>
                    <button *ngIf="order.isRepeatOrder === false || order.isRepeatOrder === null" class="btn btn-default" (click)="viewMDTProforma.show()">MDT Proforma</button>
                    <button *ngIf="order.parentOrderItemId" class="btn btn-default" (click)="routeToOrderPreviewPage()">View linked order notes</button>
                </div>
                <div class="note-actions" style="margin-top: 1rem;">
                    <button class="btn btn-default" (click)="modalService.openModal('SCRModal', order.id)">Patient Uploaded Documents</button>
                </div>
            </div>
            <div class="note-item">
                <h5><b>Prescription</b></h5>
                <div *ngIf="prescription !== undefined" class="recommendations">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>
                                    <p>Type</p>
                                </th>
                                <th>Medical Cannabis</th>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Basic details</p>
                                </th>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td>{{prescription.formulary.category}}</td>
                            </tr>
                            <tr>
                                <td>Route of admin</td>
                                <td>{{prescription.formulary.administrationRoute}}</td>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Patient preferences</p>
                                </th>
                            </tr>
                            <tr>
                                <td>Brand</td>
                                <td>{{prescription.formulary.brandName}}</td>
                            </tr>
                            <tr>
                                <td>Strain</td>
                                <td>{{prescription.formulary.strain}}</td>
                            </tr>
                            <tr>
                                <td>Manufacturer</td>
                                <td>{{prescription.formulary.manufacture}}</td>
                            </tr>
                            <tr>
                                <th>
                                    <p>Medication</p>
                                </th>
                                <th>{{prescription.formulary.productName}}</th>
                            </tr>
                            <tr>
                                <th colspan="2">
                                    <p>Additional comments</p>
                                </th>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <p>{{additionalNotes}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="comment-section">
            <app-comment-section *ngIf="expanded" [standard]="true" [orderId]="order.id"></app-comment-section>
        </div>
    </div>
</div>

<app-patient-scr-documents-modal *ngIf="order" [order]="order"></app-patient-scr-documents-modal>

<app-gp-notes-modal *ngIf="!!order" [orderstatus]="order.status" [orderId]="order.id"></app-gp-notes-modal>

<div bsModal #viewPatientNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <app-patient-notes-modal *ngIf="order.isRepeatOrder === false || order.isRepeatOrder === null" [orderNotes]="order.orderNotes" (hideModal)="viewPatientNotes.hide()">
    </app-patient-notes-modal>
</div>

<div bsModal #viewMDTProforma="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <app-mdt-proforma-modal *ngIf="proformaNote" [proformaNotes]="proformaNote" (hideModal)="viewMDTProforma.hide()">
    </app-mdt-proforma-modal>
</div>
