<div class="container login">
	<div class="central_panel">
		<div class="well well--white" style="padding: 5%;" *ngIf="!submitting">
			<img class="site_logo" src="/assets/img/logo.png" alt="Treat it" />

      <h1 class="brand-1">Confirmation</h1>
      <p class="brand-1 description"><strong>Please verify that the following information is correct before you continue.</strong></p>

			<form #form="ngForm" (ngSubmit)="confirm()" *ngIf="!loading">
				<div class="form-check">
          <input name="confirmName" type="checkbox" class="form-check-input" [(ngModel)]="confirmation.name" required />
					<label for="confirmName" class="form-check-label">&nbsp;&nbsp;I confirm that I'm {{profile.displayName}}</label>
				</div>
				<div class="form-check" *ngIf="!isIdentifierPending()">
          <input name="confirmGmc" type="checkbox" class="form-check-input" [(ngModel)]="confirmation.gmc" required />
					<label for="confirmGmc" class="form-check-label ml-1">&nbsp;&nbsp;I confirm that my {{getIdentifier()}} number is {{profile.gmc}}</label>
				</div>
				<div class="form-check">
          <input name="confirmSignature" type="checkbox" class="form-check-input" [(ngModel)]="confirmation.signature" required />
					<label for="confirmSignature" class="form-check-label ml-1">&nbsp;&nbsp;I confirm that this is a copy of my signature</label>
					<img class="signature" [src]="profile.settings.SIGNATURE_URL" style="border: 1px solid gray;">
        </div>
				
				<p class="text-danger">If you find any of the above information to be incorrect, please contact a member of thegpservice.co.uk support team at <a href="tel:+442477450733">024 7745 0733</a>.</p>
				

				<div class="form-check" *ngIf="isIdentifierPending()">
							  <label for="enterGmc" class="form-check-label ml-1" style="font-weight: bold !important;">Please enter your {{getIdentifier()}} number to continue<span style="color:red">*</span></label>
							  <br/>
							  <input name="enterGmc" type="text" class="form-control" [(ngModel)]="confirmation.newGMC" required />
				</div>

				<div class="form-check" *ngIf="isIdentifierPending()">
					<label for="enterPin" class="form-check-label ml-1" style="font-weight: bold !important;">Set up Pin<span style="color:red">*</span></label>
					<br/>
					<input name="enterPin" type="text" class="form-control" [(ngModel)]="confirmation.newPin" required />

					<div>
						<span *ngIf="!isLengthValid ()" style="margin-top: 5px; color: red; font-size: 12px;">Pin must be exactly 4 digits long.</span>
						<br *ngIf="!isLengthValid () && !containsOnlyNumbers()">
						<span *ngIf="!containsOnlyNumbers()" style="margin-top: 5px; color: red; font-size: 12px;">Pin must contain only numbers.</span> 
					</div>
	  			</div>

				<button [disabled]="form.invalid || !areFieldsValid()" type="submit" class="mt-2 btn btn-primary">Continue</button>
			</form>
		</div>
	</div>
</div>