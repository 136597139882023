<div class="container" *ngIf="order && patient">

	<div class="row">

		<div class="col col-md-6">
			<div *ngIf="order.type == 'BOOKING'">
				<h1>Consultation Summary</h1>
				<h3>Consultation date: {{order.appointmentDate | date : 'dd/MM/yyyy HH:mm'}}</h3>

			</div>
			<div *ngIf="order.type == 'FORM'">
				<h1>Assessment Summary</h1>
				<h3>Assessment date: {{order.creationDate | date : 'dd/MM/yyyy HH:mm'}}</h3>
			</div>
		</div>
		<button (click)="print()" class="pull-right btn btn-default btn-print">Print</button>


	</div>

	<div class="widgets widgets--2">
		<div class="widget widget--white widget--grey_border">
			<div class="inner">
				<h1 class="h4 brand-1">Doctor Details:</h1>
				<strong>{{order.doctorName}}</strong>
				<p *ngIf="order.doctorGmc">GMC number: {{order.doctorGmc}}</p>

			</div>
		</div>

		<div class="widget widget--white widget--grey_border">
			<div class="inner">
				<h2 class="h4 brand-1 ">Patient Details</h2>
				{{patient.displayName}} | {{patient.dateOfBirth | date : 'dd/MM/yyyy'}} | {{patient.email}} <br />
				<p>
					{{patient.addressLine1}}<br *ngIf="patient.addressLine1" /> {{patient.addressLine2}}
					<br *ngIf="patient.addressLine2" /> {{patient.addressCity}}
					<br *ngIf="patient.addressCity" /> {{patient.addressCounty}}
					<br *ngIf="patient.addressCounty" /> {{patient.addressPostcode}}
				</p>
			</div>
		</div>
	</div>



	<div class="well well--white">
		<div *ngIf="order.type == 'FORM'">
			<strong *ngIf="order.prescribedTreatments">Prescribed Treatments<br /></strong>
			<p *ngFor="let treatment of order.prescribedTreatments">

				{{treatment}} <br />
			</p>

			<strong>Assessment Form Answers</strong><br />
			<app-assessment-form [orderItem]="order"></app-assessment-form>
			<app-order-downloads order [orderItem]="order" [prescription]="true"></app-order-downloads>


		</div>
		<div *ngIf="order.type == 'BOOKING'">
			<h4>Relevant past medical history:</h4>
			<p>{{doctorNote.relevantPastMedicalHistory}}</p>

			<h4>Relevant Past Family History:</h4>
			<p>{{doctorNote.relevantPastFamilyHistory}}</p>

			<h4>Medication History (Past and Present):</h4>
			<p>{{doctorNote.medicationHistory}}</p>

			<h4>Allergy Information/Previous adverse reactions to medicine:</h4>
			<p>{{doctorNote.allergyInformation}}</p>

			<div *ngIf="doctorNote.nhsGPDetails">
				<h4>NHS GP Details: </h4>
				<pre>{{doctorNote.nhsGPDetails}}</pre>
			</div>

			<h4>Patient provided information</h4>
			<p>{{order.workflow.PRESENTING_COMPLAINT}}</p>

			<h4>Presenting Complaint</h4>
			<p>{{doctorNote.presentingComplaint}}</p>

			<h4>Differential diagnosis</h4>
			<p>{{doctorNote.differentialDiagnosis}}</p>

			<h4>Recommended Actions:</h4>
			<p>{{doctorNote.recommendedActions}}</p>

			<h4>Medicines Prescribed:</h4>
			<table *ngIf="doctorNote.medicinesPrescribedParsed?.length" class="table">
				<thead>
					<tr>
						<th>Medication</th>
						<th>Formulation</th>
						<th>Strength</th>
						<th>Dose</th>
						<th>Quantity</th>
						<th>Unit</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let medicine of doctorNote.medicinesPrescribedParsed">
						<td>{{medicine.medication}}</td>
						<td>{{medicine.formulation}}</td>
						<td>{{medicine.strength}}</td>
						<td>{{medicine.dose}}</td>
						<td>{{medicine.quantity}}</td>
						<td>{{medicine.unit ? medicine.unit : "N/A"}}</td>
					</tr>
				</tbody>
			</table>

			<h4 *ngIf="doctorNote.additionalNotes">Notes for patient record:</h4>
			<p>{{doctorNote.additionalNotes}}</p>

			<div class="row">
				<div class="col col-md-6">
					<div class="form-group">
						<label>Signature:</label>
						<textarea readonly class="form-control" rows="3"></textarea>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
