<div class="modal-dialog patient-modal">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
        aria-hidden="true">&times;</span></button>
      <h4 class="modal-title">
        Side Effect Note
      </h4>
    </div>
    <div class="modal-body">
      <section class="patient-content">
        <div class="note-score">Total: {{sideEffectNote?.average}}</div>
        <div *ngFor="let item of sideEffectForm | keyvalue" class="patient-response">
          <h4>{{item.value}}</h4>
          <span>{{sideEffectNote[item.key]}}</span>
        </div>`
      </section>

    </div>
  </div>
</div>
