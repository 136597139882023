<section class="container">
  <h1 class="brand-1">Doctor Info</h1>
  <p>Below are some helpful links and information</p>

  <div class="well well--white">
    <h3>GENERAL</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>NICE clinical knowledge summaries</strong></p>
        <a href="https://cks.nice.org.uk" target="_blank">https://cks.nice.org.uk <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>GP notebook</strong></p>
        <a href="https://www.gpnotebook.co.uk" target="_blank">https://www.gpnotebook.co.uk <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>A multidrug interaction checking tool</strong></p>
        <a href="http://reference.medscape.com/drug-interactionchecker" target="_blank">www.reference.medscape.com/drug-interactionchecker <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Treat it - Doctor Guide</strong></p>
        <a href="/assets/documents/gps-doctor-guide.pdf" target="_blank">Treat it - Doctor Guide <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Treat it - Doctor Induction Pack Ver 3.0</strong></p>
        <a href="/assets/documents/gps-doctor-induction-pack-ver-3.0.docx" target="_blank">Treat it - Doctor Induction Pack Ver 3.0 <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Treat it - Safe Computer Use</strong></p>
        <a href="/assets/documents/gps-safe-computer-use.pdf" target="_blank">Treat it - Safe Computer Use <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Safeguarding Guide NHS</strong></p>
        <a href="https://www.england.nhs.uk/safeguarding/" target="_blank">https://www.england.nhs.uk/safeguarding <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>How to raise a Safeguarding concern</strong></p>
        <a href="https://www.england.nhs.uk/safeguarding/how-to-raise-a-safeguarding-concern" target="_blank">https://www.england.nhs.uk/safeguarding/how-to-raise-a-safeguarding-concern <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Treat it - Complete Policy Documents</strong></p>
        <a href="/assets/documents/gps-policies-v2.8.pdf" target="_blank">Treat it - Complete Policy Documents <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>PATIENT INFORMATION</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>Patient UK</strong></p>
        <a href="https://patient.info" target="_blank">https://patient.info <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Asthma action plan templates</strong></p>
        <a href="https://www.asthma.org.uk/advice/manage-your-asthma/action-plan/" target="_blank">https://www.asthma.org.uk/advice/manage-your-asthma/action-plan/ <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>Travel</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>NaTHNaC - Travel health information aimed at healthcare professionals advising travellers, and people travelling overseas from the UK. News and information by country on vaccinations, infectious and non-infectious diseases, malaria prevention, disease outbreak.</strong></p>
        <p>Advice line for health professionals<br />
          Telephone: +44 (0)845 602 6712 (local call rate) Please note: mobile charges may vary<br />
          Monday – Friday (closed Wednesday afternoons and Bank Holidays) 09.00 - 11:45 and 13:00 - 15:45</p>
        <a href="https://cks.nice.org.uk/malaria-prophylaxis" target="_blank">https://cks.nice.org.uk/malaria-prophylaxis <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>NPA Malaria Prophylaxis Guide</strong></p>
        <p>Advice on malaria prophylaxis changes frequently. Any changes to guidance in this leaflet will be communicated via the NPA website, www.npa.co.uk. This information should not be used after 31 December 2019. </p>
        <a href="/assets/documents/NPA-Malaria-Prophylaxis-Guide.pdf" target="_blank">NPA Malaria Prophylaxis Guide(PDF) <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>NICE CKS Malaria prophylaxis guidelines</strong></p>
        <a href="https://travelhealthpro.org.uk" target="_blank">https://www.travelhealthpro.org.uk <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>CDC guide to malaria prophylaxis</strong></p>
        <a href="https://www.cdc.gov/malaria/travelers/drugs.html" target="_blank">https://www.cdc.gov/malaria/travelers/drugs.html <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Jet-Lag And Altitude Sickness/Medication</strong></p>
        <a href="/assets/documents/AcetazolamideAndCircadin.docx" target="_blank">Acetazolamide & Circadin <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>UTI</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>RCGP quick reference guide for diagnosis, investigation and management of UTI</strong></p>
        <a href="http://www.target-webinars.com/wp-content/uploads/2016/08/UTI-Leaflet-V16.pdf" target="_blank">
          http://www.target-webinars.com/wp-content/uploads/2016/08/UTI-Leaflet-V16.pdf <i class="fa fa-chevron-right"></i>
        </a>
      </li>
      <li>
        <p><strong>RCGP patient information leaflet on UTI – available in multiple languages</strong></p>
        <a href="https://cks.nice.org.uk/urinary-tract-infection-lower-women" target="_blank">https://cks.nice.org.uk/urinary-tract-infection-lower-women <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>SIGN guideline 88  (in full) – Management of suspected bacterial urinary tract infection in adults</strong></p>
        <a href="http://www.sign.ac.uk/assets/qrg88.pdf" target="_blank">http://www.sign.ac.uk/assets/qrg88.pdf <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>SIGN 88 Quick reference guide</strong></p>
        <a href="http://www.sign.ac.uk/assets/qrg88.pdf" target="_blank">http://www.sign.ac.uk/assets/qrg88.pdf <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>NICE guidelines on management of lower UTI in women</strong></p>
        <a href="https://cks.nice.org.uk/urinary-tract-infection-lower-women" target="_blank">https://cks.nice.org.uk/urinary-tract-infection-lower-women <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>SCORING SYSTEMS</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>Fever-PAIN for sore throat</strong></p>
        <a href="https://www.mdcalc.com/feverpain-score-strep-pharyngitis" target="_blank">https://www.mdcalc.com/feverpain-score-strep-pharyngitis <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>APPROPRIATE USE OF ANTIBIOTICS</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>Summary Antimicrobial Prescribing Guidance</strong></p>
        <a href="https://www.nice.org.uk/Media/Default/About/what-we-do/NICE-guidance/antimicrobial%20guidance/summary-antimicrobial-prescribing-guidance.pdf" target="_blank">
          https://www.nice.org.uk/Media/Default/About/what-we-do/NICE-guidance/antimicrobial%20guidance/summary-antimicrobial-prescribing-guidance.pdf
          <i class="fa fa-chevron-right"></i>
        </a>
      </li>
      <li>
        <p><strong>Managing Common Infections Guidance for primary care</strong></p>
        <a href="https://www.gov.uk/government/publications/managing-common-infections-guidance-for-primary-care" target="_blank">
          https://www.gov.uk/government/publications/managing-common-infections-guidance-for-primary-care
          <i class="fa fa-chevron-right"></i>
        </a>
      </li>
      <li>
        <p><strong>RCGP link to TARGET antibiotics toolkit</strong></p>
        <a href="http://www.rcgp.org.uk/TARGETantibiotics" target="_blank">http://www.rcgp.org.uk/TARGETantibiotics <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>PRESCQIPP resources on antimicrobial stewardship</strong></p>
        <a href="https://www.prescqipp.info/antimicrobial-stewardship/projects/antimicrobial-stewardship#target-antibiotics" target="_blank">https://www.prescqipp.info/antimicrobial-stewardship/projects/antimicrobial-stewardship#target-antibiotics <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Fever-PAIN to assist in diagnosis of streptococcal pharyngitis</strong></p>
        <p>‘Treating your infection’ – self care advice and plan for patients.  Available for GPs, out of hours settings and pharmacies. This is on the PRESCQIPP home page under Target antibiotics.</p>
        <a href="https://www.mdcalc.com/feverpain-score-strep-pharyngitis" target="_blank">https://www.mdcalc.com/feverpain-score-strep-pharyngitis <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Summary slide on Fever-PAIN</strong></p>
        <a href="http://www.target-webinars.com/wp-content/uploads/2016/08/Using-the-FeverPAIN-score-in-your-practice.pdf" target="_blank">http://www.target-webinars.com/wp-content/uploads/2016/08/Using-the-FeverPAIN-score-in-your-practice.pdf <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <!-- <div class="well well--white"> -->
    <!-- <h3>RECOGNISING SEPSIS</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>SEPSIS action too</strong></p>
        <a href="http://sepsistrust.org/wp-content/uploads/2015/08/1409322477GPScreening2014Final.pdf" target="_blank">http://sepsistrust.org/wp-content/uploads/2015/08/1409322477GPScreening2014Final.pdf <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul> -->
  <!-- </div> -->
  <div class="well well--white">
    <h3>POLICIES AND GUIDANCE</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>GMC guidance on prescribing via telephone, online or via video-link</strong></p>
        <a href="http://www.gmc-uk.org/guidance/ethical_guidance/14326.asp" target="_blank">http://www.gmc-uk.org/guidance/ethical_guidance/14326.asp <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>Antidepressant policy</strong></p>
        <a href="/assets/documents/Antidepressant-Policy-GP-Service-X.docx" target="_blank">Antidepressant-Policy-GP-Service-X <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>
  <div class="well well--white">
    <h3>Contraception & Emergency Contraception</h3>
    <ul class="list-unstyled doctor-link-list">
      <li>
        <p><strong>FSRH publishes updated Emergency Contraception</strong></p>
        <a href="/assets/documents/fsrh-press-release-fsrh-publishes-updated-emergency-contraception-guideline-final-.pdf" target="_blank">FSRH publishes updated Emergency Contraception guideline <i class="fa fa-chevron-right"></i></a>
      </li>
      <li>
        <p><strong>UKMEC SUMMARY TABLE HORMONAL AND INTRAUTERINE CONTRACEPTION</strong></p>
        <a href="/assets/documents/fsrh-ukmec-summary-pages-2017.pdf" target="_blank">UKMEC SUMMARY TABLE HORMONAL AND INTRAUTERINE CONTRACEPTION <i class="fa fa-chevron-right"></i></a>
      </li>
    </ul>
  </div>

</section>
