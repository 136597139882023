<div class="container">
    <div class="specialist-session-orders" style="margin-top: 5rem;">
        <div *ngIf="initLoading" class="loading-container">
            <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
        </div>
        <ng-container *ngIf="!initLoading">
            <div class="pagination-container">
                <span>Page {{page + 1}} of {{totalPageCount}}</span>
                <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
                    <img class="previous" src="/assets/vectors/expand.svg" alt="">
                </button>
                <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
                    <img class="next" src="/assets/vectors/expand.svg" alt="">
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
              <div class="col-lg-6 col-sm-12">
                <div class="orders-search-container">
                  <select [(ngModel)]="searchType" class="form-control" style="width: 47%">
                    <option value="name">Patient Name</option>
                    <option value="id">ID</option>
                  </select>

                  <input name="treatItSearchInput" type="text" [(ngModel)]="searchValue" class="form-control input-search-orders"
                         placeholder="Search by patient name or order ID" />
                  <button class="btn btn-primary search-button" (click)="onSearchInput(searchType, searchValue)">Search</button>
                  <button class="btn btn-danger reset-button" (click)="onResetSearch()">Reset</button>
                </div>
              </div>
            </div>

            <div class="table-container" style="margin-block-start: 2rem;">

                <table class="table table-striped table-hover">
                  <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Patient</th>
                            <th>Order Type</th>
                            <th>Initial Consultation</th>
                            <th>Care Specialist</th>
                            <th>Consulted Pharmacy</th>
                            <th>Pharmacist Name</th>
                            <th style="width: 18rem;">Pharmacy Email</th>
                            <!-- <th>Pharmacy Contact Number</th> -->
                            <th style="width: 15rem;">Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allOrders.length > 0">
                        <tr *ngFor="let order of allOrders">
                            <td>{{order.id}}</td>
                            <td>{{order.creationDate | date : 'dd-MMM-yyyy HH:mm' }}</td>
                            <td>{{order.patient.displayName}}</td>
                            <td>{{order.parentOrderItemId ? 'Repeat' : 'Consultation'}}</td>
                            <td>{{order.doctor ? order.doctor.displayName : '-'}}</td>
                            <td>{{!!order?.patient?.careSpecialist ? order.patient.careSpecialist : '-'}}</td>
                            <td>{{order.prescribingPharmacist ? order.prescribingPharmacist.displayName : '-'}}</td>
                            <td>{{!!order.prescribingPharmacist ? order.workflow.PRESCRIBING_PHARMACIST_NAME : '-'}}</td>
                            <td>{{!!order.prescribingPharmacist ? getPharmacistEmail(order.prescribingPharmacist.email)[0]+"\n@"+getPharmacistEmail(order.prescribingPharmacist.email)[1] : '-'}}</td>
                            <!-- <td>{{!!order.prescribingPharmacist ? order.prescribingPharmacist.phone : '-'}}</td> -->
                            <td>{{getOrderStatus(order.status)}}</td>
                            <td>
                                <button class="btn" (click)="routeToReview(order.id)"
                                    [class]="getButtonStatus(order.status)" [style.width]="'10rem'">
                                    {{order.status === "AWAITING_SPECIALIST_CALL_PATIENT" ? "Review" : "In Progress"}}
                                </button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="allOrders.length === 0">
                      <tr>`
                        <td colspan="10" style="text-align: center;font-weight: bold; margin-top: 15px">
                          <div>No records found</div>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <ng-template #dataFetchInProgress>
            <div class="loading-container">
                <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
            </div>
        </ng-template>
    </div>
</div>
