import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[equal]'
})
export class EqualValidatorDirective implements Validator {

  @Input("equal") value: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return { 'equal': control ? control.value == this.value : null }
  }

}