import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { UserService } from './account/user.service';
import { PrescriptionService } from './prescription.service';

@Injectable({
  providedIn: 'root',
})
export class RestrictionGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router,
    private prescriptionService: PrescriptionService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.userService.user$
      .pipe(
        filter((u) => u != null),
        take(1)
      )
      .toPromise();
    if (user.restricted) {
      if (user.settings.DOCTOR_TYPE) {
        if (user.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST') {
          return true;
        }
      }
      return this.router.navigateByUrl('/prescriptions');
    } else {
      if (this.prescriptionService.prescriptions === undefined) {
        await this.prescriptionService.cachePrescriptions();
      }
      return true;
    }
  }
}
