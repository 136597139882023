import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, lastValueFrom } from 'rxjs';
import { INoteSheet } from 'src/app/appointments/edit-patient-notes.component';
import { DataService } from 'src/app/data.service';
import { questionnaire, questionStructure, questionType } from 'src/assets/questionnaire';

@Component({
  selector: 'app-consultation-notes-form',
  templateUrl: './consultation-notes-form.component.html',
  styleUrls: ['./consultation-notes-form.component.scss']
})
export class ConsultationNotesFormComponent implements OnChanges {
  @Input() gpNote: any;
  @Input() orderId: number;
  
  parsedNote: any;
  filteredQuestionnaire: any;
  conditionalRenderingQList: questionType[] = [];
  
  notesGroup: FormGroup;

  protected stepPosition = 1;
  protected stepPercentage = 0;
  
  constructor(private toastr: ToastrService, private dataService: DataService) { }

  private createNotesForm() {
    const noteControls = {};
    const consultationNotes = this.parsedNote;

    const isChecked = (questionStructChoice: {label: string, id: string}, noteChoices: any[]) => {
      const selectedChoice = noteChoices.find(item => item.id === questionStructChoice.id)
      if (!!selectedChoice) {
        return {
          checked: new FormControl(selectedChoice.checked),
          text: new FormControl(selectedChoice.text),
        }
      }
      return {
        checked: new FormControl(undefined),
        text: new FormControl(undefined),
      }

    }

    if (!!consultationNotes.doctorComments) {
      noteControls['doctorComments'] = new FormControl(
        consultationNotes['doctorComments'],
        Validators.required
      );
    } else {
      this.filteredQuestionnaire.forEach((questionSet) => {
        for (let i = 0; i < questionSet.questions.length; i++) {
          const questionStruct = questionSet.questions[i];

          if (questionStruct.type === questionStructure.text) {
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              text: new FormControl(
                consultationNotes[questionStruct.questionId]?.text ||
                  undefined,
                Validators.required
              ),
            });
            continue;
          }

          if (
            questionStruct.type === questionStructure.choice ||
            questionStruct.type === questionStructure.choiceAndText ||
            questionStruct.type === questionStructure.choiceAndTextOnCondition
          ) {
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              text: new FormControl(
                consultationNotes[questionStruct.questionId]?.text || ''
              ),
              choice: new FormControl( 
                consultationNotes[questionStruct.questionId].choice
                ,
                Validators.required
              ),
            });
            continue;
          }

          if (
            questionStruct.type === questionStructure.checkbox ||
            questionStruct.type === questionStructure.checkboxAndText
          ) {
            const checkboxArray = questionStruct.choices.map(
              (choice, index) =>
                new FormGroup({
                  label: new FormControl(choice.label),
                  ...isChecked(choice, consultationNotes[questionStruct.questionId].choices),
                  id: new FormControl(choice.id),
                })
            );
            noteControls[questionStruct.questionId] = new FormGroup({
              question: new FormControl(questionStruct.question),
              choices: new FormArray(checkboxArray),
            });
            continue;
          }
        }
      });
    }

    this.notesGroup = new FormGroup(noteControls);
  }

  private filterQuestionnaire(){
    this.filteredQuestionnaire = questionnaire.map((questionItem) => {
      const filteredQuestions = questionItem.questions.filter(
        (question) => question.questionId in this.parsedNote
      );

      return {
        title: questionItem.title,
        questions: filteredQuestions,
        step: questionItem.step,
      };
    });
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.gpNote) {
      this.notesGroup = undefined;
      this.parsedNote = JSON.parse(this.gpNote.questionAnswer)
      this.filterQuestionnaire();
      this.createNotesForm();
      this.setProgressWidth();
    }
  }

  async handleOnGPNotesBlur() {
    let noteSheet = this.notesGroup.value;

    if (!!this.conditionalRenderingQList.length) {
      this.conditionalRenderingQList.forEach((question) => {
        const { parentId, selectionKey, decisionValue } =
          question.renderCondition;
        if (noteSheet[parentId][selectionKey] !== decisionValue) {
          delete noteSheet[question.questionId];
        }
      });
    }

    for (const [key, { choices }] of Object.entries(noteSheet as INoteSheet)) {
      if (!!choices) {
        const filteredChoices = choices.filter(
          (choice: any) => choice.checked !== null
        );
        noteSheet[key].choices = filteredChoices;
      }
    }

    const consultationNotes = {
      id: this.gpNote.id,
      type: 'GP_CONSULTATION_NOTES',
      questionAnswer: JSON.stringify(noteSheet),
    };

    await lastValueFrom(this.dataService.updatePatientNote(this.orderId, consultationNotes).pipe(debounceTime(500)));
  }

  getIsQuestionRendered(question: questionType) {
    if (!question.renderCondition) {
      return true;
    }

    if (!!question.renderCondition) {
      this.conditionalRenderingQList.push(question);
      const { parentId, selectionKey, decisionValue } =
        question.renderCondition;
      const noteSheet = this.notesGroup.value;
      return noteSheet[parentId][selectionKey] === decisionValue;
    }

    return false;
  }

  textareaVisibility(question: questionType, index = -1) {
    if (
      question.type === questionStructure.text ||
      question.type === questionStructure.choiceAndText
    ) {
      return true;
    }

    const noteValue = this.notesGroup.controls[question.questionId].value;

    if (question.type === questionStructure.choiceAndTextOnCondition) {
      if (typeof question.condition === 'boolean') {
        return noteValue.choice === question.condition;
      }

      if (typeof question.condition === 'object') {
        return !!question.condition[noteValue.choice];
      }
    }

    if (
      question.type === questionStructure.checkbox &&
      !!question.condition &&
      noteValue.choices[index] !== undefined
    ) {
      return noteValue.choices[index].checked === true && !!question.condition[noteValue.choices[index].label];
    }

    if (
      question.type === questionStructure.checkboxAndText &&
      noteValue.choices[index] !== undefined
    ) {
      return noteValue.choices[index].checked === true;
    }
  }

  textareaId(question: questionType) {
    if (question.type === questionStructure.text) {
      return question.questionId;
    }

    if (
      question.type === questionStructure.choiceAndTextOnCondition ||
      question.type === questionStructure.choiceAndText
    ) {
      return `${question.questionId}Desc`;
    }
  }

  getPlaceholder(question: questionType) {
    if (!question.placeholder) {
      return '';
    }

    if (
      question.type === questionStructure.choiceAndTextOnCondition ||
      question.type === questionStructure.choiceAndText
    ) {
      const note = this.notesGroup.value[question.questionId];
      if (!!question.choices) {
        return '';
      }

      if (note.choice === true) {
        return question.placeholder.boolTrue;
      }

      if (note.choice === false) {
        return question.placeholder.boolFalse;
      }

      return question.placeholder.default;
    }
  }

  handleStepPosition(state: boolean) {
    if (state) {
      const { questions } = questionnaire[this.stepPosition - 1];
      const controls = this.notesGroup.controls;
      const noteSheet = this.notesGroup.value;
      const errorFields = questions.filter((question) => {
        if (!question.active) {
          return false;
        }

        if (!!question.renderCondition) {
          const { decisionValue, parentId, selectionKey } =
            question.renderCondition;
          if (noteSheet[parentId][selectionKey] !== decisionValue) {
            return false;
          }
        }

        if (
          question.type === questionStructure.checkbox ||
          question.type === questionStructure.checkboxAndText
        ) {
          const valueChecked = controls[
            question.questionId
          ]?.value?.choices.some((item) => item.checked);
          return !valueChecked;
        }

        return (
          question.type !== questionStructure.signal &&
          controls[question.questionId].invalid
        );
      });

      if (errorFields.length > 0) {
        errorFields.reverse().forEach((field) => {
          this.toastr.warning(field.question, 'Please fill the empty field');
        });
        return;
      }
      this.stepPosition++;
    }

    if (!state && this.stepPosition > 1) {
      this.stepPosition--;
    }

    this.setProgressWidth();
  }

  private setProgressWidth() {
    this.stepPercentage = 100 * (this.stepPosition / questionnaire.length);
    // const firstStepPercentage = 100 * (1 / questionnaire.length);
    // this.stepPercentage = accuratePercentage - firstStepPercentage;
  }


  getQuestionStructures() {
    return questionStructure;
  }
}
