<section class="container">
  <h1 class="brand-1 ml-1em mb-0-5">Formulary</h1>
  <div class="well well--white">
    <div *ngIf="!errored">
      <div class="row mr-0 ml-0 mb-minus-2em">
        <div class="col-md-7 col-xs-12">
          <ul class="pagination mt-0" style="margin: 0;">
            <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage === 0}" (click)="changePage(0)">First</a></li>
            <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage - 1 < 0}" (click)="changePage(currentPage - 1)">Previous</a></li>
            <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link light-green disabled">...</a></li>
            <li *ngIf="currentPage !== 0" class="page-item hidden-xs"><a class="page-link light-green" (click)="changePage(currentPage - 1)">{{currentPage}}</a></li>
            <li class="page-item"><a class="page-link light-green pagination-active-background"><strong>{{currentPage + 1}}</strong></a></li>
            <li *ngIf="currentPage + 1 <= lastPage" class="page-item hidden-xs"><a class="page-link light-green" (click)="changePage(currentPage + 1)">{{currentPage + 2}}</a></li>
            <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link light-green disabled">...</a></li>
            <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage >= lastPage}" (click)="changePage(currentPage + 1)">Next</a></li>
            <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage === lastPage}" (click)="changePage(lastPage)">Last</a></li>
          </ul>
        </div>
        <div class="col-md-5 col-xs-12">
          <div class="input-group" style="display: flex; flex-direction: row; justify-content: stretch;">
            <button *ngIf="allowEditFormulary" (click)="medicationItem = {}; upsertMedicationModal.show();" class="btn btn-sm btn-primary"><i class="fa fa-plus"></i></button>&nbsp;
            <input type="text" class="form-control" placeholder="ID, name, formulation or strength" [(ngModel)]="searchQuery" (keyup.enter)="search()">
            <span class="input-group-btn" style="width: auto;">
              <button type="button" [disabled]="searchQuery==''" class="btn btn-default" (click)="searchQuery=''; search()">
                <span class="glyphicon glyphicon-remove-circle"></span>
              </button>
              <button type="button" class="btn btn-default" (click)="search()">
                <span class="glyphicon glyphicon-search"></span>
              </button>
            </span>
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-12 table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="col-md-1">ID</th>
              <th class="col-md-3">Name</th>
              <th class="col-md-3">Formulation</th>
              <th class="col-md-1">Strength</th>
              <th *ngIf="allowEditFormulary" class="col-md-1" style="text-align: right; padding-right: 1em;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let medication of formulary">
              <td class="col-md-1 middle-aligned padded-table-cell">{{medication.id}}</td>
              <td class="col-md-4 middle-aligned padded-table-cell">{{medication.name}}</td>
              <td class="col-md-3 middle-aligned padded-table-cell">{{medication.formulation}}</td>
              <td class="col-md-1 middle-aligned padded-table-cell">{{medication.strength}}</td>
              <td *ngIf="allowEditFormulary" class="col-md-1" style="text-align: right;">
                <button class="btn btn-sm btn-primary" [disabled]="!medication.canEdit" (click)="setMedicationItem(medication); upsertMedicationModal.show()"><i class="fa fa-pencil"></i></button>&nbsp;
                <button class="btn btn-sm btn-danger"  [disabled]="!medication.canEdit" (click)="setMedicationItem(medication); deleteMedicationModal.show()"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row" class="mr-0 ml-0 pl-15">
        <ul class="col-md-7 pagination mt-0">
          <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage === 0}" (click)="changePage(0)">First</a></li>
          <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage - 1 < 0}" (click)="changePage(currentPage - 1)">Previous</a></li>
          <li *ngIf="currentPage - 2 > 0" class="page-item"><a class="page-link light-green disabled">...</a></li>
          <li *ngIf="currentPage !== 0" class="page-item hidden-xs"><a class="page-link light-green" (click)="changePage(currentPage - 1)">{{currentPage}}</a></li>
          <li class="page-item"><a class="page-link light-green pagination-active-background"><strong>{{currentPage + 1}}</strong></a></li>
          <li *ngIf="currentPage + 1 <= lastPage" class="page-item hidden-xs"><a class="page-link light-green" (click)="changePage(currentPage + 1)">{{currentPage + 2}}</a></li>
          <li *ngIf="currentPage + 2 <= lastPage" class="page-item"><a class="page-link light-green disabled">...</a></li>
          <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage >= lastPage}" (click)="changePage(currentPage + 1)">Next</a></li>
          <li class="page-item"><a class="page-link light-green" [ngClass]="{'disabled': currentPage === lastPage}" (click)="changePage(lastPage)">Last</a></li>
        </ul>
      </div>

      <div style="clear: both;"></div>
    </div>

    <div *ngIf="loading && !errored" style="display: flex; align-items: center; justify-content: center; height: 500px;">
      <p>Loading...</p>
    </div>

    <div *ngIf="!loading && errored" style="display: flex; align-items: center; justify-content: center; height: 500px;">
      <p>Something went wrong. Please check your internet connection and try refreshing the page.</p>
    </div>

    <div bsModal #upsertMedicationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" (click)="upsertMedicationModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{medicationItem.id ? 'Update' : 'Add New'}} Medication</h4>
          </div>
          <div class="modal-body" style="padding-top: 0;">
            <form #medicationForm="ngForm" (ngSubmit)="onFormularyChange(medicationForm.value, upsertMedicationModal)" autocomplete="off" novalidate>
              <input style="display: none;" class="form-control" type="text" name="id" [(ngModel)]="medicationItem.id" />
              <div class="form-group">
                <label for="name">Name</label>
                <input class="form-control" type="text" name="name" required [(ngModel)]="medicationItem.name" />
              </div>
              <div class="form-group">
                <label for="formulation">Formulation</label>
                <input class="form-control" type="text" name="formulation" required [(ngModel)]="medicationItem.formulation" />
              </div>
              <div class="form-group">
                <label for="strength">Strength</label>
                <input class="form-control" type="text" name="strength" required [(ngModel)]="medicationItem.strength" />
              </div>
              <div>
                <button type="reset" class="btn btn-default" (click)="upsertMedicationModal.hide();">Cancel</button>&nbsp;
                <button type="submit" class="btn btn-primary" [disabled]="medicationForm.invalid">OK</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div bsModal #deleteMedicationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" (click)="deleteMedicationModal.hide()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Delete Medication</h4>
          </div>
          <div class="modal-body" style="padding-top: 0;">
            <p>Are you sure you want to delete this medication?</p>
            <p>
              <span><strong>Name: </strong>{{medicationItem.name}}</span><br>
              <span><strong>Formulation: </strong>{{medicationItem.formulation}}</span><br>
              <span><strong>Strength: </strong>{{medicationItem.strength}}</span><br>
            </p>
            <div>
              <button type="reset" class="btn btn-primary" (click)="deleteMedicationModal.hide();">No</button>&nbsp;
              <button type="submit" class="btn btn-danger" (click)="deleteMedication(medicationItem, deleteMedicationModal)">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>