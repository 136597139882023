<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">AUDIT Form</h4>
        </div>
        <div class="modal-body">
            <form [formGroup]="auditGroup" autocomplete="off">
                <div *ngFor="let item of auditForm | keyvalue" class="modal-question">
                    <span><b>{{item.value.label}}</b></span>
                    <div class="modal-radios">
                        <ng-container *ngFor="let radioItem of item.value.values | keyvalue">
                            <input type="radio" [value]="radioItem.key" [id]="radioItem.key + item.key"
                                [formControlName]="item.key" />
                            <label [for]="radioItem.key + item.key">{{radioItem.value}}</label>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>