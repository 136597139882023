import { Injectable, Inject } from '@angular/core';
import { AccountService } from './account.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalOptions } from '../app.module';
import { map, switchMap } from 'rxjs/operators';
import { FormularyService } from '../formulary/formulary.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
    user: any;

    private _user$ = new BehaviorSubject(null);

    get user$() {
        return this._user$.asObservable();
    }

    constructor(
        private accountService: AccountService,
        private formularyService: FormularyService,
        private http: HttpClient,
        @Inject('global_options') private options: GlobalOptions) {

        this.accountService.authorised$.subscribe((authorised) => {
            if (authorised) {
                this.getUser().subscribe(u => {
                    this._user$.next(u);
                    // this.formularyService.initFormulary(u.prescriptionCompany?.id);
                });
            } else {
                this._user$.next(null);
            }
        });

        this.user$.subscribe(u => {
            this.user = u;
        });
    }

    getUser() {
        let url = `${this.options.apiEndPoint}api/doctor/me`;
        return this.http.get<any>(url);
    }

    changePassword(oldPassword: string, newPassword: string, type = 'PASSWORD') {
        return this.http.put<any>(`${this.options.apiEndPoint}api/doctor/password?type=${type}`, {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: newPassword
        });
    }

    changePin(oldPin: string, newPin: string) {
        return this.changePassword(oldPin, newPin, 'PIN');
    }

    confirmIdentity() {
        return this.http.put<any>(`${this.options.apiEndPoint}api/doctor/me`, {
            ...this.user,
            settings: {...this.user.settings, CONFIRMED_IDENTITY: true}
        }).pipe(
            switchMap(() => {return this.getUser()}),
            map(u => {this._user$.next(u); return u;})
        );
    }

    setGmc(gmc: string) {
        this.user.gmc = gmc;
        return this.http.put<any>(`${this.options.apiEndPoint}api/doctor/me`, {
            ...this.user,
            settings: {...this.user.settings, CONFIRMED_IDENTITY: true}
        }).pipe(
            switchMap(() => {return this.getUser()}),
            map(u => {this._user$.next(u); return u;})
        );
    }
}
