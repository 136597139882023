import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-successful-review-toast',
  templateUrl: './successful-review-toast.component.html',
})
export class SuccessfulReviewToastComponent extends Toast {
  message: string;
  title: string;
  options: any;
  onShown: () => void;
  onHidden: () => void;

  constructor(toastrService: ToastrService, toastPackage: ToastPackage, private router: Router) {
    super(toastrService, toastPackage);
  }

  buttonClicked() {
    this.router.navigate(['tracking-numbers']);
    this.toastrService.clear();
  }
}
