
<div class="timepicker form-inline">
    <div class="form-group">
    <select [(ngModel)]="selectedHour"  class="form-control" (change)="hourChange($event.target.value)">
        <option *ngFor="let hour of hours" value="{{ hour }}" > {{hour | number:'2.0-0' }} </option>
    </select>
    </div>
    <div class="form-group">
      <select [(ngModel)]="selectedMin" class="form-control" (change)="minuteChange($event.target.value)" >
        <option  *ngFor="let min of mins" value="{{ min }}" > {{min | number:'2.0-0'}} </option>
    </select>
    </div>

</div>