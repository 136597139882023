import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusPipe } from './order-status.pipe';

@Pipe({
    name: 'orderFilter'
})

export class OrderFilterPipe implements PipeTransform {

    transform(value: any[], term: string): any[] {

        if (typeof value === 'undefined') {
            return [];
        }
        if (!term) {
            return value;
        }
        function isMatch(field: string) {
            return field && field.toString().toLowerCase().indexOf(term.toLowerCase()) !== -1;
        }
        return value.filter(v => {
            let orderStatusPipe = new OrderStatusPipe();
            let status = orderStatusPipe.transform(v.status);
            return isMatch(v.name) ||
                isMatch(status) ||
                isMatch(v.id) ||
                isMatch(v.type) ||
                isMatch(v.patient?.displayName) ||
                isMatch(v.patient?.id)
        });

    }
}