import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../account/user.service';
import { filter, take } from 'rxjs/operators';
import { FormControl, ValidationErrors, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  public profile: {
    displayName: string,
    gmc: string,
    settings: { SIGNATURE_URL: string, CONFIRMED_IDENTITY?: string, DOCTOR_TYPE?: string, QUICKSILVA_SUB_ID?: string },
    pinForm: FormGroup,
    pin: string,
  }

  public loading = true;
  private unknownPin = "UNKNOWN";

  confirmation = {
    name: false,
    gmc: false,
    signature: false,
    newGMC: "",
    newPin: "",
  }
  public submitting = false;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.userService.user$.pipe(filter(u => u != null), take(1)).subscribe(u => {
      this.profile = u;
      if (this.profile.settings?.CONFIRMED_IDENTITY === "true") {
        return this.router.navigate([''])
      }
      this.loading = false;
    })
  }

  public getIdentifier() {
    const profile = this.profile;
    this.isIdentifierPending()
    if (profile.settings.DOCTOR_TYPE == 'VET') {
      return 'RCV';
    }
    if (profile.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST') {
      return 'GPhC';
    }
    return 'GMC';
  }

  public confirm() {
    this.submitting = true;
    
    if (this.isQuickSilvaDoctor) {
      // if gmc is pending
      if (this.isIdentifierPending) {
        this.userService.setGmc(this.confirmation.newGMC).subscribe (() => {
          if (this.confirmation.newPin.length == 4) {
            this.userService.changePin(this.unknownPin, this.confirmation.newPin).subscribe(() => {
              this.router.navigate([''])
            })
          }
        })
      } else {
        // if gmc is not pending
        if (this.confirmation.newPin.length == 4) {
          this.userService.changePin(this.unknownPin, this.confirmation.newPin).subscribe(() => {
            this.router.navigate([''])
          })
        }
      }
      return this.router.navigate([''])
    } else {
      this.userService.confirmIdentity().subscribe(() => {
        return this.router.navigate([''])
      })
    }
  }

  isIdentifierPending() {
    return this.profile.gmc == "PENDING"
  }

  areFieldsValid () {
    if(this.isQuickSilvaDoctor()) {
      if (this.confirmation.newPin.length !== 4) {
        return false;
      }
      if ((/\D/.test(this.confirmation.newPin))) { // any non-digits
        return false;
      } 
    }
    return true;
  }

  containsOnlyNumbers () {
    return !(/\D/.test(this.confirmation.newPin));
  }

  isLengthValid () {
    return this.confirmation.newPin.length === 4 || this.confirmation.newPin.length === 0;
  }

  isQuickSilvaDoctor () {
    return this.profile.settings.QUICKSILVA_SUB_ID !== undefined;
  }

}
