<div class="video_large">
    <div class="video_mask" *ngIf="!videoConnected">
        <div class="video_mask_inner" *ngIf="!videoSupported">
            <h2>Browser is not supported</h2>
            <p>Sorry this browser is not supported. Please use <a href="https://www.google.co.uk/chrome/browser/desktop/" target="_blank"
                    class="brand-1">Chrome</a>.</p>
        </div>
        <div class="video_mask_inner" *ngIf="hasError">
          <h2 style="overflow-wrap: break-word;">{{errorMessage}}</h2>
        </div>
        <div class="video_mask_inner" *ngIf="connections == 1">
            <h2>Waiting for the {{them.toLowerCase()}} to join the consultation </h2>
            <p>If the {{them.toLowerCase()}} does not join your consultation within 10 minutes of your start time, please email
                support@thegpservice.co.uk or call us on 024 7745 0733. </p>
            <p>
                <button *ngIf="!sessionPublished" class="btn btn-primary" (click)="connect()">Join Call</button>
                <button *ngIf="sessionPublished" class="btn btn-danger" (click)="disconnect()">End Call</button>
            </p>
        </div>
        <div class="video_mask_inner" *ngIf="!streamCreated && connections > 1">
            <h2>Awaiting {{them}} to join</h2>
            <p>We're waiting for the {{them.toLowerCase()}} to join the call&hellip;</p>
            <p>
                <button *ngIf="!sessionPublished" class="btn btn-primary" (click)="connect()">Join Call</button>
                <button *ngIf="sessionPublished" class="btn btn-danger" (click)="disconnect()">End Call</button>
            </p>
        </div>
        <div class="video_mask_inner" *ngIf="streamCreated && connections > 1">
            <h2>The call has not started</h2>
            <p>The {{them.toLowerCase()}} is waiting for you to join the call. In order to join the call please press the button
                below</p>
            <p>
                <button *ngIf="!sessionPublished" class="btn btn-primary" (click)="connect()">Join Call</button>
                <button *ngIf="sessionPublished" class="btn btn-danger" (click)="disconnect()">End Call</button>
            </p>
        </div>
    </div>
    <div #subscriber class="video_wrapper">
    </div>
    <div class="video_meta">
        <button class="end_call" (click)="disconnect()"></button>
        <span class="video_duration">{{formattedTime}}</span>
    </div>
    <div class="video_small">
        <div #publisher class="video_wrapper">
        </div>
    </div>
</div>
