<div class="container">
    <div class="mdt-dashboard">

        <div *ngIf="initLoading" class="loading-container">
            <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
        </div>

        <ng-container *ngIf="!initLoading">
            <div class="mdt-list">
                <h3 class="brand-1">MDT Committee</h3>
                <ng-container *ngIf="allMDTs !== undefined">
                    <div *ngFor="let mdt of allMDTs" class="mdt-item">
                        <p>#{{mdt.id}}: <b>{{mdt.displayName}}</b> ({{mdt.settings.QUALIFICATIONS}})</p>
                        <h5><u><b>Reach out:</b></u></h5>
                        <p>
                            Contact number: {{mdt.phone}} <br>
                            E-mail: {{mdt.email}} <br>
                        </p>
                    </div>
                </ng-container>
            </div>

            <div class="orders-summary">
                <div>
                    <h5><b>Number of remaining orders</b></h5>
                    <h4>{{allPendingOrders.totalElements}}</h4>
                </div>
                <div>
                    <h5><b>Ongoing session</b></h5>
                    <p *ngIf="sessionStat === false; else sessionPresent">
                        There is no ongoing session at the moment.
                    </p>
                    <ng-template #sessionPresent>
                        <div class="sumary-content">
                            <p>Commenced Date: <span><b>{{sessionStat.startDate | date : 'dd-MMM-yyyy' }}</b></span></p>
                            <p>Number of orders reviewed so far: <span><b>{{getReviewedOrderCount()}}</b></span></p>
                            <p>Number of orders reviewed in progress: <span><b>{{getInProgressOrderCount()}}</b></span></p>
                            <br>
                            <p>Session Reporter: <span><b>{{sessionStat.reporter.displayName}}</b></span></p>
                            <p>Session Chair: <span><b>{{sessionStat.chair === null ? '-' : sessionStat.chair.displayName}}</b></span></p>
                            <p>Location: <span><b>{{sessionStat.location === null ? '-' : sessionStat.location}}</b></span></p>
                        </div>
                    </ng-template>
                    <button [disabled]="allPendingOrders.totalElements === 0" class="btn btn-primary" 
                    [title]="allPendingOrders.totalElements === 0 ? 'There are no available orders to review' : ''" 
                    (click)="handleSessionRouting()">
                        {{sessionStat === false ? "Start session" : "Go to session view"}}
                    </button>
                </div>
                <div>
                    <h5><b>Last session</b></h5>
                    <p *ngIf="lastSession === undefined; else lastSessionPresent">
                        There is no record for a past session.
                    </p>
                    <ng-template #lastSessionPresent>
                        <div class="sumary-content">
                            <p>Commenced Date: <span><b>{{lastSession.startDate | date : 'dd-MMM-yyyy' }}</b></span></p>
                            <p>Finalised Date: <span><b>{{lastSession.updateDate | date : 'dd-MMM-yyyy' }}</b></span></p>
                            <p>Number of orders reviewed: <span><b>{{lastSession.orders.length}}</b></span></p>
                            <br>
                            <p>Session Reporter: <span><b>{{lastSession.reporter.displayName}}</b></span></p>
                            <p>Session Chair: <span><b>{{lastSession.chair.displayName}}</b></span></p>
                            <p>Location: <span><b>{{lastSession.location}}</b></span></p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-container>
    </div>
</div>