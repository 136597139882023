import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subscription} from 'rxjs';
import {ModalService} from '../modal.service';
import {DataService} from '../../../data.service';

@Component({
  selector: 'app-patient-document-modal',
  templateUrl: './patient-document-modal.component.html',
  styleUrls: ['./patient-document-modal.component.scss']
})
export class PatientDocumentModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() patient: any;
  @ViewChild('patientDocumentModal') modal: ModalDirective;
  private modalEventSubscription: Subscription;
  public documents: any = [];
  constructor(private modalService: ModalService,private dataService: DataService) { }

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === 'patientDocumentModal') {
        this.getPatientDocuments(this.patient)
        this.modal.show();
      }
    });
  }

  private getPatientDocuments(patient: any): void {
    if (patient.files.length > 0) {
      patient.files.forEach(file => {
        if (file.action === 'PATIENT_KYC_DOCUMENTS' || file.action === 'PATIENT_SCR_DOCUMENTS') {
          this.documents.push(file);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
    }
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }

}
