import { Component, OnInit } from '@angular/core';
import { SpecialistService } from './specialist.service';

@Component({
  selector: 'app-past-orders',
  templateUrl: './past-orders.component.html',
  styleUrls: ['./past-orders.component.scss']
})
export class PastOrdersComponent implements OnInit {

  orderList: any
  totalPageCount: number
  page: number;
  initLoading: boolean;

  constructor(private specialistService: SpecialistService) { }

  private refresh(){
    this.initLoading = true;
    this.specialistService.getPastOrders(this.page).subscribe({
      next: (value) => {
        this.orderList = value.content
        this.totalPageCount = value.totalPages
        this.initLoading = false;
      }
    })
  }

  handlePageCount(increase: boolean){
    if (increase) {
      this.page += 1
    } else {
      this.page -= 1
    }

    this.refresh()
  }

  ngOnInit(): void {
    this.page = 0
    this.refresh()
  }

}
