import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AccountService } from './account/account.service';
import { UserService } from './account/user.service';
import { DataService } from './data.service';
import { KeepAliveService } from './account/keep-alive.service';
import { FormularyService } from './formulary/formulary.service';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { recommendationToolQuestionnaire } from 'src/assets/recommendationToolQuestions';
import { NgForm } from '@angular/forms';
import { DataLoggingService } from './data-logging.service';

enum DoctorType {
  REGULAR_DOCTOR,
  COMPANY_DOCTOR,
  COMPANY_VET,
  COMPANY_PRESCRIBING_PHARMACIST,
  PRESCRIBING_DOCTOR,
  SURGERY_ADMIN,
}

interface IRecommendationPoints {
  cbd: number;
  balanced: number;
  thc: number;
  flower: number;
  vaporize: number;
  oil: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('recommendationToolModal') recommendationToolModal: ModalDirective;
  @ViewChild('recommendationClearModal')
  recommendationClearModal: ModalDirective;
  @ViewChild('recommendationToolForm') recommendationToolForm: NgForm;

  public isCollapsed: boolean = true;
  public user: any;
  public response: any;
  public pendingOrderCount = 0;
  public doctorType: DoctorType;
  public DoctorType = DoctorType;
  public hoveredId: any;
  public recommendationPoints: IRecommendationPoints;

  // form models
  recommendationToolModel = {};

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private dataService: DataService,
    private router: Router,
    private formularyService: FormularyService,
    private keepAliveService: KeepAliveService,
    private logger: DataLoggingService
  ) {}

  ngOnInit() {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/login') != -1) {
          this.user = null;
        }
      }
    });

    this.userService.user$.subscribe((user) => {
      this.user = user;

      if (user) {
        this.logger.setDataDogUser({
          email: user.email,
          name: user.displayName,
          id: user.id,
        });
      }
    });

    this.userService.user$
      .pipe(
        filter((x) => x != null),
        take(1)
      )
      .subscribe((user) => {
        this.doctorType =
          user?.prescriptionCompany?.id == null
            ? DoctorType.REGULAR_DOCTOR
            : user?.settings?.DOCTOR_TYPE == 'VET'
            ? DoctorType.COMPANY_VET
            : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST'
            ? DoctorType.COMPANY_PRESCRIBING_PHARMACIST
            : user?.settings?.DOCTOR_TYPE == 'SURGERY_ADMIN'
            ? DoctorType.SURGERY_ADMIN
            : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_DOCTOR'
            ? DoctorType.PRESCRIBING_DOCTOR
            : DoctorType.COMPANY_DOCTOR;
      });

    this.keepAliveService.init();

    recommendationToolQuestionnaire.forEach((item) => {
      this.recommendationToolModel[item.id] = '';
    });

    this.logger.info("Data dog loggers initialized")

    this.resetRecommendationPoints();
  }

  ngAfterViewInit() {
    this.recommendationToolModal.onShown.subscribe((event) => {
      document.addEventListener(
        'mousedown',
        this.handleBackdropClick.bind(this)
      );
    });
  }

  private handleBackdropClick(event: MouseEvent): void {
    const modalDialog = document.getElementById(
      'recommendationClearModalDialog'
    );

    if (!modalDialog.contains(event.target as Node)) {
      this.handleCloseToolForm();
    }
  }

  logout() {
    this.accountService.blacklistToken().subscribe(
      (response) => {
        this.response = response;
        this.formularyService.cachedFormulary = null;
        this.accountService.logout();
      },
      (error: HttpErrorResponse) => {
        console.error(error);
      }
    );
  }

  isQuickSilvaDoctor() {
    return [DoctorType.PRESCRIBING_DOCTOR, DoctorType.SURGERY_ADMIN].includes(
      this.doctorType
    );
  }

  isUploadFileRoute() {
    return this.router.url.includes('upload-files');
  }

  calculateRecommendationPoints(form: NgForm) {
    const { value } = form;
    this.resetRecommendationPoints();
    Object.keys(value).forEach((key) => {
      if (value[key] !== '' && typeof value[key] !== 'boolean') {
        const formValue = value[key] as IRecommendationPoints;
        this.recommendationPoints = {
          cbd: this.recommendationPoints.cbd + formValue.cbd,
          balanced: this.recommendationPoints.balanced + formValue.balanced,
          thc: this.recommendationPoints.thc + formValue.thc,
          flower: this.recommendationPoints.flower + formValue.flower,
          vaporize: this.recommendationPoints.vaporize + formValue.vaporize,
          oil: this.recommendationPoints.oil + formValue.oil,
        };
      }
    });
  }

  resetRecommendationPoints() {
    this.recommendationPoints = {
      cbd: 0,
      balanced: 0,
      thc: 0,
      flower: 0,
      oil: 0,
      vaporize: 0,
    };
  }

  handleCloseToolForm() {
    let toolHasValues = false;
    for (const key in this.recommendationToolForm.value) {
      if (this.recommendationToolForm.value[key]) {
        toolHasValues = true;
        break;
      }
    }

    if (toolHasValues) {
      this.recommendationToolModal.hide();
      this.recommendationClearModal.show();
    } else {
      this.recommendationToolModal.hide();
    }
  }

  handleClearRecommendationToolValue() {
    this.recommendationToolForm.resetForm({
      q_1: '',
      q_2: '',
      q_3: '',
      q_4: '',
      q_5: '',
      q_6: '',
      q_7: '',
      q_8: '',
      q_9: '',
      q_10: '',
    });
    this.resetRecommendationPoints();
    this.recommendationClearModal.hide();
  }

  get recommendationToolQuestions() {
    return recommendationToolQuestionnaire;
  }
}
