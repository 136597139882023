import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalService } from '../components/modals/modal.service';
import { OrderNotesService } from '../order-notes.service';
import { SpecialistService } from './specialist.service';

@Component({
  selector: 'app-past-order-item',
  templateUrl: './past-order-item.component.html',
  styleUrls: ['./past-order-item.component.scss'],
})
export class PastOrderItemComponent implements OnInit {
  @Input() order: any;
  @ViewChild('viewGPSNotes', { static: true }) viewGPSNotes: ModalDirective;
  @ViewChild('viewMDTProforma', { static: true })
  viewMDTProforma: ModalDirective;
  @ViewChild('viewPatientNotes', { static: true })
  viewPatientNotes: ModalDirective;

  public proformaNote: any;
  prescription: any;
  additionalNotes: string;
  expanded = false;
  gpNote: any

  constructor(
    private specialistService: SpecialistService,
    private location: Location,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {}

  handleExpand() {
    this.expanded = !this.expanded;
    if (this.prescription === undefined) {
      this.specialistService.getPrescription(this.order.id).subscribe({
        next: (result) => {
          this.prescription = result.medication[0];
          this.additionalNotes = result.additionalPatientNotes
        },
      });
    }
  }

  routeToOrderPreviewPage() {
    const angularRoute = this.location.path();
    const url = window.location.href;
    const appDomain = url.replace(angularRoute, '');
    const routeUrl = `${appDomain}/order-preview/${this.order.parentOrderItemId}`;
    window.open(routeUrl, '_blank');
  }

  ngOnInit(): void {
    this.gpNote = this.orderNotesService.getGPConsultationNote(this.order.orderNotes)
    this.proformaNote = this.orderNotesService.getMDTProforma(this.order.orderNotes)
  }
}
