import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {type User} from '@datadog/browser-core';

@Injectable({
  providedIn: 'root',
})
export class DataLoggingService {
  private loggerInitialized = false;

  constructor() {
    this.initDataDogRUM();
    this.initDataDogLogger();
  }

  public initDataDogLogger() {
    if (!environment.dataDog) {
      return;
    }

    datadogLogs.init({
      clientToken: environment.dataDog.clientToken,
      site: environment.dataDog.site,
      service: environment.dataDog.service,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: environment.name,
    });
    this.loggerInitialized = true;
  }

  public initDataDogRUM() {
    if (!environment.dataDog) {
      return;
    }
    datadogRum.init({
      applicationId: environment.dataDog.applicationId,
      clientToken: environment.dataDog.clientToken,
      site: environment.dataDog.site,
      service: environment.dataDog.service,
      env: environment.name,
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
  }

  setDataDogUser(user: User){
    datadogRum.setUser(user);
    datadogLogs.setUser(user);
  }

  public debug(message: string, context?: { [x: string]: any }): void {
    if (this.loggerInitialized) {
      datadogLogs.logger.debug(message, context);
    }
  }

  public info(message: string, context?: { [x: string]: any }): void {
    if (this.loggerInitialized) {
      datadogLogs.logger.info(message, context);
    }
  }

  public warn(message: string, context?: { [x: string]: any }): void {
    if (this.loggerInitialized) {
      datadogLogs.logger.warn(message, context);
    }
  }

  public error(message: string, context?: { [x: string]: any }): void {
    if (this.loggerInitialized) {
      datadogLogs.logger.error(message, context);
    }
  }
}
