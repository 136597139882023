<div class="container">
    <h1 class="brand-1">Past orders</h1>

    <div *ngIf="initLoading; else dataFetched" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>

    <ng-template #dataFetched>
        <div class="pagination-container">
            <span>Page {{page + 1}} of {{totalPageCount}}</span>
            <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
                <img class="previous" src="/assets/vectors/expand.svg" alt="">
            </button>
            <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
                <img class="next" src="/assets/vectors/expand.svg" alt="">
            </button>
        </div>
        <div class="orders-container">
            <app-past-order-item *ngFor="let order of orderList" [order]="order"></app-past-order-item>
        </div>
    </ng-template>
</div>