export enum answerType {
  shortText,
  longText,
  choice,
  check,
}

type questionnaireStructure = {
  [id: string]: {
    title: string;
    answerType: answerType;
  };
};

export const proformaQuestions: questionnaireStructure = {
  gc_q_1: {
    title: 'Has the patient completed an eligibility questionnaire?',
    answerType: answerType.shortText,
  },
  gc_q_2: {
    title: 'Has the patient satisfied eligibility assessment?',
    answerType: answerType.shortText,
  },
  gc_q_3: {
    title: 'Has the patient had a clinical review?',
    answerType: answerType.shortText,
  },
  gc_q_4: {
    title:
      'Has the patient been deemed appropriate for prescription of medicinal cannabis?',
    answerType: answerType.shortText,
  },
  ca_q_1: {
    title: 'What is the condition being treated *',
    answerType: answerType.choice,
  },
  ca_q_2: {
    title:
      'Is medicinal cannabis an appropriate treatment for this condition *',
    answerType: answerType.choice,
  },
  ca_q_3: {
    title: 'Brief summary of patient case *',
    answerType: answerType.longText,
  },
  ca_q_4: {
    title:
      'Are there any contra-indications (clinical or pharmacological) identified by the MDT from case note assessment for medicinal cannabis for this patient?',
    answerType: answerType.choice,
  },
  ca_q_5: {
    title:
      'Has a psychological assessment been performed as per protocol via the traffic light system?',
    answerType: answerType.choice,
  },
  ca_q_6: {
    title:
      'Does the patient meet the criteria as per protocol for unmet clinical need?',
    answerType: answerType.choice,
  },
  ca_q_7: {
    title:
      'Is the initial dose, configuration, and delivery appropriate for this patient?',
    answerType: answerType.choice,
  },
  ca_q_8: {
    title: 'Is this patient already on medicinal cannabis?',
    answerType: answerType.choice,
  },
  ca_q_9: {
    title:
      'Is the MDT satisfied with the dose adjustment plan or plan to continue at same doses?',
    answerType: answerType.choice,
  },
  ca_q_10: { title: 'MDT Discussion', answerType: answerType.longText },
  signOff: { title: '', answerType: answerType.check },
};
