<div class="notes-container">
  <div class="edit-notes-header">
    <div class="patient" *ngIf="patient !== undefined">
      <div class="enterprise" *ngIf="enterprise">
        <h3>{{ enterprise }}</h3>
        <h4>Enterprise Employee</h4>
      </div>


      <div class="patient_name">
        <h3>Order: <b>#{{ order.id }}</b></h3>
      </div>

      <div class="contact">
        <div style="display: flex; gap: 0.5rem; flex-wrap: wrap;">
          <button *ngIf="!!patient" class="btn btn-default" (click)="modalService.openModal('keyAlertModal')">Admin Key Alerts</button>
          <button class="btn btn-default" (click)="patientDetailsModal.show()">
            Patient details
          </button>
          <button *ngIf="order.patient.files && order.patient.files.length" class="btn btn-default"
            (click)="modalService.openModal('SCRModal')">
            Patient documents
          </button>
          <button class="btn btn-default" *ngIf="!!order && !!order.pictures && !!order.pictures.length"
            (click)="modalService.openModal('consultationDocuments')">
            Consultation documents
          </button>
          <button class="btn btn-default" *ngIf="!!order && !!patientComplaint"
            (click)="modalService.openModal('consultationNotes')">
            Consultation notes
          </button>
        </div>
        <div *ngIf="!patient.settings.NHS_NUMBER" class="contact-additional-info">
          <button class="btn btn-default" (click)="verifyNHSModal.show()">
            Verify patient SCR
          </button>
        </div>
        <div *ngIf="!!order && (!order.pictures || !order.pictures.length)" class="contact-additional-info">
          <span class="brand-1" style="font-style: italic;">No consultation documents found to review.</span>
        </div>
      </div>
    </div>

    <div *ngIf="order.parentOrderItemId" class="repeat-reason"
      style="height: auto;max-height: 12rem; overflow-y: auto;">
      <!-- <p class="brand-1">
        The patient has required a change to the prescription of a previous order. <br>
        <span *ngIf="!!getReOrderReason()">
          Reason: <b>{{getReOrderReason()}}</b>
        </span>
      </p> -->
      <div style="display: flex; gap: 1rem; flex-wrap: wrap;">
        <button *ngIf="repeatOrderQuestionnaire" class="btn btn-primary" (click)="repeatOrderNoteModal.show()">
          View clinical form
        </button>
        <button *ngIf="!!pegData" class="btn btn-primary" (click)="pegFormModal.show()">
          View PEG form
        </button>
        <button class="btn btn-primary" (click)="routeToPatientHistory()">Patient history</button>
        <!-- <button class="btn btn-primary" (click)="routeToOrderPreviewPage()">
          View linked order notes
        </button> -->
      </div>
    </div>

    <div *ngIf="!order.parentOrderItemId">
      <h4>{{stepPosition === (getQuestionnaires().length) ? "Finalize" : getQuestionnaires()[stepPosition -
        1].title}}
      </h4>

      <div class="question-progress-container">
        <div class="progress-background">
          <div class="progress" [style.width]="stepPercentage+'%'"></div>
        </div>
        <span>{{stepPercentage}}%</span>
      </div>

      <div class="nav-buttons">
        <button (click)="handleStepPosition(false)" type="button" class="btn btn-default"
          [disabled]="stepPosition === 1">
          Previous
        </button>
        <button (click)="handleStepPosition(true)" type="button" class="btn btn-primary"
          [disabled]="stepPosition === getQuestionnaires().length">
          Next
        </button>
      </div>
    </div>
  </div>


  <form [formGroup]="notesGroup" autocomplete="off" *ngIf="!!notesGroup" novalidate>
    <section *ngIf="!order.parentOrderItemId; else repeatForm" #formContainer class="questions-container"
      [style.top]="getNoteFormPosition()">
      <div *ngFor="let questionsList of getQuestionnaires()">
        <ng-container *ngIf="questionsList.step === stepPosition">
          <div *ngFor="let question of questionsList.questions">
            <ng-container *ngIf="question.active">
              <ng-container *ngIf="getIsQuestionRendered(question)">
                <div *ngIf="question.type !== getQuestionStructures().signal; else signalQuestion" class="question"
                  [formGroupName]="question.questionId">
                  <label *ngIf="question.question !== '' && question.type !== getQuestionStructures().signal"
                    [for]="'question.questionId'">{{question.question}}</label>

                  <div
                    *ngIf="question.type === getQuestionStructures().checkbox || question.type === getQuestionStructures().checkboxAndText"
                    formArrayName="choices">
                    <ng-container *ngFor="let choice of question.choices; index as i">
                      <div [formGroupName]="i" [style.margin-top]="choice.id === 'none' ? '1rem': ''">
                        <label>
                          <input [id]="choice.id" type="checkbox" [name]="choice.id" [equal]="true"
                            formControlName="checked" (change)="handleCheckboxChange($event.target.checked, question.questionId, i)" />
                          <span> {{choice.label}} </span>
                        </label>
                        <ng-container *ngIf="textareaVisibility(question, i) && !!question.tbTitle">
                          <br>
                          <label>
                            {{question.tbTitle}}
                          </label>
                        </ng-container>
                        <textarea class="checkbox-text form-control" *ngIf="textareaVisibility(question, i)"
                          [name]="choice.id+'Desc'" formControlName="text" [id]="choice.id+'Desc'" rows="2"
                          (input)="debouncedInputChange()"></textarea>
                      </div>
                    </ng-container>
                  </div>

                  <div
                    *ngIf="question.type === getQuestionStructures().choiceAndTextOnCondition || question.type === getQuestionStructures().choiceAndText || question.type === getQuestionStructures().choice"
                    class="radio_control" style="display: block">
                    <div *ngIf="question.choices === undefined; else hasChoices" class="radio_control">
                      <input type="radio" [value]="true" formControlName="choice" [name]="choice"
                        [id]="[question.questionId]+'Yes'" (change)="handleRadioButtonChange(question)" />
                      <label [for]="[question.questionId]+'Yes'">Yes</label>
                      <input type="radio" [value]="false" formControlName="choice" [name]="choice"
                        [id]="[question.questionId]+'No'" (change)="handleRadioButtonChange(question)" />
                      <label [for]="[question.questionId]+'No'">No</label>
                    </div>

                    <ng-template #hasChoices>
                      <div *ngFor="let choice of question.choices" class="radio_control">
                        <input type="radio" [value]="choice.label" formControlName="choice"
                          [id]="[question.questionId]+'_'+choice.id" (change)="handleRadioButtonChange(question)" />
                        <label [for]="[question.questionId]+'_'+choice.id">{{choice.label}}</label>
                      </div>
                    </ng-template>

                  </div>

                  <ng-container *ngIf="textareaVisibility(question) && !!question.tbTitle">
                    <br>
                    <label>
                      {{question.tbTitle}}
                    </label>
                  </ng-container>
                  <textarea *ngIf="textareaVisibility(question)" class="form-control" [name]="textareaId(question)"
                    formControlName="text" [id]="textareaId(question)" [rows]="question.rows"
                    [placeholder]="getPlaceholder(question)" (input)="debouncedInputChange()"></textarea>

                </div>

                <ng-template #signalQuestion>
                  <button type="button" *ngIf="question.signalInstructions.type === 'default'; else conditionSignal"
                    class="btn btn-default signal-btn"
                    (click)="handleSignal(question.signalInstructions.signalType, question.signalInstructions.doctorFills)">

                    <ng-container
                      *ngIf="patientNotes[question.signalInstructions.signalType] !== undefined; else defaultBtn">
                      <img src="/assets/vectors/success.svg" alt="">
                      Click to view the filled {{question.question}}
                    </ng-container>

                    <ng-template #defaultBtn>
                      <img *ngIf="checksignalStatus(question.signalInstructions.signalType, 'pending')"
                        src="/assets/vectors/pending.svg" alt="">
                      <ng-container
                        *ngIf="signalFormStatus[question.signalInstructions.signalType] === undefined && !videoCall.hasError">
                        {{getSIgnalBtnText(question.signalInstructions.signalType,
                        question.signalInstructions.doctorFills)}}
                        {{question.question}}
                      </ng-container>
                      {{checksignalStatus(question.signalInstructions.signalType, 'pending') ? ("You can fill the " +
                      question.question + " as well") :
                      ""}}
                      {{videoCall.hasError ? ("Fill the " + question.question ) : ""}}
                    </ng-template>


                  </button>

                  <ng-template #conditionSignal>
                    <button
                      *ngIf="notesGroup.get(question.signalInstructions.id).value.choice === question.signalInstructions.value"
                      class="btn btn-default signal-btn" type="button"
                      (click)="handleSignal(question.signalInstructions.signalType, question.signalInstructions.doctorFills)">

                      <ng-container
                        *ngIf="patientNotes[question.signalInstructions.signalType] !== undefined; else defaultBtn">
                        <img src="/assets/vectors/success.svg" alt="">
                        Click to view the filled {{question.question}}
                      </ng-container>

                      <ng-template #defaultBtn>
                        <img *ngIf="checksignalStatus(question.signalInstructions.signalType, 'pending')"
                          src="/assets/vectors/pending.svg" alt="">
                        <ng-container
                          *ngIf="signalFormStatus[question.signalInstructions.signalType] === undefined && !videoCall.hasError">
                          {{question.signalInstructions.doctorFills ? "Fill the" : "Request patient to fill"}}
                          {{question.question}}
                        </ng-container>
                        {{checksignalStatus(question.signalInstructions.signalType, 'pending') ? ("You can fill the " +
                        question.question + " as well") :
                        ""}}
                        {{videoCall.hasError ? ("Fill the " + question.question ) : ""}}
                      </ng-template>
                    </button>
                  </ng-template>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div *ngIf="stepPosition === getQuestionnaires().length">
        <div class="question">
          <label>Do you want to make a recommendation?</label>
          <div class="radio_control">
            <div class="radio_control">
              <input type="radio" name="askForRecommendation" [value]="true" [checked]="addRecommendation === true"
                id="askForRecommendationYes" (click)="handleAddRecommendationDecision(true)" />
              <label for="askForRecommendationYes">Yes</label>
              <input type="radio" name="askForRecommendation" [value]="false" [checked]="addRecommendation === false"
                id="askForRecommendationNo" (click)="handleAddRecommendationDecision(false)" />
              <label for="askForRecommendationNo">No</label>
            </div>
          </div>
        </div>
        <button *ngIf="addRecommendation === true" class="btn btn-default recommendation-btn"
          (click)="prescriptionModal.show()" [disabled]="!patientIdentified">
          <span>{{recommendationBtnText}}</span>
          <img *ngIf="recommendation !== undefined" src="/assets/vectors/success.svg" alt="">
        </button>
        <app-decision-form (addDescision)="submitDecision($event)" [orderId]="order.id"></app-decision-form>
      </div>
    </section>

    <ng-template #repeatForm>
      <section class="questions-container" style="width: 100%; top: 28rem;">
        <div class="question">
          <label>Doctor's notes</label>
          <textarea class="form-control" name="doctorComment" id="doctorComment" rows="2"
            formControlName="doctorComments" (blur)="handleOnGPNotesBlur()"></textarea>
        </div>
        <div class="question">
          <label>Do you want to make a recommendation?</label>
          <div class="radio_control">
            <div class="radio_control">
              <input type="radio" name="askForRecommendation" [value]="true" [checked]="addRecommendation === true"
                id="askForRecommendationYes" (click)="handleAddRecommendationDecision(true)" />
              <label for="askForRecommendationYes">Yes</label>
              <input type="radio" name="askForRecommendation" [value]="false" [checked]="addRecommendation === false"
                id="askForRecommendationNo" (click)="handleAddRecommendationDecision(false)" />
              <label for="askForRecommendationNo">No</label>
            </div>
          </div>
        </div>
        <button *ngIf="addRecommendation === true" class="btn btn-default recommendation-btn"
          (click)="prescriptionModal.show()" [disabled]="!patientIdentified" style="width: fit-content;">
          <span>{{recommendationBtnText}}</span>
          <img *ngIf="recommendation !== undefined" src="/assets/vectors/success.svg" alt="">
        </button>
        <app-decision-form (addDescision)="submitDecision($event)" [orderId]="order.id"></app-decision-form>
      </section>
    </ng-template>
  </form>

</div>

<app-admin-key-alert-modal *ngIf="!!patient" [patient]="patient"></app-admin-key-alert-modal>

<div bsModal #patientDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="patientDetailsModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Patient details</h4>
      </div>
      <div *ngIf="!!patient" class="modal-body patient-details-modal">
        <div>
          <h3><b>Personal details</b></h3>
          <table>
            <tr>
              <th>ID</th>
              <td>: {{patient.id}}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>: {{patient.displayName}}</td>
            </tr>
            <tr>
              <th>Birthday</th>
              <td>: {{patient.dateOfBirth}}</td>
            </tr>
            <tr>
              <th>Age</th>
              <td>: {{ dataService.getPatientBirthday(patient.dateOfBirth) }}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>: {{ patient.gender | gender }}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                : {{patient.address.line1}}, {{patient.address.line2 ? patient.address.line2 +',' : ''}} <br>
                {{patient.address.city ? patient.address.city +', ' : ''}} {{patient.address.county ?
                patient.address.county +', ' : ''}} <br>
                {{patient.address.postcode ? patient.address.postcode +', ' : ''}}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <h3><b>Medical details</b></h3>
          <table>
            <tr>
              <th>Externally identified</th>
              <th>:</th>
              <td *ngIf="!!patient.settings.EXTERNALLY_IDENTIFIED_BY">
                Yes <b>({{patient.settings.EXTERNALLY_IDENTIFIED_BY}})</b>
              </td>
              <td *ngIf="!patient.settings.EXTERNALLY_IDENTIFIED_BY">No</td>
            </tr>
            <tr>
              <th>SCR Confirmed</th>
              <th>:</th>
              <td *ngIf="!!patient.settings.SCR_CONFIRMED_BY">Yes <b>({{patient.settings.SCR_CONFIRMED_BY}})</b></td>
              <td *ngIf="!patient.settings.SCR_CONFIRMED_BY">No</td>
            </tr>
            <tr>
              <th>NHS verified</th>
              <th>:</th>
              <td>{{patient.nhsVerified ? 'Yes': 'No'}}</td>
            </tr>
            <tr>
              <th>NHS consent given</th>
              <th>:</th>
              <td>{{patient.nhsConsent ? 'Yes': 'No'}}</td>
            </tr>
            <tr>
              <th>NHS number</th>
              <th>:</th>
              <td>{{!!patient.settings.NHS_NUMBER ? patient.settings.NHS_NUMBER : 'N/A'}}</td>
            </tr>
            <tr>
              <th>GP Practice</th>
              <th>:</th>
              <td>{{patient.preferences.gpPractice}}</td>
            </tr>
            <tr *ngIf="!!GPAddress">
              <th>GP Address</th>
              <th>:</th>
              <td>
                {{GPAddress.line1}}, {{GPAddress.line2 ? GPAddress.line2 +',' : ''}} <br>
                {{GPAddress.city ? GPAddress.city +', ' : ''}} {{GPAddress.county ? GPAddress.county +', ' : ''}} <br>
                {{GPAddress.postcode ? GPAddress.postcode +', ' : ''}}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<app-patient-scr-documents-modal *ngIf="order" [order]="order"></app-patient-scr-documents-modal>

<div bsModal #verifyNHSModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="verifyNHSModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Verify Patient SCR</h4>
      </div>
      <div class="modal-body patient-files-container">
        <form #nhsForm="ngForm" autocomplete="off" (ngSubmit)="verifyPatientSCR()">
          <input name="email" type="number" class="form-control" [(ngModel)]="nhsNumber" required
            placeholder="Patient NHS number" />
          <button class="btn btn-primary" style="width: 100%; margin-top: 1rem;" type="submit"
            [disabled]="nhsForm.invalid">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-consultation-documents-modal *ngIf="!!order && !!order.pictures && !!order.pictures.length" [documents]="order.pictures">
</app-consultation-documents-modal>
<app-consultation-notes-modal *ngIf="!!order && !!patientComplaint" [notes]="patientComplaint">
</app-consultation-notes-modal>

<div bsModal #repeatOrderNoteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="repeatOrderNoteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Repeat Order Note</h4>
      </div>
      <div class="modal-body">
        <app-repeat-order-note *ngIf="repeatOrderQuestionnaire" [note]="repeatOrderQuestionnaire">
        </app-repeat-order-note>
      </div>
    </div>
  </div>
</div>

<div bsModal #auditFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <app-audit-form [order]="order" (hideModal)="auditFormModal.hide()"></app-audit-form>
</div>

<div bsModal #gadFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <app-gad-form [order]="order" (hideModal)="gadFormModal.hide()"></app-gad-form>
</div>

<div bsModal #pegFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <app-peg-form (hideModal)="pegFormModal.hide()" [pegNote]="pegData" [orderId]="order.id"></app-peg-form>
</div>

<div bsModal #consentFormModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <app-consent-form (hideModal)="consentFormModal.hide()" [consentNote]="consentData" [orderId]="order.id">
  </app-consent-form>
</div>

<div bsModal #pendingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="pending-container">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h4>Please wait until we finalize things!</h4>
      </div>
    </div>
  </div>
</div>

<div bsModal #prescriptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <app-prescription-builder (hide)="prescriptionModal.hide()" (sendPrescription)="updateRecommendation($event)">
  </app-prescription-builder>
</div>
