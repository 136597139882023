import { Component, OnInit, ViewChild } from '@angular/core';
import { FormularyService } from './formulary.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserService } from '../account/user.service';
import { filter, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-formulary',
  templateUrl: './formulary.component.html',
})
export class FormularyComponent implements OnInit {

  @ViewChild('confirmPricesChangesModal') confirmPricesChangesModal: ModalDirective;
  formulary: any;
  loading: boolean = false;
  errored: boolean;
  errorMessage: any;
  searchQuery = '';
  currentPage = 0;
  lastPage = 0;
  medicationItem: any = {};
  doctor: any;
  allowEditFormulary = false;

  constructor(
    private formularyService: FormularyService,
    private userService: UserService,
    private toastr: ToastrService
     ) {}

  ngOnInit() {
    this.loading = true;
    this.userService.user$.pipe(filter(u => u != null), take(1)).subscribe(async (doctor) => {
      this.doctor = doctor;
      this.allowEditFormulary = doctor.prescriptionCompany && doctor.companyAdmin;
      // await this.formularyService.initFormulary(this.doctor?.prescriptionCompany?.id);
      this.searchFormulary(this.currentPage);
    })
  }

  searchFormulary(page = 0) {
    this.loading = true;
    const results = this.formularyService.searchFormulary(this.searchQuery, page, 10);
    this.handlePagination(results);
    this.formulary = results.content;
    this.errored = false;
    this.loading = false;
  }

  handlePagination(response) {
    this.currentPage = response.number;
    this.lastPage = response.totalPages ? response.totalPages - 1 : 0;
  }

  search() {
    this.searchFormulary();
  }

  changePage(page: number) {
    this.currentPage = page;
    this.searchFormulary(page);
  }

  isNumberOrLetter(event) {
    const pattern = /^[a-z0-9]+$/i;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  setMedicationItem(medication: any) {
    this.medicationItem = {...medication};
  }

  onFormularyChange(medication: any, modal: ModalDirective) {
    modal.hide();
    medication.unitPrice = 0;
    medication.active = true;
    this.formularyService.updateFormulary(medication, medication.id ? "UPDATE" : "CREATE").subscribe(async () => {
      // await this.formularyService.initFormulary(this.doctor?.prescriptionCompany?.id, true);
      this.toastr.success("Formulary Updated Successfully");
      this.search();
    }, error => {
      this.toastr.error("Could Not Update Formulary", error?.error?.message || error?.message || 'An error occurred');
    })
  }

  deleteMedication(medication: any, modal: ModalDirective) {
    modal.hide();
    this.formularyService.updateFormulary(medication, "DELETE").subscribe(async () => {
      // await this.formularyService.initFormulary(this.doctor?.prescriptionCompany?.id, true);
      this.toastr.success("Formulary Updated Successfully");
      this.search();
    }, error => {
      this.toastr.error("Could Not Update Formulary", error?.error?.message || error?.message || 'An error occurred');
    })
  }
}
