<div class="container">
    <h1 class="brand-1">Session Details</h1>
    <div *ngIf="sessionDetails !== undefined" class="past-session-container">
        <div class="session-details">
            <h5><b>Summary</b></h5>
            <p [style.margin-top]="'1rem'">
                Session chair: <b>{{sessionDetails.chair.displayName}}</b> <br>
                Session reporter: <b>{{sessionDetails.reporter.displayName}}</b> <br>
                Session participants count: <b>{{doctorList.length}}</b>
            </p>
            <p [style.margin-top]="'1rem'">
                Start date: <b>{{sessionDetails.startDate | date : 'dd-MMM-yyyy' }}</b> <br>
                End date: <b>{{sessionDetails.updateDate | date : 'dd-MMM-yyyy' }}</b>
            </p>
            <p [style.margin-top]="'1rem'">
                Location: <b>{{sessionDetails.location}}</b> <br>
                Completed orders count: <b>{{sessionDetails.orders.length}}</b>
            </p>
        </div>
        <div class="order-list">
            <div *ngFor="let item of sessionDetails.orders" class="order-item"
                [class]="selectedOrder === item ? 'expanded' : ''">
                <div class="header" (click)="handleOrderSelection(item)">
                    <p><b>#{{item.id}}: {{item.patient.displayName}}</b> consulted by
                        <b>{{item.doctor.displayName}}</b> on
                        <b>{{item.appointment.datetime| date : 'dd-MMM-yyyy'}}</b>
                    </p>

                    <img src="/assets/vectors/expand.svg" alt="">
                </div>
                <div class="content">
                    <div class="notes-section">
                        <app-gp-decisions *ngIf="selectedOrder === item" [orderNotes]="selectedOrder.orderNotes">
                        </app-gp-decisions>
                        <div class="note-item">
                            <h5><b>Notes</b></h5>
                            <div class="note-actions">
                                <button class="btn btn-default" (click)="viewPatientNotes.show()">Patient Notes</button>
                                <button class="btn btn-default" (click)="modalService.openModal('gpNotes')">GP
                                    Notes</button>
                                <button class="btn btn-default" (click)="viewMDTProforma.show()">MDT Proforma</button>
                            </div>
                            <div class="note-actions" style="margin-top: 1rem;">
                                <button class="btn btn-default" (click)="modalService.openModal('SCRModal')">Patient
                                    Uploaded
                                    Documents</button>
                            </div>
                        </div>
                        <div *ngIf="mdtRecommendation" class="note-item">
                            <h5><b>Recommendation</b></h5>
                            <div class="recommendations">
                                <!-- TODO Fill this specialist prescription -->
                                <table *ngIf="selectedOrder !== undefined" class="table">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <p>Type</p>
                                            </th>
                                            <th>{{mdtRecommendation.medicationType}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="2">
                                                <p>Basic details</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Category</td>
                                            <td>{{mdtRecommendation.category}}</td>
                                        </tr>
                                        <tr>
                                            <td>Route of admin</td>
                                            <td>{{mdtRecommendation.route}}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="2">
                                                <p>Patient preferences</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>{{mdtRecommendation.brand}}</td>
                                        </tr>
                                        <tr>
                                            <td>Strain</td>
                                            <td>{{mdtRecommendation.strain}}</td>
                                        </tr>
                                        <tr>
                                            <td>Manufacturer</td>
                                            <td>{{mdtRecommendation.manufacturer}}</td>
                                        </tr>
                                        <tr>
                                            <th colspan="2">
                                                <p>Additional comments</p>
                                            </th>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <p>{{mdtRecommendation.comments}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="comments-section">
                        <app-comment-section *ngIf="selectedOrder === item" [standard]="true" [orderId]="item.id">
                        </app-comment-section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-gp-notes-modal *ngIf="!!selectedOrder" [orderstatus]="selectedOrder.status" [orderId]="selectedOrder.id"></app-gp-notes-modal>

<app-patient-scr-documents-modal *ngIf="selectedOrder" [order]="selectedOrder"></app-patient-scr-documents-modal>

<div bsModal #viewPatientNotes="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <app-patient-notes-modal *ngIf="selectedOrder !== undefined" [orderNotes]="selectedOrder.orderNotes"
        (hideModal)="viewPatientNotes.hide()">
    </app-patient-notes-modal>
</div>

<div bsModal #viewMDTProforma="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <app-mdt-proforma-modal *ngIf="proformaNote !== undefined" [proformaNotes]="proformaNote"
        (hideModal)="viewMDTProforma.hide()">
    </app-mdt-proforma-modal>
</div>