<form *ngIf="!!decisionGroup" [formGroup]="decisionGroup" autocomplete="off" novalidate (ngSubmit)="complete()" class="final-remarks-form">
    <label for="allowPresciption">Approve patient to have cannabis prescription?</label>
    <div>
        <input required type="radio" [value]="true" required name="approved" id="allowPrescriptionYes"
            formControlName="approved" />
        <label class="radio-label" for="allowPrescriptionYes">Yes</label>
        <input required type="radio" [value]="false" required name="approved" id="allowPrescriptionNo"
            formControlName="approved" />
        <label class="radio-label" for="allowPrescriptionNo">No</label>
    </div>

    <ng-container *ngIf="decisionGroup.get('approved').value === false">
        <label for="allowPresciption">Reason for disapproval?</label>
        <div [style.display]="'flex'" [style.flex-direction]="'column'">
            <div>
                <input type="radio" [value]="'Could not identify patient'" name="reason" id="reason1"
                    formControlName="reason" />
                <label class="radio-label" for="reason1">Could not identify patient</label>
            </div>
            <div>
                <input type="radio" [value]="'Patient is not eligible'" name="reason" id="reason2"
                    formControlName="reason" />
                <label class="radio-label" for="reason2">Patient is not eligible</label>
            </div>
        </div>
    </ng-container>

    <textarea name="decision" class="form-control" formControlName="description" placeholder="Final remarks"
        rows="2"></textarea>

    <div>
        <button [disabled]="isSubmitting" type="submit" class="btn btn-primary">Complete Consultation</button>
    </div>
</form>

<div bsModal #finalizeModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="finalizeModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Finalize</h4>
            </div>
            <div class="modal-body">
                <h3>You are about to complete the consultation</h3>
                <div class="">
                    <button type="button" class="btn btn-danger" (click)="finalizeModal.hide()">Go Back</button>
                    <button type="button" class="btn btn-primary" (click)="addDescision.emit(decisionGroup)">Complete</button>
                </div>
            </div>
        </div>
    </div>
</div>