<div class="login">
    <div class="central_panel">

        <div class="well well--white">

            <img class="site_logo" src="/assets/img/logo.png" alt="Treat it" />

            <h1 class="brand-1">Doctor Portal</h1>
            <div *ngIf="otpDetails === undefined">
                <p>Welcome to the Treat it Doctor Portal. Please provide your details below in order to login to view
                    your orders</p>
                <form #loginForm="ngForm" (ngSubmit)="onLogin()">
                    <div class="alert alert--error" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>

                    <div class="form-group">
                        <label for="username">Email</label>
                        <input id="username" name="username" type="text" autocomplete="username" required
                            [(ngModel)]="model.username" class="form-control" />
                    </div>

                    <div class="form-group">
                        <label for="password">Password</label>
                        <input id="password" name="password" type="password" autocomplete="password" required
                            [(ngModel)]="model.password" class="form-control" />
                    </div>
                    <p>
                        <a routerLink="/forgot-password">Forgot your Password?</a>
                    </p>
                    <p>
                        <button class="btn btn-primary" type="submit" [disabled]="!loginForm.form.valid">Submit</button>
                    </p>
                </form>
            </div>

            <div *ngIf="otpDetails !== undefined">
                <p>Provide the OTP number you generated using the authenticator app</p>
                <form #loginForm="ngForm" (ngSubmit)="onOTPSubmit()">
                    <div class="alert alert--error" *ngIf="errorMessage !== ''">{{errorMessage}}</div>

                    <div class="form-group">
                        <label for="otp">OTP number</label>
                        <input id="otp" name="otp" type="number" autocomplete="otp" required [(ngModel)]="otpModel.topt"
                            class="form-control" />
                    </div>
                    <p>
                        <button class="btn btn-primary" type="submit" [disabled]="!loginForm.form.valid">Verify</button>
                    </p>
                </form>
            </div>

        </div>


    </div>
</div>