import { Component, ViewChild, ElementRef, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-text-chat',
    templateUrl: './text-chat.component.html'
})
export class TextChatComponent {

    @Input() patientName: string;
    @Input() enabled: boolean;
    @Output() sendMessage = new EventEmitter<any>();
    @ViewChild('chat', { static: true }) public chatRef: ElementRef;
    messages: {
      time?: Date,
      text?: string,
      type?: Document,
      question?: boolean,
      sent?: boolean,
      accept?: boolean,
      response?: boolean,
      responded?: boolean,
      isFileMsg?: boolean,
      fileUrl?: string,
      fileType?: string
    }[] = [];
    message = '';

    constructor(private ref: ChangeDetectorRef) { }

    onSendMessage() {
        if (this.message) {
            this.sendMessage.emit({ text: this.message });
            this.message = '';
        }
    }

    onMessageSent(event) {
        this.addMessage(event.data, true);
    }

    onMessageReceived(event) {
        this.addMessage(event.data, false);
    }

    onkeypress(e) {
        let code = (e.keyCode ? e.keyCode : e.which);
        if (code === 13) {
            this.onSendMessage();
        }
    }

    onRespond(text: string, accept: boolean, type: Document) {
        this.sendMessage.emit({ text: text, response: true, accept: accept, type: type });
    }

    private addMessage(message: any, sent: boolean) {

        this.messages.push({
            time: new Date(),
            text: message.text,
            type: message.type,
            question: message.question,
            sent: sent,
            accept: message.accept,
            response: message.response,
            isFileMsg: message.isFileMsg,
            fileType: message.fileType,
            fileUrl: message.fileUrl,
        });
        setTimeout(() => {
            let el = this.chatRef.nativeElement;
            el.scrollTop = el.scrollHeight;
            this.ref.detectChanges();
        });
    }

    getReadableFileType(fileType): string {
      if (fileType === 'PDF') {
        return 'a document';
      }
      return 'an image';
    }
}
