import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-downloads',
  templateUrl: './order-downloads.component.html'
})
export class OrderDownloadComponent implements OnInit {
  @Input() orderItem: any;
  @Input() fitNote: boolean;
  @Input() referralLetter: boolean;
  @Input() prescription: boolean;

  constructor(
    private dataService: DataService,
    private toastr: ToastrService
  ) { }

  ngOnInit() { }

  downloadFitNote(id: number) {
    return this.download(this.dataService.downloadSickNote(id), 'fit-note.html', 'Fit note');
  }

  downloadPrescription(id: number) {
    return this.download(this.dataService.downloadPrescription(id), 'prescription.pdf', 'Prescription');
  }

  downloadReferralLetter(id: number) {
    return this.download(this.dataService.downloadReferralLetter(id), 'referral-letter.html', 'Referral letter');
  }

  private download(observable: Observable<any>, filename: string, type: string) {
    observable.subscribe(
      data => {
        let url = window.URL.createObjectURL(data);
        let anchor = document.createElement('a');

        anchor.setAttribute('href', url);
        anchor.setAttribute('download', filename);
        anchor.click();
        window.URL.revokeObjectURL(url);
        this.toastr.success(`Please check your downloaded files`, `${type} Downloaded`);
      },
      error => this.toastr.error(this.getErrorMessage(error), `Could Not Download ${type}`)
    );
  }

  getErrorMessage(error: any) {
    return error.error?.message || error.message || "An unexpected error occurred";
  }
}
