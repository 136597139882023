<div #chat class="chat">
    <div *ngFor="let msg of messages" [ngClass]="{'chat_row--sent': msg.sent, 'chat_row--action': msg.response, 'is_rejected': !msg.accept, 'is_accepted': msg.accept }"
        class="chat_row">
        <time>{{msg.time | date : 'HH:mm'}}</time>
        <div class="message">
            <div *ngIf="msg.question">
                <p>{{msg.text}}</p>
                <div *ngIf="!msg.sent && !msg.responded">
                    <button class="btn btn--1" (click)="onRespond(msg.type + ' accepted', true, msg.type); msg.responded = true">Accept</button>
                    <button class="btn btn--o_3" (click)="onRespond(msg.type + ' rejected', false, msg.type); msg.responded = true">Reject</button>
                </div>
            </div>
            <div *ngIf="msg.isFileMsg">
              <a href="{{msg.fileUrl}}" target="_blank">
                <p>Patient sent {{getReadableFileType(msg.fileType)}}. Click to open in new tab.</p>
                <img *ngIf="msg.fileType === 'IMG'" src="{{msg.fileUrl}}" style="width: 100px;">
              </a>
            </div>
            <div *ngIf="!msg.question && !msg.isFileMsg">{{msg.text}}</div>
        </div>
    </div>
</div>

<div class="chat_actions">

    <div class="chat_group">
        <input [disabled]="!enabled" type="text" [(ngModel)]="message" (keypress)="onkeypress($event)" placeholder="Enter a message"/>
        <button [disabled]="!enabled" type="submit" (click)="onSendMessage()">Send</button>
    </div>

</div>
