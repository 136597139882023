import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-pink-prescription-modal',
  templateUrl: './pink-prescription-modal.component.html',
  styleUrls: ['./pink-prescription-modal.component.scss'],
})
export class PinkPrescriptionModalComponent implements OnInit, OnDestroy {
  @Input() prescriptions: {
    documents: any[];
    images: any[];
  };
  @ViewChild('pinkPrescriptionModal') modal: ModalDirective;

  private modalEventSubscription: Subscription;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === 'pinkPrescriptionModal') {
        this.modal.show();
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }
}
