export const repeatQuestionnaire = [
    {
        name: "q_1",
        text: "Have you been using your medication as prescribed?",
        type: "choice",
    },
    {
        name: "q_2",
        text: "Has there been a change in your medical condition since your last appointment?",
        type: "choice",
    },
    {
        name: "q_3",
        text: "Has the amount of pain medication you have been taking decreased since beginning your medical cannabis treatment (meaning, have you been consuming less than what was recommended)?",
        type: "choice",
    },
    {
        name: "q_4",
        text: "Have you started any new medication since your last appointment?",
        type: "choice",
    },
    {
        name: "q_5",
        text: "Have you been experiencing  any significant side effects (dizziness, anxiety, heart palpitations) since starting your medical cannabis treatment?",
        type: "choice",
    },
    {
        name: "q_6",
        text: "How do you currently rate your pain level?",
        type: "modal",
    },
    {
        name: "q_7",
        text: "Any other comments or concerns?",
        type: "choice",
    },
];