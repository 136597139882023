import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {

  transform(value: string):string {
    switch (value) {
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
    
      default:
        return value
    }
  }

}
