import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-recommendation-modal',
  templateUrl: './recommendation-modal.component.html',
  styleUrls: ['./recommendation-modal.component.scss']
})
export class RecommendationModalComponent implements OnInit {
  @Input() recommendation: any
  @Input() title: string
  @Output() hideModal = new EventEmitter()

  constructor() { }

  ngOnInit(): void {}

}
