<div class="chat-widget-container">
    <div class="widget-wrapper">
        <div *ngIf="!commentModal.isShown" class="text-container" (click)="commentModal.show()">
            Additional <br> notes
        </div>
        <div class="icon-container" (click)="commentModal.show()"
            [class]="!commentModal.isShown ? 'icon-border' : ''">
            <div *ngIf="!commentModal.isShown">
                <img src="/assets/vectors/clipboard.svg" alt="">
            </div>
            <img *ngIf="commentModal.isShown" src="/assets/vectors/close-icon.svg" alt="">
        </div>
    </div>
</div>

<div bsModal #commentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        
        <div class="modal-body">
            <app-comment-section [orderId]="orderId"></app-comment-section>
        </div>
      </div>
    </div>
  </div>