<div style="display: flex; flex-direction: column; align-items: center;">
  <label style="font-weight: bold; margin-top: 1em; margin-bottom: 0.5em;">Enter your postcode to find pharmacies near you:</label>
  <form autocomplete="off" style="margin-bottom: 0.5em;">
    <div style="display: flex; flex-direction: row;">
      <input class="form-control" style="width: 12em; margin-right: 0.5em;" [(ngModel)]="postcode" name="postcode" placeholder="Enter Postcode" type="text" />
      <button (click)="findPharmacies()" [disabled]="!postcode || searching" class="btn btn-primary">
        <i *ngIf="searching" class="fa fa-spinner fa-spin"></i>
        Find Pharmacies
      </button>
    </div>
    <div style="text-align: start; font-size: 0.9rem;" *ngIf="!pristine && !searching && isPostcodeValid === false">The postcode that you entered is not valid</div>
  </form>
</div>

<div *ngIf="!pristine && !searching && isPostcodeValid" style="display: grid; grid-template-columns: 45% 55%; grid-template-rows: 30em; margin-bottom: 0.5em;">
  <div style="height: 100%; display: flex; flex-direction: column; border: 1px solid #ccc; background: white; padding: 1em;">
    <h3 class="brand-1" style="margin-top: 0.5em; padding-top: 0;">Select a pharmacy</h3>
    <ul style="overflow: scroll; list-style: none; padding: 0;" *ngIf="pharmacies.length">
      <li style="padding: 0.5em 0.75em 0.5em 0.75em;" (click)="trySelectPharmacy(p)" *ngFor="let p of pharmacies" [ngClass]="{ 'pharmacy-selected' : pharmacy?.id === p.id }">
        <strong>{{ p.name }} <span *ngIf="p.distanceInMetres">({{ p.distanceInMetres / 1609.344 | number: '1.0-1' }} miles away)</span></strong>
        <app-address [address]="p.address"></app-address>
        <span style="font-size: 0.9rem;" class="text-success" *ngIf="requireVaccines && (p.settings?.DOES_VACCINES || false)"><strong>Vaccines: </strong>Provided<br /></span>
        <span style="font-size: 0.9rem;" class="text-danger" *ngIf="requireVaccines && !(p.settings?.DOES_VACCINES || false)"><strong>Vaccines: </strong>Not Provided<br /></span>
        <hr style="margin-top: 0.75em; opacity: 0.75;" [style]="{'margin-bottom': (pharmacy?.id === p.id) ? '0.5em' : '0' }" />
        <ng-container *ngIf="pharmacy?.id === p.id">
          <span *ngIf="p.phone"><strong>Phone: </strong>{{ p.phone }}<br /></span>
          <span *ngIf="p.preferences && p.preferences.openingTimes"><strong>Open: </strong>{{ p.preferences.openingTimes }}<br /></span>
          <ng-container *ngIf="deliveryOnly">
            <span *ngIf="p.deliveryNotes">Delivery notes: {{ p.deliveryNotes }}<br /></span>
            <span>Delivery charge:&nbsp;
              <strong *ngIf="p.preferences && !p.preferences.deliveryCharge">Free</strong>
              <strong *ngIf="p.preferences && p.preferences.deliveryCharge">{{ p.preferences.deliveryCharge | currency:'GBP':'symbol':'1.2-2' }}</strong>
              <br />
            </span>
          </ng-container>
          <div style="margin-bottom: 0.5em"></div>
        </ng-container>
      </li>
    </ul>
    <ng-container *ngIf="!pharmacies.length">
      <h4>No Pharmacies Found!</h4>
      <p>
        Unfortunately, we do not have any Member Pharmacies in your area that offer the requested services.
        Our team is constantly adding new pharmacies and will look at your area as a priority.
      </p>
    </ng-container>
  </div>
  <div style="background-color: #ddd; height: 100%;">
    <google-map #googleMap="googleMap" [options]="mapOptions" *ngIf="pharmacies.length" style="height: 100%;">
      <map-marker #marker="mapMarker" (mapClick)="trySelectPharmacy(p);" *ngFor="let p of pharmacies;" [options]="{'icon': markerOptions.icon, 'opacity': (p.id === pharmacy?.id ? 1 : 0.5)}" [position]="{'lat': p.latitude, 'lng': p.longitude}">
        <map-info-window #infoWindow="mapInfoWindow">
          <!-- hack to show the infoWindow every time the pharmacy changes -->
          {{updateInfoWindowState(p.id, pharmacy?.id, infoWindow, marker)}} 
          <strong>{{ p.name }}</strong> <br />
          <app-address [address]="p.address"></app-address>
          <span *ngIf="p.phone"><strong>Phone: </strong>{{ p.phone }}<br /></span>
          <span *ngIf="p.preferences?.openingTimes"><strong>Open: </strong>{{ p.preferences.openingTimes }}<br /></span>
          <span class="text-success" *ngIf="requireVaccines && (p.settings?.DOES_VACCINES || false)"><strong>Vaccines: </strong>Provided<br /></span>
          <span class="text-danger" *ngIf="requireVaccines && !(p.settings?.DOES_VACCINES || false)"><strong>Vaccines: </strong>Not Provided<br /></span>
          <span *ngIf="deliveryOnly">
            {{ p.deliveryNotes }} <br />
            Delivery charge: <strong *ngIf="p.preferences">{{ p.preferences.deliveryCharge | currency:'GBP':'symbol':'1.2-2' }}</strong><strong *ngIf="!p.preferences">N/A</strong><br />
          </span>
        </map-info-window>
      </map-marker>
    </google-map>
  </div>
</div>

<span class="text-danger" *ngIf="requireVaccines">
  <strong>Note: </strong>You may require vaccinations for your trip.
  Please check <a href="http://www.fitfortravel.nhs.uk/" target="_blank">fitfortravel.co.uk</a>
  for up-to-date travel vaccination requirements and contact a local travel health service provider.
</span>