<div class="container" *ngIf="patient">
  <div *ngIf="getAlertsTitle()" style="margin-top: 10px; margin-bottom: 10px;">
    <div class="alert" style="cursor: pointer;" (click)="showAlerts = !showAlerts">
      <div class="key-alert-title">
        <strong>Key message alerts: </strong>
        <span *ngIf="!showAlerts">{{ getAlertsTitle() }}</span>
        <span *ngIf="showAlertsDots()">...</span>
        <span *ngIf="showExpandAlertsBtn()">
          <span *ngIf="!showAlerts" class="align-to-left glyphicon glyphicon-plus" aria-hidden="true"></span>
          <span *ngIf="showAlerts" class="align-to-left glyphicon glyphicon-minus" aria-hidden="true"></span>
        </span>
      </div>

      <div *ngIf="showAlerts" class="key-alerts-inner-container">
        <div *ngIf="patient?.allAlerts?.doctor?.length > 0">
          <strong>From Doctor:</strong>
          <p style="white-space: pre-line;">{{ patient?.allAlerts?.doctor }}</p>
        </div>
        <div *ngIf="patient?.allAlerts?.admin?.length > 0">
          <strong>From Admin:</strong>
          <p style="white-space: pre-line;">{{ patient?.allAlerts?.admin }}</p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!!patient" class="patient-details" [class]="patientDetailsExpanded ? 'expanded' : ''">
    <div class="header">
      <span><b>#{{patient.id}}</b></span>
      <span>|</span>
      <span><b>Name</b>: {{patient.displayName}}</span>
      <span>|</span>
      <span><b>DOB</b>: {{patient.dateOfBirth}}</span>
      <span>|</span>
      <span><b>Discharged</b>: {{patientDischarged}}</span>
      <span *ngIf="!!patient.settings.NHS_NUMBER">NHS: {{patient.settings.NHS_NUMBER}}</span>
      <button (click)="patientDetailsExpanded = !patientDetailsExpanded;">
        <img class="handle" src="/assets/vectors/expand.svg" alt="">
      </button>
    </div>

    <div class="content">
      <div>
        <h4><u><b>Personal details</b></u></h4>
        <table>
          <tr>
            <th>Email</th>
            <th>:</th>
            <td>{{patient.email}}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <th>:</th>
            <td>{{patient.phone}}</td>
          </tr>
          <tr>
            <th>Age</th>
            <th>:</th>
            <td>{{dataService.getPatientBirthday(patient.dateOfBirth)}}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <th>:</th>
            <td>{{patient.gender | gender}}</td>
          </tr>
          <tr>
            <th>Address</th>
            <th>:</th>
            <td>
              {{patient.address.line1}},
              <br *ngIf="!!patient.address.line2"> {{patient.address.line2 ? patient.address.line2 +',' : ''}}
              <br *ngIf="!!patient.address.city">{{patient.address.city ? patient.address.city +', ' : ''}}
              <br *ngIf="!!patient.address.county">{{patient.address.county ? patient.address.county +', ' : ''}}
              <br *ngIf="!!patient.address.postcode">{{patient.address.postcode ? patient.address.postcode +', ' : ''}}
            </td>
          </tr>
        </table>
      </div>
      <div>
        <h4><u><b>Medical details</b></u></h4>
        <table>
          <tr>
            <th>Externally identified</th>
            <th>:</th>
            <td *ngIf="!!patient.settings.EXTERNALLY_IDENTIFIED_BY">
              Yes <b>({{patient.settings.EXTERNALLY_IDENTIFIED_BY}})</b>
            </td>
            <td *ngIf="!patient.settings.EXTERNALLY_IDENTIFIED_BY">No</td>
          </tr>
          <tr>
            <th>SCR Confirmed</th>
            <th>:</th>
            <td *ngIf="!!patient.settings.SCR_CONFIRMED_BY">Yes <b>({{patient.settings.SCR_CONFIRMED_BY}})</b></td>
            <td *ngIf="!patient.settings.SCR_CONFIRMED_BY">No</td>
          </tr>
          <tr>
            <th>NHS verified</th>
            <th>:</th>
            <td>{{patient.nhsVerified ? 'Yes': 'No'}}</td>
          </tr>
          <tr>
            <th>NHS consent given</th>
            <th>:</th>
            <td>{{patient.nhsConsent ? 'Yes': 'No'}}</td>
          </tr>
          <tr>
            <th>GP Practice</th>
            <th>:</th>
            <td>{{patient.preferences.gpPractice}}</td>
          </tr>
          <tr *ngIf="!!GPAddress">
            <th>GP Address</th>
            <th>:</th>
            <td>
              {{GPAddress.line1}},
              <br *ngIf="!!GPAddress.line2"> {{GPAddress.line2 ? GPAddress.line2 +',' : ''}}
              <br *ngIf="!!GPAddress.city">{{GPAddress.city ? GPAddress.city +', ' : ''}}
              <br *ngIf="!!GPAddress.county">{{GPAddress.county ? GPAddress.county +', ' : ''}}
              <br *ngIf="!!GPAddress.postcode">{{GPAddress.postcode ? GPAddress.postcode +', ' : ''}}
            </td>
          </tr>
          <tr>
            <th>Primary Specialists</th>
            <th>:</th>
            <td>{{ careSpecialist }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div class="page-title">
    <h3>Order History</h3>
    <form #searchOrderForm="ngForm" autocomplete="off" novalidate (ngSubmit)="searchOrderForm.valid && searchOrder()">
      <input name="orderId" type="number" class="form-control" [(ngModel)]="searchOrderId" required placeholder="Search by order ID"/>
      <button *ngIf="!searchOrderPresent" [disabled]="!searchOrderForm.valid" type="submit" class="btn btn-primary">Search</button>
      <button *ngIf="searchOrderPresent" type="button" class="btn btn-danger" (click)="resetOrders()">clear</button>
    </form>

    <div style="margin-left: auto">
      <button *ngIf="!!patient" class="btn btn-default" style="margin-right: 10px" (click)="modalService.openModal('patientDocumentModal')">Patient Documents</button>
      <button *ngIf="!!patient && userService.user.settings?.DOCTOR_TYPE === 'GP'" class="btn btn-default" (click)="modalService.openModal('keyAlertModal')">Key Alerts</button>
    </div>

  </div>
  <div *ngIf="isLoading" class="loading-container">
    <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
  </div>

  <div *ngIf="totalPageCount === 0">
    <h4 class="no-appointments">No appointments to display</h4>
  </div>

  <div *ngIf="!isLoading && (searchOrderPresent || totalPageCount > 0)" class="orders-container">
    <div>
      <div class="order-buttons" *ngIf="totalPageCount > 0 && !searchOrderPresent">
        <ng-container *ngFor="let item of orders">
          <button type="button" class="btn" (click)="handleSelectOrder(item)"
            [class]="selectedOrderId === item.id ? 'btn-primary' : 'btn-default'">
            #{{item.id}} - {{getOrderType(item.consultationDuration)}} <br>
            <span>{{getAppointmentDateTime(item.appointment)}}</span>
          </button>
        </ng-container>
      </div>

      <div class="order-buttons" *ngIf="searchOrderPresent">
          <button type="button" class="btn btn-primary">
            #{{selectedOrder.id}} - {{getOrderType(selectedOrder.consultationDuration)}} <br>
            <span>{{getAppointmentDateTime(selectedOrder.appointment)}}</span>
          </button>
      </div>

      <div class="pagination-container" *ngIf="totalPageCount > 0 && !searchOrderPresent">
        <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
          <img class="previous" src="/assets/vectors/expand.svg" alt="">
        </button>
        <span>Page {{page + 1}} of {{totalPageCount}}</span>
        <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
          <img class="next" src="/assets/vectors/expand.svg" alt="">
        </button>
      </div>

    </div>

    <div class="order-details">
      <div style="display: flex; justify-content: space-between">
        <h4 style="">Order #{{selectedOrder.id}} ({{getOrderType(selectedOrder.name)}})</h4>
        <h4>{{selectedOrder.status | orderStatus}}</h4>
      </div>
      <h6 *ngIf="!!selectedOrder.parentOrderItemId">
        Repeat initiated from Order #
        <button (click)="searchOrderId= selectedOrder.parentOrderItemId; searchOrder();">
          <b>{{selectedOrder.parentOrderItemId}}</b>
        </button>
      </h6>

      <ng-container>
        <h4 class="order-title"><b>Patient notes</b></h4>
        <div class="repeat-reason" *ngIf="!!prescriptionChangeNote">
          <span><u>Reason for the repeat order</u></span>
          <p>
            <b>{{prescriptionChangeNote?.descriptionKey}}</b> - {{prescriptionChangeNote.description}}
          </p>
        </div>

        <div class="notes-container">

          <button *ngIf="!!selectedOrder && selectedOrder.patient.files > 0" class="btn btn-default" (click)="modalService.openModal('SCRModal')">Patient Uploaded Documents</button>
          <button *ngIf="!!selectedOrder && !!selectedOrder.pictures && !!selectedOrder.pictures.length"
				  	class="btn btn-default" (click)="modalService.openModal('consultationDocuments')" style="margin-right: 1rem;">
				  	Consultation documents
				  </button>
          <button *ngIf="!!selectedOrder && !!patientComplaint"
				  	class="btn btn-default" (click)="modalService.openModal('consultationNotes')" style="margin-right: 1rem;">
				  	Consultation notes
				  </button>
          <button *ngIf="!!repeatOrderQuestionnaire" class="btn btn-default"
            (click)="repeatOrderNoteModal.show()">Repeat Proforma</button>
          <button *ngIf="!!selectedOrder.parentOrderItemId" class="btn btn-default" (click)="viewPegNote.show()">PEG
            Form</button>
          <button *ngIf="selectedSideEffectedNote !== undefined" class="btn btn-default" (click)="viewSideEffectNote.show()">Side Effect
            Form</button>
        </div>
      </ng-container>

      <ng-container *ngIf="!['MINUTES_00', 'IN_PERSON_MINUTE_00'].includes(selectedOrder.consultationDuration)">
        <h4 class="order-title"><b>GP notes</b></h4>

        <div class="note-state">
          <ng-container *ngIf="!gpDecision; else gpDecisionExist">
            <img src="/assets/vectors/pending.svg" alt="">
            <h5><b>GP Pending</b></h5>
          </ng-container>

          <ng-template #gpDecisionExist>
            <img [src]="gpDecision.approved ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
            <h5>
              <b>{{gpDecision.approved ? 'GP Approved' : 'GP Rejected'}}</b>
              {{!!gpDecision.description ? ' - '+gpDecision.description: ''}}
            </h5>
          </ng-template>
        </div>

        <ng-container *ngIf="!!gpDecision">
          <div class="notes-container">
            <button class="btn btn-default" (click)="modalService.openModal('gpNotes')">Questionnaire</button>
            <button *ngIf="selectedGPRecommendation !== undefined" class="btn btn-default"
              (click)="viewGPRecommendations.show()">GP Recommendation</button>
          </div>

          <div class="notes-container">
            <button *ngIf="!!selectedAuditNote" class="btn btn-default" (click)="viewAuditNote.show()">
              AUDIT Note
            </button>
            <button *ngIf="!selectedOrder.parentOrderItemId && !!selectedPegNote" class="btn btn-default"
              (click)="viewPegNote.show()">
              PEG Note
            </button>
            <button *ngIf="!!selectedGadNote" class="btn btn-default" (click)="viewGadNote.show()">
              GAD Note
            </button>
            <button *ngIf="!!selectedConsentNote" class="btn btn-default" (click)="viewConsentNote.show()">
              Consent Note
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedOrder.consultationDuration === 'IN_PERSON_MINUTE_00'">
        <h4 class="order-title"><b>Consulted Pharmacy notes</b></h4>

        <div class="note-state">
          <ng-container *ngIf="!prescribingPharmacyDecision; else prescribingDecisionExist">
            <img src="/assets/vectors/pending.svg" alt="">
            <h5><b>Prescribing Pharmacy Pending</b></h5>
          </ng-container>

          <ng-template #prescribingDecisionExist>
            <img [src]="prescribingPharmacyDecision.approved ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
            <h5>
              <b>{{prescribingPharmacyDecision.approved ? 'Prescribing Pharmacy Approved' : 'Prescribing Pharmacy Rejected'}}</b>
              <!-- {{!!gpDecision.description ? ' - '+gpDecision.description: ''}} -->
            </h5>
          </ng-template>
        </div>

        <ng-container *ngIf="!!prescribingPharmacyDecision">
          <div class="notes-container">
            <button class="btn btn-default" (click)="modalService.openModal('pharmacyConsultationNotes')">Questionnaire</button>
            <!-- <button *ngIf="selectedGPRecommendation !== undefined" class="btn btn-default"
              (click)="viewGPRecommendations.show()">GP Recommendation</button> -->
          </div>

          <div class="notes-container">
            <button *ngIf="!!selectedAuditNote" class="btn btn-default" (click)="viewAuditNote.show()">
              AUDIT Note
            </button>
            <button *ngIf="!selectedOrder.parentOrderItemId && !!selectedPegNote" class="btn btn-default"
              (click)="viewPegNote.show()">
              PEG Note
            </button>
            <button *ngIf="!!selectedGadNote" class="btn btn-default" (click)="viewGadNote.show()">
              GAD Note
            </button>
            <button *ngIf="!!selectedConsentNote" class="btn btn-default" (click)="viewConsentNote.show()">
              Consent Note
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!!gpDecision || selectedOrder.consultationDuration === 'IN_PERSON_MINUTE_00'">
        <h4 class="order-title"><b>MDT notes</b></h4>

        <div class="note-state">
          <ng-container *ngIf="!mdtDecision; else mdtDecisionExist">
            <img src="/assets/vectors/pending.svg" alt="">
            <h5><b>MDT Pending</b></h5>
          </ng-container>

          <ng-template #mdtDecisionExist>
            <img [src]="mdtDecision.approved ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
            <h5>
              <b>{{mdtDecision.approved ? 'MDT Approved' : 'MDT Rejected'}}</b>
            </h5>
          </ng-template>
        </div>

        <div class="notes-container">
          <button *ngIf="selectedMDTRecommendation !== undefined" class="btn btn-default"
            (click)="viewMDTRecommendations.show()">Recommendation</button>
          <button *ngIf="selectedProforma !== undefined" class="btn btn-default"
            (click)="viewMDTProforma.show()">Proforma</button>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedOrder.consultationDuration === 'MINUTES_00' || (!!mdtDecision && mdtDecision.approved)">
        <h4 class="order-title"><b>Specialist notes</b></h4>

        <div class="note-state">
          <ng-container *ngIf="!specialistDecision; else specialistDecisionExist">
            <img src="/assets/vectors/pending.svg" alt="">
            <h5><b>Specialist Pending</b></h5>
          </ng-container>

          <ng-template #specialistDecisionExist>
            <img [src]="specialistDecision.approved ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
            <h5>
              <b>{{specialistDecision.approved ? 'Specialist Approved' : 'Specialist Rejected'}}</b>
              {{!!specialistDecision.description ? ' - '+specialistDecision.description: ''}}
            </h5>
          </ng-template>
        </div>

        <p *ngIf="!!specialistDecision" style="margin-left: 2.3rem;">
          #{{specialistDecision.doctor.id}} <b>{{specialistDecision.doctor.displayName}}</b> ({{specialistDecision.doctor.settings.QUALIFICATIONS}})
        </p>

        <ng-container *ngIf="!!pinkPrescriptions">
          <p *ngIf="!!getPinkPrescriptionReferenceNo()" style="margin-left: 2.3rem;">
            <b>Pink prescription reference:</b> {{getPinkPrescriptionReferenceNo()}}
          </p>
        </ng-container>

        <div class="notes-container" *ngIf="!!specialistDecision">
          <button *ngIf="!!selectedPrescriptions" class="btn btn-default"
            (click)="specialistPrescription.show()">Prescription</button>
          <button *ngIf="!!specialistCallPatient && !!specialistCallPatient.questionAnswer"
          class="btn btn-default" (click)="SpecialistPatientCallModal.show()">
            Call Proforma
          </button>
          <button *ngIf="!!specialistMessages.length" class="btn btn-default" (click)="messagePatientModal.show()">
            Conversations
          </button>
          <button *ngIf="!!pinkPrescriptions" class="btn btn-default" (click)="modalService.openModal('pinkPrescriptionModal')">
            Pink Prescription
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="pharmacyDecision == undefined && pharmacyRejectDecision !== undefined">
        <h4 class="order-title"><b>Rejected</b></h4>

        <div *ngFor="let decision of pharmacyRejectDecisions; let i = index">
          <div class="note-state">
            <img src="/assets/vectors/close.svg" alt="">
            <h5>
              <b>Prescription Rejected {{ i + 1 }}</b>
              {{ !!pharmacyRejectDecision ? ' - ' + decision.description : '' }} <br>
            </h5>
          </div>

          <div class="notes-content prescription-margin block-element">
            <button *ngIf="!!pharmacyRejectDecision" class="btn btn-default" (click)="openRejectedPharmacyPrescriptions(decision, i)">
              Rejected Prescription {{ i + 1 }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!!pharmacyRejectDecision || !!pharmacyDecision">
        <h4 class="order-title"><b>Pharmacy notes</b></h4>

        <div class="note-state">
          <ng-container *ngIf="!pharmacyRejectDecision && !pharmacyDecision; else pharmacyDecisionExist">
            <img src="/assets/vectors/pending.svg" alt="">
            <h5><b>Pharmacy Pending</b></h5>
          </ng-container>

          <ng-template #pharmacyDecisionExist>
            <img [src]="!!pharmacyDecision ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
            <h5>
              <b>{{!!pharmacyDecision ? 'Pharmacy Approved' : 'Pharmacy Rejected'}}</b>
              {{!!pharmacyRejectDecision ? ' - '+pharmacyRejectDecision.description: ''}} <br>
            </h5>
          </ng-template>
        </div>
        <span *ngIf="!!pharmacyRejectDecision" style="margin-left: 2.3rem;">
          Rejected tracking code: {{pharmacyRejectDecision.trackingCode}}
        </span>
        <span *ngIf="!!pharmacyDecision && !!prescriptionTrackingCode" style="margin-left: 2.3rem;">
            Tracking code: {{prescriptionTrackingCode}}
        </span>

        <div *ngIf="!!patientPharmacyDecision" class="note-state">
          <img [src]="patientPharmacyDecision.approved ? '/assets/vectors/success.svg' : '/assets/vectors/close.svg'" alt="">
          <h5>
            <b>{{patientPharmacyDecision.approved ? 'Patient Accepted ' : 'Patient Rejected '}}</b>
            <span *ngIf="!!patientPharmacyDecision.description">- {{patientPharmacyDecision.description}}</span>
          </h5>
        </div>

        <div class="note-information">
          <h5><b>Pharmacy Details</b></h5>
          <p>
            ID: {{getPharmacy().id}} <br>
            Name: {{getPharmacy().displayName}} <br>
            Contact: {{getPharmacy().email}}
          </p>
        </div>

        <div class="notes-content">
          <button *ngIf="!!pharmacyRejectDecision" class="btn btn-default" (click)="rejectedPharmacyPrescription.show()">
            Rejected Prescription
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <app-chat-widget *ngIf="!!selectedOrderId" [orderId]="selectedOrderId"></app-chat-widget>
</div>

<app-admin-key-alert-modal *ngIf="!!patient" [patient]="patient"></app-admin-key-alert-modal>
<app-patient-document-modal *ngIf="!!patient" [patient]="patient"></app-patient-document-modal>
<app-patient-scr-documents-modal *ngIf="!!selectedOrder && selectedOrder.patient.files > 0" [order]="selectedOrder"></app-patient-scr-documents-modal>
<app-consultation-documents-modal *ngIf="!!selectedOrder && !!selectedOrder.pictures && !!selectedOrder.pictures.length" [documents]="selectedOrder.pictures"></app-consultation-documents-modal>
<app-consultation-notes-modal *ngIf="!!selectedOrder && !!patientComplaint" [notes]="patientComplaint"></app-consultation-notes-modal>

<app-pink-prescription-modal *ngIf="!!pinkPrescriptions" [prescriptions]="pinkPrescriptions">
</app-pink-prescription-modal>

<app-gp-notes-modal *ngIf="!!selectedOrder" [orderstatus]="selectedOrder.status" [orderId]="selectedOrder.id"></app-gp-notes-modal>
<app-pharmacy-consultation-note-modal *ngIf="!!selectedGpNote" [gpNote]="selectedGpNote"></app-pharmacy-consultation-note-modal>

<div bsModal #viewPegNote="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedPegNote !== undefined">
    <app-peg-note-modal [pegNote]="selectedPegNote.note" (hideModal)="viewPegNote.hide()"></app-peg-note-modal>
  </ng-container>
</div>

<div bsModal #viewSideEffectNote="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedSideEffectedNote !== undefined">
    <app-side-effect-note-modal [sideEffectNote]="selectedSideEffectedNote.note" (hideModal)="viewSideEffectNote.hide()"></app-side-effect-note-modal>
  </ng-container>
</div>

<div bsModal #viewAuditNote="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedAuditNote !== undefined">
    <app-audit-note-modal [auditNote]="selectedAuditNote" (hideModal)="viewAuditNote.hide()">
    </app-audit-note-modal>
  </ng-container>
</div>

<div bsModal #viewGadNote="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedGadNote !== undefined">
    <app-gad-note-modal [gadNote]="selectedGadNote.note" (hideModal)="viewGadNote.hide()">
    </app-gad-note-modal>
  </ng-container>
</div>

<div bsModal #viewConsentNote="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedConsentNote !== undefined">
    <app-consent-note-modal [consentNote]="selectedConsentNote" (hideModal)="viewConsentNote.hide()">
    </app-consent-note-modal>
  </ng-container>
</div>

<div bsModal #viewGPRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedGPRecommendation !== undefined">
    <app-recommendation-modal [title]="'GP'" [recommendation]="selectedGPRecommendation"
      (hideModal)="viewGPRecommendations.hide()">
    </app-recommendation-modal>
  </ng-container>
</div>

<div bsModal #viewRepeatOrderRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-hidden="true">
  <ng-container *ngIf="repeatOrderRecommendation !== undefined">
    <app-recommendation-modal [title]="'Repeat order'" [recommendation]="repeatOrderRecommendation"
      (hideModal)="viewRepeatOrderRecommendations.hide()">
    </app-recommendation-modal>
  </ng-container>
</div>

<div bsModal #repeatOrderNoteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="repeatOrderNoteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Repeat Order Note</h4>
      </div>
      <div class="modal-body">
        <app-repeat-order-note *ngIf="repeatOrderQuestionnaire" [note]="repeatOrderQuestionnaire">
        </app-repeat-order-note>
      </div>
    </div>
  </div>
</div>

<div bsModal #viewMDTRecommendations="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedMDTRecommendation !== undefined">
    <app-recommendation-modal [title]="'MDT'" [recommendation]="selectedMDTRecommendation"
      (hideModal)="viewMDTRecommendations.hide()">
    </app-recommendation-modal>
  </ng-container>
</div>

<div bsModal #viewMDTProforma="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedProforma !== undefined">
    <app-mdt-proforma-modal [proformaNotes]="selectedProforma" (hideModal)="viewMDTProforma.hide()">
    </app-mdt-proforma-modal>
  </ng-container>
</div>

<div bsModal #specialistPrescription="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="selectedPrescriptions !== undefined">
    <app-prescription-modal [prescription]="selectedPrescriptions" [additionalNotes]="selectedAdditionalNote" [creationDate]="selectedCreationDate"
      (hideModal)="specialistPrescription.hide()">
    </app-prescription-modal>
  </ng-container>
</div>

<div bsModal #messagePatientModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div *ngIf="!!specialistMessages" class="modal-dialog message-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="messagePatientModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Patient - Specialist conversation</h4>
      </div>
      <div class="modal-body">
        <div class="message-section">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of specialistMessages">
                <td>
                  <div class="date-bubble">{{item.creationDate | date : 'dd-MMM-yyyy HH:mm'}}</div>
                  <span>{{getMessage(item.questionAnswer).question}}</span>
                </td>
                <td>
                  <div class="date-bubble">{{item.updateDate | date : 'dd-MMM-yyyy HH:mm'}}</div>
                  <span [class]="!item.patient ? 'waiting-bubble' : ''">
                    {{item.patient ? getMessage(item.questionAnswer).answer : 'Waiting for reply.'}}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div bsModal #SpecialistPatientCallModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="SpecialistPatientCallModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Specialist - Patient call proforma</h4>
      </div>
      <div *ngIf="!!specialistCallPatient && !!specialistCallPatient.questionAnswer" class="modal-body">
        <p>
          {{specialistCallPatient.questionAnswer}}
        </p>
      </div>
    </div>
  </div>
</div>


<div bsModal #rejectedPharmacyPrescription="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="pharmacyRejectDecision !== undefined">
    <app-prescription-modal [prescription]="pharmacyRejectDecision.medication" [pinkPrescriptionURL]="pinkPrescriptionURL" [creationDate]="pharmacyRejectDecision.creationDate"
      (hideModal)="rejectedPharmacyPrescription.hide()">
    </app-prescription-modal>
  </ng-container>
</div>

<div bsModal #rejectedPharmacyPrescriptions="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="tempPharmacyRejectDecisions !== undefined">
    <app-prescription-modal [prescription]="tempPharmacyRejectDecisions.medication" [pinkPrescriptionURL]="tempPharmacyRejectDecisions.pinkPrescription" [creationDate]="tempPharmacyRejectDecisions.creationDate"
      (hideModal)="rejectedPharmacyPrescriptions.hide()">
    </app-prescription-modal>
  </ng-container>
</div>

<!-- <div bsModal #rejectedPharmacyPrescriptions="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <ng-container *ngIf="pharmacyRejectDecisions && pharmacyRejectDecisions.length > 0">
    <ng-container *ngFor="let decision of pharmacyRejectDecisions">
      <app-prescription-modal [prescription]="decision.medication" [pinkPrescriptionURL]="pinkPrescriptionURL"
        (hideModal)="rejectedPharmacyPrescriptions.hide()">
      </app-prescription-modal>
    </ng-container>
  </ng-container>
</div> -->
