<div class="modal-dialog patient-modal">
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close"><span
                    aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">
                Consent Note
            </h4>
        </div>
        <div class="modal-body">
            <section class="patient-content">
                <h3>The patient has confirmed that:</h3>
                <div *ngFor="let item of consentForm | keyvalue"
                    class="patient-response">
                    <div class="patient-statement">
                        <img src="/assets/vectors/success.svg" alt="">
                        <h4>{{item.value}}</h4>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>