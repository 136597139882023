<div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="hideModal.emit()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">PEG Form</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="pegGroup" autocomplete="off">
          <div *ngFor="let item of pegForm | keyvalue" class="modal-question">
            <span><b>{{item.value}}</b></span>
            <div class="custom-prg-slider">
              <ngx-slider [options]="options" [formControlName]="item.key"></ngx-slider>
            </div>
<!--            <app-pain-scale-->
<!--              [buttons]="buttonsArray"-->
<!--              [numbers]="numbersArray"-->
<!--              [pegValue]="pegGroup.get(item.key).value"-->
<!--              [key]="item.key"-->
<!--              (buttonClicked)="handleButtonClicked($event)"-->
<!--            ></app-pain-scale>-->

            <div *ngIf="item.key=='painInPastWeek'" style="display: flex; justify-content: space-between; margin-top: 5px">
              <span>0 - No pain</span>
              <span>10 - Pain as bad as you can imagine</span>
            </div>
            <div *ngIf="item.key!='painInPastWeek'" style="display: flex; justify-content: space-between">
              <span>0 - Does not interfere</span>
              <span>10 - Completely interferes</span>
            </div>
          </div>
        </form>
        <div class="score-container">
          <span>Final score </span>
          <div class="score-label">
            {{average}}
          </div>
        </div>
      </div>
    </div>
  </div>
