import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './account.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

/**
 * This page is used in three scenarios:
 *  1. When doctor requests to reset his password, via 'forgot password' page
 *  2. When the password is 3 months old - system mandated password change
 *  3. When the doctor logs in for the very first time - system mandated password change
 */

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  model: {
    token?: string,
    email?: string,
  };
  success: any;
  message: any;
  passwordForm: UntypedFormGroup;
  isProcessing = false;

  constructor(private accountService: AccountService,
              private activatedRoute: ActivatedRoute,
              private notificationService: ToastrService,
              private fb: UntypedFormBuilder,
              private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (param: any) => {
        this.model = {
          token: param?.token,
          email: param?.username
        };
      }
    );

    const crossValidators = [passwordCrossValidator];

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, this.validatePassword]],
      passwordConfirmation: ['', [Validators.required]],
    }, { validators: crossValidators });

    if (!this.model.token) { // no token, mandatory password reset scenario
      this.passwordForm.addControl('currentPassword', new UntypedFormControl(
        '',
        Validators.required
      ));
      crossValidators.push(currentAndNewPasswordValidator);
      this.passwordForm.setValidators(crossValidators);
    }
  }

  validatePassword(field: UntypedFormControl): ValidationErrors | null {
    if (field.value?.length < 8) {
      return {fieldError: 'Password must be at least 8 characters long.'};
    }
    if (!(/[A-Z]+/.test(field.value))) { // at least one upper-case char
      return {fieldError: 'Password must contain at least 1 upper-case character.'};
    }
    if (!(/[a-z]+/.test(field.value))) { // at least one lower-case char
      return {fieldError: 'Password must contain at least 1 lower-case character.'};
    }
    if (!(/[0-9]+/.test(field.value))) { // at least one number
      return {fieldError: 'Password must contain at least 1 number.'};
    }
    if (!(/[~`!@#$%^&\-*()_+=|:\\;"'[<\]>,.?{}/€£¥•=]+/.test(field.value))) { // at least one special char
      return {fieldError: 'Password must contain at least 1 special character.'};
    }
    return null;
  }

  resetPassword(): void {
    if (!this.passwordForm.valid) {
      return;
    }
    const newPassword = this.passwordForm.get('password').value;
    this.isProcessing = true;
    if (this.model.token) { // user requested password reset
      this.accountService.resetPassword(this.model.email, this.model.token, newPassword)
        .pipe(finalize(() => {
          this.isProcessing = false;
        }))
        .subscribe((response) => {
          this.success = true;
          this.message = 'Your password has been reset.<br/>Please <a href="/login">login</a> with your new password.';
        }, (error) => {
          if (error.status === 401) {
            this.message = 'The token you provided is not valid or may have expired already.';
          } else {
            this.message = 'Something went wrong';
          }
        });
    } else { // no token, mandatory password reset scenario
      const currentPassword = this.passwordForm.get('currentPassword').value;
      this.isProcessing = true;
      this.accountService.changePassword(this.model.email, currentPassword, newPassword)
        .pipe(finalize(() => {
          this.isProcessing = false;
        }))
        .subscribe((result: { value: string }) => {
          this.success = true;
          this.notificationService.success('Password change', 'Password changed successfully.');
          this.accountService.authenticate(result.value);
          this.router.navigateByUrl('/');
        }, (error) => {
          console.log(error);
          this.success = false;
          this.message = error?.error?.message || 'Something went wrong.';
        });
    }
  }
}

/** Ensure that new password and confirm password fields are equal */
const passwordCrossValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const password = formGroup.get('password').value;
  const passwordConfirmation = formGroup.get('passwordConfirmation').value;
  return password === passwordConfirmation ? null : { error: 'The passwords do not match.' };
};

/** Ensure that old and new passwords are different */
const currentAndNewPasswordValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const password = formGroup.get('password').value;
  const currentPassword = formGroup.get('currentPassword').value;
  return password === currentPassword ? { samePasswordError: 'New password can\'t be the same as the current password.' } : null;
};
