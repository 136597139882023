import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserService } from '../../account/user.service';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CreatePrescriptionComponent } from '../create-prescription/create-prescription.component';

enum DoctorType {
    REGULAR_DOCTOR,
    COMPANY_DOCTOR,
    COMPANY_VET,
    COMPANY_PRESCRIBING_PHARMACIST,
    PRESCRIBING_DOCTOR,
    SURGERY_ADMIN
}

@Component({
    selector: 'app-list-prescriptions',
    templateUrl: './list-prescriptions.component.html',
    styleUrls: ['./list-prescriptions.component.scss'],
})
export class ListPrescriptionsComponent implements OnInit {
    public orderItems: any[] = [];
    public pin: any;
    private currentAppointmentId: number;
    public searchTerm = '';
    public searching = true;
    public doctorType: DoctorType;
    public isPetOrder = false;
    public DoctorType = DoctorType;
    public showPrescriptionModal = sessionStorage.getItem('showPrescriptionModal') === 'true';
    @ViewChild('createPrescriptionModal', { static: true }) createPrescriptionModal: ModalDirective;
    @ViewChild('completeModal', { static: true }) completeModal: ModalDirective;

    constructor(
        private dataService: DataService,
        private userService: UserService,
        private toastr: ToastrService) {
    }

    ngOnInit() {
        this.userService.user$.pipe(filter(x => x != null), take(1)).subscribe(user => {
            this.doctorType = (user?.prescriptionCompany?.id == null) ? DoctorType.REGULAR_DOCTOR
                : user?.settings?.DOCTOR_TYPE == 'VET' ? DoctorType.COMPANY_VET
                : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST' ? DoctorType.COMPANY_PRESCRIBING_PHARMACIST
                : user?.settings?.DOCTOR_TYPE == 'SURGERY_ADMIN' ? DoctorType.SURGERY_ADMIN
                : user?.settings?.DOCTOR_TYPE == 'PRESCRIBING_DOCTOR' ? DoctorType.PRESCRIBING_DOCTOR
                : DoctorType.COMPANY_DOCTOR;
            this.getOrderItems();
        })
        if (this.isPrescribingDoctor() && this.showPrescriptionModal) {
            this.createPrescriptionModal.show();
            sessionStorage.setItem("showPrescriptionModal", 'false');

        }
    }

    closeCreatePrescriptionModal() {
        this.createPrescriptionModal.hide(); 
        sessionStorage.setItem("showPrescriptionModal", 'false');
    }

    isCompanyDoctor() {
        return [DoctorType.COMPANY_DOCTOR, DoctorType.COMPANY_VET, DoctorType.COMPANY_PRESCRIBING_PHARMACIST].includes(this.doctorType);
    }

    isPrescribingDoctor() {
        return [DoctorType.PRESCRIBING_DOCTOR, DoctorType.SURGERY_ADMIN].includes(this.doctorType);
    }

    getOrderItems() {
        this.searching = true;
        const getOrders: Observable<any> = this.isCompanyDoctor() || this.isPrescribingDoctor() ? this.dataService.getCompanyOrders() : this.dataService.getAwaitingDoctorActionOrders()
        getOrders.subscribe(orderItems => {
            this.orderItems = orderItems.content;
            this.searching = false;
        });
    }

    openAppointmentActionModal(appointmentId) {
        this.currentAppointmentId = appointmentId;
        this.completeModal.show();
    }

    completeAppointmentPrescription() {
        this.dataService.confirmAppointmentPrescription(this.currentAppointmentId, this.pin).subscribe(result => {
            this.toastr.success("Prescription completed successfully", "Prescription Completed");
            this.currentAppointmentId = 0;
            this.getOrderItems();
        }, error => {
            console.log(error);
            this.toastr.error(this.getErrorMessage(error), "Could Not Complete Prescription");
        });
        this.pin = '';
        this.completeModal.hide();
    }

    openCreatePrescriptionModal(isPetOrder: boolean) {
        this.isPetOrder = isPetOrder;
        this.createPrescriptionModal.show();
    }

    completeCreatePrescription(event: { success: boolean, result: any, error: any }, modal: CreatePrescriptionComponent) {
        if (event.success) {
            console.log(event.result);
            this.toastr.success("Prescription created successfully", "Prescription Created");
            modal.ngOnInit();
            // Close modal and refresh orders list
            this.createPrescriptionModal.hide();
            this.getOrderItems();
        } else {
            console.error(event.error);
            this.toastr.error(this.getErrorMessage(event.error), "Could Not Create Prescription");

        }
    }

    getErrorMessage(error: any) {
        return error.error?.message || error.message || "An unexpected error occurred";
    }
}
