import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'app-order-summary',
    templateUrl: './order-summary.component.html'
})
export class OrderSummaryComponent implements OnInit {
    doctorNote: any;
    order: any;
    patient: any;

    constructor(
        private orderService: DataService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            let orderItemId = +params['orderItemId'];
            this.orderService.getOrderItem(orderItemId).subscribe(orderItem => {
                forkJoin([
                    this.orderService.getDoctorNote(orderItem.appointmentId),
                    this.orderService.getPatient(orderItem.patientId)
                ]).subscribe(([doctorNote, patient]) => {
                    this.order = orderItem;
                    this.doctorNote = doctorNote;
                    this.patient = patient;
                });
            });

        });
    }

    print() {
        window.print();
    }
}
