<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="onCancel()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
				<h4 class="modal-title">Enter Pin Code</h4>
			</div>

			<div class="modal-body">
				<form #pinForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off" novalidate>
					<div class="form-group">
						<!--<label for="pin">Pin</label>-->
						<input id="pin" class="form-control" type="password" name="pin" required [(ngModel)]="pin" />
					</div>
					<div>
						<button type="reset" class="btn btn-default" (click)="onCancel()">Cancel</button>
						<button type="submit" class="btn btn-primary" [disabled]="pinForm.form.invalid">Ok</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>