import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatusPipe } from '../pipes/order-status.pipe';
import { SpecialistService } from './specialist.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss'],
})
export class OrdersPageComponent implements OnInit {
  initLoading: boolean = true;
  allOrders: boolean;
  totalPageCount: number;
  page = 0;
  public searchValue: any = null;
  public searchType: string = null;

  constructor(
    private specialistService: SpecialistService,
    private router: Router,
    private toastrService: ToastrService,
  ) {}

  private fetchOrders(searchType?: string, searchValue?: string): void {
    this.initLoading = true;
    this.specialistService.getAllOrders(searchType, searchValue, this.page).subscribe({
      next: (result) => {
        this.allOrders = result.content;
        this.totalPageCount = result.totalPages;
        this.initLoading = false;
      },
    });
  }

  ngOnInit(): void {
    this.searchValue = '';
    this.searchType = 'id';
    this.fetchOrders();
  }

  getPharmacistEmail(email:string){
    return email.split('@');
  }

  handlePageCount(increase: boolean): void {
    if (increase) {
      this.page += 1;
    } else {
      this.page -= 1;
    }

    if (this.searchValue !== '') {
      this.fetchOrders(this.searchType, this.searchValue);
    } else {
      this.fetchOrders();
    }
  }

  routeToReview(orderId: number) {
    this.router.navigate([`pending-orders/${orderId}/review`]);
  }

  getOrderStatus(status: string) {
    let orderStatusPipe = new OrderStatusPipe();
    return orderStatusPipe.transform(status);
  }

  getButtonStatus(status: string) {
    if (status === 'AWAITING_SPECIALIST_CALL_PATIENT') {
      return 'btn-primary';
    }

    if (status === 'SPECIALIST_REJECTED') {
      return 'btn-danger';
    }

    if (status === 'WAITING_FOR_PHARMACY_APPROVAL') {
      return 'btn-success';
    }

    return 'btn-info';
  }

  // Get the search value from the search box
  public onSearchInput(searchType: string, searchValue: string): void {
    this.page = 0;
    // Trim leading and trailing white spaces from searchValue
    searchValue = searchValue.trim();

    // Validate searchValue based on searchType
    if (searchType === 'id' && !(/^\d+$/.test(searchValue))) {
      // Display an error message for invalid input
      this.toastrService.error('', 'Please enter numeric values for ID.');
      return;
    }
    if (searchType === 'name' && (/^\d+$/.test(searchValue))) {
      // Display an error message for invalid input
      this.toastrService.error('', 'Please enter a Patient Name');
      return;
    }

    // Check if searchValue contains special characters
    if (/[^a-zA-Z0-9\s]/.test(searchValue)) {
      // Display an error message for invalid input
      this.toastrService.error('', 'Please enter a Patient Name without special characters');
      return;
    }

    this.fetchOrders(searchType, searchValue);
  }

  // Reset the search bar
  public onResetSearch(): void {
    this.page = 0;
    this.searchValue = '';
    this.searchType = 'id';
    this.fetchOrders();
  }
}
