import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../components/modals/modal.service';
import { OrderNotesService } from '../order-notes.service';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-mdt-session-item',
  templateUrl: './mdt-session-item.component.html',
  styleUrls: ['./mdt-session-item.component.scss'],
})
export class MdtSessionItemComponent implements OnInit {
  @Input() sessionOwner: boolean;
  @Input() sessionView: boolean;
  @Input() sessionAvailable = true;
  @Input() order: any;
  @Output() removeSession = new EventEmitter();
  @Output() sessionUpdated = new EventEmitter();
  @ViewChild('prescriptionModal') prescriptionModal: ModalDirective;
  @ViewChild('viewGPSNotes', { static: true }) viewGPSNotes: any;
  @ViewChild('viewPatientNotes', { static: true }) viewPatientNotes: any;
  @ViewChild('viewGPRecommendations', { static: true })
  viewGPRecommendations: any;
  @ViewChild('viewMDTRecommendations', { static: true })
  viewMDTRecommendations: any;

  approved = false;
  expanded = false;
  isSubmitting = false;

  selectedOrderNotes: any[];
  selectedGPRecommendation: any;
  selectedGPDecision: any;
  selectedMDTRecommendation: any;
  selectedMDTDecision: any;
  selectedGpNote: any;

  constructor(
    private mdtService: MdtService,
    private toastr: ToastrService,
    private orderNotesService: OrderNotesService,
    protected modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.selectedOrderNotes = this.order.orderNotes;
    this.selectedGpNote = this.orderNotesService.getGPConsultationNote(this.order.orderNotes);
    this.order.orderNotes.forEach((note) => {
      switch (note.type) {
        case 'GP_RECOMMENDATION':
          this.selectedGPRecommendation = JSON.parse(note.formulary);
          break;

        case 'MDT_RECOMMENDATION':
          this.selectedMDTRecommendation = JSON.parse(note.formulary);
          break;

        case 'GP_APPROVAL_DECISION':
          this.selectedGPDecision = note;
          break;

        case 'MDT_DECISION':
          this.selectedMDTDecision = note;
          break;

        default:
          break;
      }
    });
  }

  addToSession(orderId: number) {}

  removeFromSession(orderId: number) {
    this.removeSession.emit(orderId);
  }

  private addDecision(decision) {
    this.isSubmitting = true;
    this.mdtService.createMDTDecision(this.order.id, decision).subscribe({
      next: (value) => {
        this.toastr.success('', 'Decision added successfully!');
        this.sessionUpdated.emit();
        this.isSubmitting = false;
      },
      error: (err) => {
        console.log(err);
        this.isSubmitting = false;
      },
    });
  }

  getOrderStatus(orderNotes: any) {
    const decisionNote = orderNotes.filter(
      (note) => note.type === 'MDT_DECISION'
    );
    return decisionNote[0].approved;
  }
}
