import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './account.service';

@Component({
    selector: 'app-reset-pin',
    templateUrl: './reset-pin.component.html'
})
export class ResetPinComponent implements OnInit {

    model: any;
    response: any;
    errorMessage: any;

    constructor(
        private accountService: AccountService,
        private activatedRoute: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            this.model = {
                token: params['token'],
                email: params['username']
            };
        });
    }

    submit() {
        this.accountService.resetPin(this.model.email, this.model.token, this.model.newPassword).subscribe(
          (response) => {
              this.response = response;
          },
          (error) => {
            if (error.status == 401) {
              this.errorMessage = 'The token you have provided is not valid or may have expired already.';
            } else {
              this.errorMessage = 'Something went wrong';
            }
          });
    }
}
