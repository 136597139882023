import { Component, Inject, ViewChild, ElementRef, Input, Output, AfterViewInit, EventEmitter, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { VideoTokenService } from './video-token.service';
import { GlobalOptions } from '../app.module';

declare var OT: any;

@Component({
    selector: 'app-video-call',
    templateUrl: './video-call.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoCallComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() order: any;
    @Output() messageSent = new EventEmitter<any>();
    @Output() messageReceived = new EventEmitter<any>();
    @Output() endCall = new EventEmitter<any>(false);
    @Output() updateSignalForm = new EventEmitter<any>();
    @ViewChild('subscriber', { static: true }) subscriberRef: ElementRef;
    @ViewChild('publisher', { static: true }) publisherRef: ElementRef;
    public session: any;

    public connectionTime = 0;
    public formattedTime: string;
    public timerSubscription: Subscription;
    private publisher: any;

    public streamCreated = false;
    public sessionPublished = false;
    public connections = 0;
    public me: string;
    public them: string;
    private userType: string;
    public hasConnected = false;
    public videoSupported = true;
    public tokenResponse: any;
    public hasError = false;
    public errorMessage = '';
    public isConnected = false;
    numberOfTicks = 0;

    constructor(
        private toastr: ToastrService,
        private videoTokenService: VideoTokenService,
        private ref: ChangeDetectorRef,
        @Inject('global_options') private options: GlobalOptions) {
            setInterval(() => {
                this.numberOfTicks++;
                // require view to be updated
                this.ref.markForCheck();
            }, 1000);
         }

    ngOnInit() {
        this.videoSupported = OT.checkSystemRequirements();
    }

    ngAfterViewInit() {

        if (!this.videoSupported) { return; }

        this.videoTokenService.getToken(this.order.appointment.id).subscribe(
            (response) => {
                this.tokenResponse = response;
                this.userType = response.userType.toLowerCase();
                this.them = this.userType === 'doctor' ? 'Patient' : 'Doctor';
                this.me = this.userType === 'doctor' ? 'Doctor' : 'Patient';
                this.session = OT.initSession(this.options.openTokApiKey, response.sessionId);

                this.session
                    .on('connectionCreated', event => {
                        this.connections++;
                        this.isConnected = true
                        this.startStopTimer();
                        // TODO: send message history, connection time etc
                    })
                    .on('connectionDestroyed', event => {
                        this.connections--;
                        this.startStopTimer();
                    })
                    .on('streamCreated', event => {
                        this.streamCreated = true;
                        this.hasConnected = true;
                        this.startStopTimer();
                        this.session.subscribe(event.stream, this.subscriberRef.nativeElement, {
                            insertMode: 'append',
                            width: '100%',
                            height: '100%'
                        });
                    })
                    .on('streamDestroyed', event => {
                        this.streamCreated = false;
                        this.startStopTimer();
                    })
                    .on('signal:msg', event => {
                        if (event.data.type !== undefined) {
                            this.updateSignalForm.emit(
                                {key: event.data.type, status: "filled"}
                            )
                        } else {
                            let data = JSON.parse(event.from.data);

                            if (this.userType.toLowerCase() === data.userType.toLowerCase()) {
                                this.messageSent.emit(event);
                            } else {
                                if (typeof event.data === 'string' || event.data instanceof String) {
                                    event.data = JSON.parse(event.data);
                                }
                                this.messageReceived.emit(event);
                            }
                        }
                    });

                this.session.connect(response.token, error => {
                    if (error) {
                        console.log('There was an error connecting to the session: ', error.code, error.message);
                    }
                });
            },
            (error) => {
                if (error.error) {
                    this.errorMessage = error.error.message;
                } else if (error.message) {
                    this.errorMessage = error.message;
                } else {
                    this.errorMessage = "Something went wrong. Please refresh the page or try again later."
                }
                this.hasError = true;
            });
    }

    get videoConnected(): boolean {
        return this.streamCreated && this.connections > 1 && this.sessionPublished;
    }

    connect() {
        let publisherOptions:any = {
            insertMode: 'append',
            width: '100%',
            height: '100%'
        }
        if (OT.hasMediaProcessorSupport()) {
            publisherOptions = {
                ...publisherOptions,
                videoFilter: {
                    type: 'backgroundBlur'
                }
            }
        }
        this.publisher = OT.initPublisher(this.publisherRef.nativeElement, {
            ...publisherOptions
        });
        this.session.publish(this.publisher, () => {
            this.sessionPublished = true;
            this.startStopTimer();
        });
    }

    disconnect() {
        this.session.unpublish(this.publisher);
        this.sessionPublished = false;
        this.startStopTimer();
    }

    startStopTimer() {
        if (this.videoConnected) {
            this.startTimer();
        } else {
            this.stopTimer();
        }
    }

    startTimer() {
        if (this.timerSubscription) {
            return;
        }
        this.timerSubscription = timer(0, 1000).subscribe(t => {
            this.connectionTime++;

            let minutes = parseInt((this.connectionTime / 60).toString(), 10) % 60;
            let seconds = this.connectionTime % 60;

            this.formattedTime = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
        });
    }

    stopTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
        this.timerSubscription = null;
    }

    sendMessage(message: any) {
        this.session.signal({
            type: 'msg',
            data: message
        }, (error: any) => {
            console.log(error)
            this.toastr.error(this.getErrorMessage(error), 'Could Not Send Message');
        });
    }

    updateVerification(verified:any){
        this.session.signal({
            type: 'accept',
            data: verified
        }, (error: any) => {
            this.toastr.error(this.getErrorMessage(error), 'Could not update verification');
        });
    }

    signalForForms(type: string){
        this.session.signal({
            type: 'requestQuestionnaire',
            data: {
                type
            }
        }, (error: any) => {
            this.toastr.error(this.getErrorMessage(error), 'Could not request!');
        });
        this.updateSignalForm.emit(
            {key: type, status: "pending"}
        )
    }
    
    updateCompletion(approval:any){
        try {
            this.session?.signal({
                type: 'complete',
                data: {
                    completed: true,
                    ...approval
                }
            }, (error: any) => {
                this.toastr.error(this.getErrorMessage(error), 'Could not update completion status');
            });
        } catch (error) {
            console.log(error)
        }
    }

    ngOnDestroy() {
        if (!this.session) { return; }
        this.session.disconnect();
    }

    getErrorMessage(error: any) {
        return error.error?.message || error.message || "An unexpected error occurred";
    }

}
