import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login.component';
import { AuthQuicksilva } from './account/auth-quicksilva.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentComponent } from './appointments/appointment.component';
import { ListPrescriptionsComponent } from './prescriptions/list-prescriptions/list-prescriptions.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order.component';
import { AuthGuard } from './account/auth.guard';
import { HistoryComponent } from './orders/history.component';
import { PatientComponent } from './patient/patient.component';
import { EditPatientNoteComponent } from './patient/edit-patient-note.component';
import { ReferralLetterComponent } from './documents/referral-letter.component';
import { FitNoteComponent } from './documents/fit-note.component';
import { PrescriptionComponent } from './documents/prescription.component';
import { OrderSummaryComponent } from './documents/order-summary.component';
import { ChangePasswordComponent } from './account/change-password.component';
import { ChangePinComponent } from './account/change-pin.component';
import { ForgotPasswordComponent } from './account/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password.component';
import { ResetPinComponent } from './account/reset-pin.component';
import { MyAccountComponent } from './account/my-account.component';
import { DoctorInfoComponent } from './doctor-info/doctor-info.component';
import { SummaryCareRecordsComponent } from './summary-care-records/summary-care-records.component';
import { ConsentComponent } from './account/consent/consent.component';
import { RestrictionGuard } from './restriction.guard';
import { CreatePrescriptionComponent } from './prescriptions/create-prescription/create-prescription.component';
import { TwoFactorAuthComponent } from './account/two-factor-auth.component';
import { MdtOrdersComponent } from './mdt-orders/mdt-orders.component';
import { MdtSessionComponent } from './mdt-orders/mdt-session.component';
import { OrdersPageComponent } from './specialist-orders/orders-page.component';
import { MdtOrderReviewComponent } from './mdt-orders/mdt-order-review.component';
import { RoleGuard } from './role.guard';
import { MdtDashboardComponent } from './mdt-orders/mdt-dashboard.component';
import { SpecialistOrderReviewComponent } from './specialist-orders/specialist-order-review.component';
import { TrackOrdersComponent } from './specialist-orders/track-orders.component';
import { PastSessionsComponent } from './mdt-orders/past-sessions.component';
import { PastOrdersComponent } from './specialist-orders/past-orders.component';
import { FileUploaderComponent } from './specialist-orders/file-uploader.component';
import { PastSessionItemComponent } from './mdt-orders/past-session-item.component';
import { FormularyViewComponent } from './formulary-view/formulary-view.component';
import { PatientListComponent } from './patient/patient-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard, RestrictionGuard, RoleGuard],
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'orders/:orderItemId',
    component: OrderComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'patients',
    component: PatientListComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'patient/:patientId',
    component: PatientComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'edit-patient-note/:orderItemId',
    component: EditPatientNoteComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'appointments',
    component: AppointmentsComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'appointments/:orderItemId',
    component: AppointmentComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'prescriptions',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list',
        component: ListPrescriptionsComponent,
      },
      {
        path: 'create',
        component: CreatePrescriptionComponent,
      },
      {
        path: ':orderItemId',
        component: PrescriptionComponent,
      },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'auth/quicksilva',
    component: AuthQuicksilva,
  },
  {
    path: 'consent',
    component: ConsentComponent,
  },
  {
    path: 'fit-note/:orderItemId',
    component: FitNoteComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'referral-letter/:orderItemId',
    component: ReferralLetterComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'summary/:orderItemId',
    component: OrderSummaryComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'reset-pin',
    component: ResetPinComponent,
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-pin',
    component: ChangePinComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'two-factor-auth',
    component: TwoFactorAuthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'doctor-info',
    component: DoctorInfoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'summary-care-records',
    component: SummaryCareRecordsComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  //mdt
  {
    path: 'mdt-dashboard',
    component: MdtDashboardComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'mdt-orders',
    component: MdtOrdersComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'mdt-orders/:orderId/review',
    component: MdtOrderReviewComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'mdt-session',
    component: MdtSessionComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'past-sessions',
    component: PastSessionsComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'past-session/:sessionId',
    component: PastSessionItemComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  // Specialist
  {
    path: 'pending-orders',
    component: OrdersPageComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'pending-orders/:orderId/review',
    component: SpecialistOrderReviewComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'pending-orders/:orderId/review/parent/:parentId',
    component: SpecialistOrderReviewComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'past-orders',
    component: PastOrdersComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'tracking-numbers',
    component: TrackOrdersComponent,
    canActivate: [AuthGuard, RestrictionGuard],
  },
  {
    path: 'upload-files',
    component: FileUploaderComponent,
  },
  //common
  {
    path: 'formulary',
    component: FormularyViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/orders',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
