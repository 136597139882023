<div class="container">
    <h1 class="brand-1">Ongoing session</h1>

    <ng-container *ngIf="activeSession !== undefined">
        <div class="session-action-container" *ngIf="sessionOwner">
            <ng-container *ngIf="activeSession.status !== 'AWAITING_TEAM_APPROVAL'; else ownerAwaitingApproval">
                <button *ngIf="canFinalize" class="btn btn-primary"
                    (click)="onFinalizeSession()">Finalize session</button>
            </ng-container>

            <ng-template #ownerAwaitingApproval>
                <span class="approved-label">You have finalised and approved the session</span>
            </ng-template>
        </div>
        <div class="session-action-container" *ngIf="!sessionOwner">
            <ng-container *ngIf="activeSession.status === 'AWAITING_TEAM_APPROVAL'">
                <button *ngIf="!getApprovalVisibility().voted; else viewerVoted" class="btn btn-primary"
                    (click)="sessionApprovalModal.show()">Provide session approval</button>
            </ng-container>

            <ng-template #viewerVoted>
                <span *ngIf="getApprovalVisibility().approved" class="approved-label">You approved this session</span>
                <span *ngIf="!getApprovalVisibility().approved" class="rejected-label">You rejected this session</span>
            </ng-template>
        </div>
    </ng-container>

    <div class="session-list">
        <app-mdt-session-item *ngFor="let order of orders" [order]="order" [sessionOwner]="sessionOwner"
            [sessionView]="true" (removeSession)="removeFromSession($event)" (sessionUpdated)="onSessionUpdated()">
        </app-mdt-session-item>
    </div>
</div>

<div bsModal #ordersModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="ordersModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Orders</h4>
            </div>
            <div class="modal-body">
            </div>
        </div>
    </div>
</div>

<div bsModal #sessionApprovalModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="sessionApprovalModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Session approval</h4>
            </div>
            <div class="modal-body">
                <form #decisionForm="ngForm" (ngSubmit)="onSessionApproval(decisionForm)" novalidate autocomplete="off">
                    <label for="allowPresciption">Approve this session?</label>
                    <div>
                        <input required type="radio" [(ngModel)]="memberApproved" [value]="true" required
                            name="approved" id="allowPrescriptionYes" />
                        <label class="radio-label" for="allowPrescriptionYes" [style.margin-right]="'1rem'">Yes</label>
                        <input required type="radio" [(ngModel)]="memberApproved" [value]="false" required
                            name="approved" id="allowPrescriptionNo" />
                        <label class="radio-label" for="allowPrescriptionNo">No</label>
                    </div>

                    <textarea [style.margin-top]="'1rem'" [style.width]="'75%'" name="decision" class="form-control"
                        [(ngModel)]="memberDescription" placeholder="Final remarks" rows="2"></textarea>

                    <button class="btn btn-primary" [style.margin-top]="'1rem'">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>