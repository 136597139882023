import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, lastValueFrom } from 'rxjs';
import { CONSENT_FORM } from 'src/assets/patientQuestionnaires';
import { DataService } from '../data.service';
import { OrderNotesService } from '../order-notes.service';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.scss']
})
export class ConsentFormComponent implements OnInit, OnChanges {
  @Input() consentNote: any;
  @Input() orderId: any;
  @Output() hideModal = new EventEmitter();

  consentGroup: FormGroup;
  consentData: any;
  consentId: any;

  constructor(
    private dataService: DataService,
    private orderNotesService: OrderNotesService,
  ) { }

  private async initializeConsentNotes(){
    if (!!this.consentNote) {
      this.consentData = this.consentNote.note;
      this.consentId = this.consentNote.id;
    }
  }

  private createConsentNotesForm() {
    const noteControls = {};
    Object.keys(this.consentForm).forEach((key: string) => {
      noteControls[key] = new FormControl(
        this.consentData ? this.consentData[key] : undefined,
        [Validators.required]
      );
    });

    this.consentGroup = new FormGroup(noteControls);
    this.onConsentNotesChange();
  }

  private onConsentNotesChange() {
    this.consentGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      if (!this.consentId) {
        const consentData = this.getConsentData();
        await lastValueFrom(this.dataService.createPatientNote(this.orderId, consentData));
        const consentNote = await lastValueFrom(this.dataService.getPatientConsent(this.orderId));
        this.consentId = consentNote[0].id;
      } else {
        const consentData = this.getConsentData();
        consentData['id'] = this.consentId;
        await lastValueFrom(this.dataService.updatePatientNote(this.orderId, consentData));
      }
    });
  }

  private updateConsentNotesForm(): void {
    if (!!this.consentGroup) { 
      this.consentGroup.patchValue(this.consentData);
    }
  }

  ngOnInit(): void {
    this.initializeConsentNotes();
    this.createConsentNotesForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.consentNote) {
      this.initializeConsentNotes();
    this.updateConsentNotesForm();
    }
  }

  getConsentData() {
    let formValues = this.consentGroup.value;
    return {
      type: 'PATIENT_DETAIL_CONFIRMATION',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  get consentForm() {
    return CONSENT_FORM;
  }

}
