<div bsModal #patientDocumentsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="patientDocumentsModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">Patient documents</h4>
            </div>
            <div class="modal-body patient-files-container">
                <ng-container *ngIf="patientFiles.images || patientFiles.documents">
                    <ng-container *ngIf="patientFiles.documents">
                        <div class="documents-container">
                            <a *ngFor="let item of patientFiles.documents" class="file-item" [href]="item.url"
                                target="_blank" rel="noopener noreferrer">
                                <img src="/assets/vectors/pdf-icon.svg" alt="">
                                <span>{{item.category}}</span>
                            </a>
                        </div>
                    </ng-container>
    
                    <ng-container *ngIf="patientFiles.images">
                        <div *ngFor="let item of patientFiles.images">
                            <h3>{{item.category}}</h3>
                            <div class="file-container">
                                <img [src]="item.url" alt="">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>