import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
    public orderItems: any[] = [];
    public searchTerm = '';
    public searching: boolean;
    searchQuery: string = '';
    currentPage: number = 0;
    lastPage: number = 0;

    constructor(private dataService: DataService) {
    }

    ngOnInit() {
        this.loadOrders();
    }

    loadOrders() {
        this.searching = true;
        this.dataService.getCompleteOrders(null, this.currentPage, 10).subscribe(orderItems => {
            this.orderItems = orderItems.content;
            this.lastPage = orderItems.totalPages ? orderItems.totalPages - 1 : 0;
            this.searching = false;
        });
    }

    search() {
        this.loadOrders();
    }

    changePage(page: number) {
        this.currentPage = page;
        this.loadOrders();
    }
    isNumberOrLetter(event) {
        const pattern = /^[a-z0-9]+$/i;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}
