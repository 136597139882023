import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PEG_FORM } from 'src/assets/patientQuestionnaires';

@Component({
  selector: 'app-peg-note-modal',
  templateUrl: './peg-note-modal.component.html',
  styleUrls: ['./peg-note-modal.component.scss']
})
export class PegNoteModalComponent implements OnInit {
  @Input() pegNote: any;
  @Output() hideModal = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  get pegForm(){
    return PEG_FORM
  }

}
