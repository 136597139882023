import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { filter, lastValueFrom, take } from 'rxjs';
import { UserService } from './account/user.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private userService: UserService, private router:Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const source$ = this.userService.user$.pipe(
      filter((u) => u != null),
      take(1)
    );
    const user = await lastValueFrom(source$);

    switch (user.settings?.DOCTOR_TYPE) {
      case "GP":
        this.router.navigate(["appointments"])
        break;

      case "MDT":
        this.router.navigate(["mdt-dashboard"])
        break;

      case "SPECIALIST":
        this.router.navigate(["pending-orders"])
        break;
    
      default:
        return true;
    }
  }
}
