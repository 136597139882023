import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html'
})
export class MyAccountComponent implements OnInit {
    user: any;

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.userService.getUser().subscribe(u => {
            this.user = u;
        });
    }
}
