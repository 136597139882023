<div class="comment-section">
    <h4 class="comment-title">Additional Notes</h4>
    <div #chatContainer [scrollTop]="chatContainer.scrollHeight" class="comment-list" [ngClass]="{standard: standard}">
        <ng-container *ngFor="let comment of commentList" >
            <p class="comment-stats">
                by <span><b>{{comment.doctor.displayName}}</b></span>
                on <span><b>{{comment.creationDate | date : 'dd-MMM-yyyy  HH:mm'}}</b></span>
            </p>
            <p class="comment-bubble">{{comment.description}}</p>
        </ng-container>
    </div>
    <form #commentForm="ngForm" (ngSubmit)="addComment(commentForm)" class="comment-form">
        <textarea type="text" title="You can resize this text-area" class="form-control" name="comment" [(ngModel)]="comment" required [rows]="standard ? 2 : 10"></textarea>
        <button class="btn">
            <img src="/assets/vectors/send.svg" alt="">
        </button>
    </form>
</div>