import { IEnvironmentType } from 'src/types';

export const environment: IEnvironmentType = {
  production: true,
  apiEndPoint: 'https://api.treat-it.clinic/',
  openTokApiKey: '46057882',
  dataDog: {
    applicationId: 'ead20f81-de88-4b7b-9a6f-ec971b5a8cce',
    clientToken: 'pubeb80aaa75911efa8d906aab57e355057',
    service: 'treat-it-doctor-app',
    site: 'datadoghq.eu',
  },
  name: "prod",
};
