import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from '../account/user.service';

@Component({
    selector: 'app-prescription',
    templateUrl: './prescription.component.html',
    styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit {
    prescription: any;
    doctorNote: any;
    order: any;
    isPetOrder: boolean = false;
    patient: any;
    parsed: boolean = false;
    printed: boolean = false;
    ready: boolean = false;
    medicationsContainUnlicensedUse: boolean = false;

    constructor(
        private userService: UserService,
        private orderService: DataService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activatedRoute.params.subscribe((params) => {
            let orderItemId = +params['orderItemId'];
            this.orderService.getOrderItem(orderItemId).subscribe(orderItem => {
                forkJoin([
                    this.orderService.getPrescription(orderItemId),
                    this.orderService.getDoctorNote(orderItem.appointment?.id),
                ]).subscribe(([prescription, doctorNote]) => {
                    this.order = orderItem;
                    this.prescription = prescription;
                    this.isPetOrder = !!prescription?.pet?.id;
                    this.doctorNote = doctorNote;
                    this.patient = orderItem.patient;
                    this.ready = true;
                });
            });
        });
        if (this.prescription && this.prescription.medication) {
            this.prescription.medication.forEach(med => {
                if (med.unlicensedUse) {
                    this.medicationsContainUnlicensedUse = true;
                }
            });
        }
    }

    print() {
        window.print();
    }

    getIdentifier() {
        const profile = this.userService.user;
        if (profile.settings.DOCTOR_TYPE == 'VET') {
            return 'RCV';
        }
        if (profile.settings.DOCTOR_TYPE == 'PRESCRIBING_PHARMACIST') {
            return 'GPhC';
        }
        return 'GMC';
    }

    addressLine(address: any, line: number) {
        switch (line) {
            case 1: return address.line1;
            case 2: return address.line2;
            case 3: return [address.city, address.county, address.postcode].filter(p => p).join(", ")
        }
    }
}
