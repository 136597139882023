<div class="container">
    <h1 class="brand-1">All Patients</h1>

    <div class="patient-search-container">
        <input #patientInput="ngModel" name="gpPostcodeInput" type="text" [(ngModel)]="patientValue"
            (input)="onPatientInput($event.target.value)" class="form-control"
            placeholder="Search by patient Name, ID" />
    </div>

    <div *ngIf="initLoading" class="loading-container">
        <i class="fa fa-spinner fa-spin" style="font-size: 2rem;"></i>
    </div>

    <ng-container *ngIf="!initLoading">
        <div class="pagination-container">
            <span>Page {{page + 1}} of {{totalPageCount}}</span>
            <button class="btn btn-default" [disabled]="page === 0" (click)="handlePageCount(false)">
                <img class="previous" src="/assets/vectors/expand.svg" alt="">
            </button>
            <button class="btn btn-default" [disabled]="page === totalPageCount - 1" (click)="handlePageCount(true)">
                <img class="next" src="/assets/vectors/expand.svg" alt="">
            </button>
        </div>

        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>DOB</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>SCR reviewed</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of patientList">
                    <td>{{item.id}}</td>
                    <td>{{item.displayName}}</td>
                    <td>{{item.dateOfBirth}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.scrValidityConfirmation ? 'Yes' : 'No'}}</td>
                    <td><a class="btn btn-default" routerLink="/patient/{{item.id}}">View Patient</a></td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>