import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { combineLatest, forkJoin } from 'rxjs';
import { REJECTION_LIST } from 'src/assets/statusMappings';
import { OrderNotesService } from '../order-notes.service';
import { SpecialistService } from '../specialist-orders/specialist.service';
import { PatientNotesModalComponent } from '../common/modals/patient-notes-modal.component';
import { ModalService } from '../components/modals/modal.service';
import { Location } from '@angular/common';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit {
  @ViewChild('patientNotes') patientNotes: PatientNotesModalComponent;
  @ViewChild('rejectedPharmacyPrescriptions', { static: false }) rejectedPharmacyPrescriptions: ModalDirective;
  patient: any;
  patientId: number;
  orders: any[];
  latestOrder: any;
  gpAddress: any;
  patientDetailsExpanded = false;
  careSpecialist = "";
  public showAlerts: boolean = false;
  public patientDischarged = "";

  protected selectedOrderId: number;
  protected selectedOrder: any;
  protected totalPageCount: number;
  protected page: number;
  protected isLoading: boolean;
  protected searchOrderId: number;
  protected paramOrderId: number;
  protected searchOrderPresent = false;

  //Order Notes
  protected gpDecision: any;
  protected prescribingPharmacyDecision: any;
  protected selectedGpNote: any;
  protected selectedPegNote: any;
  protected selectedSideEffectedNote: any;
  protected selectedAuditNote: any;
  protected selectedGadNote: any;
  protected selectedConsentNote: any;
  protected selectedGPRecommendation: any;
  protected mdtDecision: any;
  protected selectedMDTRecommendation: any;
  protected selectedProforma: any;
  protected specialistMessages: any;
  protected specialistCallPatient: any;
  protected specialistDecision: any;
  protected selectedPrescriptions: any;
  protected prescriptionTrackingCode: any;
  protected selectedAdditionalNote: any;
  protected selectedCreationDate: any;
  protected pharmacyRejectDecision: any; //for a single rejected prescription
  protected pharmacyRejectDecisions: any; //for multiple rejected prescriptions
  protected tempPharmacyRejectDecisions: any;
  protected pharmacyDecision: any;
  protected patientPharmacyDecision: any;
  protected repeatOrderRecommendation: any;
  protected prescriptionChangeNote: any;
  protected repeatOrderQuestionnaire: any;
  protected pinkPrescriptions: any;
  protected patientComplaint: any;
  protected pinkPrescriptionURL: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    protected dataService: DataService,
    private orderNotesService: OrderNotesService,
    private specialistService: SpecialistService,
    protected modalService: ModalService,
    protected router: Router
  ) {}

  refresh() {
    this.isLoading = true;
    forkJoin([
      this.dataService.getPatient(this.patientId),
      this.dataService.getExtendedOrders(this.patientId, this.page),
    ]).subscribe((data) => {
      this.patient = data[0];
      this.careSpecialist = this.patient?.careSpecialist ? this.patient.careSpecialist : "";
      this.patientDischarged = this.patient?.discharged ? "Yes" : "No";

      this.orders = data[1].content;
      this.totalPageCount = data[1].totalPages;
      if (!this.latestOrder) {
        this.latestOrder = data[1].content[0];
      }
      if (!!this.orders.length) {
        this.handleSelectOrder(this.orders[0]);
      }
      this.isLoading = false;
    });
  }

  refreshForSingleOrder() {
    this.isLoading = true;
    forkJoin([
      this.dataService.getPatient(this.patientId),
      this.dataService.getOrderItem(this.paramOrderId),
    ]).subscribe((data) => {
      this.patient = data[0];
      this.handleSelectOrder(data[1]);
      this.searchOrderPresent = true;
      this.isLoading = false;
    });
  }

  searchOrder() {
    this.isLoading = true;
    this.dataService.getOrderItem(this.searchOrderId).subscribe({
      next: (value) => {
        if (value.patient.id == this.patientId) {
          this.handleSelectOrder(value);
          this.searchOrderPresent = true;
          this.isLoading = false;
        } else {
          this.router.navigate([`/patient/${value.patient.id}`], {
            queryParams: { orderId: this.searchOrderId },
          });
        }
      },
    });
  }

  resetOrders() {
    this.searchOrderPresent = false;
    this.searchOrderId = undefined;
    if (!!this.paramOrderId) {
      this.paramOrderId = undefined;
      this.location.back();
    } else {
      if (!!this.orders) {
        this.handleSelectOrder(this.orders[0]);
      } else {
        this.refresh();
      }
    }
  }

  loadPatientComplaint(order) {
    this.patientComplaint = order.orderNotes.find((note) => note.type === 'PRESENTING_COMPLAINT')?.description
  }

  ngOnInit() {
    this.page = 0;
    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
    ]).subscribe(([params, queryParams]) => {
      this.patientId = +params['patientId'];
      this.paramOrderId = +queryParams['orderId'];

      if (!!this.paramOrderId) {
        this.refreshForSingleOrder();
      } else {
        this.refresh();
      }
    });
  }

  handleSelectOrder(order: any) {
    this.loadPatientComplaint(order)
    if (!!this.selectedOrderId && this.selectedOrderId === order.id) {
      this.selectedOrderId = undefined;
      return;
    }

    this.resetNotes();

    this.selectedOrder = order;
    this.selectedOrderId = order.id;

    this.gpDecision = this.orderNotesService.getGPDecision(order.orderNotes);
    this.prescribingPharmacyDecision = this.orderNotesService.getPrescribingPharmacyDecision(order.orderNotes);
    this.selectedGpNote = this.orderNotesService.getGPConsultationNote(
      order.orderNotes
    );
    this.selectedPegNote = this.orderNotesService.getPEGNote(order.orderNotes);
    this.selectedSideEffectedNote = this.orderNotesService.getSideEffectNote(order.orderNotes);
    this.selectedAuditNote = this.orderNotesService.getAUDITNote(
      order.orderNotes
    );
    this.selectedGadNote = this.orderNotesService.getGADNote(order.orderNotes);
    this.selectedConsentNote = this.orderNotesService.getConsentNote(
      order.orderNotes
    );
    this.selectedGPRecommendation = this.orderNotesService.getGPRecommendation(
      order.orderNotes
    );
    this.mdtDecision = this.orderNotesService.getMDTDecision(order.orderNotes);
    this.selectedMDTRecommendation =
      this.orderNotesService.getMDTRecommendation(order.orderNotes);
    this.selectedProforma = this.orderNotesService.getMDTProforma(
      order.orderNotes
    );
    this.specialistMessages = this.orderNotesService.getSpecialistMessages(
      order.orderNotes
    );
    this.specialistDecision = this.orderNotesService.getSpecialistDecision(
      order.orderNotes
    );
    this.specialistCallPatient =
      this.orderNotesService.getSpecialistCallPatient(order.orderNotes);
    this.repeatOrderRecommendation =
      this.orderNotesService.getRepeatOrderRecommendation(order.orderNotes);
    this.prescriptionChangeNote =
      this.orderNotesService.getRepeatOrderChangeReason(order.orderNotes);
    this.repeatOrderQuestionnaire =
      this.orderNotesService.getRepeatOrderQuestionnaire(order.orderNotes);
    this.pinkPrescriptions = this.orderNotesService.getPinkPrescription(
      order.files
    );

    if (!!this.specialistDecision && this.specialistDecision.approved) {
      this.specialistService.getPrescription(order.id).subscribe({
        next: (result) => {
          this.selectedPrescriptions = result.medication;
          this.prescriptionTrackingCode = result.trackingCode;
          this.selectedAdditionalNote = result.additionalPatientNotes;
          this.selectedCreationDate = result.creationDate;
        },
      });
    }

    this.pharmacyRejectDecision =
      this.orderNotesService.getPharmacyRejectReason(order.orderNotes);
    this.pharmacyDecision = this.orderNotesService.getPharmacyDecision(
      order.orderNotes
    );

    this.pharmacyRejectDecisions = this.orderNotesService.getPharmacyRejectedPrescriptions(order.orderNotes);

    this.patientPharmacyDecision = this.orderNotesService.getPatientPharmacyDecision(order.orderNotes);

    // tslint:disable-next-line:max-line-length
    if (this.selectedOrder && this.pharmacyRejectDecision && this.selectedOrder.files && this.selectedOrder.files[0] && this.selectedOrder.files[0].url) {
      this.pinkPrescriptionURL = this.selectedOrder.files[0].url;
    }
  }

  resetNotes(): void {
    this.selectedOrder = undefined;
    this.selectedOrderId = undefined;
    this.gpDecision = undefined;
    this.selectedGpNote = undefined;
    this.selectedPegNote = undefined;
    this.selectedSideEffectedNote = undefined;
    this.selectedAuditNote = undefined;
    this.selectedGadNote = undefined;
    this.selectedConsentNote = undefined;
    this.selectedGPRecommendation = undefined;
    this.mdtDecision = undefined;
    this.selectedMDTRecommendation = undefined;
    this.selectedProforma = undefined;
    this.specialistMessages = undefined;
    this.specialistDecision = undefined;
    this.repeatOrderRecommendation = undefined;
    this.prescriptionChangeNote = undefined;
    this.repeatOrderQuestionnaire = undefined;
    this.pinkPrescriptions = undefined;
    this.selectedPrescriptions = undefined;
    this.prescriptionTrackingCode = undefined;
    this.selectedAdditionalNote = undefined;
    this.pharmacyRejectDecision = undefined;
    this.pharmacyRejectDecisions = undefined;
    this.tempPharmacyRejectDecisions = undefined;
    this.pharmacyDecision = undefined;
    this.patientPharmacyDecision = undefined;
    this.specialistCallPatient = undefined;
  }

  getHeaderLabelStatus(status: string) {
    if (status === 'DELIVERED') {
      return 'success';
    }

    if (REJECTION_LIST.includes(status)) {
      return 'failed';
    }

    return 'default';
  }

  handlePageCount(increase: boolean) {
    if (increase) {
      this.page += 1;
    } else {
      this.page -= 1;
    }

    this.refresh();
  }

  getMessage(value: string) {
    return JSON.parse(value);
  }

  getOrderType(duration: string) {
    switch (duration) {
      case 'MINUTES_30':
        return 'Initial consultation';
      case 'MINUTES_15':
        return 'Repeat consultation';
      case 'MINUTES_00':
        return 'Repeat prescription';
      case 'IN_PERSON_MINUTE_00':
        return 'Pharmacy consultation';
      default:
        return 'Consultation';
    }
  }

  getAppointmentDateTime(appointment: any) {
    if (!!appointment) {
      const appointmentDate = new Date(appointment.datetime);
      const year = appointmentDate.getFullYear();
      const month = String(appointmentDate.getMonth() + 1).padStart(2, '0');
      const day = String(appointmentDate.getDate()).padStart(2, '0');
      const hours = String(appointmentDate.getHours()).padStart(2, '0');
      const minutes = String(appointmentDate.getMinutes()).padStart(2, '0');
      const period = Number(hours) < 12 ? 'am' : 'pm';

      return `${year}/${month}/${day} ${hours}:${minutes} ${period}`;
    }

    return '';
  }

  getPharmacy() {
    if (!!this.pharmacyRejectDecision) {
      return this.pharmacyRejectDecision.pharmacy;
    }

    return this.pharmacyDecision.pharmacy;
  }

  getPinkPrescriptionReferenceNo(){
    if (!!this.pinkPrescriptions.images.length) {
      return this.pinkPrescriptions.images[0].reference;
    }

    if (!!this.pinkPrescriptions.documents.length) {
      return this.pinkPrescriptions.documents[0].reference;
    }

    return "";
  }

  get GPAddress() {
    if (this.patient) {
      return this.patient.preferences?.gpAddress;
    }
    return undefined;
  }

  public openRejectedPharmacyPrescriptions(data: any, index: number): void {
    this.tempPharmacyRejectDecisions = data;

    if (index + 1 === this.pharmacyRejectDecisions.length) {
      this.tempPharmacyRejectDecisions.pinkPrescription = this.pinkPrescriptionURL;
    } else {
      this.tempPharmacyRejectDecisions.pinkPrescription = null;
    }
    this.rejectedPharmacyPrescriptions.show();
  }
}
