<div *ngIf="gpDecision" class="gp-decision" [class]="gpDecision.approved ? 'approved' : ''">
    <h5 *ngIf="gpDecision.approved">GP approves this order</h5>
    <h5 *ngIf="!gpDecision.approved">GP does not approve this order</h5>
    <p><b>Follow up description:</b> {{gpDecision.description}}</p>
    <div *ngIf="!!latestConsultationNote && !!latestConsultationNote.q_12?.choices && !!latestConsultationNote.q_12?.choices?.length">
        <b>Patient's formulation preference(s): </b> 
        <ng-container *ngFor="let item of latestConsultationNote.q_12.choices">
            <span *ngIf="item.checked" style="margin-right: 0.5rem;">{{item.label}}</span>
        </ng-container>
    </div>
</div>