import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, lastValueFrom } from 'rxjs';
import { GAD_FORM } from 'src/assets/patientQuestionnaires';
import { DataService } from '../data.service';
import { OrderNotesService } from '../order-notes.service';

@Component({
  selector: 'app-gad-form',
  templateUrl: './gad-form.component.html',
  styleUrls: ['./gad-form.component.scss']
})
export class GadFormComponent implements OnInit {
  @Input() order:any;
  @Output() hideModal = new EventEmitter();

  gadGroup: FormGroup;
  gadNotes: any;
  gadId: number;

  constructor(
    private dataService: DataService,
    private orderNotesService: OrderNotesService,
  ) { }

  private async initializeGadNotes(){
    const gadNote = this.orderNotesService.getGADNote(this.order.orderNotes);
    if (!!gadNote) {
      this.gadNotes = gadNote.note;
      this.gadId = gadNote.id;
    }
  }

  private createGadNotesForm() {
    const noteControls = {};
    const gadNotes = this.gadNotes;
    Object.keys(this.gadForm).forEach((key: string) => {
      noteControls[key] = new FormControl(gadNotes?.[key] || undefined, [
        Validators.required,
      ]);
    });

    this.gadGroup = new FormGroup(noteControls);
    this.onGadNotesChange();
  }

  private onGadNotesChange() {
    this.gadGroup.valueChanges.pipe(debounceTime(800)).subscribe(async (val) => {
      if (!this.gadId) {
        const gadData = this.getGadData(val);
        await lastValueFrom(this.dataService.createPatientNote(this.order.id, gadData));
        const gadNote = await lastValueFrom(this.dataService.getGadNote(this.order.id));
        this.gadId = gadNote[0].id;
      } else {
        const gadData = this.getGadData(val);
        gadData['id'] = this.gadId;
        await lastValueFrom(this.dataService.updatePatientNote(this.order.id, gadData));
      }
    });
  }

  ngOnInit(): void {
    this.initializeGadNotes();
    this.createGadNotesForm();
  }

  getGadData(gadValue: any) {
    let totalScore = 0;
    Object.keys(gadValue).forEach((key: string) => {
      if (!!gadValue[key]) {
        totalScore += parseInt(gadValue[key]);
      }
    });

    const formValues = {
      ...gadValue,
      score: totalScore,
    };

    return {
      type: 'GAD_FORM',
      questionAnswer: JSON.stringify(formValues),
    };
  }

  get gadForm() {
    return GAD_FORM;
  }

}
