import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrderNotesService {

  constructor() { }

  getGPConsultationNote(orderNotes: any[]){
    const note = orderNotes.filter(note => note.type === 'GP_CONSULTATION_NOTES');
    if (!!note.length) {
      return note
    }

    return undefined;
  }

  getPEGNote(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "PEG_FORM");
    if (note) {
      return {
        id: note.id,
        note: JSON.parse(note.questionAnswer)
      }
    }

    return undefined;
  }

  // Getting the side effect form answers
  public getSideEffectNote(orderNotes: any[]): any {
    // tslint:disable-next-line:no-shadowed-variable
    const note = orderNotes.find(note => note.type === 'SIDE_EFFECT_FORM');
    if (note) {
      return {
        id: note.id,
        note: JSON.parse(note.questionAnswer)
      };
    }

    return undefined;
  }

  getGADNote(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "GAD_FORM");
    if (note) {
      return {
        id: note.id,
        note: JSON.parse(note.questionAnswer)
      }
    }

    return undefined;
  }

  getConsentNote(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "PATIENT_DETAIL_CONFIRMATION");
    if (note) {
      return {
        id: note.id,
        note: JSON.parse(note.questionAnswer)
      }
    }

    return undefined;
  }

  getAUDITNote(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "AUDIT_FORM");
    if (note) {
      return {
        id: note.id,
        note: JSON.parse(note.questionAnswer)
      }
    }

    return undefined;
  }

  getGPRecommendation(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "GP_RECOMMENDATION");
    if (note) {
      return JSON.parse(note.formulary)
    }
    return undefined;
  }

  getGPDecision(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "GP_APPROVAL_DECISION");
    if (note) {
      return {
        approved: note.approved,
        description: note.description
      }
    }
    return undefined;
  }

  getPrescribingPharmacyDecision(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "PRESCRIBING_PHARMACIST_APPROVAL_DECISION");
    if (note) {
      return {
        approved: note.approved,
        description: note.description
      }
    }
    return undefined;
  }

  getMDTRecommendation(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "MDT_RECOMMENDATION");
    if (note) {
      return JSON.parse(note.formulary)
    }
    return undefined;
  }

  getMDTProforma(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "MDT_PROFORMA");
    if (note) {
      return JSON.parse(note.questionAnswer)
    }
    return undefined;
  }

  getMDTDecision(orderNotes: any[]){
    return orderNotes.find(note => note.type === "MDT_DECISION");
  }

  getSpecialistMessages(orderNotes: any[]){
    const note = orderNotes.filter((note) => note.type === 'SPECIALIST_PATIENT_MESSAGE');
    if (note.length === 0) {
      return []
    }
    return note.sort((message1, message2) =>
      new Date(message1.creationDate) > new Date(message2.creationDate)
        ? 1
        : new Date(message1.creationDate) < new Date(message2.creationDate)
        ? -1
        : 0
    );
  }

  getSpecialistDecision(orderNotes: any[]){
    return orderNotes.find(note => note.type === "SPECIALIST_DECISION");
  }

  getSpecialistCallPatient(orderNotes: any[]){
    return orderNotes.find(note => note.type === "SPECIALIST_CALL_PATIENT");
  }

  getPinkPrescription(files: any[]){
    const pinkPrescriptions = files.filter((file) => file.action === 'PINK_PRESCRIPTION_UPLOAD');
    if (pinkPrescriptions.length === 0) {
      return undefined;
    }
    const images = [];
    const documents = [];

    pinkPrescriptions.forEach(item => {
      if (item.contentType === 'application/pdf') {
        documents.push(item)
      } else {
        images.push(item)
      }
    })
    return {
      images,
      documents
    };
  }

  //return only last rejected prescription
  getPharmacyRejectReason(orderNotes: any[]){
    // const note = orderNotes.find((note) => note.type === 'PHARMACY_ORDER_REJECTION_NOTE');
    const notes = orderNotes.filter(note => note.type === 'PHARMACY_ORDER_REJECTION_NOTE');
    // @ts-ignore
    const sortedNotes = notes.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    const note = sortedNotes[0];

    // const trackingNote = orderNotes.find((note) => note.type === 'REJECTED_TRACKING_CODE');
    // tslint:disable-next-line:no-shadowed-variable
    const trackingNotes = orderNotes.filter((note: any): boolean => note.type === 'REJECTED_TRACKING_CODE');
    // @ts-ignore
    const sortedTrackingNotes = trackingNotes.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    const trackingNote = sortedTrackingNotes[0];

    let trackingCode = '';

    if (!!trackingNote) {
      trackingCode = trackingNote.description
    }

    if (!!note) {
      return {
        description: `${note.descriptionKey} ${!!note.description ? `- ${note.description}` : ''}`,
        medication: JSON.parse(note.formulary).medication,
        pharmacy: note.pharmacy,
        creationDate: note.creationDate,
        trackingCode
      };
    }
    return undefined;
  }

  // return all rejected prescription
  getPharmacyRejectedPrescriptions(orderNotes: any[]){
    let tempArr = [];
    const notes = orderNotes.filter(note => note.type === 'PHARMACY_ORDER_REJECTION_NOTE');
    // @ts-ignore
    const sortedNotes = notes.sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate));

    const trackingNotes = orderNotes.filter((note) => note.type === 'REJECTED_TRACKING_CODE');

    if (sortedNotes.length > 0) {
      for (let i = 0; i < notes.length; i++) {
        tempArr.push({
          description: `${notes[i].descriptionKey} ${!!notes[i].description ? `- ${notes[i].description}` : ''}`,
          medication: JSON.parse(notes[i].formulary).medication,
          pharmacy: notes[i].pharmacy,
          creationDate: notes[i].creationDate,
          trackingCode: trackingNotes[i].description
        });
      }
      if (tempArr.length > 0){
        return tempArr;
      }
    }
    return undefined;
  }

  getPharmacyDecision(orderNotes: any[]){
    return orderNotes.find((note) => note.type === 'PHARMACY_DECISION');
  }

  getPatientPharmacyDecision(orderNotes: any[]){
    return orderNotes.find((note) => note.type === 'PATIENT_PHARMACY_DECISION');
  }

  // Repeat order ---------------------------------------------------------------
  getRepeatOrderQuestionnaire(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "REPEAT_ORDER_QUESTIONNAIRE");
    if (note) {
      const questionnaire = JSON.parse(note.questionAnswer);

        if (typeof questionnaire.q_1 === 'boolean') {
          return {
            version: 'old',
            formValues: questionnaire,
          };
        } else {
          return {
            version: 'new',
            formValues: questionnaire,
          };
        }

    }

    return undefined;
  }

  getRepeatOrderChangeReason(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "REPEAT_ORDER_PATIENT_PRESCRIPTION_CHANGE_REASON");
    if (note) {
      return {
        description: note.description,
        descriptionKey: note.descriptionKey,
      }
    }
    return undefined;
  }

  getRepeatOrderRecommendation(orderNotes: any[]){
    const note = orderNotes.find(note => note.type === "REPEAT_ORDER_RECOMMENDATION");
    if (note) {
      return JSON.parse(note.formulary)
    }
    return undefined;
  }
}
