import { Component, Input, OnInit } from '@angular/core';
import { repeatQuestionnaire } from 'src/assets/repeatOrderQuestionnaire';

@Component({
  selector: 'app-repeat-order-note',
  templateUrl: './repeat-order-note.component.html',
})
export class RepeatOrderNoteComponent implements OnInit {
  @Input() note: any;
  
  constructor() { }

  ngOnInit(): void {
  }

  getRepeatQuestionLabel(id: string) {
    return repeatQuestionnaire.find((item) => item.name === id).text;
  }

}
