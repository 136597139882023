import {Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { ModalService } from '../modal.service';

// @ts-ignore
@Component({
  selector: 'app-admin-key-alert-modal',
  templateUrl: './admin-key-alert-modal.component.html',
  styleUrls: ['./admin-key-alert-modal.component.scss']
})
export class AdminKeyAlertModalComponent implements OnInit, OnChanges, OnDestroy, DoCheck  {


  constructor(private modalService: ModalService, private dataService: DataService) {}
  @Input() patient: any;
  @ViewChild('keyAlertModal') modal: ModalDirective;

  private modalEventSubscription: Subscription;

  protected editNote = false;
  protected doctorAlertText = '';
  protected adminAlertText: string = '';
  protected isLoading = false;
  public maxWordCount: number = 300;
  public currentWordCount: number = 0;

  ngOnInit(): void {
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((modalName) => {
      if (modalName === 'keyAlertModal') {
        this.modal.show();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patient) {
      if (!!this.patient.settings?.ADMIN_ALERTS) {
        this.adminAlertText = this.patient.settings.ADMIN_ALERTS;
      }
      if (!!this.patient.settings?.DOCTOR_ALERT) {
        this.doctorAlertText = this.patient.settings.DOCTOR_ALERT;
      }
    }
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }

  // tslint:disable-next-line:typedef
  private convertKeysToStrings(obj) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[String(`${key}`)] = String(value);
    }
    return newObj;
  }

  async handlePatientAlerts(state: boolean): Promise<void> {
    if (!state) {
      if (!!this.doctorAlertText && this.currentWordCount <= this.maxWordCount) {
        this.isLoading = true;
        const patientPayload = {...this.patient};
        const preferencesPayload = this.convertKeysToStrings(this.patient.preferences);
        const settingsPayload = this.convertKeysToStrings(this.patient.settings);
        patientPayload.preferences = preferencesPayload;
        patientPayload.settings = settingsPayload;
        patientPayload.settings.DOCTOR_ALERT = this.doctorAlertText;
        patientPayload.settings.ADMIN_ALERT = this.adminAlertText;
        const result = await lastValueFrom(this.dataService.updatePatientSettings(patientPayload));
        this.isLoading = false;
        this.editNote = state;
      }
    } else {
      this.editNote = state;
    }
  }

  public ngDoCheck(): void {
    this.problemsWordsCount();
  }
  // Display words count under symptoms/problems section on booking page
  private problemsWordsCount(): void {
    this.currentWordCount = this.doctorAlertText.length;
  }
}
