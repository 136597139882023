
export enum DocumentAction {
    Add,
    Update,
    Cancel
}

export enum Document {
    Prescription,
    FitNote,
    ReferralLetter,
    AppointmentExtension
}

export enum DocumentState {
    None,
    Proposed,
    Accepted,
    Updated,
    Completed
}