import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderState'
})

export class OrderStatePipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'WAITING_FOR_PHARMACY_APPROVAL':
                return 'info';
            case 'PAYMENT_FAILED':
                return 'warning';
            case 'REJECTED_BY_PHARMACY':
                return 'danger';
            case 'CANCELED_BY_PHARMACY':
                return 'danger';
            case 'APPROVED_BY_PHARMACY':
                return 'warning';
            case 'PROPOSED_BY_DOCTOR':
                return 'warning';
            case 'DELIVERED':
                return 'success';
            case 'WAITING_FOR_DOCTOR_APPROVAL':
                return 'info';
            case 'CANCELED_BY_PATIENT':
                return 'danger';
            case 'RESERVED_BY_DOCTOR':
                return 'info';
            case 'REJECTED_BY_DOCTOR':
                return 'danger';
            case 'REJECTED_BY_PATIENT':
                return 'danger';
            case 'BOOKED':
                return 'info';
            case 'ONGOING':
                return 'info';
            case 'APPOINTMENT_COMPLETED':
                return 'info';
            case 'CANCELED_BY_DOCTOR':
                return 'danger';
            case 'WAITING_FOR_PATIENT_DELIVERY_DETAILS':
                return 'warning';
            case 'WAITING_FOR_DOCTOR_PRESCRIPTION_COMPLETION':
                return 'info';
            case 'WAITING_FOR_ADMIN_DELIVERY_DETAILS':
                return 'warning';
            case 'DELIVERED':
                return 'success';
            case 'AWAITING_PATIENT_RESPONSE':
                return 'warning';
            default:
                return 'info';
        }
    }
}
