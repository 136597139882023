import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { AccountService } from './account.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private accountService: AccountService, private userService: UserService, private router: Router) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.loggedIn()) {
            const user = await this.userService.user$.pipe(filter(u => u != null), take(1)).toPromise();
            // Ensure doctor has confirmed his/her identity before they start using the system
            return (user.settings?.CONFIRMED_IDENTITY === "true" || false) ? true : this.router.navigate(["consent"]);
        } else {
            return this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        }
    }
}
