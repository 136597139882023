import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appointmentStatus'
})

export class AppointmentStatusPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case "BOOKED":
                return "Booked";
            case "SCHEDULED":
                return "Available";
            case "APPOINTMENT_COMPLETED":
                return "Complete";
            case "CANCELED_BY_DOCTOR":
                return "Cancelled"
        }
    }
}
