import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-mdt-dashboard',
  templateUrl: './mdt-dashboard.component.html',
  styleUrls: ['./mdt-dashboard.component.scss'],
})
export class MdtDashboardComponent implements OnInit {
  // Variables to grab fetch results
  allMDTs: any[];
  allPendingOrders: any[];
  sessionStat: any;
  lastSession: any
  initLoading: boolean;

  constructor(private mdtService: MdtService, private router: Router) {}

  ngOnInit(): void {
    this.initLoading = true;
    forkJoin([
      this.mdtService.getAllMDTs(),
      this.mdtService.getOrdersForReview(null, null, 0),
      this.mdtService.getActiveSession(),
      this.mdtService.getAllSessions()
    ]).subscribe({
      next: (result) => {
        this.allMDTs = result[0];
        this.allPendingOrders = result[1];
        if ('active' in result[2]) {
          this.sessionStat = result[2].active;
        } else {
          this.sessionStat = result[2];
        }
        if (result[3].content.length < 1) {
          this.initLoading = false
          return
        }
        const lastCompletedSession = result[3].content.find(order => order.status === "COMPLETED")
        if (lastCompletedSession === undefined) {
          this.initLoading = false
          return
        }
        this.mdtService.getCompletedSession(lastCompletedSession.id).subscribe({
          next: (value) => {
            this.lastSession = value
            this.initLoading = false;
          }
        })
      },
    });
  }

  handleSessionRouting() {
    if (this.sessionStat === false) {
      this.mdtService.createSession().subscribe({
        next: (value) => {
          this.router.navigate(['mdt-orders']);
        },
      });
    } else {
      this.router.navigate(['mdt-orders']);
    }
  }

  getReviewedOrderCount(){
    return this.sessionStat.orders.filter(order => order.status === "AWAITING_MDT_SESSION_COMPLETION").length
  }

  getInProgressOrderCount(){
    return this.sessionStat.orders.filter(order => order.status === "IN_MDT_SESSION").length
  }
}
