<div class="uploader-container">
    <h5 class="title">{{prescriptionPlaceholder}}</h5>
    <img *ngIf="pinkPrescription === undefined" class="placeholder-img" src="/assets/vectors/image-outline.svg" alt="">
    <div *ngIf="thumbnail !== undefined">
        <img class="prescription-img" [src]="thumbnail" alt="">
        <input type="text" placeholder="Reference No." name="reference" id="reference" class="form-control">
    </div>
    <div class="actions-container">
        <input #fileInput type="file" accept="image/png, image/jpeg" name="pinkPrescriptions" id="pinkPrescriptions"
            (change)="updatePinkPrescriptions($event)" required>

        <button class="btn btn-info" (click)="openFileUploader()" [disabled]="btnDisabled">Select image</button>
        <button *ngIf="pinkPrescription !== undefined" class="btn btn-primary" (click)="uploadImages()"
            [disabled]="btnDisabled">Upload image</button>
    </div>
</div>