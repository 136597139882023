<div class="alert" [ngClass]="{ 'alert-success': patientGaveConsentForSCR, 'alert-danger': !patientGaveConsentForSCR }">
  <span *ngIf="patientGaveConsentForSCR">The patient has consented for their SCR to be accessed.</span>
  <span *ngIf="!patientGaveConsentForSCR">The patient did not consent for you to view their SCR.</span>
</div>
<table class="table table-striped table--history">
  <thead>
    <tr>
      <th>Question</th>
      <th>Answer</th>
    </tr>
  </thead>
  <tbody *ngIf="!orderItem.formType">
      <tr *ngFor="let answer of form; let i = index" [ngClass]="{ 'original-answer': originalAnswer(answer), 'blocker-answer': blockerAnswer(answer)}">
        <!-- <td>{{ blockerQuestion(answer) }}</td> -->
        <td>{{ answer[0] }}</td>
        <td>
          <strong>{{ answer[1] | booleanAnswer }}</strong>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="orderItem.formType" >
      <tr *ngFor="let answer of formAnswers" [ngStyle]="{'color': answer.alteredAfterTermination ? 'red':'' }">
        <td>
          <span>{{answer.formQuestion.title}}</span>
          <div *ngIf="answer.formQuestion.optionType=='MULTIPLE_SELECT'">
              <div *ngFor="let options of answer.formQuestion.questionOptions">
                <i>{{options.description}}</i>
              </div>
          </div>
        </td>
        <td>
          <ng-container [ngSwitch]="answer.formQuestion.optionType">
            <ng-container *ngSwitchCase="'CALCULATION'">
              <div style="margin-bottom: 0.5rem;">
                <strong *ngIf="answer.answer!='NONE'">{{answer.formQuestion.additionalDetails.label}} - {{answer.answer}}</strong>
              </div>
              <p *ngFor="let option of answer.formAnswersOptions" style="margin: 0;">
                {{option.formQuestionOption.description.label}} {{option.formQuestionOption.description.description}} - <i>{{option.value}}</i>
              </p>
            </ng-container>

            <ng-container *ngSwitchCase="'DATE_TIME'">
              <div style="margin-bottom: 1rem;" *ngFor="let option of answer.formAnswersOptions">
                <strong>{{option.formQuestionOption.description.label}}</strong>
                <br>
                <i>{{option.value}}</i>
              </div>
            </ng-container>
            
            <ng-container *ngSwitchDefault>
              <strong *ngIf="answer.answer!='NONE'">{{answer.answer}}</strong>
              <span *ngIf="answer.additionalDetails">
              - {{answer.additionalDetails}}
              </span>
              <p *ngFor="let option of answer.formAnswersOptions">
                {{option.formQuestionOption.description.label ||option.formQuestionOption.description}}
                <br>
                <i>{{option.additionalNotes}}</i>
                <i>{{option.value}}</i>
              </p>
              <strong *ngIf="answer.formAnswersOptions.length==0 && !answer.additionalDetails && answer.answer=='NONE'">NO</strong>
            </ng-container>
          </ng-container>
        </td>
      </tr>
  </tbody>

</table>
