export enum questionStructure {
  text,
  choice,
  choiceAndTextOnCondition,
  choiceAndText,
  checkboxAndText,
  checkbox,
  signal
}

export const signalFormTypes = {
  PATIENT_DETAIL_CONFIRMATION: 'PATIENT_DETAIL_CONFIRMATION',
  PEG_FORM: 'PEG_FORM',
  AUDIT_FORM: 'AUDIT_FORM',
  GAD_FORM: 'GAD_FORM',
  SIDE_EFFECT_FORM: 'SIDE_EFFECT_FORM'
};

type hideCondition = "FEMALE" | "MALE"

export type checkboxStructure = {
  label: string;
  id: string;
};

interface IPlaceholder {
  [key: string]: string
  boolTrue?: string
  boolFalse?: string
  default?: string
}

interface IRenderCondition {
  parentId: string
  selectionKey: string
  decisionValue: boolean | string | number
}

export type questionType = {
  question: string;
  questionId: string;
  type: questionStructure;
  active: boolean;
  rows?: number;
  condition?: boolean | object;
  choices?: checkboxStructure[];
  signalInstructions?: object;
  hideInstructions?: hideCondition[];
  placeholder?: IPlaceholder;
  tbTitle?: string
  renderCondition ?: IRenderCondition
};

type questionnaireStructure = {
  title: string;
  step: number;
  questions: questionType[];
};
export const questionnaire: questionnaireStructure[] = [
  {
    title: 'Question set 1',
    step: 1,
    questions: [
      {
        active: true,
        question: 'SCR accessed and reviewed?',
        questionId: 'q_28',
        type: questionStructure.choiceAndText,
        rows: 2,
        placeholder: {
          boolTrue: 'Please paste relevant information',
          boolFalse: 'Please provide further information',
          default: '',
        }
      },
      {
        active: true,
        question: 'What is your occupation/Does this involve the performance of skilled tasks, manual or cognitive?',
        questionId: 'q_29',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: true,
        question: 'For what condition(s) and resultant symptoms are you hoping to be treated for?',
        questionId: 'q_1',
        type: questionStructure.text,
        rows: 2,
      },
      {
        active: true,
        question: 'Are your symptoms stable?',
        questionId: 'q_2',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: true,
        question: 'What treatments have you tried to date?',
        questionId: 'q_30',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: true,
        question: 'Investigations diagnostics',
        questionId: 'q_31',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: true,
        question: 'PEG questionnaire',
        questionId: '',
        type: questionStructure.signal,
        signalInstructions: {
          type: "default",
          signalType: signalFormTypes.PEG_FORM,
          doctorFills: false
        }
      },
      {
        active: true,
        question: 'Past medical history',
        questionId: 'q_32',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: false,
        question: 'What is your occupation?',
        questionId: 'q_3',
        type: questionStructure.text,
        rows: 1,
      },
      {
        active: false,
        question:
          'Does your job involved skilled tasks or the operation of machinery?',
        questionId: 'q_4',
        type: questionStructure.choiceAndTextOnCondition,
        rows: 3,
        condition: true,
      },
      {
        active: true,
        question:
          'Have you ever had any medical problems affecting the following?',
        questionId: 'q_5',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'Heart', id: 'heart' },
          { label: 'Lungs', id: 'lungs' },
          { label: 'GIT', id: 'git' },
          { label: 'Eyes', id: 'eyes' },
          { label: 'Skin', id: 'skin' },
          { label: 'Brain', id: 'brain' },
          { label: 'Liver', id: 'liver' },
          { label: 'Kidney', id: 'kidney' },
          { label: 'Anything else', id: 'others' },
          { label: 'None of the above', id: 'none' },
        ],
      },
    ],
  },
  {
    title: 'Question set 2',
    step: 2,
    questions: [
      {
        active: true,
        question:
          'Are you currently under the care of a specialist for the condition you are requesting Medical Cannabis for?',
        questionId: 'q_11',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true
      },
      {
        active: true,
        question: 'Have you previously or are you currently prescribed Medical Cannabis?',
        questionId: 'q_7',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
        tbTitle: 'Formulation Dose Frequency'
      },
      //This is troublesome
      {
        active: false,
        question:
          'Have you used Cannabis within the last year via any of the following methods?',
        questionId: 'q_8',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'Oil', id: 'oil' },
          { label: 'Flower', id: 'flower' },
          { label: 'Smoke', id: 'smoke' },
          { label: 'Oral', id: 'oral' },
          { label: 'Other', id: 'other' },
          // { label: 'Have not used cannabis in the last year', id: 'none' },
        ],
        tbTitle: 'Formulation Dose Frequency'
      },
      {
        active: true,
        question:
          'Have you used Cannabis within the last year?',
        questionId: 'q_37',
        type: questionStructure.choice,
      },
      {
        active: true,
        question:
          'Via what method did you take cannabis?',
        questionId: 'q_38',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'Oil', id: 'oil' },
          { label: 'Flower', id: 'flower' },
          { label: 'Smoke', id: 'smoke' },
          { label: 'Vape', id: 'vape' },
          { label: 'Oral', id: 'oral' },
          { label: 'Other', id: 'other' },
        ],
        tbTitle: 'Formulation Dose Frequency',
        renderCondition: {
          parentId: 'q_37',
          selectionKey: 'choice',
          decisionValue: true
        }
      },
      {
        active: true,
        question:
          'I understand that should I be prescribed medical cannabis I require an effective means of contraception?',
        questionId: 'q_14',
        type: questionStructure.choice,
        hideInstructions: ["FEMALE"]
      },
      {
        active: true,
        question:
          'Has a healthcare professional ever advised that you should not take Cannabis?',
        questionId: 'q_6',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question:
          'Have you ever had an allergic or hypersensitivity reaction to Cannabis or Cannabis related products?',
        questionId: 'q_13',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question:
          'Are you allergic to any other medication or anything else including nuts seeds and oils?',
        questionId: 'q_33',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question: 'Current medication',
        questionId: 'q_34',
        type: questionStructure.text,
      },
      {
        active: true,
        question: 'Have you ever been prescribed the following?',
        questionId: 'q_9',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'PreGabalin', id: 'preGabalin' },
          { label: 'Codeine', id: 'codeine' },
          { label: 'Morphine', id: 'morphine' },
          { label: 'Oxycodone', id: 'oxycodone' },
          { label: 'Oxycontin', id: 'oxycontin' },
          { label: 'Tapentadol', id: 'tepantadol' },
          { label: 'Fentanyl or Buprenoprphine Patch', id: 'fentanyl' },
          { label: 'Tramadol', id: 'tramadol' },
          { label: 'Co-Codamol', id: 'codamol' },
          { label: 'Gabapentin', id: 'gabapentin' },
          { label: 'Amitriptyline', id: 'amitriptyline' },
          { label: 'Duloxetine', id: 'duloxetine' },
          // {
          //   label: 'Benzodiazepine such as Diazepam, Temazepam, Clobazam',
          //   id: 'benzodiazepine',
          // },
          // {
          //   label:
          //     'ADHD Medication such as Methylphenidate, Ritalin, Amphetamines, Aderrall',
          //   id: 'adhd',
          // },
          { label: 'Other', id: 'other' },
          { label: 'None of the above', id: 'none' },
        ],
      },
      {
        active: false,
        question: 'Are you taking any regular medication?',
        questionId: 'q_10',
        type: questionStructure.text,
      },
    ],
  },
  {
    title: 'Question set 3',
    step: 3,
    questions: [
      {
        active: true,
        question: 'Have you ever been diagnosed with any of the following?',
        questionId: 'q_15',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'Personality disorder', id: 'personality' },
          { label: 'Schizophrenia or Psychosis', id: 'schizophrenia' },
          { label: 'Depression', id: 'depression' },
          { label: 'PTSD', id: 'ptsd' },
          { label: 'Bipolar disorder', id: 'bipolar' },
          { label: 'History of Suicide attempts or Self harm', id: 'selfharm' },
          { label: 'History of addiction', id: 'addiction' },
          {
            label:
              'A blood relative with a history of Schizophrenia or Psychosis',
            id: 'bloodRelative',
          },
          { label: 'Anything else', id: 'any' },
          { label: 'None of the above', id: 'none' },
        ],
      },
      {
        active: true,
        question: 'Have you been diagnosed with any other mental health disorder?',
        questionId: 'q_35',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question:
          'During the last month, have you often been bothered by feeling down, depressed or hopeless?',
        questionId: 'q_22',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question:
          'During the last month, have you often been bothered by having little interest or pleasure in doing things?',
        questionId: 'q_23',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question: 'GAD-2 questionnaire',
        questionId: '',
        type: questionStructure.signal,
        signalInstructions: {
          type: "default",
          signalType: signalFormTypes.GAD_FORM,
          doctorFills: true
        }
      },
      {
        active: true,
        question: 'MSE - General comments?',
        questionId: 'q_24',
        type: questionStructure.text,
      },
      {
        active: true,
        question: 'Do you drink alcohol?',
        questionId: 'q_17',
        type: questionStructure.choice,
      },
      {
        active: true,
        question: 'AUDIT form',
        questionId: '',
        type: questionStructure.signal,
        signalInstructions: {
          type: "condition",
          id: "q_17",
          value: true,
          signalType: signalFormTypes.AUDIT_FORM,
          doctorFills: true
        }
      },
      {
        active: true,
        question: 'Do you use tobacco?',
        questionId: 'q_16',
        type: questionStructure.choiceAndTextOnCondition,
        choices: [
          { label: 'Non-smoker', id: 'none' },
          { label: 'Ex-smoker', id: 'ex' },
          { label: 'Current-smoker', id: 'smoker' },
        ],
        condition: {
          'Ex-smoker': 'smoker',
          'Current-smoker': 'none',
        }
      },
      {
        active: true,
        question: 'Do you currently use any recreational drugs?',
        questionId: 'q_18',
        type: questionStructure.checkbox,
        choices: [
          { label: 'Cocaine', id: 'cocaine' },
          { label: 'Ecstasy', id: 'ecstasy' },
          { label: 'Heroin', id: 'heroin' },
          { label: 'Anything else', id: 'any' },
          { label: 'None of the above', id: 'none' },
        ],
        condition: {
          'Anything else': 'any',
        }
      },
      {
        active: false,
        question: 'Do you have children at home with you?',
        questionId: 'q_20',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
    ],
  },
  {
    title: "Question set 4",
    step: 4,
    questions: [
      {
        active: true,
        question:
          'Do you have any mobility issues/Have you been advised you are at risk of falls?',
        questionId: 'q_19',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question: 'Are you the main carer for anyone you live with?',
        questionId: 'q_21',
        type: questionStructure.choiceAndTextOnCondition,
        condition: true,
      },
      {
        active: true,
        question: '',
        questionId: 'q_25',
        type: questionStructure.checkboxAndText,
        choices: [
          {
            label: 'Advice regarding Driving/ operating skilled machinery',
            id: 'operatingAdvice',
          },
        ],
      },
      {
        active: true,
        question: '',
        questionId: 'q_26',
        type: questionStructure.checkboxAndText,
        choices: [
          { label: 'Advice regarding Cannabis and travel', id: 'travelAdvice' },
        ],
      },
      {
        active: true,
        question: '',
        questionId: 'q_27',
        type: questionStructure.checkboxAndText,
        choices: [
          {
            label: 'Advice regarding Cannabis and side effects',
            id: 'sideEffectAdvice',
          },
        ],
      },
      {
        active: true,
        question: 'Consent',
        questionId: '',
        type: questionStructure.signal,
        signalInstructions: {
          type: "default",
          signalType: signalFormTypes.PATIENT_DETAIL_CONFIRMATION,
          doctorFills: false
        }
      },
      {
        active: true,
        question: 'Do you believe this patient meets the criteria to be prescribed and would benefit from being prescribed medical cannabis?',
        questionId: 'q_36',
        type: questionStructure.choiceAndText,
      },
      {
        active: true,
        question: 'Any preference for formulation',
        questionId: 'q_12',
        type: questionStructure.checkbox,
        choices: [
          { label: 'Vape', id: 'vape' },
          { label: 'Oil', id: 'oil' },
          { label: 'Flower', id: 'flower' },
          { label: 'Oral', id: 'oral' },
          { label: 'Other', id: 'other' },
          { label: 'No preference', id: 'none' },
        ],
      },
    ]
  }
];
