import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { proformaQuestions } from 'src/assets/proforma';

@Component({
  selector: 'app-mdt-proforma-modal',
  templateUrl: './mdt-proforma-modal.component.html',
  styleUrls: ['./mdt-proforma-modal.component.scss'],
})
export class MdtProformaModalComponent implements OnInit {
  @Input() proformaNotes: any;
  @Output() hideModal = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    // console.log(this.orderNotes);
    // const mdtProfoma = this.orderNotes.find(
    //   (note) => note.type === 'MDT_PROFORMA'
    // );
    // this.proforma = JSON.parse(mdtProfoma.questionAnswer);
  }

  isKeyExist(key: string) {
    return key in this.proformaNotes;
  }

  isAnswerBoolean(key: string) {
    return typeof this.proformaNotes[key].answer === 'boolean';
  }

  returnZero() {
    return 0;
  }

  protected get questions() {
    return proformaQuestions;
  }
}
