import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ConditionsService } from './conditions.service';
import { DataService } from '../data.service';
import { DocumentState } from './document';
import * as moment from 'moment';

@Component({
    selector: 'app-add-fit-note',
    templateUrl: './add-fit-note.component.html',
    styleUrls: ['./add-fit-note.component.scss'],
})
export class AddFitNoteComponent implements OnInit {

    @Input() doctorNote: any;
    @Input() fitNoteId: number;
    @Output() documentStateChanged = new EventEmitter();
    @Output() submit = new EventEmitter<any>();
    @Output() delete = new EventEmitter();
    fitNoteState = 'initial';
    model: any = {
        phasedReturn: false,
        alteredHours: false,
        amendedDuties: false,
        workplaceAdapt: false
    };
    conditions: string[];
    public showSummary: boolean = false;
    public today = moment();
    public minFromDate: string;
    public maxFromDate: string;
    public wrongFromDate: boolean = false;
    public wrongExpirationDate: boolean = false;

    constructor(private conditionsService: ConditionsService,
                private dataService: DataService) {
    }

    ngOnInit() {
        this.conditionsService.getConditions().subscribe(conditions => {
            this.conditions = conditions;
            this.model.reassessmentNeeded = false;
            this.model.differentialDiagnosis = this.doctorNote.details.differentialDiagnosis;
            this.model.differentialDiagnosisNotes = this.doctorNote.details.differentialDiagnosisNotes;
        });
        if (this.doctorNote.sicknoteId) {
            this.dataService.getFitNote(this.doctorNote.appointment.id).subscribe(fitNote => {
                this.model = fitNote;
            })
        }

        this.maxFromDate = this.today.format('YYYY-MM-DD');
        this.minFromDate = moment(this.today).subtract(14, 'days').format('YYYY-MM-DD');
    }

    onSubmit() {
        delete this.model.id;
        this.submit.emit(this.model);
        if (!this.model.fit) {
            this.model.phasedReturn = false;
            this.model.alteredHours = false;
            this.model.amendedDuties = false;
            this.model.workplaceAdapt = false;
        }
        this.model.pin = '';
        this.fitNoteState = 'initial';
        this.documentStateChanged.emit(DocumentState.Completed);
    }

    onCreate(){
        this.fitNoteState = 'proposed';
    }

    onDelete() {
        this.delete.emit(this.model);
        this.fitNoteState = 'initial';
    }

    resetFitNote() {
        this.model = {
            reassessmentNeeded : false,
            differentialDiagnosis : this.doctorNote.details.differentialDiagnosis,
            differentialDiagnosisNotes : this.doctorNote.details.differentialDiagnosisNotes
        }
    }

    moveToCanceledState () {
        this.dataService.getFitNote(this.doctorNote.appointment.id).subscribe(
            fitNote => {
            this.fitNoteId = fitNote.id;
        },
        error => {
            delete this.fitNoteId;
        });
        this.fitNoteState = 'canceled';
    }

    onFitNoteDateChange(event, dateType) {
      if (dateType === 'FROM') {
        this.wrongFromDate = false;
        if (!!this.model.expirationDate) {
          // expiration date is set, is this after that?
          this.wrongFromDate = moment(event.target.value, 'YYYY-MM-DD').isAfter(moment(this.model.expirationDate, 'YYYY-MM-DD'));
        }
      } else if (dateType === 'TO') {
        this.wrongExpirationDate = false;
        if (!!this.model.fromDate) {
          // is this before from date?
          this.wrongExpirationDate = moment(event.target.value, 'YYYY-MM-DD').isBefore(moment(this.model.fromDate, 'YYYY-MM-DD'));
        }
      }
    }

    public isValid() {
        this.dataService.getFitNote(this.doctorNote.appointment.id).subscribe(fitNote => {
            let serverFitNote = JSON.parse(JSON.stringify(fitNote));

            let date = new Date(this.model.expirationDate);
            let formattedDate = date.getFullYear() + '-' + ("0"+(date.getMonth()+1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);

            if (this.model.fit != serverFitNote.fit
              || formattedDate != serverFitNote.expirationDate
              || this.model.phasedReturn != serverFitNote.phasedReturn
              || this.model.alteredHours != serverFitNote.alteredHours
              || this.model.amendedDuties != serverFitNote.amendedDuties
              || this.model.workplaceAdapt != serverFitNote.workplaceAdapt
              || ((this.model.conditionNotes && serverFitNote.conditionNotes && this.model.conditionNotes != serverFitNote.conditionNotes) || this.model.conditionNotes != serverFitNote.conditionNotes)
              || this.model.differentialDiagnosis != serverFitNote.differentialDiagnosis
              || this.model.differentialDiagnosisNotes != serverFitNote.differentialDiagnosisNotes) {
                this.documentStateChanged.emit(DocumentState.Updated);
            } else {
                this.documentStateChanged.emit(DocumentState.Completed);
            }
        });
    }
}
