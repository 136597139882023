import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdtService } from './mdt.service';

@Component({
  selector: 'app-past-sessions',
  templateUrl: './past-sessions.component.html',
  styleUrls: ['./past-sessions.component.scss'],
})
export class PastSessionsComponent implements OnInit {
  allSessions: any;
  totalPageCount: number;
  page: number;

  constructor(private mdtService: MdtService, private router: Router) {}

  private refresh() {
    this.mdtService.getAllSessions(this.page).subscribe({
      next: (value) => {
        this.allSessions = value.content;
        this.totalPageCount = value.totalPages;
      },
    });
  }

  handlePageCount(increase: boolean) {
    if (increase) {
      this.page += 1;
    } else {
      this.page -= 1;
    }

    this.refresh();
  }

  handleSessionRoute(sessionId: number | string) {
    this.router.navigate([`past-session/${sessionId}`]);
  }

  ngOnInit(): void {
    this.page = 0;
    this.refresh();
  }
}
