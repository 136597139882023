import { Component, Input, OnInit, Output, EventEmitter, } from '@angular/core';

@Component({
  selector: 'app-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
})
export class TabNavComponent implements OnInit {
  @Input() activeTab: any;
  @Input() tabs: object;
  @Input() small: boolean = false;
  @Output() changeTab = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  setTab(tabType: string) {
    this.changeTab.emit(tabType);
  }
}
