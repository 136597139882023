import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  questionsLookup = {
    'important.understoodDoxycycline': 'Note: This service is not suitable for women who are pregnant, breastfeeding or planning to become pregnant. You are requesting DOXYCYCLINE. 3 out of 100 people who use doxycycline will find their skin becomes more sensitive to sunlight.  This increases the chances of sunburn. Be sure to take care in the sun by using a high factor sunscreen and/or covering up. Doxycycline is NOT suitable for women who are pregnant or breastfeeding, or children under the age of 12. Doxycycline could harm your baby. I am over 18. I am using this service of my own free will. Any treatment or advice obtained is solely for my own use. I understand the need to provide honest and accurate answers to the questions asked, for my own safety. I have read the above and understand it',
    'important.understoodLariam': 'Note: This service is not suitable for women who are pregnant, breastfeeding or planning to become pregnant. You are requesting LARIAM. Lariam has been known to cause serious mood changes in some people.  You should NOT order this medication if you have a history of depression, anxiety or other psychiatric condition.  It can cause seizures and is NOT to be taken by people who have epilepsy or history of seizures. This medicine is also NOT suitable for people with certain heart problems. Lariam can cause dizziness or disturbed balance so care must be taken while driving or operating machinery. Effects may persist up to several months after stopping treatment. We are unable to prescribe Lariam for women who are: Pregnant, Planning to become pregnant within the next 6 months, Breastfeeding, Because Lariam takes time to be cleared from the body, it could harm your baby if you become pregnant even after stopping treatment. I have read and understood this message. If I am unsure I will speak to a doctor or pharmacist for advice.',
    'important.understoodChloroquine': 'I am over 18. I am using this service of my own free will. Any treatment or advice obtained is solely for my own use. I understand the need to provide honest and accurate answers to the questions asked, for my own safety. We are unable to prescribe Chloroquine (Avloclor)/Proguanil & Chloroquine (Paludrine & Avloclor) for women who are: Pregnant, Planning to become pregnant within the next 6 months, Breastfeeding. ',
    'important.understoodMalarone': 'I am over 18. I am using this service of my own free will. Any treatment or advice obtained is solely for my own use. I understand the need to provide honest and accurate answers to the questions asked, for my own safety. We are unable to prescribe Malarone for women who are: Pregnant, Planning to become pregnant within the next 6 months, Breastfeeding.',
    'important.understoodVaccines': 'I am over 18. I am using this service of my own free will. Any treatment or advice obtained is solely for my own use. I understand the need to provide honest and accurate answers to the questions asked, for my own safety.',

    'agreement.confirmStatement0': 'I am over 18',
    'agreement.confirmStatement1': 'I am using this service of my own free will',
    'agreement.confirmStatement2': 'I confirm that any advice or treatment I receive is only for my personal use and not on behalf of any other person',
    'agreement.confirmStatement3': 'Please confirm that you have read and understand the statement(s) below:',
    'agreement.confirmStatement4': 'Please confirm that you have read and understand the statement(s) below:',
    'agreement.confirmStatement5': 'Please confirm that you have read and understand the statement(s) below:',
    'agreement.confirmStatement6': 'Please confirm that you have read and understand the statement(s) below:',
    'agreement.confirmStatement7': 'Please confirm that you have read and understand the statement(s) below:',
    'agreement.confirmStatement8': 'Please confirm that you have read and understand the statement(s) below:',

    'agreement.confirmStatement3ed': 'I confirm that I am under 65 years old and seeking treatment for ED because I have experienced a persistent problem getting or maintaining an erection, despite being sexually aroused.',
    'agreement.confirmStatement3pe': 'This form is designed for men aged between 18 and 64 who cannot control when they ejaculate and for whom this is causing distress and/or relationship difficulties',
    'agreement.confirmStatement3hl': 'This medicine can also reduce levels of ‘PSA’ in the blood, the marker that is used to monitor and sometimes to look for prostate cancer.  It is therefore important that your doctor knows you are taking this medicine. We can inform your doctor for you.',
    'agreement.confirmStatement4hl': 'Propecia (Finasteride) is secreted in the semen.  Wearing a condom is recommended if your sexual partner is pregnant or likely to become pregnant. Women of childbearing potential should avoid handling crushed or broken tablets',
    'agreement.confirmStatement4hlReplacement': 'Finasteride (Generic) is secreted in the semen. Wearing a condom is recommended if your sexual partner is pregnant or likely to become pregnant. This treatment is not suitable for women and women of childbearing potential should avoid/not handle crushed or broken tablets. The medication can also result in loss of sex drive and in 1.3% (just over 1 in a hundred men) effect erectile dysfunction',
    'agreement.confirmStatement5hl': 'I confirm that I understand that Finasteride may cause mood alterations and depression and, less frequently, suicidal thoughts have been reported. If I experience any of these symptoms, I will stop taking Finasteride and contact my doctor for further medical advice as soon as possible',
    'agreement.confirmStatement3trush': 'If you have had Thrush symptoms more than TWICE over the last SIX months, you must consult your regular GP. This may be due to an underlying cause. ',
    'agreement.confirmStatement3cystitis': 'Mild cystitis can clear up on its own without antibiotics. It is better for your overall health that you do not take antibiotics unless absolutely necessary. Many can cause side effects such as diarrhoea, thrush and nausea. Using them when not necessary, and not completing the course increases the chances that they won’t work the next time you need them. ',
    'agreement.confirmStatement4cystitis': 'Please note that the antibiotic nitrofurantoin is NOT suitable for people with kidneys that have reduced filtering ability.  You must NOT take this medicine if your estimated glomerular filtration rate is less than 60ml/minute as this would increase the chance of serious side effects such as liver, lung, nerve and kidney problems. The treatment is also less likely to work.',
    'agreement.confirmStatement5cystitis': 'We can only supply antibiotics for Cystitis in women only.',
    'agreement.confirmStatement3chlamydia': 'Before continuing with this assessment please ensure that you have been tested for and diagnosed as having Chlamydia. You can purchase Chlamydia from our website if you have not yet confirmed diagnosis. You should also inform sexual partners of your diagnosis so they can obtain appropriate treatment. <p><strong>Please note:</strong> Up to 50% of men and 75% of women with Chlamydia do not display any symptoms.',
    'agreement.confirmStatement3ai': 'This service is not intended to provide emergency or urgent treatment for breathing problems',
    'agreement.confirmStatement4ai': 'If you are having an asthma attack, are severely short of breath for any reason or experiencing chest pain please seek medical assistance.  Go to A&E or Dial 999 if it is an emergency.',
    'agreement.confirmStatement5ai': 'Good control means needing no more than 1 reliever every 6 months.  If you are using more than this you should review your symptoms with a doctor',
    'agreement.confirmStatement3flu': 'This medication should only be purchased for future use and if you are showing any signs of flu you should consult your GP.',
    'agreement.confirmStatement3jl': 'You should not take melatonin(Circadin) for more than five consecutive days.',
    'agreement.confirmStatement4jl': 'Melatonin is commonly used to treat jet lag, even though it was originally licensed as a treatment for insomnia. Using melatonin to manage jet lag is termed "off-label" use because although many people find it helpful, the effectiveness and possible side effects for this purpose have not been specifically studied by the manufacturer.',
    'agreement.confirmStatement3ha': 'This treatment does not replace acclimatization techniques',
    'agreement.confirmStatement4ha': 'Using acetazolomide to manage high altitude sickness is termed "off-label" use because although many people find it helpful, the effectiveness and possible side effects for this purpose have not been specifically studied by the manufacturer. This treatment does not always prevent altitude sickness, including the very serious conditions High Altitude Pulmonary Oedema (HAPE) and High Altitude Cerebral Oedema (HACE).',
    'agreement.confirmStatement3ts': 'Anti-sickness tablets can cause drowsiness and can impair your ability to drive or operate machinery.',
    'agreement.confirmStatementhajl': 'Please be aware that you are using this medicine for an ‘off-licensed’ indication, the manufacturer will not be held liable for any unwanted effects that you may experience.',

    'general.travelPurpose': 'What is the purpose of your travel?',
    'general.travelReasonDetails': 'Please give details:',
    'general.medicalFacilityAccess': 'At all times during your trip, will you be able to access medical facilities within 24 hours?','general.medicalFacilityAccessOriginal': 'Original Answer:',

    'general.correctMedication': 'Have you checked if this is the correct medication for the region you are visiting?  (If not, please confirm on www.fitfortravel.nhs.uk or check with your pharmacist).',
    'general.correctQuantity': 'Have you confirmed the quantity you require will be sufficient for the duration of treatment necessary? (If not, please confirm on www.fitfortravel.nhs.uk or check with your pharmacist).',
    'general.circumcised': 'Are you circumcised?',
    'general.face': 'Please select on which areas of the body you are experiencing the excess hair growth:',
    'general.faceOriginal': 'Original Answer:',
    'general.contributingfactors': 'Are you aware of any contributing factors that may be causing you ED:',
    'general.countries': 'Please state which countries/ regions you are intending to travel to:',
    'general.country': 'Please select the country you are visiting?',
    'general.growthRate': 'How would you best describe the growth of your facial hair?',
    'general.currentMedicationCystitis': 'At present are you taking Amiodarone, Warfain, Phenytoin, Azathioprine or any other antibiotics?',
    'general.currentMedicationCystitisOriginal': 'Original Answer:',
    'general.cystitisMedicationAnswer': 'Please confirm if in the past you have been treated for cystitis?',
    'general.cystitisMedicationDetails': 'Please give details:',
    'general.dateInformation': 'Please provide information on date and if possible approximate time you had unprotected sex?',
    'general.diagnosedBefore': 'Have you previously been diagnosed as suffering from thrush by your GP?',
    'general.disfigurement': 'Do you have some other disfigurement or abnormality of your penis or the genital area?',
    'general.disfigurementOriginal': 'Original Answer:',
    'general.eczemaDiagnosed': 'Please confirm if you have previously suffered or been diagnosed with Eczema by your regular GP?',
    'general.eczemaDiagnosedOriginal': 'Original answer:',
    'general.frequency': 'Have you seen an increase in frequency for your condition?',
    'general.gender': 'Gender',
    'general.gpMigraines': 'Have you been previously diagnosed with this condition by your GP?',
    'general.heartDisease': 'Do you suffer from heart disease?',
    'general.howLong': 'How long have you been suffering with your symptoms?',
    'general.howLongOriginal': 'Original answer:',
    'general.asthmaMedicationAnswer': 'Have you been previously diagnosed with this condition by your GP?',
    'general.asthmaMedicationAnswerOriginal': 'Original answer:',
    'general.asthmaReviewOriginal': 'Original answer:',
    'general.asthmaOtherMedicationAnswer': 'Do you take any other medication to help control your asthma / COPD?',
    'general.asthmaOtherMedicationDetails': 'Please give more details:',
    'general.howOften': 'How often do you experience the symptoms?',
    'general.howOftenOriginal': 'Original Answer:',
    'general.cystitisSuspection': 'Do you currently think you have cystitis?',
    'general.cystitisSuspectionOriginal': 'Original Answer:',
    'general.cystitisDiagnosed': 'Has a doctor or nurse diagnosed you with cystitis before?',
    'general.cystitisDiagnosedOriginal': 'Original Answer:',
    'general.cystitisReoccurrence': 'How many times in the last 12 months have you had cystitis?',
    'general.cystitisReoccurrenceOriginal': 'Original Answer:',
    'general.cystitisRecognitionAbility': 'Do you feel confident you can recognize when you have cystitis?',
    'general.cystitisRecognitionAbilityOriginal': 'Original Answer:',
    'general.cystitisTreated': 'Have you taken any treatment for this episode of cystitis?',
    'general.cystitisTreatedOriginal': 'Original Answer:',
    'general.asthmaReview': 'Have you had a review with your GP/respiratory nurse within the last 12 months?',
    'general.asthmaReview1': 'In the last month have your symptoms interfered with your sleep?',
    'general.asthmaReview2': 'In the last month, have your symptoms interfered with your normal daily activities?',
    'general.asthmaReview3': 'Have you had any unintentional weight loss in the last 6 months?',
    'general.asthmaReview3Details': 'Please give details:',
    'general.asthmaReview4': 'Are you happy with your inhaler technique?',
    'general.howManyTimesUseAWeek': 'How many times a week do you use your reliever inhaler?',
    'general.howManyInhalersAYear': 'How many inhalers have you used up in the last 12 months?',
    'general.howManyInhalersAYearOriginal': 'Original Answer:',
    'general.howSudden': 'Did your symptoms develop suddenly or gradually over a period of time?',
    'general.howSuddenOriginal': 'Original answer:',
    'general.maintainingErectionAnswer': 'Do you ever experience trouble getting or maintaining an erection during sex?',
    'general.maintainingErectionDetails': 'Please give details:',
    'general.ibsMedicationAnswer': 'Have you been previously diagnosed with this condition by your GP?',
    'general.ibsMedicationAnswerOriginal': 'Original answer:',
    'general.ibsMedicationDetails': 'Please give details:',
    'general.painEjaculation': 'Do you experience any pain or discomfort when you ejaculate?',
    'general.painEjaculationOriginal': 'Original Answer:',
    'general.pastMedication': 'Are you currently taking or have you previously taken medication to treat your ED?',
    'general.pastMedicationAnswer': 'Are you currently taking or previously used any medication(s) to treat your Condition?',
    'general.pastMedicationChangedSymptoms': 'Has there been a change in your symptoms since you last took this medication (such as increase in frequency or severity of symptoms)?',
    'general.pastMedicationDetails': 'Please give details of this medication, how often you are taking it, if it is helping to resolve the symptoms and any side effects that were experienced:',
    'general.pastMedicationHelped': 'Has the medication helped in controlling your symptoms?',
    'general.pastMedicationHowOften': 'How often are you taking this medication?',
    'general.pastMedicationHowSoonItStartedAgain': 'Once the symptoms have resolved and you have stopped taking the medication, after what period of time do the symptoms tend to re-appear?',
    'general.pastMedicationPropeciaAnswer': 'Do you currently take or ever taken Propecia (Finasteride) for male baldness or any other condition?',
    'general.pastMedicationPropeciaDetails': 'Please give details:',
    'general.hairlossGender': 'Are you genetically male or female?',
    'general.hairlossGenderOriginal': 'Original Answer:',
    'general.hairlossConfined': 'Are you losing hair from other parts of your body? E.g. eyebrows, eyelashes, chest, pubic or underarm hair?',
    'general.hairlossConfinedOriginal': 'Original Answer:',
    'general.hairlossSkinChanges': 'Are there changes to the skin in the areas of hair loss? E.g. do you have rough, flaky, itchy or scarred patches of skin where you have lost hair?',
    'general.hairlossSkinChangesOriginal': 'Original Answer:',
    'general.picture': 'Describe your hair loss pattern:',
    'general.pictureOriginal': 'Original answer:',
    'general.pastUse': 'Please confirm if in the past you have used Emergency Contraception?',
    'general.pastUseDetails': 'Please give details:',
    'general.currentUsage': 'Do you currently use a Ventolin (Salbutamol) inhaler?',
    'general.currentUsageOriginal': 'Original answer:',
    'general.previousUsage': 'Have you previously used this medication to help with your symptoms?',
    'general.previousUsageOriginal': 'Original answer:',
    'general.previousUsageAnswer': 'Have you previously used this medication to help with your symptoms?',
    'general.previousUsageAnswerOriginal': 'Original answer:',
    'general.previousUsageDetails': 'Please give details:',
    'general.reason': 'Whats the reason behind wanting to delay your periods?',
    'general.predictPeriods': 'Can you predict when your periods are due?',
    'general.predictPeriodsOriginal': 'Original answer:',
    
    'general.canGetErection':'Do you ever get an erection, for example in the morning or when you masturbate?',
    'general.factorStressTiredness': 'Are you aware if stress or tiredness is causing you ED?',
    'general.factorDepression': 'Are you aware if depression is causing you ED?',
    'general.factorPerformanceAnxiety': 'Are you aware if performance anxiety is causing you ED?',
    'general.factorRelationshipIssues': 'Are you aware if relationship issues are causing you ED?',
    'general.factorAge': 'Are you aware if age is causing you ED?',
    'general.factorUnhealthyLifestyle': 'Are you aware if unhealthy lifestyle is causing you ED?',
    'general.factorUnhealthyLifestyleDetails': 'Please give details:',
    'general.factorMedicationConditionOrMedication': 'Are you aware if a medical condition or medication is causing you ED?',
    'general.factorMedicationConditionOrMedicationDetails': 'Please give details:',
    'general.factorOther': 'Are you aware if something else is causing you ED?',
    'general.factorOtherDetails': 'Please give details:',
    
    'general.pastMedicationTreatment': 'Are you currently taking or have you previously taken medication to treat your ED?',
    'general.pastMedicationTreatmentDetails': 'Please give details:',
    'general.pastMedicationTreatmentAgain': 'Do you wish to purchase the same treatment again?',
    'general.pastMedicationTreatmentAgainDetails': 'Please give details:',
    'general.pastMedicationTreatmentSideEffects': 'Did you experience any side effects while on the treatment? ',
    'general.pastMedicationTreatmentSideEffectsDetails': 'Please give details:',
    
    // 'general.risksDiabetes': 'Do you have Diabetes',
    // 'general.risksHighBloodPressure': 'Do you have High Blood pressure',
    // 'general.risksHighCholesterol': 'Do you have High Cholesterol',
    // 'general.risksRelativesStrokeUnder60': 'Do you have any relatives who has had a heart attack or stoke under the age of 60?',
    // 'general.risksSmoke': 'Do you smoke?',
    'general.symptoms': 'Please select if you have experienced any of the following symptoms:',
    'general.symptomsAbnormalVaginalBleeding': 'Do you have abnormal Vaginal Bleeding?',
    'general.symptomsDetails': 'Do you have other symptoms?',
    'general.symptomsDullAcheInTesticles': 'Do you have dull ache in testicles?',
    'general.symptomsLowerAbodminalPelvicPain': 'Do you have lower abodminal or Pelvic pain?',
    'general.symptomsHeavyDischargeFromTheVagina': 'Do you have heavy discharge from the vagina?',
    'general.symptomsPainDuringSex': 'Do you have pain during sex?',
    'general.symptomsPainfulInflamedTesticles': 'Do you have painful inflamed testicles?',
    'general.symptomsPainOrBurningWhenPassingUrine': 'Do you have pain or burning when passing urine?',
    'general.symptomsSeverePainInTesticles': 'Do you have severe pain in testicles?',
    'general.symptomsThickOrBloodyDischargeFromPenis': 'Do you have thick or bloody discharge from the penis?',
    'general.symptomsBackOrSidePain': 'Do you have pain on your back or side?',
    'general.symptomsBackOrSidePainOriginal': 'Original Answer:',
    'general.symptomsHighTemperature': 'Do you have fever(or high temperature)?',
    'general.symptomsHighTemperatureOriginal': 'Original Answer:',
    'general.symptomsUncontrollableShaking': 'Are you shaking uncontrollably?',
    'general.symptomsUncontrollableShakingOriginal': 'Original Answer:',
    'general.symptomsFeelingFaint': 'Are you feeling faint?',
    'general.symptomsFeelingFaintOriginal': 'Original Answer:',
    'general.priligyHistory': 'Have you used Priligy before?',
    'general.priligyHistorySymptomsFainting': 'Did you experience fainting, when taking Priligy?',
    'general.priligyHistorySymptomsFaintingOriginal': 'Original answer:',
    'general.priligyHistorySymptomsDizziness': 'Did you experience dizziness, particularly on standing up or changing position, when taking Priligy?',
    'general.priligyHistorySymptomsDizzinessOriginal': 'Original answer:',
    
    'general.priligyHistorySideEffects': 'Did you experience any other side effects?',
    'general.priligyHistorySideEffectsDetails': 'Please give details:',
    'general.priligyOtherOptions': 'Have you previously considered other options for treating your premature ejaculation other than Priligy?',
    'general.priligyHelpful': 'Did you found Priligy helpful?',
    'general.priligyHelpfulOriginal': 'Original Answer:',
    'general.howLongSexuallyActive': 'For how long have you been sexually active?',
    'general.preEjacNewIssue': 'Is PE a new problem or one you have experienced throughout your sex life?',
    'general.howOftenPE': 'How often do you experience PE?',
    'general.howOftenPEOriginal': 'Original answer:',
    'general.howOftenPEDetails': 'Please give details:',
    'general.howLongEjaculation': 'How long after penetration do you usually ejaculate?',
    'general.howLongEjaculationOriginal': 'Original answer:',
    'general.preEjacRelationshipDistress': 'Is your PE causing you distress or relationship difficulties?', 
    'general.preEjacRelationshipDistressOriginal': 'Original answer:', 

    'general.symptomsChlamydiaOther': 'Other',
    'general.symptomsChlamydiaDetails': 'Please give details:',
    'general.bleeding': 'Do you have irregular or unexplained vaginal bleeding?',
    'general.bleedingOriginal': 'Original answer:',
    'general.ulcers': 'Do you have open Sores, ulcers or blisters in the genital Area?',
    'general.ulcersOriginal': 'Original answer:',
    'general.otherSymptomsAnswer': 'Do you have any other symptoms?',
    'general.otherSymptomsDetails': 'Please give details:',
    'general.timeDifference': 'Select the difference in time zone between your departure airport and destination airport:',
    'general.typeOfHair': 'How would you best describe the type your facial hair?',
    'general.typeOfHairConfirmation': 'Vaniqa is known to only reduce the appearance of facial hair that is thick and coarse. Please select if you wish to carry on with the consultation.',
    'general.typeOfHairConfirmationOriginal': 'Original answer:',
    'general.unusualBleedingAnswer': 'Did you experience any unusual vaginal bleeding whilst on this medication?',
    'general.unusualBleedingDetails': 'Please give details:',
    'general.use': 'Are you looking to use this medication immediately or in the future?',
    'general.useReason': 'Why do you feel you require this medication:', 
    'general.useReasonDetails': 'Please give details:',
    'general.howLongAsthma': 'For how long have been using Ventolin (salbutamol)?',
    'general.whatForAnswer': 'Why are you looking to obtain a Ventolin (Salbutamol) inhaler?',
    'general.whatForDetails': 'Please give details:',
    'general.conditionDiagnosed': 'Has this condition been diagnosed by a medical doctor (GP or hospital doctor)?',
    'general.conditionDiagnosedOriginal': 'Original answer:',
    'general.who': 'Please specify who you are looking to purchase this treatment for?',
    'general.tripLength': 'Please state the length of your visit:',
    'general.itchyVagina': 'Are you experiencing Itching or irritation of the vagina?',
    'general.itchyVaginaOriginal': 'Original answer:',
    'general.vaginalDischarge': 'New or abnormal vaginal discharge?',
    'general.vaginalDischargeOriginal': 'Original answer:',

    'general.unprotectedSexDay1':'Have you had unprotected sex 1 day ago?',
    'general.unprotectedSexDay2':'Have you had unprotected sex 2 days ago?',
    'general.unprotectedSexDay3':'Have you had unprotected sex 3 days ago?',
    'general.unprotectedSexDay4':'Have you had unprotected sex 4 days ago?',
    'general.unprotectedSexDay5':'Have you had unprotected sex 5 days ago?',
    'general.unprotectedSexDay6':'Have you had unprotected sex 6 days - 3 weeks ago?',
    'general.unprotectedSexDay6Original':'Original answer:',
    'general.unprotectedSexDay7':'Have you had unprotected sex more than 3 weeks ago?',
    'general.unprotectedSexDay7Original':'Original answer:',
    'general.unprotectedSexTime':'At what time did you have unprotected sex? (this helps us ensure the medication is suitable for you – just tell us the earliest time on the first day in this cycle)',
    'general.contraceptionFailed':'Please tell us why you think your contraception may have failed?',
    'general.contraceptionFailedDetails':'Please give details:',
    'general.unprotectedSexAlreadyMedicated':'Have you used emergency contraception before in THIS menstrual cycle?',
    'general.unprotectedSexAlreadyMedicatedOriginal':'Original answer:',
    'general.lastPeriod':'Was your last period normal?',
    'general.lastPeriodOriginal':'Original answer:',
    'general.contraceptionUse':'Are you using a regular form of contraception?',
    'general.contraceptionType':'Which type are you using?',
    'general.contraceptionName':'What is the name of your pill, patch or ring?',
    'general.contraceptionUseLast7Days':'Have you been using it in the last 7 days?',
    'general.nextPeriod':'When is your next period due?',
    'general.nextPeriodOriginal':'Original answer:',

    'medicationHistory.periodDelayMedication': 'Have you used period delay medication before?',
    'medicationHistory.periodDelayMedicationDetails': 'What did you use?',
    'medicationHistory.periodDelayMedicationSideEffects': 'Did you experience any side effects?',
    'medicationHistory.periodDelayMedicationSideEffectsDetails': 'Please give details:',

    'medicationHistory.edCurrentUse': 'Are you taking treatment for Erectile Dysfunction? For example: Viagra™ (Sildenafil), Cialis™ (Tadalafil), Spedra™ (Avanafil), Levitra™ (Vardenafil) or Vitaros Cream™ (Alprostadil).',
    'medicationHistory.edCurrentUseOriginal': 'Original answer:',
    'medicationHistory.pePreviousTreatment': 'Have you previously tried any treatment for PE?',
    'medicationHistory.pePreviousTreatmentDetails': 'Please give details:',
    'medicationHistory.pePrescriptionMedication': 'Do you take any prescription medication? Include medication you only take as and when you need it such as treatment for migraine or hayfever (Please note Priligy should NOT be taken with medication to treat erectile dysfunction)',
    'medicationHistory.pePrescriptionMedicationDetails': 'Please give details:',
    'medicationHistory.peCounterMedication': 'Do you take any medicines that have been purchased over the counter, herbal medications or supplements?',
    'medicationHistory.peCounterMedicationDetails': 'Please give details:',
    'medicationHistory.peLastTwoMonthsMedication': 'In the last 2 months have you taken any other medication?',
    'medicationHistory.peLastTwoMonthsMedicationDetails': 'Please give details:',
    'medicationHistory.peHeavyMachinery': 'Do you drive or operate any hazardous machinery or heavy goods vehicles?',
    'medicationHistory.peHeavyMachineryDetails': 'Please give details:',

    'medicationHistory.hairlossMedication': 'Do you currently take Propecia (Finasteride) for male-pattern hair loss or any other condition (for example, an enlarged prostate)?',
    'medicationHistory.hairlossMedicationFinasteride': 'Do you currently take Finasteride (Generic) for male-pattern hair loss or any other condition (for example, an enlarged prostate)?',
    'medicationHistory.hairlossMedicationDosage': 'What dose are you taking?',
    'medicationHistory.hairlossMedicationDosageDetails': 'Please give details:',
    'medicationHistory.hairlossMedicationHowLong': 'For how long have you been using Propecia (Finasteride)?',
    'medicationHistory.hairlossMedicationHowLongFinasteride': 'For how long have you been using Finasteride (Generic)?',
    'medicationHistory.hairlossMedicationSideEffects': 'Have you experienced any side effects?',
    'medicationHistory.hairlossMedicationSideEffectsDetails': 'Please give details:',
    'medicationHistory.enlargedProstateMedication': 'Do you take Dutasteride (Avodart) for an enlarged prostate?',
    'medicationHistory.enlargedProstateMedicationOriginal': 'Original Answer:',
    'medicationHistory.chemoRadioImmunoTherapy': 'Have you recently been treated with cancer chemotherapy, radiotherapy or immunotherapy?',
    'medicationHistory.chemoRadioImmunoTherapyOriginal': 'Original Answer:',
    
    'medicationHistory.confirmFatalReaction': 'I confirm I have read and understood the FATAL reaction between nitrates and ED medication. I will speak to a doctor if I am unsure whether this applies to me.',
    'medicationHistory.currentMedicationNitrates': 'Are you taking any nitrates including glyceryl trinitrate spray, cream, patch or gel, isosorbide mononitrate or isosorbide dinitrate? (NB these can be sold under many different brand names - look for the name of the drug itself on the packet)',
    'medicationHistory.currentMedicationNitratesOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationAlphaBlockers': 'Are you taking any drugs classed as alpha-blockers? These medicines are usually used to treat high blood pressure or prostate problems. For a list click here – then reveal the following list They include: alfuzosin, doxazosin, indoramin, prazosin, tamsulosin, terazosin or a medication available over the counter called Flomax?',
    'medicationHistory.currentMedicationAlphaBlockersOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationBetaBlockers': 'Are you taking any beta-blockers? For a list click here Propranolol. Sotalol, esmolol, acebutolol, bisoprolol, carvedilol. Celiprolol, labetalol, metoprolol, nadolol, nebivolol, oxprenolol, pindolol, timolol',
    'medicationHistory.currentMedicationBetaBlockersOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationCalciumChannelBlockers': 'Are you taking any calcium channel blockers? For a list click here e.g. amlodipine, diltiazem, felodipine, isradipine, lacidipine, lercanidpine, nicardipine, nifedipine, nimodipine, verapamil',
    'medicationHistory.currentMedicationCalciumChannelBlockersOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationDiuretics': 'Are you taking any diuretics (sometimes called water tablets)? E.g. spironolactone, bendroflumethiazide, indapamide, metolazone, bumetanide, chlortalidone, furosemide, frusemide, torasemide',
    'medicationHistory.currentMedicationDiureticsOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationAntidepressants': 'Are you taking antidepressants? Fluoxetine, citalopram, sertraline, paroxetine, escitalopram, venlafaxine, fluxvoxamine, duloxetine, mirtazapine, reboxetine',
    'medicationHistory.currentMedicationAntidepressantsOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationLowerCholesterol': 'Are you taking any medication to lower your cholesterol? E.g. simvastatin, atorvastatin, rosuvastatin, pravastatin, fenofibrate, nicotinic acid, ezetimibe, colestyramine. (Remember, your Pharmacist may be able to check your cholesterol)',
    'medicationHistory.currentMedicationLowerCholesterolOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationRiociguat': 'Are you taking Riociguat? (this medication is normally used to treat pulmonary arterial hypertension i.e. high blood pressure in the lungs',
    'medicationHistory.currentMedicationRiociguatOriginal': 'Original Answer:',
    'medicationHistory.currentMedicationPainkillers': 'Are you taking any painkillers?',
    'medicationHistory.currentMedicationPainkillersDetails': 'Please give details:',
    'medicationHistory.prescriptionOtherMedication': 'Are you taking any other prescription medications? Please list them here. Remember to include anything you take occasionally, such as migraine treatment, hayfever remedies or antacids. ',
    'medicationHistory.prescriptionOtherMedicationDetails': 'Please give details:',
    'medicationHistory.edRecreationalDrugs': 'Do you take any recreational drugs? In particular, cannabis, cocaine, heroin, ecstasy or amyl nitrate (poppers).',
    'medicationHistory.edRecreationalDrugsDetails': 'Please give details:',

    'medicationHistory.contraIndicationsAcetazolamide': 'Are you taking or planning to use the aspirin or non-steroidal anti-inflammatory drugs such as ibuprofen while taking acetazolamide?',    
    'medicationHistory.counterMedicationAnswer': 'Do you take any medicines that have been purchased over the counter, herbal medications or supplements?',
    'medicationHistory.counterMedicationDetails': 'Please give details:',
    'medicationHistory.currentAsthmaMedication': 'Are you taking any other inhalers or other medication for your asthma/COPD?',
    'medicationHistory.currentAsthmaMedicationDetails': 'Please give details:',
    'medicationHistory.currentMedication1AR': 'Are you presently taking any type of Non-Steroidal Anti-Inflammatory (for example Ibuprofen, Naproxen or Diclofenac), Propranolol, Diazepam, Phenytoin, Theophylline, Warfarin or Glipizide?',
    'medicationHistory.currentMedication2AR': 'Are you presently taking Diazepam, Phenytoin, Warfarin, Digoxin or Tacrolimus?',
    'medicationHistory.currentMedications1CHL': 'At present are you taking any of the following medications? Ciclosporin, Digoxin, Ergotamine, Terfenadine, Theophylline, Disopyramide, Rifabutin, Warfarin?',
    'medicationHistory.currentMedications2CHL': 'At present are you taking any of the following medications? Warfarin, Penicillin, Phenobarbital, Carbamazepine, Phenytoin, Primidone, Ciclosporin?',
    'medicationHistory.currentMedications1TD': 'Are you taking any of the following medication: Quinindine, Ritonavir?',
    'medicationHistory.currentMedications1TDOriginal': 'Original answer:',
    'medicationHistory.currentMedications2TD': 'Are you taking any of the following medication: Clozapine, Methotrexate, Probenecid, Ropinirole, Phenytoin, Pentoxifylline, Theophylline, Tizanidine, Warfarin?',
    'medicationHistory.currentMedications2TDOriginal': 'Original answer:',
    'medicationHistory.currentMedications3TD': 'At present are you taking any of the following medications? Ciclosporin, Digoxin, Ergotamine, Terfenadine, Theophylline, Disopyramide, Rifabutin, Warfarin:',
    'medicationHistory.currentMedications3TDOriginal': 'Original answer:',
    'medicationHistory.currentMedications1Trush': 'At present are you taking any of the following medications: Theophylline, Warfarin, Digoxin?',
    'medicationHistory.currentMedications1TrushOriginal': 'Original answer:',
    'medicationHistory.currentMedicationsTS': 'Are you currently taking any of the following medication: Amantadine, Antihistamines, Antipsychotics, Antidepressants, Domperidone, Metoclopramide, GTN tablets or spray?',
    'medicationHistory.currentMedicationsTSOriginal': 'Original answer:',
    'medicationHistory.FluvoxamineMedicationAnswer': 'Do you take Fluvoxamine?',
    'medicationHistory.FluvoxamineMedicationAnswerOriginal': 'Original answer:',
    'medicationHistory.medication': 'Please select if you are taking any of the following medication',
    'medicationHistory.prescriptionMedicationAnswer': 'Do you take any prescription medicines?',
    'medicationHistory.prescriptionMedicationDetails': 'Please give details:',
    'medicationHistory.recreationalDrugsAnswer': 'Do you use any recreational drugs?',
    'medicationHistory.recreationalDrugsDetails': 'Please give details:',
    'medicationHistory.currentMedicationCystitis': 'At present are you taking Amiodarone, Warfain, Phenytoin, Azathioprine or any other antibiotics?',
    'medicationHistory.currentMedicationCystitisOriginal': 'Original answer:',
    'medicationHistory.alphaBlockers': 'Are you taking any Alpha-blockers (for example Alfuzosin, Doxazosin, Indoramin, Tamsulosin, Terazosin)',
    'medicationHistory.alphaBlockersOriginal': 'Original answer:',
    'medicationHistory.nitrates': 'Are you taking any Nitrates (for example Isosorbide Mononitrate, Isosorbide Dinitrate, Nicorandil or GTN spray or tablets)',
    'medicationHistory.nitratesOriginal': 'Original answer:',
    'medicationHistory.betaBlockers': 'Are you taking any Beta-blockers (for example atenolol, bisoprolol, metoprolol, nebivolol, propranolol)',
    'medicationHistory.betaBlockersOriginal': 'Original answer:',
    'medicationHistory.diuretics': 'Are you taking any Diuretics (for example bendroflumethiazide, metolazone, furosemide, bumetanide, indapamide)',
    'medicationHistory.diureticsOriginal': 'Original answer:',
    'medicationHistory.riociguat': 'Are you taking Riociguat (this medication is normally used to treat pulmonary arterial hypertension i.e. high blood pressure in the lungs or chronic pulmonary hypertension i.e. high blood pressure in the lungs)',
    'medicationHistory.riociguatOriginal': 'Original answer:',
   
    'medicationHistory.MoclobemideMedicationAnswer': 'Do you take Moclobemide?',
    'medicationHistory.MoclobemideMedicationAnswerOriginal': 'Original answer:',
    'medicationHistory.doxycyclinePreviousUse': 'Have you used Doxycycline before?',
    'medicationHistory.doxycyclinePreviousUseDetails': 'please give details of any side effects you experienced whilst on this medication:',
    'medicationHistory.doxycyclineSignOff': '3 out of 100 people who use doxycycline will find their skin becomes more sensitive to sunlight.  This increases the chances of sunburn. Be sure to take care in the sun by using a high factor sunscreen or covering up.',
    'medicationHistory.doxycyclineMedicationCheck': 'Do you take or intend to take any of the following medication while using antimalarial treatment? Methotrexate, Corticosteroids, Sulfonylureas for diabetes (gliclazide, glipizide, glibenclamide, gliperimide), Retinoids, Drugs to prevent seizures, Malarone or Atovaquone',
    'medicationHistory.doxycyclineMedicationCheckOriginal': 'Original Answer:',
    'medicationHistory.doxycyclineIndigestionCheck': 'Do you take indigestion remedies or oral iron supplements?',
    'medicationHistory.chloroquinePreviousUse': 'Have you used this antimalarial before?',
    'medicationHistory.chloroquineSignOff': 'Do you take any of the following medication? Amiodarone, Praziquantel, Agasidase alfa and beta, Kaolin (component of diarrhea treatment), Moxifloxacin, Escitalopram, Citalopram, Another antimalarial artemether and lmefantrine, Mefloquine (Lariam), Droperidol, Digoxin, Ciclosporin,Bosutinib, Histamine, Lanthanum, Laronidase, Neostigmine and pyridostigmine, Penicillamine, Cimetidine, Pyrimethamine, Efavirenz.',
    'medicationHistory.chloroquineSignOffOriginal': 'Original Answer:',
    'medicationHistory.chloroquineProguanilSignOff': 'Do you take any of the following medication? Amiodarone, Praziquantel, Agasidase alfa and beta, Kaolin (component of diarrhea treatment), Moxifloxacin, Escitalopram, Citalopram, Another antimalarial artemether and lmefantrine, Mefloquine (Lariam), Droperidol, Digoxin, Ciclosporin,Bosutinib, Histamine, Lanthanum, Laronidase, Neostigmine and pyridostigmine, Penicillamine, Cimetidine, Pyrimethamine, Efavirenz.',
    'medicationHistory.lariamPreviousUse': 'Have you used Lariam before?',
    'medicationHistory.lariamPreviousUseDetails': 'please give details of any side effects you experienced whilst on this medication:',
    'medicationHistory.lariamPreviousMeds': 'Do you take or intend to take any of the following medication while using Lariam? Amiodarone, Hydroxychloroquine, chloroquine, quinine, quinidine, Beta-blockers (Propranolol, Sotalol, Esmolol, Acebutolol, Bisoprolol, Carvedilol, Celiprolol, Labetalol, Metoprolol, Nadolol, Nebivolol, Oxprenolol, Pindolol, Timolol), Calcium channel blockers (Amlodipine, Diltiazem, Felodipine, Isradipine, Lacidipine, Lercanidpine, Nicardipine, Nifedipine, Nimodipine, Verapamil), Digoxin, Moxifloxacin, Vortioxetine, Amisulpiride, haloperidol, pimizide, risperidone, Ritonavir, Atomexatine, Crizotinib, Histamine, Ivabradine, carbamazepine, Anticonvulsants, Ketoconazole, Penicillamine',
    'medicationHistory.lariamPreviousMedsOriginal': 'Original Answer:',
    'medicationHistory.malaronePreviousUse': 'Have you used Malarone before?',
    'medicationHistory.malaronePreviousUseDetails': 'please give details of any side effects you experienced whilst on this medication:',
    'medicationHistory.malaronePreviousMeds': 'Do you take any of the following medicines? Doxycycline, Lymecycline, Oxytetracycline, Tetracycline, Minocycline, Rifampicin, Rifabutin, Indinavir, ritonavir, efavirenz, zidovudine, Antacids containing the ingredient magnesium, The antimalarial Artemether and lumefantrine, Pyrimethamine, Histamine, Penicillamine',
    'medicationHistory.malaronePreviousMedsOriginal': 'Original Answer:',
    'medicationHistory.paludrineHeartConditions': 'Do you have any heart conditions, especially cardiac conduction disorders?',
    'medicationHistory.warfarinMalaria': 'Do you take warfarin or any medicine(s) to thin your blood?',
    'medicationHistory.warfarinMalariaOriginal': 'Original Answer:',
    'medicationHistory.doxycyclineSideEffects': 'Did you experience any side effects?',

    'medicationHistory.chloroquineSideEffects': 'Did you experience any side effects?',
    'medicationHistory.chloroquinePreviousUseDetails': 'Please give details:',
    'medicationHistory.vaccineNeededChloroquine': 'Do you intend to be vaccinated against Rabies before you travel?',
    'medicationHistory.lariamSideEffects': 'Did you experience any side effects?',
    'medicationHistory.malaroneSideEffects':'Did you experience any side effects?',
    'medicationHistory.malariaTyphoidVaccine':'Do you intend to be vaccinated against Typhoid before you travel?',
    'medicationHistory.malariaCholeraVaccine':'Do you intend to be vaccinated against Cholera before you travel?',


    'medicalHistory.pdCriticalConditionPorphyria': 'Do you have or have you ever had porphyria?',
    'medicalHistory.pdCriticalConditionPorphyriaOriginal': 'Original answer:',
    'medicalHistory.pdCriticalConditionCancer': 'Do you have or have you ever had cancer now or in the past?',
    'medicalHistory.pdCriticalConditionCancerOriginal': 'Original answer:',
    'medicalHistory.pdCriticalConditionUndiagnosedVaginalBleeding': 'Do you have or have you ever had undiagnosed vaginal bleeding (bleeding between your periods or after sex)?',
    'medicalHistory.pdCriticalConditionUndiagnosedVaginalBleedingOriginal': 'Original answer:',
    'medicalHistory.pdCriticalConditionArterialDisease': 'Do you have or have you ever had severe arterial disease including history of heart attack or stroke?',
    'medicalHistory.pdCriticalConditionArterialDiseaseOriginal': 'Original answer:',
    'medicationHistory.pdConditionBloodClotLegLungs': 'Do you have or have you ever had a blood clot in your leg or lungs (deep vein thrombosis or pulmonary embolism)?',
    'medicationHistory.pdConditionAntiphospholipidSyndrome': 'Do you have or have you ever had an inherited predisposition to blood clots such as antiphospholipid syndrome?',
    'medicationHistory.pdConditionSystemicLupusErythematosus': 'Do you have or have you ever had Systemic Lupus Erythematosus?',
    'medicationHistory.pdConditionHeartAttackStrokeTIA': 'Do you have or have you ever had a heart attack, stroke or transient ischaemic attack (TIA or mini-stroke)?',
    'medicationHistory.pdConditionMigraineWithAura': 'Do you have or have you ever had a migraine with aura?',
    'medicationHistory.pdConditionBedWheelchairboundPlaster': 'Do you have or have you ever had a reason to be immobile such as being bed-bound, wheelchair bound or having your leg in a plaster cast?',
    'medicationHistory.pdConditionSurgeryPast3MonthsNext8Weeks': 'Do you have or have you ever had surgery in the last 3 months or due to have surgery in the next 8 weeks?',
    'medicationHistory.pdConditionGallstones': 'Do you have or have you ever had Gallstones?',
    'medicationHistory.pdConditionVaricoseVeins': 'Do you have or have you ever had treatment for varicose veins or superficial thrombophlebitis (inflamed leg veins that may be red, hot, painful and itchy)?',
    'medicationHistory.pdConditionChronsDisease': 'Do you have or have you ever had Crohn’s disease or ulcerative colitis?',
    'medicationHistory.pdConditionHighLipidLevelsBlood': 'Do you have or have you ever had history of high lipid(fat) levels in the blood?',
    'medicationHistory.pdConditionSickleCellDisease': 'Do you have or have you ever had Sickle cell disease?',
    'medicationHistory.pdConditionHighBloodPressure16095': 'Do you have or have you ever had high blood pressure > 160/95?',
    'medicationHistory.pdConditionPregnancyItchingRashJaundice': 'Do you have or have you ever had history during pregnancy of itching, blistering skin rash (pemphigoid) or jaundice (yellowing of the skin and eyes)?',
    'medicationHistory.pdConditionAsthma': 'Do you have or have you ever had Asthma?',
    'medicationHistory.pdConditionHeartDisease': 'Do you have or have you ever had heart disease/heart failure/heart attack?',
    'medicationHistory.pdConditionDiabetes': 'Do you have or have you ever had Diabetes?',
    'medicationHistory.pdConditionEpilepsy': 'Do you have or have you ever had epilepsy, brain injury or seizures?',
    'medicationHistory.pdConditionDepression': 'Do you have or have you ever had depression?',
    'medicationHistory.pdConditionHighBloodPressure14090': 'Do you have or have you ever had high blood pressure > 140/90?',
    'medicationHistory.pdConditionMigraineWithoutAura': 'Do you have or have you ever had a migraine without aura?',
    'medicationHistory.pdConditionUlcers': 'Do you have or have you ever had history of Peptic or Gastric Ulcers?',

    'medicationHistory.previousMorningPillUse': 'Have you used the emergency contraceptive pill before and if so, did you experience any side effects?',
    'medicationHistory.previousMorningPillUseDetails': 'Please give details:',
    'medicationHistory.herbalAntidepressant': 'Have you taken the herbal antidepressant St John’s Wort (Hypericum perforatum) in the last 28 days?',
    'medicationHistory.stomachAcidUlcersTreatment': 'Have you taken medication to reduce stomach acid or treat stomach ulcers e.g omeprazole, esomeprazole, lansoprazole in the last 28 days?',
    'medicationHistory.HIVTreatment': 'Have you taken treatment for HIV: efavirenz, nevirapine, ritonavir in the last 28 days?',
    'medicationHistory.tuberculosisAntibiotics': 'Have you taken antibiotics for tuberculosis: rifampicin or rifabutin in the last 28 days?',
    'medicationHistory.epilepsyTreatment': 'Have you taken treatment for epilepsy in the last 28 days?',
    'medicationHistory.griseofulvin': 'Have you taken Griseofulvin (treats fungal infections) in the last 28 days?',
    'medicationHistory.ciclosporin': 'Have you taken Ciclosporin in the last 28 days?',
    'medicationHistory.oralSteroidTablets': 'Have you taken oral steroid tablets in the last 28 days?',
    'medicationHistory.counterActionDetails': 'These medications can reduce the effectiveness of the emergency contraceptive pill. Please give details about what you have taken:',

    'medicalHistory.painEjaculation': 'Do you experience any pain or discomfort when you ejaculate?',
    'medicalHistory.painEjaculationOriginal': 'Original Answer:',
    'medicalHistory.difficultyMaintainingErection': 'Do you regularly experience any difficulty getting or maintaining an erection BEFORE you ejaculate?',
    'medicalHistory.difficultyMaintainingErectionTreatment': 'Are you taking any treatment for erectile dysfunction e.g. Viagra or similar drugs?',
    'medicalHistory.peMentalHealthCondition': 'Do you have or have you ever been diagnosed with any mental health condition including bipolar disorder, mania or depression?',
    'medicalHistory.peMentalHealthConditionOriginal': 'Original Answer:',
    'medicalHistory.peLowBloodPressure': 'Do you have or have you ever been diagnosed with low blood pressure, fainting, collapse or feeling dizzy standing on standing up?',
    'medicalHistory.peLowBloodPressureOriginal': 'Original Answer:',
    'medicalHistory.peHeartProblems': 'Do you have or have you ever been diagnosed with heart problems including heart attack, an irregular heartbeat, (angina) chest pain or heart failure?',
    'medicalHistory.peHeartProblemsOriginal': 'Original Answer:',
    'medicalHistory.peBrainInjury': 'Do you have or have you ever been diagnosed with stroke or brain injury?',
    'medicalHistory.peBrainInjuryOriginal': 'Original Answer:',
    'medicalHistory.peEpilepsy': 'Do you have or have you ever been diagnosed with epilepsy, seizures or convulsions?',
    'medicalHistory.peEpilepsyOriginal': 'Original Answer:',
    'medicalHistory.peBleedingDisorder': 'Do you have or have you ever been diagnosed with a bleeding or blood clotting disorder',
    'medicalHistory.peBleedingDisorderOriginal': 'Original Answer:',
    'medicalHistory.peUlcer': 'Do you have or have you ever been diagnosed with stomach  or duodenal (peptic) ulcer?',
    'medicalHistory.peUlcerOriginal': 'Original Answer:',
    'medicalHistory.peProstateCondition': 'Do you have or have you ever been diagnosed with prostate conditions or surgery for the prostate?',
    'medicalHistory.peProstateConditionOriginal': 'Original Answer:',
    'medicalHistory.peGlaucomaSusceptability': 'Do you have or have you ever been diagnosed with susceptibility to angle-closure glaucoma?',
    'medicalHistory.peGlaucomaSusceptabilityOriginal': 'Original Answer:',

    'medicalHistory.peyroniesDisease': 'Do you have any deformity of the penis, for example Peyronie’s disease?',
    'medicalHistory.peyroniesDiseaseDetails': 'Please give details:',
    'medicalHistory.circumcised': 'Are you circumcised?',
    'medicalHistory.retractForeskin': 'If no can you fully retract the foreskin when erect?',
    'medicalHistory.retractForeskinDetails': 'Please give details:',
    'medicalHistory.priapism': 'Have you ever experienced priapism?',
    'medicalHistory.priapismOriginal': 'Original Answer:',
    'medicalHistory.prostateSurgeryDiagnosed': 'Have you every had prostate surgery or been diagnosed with a prostate problem?',
    'medicalHistory.prostateSurgeryDiagnosedDetails': 'Please give details:',
    'medicalHistory.edHeartConditions': 'Do you have any heart conditions? This includes such problems as heart failure, arrhythmias (irregular heart beat), angina (heart-related chest pain), aortic stenosis (narrow heart valve), cardiomyopathy, severe heart disease or uncontrolled high blood pressure (over 160/90)? NB heart failure, arrhythmias and uncontrolled hypertension are contraindications to tadalafil. All PDE5 inhibitors should be used with caution in cardiovascular disease.',
    'medicalHistory.edHeartConditionsOriginal': 'Original Answer:',
    'medicalHistory.heartAttack6months': 'Have you had a heart attack, heart operation or stroke in the last 6 months?',
    'medicalHistory.heartAttack6monthsOriginal': 'Original Answer:',
    'medicalHistory.edLowBloodPressure': 'Do you suffer from low blood pressure (systolic pressure less than 90mmHg), fainting or dizzy spells, particularly on standing up?',
    'medicalHistory.edLowBloodPressureOriginal': 'Original Answer:',
    'medicalHistory.currentBloodPressure': 'If you know your blood pressure, please enter it here. If you do not know your blood pressure, you pharmacist may be able to measure it for you.',
    'medicalHistory.edDiabetesDiagnosed': 'Have you ever been diagnosed with diabetes or abnormally raised blood sugar levels? Please note: diabetes can be a cause of ED so if you have not had your blood sugar checked since experiencing ED we recommend you see your doctor.',
    'medicalHistory.edDiabetesDiagnosedOriginal': 'Original Answer:',
    'medicalHistory.suddenBlindness': 'Have you ever experienced sudden loss of vision in one eye due to a condition called Non-arteritic anterior optic neuropathy (NAION)?',
    'medicalHistory.suddenBlindnessOriginal': 'Original Answer:',
    'medicalHistory.suddenBlindnessDetails': 'Please give details:',
    'medicalHistory.hereditaryRetinaDisorder': 'Do you have any hereditary disorders of the retina (eyes)?',
    'medicalHistory.hereditaryRetinaDisorderDetails': 'Please give details:',
    'medicalHistory.bloodClottingCondition': 'Do you have any conditions affecting the clotting of the blood such as leukaemia, multiple myeloma, sickle cell disease or other bleeding disorder?',
    'medicalHistory.bloodClottingConditionOriginal': 'Original Answer:',
    'medicalHistory.galactoseIntolerance': 'Do you have galactose intolerance, glucose-galactose malabsorption or Lapp lactase deficiency?',
    'medicalHistory.galactoseIntoleranceOriginal': 'Original Answer:',
    
    'medicalHistory.pulserate':'Pulse Rate:',
    'medicalHistory.bloodpressure' :'Blood Pressure:',
    'medicalHistory.temperature':'Body Temperature:',
    'medicalHistory.cystitisSpread': 'Have you ever had cystitis that spread to your kidneys or required you to go to hospital?',
    'medicalHistory.cystitisSpreadOriginal': 'Original answer:',
    'medicalHistory.urinaryInfectionPregnant': 'Is there any possibility you could be pregnant? Urinary tract infection is more common in pregnancy and can have serious consequences such as preterm labour or kidney infection, however NOT all treatments are safe in pregnancy.',
    'medicalHistory.urinaryInfectionPregnantOriginal': 'Original answer:',
    'medicalHistory.kidneyBladderUrinaryCondition': 'Have you ever been diagnosed with a kidney, bladder or urinary tract condition other than urine infections?',
    'medicalHistory.kidneyBladderUrinaryConditionOriginal': 'Original answer:',
    'medicalHistory.kidneyBladderUrinarySurgery': 'Have you every had any surgery or procedures performed on your kidneys, bladder or urinary tract?',
    'medicalHistory.kidneyBladderUrinarySurgeryDetails':  'Please give details:',
    'medicalHistory.catheterInPlace': 'Do you currently have a catheter in place to drain your urine? ',
    'medicalHistory.catheterRemoved': 'Have you had a catheter removed within the last 14 days?',
    'medicalHistory.G6PDDeficiency': 'Have you ever been diagnosed with G6PD deficiency?',
    'medicalHistory.weakenedImmuneSystem': 'Do you have an weakened immune system, for example due to a medical condition such as leukaemia or AIDS, or due to drugs such as steroids or those used to treat cancer?',
    'medicalHistory.otherMedicalConditions': 'Do you have any other medical conditions, illnesses or have you had any other surgical procedures other than those already mentioned?',
    'medicalHistory.otherMedicalConditionsDetails':  'Please give details:',

    'medicalHistory.hairlossConditionHyperthyroidism': 'Have you been diagnosed with Hyperthyroidism?',
    'medicalHistory.hairlossConditionHyperthyroidismOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionDiabetes': 'Have you been diagnosed with Diabetes?',
    'medicalHistory.hairlossConditionDiabetesOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionDownsyndrome': 'Have you been diagnosed with Down’s syndrome?',
    'medicalHistory.hairlossConditionDownsyndromeOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionScleroderma': 'Have you been diagnosed with Scleroderma?',
    'medicalHistory.hairlossConditionSclerodermaOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionLichenPlanus': 'Have you been diagnosed with Lichen planus?',
    'medicalHistory.hairlossConditionLichenPlanusOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionDiscoidLupus': 'Have you been diagnosed with Discoid Lupus?',
    'medicalHistory.hairlossConditionDiscoidLupusOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionSystemicLupusErythematosus': 'Have you been diagnosed with Systemic Lupus Erythematosus (SLE)?',
    'medicalHistory.hairlossConditionSystemicLupusErythematosusOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionPsoriasis': 'Have you been diagnosed with Psoriasis?',
    'medicalHistory.hairlossConditionPsoriasisOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionRheumatoidArthritis': 'Have you been diagnosed with Rheumatoid Arthritis?',
    'medicalHistory.hairlossConditionRheumatoidArthritisOriginal': 'Original answer:',
    'medicalHistory.hairlossConditionOtherAutoimmuneDisease': 'Have you been diagnosed with Other autoimmune disease?',
    'medicalHistory.hairlossConditionOtherAutoimmuneDiseaseOriginal': 'Original answer:',

    'medicalHistory.againstSexualActivity': 'Do you suffer from any medical condition that advises against sexual activity?',
    'medicalHistory.againstSexualActivityOriginal': 'Original answer:',
    'medicalHistory.Anaemia': 'Do you currently suffer from or have a history of Anaemia?',
    'medicalHistory.AnaemiaOriginal': 'Original answer:',
    'medicalHistory.autoimmuneDiseasesAnswer': 'Do you suffer from any autoimmune diseases?',
    'medicalHistory.autoimmuneDiseasesDetails': 'Please give details:',
    'medicalHistory.bowel': 'Do you suffer from any condition which affects your bowel?',
    'medicalHistory.bloodClot': 'Have you ever suffered from a stroke or any form of blood clot?',
    'medicalHistory.bloodClotOriginal': 'Original answer:',
    'medicalHistory.bladder': 'Do you have any medical conditions or suffer from any problems with your bladder?',
    'medicalHistory.bladderOriginal': 'Original answer:',
    'medicalHistory.brainInjury': 'Have you ever suffered a brain injury?',
    'medicalHistory.brainInjuryOriginal': 'Original answer:',
    'medicalHistory.cancer': 'Have you ever suffered from any form of cancer?',
    'medicalHistory.cancerOriginal': 'Original answer:',
    'medicalHistory.epilepsy': 'Have you been diagnosed or suffer from Epilepsy?',
    'medicalHistory.epilepsyOriginal': 'Original answer:',
    'medicalHistory.G6PDdeficient': 'Please advise if you are G6PD deficient?',
    'medicalHistory.glaucoma': 'Have you been diagnosed or suffer from Glaucoma?',
    'medicalHistory.glaucomaOriginal': 'Original answer:',
    'medicalHistory.headaches': 'Have you suffered from migraines or regular severe headaches?',
    'medicalHistory.headachesOriginal': 'Original answer:',
    'medicalHistory.heartProblems': 'Do you suffer from any form of Heart Disease, ever suffered a Heart Attack or Stroke?',
    'medicalHistory.heartProblemsOriginal': 'Original answer:',
    'medicalHistory.heartProblemsDetails': 'Please give details:',
    'medicalHistory.highBloodPressure': 'Do you suffer from High Blood Pressure for example 160/90mmHg?',
    'medicalHistory.highBloodPressureOriginal': 'Original answer:',
    'medicalHistory.injuryOrTraumaProblemsAnswer': 'In the past have you ever suffered from any trauma or injury to the head?',
    'medicalHistory.injuryOrTraumaProblemsDetails': 'Please give details:',
    'medicalHistory.jaundice': 'Do you suffer from or have a history of Jaundice?',
    'medicalHistory.admittedHospitalAsthma': 'Have you ever been admitted to hospital for your asthma?',
    'medicalHistory.admittedHospitalAsthmaDetails': 'Please give details:',
    'medicalHistory.peKidneyCondition': 'Have you ever been diagnosed with a kidney condition?',
    'medicalHistory.kidneyCondition': 'Have you ever been diagnosed with a kidney condition?',
    'medicalHistory.kidneyConditionDetails': 'Please give details:',
    'medicalHistory.kidneyConditionOriginal': 'Original answer:',
    'medicalHistory.lactoseIssues': 'Are you galactose intolerance, glucose-galactose malabsorption, or Lapp lactose deficiency?',
    'medicalHistory.lactoseIssuesDetails': 'Please give details:',
    'medicalHistory.liverCondition': 'Have you ever been diagnosed with a liver condition?',
    'medicalHistory.liverConditionOriginal': 'Original answer:',
    'medicalHistory.liverConditionAnswer': 'Have you ever been diagnosed with a liver condition?',
    'medicalHistory.liverConditionDetails': 'Please give details:',
    'medicalHistory.otherConditionsAsthma': 'Do you have any other medical conditions? In particular please tell us about any heart conditions such as angina (chest pain) or an irregular heart beat.',
    'medicalHistory.otherConditionsAsthmaDetails': 'Please give details:',
    'medicalHistory.looseBowels': 'Do you experience any symptoms which is resulting in you having loose bowels?',
    'medicalHistory.looseBowelsDetails': 'Please give details:',
    'medicalHistory.lowBloodPressure': 'Do you suffer from Low Blood Pressure?',
    'medicalHistory.lowBloodPressureOriginal': 'Original answer:',
    'medicalHistory.highLowBloodPressure': 'Do you suffer from High or Low Blood Pressure?',
    'medicalHistory.highLowBloodPressureOriginal': 'Original answer:',
    'medicalHistory.lungDisease': 'Do you suffer from any form of lung disease including asthma, COPD or lung cancer?',
    'medicalHistory.myasthenia': 'Have you been diagnosed with Myasthenia gravis?',
    'medicalHistory.neurologicalDisease': 'Do you suffer from Parkinsons disease, Alzheimers, Multiple Sclerosis or any other neurological disease?',
    'medicalHistory.other': 'Do you currently suffer from or have a history of any other type of medical condition other than those listed above?',
    'medicalHistory.otherDetails': 'Please give details:',
    'medicalHistory.otherCondition': 'Do you currently suffer from or have a history of any other type of medical condition other than those listed above?',
    'medicalHistory.otherConditionDetails': 'Please give details:',
    'medicalHistory.otherConditionsAnswer': 'Do you currently suffer from or have a history of any other type of medical condition other than those listed above?',
    'medicalHistory.otherConditionsDetails': 'Please give details:',
    'medicalHistory.paralyticIleus': 'Please advise if you presently suffer from Paralytic ileus?',
    'medicalHistory.poorCirculation': 'Do you suffer from any condition that results in you having poor circulation?',
    'medicalHistory.porphyria': 'Please advise if you presently suffer from Porphyria?',
    'medicalHistory.porphyriaOriginal': 'Original answer:',
    'medicalHistory.prostateSurgery': 'Do you have prostate disease, any condition of prostate or had prostate surgery?',
    'medicalHistory.prostateSurgeryOriginal': 'Original answer:',
    'medicalHistory.psychiatricDisorder': 'Have you ever suffered from or had a treatment for any type of mental health or psychiatric disorder such as anxiety, depression, schizophrenia, ADHD?',
    'medicalHistory.psychiatricDisorderOriginal': 'Original answer:',
    'medicalHistory.reducedImmuneSystem': 'Do you suffer from any condition which results in you having reduced immune system?',
    'medicalHistory.seizures': 'Do you suffer from epilepsy or experience seizures?',
    'medicalHistory.seizuresOriginal': 'Original answer:',
    'medicalHistory.seizuresAnswer': 'Do you presently suffer from epilepsy or seizures?',
    'medicalHistory.seizuresDetails': 'Please give details:',
    'medicalHistory.tendonWeakness': 'Are you suffering from Tendon weakness or disorders?',
    'medicalHistory.ulcerativeColitis': 'Do you suffer from Ulcerative Colitis?',
    'medicalHistory.ulcers': 'Do you suffer from or have a history of Peptic or Gastric Ulcers?',
    'medicalHistory.ulcersOriginal': 'Original answer:',
    'medicalHistory.retinitisPigmentosa': 'Do you suffer from any conditions of the eye? (for example retinitis pigmentosa which is a rare eye condition)',
    'medicalHistory.retinitisPigmentosaOriginal': 'Original answer:',
    'medicalHistory.opticNeuropathy': 'Have you ever had loss of vision due to non-arteritis anterior ischaemic optic neuropathy (NAION)?',
    'medicalHistory.opticNeuropathyOriginal': 'Original answer:',
    'medicalHistory.vaccineInfection': 'Do you have an impaired immune system? (For example HIV infection, splenectomy, or taking long-term immunosuppressive therapy)',
    'medicalHistory.neurologicalComplications': 'Do you suffer from any neurological complications? (For example encephalopathy or encephalitis)',
    'medicalHistory.illnessFever': 'Are you currently suffering from any for of illness or fever?',
    'medicalHistory.bleedingDisorder': 'Do you suffer from any form or bleeding disorder(s)?',
    'medicalHistory.bleedingDisorderOriginal': 'Original answer:',
    'medicalHistory.impairedImmune': 'Do you have an impaired immune system? (For example HIV infection, splenectomy, or taking long-term immunosuppressive therapy)',
    'medicalHistory.vaccineNeeded': 'Do you need to have the typhoid vaccine or rabies vaccine before you travel?',
    'medicalHistory.impairedImmuneMalaria': 'Do you have a weakened immune system for any reason including: A medical condition e.g. HIV, AIDS, some cancers? Taking medication that suppresses the immune system such as steroids or some cancer treatments? Removal of your spleen or impaired function of the spleen?',
    'medicalHistory.impairedImmuneMalariaOriginal': 'Original Answer:',
    'medicalHistory.chloroquineWeight': 'Do you weigh less than 45kg?',
    'medicalHistory.chloroquineWeightOriginal': 'Original Answer:',
    'medicalHistory.lariamWeight': 'Do you weigh less than 45kg?',
    'medicalHistory.lariamWeightOriginal': 'Original Answer:',
    'medicalHistory.lariamSeizures': 'Have you ever suffered from Epilepsy or Seizures?',
    'medicalHistory.lariamSeizuresOriginal': 'Original Answer:',
    'medicalHistory.lariamHeartConditions': 'Do you have any heart conditions, especially cardiac conduction disorders?',
    'medicalHistory.lariamHeartConditionsOriginal': 'Original Answer:',
    'medicalHistory.lariamHeartDepression': 'Have you ever experienced any of the following: Depression Anxiety Schizophrenia Any other psychiatric condition Attempted suicide',
    'medicalHistory.lariamHeartDepressionOriginal': 'Original Answer:',
    'medicalHistory.malaroneWeight': 'Do you weigh less than 41kg?',
    'medicalHistory.malaroneWeightOriginal': 'Original Answer:',
    'medicalHistory.paludrineWeight': 'Do you weigh less than 41kg?',
    'medicalHistory.previousVaccineIllness': 'Have you ever had the illness or illnesses you want to be vaccinated against?',
    'medicalHistory.vaccineBloodThinner': 'Do you take Warfarin or any medication other than aspirin to thin the blood?',
    'medicalHistory.vaccineBloodThinnerOriginal': 'Original Answer:',
    'medicalHistory.vaccineCurrentlyUnwell': 'Are you currently unwell? You should not be vaccinated while you are unwell. A simple head cold however is not a reason you should postpone your vaccination, although you may wish to.',
    'medicalHistory.vaccineCurrentlyUnwellOriginal': 'Original Answer:',
    'medicalHistory.previousVaccineIllnessDetails': 'Please state which illnesses you have had (NOTE our doctors will not be able to prescribe any vaccines for which you have suffered from the illness):',
    'medicalHistory.vaccineNervousSystem': 'Do you have any conditions that affect the nervous system, for example any weakness or numbness, or conditions such as MS, Guillain Barre syndrome, poorly controlled epilepsy or encephalitis?',
    'medicalHistory.vaccineNervousSystemOriginal': 'Original Answer:',
    'medicalHistory.doxcyclineDiabetic': 'Are you diabetic?',
    'medicalHistory.doxcyclineDiabeticOriginal': 'Original Answer:',
    'medicalHistory.diabetic': "Are you diabetic?",
    'medicalHistory.chloroquineConditions': 'Do you have any of the following medical conditions: Acute porphyria,G6PD deficiency,Myasthaenia gravis,Psoriasis,Epilepsy,Medical conditions affecting the gut',
    'medicalHistory.chloroquineConditionsOriginal': 'Original Answer:',

    'medicalHistory.surgeryMolarPregnancy': 'Have you had any surgery in the last 4 days, including surgery of a molar pregnancy?',
    'medicalHistory.surgeryMolarPregnancyOriginal': 'Original answer:',
    'medicalHistory.terminationMiscarriage': 'Have you had a termination or miscarriage in the last 4 days?',
    'medicalHistory.terminationMiscarriageOriginal': 'Original answer:',
    'medicalHistory.recentMother': 'Have you had a baby in the last 21 days?',
    'medicalHistory.recentMotherOriginal': 'Original answer:',
    'medicalHistory.ectopicPregnancySurgeryDisease': 'Have you ever had an ectopic pregnancy, fallopian tube surgery or pelvic inflammatory disease?',
    'medicalHistory.ectopicPregnancySurgeryDiseaseDetails': 'Please give details:',
    'medicalHistory.mpSevereAsthma': 'Do you have or have you ever been diagnosed with severe asthma requiring treatment with steroid tablets',
    'medicalHistory.mpSevereAsthmaOriginal': 'Original answer:',
    'medicalHistory.mpHIV': 'Do you have or have you ever been diagnosed with HIV',
    'medicalHistory.mpHIVOriginal': 'Original answer:',
    'medicalHistory.mpEpilepsy': 'Do you have or have you ever been diagnosed with Epilepsy',
    'medicalHistory.mpEpilepsyOriginal': 'Original answer:',
    'medicalHistory.mpTuberculosis': 'Do you have or have you ever been diagnosed with Tuberculosis',
    'medicalHistory.mpTuberculosisOriginal': 'Original answer:',
    'medicalHistory.mpPorphyria': 'Do you have or have you ever been diagnosed with Porphyria',
    'medicalHistory.mpPorphyriaOriginal': 'Original answer:',
    'medicalHistory.mpLappLactoseDeficiency': 'Do you have or have you ever been diagnosed with Lapp-lactose deficiency, Galactose intolerance, Glucose-galactose malabsorption',
    'medicalHistory.mpLappLactoseDeficiencyOriginal': 'Original answer:',

    'familyMedicalHistory.bloodClotsFamily': 'Has any member of your family suffered from blood clots whilst under the age of 45 years?',
    'familyMedicalHistory.bloodClotsFamilyOriginal': 'Original Answer:',
    'familyMedicalHistory.cancerFamily': 'Has any member of your family suffered with cancer of the breast, womb or ovary?',
    'familyMedicalHistory.cancerFamilyOriginal': 'Original Answer:',
    'familyMedicalHistory.heartDiseaseFamily': 'Has any member of your family suffered from any form of Heart Disease whilst under the age of 45 years?',
    'familyMedicalHistory.heartDiseaseFamilyOriginal': 'Original Answer:',
    'familyMedicalHistory.pdFamilyHeartAttackUnder45': 'Has a first degree relative of yours (mother, father, brother, sister, child) had a heart attack under the age of 45?"',
    'familyMedicalHistory.pdFamilyStrokeUnder45': 'Has a first degree relative of yours (mother, father, brother, sister, child) had a stroke under the age of 45?"',
    'familyMedicalHistory.pdFamilyBloodClotsLegLungs': 'Has a first degree relative of yours (mother, father, brother, sister, child) had a blood clot in leg or lungs (deep vein thrombosis, DVT, or pulmonary embolism, PE) under the age of 45?"',
    'familyMedicalHistory.pdPredispositionBloodClots': 'Has a first degree relative of yours (mother, father, brother, sister, child) had an inherited predisposition to blood clots e.g. Factor V Leiden, antiphospholipid syndrome, etc.?"',

    'measurements.measurementSystem': 'Imperial(e.g. 5ft 7in / 13st 7lb) or metrical(e.g. 160cm / 60kg) system?',
    'measurements.height': 'Height:',
    'measurements.weight': 'Weight:',
    'measurements.highBloodPressure': 'Do you suffer from High Blood pressure or take medicines to control your blood pressure?',
    'measurements.highBloodPressureOriginal': 'Original Answer:',
    'measurements.lowBloodPressure': 'Is your blood pressure under 140/90mm Hg?',
    'measurements.lowBloodPressureOriginal': 'Original Answer:',

    'pregnancyBreastfeeding.gender': 'Please select if you are: Male/Female',
    'pregnancyBreastfeeding.pregnant': 'Are you pregnant, planning on becoming pregnant or is there any chance you could be pregnant?',
    'pregnancyBreastfeeding.pregnant1': 'Are you pregnant or is there any chance you could be pregnant?',
    'pregnancyBreastfeeding.pregnantOriginal': 'Original answer:',
    'pregnancyBreastfeeding.pregnantDetails': 'Please give details:',
    'pregnancyBreastfeeding.breastFeeding': 'Please advise if you are currently breast feeding?',
    'pregnancyBreastfeeding.breastFeedingOriginal': 'Original answer:',
    'pregnancyBreastfeeding.breastFeedingAnswer': 'Please advise if you are currently breast feeding?',
    'pregnancyBreastfeeding.breastFeedingDetails': 'Please give details:',

    'pregnancyBreastfeeding.recentMother': 'Have you had a baby in the last 6 months?',
    'pregnancyBreastfeeding.recentMotherOriginal': 'Original answer:',
    'pregnancyBreastfeeding.futureMother': 'Are you planning pregnancy in the next 6 months?',
    'pregnancyBreastfeeding.futureMotherOriginal': 'Original answer:',

    'lifeStyle.alcoholAnswer': 'Do you drink alcohol?',
    'lifeStyle.alcoholDetails': 'Approximately how many units of alcohol do you drink per week?',
    'lifeStyle.smoke': 'Do you smoke?',

    'allergies.acetazolamideAllergiesDisclaimer': 'You should not take this acetazolamide if you are allergic to any of a group of medicines called sulfonamide. If you are unsure if you are taking a Sulfonamide you check with your Pharmacist.',
    'allergies.hasAllergies': 'Do you have any allergies?',
    'allergies.allergiesHayFever': 'Allergic to Grass, pollen, trees, plants (Hay fever)',
    'allergies.allergiesHouseDust': 'Allergic to House dust',
    'allergies.allergiesAnimals': 'Allergic to Animals (Dogs, cats, horses etc)',
    'allergies.allergiesNuts': 'Allergic to Nuts',
    'allergies.allergiesPenicillin': 'Allergic to Penicillin',
    'allergies.allergiesOtherMedicines': 'Allergic to other medicines',
    'allergies.allergiesOtherMedicinesDetails': 'Allergic to other medicines details',
    'allergies.allergiesOther': 'Allergic to Other',
    'allergies.allergiesOtherDetails': 'Allergic to Other Details',
    'allergies.allergiesEggs': 'Allergic to Eggs',
    'allergies.allergiesLatex': 'Allergic to Latex',
    'allergies.allergiesCausative': 'Allergic to causative agent in the Vaccine',
    'allergies.chloroquineAllergy': 'Have you ever had an allergic reaction to quinine?',
    'allergies.chloroquineAllergyOriginal': 'Original Answer:',

    'vaccineHistory.vaccineHistoryDetails': 'Please provide details of your vaccination history. Please include information for vaccinations you have had previously, providing details of approximately when they were administered.',
    'vaccineHistory.vaccineHistoryReaction': 'Have you ever had an anaphylactic reaction to any vaccine or any component of a vaccine? (if you are unsure – please consult your pharmacist or regular GP. If you cannot confirm this information you should not proceed with this consultation)',
    'vaccineHistory.vaccineHistoryReactionOriginal': 'Original Answer:',

    'drugPreferences.treatment': 'Do you have any drug preferences?',
    'drugPreferences.dosage': 'Please select required dosage',
    'drugPreferences.package': 'Please select required pack size',
    'disclaimer.consideredRepeatPrescriptions': 'Would you like to be considered for repeat prescriptions?',
    'disclaimer.shareDetailsWithGp': 'If successful can we forward the details of this consultation and any prescribed medication to your regular GP for their records?',


    'disclaimer.malariaProtection': 'I understand that NO malaria tablet can guarantee complete protection against malaria. It is very important to take precautions against being bitten. For advice on avoiding mosquito bites, click here.',
    'disclaimer.malariaDisclaimer': 'I have read and fully understand all the information in this form.',
    'disclaimer.malariaDisclaimer2': 'I have answered honestly and completely, with up to date and accurate information so that the doctor can safely assess my suitability for this medicine.',
    'disclaimer.malariaDisclaimer3': 'I understand the risks and benefits of taking this treatment including possible side-effects.',
    'disclaimer.malariaDisclaimer4': 'I am aware of alternatives to taking this treatment.',
    'disclaimer.malariaDisclaimer5': 'I understand the importance of notifying my regular GP practice of medicines and advice received through the Treat it so that they may provide ongoing safe care.',
    'disclaimer.malariaDisclaimer6': 'I have read and understood the latest terms and conditions and privacy policy.',

    'disclaimer.malariaDisclaimerLariam': 'Lariam has been known to cause serious mood changes in some people.  You should NOT order this medication if you have a history of depression, anxiety or other psychiatric condition.  It can cause seizures and is NOT to be taken by people who have epilepsy or history of seizures. This medicine is also NOT suitable for people with certain heart problems. Lariam can cause dizziness or disturbed balance so care must be taken while driving or operating machinery. Effects may persist up to several months after stopping treatment. We are unable to prescribe Lariam for women who are: Pregnant, Planning to become pregnant within the next 6 months, Breastfeeding. Because Lariam takes time to be cleared from the body, it could harm your baby if you become pregnant even after stopping treatment',
    'disclaimer.malariaDisclaimerMalarone': 'It is very important that you take any medicines as instructed by the patient information leaflet.  You must complete the full course of medication even upon leaving the affected area. Failure to do so may decrease the effectiveness of the medicine and mean you are not protected against malaria.',
    'disclaimer.malariaDisclaimerMalarone2': 'The time between being bitten by an infected mosquito and getting symptoms of malaria varies.  Any illness that develops with 1 year, and especially within 3 months of entering a malarial zone might be malaria. You should see a doctor straight away and specifically mention your exposure to malaria.',
    'disclaimer.malariaDisclaimerPaludrine': 'We are unable to prescribe Paludrine for women who are: Pregnant Planning to become pregnant within the next 6 months Breastfeeding. It’s important that you complete any vaccinations at least 3 days before starting antimalarial treatment as it can interfere with the body responding properly to the vaccine.  If you are not sure if you have enough time, please discuss with a doctor.',
    'disclaimer.scrAccess': 'As a patient using The Treat it, do you give permission for our Doctors to access your NHS Health Record (Summary Care Record), if you have one?',
    'disclaimer.gPDetails': 'Please enter your GP\'s postcode or the first line of their address',
    'disclaimer.gpName': 'GP Name',
    'disclaimer.gpPractice': 'GP Practice',
    'disclaimer.gpAddressLine1': 'Address line 1',
    'disclaimer.gpAddressLine2': 'Address line 2',
    'disclaimer.gpAddressCity': 'City/Town',
    'disclaimer.gpAddressPostcode': 'PostCode',
    'disclaimer.gpAddressCounty': 'County',
    'disclaimer.confirmAnswers': 'I confirm that all the responses I have provided in the assessment are true and to the best of my knowledge.',
    'disclaimer.confirmPersonalUse': 'I confirm that I am requesting treatment for my personal use and not on the behalf of any other person.',

    'disclaimer.disclaimer0': 'I confirm that I am requesting treatment for my personal use and not on the behalf of any other person.',
    'disclaimer.disclaimer1': 'I have read and fully understand all the information in this form.',
    'disclaimer.disclaimer2': 'I have answered honestly and completely, with up to date and accurate information so that the doctor can safely assess my suitability for this medicine.',
    'disclaimer.disclaimer3': 'I understand the risks and benefits of taking this treatment including possible side-effects. I am aware of alternatives to taking this treatment.',
    'disclaimer.disclaimer4': 'I understand the importance of notifying my regular GP practice of medicines and advice received through the Treat it so that they may provide ongoing safe care.',
    'disclaimer.disclaimer5': 'I have read and understood the latest terms and conditions and privacy policy.'
    
  };

  blockerAnswers = [
    // erectile-dysfunction
    { formId: 1, group: 'general', question: 'howSudden', answer: ['Suddenly'] },
    { formId: 1, group: 'general', question: 'canGetErection', answer: ['Yes'] },
    { formId: 1, group: 'general', question: 'factorOther', answer: ['true'] },
    { formId: 1, group: 'medicationHistory', question: 'currentMedicationRiociguat', answer: ['Yes'] },
    { formId: 1, group: 'medicationHistory', question: 'edRecreationalDrugs', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'peyroniesDisease', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'priapism', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'againstSexualActivity', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'edHeartConditions', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'heartAttack6months', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'edLowBloodPressure', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'edDiabetesDiagnosed', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'suddenBlindness', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'hereditaryRetinaDisorder', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'bloodClottingCondition', answer: ['Yes'] },
    { formId: 1, group: 'medicalHistory', question: 'bloodClottingCondition', answer: ['Yes'] },
    { formId: 1, group: 'disclaimer', question: 'shareDetailsWithGp', answer: ['false'] },
    // morning-after-pill
    { formId: 2, group: 'general', question: 'unprotectedSexDay6', answer: ['true'] },
    { formId: 2, group: 'general', question: 'unprotectedSexDay7', answer: ['true'] },
    { formId: 2, group: 'general', question: 'lastPeriod', answer: ['No', 'I missed my period'] },
    { formId: 2, group: 'general', question: 'nextPeriod', answer: ['In less than 14 days'] },
    { formId: 2, group: 'medicalHistory', question: 'mpSevereAsthma', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'mpHIV', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'mpEpilepsy', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'mpTuberculosis', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'mpPorphyria', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'mpLappLactoseDeficiency', answer: ['true'] },
    { formId: 2, group: 'medicalHistory', question: 'surgeryMolarPregnancy', answer: ['Yes'] },
    { formId: 2, group: 'medicalHistory', question: 'terminationMiscarriage', answer: ['Yes'] },
    { formId: 2, group: 'medicalHistory', question: 'recentMother', answer: ['Yes'] },
    { formId: 2, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 2, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    { formId: 2, group: 'pregnancyBreastfeeding', question: 'pregnant', answer: ['Yes'] },
    // pre-ejaculation
    { formId: 4, group: 'general', question: 'priligyHistorySymptomsFainting', answer: ['true'] },
    { formId: 4, group: 'general', question: 'priligyHistorySymptomsDizziness', answer: ['true'] },
    { formId: 4, group: 'general', question: 'priligyHelpful', answer: ['No'] },
    { formId: 4, group: 'general', question: 'howLongSexuallyActive', answer: ['Fewer than 6 months'] },
    { formId: 4, group: 'general', question: 'preEjacNewIssue', answer: ['This is a new problem'] },
    { formId: 4, group: 'general', question: 'howOftenPE', answer: ['When I have sex with a new partner'] },
    { formId: 4, group: 'general', question: 'howLongEjaculation', answer: ['More than 2 minutes'] },
    { formId: 4, group: 'general', question: 'preEjacRelationshipDistress', answer: ['No'] },
    { formId: 4, group: 'medicationHistory', question: 'edCurrentUse', answer: ['Yes'] },
    { formId: 4, group: 'medicalHistory', question: 'peMentalHealthCondition', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peLowBloodPressure', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peHeartProblems', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peBrainInjury', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peEpilepsy', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peBleedingDisorder', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peUlcer', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peProstateCondition', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'peGlaucomaSusceptability', answer: ['true'] },
    { formId: 4, group: 'medicalHistory', question: 'painEjaculation', answer: ['Yes'] },
    { formId: 4, group: 'medicalHistory', question: 'againstSexualActivity', answer: ['Yes'] },
    // hair-loss
    { formId: 5, group: 'general', question: 'hairlossGender', answer: ['Female'] },
    { formId: 5, group: 'general', question: 'hairlossConfined', answer: ['Yes'] },
    { formId: 5, group: 'general', question: 'hairlossSkinChanges', answer: ['Yes'] },
    { formId: 5, group: 'general', question: 'howLong', answer: ['Less than 6 months'] },
    { formId: 5, group: 'general', question: 'howSudden', answer: ['Suddenly'] },
    { formId: 5, group: 'general', question: 'picture', answer: ['Complete Loss', 'Random Patches'] },
    { formId: 5, group: 'medicationHistory', question: 'enlargedProstateMedication', answer: ['Yes'] },
    { formId: 5, group: 'medicationHistory', question: 'chemoRadioImmunoTherapy', answer: ['Yes'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionHyperthyroidism', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionDiabetes', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionDownsyndrome', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionScleroderma', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionLichenPlanus', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionDiscoidLupus', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionSystemicLupusErythematosus', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionPsoriasis', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionRheumatoidArthritis', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'hairlossConditionOtherAutoimmuneDisease', answer: ['true'] },
    { formId: 5, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 5, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    // cystitis
    { formId: 9, group: 'general', question: 'cystitisSuspection', answer: ['No'] },
    { formId: 9, group: 'general', question: 'cystitisDiagnosed', answer: ['No'] },
    { formId: 9, group: 'general', question: 'cystitisReoccurrence', answer: ['3 or more times'] },
    { formId: 9, group: 'general', question: 'cystitisRecognitionAbility', answer: ['No'] },
    { formId: 9, group: 'general', question: 'cystitisTreated', answer: ['Yes'] },
    { formId: 9, group: 'general', question: 'symptomsBackOrSidePain', answer: ['true'] },
    { formId: 9, group: 'general', question: 'symptomsHighTemperature', answer: ['true'] },
    { formId: 9, group: 'general', question: 'symptomsUncontrollableShaking', answer: ['true'] },
    { formId: 9, group: 'general', question: 'symptomsFeelingFaint', answer: ['true'] },
    { formId: 9, group: 'general', question: 'itchyVagina', answer: ['Yes'] },
    { formId: 9, group: 'general', question: 'vaginalDischarge', answer: ['Yes'] },
    { formId: 9, group: 'medicationHistory', question: 'currentMedicationCystitis', answer: ['Yes'] },
    { formId: 9, group: 'medicalHistory', question: 'catheterInPlace', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'catheterRemoved', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'G6PDDeficiency', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'porphyria', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'diabetic', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'weakenedImmuneSystem', answer: ['true'] },
    { formId: 9, group: 'medicalHistory', question: 'cystitisSpread', answer: ['Yes'] },
    { formId: 9, group: 'medicalHistory', question: 'urinaryInfectionPregnant', answer: ['Yes'] },
    { formId: 9, group: 'medicalHistory', question: 'kidneyBladderUrinaryCondition', answer: ['Yes'] },
    { formId: 9, group: 'pregnancyBreastfeeding', question: 'pregnant', answer: ['Yes'] },
    { formId: 9, group: 'pregnancyBreastfeeding', question: 'breastFeeding', answer: ['Yes'] },
    // asthma-inhalers
    { formId: 19, group: 'general', question: 'howLongAsthma', answer: ['I have never used it before'] },
    { formId: 19, group: 'general', question: 'conditionDiagnosed', answer: ['No'] },
    { formId: 19, group: 'general', question: 'howManyInhalersAYear', answer: ['Six or more'] },
    { formId: 19, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 19, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    // period-delay
    { formId: 25, group: 'general', question: 'predictPeriods', answer: ['No'] },
    { formId: 25, group: 'medicalHistory', question: 'pdCriticalConditionPorphyria', answer: ['true'] },
    { formId: 25, group: 'medicalHistory', question: 'pdCriticalConditionCancer', answer: ['true'] },
    { formId: 25, group: 'medicalHistory', question: 'pdCriticalConditionUndiagnosedVaginalBleeding', answer: ['true'] },
    { formId: 25, group: 'medicalHistory', question: 'pdCriticalConditionArterialDisease', answer: ['true'] },
    { formId: 25, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 25, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    { formId: 25, group: 'pregnancyBreastfeeding', question: 'pregnant1', answer: ['Yes'] },
    { formId: 25, group: 'pregnancyBreastfeeding', question: 'breastFeeding', answer: ['Yes'] },
    { formId: 25, group: 'pregnancyBreastfeeding', question: 'recentMother', answer: ['Yes'] },
    { formId: 25, group: 'pregnancyBreastfeeding', question: 'futureMother', answer: ['Yes'] },
    // jet-lag
    { formId: 26, group: 'medicationHistory', question: 'MoclobemideMedicationAnswer', answer: ['Yes'] },
    { formId: 26, group: 'medicationHistory', question: 'FluvoxamineMedicationAnswer', answer: ['Yes'] },
    { formId: 26, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 26, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    { formId: 26, group: 'pregnancyBreastfeeding', question: 'pregnant', answer: ['Yes'] },
    { formId: 26, group: 'pregnancyBreastfeeding', question: 'breastFeeding', answer: ['Yes'] },
    // high-altitude-sickness
    { formId: 27, group: 'medicationHistory', question: 'currentMedications3TD', answer: ['Yes'] },
    { formId: 27, group: 'medicalHistory', question: 'diabetic', answer: ['Yes'] },
    { formId: 27, group: 'medicalHistory', question: 'liverCondition', answer: ['Yes'] },
    { formId: 27, group: 'medicalHistory', question: 'kidneyCondition', answer: ['Yes'] },
    { formId: 27, group: 'pregnancyBreastfeeding', question: 'pregnant', answer: ['Yes'] },
    { formId: 27, group: 'pregnancyBreastfeeding', question: 'breastFeeding', answer: ['Yes'] }
  ]

    constructor() { }

    parse(form: any): [string, string, string][] {

        return <[string, string, string][]>Object.keys(this.questionsLookup)
            .filter(k => { return form[k]; })
            .map(k => { return [this.questionsLookup[k], <string>form[k], k]; });
    }
}
