import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalService } from '../modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gp-notes-modal',
  templateUrl: './gp-notes-modal.component.html',
  styleUrls: ['./gp-notes-modal.component.scss']
})
export class GpNotesModalComponent implements OnInit, OnDestroy {
  @Input() orderId: any;
  @Input() orderstatus: string;
  @ViewChild('viewGPSNotes') modal: ModalDirective;

  private modalEventSubscription: Subscription;

  constructor(private modalService: ModalService, private router: Router) { }

  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    this.modalEventSubscription = this.modalService.modalEvent.subscribe((data) => {
      if (currentUrl.includes('past-orders')) {
        if (data.modalName === 'gpNotes' && data.orderId === this.orderId) {
          this.modal.show();
        }
      } else {
        if (data === 'gpNotes') {
          this.modal.show();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.modalEventSubscription) {
      this.modalEventSubscription.unsubscribe();
    }
  }

}
